import axios from "axios";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_REST_API}`,
});

API.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  config.baseURL = `${process.env.REACT_APP_REST_API}`;
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const jcnError = {
      message: error.response?.data?.userMessage || error.response?.data,
    };
    if (jcnError.message === "UNAUTHORIZED") {
      localStorage.clear();
      window.location.replace("/");
    }
    return Promise.reject(jcnError);
  }
);

export default API;
