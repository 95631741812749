import gql from 'graphql-tag';

const CREATE_BOTFOLLOWUP_MESSAGE = gql`
  mutation createMessageBotConfig(
    $companyId: ID!,
    $content: String,
    $isActive: Boolean!,
    $timeLapseInSeconds: Int!
    $channelId: String
    $templateId: String
    ) {
    createMessageBotConfig(
      data: {
        company: { connect: { id: $companyId } },
        content: $content,
        isActive: $isActive,
        timeLapseInSeconds: $timeLapseInSeconds,
        channelId: $channelId,
        templateId: $templateId,
      }
    ) {
      id
      content
      isActive 
    }
  }
`;

const GET_MESSAGE_BOT_CONFIG = gql`
  query messageBotConfigs($channelId: String) {
    messageBotConfigs(where: { channelId: $channelId }) {
      id
      content
      isActive
      timeLapseInSeconds
      channelId
      templateId
    }
  }
`;
const GET_MESSAGE_BOT_CONFIG_TEMPLATE = gql`
  query messageBotConfigs($templateId: String!) {
    messageBotConfigs(where: { templateId: $templateId }) {
      id
      content
      isActive
      timeLapseInSeconds
      channelId
      templateId
    }
  }
`;

const GET_MESSAGE_BOTS = gql`
  query messageBots($messageBotConfigId: ID!) {
    messageBots(where: { messageBotConfig: { id: $messageBotConfigId } }) {
      id
      nextDate
      wasSent
      client {
        id
      }
    }
  }
`;

const DELETE_MESSAGE_BOTS = gql`
  mutation deleteMessageBots($messageBotId: ID!) {
    deleteMessageBot(where: { id:  $messageBotId }) {
      id
    }
  }
`;

const UPDATE_BOTFOLLOWUP_MESSAGE = gql`
  mutation updateMessageBotConfig(
    $id: ID!,
    $text: String,
    $isActive: Boolean!,
    $timeLapseInSeconds: Int!
    $templateId: String
  ) {
    updateMessageBotConfig
    (
      where: { id: $id }
      data: {
        isActive: $isActive,
        content: $text,
        timeLapseInSeconds: $timeLapseInSeconds,
        templateId: $templateId,
      }
    ) {
      id
    }
  }
`;const CREATE_MESSAGE_BOT = gql`
  mutation createMessageBot($wasSent: Boolean!, $clientId: ID!, $nextDate: Int!, $messageBotConfigId: ID!) {
    createMessageBot
    (
      data: {
      wasSent: $wasSent,
      client: {connect: {id: $clientId}},
      nextDate: $nextDate,
      messageBotConfig: {connect: {id:$messageBotConfigId}},
      }
    ) {
      id
      client{
        id
      }
      nextDate
      wasSent

    }
  }
`;

const ApolloWelcomeMessage = {
  CREATE_BOTFOLLOWUP_MESSAGE,
  GET_MESSAGE_BOT_CONFIG,
  GET_MESSAGE_BOT_CONFIG_TEMPLATE,
  UPDATE_BOTFOLLOWUP_MESSAGE,
  CREATE_MESSAGE_BOT,
  GET_MESSAGE_BOTS,
  DELETE_MESSAGE_BOTS,
};

export default ApolloWelcomeMessage;
