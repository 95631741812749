import React, { useState, useEffect } from "react";

import { Label, PrimaryButton } from "./Onboarding.styles";
import { WhatsappQRCode } from "../shared/components/whatsappQRCode/WhatsappQRCode";
import { getChannels } from "../../utils/channels";
import { verifyToken } from "../../utils/userAuth";

const WhatsappLinkQrCode = () => {
  const [loading, setLoading] = useState(true);
  const [phone, setPhone] = useState(null);

  const init = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await verifyToken(token);
      console.log(data);
      const channels = await getChannels(data?.company?.id);
      console.log(channels);
      setPhone(channels?.[0]?.phone);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading && <div>Cargando...</div>}
      {!loading && (
        <>
          <Label
            style={{
              textAlign: "center",
            }}
          >
            ¡Con este QR podrás mandar tu primer mensaje a tu panel de iVentas!
          </Label>

          <WhatsappQRCode phoneNumber={phone} />
          <div style={{ cursor: "pointer", marginTop: "2rem" }}>
            <PrimaryButton onClick={() => (window.location.href = `/chat`)}>
              Ir al chat
            </PrimaryButton>
          </div>
        </>
      )}
    </main>
  );
};

export default WhatsappLinkQrCode;
