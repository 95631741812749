import styled from 'styled-components';

export const MultimediaActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
`;

export const SearchContainer = styled.div`
  width: 18rem;
`;

export const MultimediaSortLabel = styled.span`
  font-family: Montserrat-Medium, sans-serif;
  font-size: 0.875rem;
  opacity: 0.5;
  margin-right: 1rem;
`;

export const MultimediaCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const MultimediaCard = styled.div`
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  display: inline-block;
`;

export const MultimediaEmpty = styled.div`
  height: calc(100% - 20rem);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Medium, sans-serif;
  font-size: 1rem;
  opacity: 0.4;
`;
