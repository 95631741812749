import client from '../apollo/apolloClient';
import apolloAdmins from '../apollo/apolloAdmins';
import API from '../axios';
import ApolloAgents from '../apollo/agents';

export const getAdmins = async (companyId) => {
  const { data } = await client.query({
    query: apolloAdmins.GET_ADMINS_BY_COMPANY_ID_AND_LOCATION_ID,
    variables: { companyId, userType: 'ADMIN' },
    fetchPolicy: 'no-cache',
  });
  if (data) {
    return data.users;
  }
};

export const deleteAdmin = async (adminId, agentEmail) => {
  try {
    const { data: adminData } = await client.mutate({
      mutation: apolloAdmins.DELETE_ADMIN,
      variables: { documentId: adminId },
    });
    const { data: agentData } = await client.mutate({
      mutation: ApolloAgents.DELETE_AGENT_BY_EMAIL,
      variables: { email: agentEmail },
    });
    if (
      adminData
      && adminData.deleteUser
      && adminData.deleteUser.id
      && agentData
      && agentData.deleteAgent
      && agentData.deleteAgent.id
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addAdmin = async (data) => {
  const response = await API.post('/registerAdmin', data);
  return response.data;
};

export const editAdmin = async (data, adminEmail) => {
  try {
    const { data: adminData } = await client.mutate({
      mutation: apolloAdmins.UPDATE_ADMIN,
      variables: data,
    });
    const agent = { ...data, previousEmail: adminEmail };
    const { data: agentData } = await client.mutate({
      mutation: ApolloAgents.UPDATE_AGENT_BY_EMAIL,
      variables: agent,
    });
    if (
      adminData
      && adminData.updateUser
      && adminData.updateUser.id
      && agentData
      && agentData.updateAgent
      && agentData.updateAgent.id
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getUserImageUrl = async (userId) => {
  try {
    const { data } = await client.query({
      query: apolloAdmins.GET_USER_IMAGE_URL,
      variables: { userId },
    });
    if (data) {
      return data?.user?.imageUrl;
    } return null;
  } catch (error) {
    console.log(error);
  }
};
