import CONSTANTS from '../const/constants';
import {
  getQuickAnswers,
  deleteQuickAnswer,
  createQuickAnswer,
  updateQuickAnswer,
  updateQuickAnswerLastUse,
} from '../utils/quickAnswers';

const QUICK_ANSWERS_INIT_STATE = {
  data: [],
  selected: {},
  fetching: false,
  error: false,
};

const GETTING_QUICK_ANSWERS = 'GETTING_QUICK_ANSWERS';
const GET_QUICK_ANSWERS_SUCCESS = 'GET_QUICK_ANSWERS_SUCCESS';
const GET_QUICK_ANSWERS_ERROR = 'GET_QUICK_ANSWERS_ERROR';
const SELECT_QUICK_ANSWER = 'SELECT_QUICK_ANSWER';
const CLEAR_DATA = 'CLEAR_DATA';

export default function quickAnswersReducer(state = QUICK_ANSWERS_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_QUICK_ANSWERS:
      return { ...state, fetching: true };
    case GET_QUICK_ANSWERS_SUCCESS:
      return { ...state, fetching: false, data: payload };
    case GET_QUICK_ANSWERS_ERROR:
      return { ...state, fetching: false, error: payload };
    case SELECT_QUICK_ANSWER:
      return { ...state, selected: payload };
    case CLEAR_DATA:
      return { ...QUICK_ANSWERS_INIT_STATE };
    default:
      return state;
  }
}

export const getQuickAnswersAction = (descOrder) => async (dispatch, getState) => {
  try {
    dispatch({ type: GETTING_QUICK_ANSWERS });
    const spaceId = getState().spaces.selected.id;
    const response = await getQuickAnswers(spaceId, descOrder);
    dispatch({ type: GET_QUICK_ANSWERS_SUCCESS, payload: response });
    return response;
  } catch (error) {
    dispatch({ type: GET_QUICK_ANSWERS_ERROR, payload: error });
    // const JcnError = new Error('CANNOT_GET_QUICK_ANSWERS');
    // throw JcnError;
  }
};

export const deleteQuickAnswerAction = () => async (dispatch, getState) => {
  try {
    const quickAnswerId = getState().quickAnswers.selected.id;
    const response = await deleteQuickAnswer(quickAnswerId);

    const quickAnswers = [...getState().quickAnswers.data];
    const deletedQuickAnswerIndex = quickAnswers.findIndex((x) => x.id === quickAnswerId);
    quickAnswers.splice(deletedQuickAnswerIndex, 1);
    dispatch({ type: SELECT_QUICK_ANSWER, payload: {} });
    dispatch({ type: GET_QUICK_ANSWERS_SUCCESS, payload: quickAnswers });
    return response;
  } catch (error) {
    throw error;
  }
};

export const selectQuickAnswerAction = (quickAnswer) => (dispatch) => {
  dispatch({ type: SELECT_QUICK_ANSWER, payload: quickAnswer });
};

export const updateSelectedQuickAnswerAction = (updatedData, file) => async (dispatch, getState) => {
  const formData = new FormData();
  const quickAnswerId = getState().quickAnswers.selected.id;
  formData.append('id', quickAnswerId);
  if (updatedData.shortcut) formData.append('shortcut', updatedData.shortcut.substring(1));
  if (updatedData.message) formData.append('text', updatedData.message);
  if (file && !file.id) {
    formData.append('file', file);
    formData.append('weight', file.size);
    formData.append('type', 'image/jpeg');
    formData.append('fileName', file.name);
    formData.append('fileType', CONSTANTS.FILE_TYPE_IMAGE);
  }
  await updateQuickAnswer(formData);
  dispatch({ type: SELECT_QUICK_ANSWER, payload: {} });
};

export const createQuickAnswerAction = (data, file) => async (_, getState) => {
  const spaceId = getState().spaces.selected.id;
  const formData = new FormData();
  formData.append('shortcut', data.shortcut.substring(1));
  formData.append('text', data.message);
  formData.append('spaceId', spaceId);
  if (file) {
    formData.append('file', file);
    formData.append('weight', file.size);
    formData.append('fileName', file.name);
    formData.append('fileType', CONSTANTS.FILE_TYPE_IMAGE);
    formData.append('type', 'image/jpeg');
  }
  await createQuickAnswer(formData);
};

export const updateQuickAnswerLastUseAction = (quickAnswerId) => async (dispatch, getState) => {
  try {
    const data = await updateQuickAnswerLastUse(quickAnswerId);
    const quickAnswers = getState().quickAnswers.data;
    const idx = quickAnswers.findIndex((x) => x.id === quickAnswerId);
    quickAnswers[idx].lastUse = data.lastUse;
    dispatch({ type: GET_QUICK_ANSWERS_SUCCESS, payload: quickAnswers });
  } catch (error) {
    console.log(error);
  }
};
