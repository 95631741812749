import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../../assets/test.json';
import logo from '../../assets/images/logos/logo_white.svg';


const Loader = ({ fit }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: 'plane',
    },
  };

  return (
    <div
      style={{
        width: fit ? '100%' : '100vw',
        height: fit ? '100%' : '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: fit ? 'white' : '#062E93',
      }}
    >
      <img src={logo} width={200} alt="Logo de iVentas" />
    </div>
  );
};

export default Loader;
