/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import {
  isNull,
  find,
  isUndefined,
  findIndex,
  map,
  intersectionWith,
  first,
} from "lodash";

import client from "../apollo/apolloClient";
import API from "../axios";
import ApolloMessages from "../apollo/apolloMessages";
import ApolloConversations from "../apollo/conversations";
import ApolloAdmins from "../apollo/apolloAdmins";
import CONSTANTS from "../const/constants";
import { getLabelMessage } from "../const/labelService";
import {
  SET_CONVERSATION_AS_SELECTED,
  GET_CONVERSATIONS_SUCCESS,
} from "../redux/conversationsDuck";

import ApolloDatasheet from "../apollo/apolloDatasheet";
import { saveTagAction } from "../redux/tagsDuck";
import { useDispatch } from "react-redux";
import { async } from "@firebase/util";
import createTag, { editTag, getTags, upsertTag } from "./tag";
import {
  createDataSheetConfiguration,
  updateVinteInfo,
  upsertClientInformation,
  upsertDatasheetConfiguration,
} from "./datasheet";
import { saveDataSheetFieldAction } from "../redux/dataSheetConfigurationDuck";
import { findAgentByEmail } from "./getAgents";

export const getQueryConversationsFilter = ({
  agents,
  tags,
  channels,
  companyId,
  selectedSpacesIds,
  skip,
  first,
  cleanedName,
  noAnswered,
  unanswered,
  isArchived,
  isArchivedBroadcast,
  isConversationValuable,
  unreadMessages,
  isAgent,
  isVinte,
  groupAdminId
}) => {
  try {
    const finalFilters = {};

    if (agents?.length > 0) {
      const isUnassignedAgentSelected = agents.some(
        (x) => x.id === CONSTANTS.UNASSIGNED_AGENT_ID
      );
      let agentsId = agents
        .filter((x) => x.id !== CONSTANTS.UNASSIGNED_AGENT_ID)
        .map((x) => x.id);
      if (isUnassignedAgentSelected) agentsId = [...agentsId, ""];
      finalFilters.agentIdIn = agentsId;
    }
    if (tags?.length > 0) {
      const tagsIds = map(tags, (tag) => tag.id);
      finalFilters.tagsIdIn = tagsIds;
    }
    if (channels?.length > 0) {
      if (!isVinte || (isVinte && !isAgent)) {
        const channelsNames = map(channels, (channel) => channel.name);
        finalFilters.channelsNames = channelsNames;
      }
    }
    if (companyId) finalFilters.companyId = companyId;
    if (selectedSpacesIds?.length > 0) {
      finalFilters.channelsIds = selectedSpacesIds;
    }

    if (skip) finalFilters.skip = skip;
    if (first) finalFilters.first = first;

    if (cleanedName) finalFilters.cleanedName = cleanedName;
    else finalFilters.cleanedName = null;

    if (noAnswered) finalFilters.answered = false;
    if (isArchived) finalFilters.isArchived = true;
    if (isArchivedBroadcast) finalFilters.isBroadcastMessage = true;
    if (isConversationValuable) finalFilters.isConversationValuable = true;
    if (unanswered) finalFilters.unanswered = false;

    if (unreadMessages) finalFilters.unreadMessages = true;
    if (groupAdminId) finalFilters.groupAdminId = groupAdminId;

    return finalFilters;
  } catch (error) {
    console.log(error);
  }
};

export const getConversations = async (
  {
    priority,
    agents,
    tags,
    channels,
    companyId,
    selectedSpacesIds,
    skip,
    first,
    cleanedName,
    isArchived,
    isArchivedBroadcast,
    isConversationValuable,
    unreadMessages,
    noAnswered,
    unanswered,
    isAgent,
    isVinte,
    groupAdminId,
  },
  userId
) => {
  try {
    const graphQueryFilters = getQueryConversationsFilter({
      priority,
      agents,
      tags,
      channels,
      companyId,
      selectedSpacesIds,
      skip,
      first,
      cleanedName,
      noAnswered,
      unanswered,
      isArchived,
      isArchivedBroadcast,
      isConversationValuable,
      unreadMessages,
      isAgent,
      isVinte,
      groupAdminId
    });
    let fetchedConversations = [];
    if (!cleanedName) {
      if (groupAdminId) {
        console.log('fetching conversations_by_filter')
        const response = await API.post("/conversations_by_filter", {
          query: {
            priority,
            agentId: agents?.map(agent => agent?.id).pop(),
            channelId: channels?.map(channel => channel?.id).pop(),
            companyId,
            skip: skip ?? 0,
            first,
            cleanedName,
            noAnswered,
            unanswered,
            isArchived,
            isArchivedBroadcast,
            isConversationValuable,
            unreadMessages,
            groupAdminId,
            tagsIds: tags?.map(tag => tag?.id),
          }
        });
        console.log('response.data conversations_by_filter',  response.data)
        fetchedConversations = response.data;
      } else {
        const data = await client.query({
          query:
            ApolloConversations.GET_CONVERSATIONS_BY_FILTERS(graphQueryFilters),
          variables: graphQueryFilters,
        });
        fetchedConversations = data?.data?.clients;
      }
    } else {
      console.log("getConversationscleanedName => ", cleanedName);
      let channelsNames;
      if (channels?.length > 0) {
        if (!isVinte || (isVinte && !isAgent)) {
          const getChannelsNames = map(channels, (channel) => channel.name);
          channelsNames = [...getChannelsNames];
        }
      }

      console.log("getConversations channelsNames => ", channelsNames);
      let channelsIds;
      if (selectedSpacesIds?.length > 0) {
        if (!isVinte) graphQueryFilters.channelsIds = selectedSpacesIds;
        if (isVinte && !isAgent)
          graphQueryFilters.channelsIds = selectedSpacesIds;
        if (!isVinte || (isVinte && !isAgent))
          graphQueryFilters.channelsIds = selectedSpacesIds;
        if(isVinte && isAgent)
          graphQueryFilters.channelsIds = selectedSpacesIds;
      }
      console.log("getConversations channelsIds => ", channelsIds);
      const graphQuerySearchFilter = {
        companyId,
        keyword: cleanedName,
        channelsNames,
        channelsIds,
        ...(groupAdminId && { groupAdminId: groupAdminId }),
        agentIdIn: graphQueryFilters?.agentIdIn,
        skip: graphQueryFilters?.skip,
        first: graphQueryFilters?.first,
      };

      if (groupAdminId) {
        console.log('fetching conversations_by_filter')
        const response = await API.post("/conversations_by_filter", {
          query: {
            channelId: channels?.map(channel => channel?.id).pop(),
            companyId,
            skip: skip ?? 0,
            first,
            keyword: cleanedName,
            groupAdminId,
          }
        });
        console.log('searching response.data conversations_by_filter',  response.data)
        fetchedConversations = response.data;
      } else {
        const data = await client.query({
          query: ApolloConversations.GET_CONVERSATIONS_BY_SEARCHING_KEYWORD(
            graphQuerySearchFilter
          ),
          variables: graphQuerySearchFilter,
        });
        console.log("getConversations data => ", data);
        console.log("graphQuerySearchFilter data => ", graphQuerySearchFilter);
  
        fetchedConversations = data?.data?.clients;
      }
      
    }

    const messageIds = fetchedConversations
      .map((x) => x.lastMessageId)
      .filter((y) => !isNull(y) && y !== "");
    const {
      data: { messages: lastMessages },
    } = await client.query({
      query: ApolloMessages.GET_MESSAGES_BY_ID,
      variables: { ids: messageIds },
    });

    const newConversations = [];
    for await (const conversation of fetchedConversations) {
      try {
        const message = find(lastMessages, ["clientId", conversation.id]);
        if (message?.time)
          message.time =
            message.time && parseInt(`${message.time.toString()}000`, 10);
        let agent = { id: null, name: null };
        if (conversation.agent?.id) agent = { ...conversation.agent };

        newConversations.push({
          id: conversation.id,
          platform: conversation.channel?.platform,
          name: conversation.name,
          nameFromChannel: conversation.nameFromChannel,
          key: conversation.key,
          senderId: conversation.senderId,
          cleanedName: conversation.cleanedName,
          imageUrl: conversation.imageUrl,
          accessId: conversation.accessId,
          senderId: conversation.senderId,
          channel: { ...conversation.channel },
          company: { id: companyId },
          hasUpdates: false,
          agent,
          priority: conversation.priority,
          unreadMessages: conversation.unreadMessagesCount,
          tags: conversation.tags,
          lastMessage: !isUndefined(message) ? message : {},
          lastMessageId: conversation.lastMessageId,
          answered: conversation.answered,
          isArchived: conversation.isArchived,
          isBroadcastMessage: conversation.isBroadcastMessage,
          category: conversation.category,
          isConversationValuable: conversation.isConversationValuable,
          setValuableByUser: conversation.setValuableByUser,
          hasFollowUpMessage: conversation.hasFollowUpMessage,
          participants: conversation.participants,
          isGroupMessage: conversation.senderId.includes("@g.us"),
          pageId: conversation.pageId,
          pageToken: conversation.pageToken,
          whatsappStartedAt: conversation.whatsappStartedAt,
          whatsappLastMessageAt: conversation.whatsappLastMessageAt,
          isClosed: conversation.isClosed,
          groupAdminId: conversation.groupAdminId
        });
      } catch (error) {
        console.log(error);
      }
    }

    return newConversations;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setIsArchived = async (id, setValuableIsArchived) => {
  const data = await client.query({
    query: ApolloConversations.SET_IS_ARCHIVED,
    variables: { id, setValuableIsArchived },
  });
  return data;
};

export const getBroadCastConversationsQuery = async (companyId) => {
  const { data } = await client.query({
    query: ApolloConversations.GET_BROADCAST_CONVERSATIONS,
    variables: { companyId },
  });
  return data;
};

export const updateClientInfo = async (clientInfo) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.UPDATE_CLIENT_INFO,
      variables: clientInfo,
    });
    if (data) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const createClientFromPhone = async (
  newClientData,
  agentId,
  importContact = false,
  updateProgress = null,
  companyId = null
) => {
  console.log("newClientData:", newClientData);
  if (importContact) {
    let agentResult = null;
    const { tags: dbTags } = await getTags(newClientData[0]?.data?.companyId);
    const colors = [
      "#f61e44",
      "#a300ff",
      "#00cd7e",
      "#072e93",
      "#ffc730",
      "#cd81fb",
      "#27b1b3",
    ];
    const newClientsDataResult = [];
    const finalStatus = [];
    // const agentName = useSelector((state) => state.user?.data?.name);
    for (const clientData of newClientData) {
      try {
        const { data, customFields } = clientData;
        if (data.statusInfo.status === "error") {
          finalStatus.push(data.statusInfo);
          continue;
        }
        console.log("dataCreateClient:", data);
        console.log("customFields:", customFields);
        const connectedTags = [];
        if (data?.tags) {
          const clientTags = data?.tags?.split(",").map((tag) => tag.trim());
          for await (const tag of clientTags) {
            const isTagInDb = dbTags?.find((t) => t.name === tag);
            if (!isTagInDb?.id) {
              const newTag = await createTag(
                tag,
                colors[Math.floor(Math.random() * colors.length)],
                data.companyId,
                `${data.companyId}-${tag}`
              );
              dbTags.push(newTag);
              connectedTags.push(newTag.id);
            } else {
              console.log("isTagInDb:", isTagInDb);
              console.log("isTagInDb:", isTagInDb.key);
              await editTag(
                isTagInDb.id,
                isTagInDb.name,
                isTagInDb.color,
                isTagInDb?.key ? isTagInDb.key : `${data.companyId}-${tag}`
              );
              connectedTags.push(isTagInDb.id);
            }
          }
        }

        console.log("connectedTags:", connectedTags);

        const responseKey = await client.query({
          query: ApolloConversations.GET_CONVERSATION_BY_KEY,
          variables: { key: data.key },
        });

        //console.log('responseKey => ', responseKey)

        let clientResult = null;
        try {
          if (data?.agentEmail) {
            const agent = await findAgentByEmail(data?.agentEmail);
            agentResult = agent[0];
            console.log("agentResult:", agentResult);
          }
        } catch (error) {
          console.log("ERROR", error);
          continue;
        }
        if (responseKey?.data?.client?.id) {
          clientResult = responseKey?.data?.client;
          finalStatus.push({
            status: "duplicado",
            message: "El contacto ya existe",
            number: data.senderId?.toString(),
          });
        } else {
          const getAgentId = agentResult?.id;
          console.log("getAgentId:", getAgentId);
          const clientData = {
            channel: { connect: { id: data?.channelId } },
            channelId: data.channelId,
            channelIdString: data.channelId,
            senderId: data.senderId?.toString(),
            company: { connect: { id: data.companyId } },
            companyId: data.companyId,
            companyIdString: data.companyId,
            key: data?.channelId + data.senderId,
            cleanedName: data.cleanedName,
            lastUpdate: new Date(),
            isConversationValuable: null,
            agent: { connect: { id: getAgentId ? getAgentId : agentId } },
            agentId: getAgentId ? getAgentId : agentId,
            categoryId: "5ed5b4670274390007afcc59",
            category: { connect: { id: "5ed5b4670274390007afcc59" } },
            name: data.nameFromChannel,
            ...(connectedTags.length > 0 && {
              tags: connectedTags.map((x) => ({ id: x })),
              tagsString: connectedTags?.reduce(
                (acc, tag) => `${acc}-${tag}`,
                ""
              ),
            }),
          };
          console.log("clientData:", clientData);
          const response = await client.mutate({
            mutation: ApolloConversations.CREATE_CLIENT_FROM_PHONE,
            variables: clientData,
          });
          clientResult = response.data.createClient;
          finalStatus.push({
            status: "creado",
            message: "Contacto creado exitosamente",
            number: data.senderId?.toString(),
          });
          console.log("clientResultCreate:", clientResult);
        }

        for await (const customField of customFields) {
          const value = customField.value;
          const fieldName = customField.name
            .trim()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const key = `${data.companyId}-${fieldName}`;

          const res = await client.query({
            query: ApolloDatasheet.GET_DATASHEET_CONFIGURATION_BY_KEY,
            variables: { key },
          });
          const dataSheetByKey = res.data.dataSheetConfigurations;
          console.log("dataSheetByKey:", dataSheetByKey);
          let dataSheet = null;
          // console.log("dataSheetByKey:", dataSheetByKey);

          if (dataSheetByKey.length === 0) {
            const field = { name: fieldName, type: "string", options: [], key };
            dataSheet = await createDataSheetConfiguration(
              field,
              data.companyId
            );
          } else {
            dataSheet = dataSheetByKey[0];
          }
          console.log("dataSheet:", dataSheet);
          console.log("clientResult.id:", clientResult.id);
          console.log("key.key:", key);
          const clientInfoKey = clientResult?.id + dataSheet?.id;
          await upsertClientInformation(
            clientInfoKey,
            clientResult.id,
            dataSheet.id,
            value
          );
        }
        console.log("agentResultFinal:", agentResult);

        newClientsDataResult.push({
          name: data.nameFromChannel,
          lastName:
            data?.nameFromChannel && data?.nameFromChannel?.split(" ")[1],
          phone: data.senderId,
          tags: data.tags,
          agent: agentResult?.name ? agentResult?.name : data.agentName,
          channel: "WhatsApp",
          createdAt: data.createdAt,
          moreInfo: "Additional details...",
          clientInfo: customFields,
        });
        updateProgress((prevProgress) => (prevProgress = prevProgress + 12));
      } catch (error) {
        console.log("ERROR", error);
        continue;
      }
    }

    return { newClientsDataResult, finalStatus };
  } else {
    const { phone, name, companyId, channelId, tags, notes } = newClientData;
    const cleanedName = `${name
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g)
      .replace(/\s/g, "")
      .toLowerCase()} ${phone}`;

    let isUpdatedClient = false;
    try {
      const clientData = {
        senderId: `${phone}`,
        name,
        companyId,
        companyIdString: companyId,
        channelId,
        channelIdString: channelId,
        key: `${channelId + phone}`,
        cleanedName,
        lastUpdate: new Date().toISOString(),
        agentId,
        agentIdString: agentId,
      };

      const responseKey = await client.query({
        query: ApolloConversations.GET_CONVERSATION_BY_KEY,
        variables: { key: `${channelId + phone}` },
      });

      let clientResult = null;

      if (responseKey?.data?.client?.id) {
        clientResult = responseKey?.data?.client;
        isUpdatedClient = true;
      } else {
        const response = await client.mutate({
          mutation: ApolloConversations.CREATE_CLIENT_FROM_PHONE,
          variables: clientData,
        });
        clientResult = response.data.createClient;
        if (companyId === CONSTANTS.VINTE_COMPANY_ID) {
          updateVinteInfo(clientResult.id).then((res) => {
            console.log('updateVinteInfo', res);
          }).catch((error) => {
            console.error('updateVinteInfo', error);
          });
        }
      }
      return { clientResult, isUpdatedClient };
    } catch (error) {
      throw error;
    }
  }
};

export const getPlatformApiFromPhone = async (companyId) => {
  try {
    const response = await client.mutate({
      mutation: ApolloConversations.GET_PLATFORM_API_PHONE,
      variables: { companyId },
    });
    return response.data.channels;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const changeCategory = async (id, categoryId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.CHANGE_CATEGORY_CLIENT,
      variables: { id, categoryId },
    });
    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_REASSIGNING_AGENT");
  }
};

export const sendToCrm = async (clientId) => {
  try {
    const response = await API.post("/sendToCRM", { clientId });
    return response.data;
  } catch (error) {
    console.log(error);
    return { code: 400, description: "Error al enviar información al CRM" };
  }
};

export const getClientInfo = async (clientId) => {
  try {
    const { data } = await client.query({
      query: ApolloConversations.GET_CLIENT_INFO,
      variables: { id: clientId },
    });
    if (data && data.client) {
      return data.client;
    }
    return { error: "Error al obtener información del cliente" };
  } catch (error) {
    return { error };
  }
};

export const connectTag = async (id, tagId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.CONVERSATION_ADD_TAGS,
      variables: { id, tagId },
    });
    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_ADDING_TAG");
  }
};

export const disconnectTag = async (id, tagId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.CONVERSATION_REMOVE_TAGS,
      variables: { id, tagId },
    });

    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_REMOVING_TAG");
  }
};

export const getMessageStatusSubscription = async (companyId) => {
  try {
    const subscriptionMessageStatus = await client.subscribe({
      query: ApolloMessages.MESSAGE_STATUS_SUBSCRIBE,
      variables: companyId,
    });

    return { subscriptionMessageStatus };
  } catch (error) {
    console.error(error);
  }
};

export const addNewConversation = async (
  newConversation,
  conversations,
  currentConversationId,
  updatedFields
) => {
  const updateCounter = updatedFields?.some((x) => x === "lastMessageId");
  const updateConversation = !updatedFields?.length !== 0 || updateCounter;

  const lbls = getLabelMessage([
    "newMessageNotification",
    "newConversationNotification",
  ]);
  const foundConversationIndex = findIndex(conversations, [
    "id",
    newConversation.id,
  ]);

  const { data: lastMessageData } = await client.query({
    query: ApolloMessages.GET_LAST_MESSAGE_BY_CLIENT_ID,
    variables: { clientId: newConversation.id },
  });
  const lastMessages = lastMessageData.messages;

  let unreadMessages = 0;
  if (currentConversationId !== newConversation.id) {
    if (updateCounter) {
      if (
        foundConversationIndex === -1 ||
        !conversations[foundConversationIndex]?.unreadMessages
      ) {
        unreadMessages = 1;
      } else {
        unreadMessages =
          conversations[foundConversationIndex]?.unreadMessages + 1;
      }
    } else {
      unreadMessages = conversations[foundConversationIndex]?.unreadMessages;
    }
  }
  console.log("lastMessageData", lastMessageData);
  const newConversationFormated = {
    id: newConversation.id,
    platform: newConversation.channel?.platform,
    nameFromChannel: newConversation.nameFromChannel,
    imageUrl: newConversation.imageUrl,
    accessId: newConversation.accessId,
    senderId: newConversation.senderId,
    channel: newConversation.channel,
    company: newConversation.company,
    agent: newConversation.agent,
    priority: newConversation.priority,
    unreadMessages,
    tags: newConversation.tags?.length > 0 ? newConversation.tags : [],
    lastMessage: lastMessages.length > 0 ? lastMessages[0] : {},
    answered: newConversation.answered,
    category: newConversation.category,
    hasUpdates:
      updateConversation && currentConversationId !== newConversation.id,
    isConversationValuable: newConversation.isConversationValuable,
    setValuableByUser: newConversation.setValuableByUser,
    hasFollowUpMessage: newConversation.hasFollowUpMessage,
    isGroupMessage: newConversation.senderId.includes("@g.us"),
    pageId: newConversation.pageId,
    pageToken: newConversation.pageToken,
    whatsappStartedAt: newConversation.whatsappStartedAt,
    whatsappLastMessageAt: newConversation.whatsappLastMessageAt,
  };

  if (foundConversationIndex === -1) {
    if (Notification.permission === "granted") {
      const notification = new Notification(lbls.newConversationNotification, {
        body: `${newConversationFormated.nameFromChannel}`,
      });
      setTimeout(notification.close.bind(notification), 5000);
    }
    return {
      foundIndex: -1,
      conversations: [newConversationFormated, ...conversations],
    };
  }

  const newConversations = [...conversations];
  const currentConversation = { ...newConversationFormated };

  if (updateConversation) {
    newConversations.splice(foundConversationIndex, 1);

    if (
      updateCounter &&
      lastMessages?.[0]?.sender === CONSTANTS.CLIENT_SENDER
    ) {
      if (Notification.permission === "granted") {
        const notification = new Notification(lbls.newMessageNotification, {
          body: `${newConversationFormated.nameFromChannel}: ${lastMessages?.[0]?.content}`,
        });
        setTimeout(notification.close.bind(notification), 5000);
      }
    }

    return {
      foundIndex: foundConversationIndex,
      conversations: [newConversationFormated, ...newConversations],
      currentConversation,
    };
  }

  newConversations[foundConversationIndex] = newConversationFormated;
  return {
    foundIndex: foundConversationIndex,
    conversations: newConversations,
    currentConversation,
  };
};

const deleteConversationByIndex = (previousConversations, selectedIndex) => {
  const newConversations = [...previousConversations];
  newConversations.splice(selectedIndex, 1);
  return newConversations;
};

const verifyFilters = (filters, deletedConversation) => {
  if (filters?.agents?.length > 0) {
    const hasConversationAgent = filters.agents.some(
      (x) => x.id === deletedConversation.agent?.id
    );
    if (!hasConversationAgent) return false;
  }

  if (filters?.categories?.length > 0) {
    const hasConversationAgent = filters.categories.some(
      (x) => x.id === deletedConversation.category?.id
    );
    if (!hasConversationAgent) return false;
  }

  if (filters?.companyId) {
    const hasConversationAgent =
      filters.companyId === deletedConversation.company.id;
    if (!hasConversationAgent) return false;
  }

  if (filters?.channels?.length > 0) {
    const hasConversationAgent = filters.channels.some(
      (x) => x.name === deletedConversation.channel?.platform?.name
    );
    if (!hasConversationAgent) return false;
  }

  if (filters?.nameFromChannel) {
    const hasConversationAgent = deletedConversation.nameFromChannel
      .toLowerCase()
      .includes(filters.nameFromChannel.toLowerCase());
    if (!hasConversationAgent) return false;
  }

  if (filters?.priority?.length > 0) {
    let hasConversationAgent = false;
    if (
      filters.priority.includes("high") &&
      deletedConversation.priority <= 0.333
    )
      hasConversationAgent = true;
    else if (
      filters.priority.includes("medium") &&
      deletedConversation.priority <= 0.666
    )
      hasConversationAgent = true;
    else if (
      filters.priority.includes("low") &&
      deletedConversation.priority > 0.666
    )
      hasConversationAgent = true;
    if (!hasConversationAgent) return false;
  }

  if (filters?.selectedSpacesIds?.length > 0) {
    const hasConversationAgent = filters.selectedSpacesIds.some(
      (x) => x === deletedConversation.channel?.id
    );
    if (!hasConversationAgent) return false;
  }

  if (filters?.tags?.length > 0) {
    const deletedConversationIncludesTagsFilter = intersectionWith(
      deletedConversation.tags,
      filters.tags,
      (arrVal, othVal) => arrVal.id === othVal.id
    );
    if (deletedConversationIncludesTagsFilter?.length === 0) return false;
  }

  return true;
};

const updateUnsubscribeQuery = async (currentConversations) => {
  const unassignedAgentObservable = await client.subscribe({
    query: ApolloAdmins.UNSUBSCRIBE_QUERY,
    variables: { ids: currentConversations.map((x) => x.id) },
  });

  return unassignedAgentObservable;
};

export const deleteConversationCallback = async (
  deletedConversation,
  conversations,
  filters
) => {
  const deletedIndex = findIndex(conversations, ["id", deletedConversation.id]);
  if (deletedIndex === -1) return {};

  const conversationFulfillFilters = verifyFilters(
    filters,
    deletedConversation
  );

  if (conversationFulfillFilters) return {};
  const newConversations = deleteConversationByIndex(
    conversations,
    deletedIndex
  );
  const newUnsubscribeQuery = await updateUnsubscribeQuery(newConversations);
  return { newConversations, newUnsubscribeQuery };
};

export const getMessageById = async (messageId) => {
  const { data: messageData } = await client.query({
    query: ApolloMessages.GET_MESSAGE_BY_ID,
    variables: { messageId },
  });
  return messageData.message;
};

export const getConversationByKey = async (key) => {
  try {
    const { data } = await client.query({
      query: ApolloConversations.GET_CONVERSATION_BY_KEY,
      variables: { key },
    });
    console.log("getConversationByKey => ", data);
    return data.client;
  } catch (e) {
    console.log("ERROR getConversationByKey => ", e);
  }
};

export const updateClientUsernameMutation = async (
  id,
  nameFromChannel,
  cleanedName
) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.UPDATE_CLIENT_NAME,
      variables: { id, nameFromChannel, cleanedName },
    });
    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_UPDATING_USER_NAME");
  }
};

export const updateClientPhoneMutation = async (
  id,
  cleanedName,
  key,
  senderId
) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.UPDATE_CLIENT_PHONE,
      variables: { id, cleanedName, key, senderId },
    });
    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_UPDATING_USER_PHONE");
  }
};

export const checkExistedPhone = async (companyId, channelId, phone) => {
  console.log("checkExistedPhone ======> ", companyId, channelId, phone);
  try {
    const { data } = await client.query({
      query: ApolloConversations.CHECK_EXISTED_PHONE,
      variables: { companyId, channelId, phone },
    });
    console.log("checkExistedPhone data =>", data);
    return data.clients;
  } catch (error) {
    console.log("eeeeeeeee => ", error);
    throw new Error("ERROR_AT_CHECKING_EXISTED_PHONE");
  }
};

export const addNewConversationBySubscriptionCallback = async (
  subscriptionClient,
  conversationsData,
  currentConversationId,
  updatedFields,
  userId,
  userRole
) => {
  const nextConversations = await addNewConversation(
    subscriptionClient,
    conversationsData,
    currentConversationId,
    updatedFields,
    userId
  );

  if (
    userRole === CONSTANTS.AGENT_PERMISSION &&
    userId !== subscriptionClient?.agent?.id
  ) {
    return { action: null, newUnsubscribeQuery: null };
  }

  let action = null;
  if (
    subscriptionClient?.id === currentConversationId &&
    nextConversations.currentConversation
  ) {
    nextConversations.conversations[0].selected = true;
    const payload = {
      data: nextConversations.conversations,
      current: nextConversations.currentConversation,
    };
    action = {
      type: SET_CONVERSATION_AS_SELECTED,
      payload: {
        ...payload,
        source: CONSTANTS.CONVERSATIONS_SOURCE_SUBSCRIPTION,
      },
    };
  } else {
    action = {
      type: GET_CONVERSATIONS_SUCCESS,
      payload: {
        conversations: nextConversations.conversations,
        source: CONSTANTS.CONVERSATIONS_SOURCE_SUBSCRIPTION,
      },
    };
  }

  let newUnsubscribeQuery = null;
  if (conversationsData.length < nextConversations.conversations?.length) {
    newUnsubscribeQuery = await updateUnsubscribeQuery(
      nextConversations.conversations
    );
  }

  return { action, newUnsubscribeQuery };
};

export const updateValuableByUserMutation = async (id, value) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloConversations.UPDATE_CONVERSATION_CLASSIFICATION,
      variables: {
        id,
        isConversationValuable: value,
        setValuableByUser: value,
      },
    });
    return data.updateClient;
  } catch (error) {
    throw new Error("ERROR_UPDATING_VALUABLE_CLASSIFICATION");
  }
};

export const deleteConversation = async (data) => {
  try {
    const response = await API.post("/delete-conversation", data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClientReport = async (
  companyId,
  companyName,
  initialDate,
  endDate,
  channelIds,
  filters,
  skip = 0,
  rol,
  userId
) => {
  try {
    const graphQueryFilters = { companyId, initialDate, endDate };
    if (companyName === CONSTANTS.VINTE_COMPANY_NAME && channelIds?.length > 0)
      graphQueryFilters.channelIds = channelIds;

    // if (filters?.isArchived) graphQueryFilters.isArchived = true;
    // if (filters?.noAnswered) graphQueryFilters.answered = false;
    // if (filters?.unread) graphQueryFilters.unreadMessages = true;
    // if (filters?.broadcast) graphQueryFilters.isArchivedBroadcast = true;
    // if (filters?.isConversationValuable)
    //   graphQueryFilters.isConversationValuable = true;
    // if (filters?.agents?.length > 0)
    //   graphQueryFilters.agentIdIn = [...filters.agents];
    // if (filters?.tags?.length > 0)
    //   graphQueryFilters.tagsIdIn = [...filters.tags];

    // let platforms = [];
    // if (filters?.whatsappChannel) {
    //   platforms.push("WHATSAPP");
    // }
    // if (filters?.messengerChannel) {
    //   platforms.push("MESSENGER");
    // }

    // if (platforms.length > 0) {
    //   graphQueryFilters.channelsNames = platforms;
    // }
    graphQueryFilters.skip = skip;
    console.log("graphQueryFiltersskip", graphQueryFilters.skip);
    graphQueryFilters.first = 50;

    if(rol === CONSTANTS.AGENT_PERMISSION || rol === "Agent" || rol === "Agente" || rol === "agente"){
      graphQueryFilters.agentIdIn = [userId];
    }

    console.log("graphQueryFilters", graphQueryFilters);
    const { data: clients } = await client.query({
      query: ApolloConversations.GET_CLIENTS_REPORT(graphQueryFilters),
      variables: graphQueryFilters,
    });

    if (companyName === CONSTANTS.VINTE_COMPANY_NAME) {
      const { data: ds } = await client.query({
        query: ApolloDatasheet.GET_DATASHEET_CONFIGURATION_BY_COMPANY_ID,
        variables: { companyId },
      });
      const plazaDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "plaza"
      );
      const emailDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "correoElectronico"
      );
      const nombreClienteDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "nombreCliente"
      );
      const telefonoOtrosDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "telefonoOtros"
      );

      const keyIn = [];

      if (clients?.clients?.length > 0) {
        clients.clients.forEach((x) => {
          keyIn.push(x.id + plazaDs.id);
          keyIn.push(x.id + emailDs.id);
          keyIn.push(x.id + telefonoOtrosDs.id);
          keyIn.push(x.id + nombreClienteDs.id);
        });

        let clientInfoes = [];
        if (keyIn?.length > 1000) {
          const chunkSize = 1000;
          for (let i = 0; i < keyIn.length; i += chunkSize) {
            const chunk = keyIn.slice(i, i + chunkSize);
            console.log(chunk);
            const { data: clientInfoesData } = await client.query({
              query: ApolloConversations.GET_CLIENT_VINTE_EXTRA_INFO({
                keyIn: chunk,
              }),
              variables: { keyIn: chunk },
            });
            clientInfoes.push(...clientInfoesData.clientInfoes);
          }
        } else {
          const { data: clientInfoesData } = await client.query({
            query: ApolloConversations.GET_CLIENT_VINTE_EXTRA_INFO({ keyIn }),
            variables: { keyIn },
          });
          clientInfoes = [...clientInfoesData.clientInfoes];
          console.log(clientInfoes);
        }
        const clientInfoesSorted = {};
        clientInfoes.forEach((x) => {
          if (clientInfoesSorted[x.clientId]) {
            clientInfoesSorted[x.clientId] = {
              ...clientInfoesSorted[x.clientId],
              [x.fieldName.id]: x.value,
            };
          } else {
            clientInfoesSorted[x.clientId] = { [x.fieldName.id]: x.value };
          }
        });
        return clients?.clients?.map((client) => {
          const c = {
            ...client,
          };
          if (clientInfoesSorted[client.id]?.[nombreClienteDs.id]) {
            c.name = clientInfoesSorted[client.id]?.[nombreClienteDs.id];
          }
          if (clientInfoesSorted[client.id]?.[emailDs.id]) {
            c.correoElectronico = clientInfoesSorted[client.id]?.[emailDs.id];
          }
          if (clientInfoesSorted[client.id]?.[plazaDs.id]) {
            c.plaza = clientInfoesSorted[client.id]?.[plazaDs.id];
          }
          if (clientInfoesSorted[client.id]?.[telefonoOtrosDs.id]) {
            c.number =
              clientInfoesSorted[client.id]?.[telefonoOtrosDs.id] ||
              client.senderId;
          }
          return c;
        });
      }
    }

    return clients.clients;
  } catch (error) {
    console.log(error);
    throw new Error("ERROR_GETTING_REPORT");
  }
};
export const getClientReportToExport = async (
  filterContactsBy,
  companyId,
  companyName,
  initialDate,
  endDate,
  channelsIds,
  keyword,
  tagsIds,
  agentId
) => {
  try {
    const graphQueryFilters = {
      filterContactsBy,
      companyId,
      createdAtFrom: initialDate,
      createdAtTo: endDate,
      keyword, tagsIds,
      channelsIds,
      agentId: agentId === 'Todos' ? null : agentId
    };
    // if (companyName === CONSTANTS.VINTE_COMPANY_NAME && channelIds?.length > 0)
    //   graphQueryFilters.channelIds = channelIds;

    console.log("graphQueryFilters", graphQueryFilters);
    // const { data: clients } = await client.query({
    //   query: ApolloConversations.GET_CLIENTS_REPORT(graphQueryFilters),
    //   variables: graphQueryFilters,
    // });

    const response = await API.post("/conversations_by_filter", {
      query: {
        ...graphQueryFilters,
        skip: 0,
        first: 5000,
        custom: true,
      }
    });

    const clients = { clients: response?.data }

    if (companyName === CONSTANTS.VINTE_COMPANY_NAME) {
      const { data: ds } = await client.query({
        query: ApolloDatasheet.GET_DATASHEET_CONFIGURATION_BY_COMPANY_ID,
        variables: { companyId },
      });
      const plazaDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "plaza"
      );
      const emailDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "correoElectronico"
      );
      const nombreClienteDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "nombreCliente"
      );
      const telefonoOtrosDs = ds?.dataSheetConfigurations.find(
        (x) => x.name === "telefonoOtros"
      );

      const keyIn = [];

      if (clients?.clients?.length > 0) {
        clients.clients.forEach((x) => {
          keyIn.push(x.id + plazaDs.id);
          keyIn.push(x.id + emailDs.id);
          keyIn.push(x.id + telefonoOtrosDs.id);
          keyIn.push(x.id + nombreClienteDs.id);
        });

        let clientInfoes = [];
        if (keyIn?.length > 1000) {
          const chunkSize = 1000;
          for (let i = 0; i < keyIn.length; i += chunkSize) {
            const chunk = keyIn.slice(i, i + chunkSize);
            console.log(chunk);
            const { data: clientInfoesData } = await client.query({
              query: ApolloConversations.GET_CLIENT_VINTE_EXTRA_INFO({
                keyIn: chunk,
              }),
              variables: { keyIn: chunk },
            });
            clientInfoes.push(...clientInfoesData.clientInfoes);
          }
        } else {
          const { data: clientInfoesData } = await client.query({
            query: ApolloConversations.GET_CLIENT_VINTE_EXTRA_INFO({ keyIn }),
            variables: { keyIn },
          });
          clientInfoes = [...clientInfoesData.clientInfoes];
          console.log(clientInfoes);
        }
        const clientInfoesSorted = {};
        clientInfoes.forEach((x) => {
          if (clientInfoesSorted[x.clientId]) {
            clientInfoesSorted[x.clientId] = {
              ...clientInfoesSorted[x.clientId],
              [x.fieldName.id]: x.value,
            };
          } else {
            clientInfoesSorted[x.clientId] = { [x.fieldName.id]: x.value };
          }
        });
        return clients?.clients?.map((client) => {
          const c = {
            ...client,
          };
          if (clientInfoesSorted[client.id]?.[nombreClienteDs.id]) {
            c.name = clientInfoesSorted[client.id]?.[nombreClienteDs.id];
          }
          if (clientInfoesSorted[client.id]?.[emailDs.id]) {
            c.correoElectronico = clientInfoesSorted[client.id]?.[emailDs.id];
          }
          if (clientInfoesSorted[client.id]?.[plazaDs.id]) {
            c.plaza = clientInfoesSorted[client.id]?.[plazaDs.id];
          }
          if (clientInfoesSorted[client.id]?.[telefonoOtrosDs.id]) {
            c.number =
              clientInfoesSorted[client.id]?.[telefonoOtrosDs.id] ||
              client.senderId;
          }
          return c;
        });
      }
    }

    return clients.clients;
  } catch (error) {
    console.log(error);
    throw new Error("ERROR_GETTING_REPORT");
  }
};

export const getConversationDataByKey = async (key) => {
  try {
    const { data } = await client.query({
      query: ApolloConversations.GET_CONVERSATION_DATA_BY_KEY,
      variables: { key },
    });
    return data.client;
  } catch (e) {
    console.log("ERROR getConversationByKey => ", e);
  }
};
