import React from "react";

import { TableWrapper, TableTh, TableTd } from "./AnalyticsTable.style";

const AnalyticsTable = ({ data = [] }) => {
  return (
    <TableWrapper>
      <tr>
        <TableTh>Agente</TableTh>
        <TableTh>Conversaciones totales</TableTh>
        <TableTh>Chats respondidos</TableTh>
        <TableTh>Chats no respondidos</TableTh>
        <TableTh>Tiempo de respuesta (minutos)</TableTh>
        <TableTh>Calificación</TableTh>
      </tr>
      {data?.map((x) => (
        <tr>
          <TableTd>{x.name}</TableTd>
          <TableTd>{x.totalMessages}</TableTd>
          <TableTd>{x.answeredChats}</TableTd>
          <TableTd>{x.unansweredChats}</TableTd>
          <TableTd>{Math.floor(x.timeToResponse)}</TableTd>
          <TableTd>{Math.floor(x.rating)}</TableTd>
        </tr>
      ))}
    </TableWrapper>
  );
};

export default AnalyticsTable;
