import styled, { css } from "styled-components";

export const DragWrapper = styled.div`
  margin-bottom: 1rem;

  ${(props) =>
    props.fullSize &&
    css`
      width: 100%;
      height: 100%;
    `}
  @media (max-width: 1400px) {
    font-size: 2.5rem;
  }

  ${(props) =>
    props.importContacts &&
    css`
      margin-bottom: 0;
    `}
  ${(props) =>
    props.templateMedia &&
    css`
      margin-top: 1.375rem;
      height: 100%;
    `}
  ${(props) =>
    props.doc &&
    css`
      height: 17.5rem;
    `}
`;

export const DragZoneContainer = styled.div`
  display: flex;
  ${(props) =>
    props.fullSize
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: max-content;
        `}
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => {
    if (props.isDragAccept) return props.theme.primary;
    if (props.isDragReject) return "red";
    if (props.showBorder) return "rgba(0, 0, 0, 0.1)";
    return "transparent";
  }};
  border-style: dashed;
  transition: border 0.24s ease-in-out;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.importContacts &&
    css`
      width: 13.5rem;
      height: 2.75rem;
      color: white;

      margin-right: 2.063rem;
      background: #128c7e;
      border-radius: 4px;
    `}
  ${(props) =>
    props.templateMedia &&
    css`
      border: 2px dashed #a5a4a5;
      border-radius: 4px;
      width: 28rem;
      height: 17.25rem;
    `}
`;

export const Thumb = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
`;

export const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export const Label = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.6rem;
  ${(props) =>
    props.newTheme
      ? css`
          color: ${props.theme.new.secondaryText};
        `
      : css`
          opacity: 0.5;
        `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(props) =>
    props.fullSize &&
    css`
      align-items: center;
      width: 100%;
      margin-left: 0;
    `}
  margin-left: 1rem;
  ${(props) =>
    props.importContacts &&
    css`
      align-items: center;
      width: 100%;
      margin-left: 0;
    `}
  ${(props) =>
    props.templateMedia &&
    css`
      margin-left: 0;
      padding: 1rem;
    `}
`;

export const MainText = styled.div`
  font-family: SpaceGrotesk-Bold;
  font-size: 0.9rem;
  color: ${(props) =>
    props.newTheme ? props.theme.new.mainText : props.theme.primary};
  margin-bottom: 0.6rem;
  text-decoration: underline;

  ${(props) =>
    props.importContacts &&
    css`
      text-decoration: none;
      margin-bottom: 0;
      color: white;
      font-weight: 700;
      font-size: 1rem;
      line-height: 34px;
    `}
  ${(props) =>
    props.templateMedia &&
    css`
      font-family: "SpaceGrotesk-Medium";
      font-style: normal;
      font-size: 1rem;
      line-height: 150%;
      /* or 24px */

      text-align: center;
      letter-spacing: -0.257238px;
      text-decoration-line: underline;

      color: #128c7e;
    `}
`;

export const SecondaryText = styled.div`
  font-size: 0.75rem;
  color: ${(props) =>
    props.newTheme ? props.theme.new.secondaryText : "black"};
  opacity: 0.5;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;
export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconText = styled.span`
  font-family: SpaceGrotesk-SemiBold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */

  text-align: center;
  letter-spacing: -0.257238px;

  color: #2e3353;
  margin-top: 7px;
`;
export const PreviewContainer = styled.div`
  width: 27.938rem;
  height: ${(props) => (!props.isSelectTemplate ? "100%" : "98%")};
  display: flex;
  border: 1px solid #a5a4a5;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
`;
export const PreviewHeader = styled.header`
  width: 25.688rem;
  height: ${(props) => (props.image ? "10.125rem" : "14rem")};
  background-color: #f5f4f6;
  margin-top: 0.75rem;
  border-radius: 4px;
  max-height: 260px;
  overflow: hidden;

  ${(props) =>
    props.doc &&
    css`
      height: 10.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  ${(props) =>
    props.messagePreview &&
    css`
      width: 100%;
      height: 12rem;
      background-color: #e3e3e3;
    `}

    @media (min-width: 1400px) {
    ${(props) =>
      props.messagePreview &&
      css`
        width: 100%;
        height: 10rem;
        background-color: #e3e3e3;
      `}
  }
`;
export const PreviewBody = styled.div`
  width: 25.688rem;
  position: relative;
  display: flex;
  margin-top: 1.25rem;
`;
export const PreviewIconContainer = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
`;
export const PreviewIcon = styled.img`
  width: 100%;
`;

export const PreviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const PreviewDetailsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  flex-direction: column;
  justify-content: end;
`;
export const PreviewTitle = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */
  letter-spacing: -0.257238px;
  width: ${(props) => (props.wrap ? "80%" : "100%")};
  color: #2e3353;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const PreviewFileType = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */
  letter-spacing: -0.257238px;

  color: #757575;
`;
export const PreviewDeleteFile = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */
  letter-spacing: -0.257238px;
  text-decoration-line: underline;
  color: #f61e44;
  cursor: pointer;
`;
export const PreviewDetailsFile = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  /* or 21px */
  letter-spacing: -0.257238px;

  color: #757575;
  margin-top: 0.625rem;
  text-align: right;
`;
