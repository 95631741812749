import helpCenterIconW from "../assets/svg/ayuda.svg";
import helpCenterIconA from "../assets/svg/ayudaA.svg";
import chatgIconI from '../assets/svg/chat.svg';
import chatgIconA from '../assets/sidebar/chat-active.svg';
import multimedia from '../assets/sidebar/multimediaIcon.svg';
import multimediaA from '../assets/sidebar/multimediaIconA.svg';
import quickAnswers from '../assets/sidebar/respuestas.svg';
import quickAnswersA from '../assets/sidebar/respuestasA.svg';
import spacesIcon from '../assets/sidebar/spaces.svg';
import spacesIconA from '../assets/sidebar/spacesA.svg';
import settingsIcon from '../assets/sidebar/config.svg';
import settingsIconA from '../assets/sidebar/configA.svg';
import usersIcon from '../assets/sidebar/contactos.svg';
import usersIconA from '../assets/sidebar/contactosA.svg';
import robotSolid from "../assets/svg/botIcon.svg";
import robotSolidA from "../assets/svg/botIconA.svg";
import broadcastIcon from '../assets/svg/campañas.svg';
import broadcastIconA from '../assets/svg/campañasA.svg';

import analyticsUnselectedIcon from '../assets/sidebar/estadísticas.svg';
import analyticsUnselectedIconA from '../assets/sidebar/estadísticasA.svg';

import CONSTANTS from './constants';

const links = [
  {
    routes: ["/chat"],
    activeIcon: chatgIconA,
    inactiveIcon: chatgIconI,
    name: "Chat",
    permissions: ["readChat", "agent"],
  },
  {
    routes: ["/contacts"],
    activeIcon: usersIconA,
    inactiveIcon: usersIcon,
    name: "Contactos",
    permissions: ["agent"],
  },
  {
    routes: [
      CONSTANTS.MULTIMEDIA_ROUTE,
      CONSTANTS.ALBUM_ROUTE,
      CONSTANTS.SUBALBUM_ROUTE,
    ],
    activeIcon: multimediaA,
    inactiveIcon: multimedia,
    name: "Multimedia",
    permissions: ["readMultimedia", "writeMultimedia"],
  },
  {
    routes: [
      CONSTANTS.QUICK_ANSWERS_ROUTE,
      CONSTANTS.QUICK_ANSWERS_ACTION_ROUTE,
    ],
    activeIcon: quickAnswersA,
    inactiveIcon: quickAnswers,
    name: "Respuestas",
    permissions: ["readQuickAnswers", "writeQuickAnswers"],
  },
  {
    routes: ["/users", "/users/new"],
    activeIcon: usersIconA,
    inactiveIcon: usersIcon,
    name: "Equipo",
    permissions: ["readUsers", "writeUsers"],
  },
  {
    routes: ["/bot"],
    activeIcon: robotSolidA,
    inactiveIcon: robotSolid,
    name: "Bot",
    permissions: ["readBot", "writeBot"],
  },
  {
    routes: ["/broadcast/table"],
    activeIcon: broadcastIconA,
    inactiveIcon: broadcastIcon,
    name: "Campañas",
    permissions: ["campaigns"],
  },
  {
    routes: ["/analytics"],
    activeIcon: analyticsUnselectedIconA,
    inactiveIcon: analyticsUnselectedIcon,
    name: "Estadísticas",
    permissions: ["readAnalytics"],
  },
  {
    routes: ["/spaces", "spaces/new"],
    activeIcon: spacesIconA,
    inactiveIcon: spacesIcon,
    name: "Canales",
    permissions: ["readSpaces", "writeSpaces"],
  },
  {
    routes: [
      "/config",
      "/config/new-tag",
      "/config/new-rol",
      "/config/new-datasheet",
      "/config/edit-plan",
      "/config/edit-card",
      "/config/album",
    ],
    activeIcon: settingsIconA,
    inactiveIcon: settingsIcon,
    name: "Configuración",
    permissions: [
      "readCompany",
      "writeCompany",
      "readPayments",
      "writePayments",
      "readTags",
      "writeTags",
      "readRols",
      "writeRols",
      "readDataSheetConfiguration",
      "writeDataSheetConfiguration",
    ],
  },
  {
    routes: ["/account", CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE],
    activeIcon: usersIconA,
    inactiveIcon: usersIcon,
    name: "Cuenta",
    permissions: [
      "readCompany",
      "writeCompany",
      "readPayments",
      "writePayments"
    ],
  },
  {
    routes: ["/help"],
    activeIcon: helpCenterIconA,
    inactiveIcon: helpCenterIconW,
    name: "Ayuda",
    permissions: ["readCompany", "writeCompany"],
  },
];

export default links;
