import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  Grid,
  Row,
  Column,
  StatCard,
  StatCardColumn,
  SvgIcon,
  StatCardRow,
  StatCardTile,
  StatCardContent,
  MainRow,
  RowFullHeight,
} from "./BroadcastView.styles";
import BroadcastTable from "./BroadcastTable.component";
import creditStarIcon from "../../../assets/svg/credit-star-icon.svg";
import helpIcon from "../../../assets/svg/help-icon.svg";
import infoIcon from "../../../assets/svg/info-icon.svg";
import BroadcastSelectTagView from "./BroadcastSelectTagView";
import BroadcastType from "./BroadcastType.component";
import BroadcastSelectTemplate from "./BroadcastSelectTemplate";
import BroadcastName from "./BroadcastName";
import BroadcastSelectDate from "./BroadcastSelectDate";
import BroadcastPreview from "./BroadcastPreview";
import BroadcastPayment from "./BroadcastPayment";
import BroadcastAnalitycs from "./BroadcastAnalitycs";
import BroadcastSelectMultimedia from "./BroadcastSelectMultimedia";
import BroadcastCreateTag from "./BroadcastCreateTag";
import { get360StatusAccount } from "../../../utils/broadcast";
import LoaderComponent from "../../../components/loader/Loader.component";
import { useHistory } from "react-router-dom";

const qualityRating = {
  High: "Alta",
  Medium: "Media",
  Low: "Baja",
  NA: "NA",
};

const currentLimit = {
  TIER_1K: "1,000",
  TIER_10K: "10,000",
  TIER_100K: "100,000",
  "TIER_0.25K": "250",
  NA: "NA",
};

const qualityColor = {
  High: "#128C7E",
  Medium: "#FF9518",
  Low: "#F61E44",
};

const BroadcastView = (props) => {
  console.log(props?.computedMatch?.params?.currentStep);
  const history = useHistory();
  const waChannels = useSelector(
    (state) => state.channels.data.whatsappChannels
  );
  const channelId = useSelector((state) => state.campaign.create.channelId);

  const [selectedChannel, setSelectedChannel] = useState();
  const [isWhatsappTemplateMedia, setIsWhatsappTemplateMedia] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [accountStatus, setAccountStatus] = useState({
    current_limit: "NA",
    current_quality_rating: "NA",
  });

  useEffect(() => {
    if (waChannels?.length) {
      setSelectedChannel(waChannels[0]);
      fetch360StatusAccount(waChannels[0]);

    }
  }, [waChannels]);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);

  const fetch360StatusAccount = async (ch) => {
    try {
      const response = await get360StatusAccount(ch?.providerId);
      console.log("fetch360StatusAccount => ", response);
      setAccountStatus(response);
    } catch (e) {
      console.log("ERROR AT FETCHING 360 STATUS => ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid>
      <Row>
        <Column>
          <StatCard>
            <StatCardColumn>
              <SvgIcon src={creditStarIcon} size={36} />
            </StatCardColumn>
            <StatCardColumn>
              <StatCardRow>
                <StatCardTile>Calidad de tu cuenta</StatCardTile>
              </StatCardRow>
              <StatCardRow>
                <StatCardContent
                  color={
                    qualityColor[accountStatus["current_quality_rating"]] ||
                    "#128C7E"
                  }
                >
                  {isLoading && <LoaderComponent width="22" height="22" />}
                  {!isLoading &&
                    (qualityRating[accountStatus["current_quality_rating"]] ||
                      "Baja")}
                </StatCardContent>
              </StatCardRow>
            </StatCardColumn>
            <StatCardColumn>
              <SvgIcon src={helpIcon} size={14} />
            </StatCardColumn>
          </StatCard>
        </Column>
        <Column>
          <StatCard>
            <StatCardColumn>
              <SvgIcon src={infoIcon} size={36} />
            </StatCardColumn>
            <StatCardColumn>
              <StatCardRow>
                <StatCardTile>Límite de envío diario</StatCardTile>
              </StatCardRow>
              <StatCardRow>
                <StatCardContent>
                  {isLoading && <LoaderComponent width="22" height="22" />}
                  {!isLoading && (
                    <>
                      <StatCardContent color="#128C7E">
                        {currentLimit[accountStatus["current_limit"]] || "250"}
                      </StatCardContent>
                      &nbsp;mensajes
                    </>
                  )}
                </StatCardContent>
              </StatCardRow>
            </StatCardColumn>
            <StatCardColumn>
              <SvgIcon src={helpIcon} size={14} />
            </StatCardColumn>
          </StatCard>
        </Column>
      </Row>
      {props?.computedMatch?.params?.currentStep === "table" && (
        <RowFullHeight>
          <BroadcastTable
            qualityRating={qualityRating[selectedChannel?.qualityRating]}
          />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "type" && (
        <RowFullHeight>
          <BroadcastType />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "tags" && (
        <RowFullHeight>
          <BroadcastSelectTagView
            accountLimitOfMessages={accountStatus["current_limit"] || 250}
          />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "selectTemplate" && (
        <RowFullHeight>
          <BroadcastSelectTemplate />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "selectDate" && (
        <RowFullHeight>
          <BroadcastSelectDate />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "preview" && (
        <RowFullHeight>
          <BroadcastPreview />
        </RowFullHeight>
      )}
       {props?.computedMatch?.params?.currentStep === "pricing" && (
        <RowFullHeight>
          <BroadcastPayment accountLimitOfMessages={accountStatus["current_limit"] || 250}/>
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "createTag" && (
        <RowFullHeight>
          <BroadcastCreateTag />
        </RowFullHeight>
      )}
      {props?.computedMatch?.params?.currentStep === "name" && (
        <RowFullHeight>
          <BroadcastName
            accountLimitOfMessages={accountStatus["current_limit"] || 250}
          />
        </RowFullHeight>
      )}
      <Row>
        {props?.computedMatch?.params?.currentStep === "selectMultimedia" && (
          <BroadcastSelectMultimedia />
        )}
      </Row>
      <Row>
        {props?.computedMatch?.params?.currentStep === "analitycs" && (
          <BroadcastAnalitycs />
        )}
      </Row>
    </Grid>
  );
};

export default BroadcastView;
