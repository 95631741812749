import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Esto es necesario para que el modal funcione correctamente

const ImportModal = ({ isOpen, onRequestClose, importResults }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Import Modal"
    >
      <h2>Resultados de Importación</h2>
      <div>
        <table>
          <thead>
            <tr>
              <th>Número</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {importResults.map((result, index) => (
              <tr key={index}>
                <td>{result.number}</td>
                <td>{result.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={onRequestClose}>Cerrar</button>
    </Modal>
  );
};

export default ImportModal;
