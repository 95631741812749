import gql from "graphql-tag";

const GET_CAMPAIGNS = gql`
  query getCampaigns($companyId: String!) {
    campaigns(where: { companyId: $companyId, createdAt_gt: "2023-04-24" }) {
      id
      name
      selectedTags {
        name
      }
      status
      lastSendAt
      createdAt
    }
  }
`;

const GET_CAMPAIGNS_BY_CHANNEL_ID = gql`
  query getCampaigns($channelId: String!) {
    campaigns(where: { channelId: $channelId, createdAt_gt: "2023-04-24" }, orderBy: createdAt_DESC) {
      id
      name
      selectedTags {
        name
      }
      sendAt
      status
      lastSendAt
      createdAt
    },
  }
`;

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(where: { id: $id }) {
      id
    }
  }
`;

const ApolloCampaign = {
  GET_CAMPAIGNS,
  DELETE_CAMPAIGN,
  GET_CAMPAIGNS_BY_CHANNEL_ID,
};

export default ApolloCampaign;
