import { remove, cloneDeep } from "lodash";

import { createTag, getTags, deleteTag, editTag } from "../utils/tag";
import { clientTagAction } from "./conversationsDuck";

const TAGS_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  selected: {},
};

const GET_TAGS_SUCESS = "GET_TAGS_SUCESS";
const GET_TAGS = "GET_TAGS";
const SET_SELECTED_TAG = "SET_SELECTED_TAG";
const UPDATE_CLIENT_TAG = "UPDATE_CLIENT_TAG";
const UPDATE_CLIENT_TAG_END = "UPDATE_CLIENT_TAG_END";

export default function tagsReducer(state = TAGS_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TAGS:
      return { ...state, fetching: true };
    case GET_TAGS_SUCESS:
      return { ...state, fetching: false, data: payload };
    case SET_SELECTED_TAG:
      return { ...state, selected: payload };
    case UPDATE_CLIENT_TAG:
      return { ...state, fetching: true };
    case UPDATE_CLIENT_TAG_END:
      return { ...state, fetching: false };
    default:
      return state;
  }
}

export const saveTagAction = (name, color) => async (dispatch, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const key = `${companyId}-${name}`;
    await createTag(name, color, companyId, key);
  } catch (error) {
    throw error;
  }
};

export const getTagsByCompanyAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_TAGS });
    const companyId = getState().user.data.company.id;
    const { tags } = await getTags(companyId);
    dispatch({ type: GET_TAGS_SUCESS, payload: cloneDeep(tags) });
    return cloneDeep(tags);
  } catch (error) {
    throw error;
  }
};

export const getAllTagsByCompany = () => async (dispatch, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const { tags } = await getTags(companyId);    
    return tags;
  } catch (error) {
    throw error;
  }
};

export const eraseTagAction = (id) => async (dispatch, getState) => {
  try {
    const response = await deleteTag(id);
    const newTags = getState().configurations.tags;
    remove(newTags, ["id", response.id]);
    dispatch({ type: GET_TAGS_SUCESS, payload: newTags });
  } catch (error) {
    throw error;
  }
};

export const setSelectedTagAction = (tag) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELECTED_TAG, payload: tag });
  } catch (error) {
    throw error;
  }
};

export const editTagAction =
  (id, name, color) => async (dispatch, getState) => {
    try {
      const companyId = getState().user.data.company.id;
      const key = `${companyId}-${name}`;
      await editTag(id, name, color, key);
      const tags = await getTags(companyId);
      dispatch({ type: GET_TAGS_SUCESS, payload: tags });
    } catch (error) {
      throw error;
    }
  };

export const AddTagToClient = (clientId, tag, add) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CLIENT_TAG });
    await dispatch(clientTagAction(clientId, tag, add));
    dispatch({ type: UPDATE_CLIENT_TAG_END });
  } catch (error) {
    dispatch({ type: UPDATE_CLIENT_TAG_END });
    throw error;
  }
};
