/* eslint-disable import/no-cycle */
import * as _ from 'lodash';
// import * as Sentry from "@sentry/react";

import {
  login,
  verifyToken,
  register,
  updateCompany,
  updateCompanyV2,
  updateLocations,
  verifyCodeEmail,
  sendEmailVerification,
  getTwoFactor,
} from '../utils/userAuth';
import { getLocationsAction } from './locationsDuck';
import { setFilterTypeAction } from './filtersDuck';
import { getChannelsAction } from './channelsDuck';
import { getSpacesAction } from './spacesDuck';
import { getPaymentCustomerAction } from './paymentDuck';
import { getTokenFirebase } from '../utils/FCMInit';
import CONSTANTS from '../const/constants';
import PlanStatus from '../const/planStatus';

const USER_INITIAL_STATE = {
  loginLoading: false,
  data: {},
  loginError: null,
  registerLoading: false,
  registerError: null,
  validatingToken: false,
  loadingData: false,
  loadingDataError: false,
};

// constants
const LOGIN = 'LOGIN';
const LOGIN_ERROR = 'LOGIN_ERROR';
const SET_USER = 'SET_USER';
const CLEAR_DATA = 'CLEAR_DATA';
const REGISTER = 'REGISTER';
const REGISTER_ERROR = 'REGISTER_ERROR';
const VERIFY_TOKEN = 'VERIFY_TOKEN';
const REGISTER_END = 'REGISTER_END';
const SET_INSTANCE_COUNT = 'SET_INSTANCE_COUNT';
const SET_FIRST_FACTOR = 'SET_FIRST_FACTOR';
const SET_IS_AGENT_NAME_IN_MESSAGE_ACTIVE = 'SET_IS_AGENT_NAME_IN_MESSAGE_ACTIVE';
const SET_COMPANY_UPDATE = 'SET_COMPANY_UPDATE';
const LOAD_LOGIN_DATA = 'LOAD_LOGIN_DATA';
const LOAD_LOGIN_DATA_FINISHED = 'LOAD_LOGIN_DATA_FINISHED';
const LOAD_LOGIN_DATA_ERROR = 'LOAD_LOGIN_DATA_ERROR';

// reducer
export default function userReducer(state = USER_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return { ...state, loginLoading: true, validatingToken: false };
    case SET_FIRST_FACTOR:
      return { ...state, loginLoading: false, validatingToken: false, data: payload };
    case LOGIN_ERROR:
      return {
        ...state,
        loginLoading: false,
        loginError: payload,
        validatingToken: false,
      };
    case LOAD_LOGIN_DATA:
      return { ...state, loadingData: true };
    case LOAD_LOGIN_DATA_FINISHED:
      return { ...state, loadingData: false };
    case LOAD_LOGIN_DATA_ERROR:
      return { ...state, loadingData: false, loadingDataError: true };
    case SET_USER:
      return {
        ...state,
        loginLoading: false,
        registerLoading: false,
        validatingToken: false,
        loginError: null,
        registerError: null,
        data: payload,
      };
    case CLEAR_DATA:
      return { ...USER_INITIAL_STATE };
    case REGISTER:
      return { ...state, registerLoading: true };
    case REGISTER_END:
      return { ...state, registerLoading: false };
    case REGISTER_ERROR:
      return {
        ...state,
        registerLoading: false,
        registerError: payload,
        validatingToken: false,
      };
    case VERIFY_TOKEN:
      return { ...state, validatingToken: true };
    case SET_INSTANCE_COUNT:
      return { ...state, data: payload };
    case SET_IS_AGENT_NAME_IN_MESSAGE_ACTIVE:
      return { ...state, data: payload };
    case SET_COMPANY_UPDATE:
        return { ...state, data: payload };
    default:
      return state;
  }
}

// actions
export const loginUserAction = (userLoginData) => async (dispatch) => {
  try {
    await dispatch({ type: LOGIN, payload: null });
    let firebaseToken = null;
    try {
      firebaseToken = await getTokenFirebase();
    } catch (error) {
      console.log('error getTokenFirebase', error);
    }
    console.log("LOGIN USER DATA", userLoginData)
    const token = await login(userLoginData, firebaseToken);
    if (token) {
      const response = await verifyToken(token);
      if (response.id) {        
        const payload = { ...response };

        const newPermissions = {};
        response.rol.permissions.forEach((i) => {
          newPermissions[i.name] = true;
          return i;
        });
        if (newPermissions.readUsers) {
          await dispatch(setFilterTypeAction('all'));
        }
        // console.log('setting sentry params')
        // Sentry.setUser({ email: payload?.email, id: payload?.id })

        payload.permissions = newPermissions;
        await dispatch({ type: SET_USER, payload });

        dispatch(getSpacesAction(response.spaces));

        if (response.company?.status === PlanStatus.AWAITING_FIRST_PAYMENT) {
          return CONSTANTS.FIRST_PAYMENT_ROUTE;
        }
        if (response.company?.status === PlanStatus.AWAITING_WA_VINCULATION) {
          return CONSTANTS.LINK_WHATSAPP_ROUTE;
        }
        if (response.company?.status === PlanStatus.PAUSED || response.company?.status === PlanStatus.INACTIVE) {
          return CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE;
        }
      } else {
        await dispatch({ type: LOGIN_ERROR, payload: 'Error getting user info' });
      }
    } else {
      await dispatch({ type: LOGIN_ERROR, payload: 'null token' });
    }
    return '/chat';
  } catch (error) {
    console.error(error);
    await dispatch({ type: LOGIN_ERROR, payload: error.response ? error.response.data : 'Error' });
    throw error;
  }
};
export const getTwoFactorAction = (userLoginData) => async (dispatch) => {
  try {
    let firebaseToken = null;
    try {
      firebaseToken = await getTokenFirebase();
    } catch (error) {
      console.log("error getTokenFirebase", error);
    }
    const {factor, userEmail} = await getTwoFactor(userLoginData, firebaseToken);

    console.log("factor", factor);
    if (factor.status === "unverified") return "/qr-code";
    return "/two-factor";
  } catch (error) {
    console.error(error);
    await dispatch({
      type: LOGIN_ERROR,
      payload: error.response ? error.response.data : "Error",
    });
    throw error;
  }
};

export const setInstancesCountAction = (instanceCount) => async (dispatch, getState) => {
  const { data } = getState().user;
  const { company } = data;
  company.whatsappInstancesCount = instanceCount;
  data.company = _.cloneDeep(company);
  const newData = _.cloneDeep(data);
  dispatch({ type: SET_INSTANCE_COUNT, payload: newData });
};

export const setisAgentNameInMessageActiveAction = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.isAgentNameInMessageActive = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({ isAgentNameInMessageActive: isActive, id: company.id });
    await dispatch({ type: SET_IS_AGENT_NAME_IN_MESSAGE_ACTIVE, payload: newData });
  } catch (e) {
    console.log('setisAgentNameInMessageActiveAction ERROR => ', e);
  }
};
export const setcanUnarchiveConversations = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.canUnarchiveConversations = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      canUnarchiveConversations: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log('setisAgentNameInMessageActiveAction ERROR => ', e);
  }
};
export const canReturnInboxConversation = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.canReturnInboxConversation = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      canReturnInboxConversation: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log("setisAgentNameInMessageActiveAction ERROR => ", e);
  }
};

export const isBotAutoAssignActive = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.isBotAutoAssignActive = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      isBotAutoAssignActive: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log("setisBotAutoAssignActive ERROR => ", e);
  }
};
export const isBotAutoAssignActiveAfterDay = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.isBotAutoAssignActiveAfterDay = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      isBotAutoAssignActiveAfterDay: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
    return
  } catch (e) {
    console.log("setisBotAutoAssignActive ERROR => ", e);
  }
};
export const setAutomaticAssignment = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.automaticAssignment = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      automaticAssignment: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
    return
  } catch (e) {
    console.log("setAutomaticAssignment ERROR => ", e);
  }
};

export const isBotActiveAfterDay = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.isBotActiveAfterDay = isActive;
    if (!isActive) {
      company.isBotAutoAssignActiveAfterDay = false;
    }
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      isBotActiveAfterDay: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
    return
  } catch (e) {
    console.log("setisBotAutoAssignActive ERROR => ", e);
  }
};

export const showClientCloseOption = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.showClientCloseOption = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      showClientCloseOption: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log("showClientCloseOption ERROR => ", e);
  }
};

export const sentClientSurveyByDefault = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.sentClientSurveyByDefault = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      sentClientSurveyByDefault: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log("sentClientSurveyByDefault ERROR => ", e);
  }
};

export const archiveClosedClientByDefault = (isActive) => async (dispatch, getState) => {
  try {
    const { data } = getState().user;
    const { company } = data;
    company.archiveClosedClientByDefault = isActive;
    data.company = _.cloneDeep(company);
    const newData = _.cloneDeep(data);
    await updateCompanyV2({
      archiveClosedClientByDefault: isActive,
      id: company.id,
    });
    await dispatch({
      type: SET_COMPANY_UPDATE,
      payload: newData,
    });
  } catch (e) {
    console.log("archiveClosedClientByDefault ERROR => ", e);
  }
};

export const setShowActiveAgents = (isActive) => async (dispatch) => {
  await dispatch(
    updateCompanySetting("isShowActiveAgents", isActive, SET_COMPANY_UPDATE)
  );
};

export const setAddTagFromAds = (isActive) => async (dispatch) => {
  await dispatch(
    updateCompanySetting("includeAdsTags", isActive, SET_COMPANY_UPDATE)
  );
};

export const setShowGroupsAtReassign = (isActive) => async (dispatch) => {
  await dispatch(
    updateCompanySetting("isShowGroupsAtReassign", isActive, SET_COMPANY_UPDATE)
  );
};

export const updateCompanySetting =
  (settingName, isActive, actionType) => async (dispatch, getState) => {
    try {
      const { data } = getState().user;
      const { company } = data;
      company[settingName] = isActive;
      data.company = _.cloneDeep(company);
      const newData = _.cloneDeep(data);
      await updateCompanyV2({
        [settingName]: isActive,
        id: company.id,
      });
      await dispatch({
        type: actionType,
        payload: newData,
      });
    } catch (e) {
      console.log("updateCompanySetting ERROR => ", e);
    }
  };


export const registerUserAction = (userRegisterData) => async (dispatch) => {
  try {
    await dispatch({ type: REGISTER, payload: null });
    const token = await register(userRegisterData);
    if (token) {
      const response = await verifyToken(token);
      if (response.userId) {
        const payload = { ...response };
        const newPermissions = {};
        response.permissions.forEach((i) => (newPermissions[i.name] = i.action));
        payload.permissions = newPermissions;
        await dispatch({ type: SET_USER, payload });
      } else {
        await dispatch({ type: REGISTER_ERROR, payload: 'Error getting user info' });
      }
    } else {
      await dispatch({ type: REGISTER_ERROR, payload: 'null token' });
    }
    return true;
  } catch (error) {
    console.log(error);
    await dispatch({
      type: REGISTER_ERROR,
      payload: error.response ? error.response.data : 'Error',
    });
    return false;
  }
};

export const updateCompanyInfo = (companyUpdateInfo) => async () => {
  console.log('updateCompanyInfo 2222222222222222 ============');
  try {
    const companyInfo = await updateCompany(companyUpdateInfo);
    return companyInfo;
  } catch (error) {
    console.log('updateCompanyInfo error ============', error);

    throw error;
  }
};

export const updateLocationsAction = (locationsData, deletedLocations) => async (
  dispatch,
  getState,
) => {
  try {
    const companyId = getState().user.data.company.id;
    const updatedSuccessful = await updateLocations(locationsData, deletedLocations, companyId);
    if (updatedSuccessful) {
      dispatch(getLocationsAction());
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const verifyCodeAction = (code) => async (distpach) => {
  try {
    distpach({ type: REGISTER });
    const verificationToken = await verifyCodeEmail(code);
    distpach({ type: REGISTER_END });
    return verificationToken.data;
  } catch (error) {
    distpach({ type: REGISTER_END });
    throw error;
  }
};

export const sendEmailVerificationAction = (email, plan) => async (distpach) => {
  try {
    distpach({ type: REGISTER });
    const response = await sendEmailVerification(email, plan);
    distpach({ type: REGISTER_END });
    return response;
  } catch (error) {
    distpach({ type: REGISTER_END });
    throw error;
  }
};

export const onboardingRegisterAction = (token, data, code) => async () => {
  try {
    const formData = new FormData();
    formData.append('token', token);
    if (code) formData.append('code', code);
    _.mapKeys(data, (value, key) => {
      const newKey = key.replace('onboarding', '').toLowerCase();
      formData.append(newKey, value);
    });
    const registerData = await register(formData);
    return registerData;
  } catch (error) {
    throw error;
  }
};

export const verifyTokenAction = () => async (dispatch) => {
  try {
    await dispatch({ type: VERIFY_TOKEN, payload: null });
    const token = localStorage.getItem('token');
    if (token) {
      const response = await verifyToken(token);
      if (response.id) {
        const payload = { ...response };

        const newPermissions = {};
        response.rol.permissions.forEach((i) => {
          newPermissions[i.name] = true;
          return i;
        });
        if (newPermissions.readUsers) {
          await dispatch(setFilterTypeAction('all'));
        }
        // console.log('setting sentry params')
        // Sentry.setUser({ email: payload?.email, id: payload?.id })
        payload.permissions = newPermissions;
        await dispatch({ type: SET_USER, payload });

        dispatch(getSpacesAction(response.spaces));

        await dispatch(getChannelsAction(response.spaces));
      }
      await dispatch({ type: LOGIN_ERROR, payload: 'Error getting user info' });
    } else await dispatch({ type: LOGIN_ERROR, payload: 'Error getting user info' });
  } catch (error) {
    console.error(error);
  }
};

export const getCompanyInfo = () => async (dispatch, getState) => {
  try {
    const companyId = getState()?.user?.data?.company?.id;
    if (companyId) {
      dispatch(getPaymentCustomerAction(getState().user.data.company.id));
    }
    // const { data } = getState().user;
    // const { company } = data;
    // const response = await updateCompany(company);
    // return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const getLoginDataAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_LOGIN_DATA });
    await dispatch(verifyTokenAction());
    await dispatch(getCompanyInfo());
    dispatch({ type: LOAD_LOGIN_DATA_FINISHED });
  } catch (error) {
    dispatch({ type: LOAD_LOGIN_DATA_ERROR });
  }
};

export const clearDataAction = () => ({ type: CLEAR_DATA, payload: null });
