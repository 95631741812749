import styled from "styled-components";

export const TableWrapper = styled.table`
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px 12px 0px 0px;
  width: 100%;
`;

export const TableTh = styled.th`
  padding: 20px 20px;
  color: #979797;
  font-size: 18px;
  font-weight: 700;
  font-family: Montserrat-Bold;
  border-bottom: 2px solid #f5f4f6;
`;

export const TableTd = styled.td`
  padding: 20px 20px;
  font-size: 18px;
  font-family: Montserrat-Medium;
  text-align: center;
  border-bottom: 2px solid #f5f4f6;
`;
