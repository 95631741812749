
import styled, { css } from 'styled-components';

const Cointainer = styled.section`
  width: 100%;
  height: 100%;
  & > img {
    object-fit: cover !important;
    border-radius: 100% !important;
    height: 100% !important;
    width: 100% !important;
  }
  & > div {
    background-color: transparent !important;
    width: 100% !important;
    height: 100% !important;
  }
  & > div > img {
    object-fit: cover !important;
    border-radius: 100% !important;
    height: 100% !important;
    width: 100% !important;
`;
export default Cointainer;
