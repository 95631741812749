import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Title = styled.h1`
  font-family: SpaceGrotesk-Bold;
  font-size: 32px;
`;

export const CardsSection = styled.section`
  display: flex;
  flex-wrap: wrap;
`;

export const HeaderSection = styled.section`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 30px;
  ${(props) =>
    props.fixed &&
    css`
      width: 100%;
      position: fixed;
      z-index: 100;
      top: 72px;
      @media (max-width: 955px) {
        width: 100%;
        position: relative;
        top: 0;
      }

    `}
`;

export const PickerSection = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DatePicker = styled.div`

`;

export const ExportButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 12px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  th,
  td {
    padding: 12px;
    border: 1px solid #ddd;
  }
  th {
    background-color: #f2f2f2;
  }
`;

export const CardAgentInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 16px;
  border-radius: 12px;
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.077));
  padding: 26px;
`;
export const CardChatInfo = styled.div`
  display: flex;

  flex-wrap: wrap;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.077));
  padding: 26px;
`;

export const CardAgentInfoTitle = styled.h2`
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 6px;
  align-self: flex-start;
`;

export const CardAgentInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  margin-right: 16px;
  border-radius: 12px;
  background-color: white;
`;

export const CardAgentInfoContent = styled.span`
  display: block;
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 6px;
  align-self: flex-start;
`;

export const CardAgentInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 6px;
`;

export const CardAgentInfoItemTitle = styled.span`
  display: block;
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 6px;
  align-self: flex-start;
`;

export const CardAgentInfoItemValue = styled.span`
  display: block;
  font-family: SpaceGrotesk-Regular, sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 6px;
  align-self: flex-start;
`;
