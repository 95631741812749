import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import SearchIcon from "../../assets/svg/Search.svg";

import { SearchContainer, SearchInput, SearchForm, SearchIconContainer } from "./styles";

const Search = ({ handleSearch, value, templateSelection, label }) => {
  return (
    <SearchContainer>
      <SearchForm templateSelection={templateSelection}>
        {!templateSelection && (
          <FontAwesomeIcon icon={faSearch} style={{ color: "#cccccc" }} />
        )}
        <SearchInput
          templateSelection={templateSelection}
          placeholder={templateSelection ? label : "Buscar"}
          value={value}
          onChange={handleSearch}
        />
        {templateSelection && (
          <SearchIconContainer>
            <img src={SearchIcon} width="100%" />
          </SearchIconContainer>
        )}
      </SearchForm>
    </SearchContainer>
  );
};

export default Search;
