import gql from "graphql-tag";

const CREATE_REPORT = gql`
  mutation createReport(
    $name: String!
    $frecuency: String!
    $email: String!
    $companyId: ID!
    $isActive: Boolean!
  ) {
    createReport(
      data: {
        name: $name
        frequency: $frecuency
        email: $email
        isActive: $isActive
        company: { connect: { id: $companyId } }
      }
    ) {
      id
      name
    }
  }
`;

const UPDATE_REPORT = gql`
  mutation updateReport($id: ID!, $name: String!, $email: String!, $isActive: Boolean!) {
    updateReport(where: { id: $id }, data: { name: $name, email: $email, isActive: $isActive }) {
      id
      name
      email
      isActive
    }
  }
`;

const DELETE_REPORTS = gql`
  mutation deleteManyReports($email: String!) {
    deleteManyReports(where: { email: $email }) {
      count
    }
  }
`;

const GET_REPORTS_BY_COMPANY_ID = gql`
  query reports($companyId: ID!) {
    reports(where: { company: { id: $companyId } }) {
      id
      name
      frequency
      email
      isActive
    }
  }
`;

const ApolloReports = {
  CREATE_REPORT,
  GET_REPORTS_BY_COMPANY_ID,
  UPDATE_REPORT,
  DELETE_REPORTS
};

export default ApolloReports;
