import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from './indexReducer';

let store = null;

if (process.env.REACT_APP_ENV === 'PRODUCTION') {
  store = createStore(reducer, {}, applyMiddleware(thunk));
}

if (process.env.REACT_APP_ENV === 'DEVELOPMENT') {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(reducer, {}, composeEnhancers(applyMiddleware(thunk, logger)));
}

export default store;
