import client from "../apollo/apolloClient";
import API from "../axios";
import ApolloCampaign from "../apollo/apolloCampaign";

export const getCampaigns = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloCampaign.GET_CAMPAIGNS,
      variables: { companyId },
    });
    return response.data;
  } catch (error) {
    throw new Error("ERROR_GETTING_CAMPAIGNS");
  }
};

export const getCampaignsByChannelId = async (channelId) => {
  try {
    const response = await client.query({
      query: ApolloCampaign.GET_CAMPAIGNS_BY_CHANNEL_ID,
      variables: { channelId },
    });
    return response.data;
  } catch (error) {
    throw new Error("ERROR_GETTING_CAMPAIGNS");
  }
};

export const deleteCampaign = async (id) => {
  try {
    await client.query({
      query: ApolloCampaign.DELETE_CAMPAIGN,
      variables: { id },
    });
  } catch (error) {
    throw new Error("ERROR_DELETING_CAMPAIGNS");
  }
};

export const getCampaignStatus = async (
  campaignId,
) => {
  try {
    const response = await API.post("/campaign/analitycs", {
      campaignId,
    });

    console.log(response);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    throw new Error("ERROR_GET_WHATSAPP_TEMPLATE_VARIABLES");
  }
};
