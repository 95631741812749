import styled from "styled-components";

export const MainWraper = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 4px 24px;
  grid-area: alert;
  height: fit-content;
  z-index: 1;
  border: 1px solid #F7F8FA;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: ${({ type }) => type === "error" ? "#FDECED" : type === "warning" ? "#FEF1DB" : type === "success" ? "#E7F7F4" : "#FFF"};
`;

export const MessageWrapper = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  color: ${({ type }) => type === "error" ? "#D00000" : type === "warning" ? "#592702" : type === "success" ? "#118C7E" : "#000000"};
`;

export const Button = styled.button`
  background: none;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: 700;
  border: none;
  color: ${({ type }) => type === "error" ? "#D00000" : type === "warning" ? "#592702" : type === "success" ? "#118C7E" : "#000000"};
  
  &:hover {
  }
`;