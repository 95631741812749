/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import {
  InputContainer,
  InputLabel,
  InputForm,
  InputIcon,
  InputValue,
  InputError,
  OnboardingStyleError,
  OnboardingErrorIcon,
} from "./input.style";

import errorIcon from "../../../../assets/svg/onboarding-error.svg";

const Input = forwardRef(
  (
    {
      name,
      onChange,
      type,
      placeholder = null,
      label = null,
      disabled = false,
      icon = null,
      showLabelError = false,
      error = null,
      step,
      value,
      fillFlex,
      autocomple = true,
      onKeyDown = null,
      newTheme = false,
      inputValueBold = true,
      onboardingLabelStyle = false,
      templateName = false,
      broadcastName = false,
    },
    ref
  ) => (
    <InputContainer hasErrors={error} fillFlex={fillFlex}>
      {label && (
        <InputLabel
          htmlFor={name}
          onboardingLabelStyle={onboardingLabelStyle}
          broadcastName={broadcastName}
        >
          {label}
        </InputLabel>
      )}
      <InputForm
        error={error}
        disabled={disabled}
        newTheme={newTheme}
        broadcastName={broadcastName}
        onboardingLabelStyle={onboardingLabelStyle}
      >
        {icon && <InputIcon src={icon} />}
        {ref ? (
          <InputValue
            placeholder={placeholder}
            inputValueBold={inputValueBold}
            type={type}
            ref={ref}
            onChange={() => onChange(name)}
            name={name}
            step={type === "number" && step}
            disabled={disabled}
            autoComplete={autocomple ? "on" : "off"}
            onKeyDown={onKeyDown}
            newTheme={newTheme}
            broadcastName={broadcastName}
            error={error}
          />
        ) : (
          <InputValue
            placeholder={placeholder}
            inputValueBold={inputValueBold}
            type={type}
            onChange={onChange}
            value={value}
            name={name}
            step={type === "number" && step}
            disabled={disabled}
            autoComplete={autocomple ? "on" : "off"}
            newTheme={newTheme}
            broadcastName={broadcastName}
            error={error}
          />
        )}
      </InputForm>
      {showLabelError && onboardingLabelStyle && error && (
        <OnboardingStyleError>
          <OnboardingErrorIcon src={errorIcon} alt="Error" />
          <InputError onboardingLabelStyle>{error}</InputError>
        </OnboardingStyleError>
      )}
      {showLabelError && !onboardingLabelStyle && error && (
        <InputError>{error}</InputError>
      )}
    </InputContainer>
  )
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["text", "number"]).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  showLabelError: PropTypes.bool,
  newTemplate: PropTypes.bool,
  templateName: PropTypes.bool,
  error: PropTypes.string,
  fillFlex: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomple: PropTypes.bool,
  onKeyDown: PropTypes.func,
  newTheme: PropTypes.bool,
  inputValueBold: PropTypes.bool,
  step: (props, propName) => {
    if (props.type === "number" && (props[propName] === undefined || props[propName] === null)) {
      return new Error("Please provide step prop if type prop is number");
    }
  },
  value: (props, propName) => {
    if (props.ref === null && (props[propName] === undefined || props[propName] === null)) {
      return new Error("Please provide value prop if ref prop is not provided");
    }
    if (typeof props[propName] !== "number" && typeof props[propName] !== "string") {
      return new Error("value props must be a number or string");
    }
  },
  ref: (props, propName) => {
    if (props.value === null && (props[propName] === undefined || props[propName] === null)) {
      return new Error("Please provide ref prop if value prop is not provided");
    }
  },
  onboardingLabelStyle: PropTypes.bool,
};

Input.defaultProps = {
  newTheme: false,
  inputValueBold: true,
  onboardingLabelStyle: PropTypes.bool,
};

export default Input;
