import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import API from "../../axios";

import pusher from "../../utils/pusher";
import {
  Label,
  PrimaryButton,
  SecondaryButton,
  TalkToExpertLabel,
  WhatsappButton,
} from "./Onboarding.styles";

const WhatsappLinkInstructions = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [isTalkToExpert, setIsTalkToExpert] = useState(false);
  const secondsRef = useRef(1000 * 60 * 10);

  const instructions = [
    "Tu chip y dispositivo móvil en físico, pues recibirás un código.",
    " Compartir con nosotros la página web de tu negocio.",
    "Contar con un correo corporativo",
    "Confirmar que tienes una cuenta de administrador de tu página de Facebook.",
  ];

  const goBack = () => {
    history.goBack();
  };

  const handletalkToExpert = async () => {
    setIsTalkToExpert(true)
    API.get(`/onboarding/need-help`);
    return;
  };

  const sendWhatsappInfo = () => {
    API.get(`/onboarding/send-video`);
  };

  // const updateTimer = () => {
  //   if (secondsRef.current === 0) return;
  //   secondsRef.current = secondsRef.current - 1;
  //   setSeconds(secondsRef.current);
  // };

  // useEffect(() => {
  //   // const details = navigator.userAgent;
  //   // const regexp = /android|iphone|kindle|ipad/i;
  //   // const isMobileDevice = regexp.test(details);
  //   // setIsMobileDevice(isMobileDevice);
  //   // const interval = setInterval(() => updateTimer(), 1000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
      }}
    >
      <Label>Instrucciones para vincular WhatsApp</Label>
      {/* <strong style={{ margin: "20px 0" }}>
        ¡Realiza la vinculación por tu cuenta y recibe 500 mensajes gratis!
      </strong> */}
      <h1>Para hacer la llamada, necesitamos que cuentes con:</h1>
      <ol
        style={{
          padding: "20px",
        }}
      >
        {instructions.map((instruction, index) => (
          <li key={index}>{instruction}</li>
        ))}
      </ol>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
          alignItems: "center",
          width: "50%",
        }}
      >
        <SecondaryButton onClick={goBack}>Regresar</SecondaryButton>
        <div onClick={handletalkToExpert} style={{ cursor: "pointer" }}>
          <PrimaryButton onClick={handletalkToExpert}>Agendar cita</PrimaryButton>
        </div>
      </div>
      {isTalkToExpert === true && (
        <div>
          <TalkToExpertLabel>
            En breve un experto se pondrá en contacto contigo para ayudarte a
            vincular tu número de WhatsApp.
          </TalkToExpertLabel>
        </div>
      )}
      {error && (
        <Label>
          Hubo un error al vincular tu número de WhatsApp. Por favor, contacta
          al equipo de soporte.
        </Label>
      )}
    </main>
  );
};

export default WhatsappLinkInstructions;
