const safariBrowserVerification = () => {
  const usrAg = navigator.userAgent;
  let isSafari = false;
  if (usrAg.includes('Safari')) {
    isSafari = true;
    if (usrAg.includes('Chrome')) {
      isSafari = false;
    }
  }
  return isSafari;
};

export default safariBrowserVerification;
