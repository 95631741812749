import client from '../apollo/apolloClient';
import ApolloOnboarding from '../apollo/apolloOnboarding';
import API from '../axios';

export const updateUserInfo = async (
  id,
  device,
  connSpeed,
  location
) => {
  try {
    const response = await client.mutate({
      mutation: ApolloOnboarding.UPDATE_USER_INFO,
      variables: { id, device, connSpeed , location},
    });
    const result = response.data?.updateOnboardingInProcess;
    console.log("updateUserInfo =====> ", result)
    return result;
  } catch (error) {
    console.log('ERROR_updateUserInfo', error)
    /* throw new Error('ERROR_updateUserInfo'); */
  }
};

export const setParams = async (
  id,
  data,
) => {
  try {
    // const response = await client.mutate({
    //   mutation: ApolloOnboarding.UPDATE_SCORE,
    //   variables: { id, score },
    // });
    // const result = response.data?.updateOnboardingInProcess;
    // console.log("updateScore =====> ", result)
    // return result;

    const response = await API.post("/onboarding/set", {
      id: id,
      data: data,
    });
  } catch (error) {
    console.log('ERROR_updateScore', error)
    throw new Error('ERROR_updateScore');
  }
};

export const updateStep = async (
  id,
  step,
) => {
  try {
    const response = await client.mutate({
      mutation: ApolloOnboarding.UPDATE_STEP,
      variables: { id, step },
    });
    const result = response.data?.updateOnboardingInProcess;
    console.log("update step =====> ", result)
    return result;
  } catch (error) {
    console.log('ERROR_update step', error)
    throw new Error('ERROR_update step');
  }
};

export const updateLocation = async (
  id,
  location,
) => {
  try {
    const response = await client.mutate({
      mutation: ApolloOnboarding.UPDATE_LOCATION,
      variables: { id, location },
    });
    const result = response.data?.updateOnboardingInProcess;
    console.log("updateLocation =====> ", result)
    return result;
  } catch (error) {
    console.log('ERROR_updateLocation', error)
    throw new Error('ERROR_updateLocation');
  }
};

export const updateDevice = async (
  id,
  device,
) => {
  try {
    const response = await client.mutate({
      mutation: ApolloOnboarding.UPDATE_DEVICE,
      variables: { id, device },
    });
    const result = response.data?.updateOnboardingInProcess;
    console.log("updateDevice =====> ", result)
    return result;
  } catch (error) {
    console.log('ERROR_updateDevice', error)
    throw new Error('ERROR_updateDevice');
  }
};