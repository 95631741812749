import React from 'react';
import PropTypes from 'prop-types';
import {
  CardImageContainer,
  CardImg,
  CardContainer,
  CardInfoContainer,
  CardTitle,
  CardSubtitle,
  DefaultImg,
} from './Card.style';
import { getLabelMessage } from '../../../../const/labelService';
import iVentasLogo from '../../../../assets/images/logo_iventas.png';

const Card = ({ name, albumsCount, filesCount, creationDate, image, onClick, small = false, isSubAlbum = false }) => {
  const labels = ['multimediaAlbumsCount', 'multimediaSubAlbumFilesCount'];
  const lbls = getLabelMessage(labels);

  const setSubtitleText = () => {
    if (small) {
      if (albumsCount === 0 || isSubAlbum) return lbls.multimediaSubAlbumFilesCount(filesCount);
      return lbls.multimediaAlbumsCount(albumsCount, filesCount);
    }
    if (isSubAlbum) return lbls.multimediaSubAlbumFilesCount(filesCount);
    return lbls.multimediaAlbumsCount(albumsCount, filesCount);
  };

  return (
    <CardContainer small={small} onClick={onClick}>
      <CardImageContainer small={small}>
        <div>
          { image
            ? (<CardImg src={image} alt={name} />)
            : (<DefaultImg src={iVentasLogo} alt="iVentas" />)}
        </div>
      </CardImageContainer>
      <CardInfoContainer small={small}>
        <CardTitle small={small}>{name}</CardTitle>
        <CardSubtitle>{ setSubtitleText() }</CardSubtitle>
        {!small && (<CardSubtitle>{creationDate}</CardSubtitle>)}
      </CardInfoContainer>
    </CardContainer>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  albumsCount: PropTypes.number.isRequired,
  filesCount: PropTypes.number.isRequired,
  creationDate: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  isSubAlbum: PropTypes.bool,
};

export default Card;
