import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import DashboardLayout from "../components/dashboardLayout/DashboardLayout";
import LinkFirstWhatsapp from "../components/simplifiedOnboarding/LinkFirstWhatsapp.component";
import SuccessfulLink from "../components/simplifiedOnboarding/successfullLink.component";
import WhatsappLinkInstructions from "../components/simplifiedOnboarding/WhatsappLinkInstructions.component";
import WhatsappLinkProfile from "../components/simplifiedOnboarding/WhatsappLinkProfile.component";
import WhatsappLinkQrCode from "../components/simplifiedOnboarding/WhatsappLinkQrCode.component";
import CONSTANTS from "../const/constants";
import AnalyticsView from "../views/dashboard/AnalyticsView/AnalyticsView";
import BroadcastView from "../views/dashboard/BroadcastView/BroadcastView";

import LoaderTest from "../views/dashboard/LoaderTest";
import SplashScreen from "../views/dashboard/SplashScreen";
import PanelView from "../views/panel/panel.view";
import Multimedia from "../views/settings/multimedia/Multimedia.component";
// import UpdateSubscriptionView from "../views/update-subscription/UpdateSubscription.component";
import OnboardingUseOldNumber from "../components/simplifiedOnboarding/OnboardingUseOldNumber.component";
import WhatsappLinkQrCodeInstructions from "../components/simplifiedOnboarding/WhatsappLinkQrCodeInstructions.component";
import TwoFactorQrCodeView from "../views/twoFactor/TwoFactorQrCode";
import TwoFactorView from "../views/twoFactor/TwoFactor";
import SelectPlanView from "../views/select-plan/SelectPlanView";

const InitialPayment = lazy(() => import("../components/simplifiedOnboarding/InitialPayment.component"));
const LoginView = lazy(() => import("../views/login/login.view"));
const ChatView = lazy(() => import("../views/dashboard/ChatView"));
const QuickAnswersView = lazy(() =>
  import("../views/dashboard/QuickAnswers/QuickAnswers.view")
);
const QuickAnswersAction = lazy(() =>
  import(
    "../views/dashboard/QuickAnswers/QuickAnswersAction/QuickAnswersAction.view"
  )
);
const Users = lazy(() => import("../views/users/Users.component"));
const UsersAction = lazy(() =>
  import("../views/users/usersAction/UsersAction.component")
);
const Spaces = lazy(() => import("../views/spaces/Spaces.view"));
const SpacesAction = lazy(() =>
  import("../views/spaces/spacesAction/SpacesAction.component")
);
const LinkWhatsapp = lazy(() =>
  import("../views/spaces/linkWhatsapp/LinkWhatsapp.component")
);
const Setting = lazy(() => import("../views/settings/Settings.view"));
const Bot = lazy(() => import("../views/BotView"));
const TagsAction = lazy(() =>
  import("../views/settings/tags/tagsAction/TagsAction.view")
);
const WhatsappTemplatesAction = lazy(() =>
  import(
    "../views/settings/whatsappTemplates/whatsappTemplatesAction/WhatsappTemplatesAction.view"
  )
);
const analyticsWhitAgents = lazy(() =>
  import("../views/dashboard/AnalyticsView/AnaliticsWithAgents")
);
const RolesAction = lazy(() =>
  import("../views/settings/roles/rolesAction/RolesAction.view")
);
// const PlansAction = lazy(() =>
//   import("../views/settings/plans/plansAction/PlansAction.component")
// );
// const CardAction = lazy(() =>
//   import("../views/settings/plans/cardAction/CardAction.component")
// );
const ForbiddenView = lazy(() => import("../views/forbidden/forbidden.view"));
const RecoverPasswordView = lazy(() =>
  import("../views/recover-password/RecoverPassword.view")
);
const ChangePasswordView = lazy(() =>
  import("../views/change-password/ChangePassword.view")
);
// const PaymentView = lazy(() => import("../views/payment/payment.view"));
const DatasheetConfigurationAction = lazy(() =>
  import(
    "../views/settings/dataSheetConfiguration/dataSheetConfigurationAction/DatasheetConfigurationAction.component"
  )
);
const Album = lazy(() =>
  import("../views/settings/multimedia/album/Album.component")
);
const AccountView = lazy(() => import("../views/settings/Account.view"));
const ReferralCodesView = lazy(() =>
  import("../views/referral-codes/ReferralCodes.view")
);
const Contacts = lazy(() => import("../views/contacts/Contacts.component"));
const Help = lazy(() => import("../views/help/Help"));
const SimplifiedOnboarding = lazy(() =>
  import("../components/simplifiedOnboarding/SimplifiedOnboarding.component")
);

const Routes = () => (
  <Suspense fallback={<SplashScreen />}>
    <Router>
      <Switch>
        <Route
          path="/onboarding/step/:currentStep"
          exact
          render={() => <SimplifiedOnboarding />}
        />
        <Route
          path="/select-plan"
          exact
          render={() => <SelectPlanView />}
        />
        <Route path={CONSTANTS.FIRST_PAYMENT_ROUTE} exact render={() => <InitialPayment />} />
        <Route
          path="/phone-selection"
          exact
          render={() => <OnboardingUseOldNumber />}
        />
        <Route path="/qr-code" exact render={() => <TwoFactorQrCodeView />} />
        <Route path="/two-factor" exact render={() => <TwoFactorView />} />
        <Route
          path="/link-whatsapp"
          exact
          render={() => <LinkFirstWhatsapp />}
        />
        <Route
          path="/link-whatsapp/whatsappLink/instructions"
          exact
          render={() => <WhatsappLinkInstructions />}
        />
        <Route
          path="/link-whatsapp/profile"
          exact
          render={() => <WhatsappLinkProfile />}
        />

        <Route
          path="/link-whatsapp/qrcode-instructions"
          exact
          render={() => <WhatsappLinkQrCodeInstructions />}
        />

        <Route
          path="/link-whatsapp/qrcode"
          exact
          render={() => <WhatsappLinkQrCode />}
        />

        <Route path="/success-link" exact render={() => <SuccessfulLink />} />

        <Route exact path="/">
          <Redirect to="/chat" />
        </Route>

        <Route path="/login" exact render={() => <LoginView />} />

        <Route path="/forbidden" exact render={() => <ForbiddenView />} />
        <Route
          path="/recoverPassword"
          exact
          render={() => <RecoverPasswordView />}
        />
        <Route
          path="/changePassword"
          exact
          render={() => <ChangePasswordView />}
        />
        <Route
          exact
          validateAuth
          path={CONSTANTS.LINK_WHATSAPP_ROUTE}
          component={LinkWhatsapp}
        />
        <Route
          path={`${CONSTANTS.REF_CODE_ROUTE}/:refCode`}
          component={ReferralCodesView}
        />
        <DashboardLayout exact validateAuth path="/chat" component={ChatView} />

        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.QUICK_ANSWERS_ROUTE}
          component={QuickAnswersView}
        />
        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.QUICK_ANSWERS_ACTION_ROUTE}
          component={QuickAnswersAction}
        />

        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.MULTIMEDIA_ROUTE}
          component={Multimedia}
        />
        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.ALBUM_ROUTE}
          component={Album}
        />
        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.SUBALBUM_ROUTE}
          component={Album}
        />

        <DashboardLayout exact validateAuth path="/users" component={Users} />
        <DashboardLayout
          exact
          validateAuth
          path="/users/new"
          component={UsersAction}
        />

        <DashboardLayout exact validateAuth path="/spaces" component={Spaces} />
        <DashboardLayout
          exact
          validateAuth
          path="/spaces/new"
          component={SpacesAction}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/spaces/new-whatsapp"
          component={LinkWhatsapp}
        />

        <DashboardLayout
          exact
          validateAuth
          path="/contacts"
          component={Contacts}
        />

        <DashboardLayout
          exact
          validateAuth
          path="/config"
          component={Setting}
        />
        <DashboardLayout exact validateAuth path="/help" component={Help} />
        <DashboardLayout exact validateAuth path="/bot" component={Bot} />
        <DashboardLayout
          exact
          validateAuth
          path="/config/new-tag"
          component={TagsAction}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/config/new-whatsapp-template"
          component={WhatsappTemplatesAction}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/config/new-rol"
          component={RolesAction}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/config/new-datasheet"
          component={DatasheetConfigurationAction}
        />
        {/* <DashboardLayout
          exact
          validateAuth
          path="/config/edit-card"
          component={CardAction}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/config/edit-plan"
          component={PlansAction}
        /> */}
        {/* <DashboardLayout
          exact
          validateAuth
          path="/config/payment"
          component={PaymentView}
        /> */}

        <DashboardLayout
          exact
          validateAuth
          path={CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE}
          component={AccountView}
        />

        <DashboardLayout
          exact
          validateAuth
          path="/panel"
          component={PanelView}
        />

        <DashboardLayout
          exact
          validateAuth
          path="/analytics"
          component={AnalyticsView}
        />
        <DashboardLayout
          exact
          validateAuth
          path="/analytics/agents"
          component={analyticsWhitAgents}
        />

        <DashboardLayout
          exact
          validateAuth
          path="/broadcast/:currentStep"
          component={BroadcastView}
        />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
