import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  font-family: SpaceGrotesk-Bold, sans-serif;
  background-color: ${(props) =>
    props.color === "primary" ? "#128c7e" : "#98B2CD"};
  width: 13rem;
  height: 3rem;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;

  & > span {
    font-family: SpaceGrotesk-Bold, sans-serif;
  }

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.isloadingForm &&
    css`
      display: none;
    `}

  ${(props) =>
    props.right &&
    css`
      margin: 0 0 0 auto;
    `}
  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.small &&
    css`
      width: 2rem;
      height: 2rem;
    `}

  ${(props) =>
    props.xs &&
    css`
      width: 1.5rem;
      height: 1.5rem;
    `}

  ${(props) =>
    props.warning &&
    css`
      background-color: #f61e44;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}


  ${(props) =>
    props.facebook &&
    css`
      justify-content: space-between;
      background-color: #ededed;
      color: #3179ff;
      font-size: 1.1rem;
      width: 16.375rem;
      padding: 0 1.5rem;
    `}

  ${(props) =>
    props.whatsapp &&
    css`
      justify-content: space-between;
      background-color: #25d366;
      font-size: 0.875rem;
      width: 16.375rem;
      padding: 0 1.5rem;
    `}
  
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
  
  ${(props) =>
    props.secondary &&
    css`
      background-color: white;
      color: #3f51b5;
      border: solid thin #3f51b5;
    `}

  ${(props) =>
    props.tight &&
    css`
      height: 1.8rem;
      width: auto;
      padding: 0.1rem 0.8rem;
    `}

  ${(props) =>
    props.addElementButton &&
    css`
      padding: 12px 16px;
      font-size: 0.75rem; 
      width: 9.75rem;
      height: 2.75rem;
      font-family: "Montserrat-Bold";
      border-radius: 6px;
    `}
  ${(props) =>
    props.ExportButton &&
    css`
      padding: 12px 16px;
      font-size: 12px;
      width: 8.75rem;
      height: 2.75rem;
      font-family: "Montserrat-Bold";
      border-radius: 6px;
    `}
  ${(props) =>
    props.importButton &&
    css`
      width: 9.75rem;
      height: 2.75rem;
      color: #128c7e;
      padding: 12px 16px;
      background-color: white;
      border-radius: 6px;
      border: 2px solid #128c7e;
    `}
  ${(props) =>
    props.importCancelButton &&
    css`
      width: 13.5rem;
      height: 2.75rem;
      margin-right: 8.625rem;
      color: #f61e44;
      padding: 12px 16px;
      background-color: white;
      border: 2px solid #f61e44;
      border-radius: 4px;
    `}
  ${(props) =>
    props.importInfoCancelButton &&
    css`
      width: 13.5rem;
      height: 2.75rem;
      margin-left: 33px;
      margin-right: 3.625rem;
      color: #f61e44;
      padding: 12px 16px;
      background-color: white;
      border: 2px solid #f61e44;
      border-radius: 4px;
    `}
  ${(props) =>
    props.importCancelButtonChannels &&
    css`
      width: 13.5rem;
      height: 2.75rem;
      margin-right: 3.625rem;
      color: #f61e44;
      margin-left: 2.063rem;
      padding: 12px 16px;
      background-color: white;
      border: 2px solid #f61e44;
      border-radius: 4px;
    `}
  ${(props) =>
    props.importConfirmButton &&
    css`
      width: 13.5rem;
      height: 2.75rem;
      margin-right: 2.063rem;
      color: white;
      padding: 12px 16px;
      background: #128c7e;
      border-radius: 4px;
    `}
  ${(props) =>
    props.followUpCancelModal &&
    css`
      width: 372px;
      height: 44px;
      left: 42px;

      border: 1.6px solid #f61e44;
      border-radius: 4px;
      background-color: #fff;

      font-family: "Montserrat-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #f61e44;
      line-height: 1rem;
      /* or 100% */

      letter-spacing: 0.275122px;
    `}
  ${(props) =>
    props.followUpConfirmModal &&
    css`
      width: 372px;
      height: 44px;
      left: 42px;

      border-radius: 4px;
      background-color: #00cd7e;

      font-family: "Montserrat-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      color: #fff;
      line-height: 16px;
      /* or 100% */

      letter-spacing: 0.275122px;
    `}
  ${(props) =>
    props.templateSelect &&
    css`
      width: ${(props) => (props.templateSelected ? "100%" : "24.625rem")};
      height: 2rem;
      left: 54px;
      top: 175px;

      background: #128c7e;
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.createTemplate &&
    css`
      width: 10.5rem;
      height: 2.375rem;
      left: 54px;
      top: 175px;

      background-color: ${(props) => (props.disabled ? "#D9D9D9" : "#128c7e")};
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.broadcastType &&
    css`
      width: 16rem;
      height: 2.375rem;

      margin-top: 1.813rem;

      background-color: ${(props) => (props.disabled ? "#D9D9D9" : "#128c7e")};
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.createTemplateDisable &&
    css`
      width: 10.5rem;
      height: 2.375rem;
      left: 54px;
      top: 175px;

      background: #d3d3d3;
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.createTemplateCard &&
    css`
      width: 19rem;
      height: 2.375rem;
      left: 54px;
      top: 175px;

      background: #128c7e;
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.broadcastQualityModal &&
    css`
      margin-top: 4.625rem;
      width: 25.063rem;
      height: 2.375rem;
      left: 54px;
      top: 175px;

      background: #128c7e;
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
  ${(props) =>
    props.disabledBroadcast &&
    css`
      width: 16rem;
      height: 2.375rem;

      margin-top: 1.813rem;

      background: rgba(214, 214, 214, 0.78);
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
      ${(props) =>
    props.broadcastAnalitycs &&
    css`
      width: 10.5rem;
      height: 2.375rem;
      left: 54px;
      top: 175px;

      background-color: ${(props) => (props.disabled ? "#D9D9D9" : "#128c7e")};
      border-radius: 4px;

      font-family: "SpaceGrotesk-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 150%;
      /* identical to box height, or 24px */

      letter-spacing: -0.257238px;

      color: #ffffff;
    `}
`;

export default StyledButton;



