import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { toast } from 'react-toastify';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  faFileDownload,
  faFileExport,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import * as Excel from "exceljs";
import * as FileSaver from "file-saver";

import { useSelector } from "react-redux";

import AnalyticsCard from "../../../components/AnalyticsCard/AnalyticsCard.component";
import AnalyticsTable from "../../../components/analyticsTable/AnalyticsTable.component";
import {
  Title,
  CardsSection,
  HeaderSection,
  PickerSection,
  ExportButtonIcon,
  DatePicker,
} from "./AnalyticsView.styles";
import Button from "../../../components/shared/components/button/Button.style";
import { useEffect } from "react";
import { getAnalytics, getCustomFieldsFromBot } from "../../../utils/analytics";
import LoaderComponent from "../../../components/loader/Loader.component";
import CONSTANTS from "../../../const/constants";
// import defaultWave from "../../../assets/images/cards/photo_2023-07-17_14-52-07.jpg";
import wave1 from "../../../assets/images/cards/wave1.png";
import wave2 from "../../../assets/images/cards/wave2.png";
import wave3 from "../../../assets/images/cards/wave3.png";
import wave4 from "../../../assets/images/cards/wave4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@material-ui/core";
import { groupBy } from "lodash";

const ExportButton = ({ onClick, label, disabled, agents }) => (
  <Button
    addElementButton
    onClick={onClick}
    color="primary"
    disabled={disabled}
  >
    {agents ? (
      <ExportButtonIcon icon={faUser} />
    ) : (
      <ExportButtonIcon icon={faFileExport} />
    )}
    {label}
  </Button>
);

const AnalyticsView = () => {
  const userData = useSelector((state) => state.user.data);
  const selectedSpace = useSelector((state) => state.spaces.selected);
  const companyName = useSelector((state) => state.user.data.company?.name);
  const companyId = useSelector((state) => state.user.data.company?.id);

  const firstEndDate = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const firstInitialDate = moment()
    .startOf("day")
    .subtract(1, "weeks")
    .format("YYYY-MM-DDTHH:mm:ss");
  const today = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");

  const [initalDate, setInitalDate] = useState(firstInitialDate);
  const [endDate, setEndDate] = useState(firstEndDate);

  const [isLoading, setIsLoading] = useState(true);

  const [messages, setMessages] = useState(0);
  const [activeClients, setActiveClients] = useState(0);
  const [timeWithMoreMessages, setTimeWithMoreMessages] = useState(0);
  const [whatsapp, setWhatsapp] = useState(0);
  const [messenger, setMessenger] = useState(0);
  const [answeredChats, setAnsweredChats] = useState(0);
  const [unansweredChats, setUnansweredChats] = useState(0);
  const [agentsData, setAgentsData] = useState([]);
  const [totalFromAds, setTotalFromAds] = useState([]);
  const [clients, setClients] = useState([]);

  const setData = (selectedInitDate, selectedEndDate) => {
    try {
      console.log("SELECTED SPACE", selectedSpace);
      console.log("COMPANY NAME", companyName);
      setIsLoading(true);
      getAnalytics(
        selectedInitDate,
        selectedEndDate,
        selectedSpace?.channels?.length > 0 &&
          companyName === CONSTANTS.VINTE_COMPANY_NAME
          ? selectedSpace?.channels?.map((x) => x.id)
          : []
      ).then((response) => {
        console.log("ANALYTICS", response);
        setTimeWithMoreMessages(response.timeWithMoreMessages);
        setWhatsapp(response.whatsapp);
        setMessenger(response.messenger);
        setAnsweredChats(response.totalAnswered);
        setUnansweredChats(response.totalUnanswered);
        setAgentsData(response.agents);
        setMessages(response.totalMessages);
        setActiveClients(response.totalActive);
        setTotalFromAds(response.totalFromAds);
        setIsLoading(false);
        setClients(response.clients);
        toast.warn(
          'Los datos de estadísticas están limitados a 500 registros, si necesitas el reporte completo solicítalo a soporte.',
          {
            position: 'top-center',
            autoClose: 15000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setData(firstInitialDate, firstEndDate);
  }, []);

  // useEffect(() => {
  //   setData(firstInitialDate, firstEndDate);
  // }, [selectedSpace?.id]);

  const handleInitialDate = (date) => {
    const initDate = moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    setInitalDate(initDate);
    let _endDate = endDate;
    if (moment(endDate).isAfter(moment(date).add(15, "days"))) {
      setEndDate(moment(date).endOf("day").add(15, "days"));
      _endDate = moment(date).endOf("day").add(15, "days");
    }
    setIsLoading(true);
    setData(initDate, _endDate);
  };

  const handleEndDate = (date) => {
    const finalDate = moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    setEndDate(finalDate);
    let _initialDate = initalDate;
    if (moment(initalDate).isBefore(moment(date).subtract(15, "days"))) {
      setInitalDate(moment(date).startOf("day").subtract(15, "days"));
      _initialDate = moment(date).startOf("day").subtract(15, "days");
    }
    setIsLoading(true);
    setData(_initialDate, finalDate);
  };

  const gotToAgents = () => {
    window.location.href = "/analytics/agents"; //
  };

  const getMessageStatus = (messageStatus) =>
    CONSTANTS.MESSAGE_STATUS[messageStatus?.toUpperCase()] || "";

  const exportAnalytics = async () => {
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet("iVentas");

    const row = sheet.getRow(1);
    row.getCell(1).value = "Nombre";
    row.getCell(2).value = "Teléfono";
    row.getCell(3).value = "Canal";
    row.getCell(4).value = "Agente";
    row.getCell(5).value = "Etiquetas";
    row.getCell(6).value = "Plataforma";
    row.getCell(7).value = "Fecha de creación";
    row.getCell(8).value = "Ultima actualización";
    row.getCell(9).value = "Status del último mensaje";
    clients.forEach((x, i) => {
      const lastMessage = x.messages.filter((x) => x.sender !== "CLIENT").pop();
      const clientRow = sheet.getRow(2 + i);
      clientRow.getCell(1).value = x?.nameFromChannel;
      clientRow.getCell(2).value = x?.senderId;
      clientRow.getCell(3).value = x?.channel?.name || x?.channel?.phone || "";
      clientRow.getCell(4).value = x?.agent?.name || "";
      clientRow.getCell(5).value = x?.tags?.map((x) => x?.name).join(", ");
      clientRow.getCell(6).value = x?.channel?.platform?.name || "";
      clientRow.getCell(7).value = moment(x?.createdAt).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      clientRow.getCell(8).value = moment(lastMessage?.updatedAt).format(
        "DD/MM/YYYY HH:mm:ss"
      );
      clientRow.getCell(9).value =
        getMessageStatus(lastMessage?.messageStatus) || "";
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(blob, "iventas.xlsx");
  };
  const exportReportBot = async () => {
    setIsLoading(true);
    const getCustomFieldFromBot = await getCustomFieldsFromBot(
      initalDate,
      endDate,
      companyName,
      selectedSpace?.channels?.map((x) => x.id)
    );
    console.log("getCustomFieldFromBot", getCustomFieldFromBot);

    const resultMatrix = [];
    resultMatrix.push([
      "N° Correlativo",
      "Fecha de Registro",
      "Hora de Registro",
      "N° Télefono Usuario",
      "Tipo de evento",
      "Nombre",
      "Fecha inc.",
      "Region",
      "Lugar o intalación",
      "Empresa",
      "Detalles",
      "Evidencia 1",
      "Evidencia 2",
      "Evidencia 3",
    ]);

    getCustomFieldFromBot.forEach((clientInfo) => {
      console.log("clientInfo", clientInfo);
      const clientInfoByFlowId = groupBy(clientInfo, "flowId");
      console.log("clientInfoByFlowId", clientInfoByFlowId);

      Object.keys(clientInfoByFlowId).forEach((flowId) => {
        const clientInfo = clientInfoByFlowId[flowId];
        const newRow = {
          "N° Correlativo": clientInfo[0]?.flowId || "", // Assuming flowId is available
          "Fecha de Registro":
            moment(clientInfo[0].createdAtClient).format("DD/MM/YYYY") || "", // Initialize with empty string
          "Hora de Registro": moment(clientInfo[0].createdAtClient).format(
            "HH:mm:ss"
          ), // Initialize with empty string
          "N° Télefono Usuario": clientInfo[0]?.senderId || "", // Assuming senderId is available
          "Tipo de evento": "", // Initialize with empty string
          Nombre: "", // Initialize with empty string
          "Fecha inc.": "", // Initialize with empty string
          Region: "", // Initialize with empty string
          "Lugar o intalación": "", // Initialize with empty string
          Empresa: "", // Initialize with empty string
          Detalles: "", // Initialize with empty string
          "Evidencia 1": "", // Initialize with empty string
          "Evidencia 2": "", // Initialize with empty string
          "Evidencia 3": "", // Initialize with empty string
        };

        clientInfo.forEach((element) => {
          const fieldName = element?.fieldName?.split("_")[1];
          if (fieldName && newRow.hasOwnProperty(fieldName)) {
            newRow[fieldName] = element.value || ""; // Assuming value is available
          } else if (fieldName === "Tipo de accidente") {
            newRow["Tipo de evento"] = element.value || ""; // Assuming value is available
          } else if (fieldName === "Fecha del accidente") {
            newRow["Fecha inc."] = element.value || ""; // Assuming value is available
          } else if (fieldName === "Lugar del accidente") {
            newRow["Lugar o intalación"] = element.value || ""; // Assuming value is available
          }
        });

        resultMatrix.push(Object.values(newRow));
      });
    });

    console.log("resultMatrix", resultMatrix);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(resultMatrix);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte Bot");
    XLSX.writeFileXLSX(workbook, "reporteBot.xlsx");
    setIsLoading(false);
  };
  return (
    <>
      <HeaderSection>
        <Title>Estadísticas</Title>
        <PickerSection>
          <DatePicker>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-init"
                label="Fecha inicial"
                value={initalDate}
                maxDate={endDate}
                onChange={handleInitialDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                autoOk
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-init"
                label="Fecha final"
                value={endDate}
                minDate={initalDate}
                maxDate={today}
                onChange={handleEndDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </DatePicker>
        </PickerSection>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            width: "auto",
          }}
        >
          <ExportButton
            label={`${
              userData.rol.name === "admin" ||
              userData.rol.name === "superadmin"
                ? "Agentes"
                : "Agente"
            }`}
            onClick={gotToAgents}
            disabled={isLoading}
            agents
          />

          <ExportButton
            label="Exportar"
            onClick={exportAnalytics}
            disabled={isLoading}
          />

          {(companyId === CONSTANTS.AGUASAFE_COMPANY_ID || companyId === CONSTANTS.IVENTAS_COMPANY_ID) && (
              <Button
                addElementButton
                onClick={exportReportBot}
                color="primary"
                disabled={isLoading}
              >
                <Icon>
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    style={{
                      width: "10px",
                    }}
                  />
                </Icon>{" "}
                Reporte del bot
              </Button>
            )}
        </div>
      </HeaderSection>
      {isLoading && <LoaderComponent width={50} height={50} />}
      {!isLoading && (
        <>
          <CardsSection>
            <AnalyticsCard
              color="#444E7C"
              title="Conversaciones"
              image={wave4}
              count={messages?.toLocaleString("en-US") || 0}
            />
            <AnalyticsCard
              color="#444E7C"
              title="Conversaciones Activas"
              image={wave4}
              count={activeClients?.toLocaleString("en-US") || 0}
            />
            <AnalyticsCard
              color="#00CD7E"
              title="WhatsApps"
              image={wave3}
              count={whatsapp?.toLocaleString("en-US") || 0}
            />
            <AnalyticsCard
              color="#0774F7"
              title="Facebook Messenger"
              count={messenger?.toLocaleString("en-US") || 0}
              image={wave1}
            />
            <AnalyticsCard
              color="#0774F7"
              title="Campañas de anuncios"
              count={totalFromAds?.toLocaleString("en-US") || 0}
              image={wave1}
            />
            <AnalyticsCard
              color="#10B054"
              title="Chats respondidos"
              count={answeredChats?.toLocaleString("en-US") || 0}
              image={wave1}
            />
            <AnalyticsCard
              color="#F9C948"
              title="Chats sin responder"
              count={unansweredChats?.toLocaleString("en-US") || 0}
              image={wave2}
            />
            <AnalyticsCard
              color="#5D86FF"
              title="Horario con más mensajes"
              count={
                timeWithMoreMessages === ""
                  ? "Sin mensajes"
                  : moment(timeWithMoreMessages).format("hh A")
              }
              image={wave4}
              hideSubtitle
            />
          </CardsSection>
          <AnalyticsTable data={agentsData} />
        </>
      )}
    </>
  );
};

export default AnalyticsView;
