import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  SelectTagTable,
  TableContent,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SelectTagHead,
  SelectTagTitle,
  SelectTagWidgetContainer,
  SelectTagSearchContainer,
  ColorCircle,
  BroadcastAppCard,
  TableHeaderText,
  TransparentBackground,
  ConjunctionContainer,
  ConjunctionTitle,
  ConjunctionGreenTitle,
  ConjunctionOptions,
  ConjunctionOption,
  ConjunctionImg,
  ConjunctionSelect,
  ConjunctionButtonContainer,
  ConjunctionButton,
} from "./BroadcastView.styles";
import Search from "../../../components/search/Search";
import LoaderComponent from "../../../components/loader/Loader.component";
import { getTagsCount } from "../../../utils/tag";
import Button from "../../../components/shared/components/button/Button.style";
import { dateFromNowLongVersion } from "../../../tools/date-utils";
import {
  setSelectedConjunctionAction,
  setSelectedTagsAction,
} from "../../../redux/campaignDuck";

import unionImg from "../../../assets/images/union.png";
import intersectionImg from "../../../assets/images/intersection.png";
import differenceImg from "../../../assets/images/difference.png";
import conjunctionButtonImg from "../../../assets/images/conjunction-button.png";
import { Checkbox } from "@material-ui/core";

const BroadcastSelectTagView = ({ accountLimitOfMessages, isFromContacts, companyId, parentCallAction, globalTags, selectedTags }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const channelId = useSelector((state) => state.campaign.create.channelId);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState();
  const [query, setQuery] = useState("");
  const [isLimitWarningOpen, setIsLimitWarningOpen] = useState(false);
  const [conjunctionType, setConjunctionType] = useState(null); // union, intersection, difference
  const [isConjunctionSelectOpen, setIsConjunctionSelectOpen] = useState(false);

  const getTagsAction = async () => {
    const checks = [];
    setIsLoading(true);
    let tags;
    if (isFromContacts === true) {
      tags = globalTags;
      console.log('>>>>>>>>>>globalTags', globalTags)
      console.log('>>>>>>>>>>selectedTags', selectedTags)
      for (let tag of tags) {
        checks.push(selectedTags?.some(id => id === tag?.id))
      }
      console.log('>>>>>>>>>>checked', checks)
    } else {
      tags = await getTagsCount(channelId === "" ? null : channelId , companyId);
      tags.forEach(() => {
        checks.push(false);
      });
    }
    setIsChecked(checks);
    setTags(tags);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!channelId && !isFromContacts) history.push("/broadcast/table");
    else getTagsAction();
  }, [channelId]);

  const handleActionButton = () => {

    if (isFromContacts === true) {
      console.log("CONTACTS TAGS CLICK!!!!!!!!!!!!!!!!!!");
      const ids = [];
      isChecked.forEach((item, index) => {
        if (item) {
          ids.push(tags[index].id);
        }
      });
      parentCallAction(ids);
    } else {
      const ids = [];
      let count = 0;
      dispatch(setSelectedConjunctionAction(conjunctionType));
      isChecked.forEach((item, index) => {
        if (item) {
          ids.push(tags[index].id);
          count += count + tags[index].clientCount;
        }
      });
      if (count > accountLimitOfMessages) {
        setIsLimitWarningOpen(true);
      } else {
        dispatch(setSelectedTagsAction(ids));
        history.push("/broadcast/selectTemplate");
      }
    }
  };

  const handleModalActionButton = () => {
    console.log(isChecked);

    const ids = [];
    let count = 0;
    isChecked.forEach((item, index) => {
      if (item) {
        ids.push(tags[index].id);
        count += count + tags[index].clientCount;
      }
    });
    dispatch(setSelectedTagsAction(ids));
    history.push("/broadcast/selectTemplate");
  };

  const handleModalCancelButton = () => {
    setIsLimitWarningOpen(false);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleCheck = (_, index) => {
    const newChecked = [...isChecked];
    newChecked[index] = !newChecked[index];
    console.log("newChecked", newChecked);

    const checkedCount = newChecked.filter((item) => item === true).length;
    if (checkedCount > 1 && !conjunctionType) {
      setConjunctionType("union");

      if (!isFromContacts) setIsConjunctionSelectOpen(true);
    }

    if (checkedCount <= 1) {
      setIsConjunctionSelectOpen(false);
      setConjunctionType(null);
    }

    setIsChecked([...newChecked]);
  };

  const selectConjunctionType = (type) => {
    setConjunctionType(type);
  };

  const openConjunctionSelect = () => {
    setIsConjunctionSelectOpen(true);
  };

  const closeConjunctionSelect = () => {
    setIsConjunctionSelectOpen(false);
  };

  useEffect(() => {
    if (query.length > 0) {
      const filteredData = tags.filter((item) => {
        const regex = new RegExp(query, "gi");
        console.log(item.name);
        return regex.test(item.name);
      });
      setTags(filteredData);
      setIsLoading(false);
    } else {
      getTagsAction();
    }
  }, [query]);

  return (
    <>
      <SelectTagWidgetContainer>
        <BroadcastAppCard>
          <SelectTagHead>
            {!isFromContacts && (<>
              <TableHeaderText>
                Envía un mensaje a varias personas al mismo tiempo creando
                campañas. Puedes usar una lista de contactos en un archivo Excel o
                seleccionar grupos específicos para enviar tu mensaje.
              </TableHeaderText>
            </>)}
            <SelectTagSearchContainer templateHead>
              <Search
                handleSearch={handleQueryChange}
                value={query}
                label={"Buscar etiquetas..."}
                templateSelection
              />
            </SelectTagSearchContainer>
          </SelectTagHead>
          <TableContent>
            <SelectTagTable onClick={() => {}}>
              {isLoading ? (
                <LoaderComponent width="32px" height="32px" />
              ) : (
                <>
                  <Thead>
                    <Th left style={{ width: "20%" }}>
                      Nombre de etiqueta
                    </Th>
                    <Th style={{ width: "20%" }}>Fecha de creación</Th>
                    <Th left>Color</Th>
                    {!isFromContacts && (<Th style={{ width: "20%" }}>Número de contactos</Th>)}
                    <Th>
                      {(conjunctionType && !isFromContacts) && (
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                            width: "80px",
                            height: "80px",
                          }}
                          onClick={openConjunctionSelect}
                        >
                          <img
                            src={conjunctionButtonImg}
                            alt="Selección"
                            style={{ width: "100%" }}
                          />
                        </button>
                      )}
                    </Th>
                  </Thead>
                  <Tbody>
                    {tags.map((val, key) => {
                      return (
                        <Tr key={key}>
                          <Td style={{ width: "20%" }} left>
                            {val.name}
                          </Td>
                          <Td>{dateFromNowLongVersion(val.createdAt)}</Td>
                          <Td style={{ width: "5%" }}>
                            <ColorCircle color={val.color} />
                          </Td>
                          {!isFromContacts && (
                            <Td style={{ width: "25%" }}>
                              {val.clientCount || 0}
                            </Td>
                          )}
                          <Td>
                            <input
                              type="checkbox"
                              checked={isChecked[key]}
                              onChange={() => {
                                handleCheck(val, key);
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </>
              )}
            </SelectTagTable>
          </TableContent>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              createTemplate
              color="primary"
              onClick={handleActionButton}
              disabled={!isFromContacts && !isChecked?.some((x) => x === true)}
            >
              {isLoading ? (
                <LoaderComponent width="22" height="22" />
              ) : (
                !isFromContacts ? "Elegir etiquetas" : "Aplicar"
              )}
            </Button>
          </div>
        </BroadcastAppCard>
      </SelectTagWidgetContainer>
      {isLimitWarningOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "70px",
              borderRadius: "10px",
              width: "600px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <p
              style={{
                textAlign: "center",
                width: "100%",
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "28px",
                color: "#2E3353",
              }}
            >
              Has sobrepasado el límite de envío diario de mensajes.
            </p>
            <p
              style={{
                textAlign: "center",
                width: "100%",
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "28px",
                color: "#2E3353",
                marginBottom: "30px",
              }}
            >
              Los mensajes restantes se enviarán en los días siguientes.
            </p>
            <Button
              createTemplate
              color="primary"
              onClick={handleModalActionButton}
              style={{
                width: "100%",
              }}
            >
              Entendido
            </Button>
            <button
              onClick={handleModalCancelButton}
              style={{
                width: "100%",
                marginTop: "20px",
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                cursor: "pointer",
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                color: "#2E3353",
              }}
            >
              Regresar
            </button>
          </div>
        </div>
      )}
      {isConjunctionSelectOpen && (
        <TransparentBackground>
          <ConjunctionContainer>
            <ConjunctionTitle>
              Seleccionaste más de una etiqueta,
            </ConjunctionTitle>
            <ConjunctionGreenTitle>
              ¿Cómo te gustaría que sean contactados?
            </ConjunctionGreenTitle>
            <ConjunctionOptions>
              <ConjunctionOption
                onClick={() => selectConjunctionType("intersection")}
              >
                <ConjunctionImg src={intersectionImg} alt="Intersección" />
                <p>
                  Enviar a los contactos que tienen todas las etiquetas
                  seleccionadas al mismo tiempo.
                </p>
                <input
                  type="checkbox"
                  value={conjunctionType === "intersection"}
                  checked={conjunctionType === "intersection"}
                  style={{ marginTop: "10px" }}
                />
              </ConjunctionOption>
              <ConjunctionOption
                onClick={() => selectConjunctionType("difference")}
              >
                <ConjunctionImg src={differenceImg} alt="Diferencia" />
                <p>
                  Enviar a los contactos que tienen al menos una etiqueta, pero
                  no todas al mismo tiempo.
                </p>
                <input
                  type="checkbox"
                  value={conjunctionType === "difference"}
                  checked={conjunctionType === "difference"}
                  style={{ marginTop: "10px" }}
                />
              </ConjunctionOption>
              <ConjunctionOption onClick={() => selectConjunctionType("union")}>
                <ConjunctionImg src={unionImg} alt="Unión" />
                <p>
                  Enviar a todos los contactos que contengan las etiquetas
                  elegidas.
                </p>
                <input
                  type="checkbox"
                  value={conjunctionType === "union"}
                  checked={conjunctionType === "union"}
                  style={{ marginTop: "10px" }}
                />
              </ConjunctionOption>
            </ConjunctionOptions>
            <ConjunctionButtonContainer>
             <ConjunctionButton onClick={closeConjunctionSelect}>
                Regresar
              </ConjunctionButton>
              <ConjunctionButton onClick={closeConjunctionSelect}>
                Guardar
              </ConjunctionButton>
            </ConjunctionButtonContainer>
          </ConjunctionContainer>
        </TransparentBackground>
      )}
    </>
  );
};

export default BroadcastSelectTagView;
