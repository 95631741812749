/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getLoginDataAction } from "../../redux/userDuck";
import { getLabelMessage } from "../../const/labelService";
import Nav from "./Nav/Nav";
import Loader from "../loader/Loader";
import routeGuard from "../../router/routeGuard";
import { toast } from "react-toastify";

import {
  GridLayout,
  Main,
  SectionPage,
  Title,
  PageContainer,
} from "./DashboardLayout.style";
import { clearDataAction } from "../../redux/locationsDuck";
import CONSTANTS from "../../const/constants";
import {
  archivedBroadConversationAction,
  archivedConversationAction,
  deleteConversationAction,
  getClientNameFromNotification,
  markAsConversationValuable,
  markAsReadAction,
  markAsUnReadAction,
  unassignClient,
  updateClientTags,
  updateConverations,
  updateMessageStatus,
  finishBotAssignment,
  setConversationClosed,
  handleGroupAdmin,
} from "../../redux/conversationsDuck";
import useBinder from "../../hooks/useBinder";
import SuscriptionAlert from "./SuscriptionAlert/SuscriptionAlert.component";
import PlanStatus from "../../const/planStatus";

const DashboardLayout = ({ component: Component, validateAuth, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const channels = useSelector((state) => state.channels);
  const user = useSelector((state) => state.user);
  const payment = useSelector((state) => state.payment);
  const isPaymentLocation = location.pathname.includes(CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE);
  const whatsappTemplateView = useSelector(
    (state) => state.whatsappTemplates.whatsappTemplateView
  );
  const isWhatsappTemplateWithVars = useSelector(
    (state) => state.whatsappTemplates.createTemplate.isVariables
  );

  const [viewTitle, setViewTitle] = useState();

  const labels = [
    "configurationViewTitle",
    "SpacesViewTitle",
    "UsersViewTitle",
    "QuickAnswersViewTitle",
    "MultimediaViewTitle",
    "whatsappTemplatesRouteLabel",
    "newWhatsappTempateRouteLabel",
    "createWhatsappTemplate",
    "BotTitle",
    "BroadcastTitle",
    "newWhatsappTemplate",
    "newCustomWhatsappTemplate",
    "whatsappTemplateCategoryView",
    "customWhatsappTemplateTitle",
    "whatsappTemplateTitle",
    "customWhatsappTemplateTitle",
  ];
  const lbls = getLabelMessage(labels);
  let isChat = false;

  const handleNotification = (data) => {
    console.log("Binder:: Notification received");

    let cleanedData = data;
    if (data?.client?.id) {
      cleanedData = cleanCircularObjects(data);
    }

    const actionToDispatch = notificationActions[cleanedData.type];
    console.log("Binder:: action to dispatch: ", cleanedData.type);

    if (actionToDispatch) {
      if (
        location.pathname === "/chat" ||
        cleanedData.type === "onLinkedChannel"
      ) {
        dispatch(actionToDispatch(cleanedData));
      }
    }
  };

  const binder = useBinder(user, handleNotification);

  const paths = [
    {
      route: "/config",
      name: lbls.whatsappTemplatesRouteLabel,
      tab: "whatsappTemplates",
    },
    {
      route: "/config/new-whatsapp-template",
      name: lbls.newWhatsappTempateRouteLabel,
    },
  ];

  const { path } = props;

  if (location.pathname === "/chat") isChat = true;

  useEffect(() => {
    switch (whatsappTemplateView) {
      case "initWhatsappTemplateView":
        // setViewTitle(lbls.initWhatsappTemplateView);
        setViewTitle(lbls.configurationViewTitle);
        break;
      case "createWhatsappTemplate":
        setViewTitle(lbls.createWhatsappTemplate);
        break;
      case "newWhatsappTemplate":
        setViewTitle(lbls.newWhatsappTemplate);
        break;
      case "newCustomWhatsappTemplate":
      case "identifyVariables":
        setViewTitle(lbls.newCustomWhatsappTemplate);
        break;
      case "whatsappTemplateCategory":
        setViewTitle(lbls.whatsappTemplateCategoryView);
        break;
      case "addMultimedia":
      case "previewMessage":
        if (isWhatsappTemplateWithVars) {
          setViewTitle(lbls.customWhatsappTemplateTitle);
        } else {
          setViewTitle(lbls.whatsappTemplateTitle);
        }
        break;
      case "creditsView":
        setViewTitle(lbls.creditsView);
        break;

      case "broadcastForLabelView":
        setViewTitle(lbls.broadcastForLabelView);
        break;

      default:
        break;
    }
  }, [whatsappTemplateView]);

  useEffect(() => {
    switch (location.pathname) {
      case "/config":
      case "/config/new-tag":
      case "/config/new-rol":
      case "/config/new-datasheet":
      case "/config/edit-card":
      case "/config/edit-plan":
      case "/chat":
        setViewTitle(lbls.configurationViewTitle);
        break;
      case "/config/new-whatsapp-template":
        break;
      case "/spaces":
      case "/spaces/new":
      case "/spaces/new-whatsapp":
        setViewTitle(lbls.SpacesViewTitle);
        break;
      case "/users":
      case "/users/new":
        setViewTitle(lbls.UsersViewTitle);
        break;
      case "/bot":
        setViewTitle(lbls.BotTitle);
        break;
      case "/broadcast":
        setViewTitle(lbls.BroadcastTitle);
        break;
      case CONSTANTS.QUICK_ANSWERS_ROUTE:
      case CONSTANTS.QUICK_ANSWERS_ACTION_ROUTE:
        setViewTitle(lbls.QuickAnswersViewTitle);
        break;
      case CONSTANTS.MULTIMEDIA_ROUTE:
      case CONSTANTS.ALBUM_ROUTE:
      case CONSTANTS.SUBALBUM_ROUTE:
        setViewTitle(lbls.MultimediaViewTitle);
        break;
      case "/account":
        setViewTitle("Mi cuenta");
        break;
      case "/contacts":
        setViewTitle("Contactos");
        break;
      case "/analytics":
        setViewTitle("");
        break;
      case "/analytics/agents":
        setViewTitle("");
        break;
      case "/help":
        setViewTitle("Lo nuevo de iVentas");
        break;
      default:
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    location.pathname === "/chat" ? binder.subscribe() : binder.unsubscribe();
  }, [
    location.pathname,
    user.data?.company?.id,
    user.data?.rol?.name,
    user.data?.id,
  ]);

  useEffect(() => {
    dispatch(getLoginDataAction());
    // if (navigator.onLine) {
    //   console.log("iventas online");
    // } else {
    //   console.log("iventas offline");
    //   toast.warn("Revisa tu conexión a Internet", {
    //     autoClose: false,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    //   });
    // }

    window.addEventListener("offline", (e) => {
      console.log("you went offline");
      toast.dismiss();
      toast.warn("Revisa tu conexión a Internet", {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    window.addEventListener("online", (e) => {
      console.log("you went online");
      toast.dismiss();
      toast.success("Conexión restablecida", {
        position: "top-center",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    return () => {
      window.removeEventListener("online", () => {});
      window.removeEventListener("offline", () => {});
    };
  }, []);

  useEffect(() => {
    if (channels.reload) dispatch(getLoginDataAction());
  }, [channels.reload]);

  useEffect(() => {
    const planStatus = user?.data?.company?.status;

    if (!planStatus) return;

    if (
      (planStatus === PlanStatus.INACTIVE || planStatus === PlanStatus.PAUSED)
      && location.pathname !== CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE
    ) {
      history.replace(CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE);
    }

    if (
      planStatus === PlanStatus.AWAITING_FIRST_PAYMENT
      && location.pathname !== CONSTANTS.WA_VINCULATION_ROUTE
    ) {
      history.replace(CONSTANTS.FIRST_PAYMENT_ROUTE);
    }

    if (planStatus !== PlanStatus.AWAITING_FIRST_PAYMENT && location.pathname === CONSTANTS.FIRST_PAYMENT_ROUTE) {
      history.replace("/chat");
    }
  }, [user?.data?.company?.status, location.pathname]);

  const clearData = () => {
    history.replace("/login");
    localStorage.clear();
    dispatch(clearDataAction());
  };

  const notificationActions = {
    messageStatus: updateMessageStatus,
    newUserName: getClientNameFromNotification,
    tagsUpdate: updateClientTags,
    newClient: updateConverations,
    newMessage: updateConverations,
    groupAdmin: handleGroupAdmin,
    unassignClient: unassignClient,
    deleteConversation: deleteConversationAction,
    archived: archivedConversationAction,
    archivedBroadcastConversation: archivedBroadConversationAction,
    markAsRead: markAsReadAction,
    markAsUnread: markAsUnReadAction,
    conversationValuable: markAsConversationValuable,
    finishBotAssignment: finishBotAssignment,
    openClient: setConversationClosed,
    onLinkedChannel: (data) => {
      console.log("onLinkedChannel => ", data);
      history.replace("/chat");
    },
  };

  const hasCircularReference = (obj) => {
    const seenObjects = new WeakSet();

    const detect = (obj) => {
      if (typeof obj !== "object" || obj === null) {
        return false;
      }

      if (seenObjects.has(obj)) {
        return true;
      }

      seenObjects.add(obj);

      for (let key in obj) {
        if (obj.hasOwnProperty(key) && detect(obj[key])) {
          return true;
        }
      }

      return false;
    };

    return detect(obj);
  };

  const replacer = (key, value) => {
    // Detectar referencias circulares y reemplazarlas
    if (key === "lastMessage" && typeof value === "object" && value !== null) {
      const cleanedClient = { ...value };

      cleanedClient.client = "[circular]";

      return cleanedClient;
    }
    return value;
  };

  const cleanCircularObjects = (inputData) => {
    if (hasCircularReference(inputData)) {
      console.log("hasCircularReference => ", inputData);
      let serializedData = JSON.stringify(inputData, replacer);
      return JSON.parse(serializedData);
    }
    // serializedData = cleanCircularObjects(serializedData);
    console.log("cleanCircularObjects => ", inputData);
    return inputData;
  };

  if (
    user?.loadingDataError ||
    (!user?.data.id && !user?.validatingToken && user?.loginError) ||
    (validateAuth && !localStorage.getItem("token"))
  ) {
    clearData();
  }

  if (!user?.data?.id || user.loadingData) {
    return <Loader />;
  }

  if ("Notification" in window && Notification.permission !== "granted") {
    Notification.requestPermission().then((permission) => {
      console.log(permission);
    });
  }

  return (
    <GridLayout>
      <Nav />
      {(!isPaymentLocation && payment?.customer) && (
        <SuscriptionAlert customer={payment.customer} />
      )}
      <Main>
        <SectionPage isChat={isChat}>
          {!isChat &&
            viewTitle &&
            !path?.includes("/broadcast") &&
            !path?.includes("/contacts") && <Title>{viewTitle}</Title>}

          <PageContainer
            isChat={isChat}
            isPageChat={path === "/chat"}
            hideTitle={
              path?.includes("/broadcast") || path?.includes("/contacts")
            }
            isCreateWhatsappTemplate={
              whatsappTemplateView === "createWhatsappTemplate" ||
              whatsappTemplateView === "whatsappTemplateCategory"
            }
          >
            <Route
              render={(componentProps) =>
                routeGuard(path, user) ? (
                  <Component {...componentProps} {...props} />
                ) : (
                  <Redirect to="/forbidden" />
                )
              }
            />
          </PageContainer>
        </SectionPage>
      </Main>
    </GridLayout>
  );
};

export default DashboardLayout;
