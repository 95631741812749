import { getLocations, getUsersByCompanyId, getLocationsByIdNotIn } from "../utils/getLocations";
import { getAgentsByCompanyIdAndNotLocationId } from "../utils/getAgents";

const LOCATIONS_INITIAL_STATE = {
  data: [],
  locationsLoading: false,
  locationsError: false,
  locationsSuccess: false,
  fetched: false
};

// constants
const GET_LOCATIONS = "GET_LOCATIONS";
const SET_LOCATIONS_ERROR = "SET_LOCATIONS_ERROR";
const SET_LOCATIONS_SUCCESS = "SET_LOCATIONS_SUCCESS";
const CLEAR_DATA = "CLEAR_DATA";

// reducer
export default function userReducer(state = LOCATIONS_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LOCATIONS:
      return { ...state, locationsLoading: true };
    case SET_LOCATIONS_ERROR:
      return {
        ...state,
        locationsLoading: false,
        locationsError: payload,
        fetched: true
      };
    case SET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locationsLoading: false,
        data: payload,
        locationsSuccess: true,
        fetched: true
      };
    case CLEAR_DATA:
      return { ...LOCATIONS_INITIAL_STATE };
    default:
      return state;
  }
}

// actions
export const getLocationsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LOCATIONS, payload: null });
    const companyId = getState().user.data.company.id;
    const locations = await getLocations(companyId);
    let formatedLocations = [
      {
        text: "Seleccione una opción",
        value: ""
      }
    ];
    locations.forEach(x => {
      formatedLocations.push({
        text: x.name,
        value: x.id
      });
    });
    dispatch({ type: SET_LOCATIONS_SUCCESS, payload: formatedLocations });
  } catch (error) {
    dispatch({ type: SET_LOCATIONS_ERROR, payload: error });
  }
};

export const getLocationsFormatedAction = () => async (dispatch, getState) => {
  try {
    const companyId = getState().user.data.companyId;
    const admins = await getUsersByCompanyId(companyId);
    const adminsLocations = admins && admins.map(x => x.location).filter(x => x);
    const agents = await getAgentsByCompanyIdAndNotLocationId(
      companyId,
      adminsLocations.map(x => x.id)
    );
    const agentsLocations = agents && agents.map(x => x.location).filter(x => x);
    const locationsNotAbleToUpdate = [...adminsLocations, ...agentsLocations];
    const locationsAbleToUpdate = await getLocationsByIdNotIn(
      companyId,
      locationsNotAbleToUpdate.map(x => x.id)
    );
    const locations = [
      ...locationsAbleToUpdate.map(x => {
        return { ...x, canUpdate: true };
      }),
      ...locationsNotAbleToUpdate.map(x => {
        return { ...x, canUpdate: false };
      })
    ];
    return locations;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const clearDataAction = () => ({ type: CLEAR_DATA, payload: null });
