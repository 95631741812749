/* eslint-disable no-restricted-syntax */
/* eslint-disable padded-blocks */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable import/no-cycle */
import { map, uniqBy } from "lodash";
import * as moment from "moment";

import { nowUtcDate } from "../tools/date-utils";
import getConverstionMessages, {
  markAsReadMessages,
  markAsNotReadMessage,
  getNotSeenMessages,
  getLastMessageId,
  getFollowUpMessageQuery,
  createFollowUpMessageQuery,
  deleteMessage,
  getFollowUpMessageQueryEdit,
  updateFollowUpMessageQuery,
  deleteFollowUpMessageQuery,
  getLastActiveWindow
} from "../utils/getMessages";
import {
  SET_CONVERSATIONS,
  SET_CONVERSATION_AS_SELECTED,
} from "./conversationsDuck";
import CONSTANTS from "../const/constants";
import { getConversationByKey } from "../utils/getConversations";
const MESSAGES_INITIAL_STATE = {
  data: [],
  selectedMessages: [],
  selectedToReply: null,
  fetched: false,
  isSelectionActive: false,
  fetchingMoreMessages: false,
  fetchingNewMessage: false,
  fetchedNewMessageError: false,
  showNewMessagesButton: false,
  isScrollMessagesBottom: true,
  lastMessageCursor: 0,
  forwardingSuccess: false,
  isSMSShowed: true,
  isWAShowed: true,
  windowRemaining: 0,
};

let isFirstMessage = true;

// constants
const SET_MESSAGES = "SET_MESSAGES";
const SET_MESSAGES_SELECTION_ACTIVE = "SET_MESSAGES_SELECTION_ACTIVE";
const SET_MESSAGES_SELECTION_DISABLED = "SET_MESSAGES_SELECTION_DISABLED";
export const ADD_NEW_MESSAGES = "ADD_NEW_MESSAGES";
const GET_MESSAGES = "GET_MESSAGES";
const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";
const HANDLE_NEW_MESSAGES_BUTTON = "HANDLE_NEW_MESSAGES_BUTTON";
const ADD_NEW_MESSAGE_TO_CURSOR = "ADD_NEW_MESSAGE_TO_CURSOR";
const CLEAR_MESSAGES = "CLEAR_MESSAGES";
const SET_CURSOR = "SET_CURSOR";
const CLEAR_DATA = "CLEAR_DATA";
const SET_F0RWARDING_SUCCESS = "SET_F0RWARDING_SUCCESS";
const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
const SET_MESSAGE_TO_REPLY = "SET_MESSAGE_TO_REPLY";
const SET_IS_SMS_SHOWED = "SET_IS_SMS_SHOWED";
const SET_IS_WA_SHOWED = "SET_IS_SMS_SHOWED";
const UNSET_MESSAGE_TO_REPLY = "UNSET_MESSAGE_TO_REPLY";
const SET_WINDOW_REMAINING_TIME = "SET_WINDOW_REMAINING_TIME"
// reducer
export default function messagesReducer(
  state = MESSAGES_INITIAL_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_MESSAGES:
      return payload;
    case ADD_NEW_MESSAGES:
      return { ...state, data: payload };
    case UPDATE_MESSAGE_STATUS:
      return { ...state, data: payload };
    case GET_MESSAGES:
      return { ...state, fetching: true };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: payload.messages,
        lastMessageCursor: payload.lastMessageCursor,
        selectedMessages: payload.selectedMessages,
      };
    case GET_MESSAGES_ERROR:
      return { ...state, fetching: false, error: payload };
    case HANDLE_NEW_MESSAGES_BUTTON:
      return { ...state, showNewMessagesButton: payload };
    case ADD_NEW_MESSAGE_TO_CURSOR:
      return { ...state, lastMessageCursor: state.lastMessageCursor + 1 };
    case SET_CURSOR:
      return { ...state, lastMessageCursor: 0 };
    case CLEAR_MESSAGES:
      return { ...MESSAGES_INITIAL_STATE };
    case CLEAR_DATA:
      return { ...MESSAGES_INITIAL_STATE };
    case SET_MESSAGES_SELECTION_ACTIVE:
      return {
        ...state,
        isSelectionActive: true,
        selectedMessages: payload.selectedMessages,
      };
    case SET_IS_SMS_SHOWED:
      return {
        ...state,
        isSMSShowed: payload.isSMSShowed,
        isWAShowed: payload.isWAShowed,
      };
    case SET_WINDOW_REMAINING_TIME:
        return {
          ...state,
          windowRemaining: payload.windowRemaining,
        };
    case SET_IS_WA_SHOWED:
      return {
        ...state,
        isWAShowed: payload.isWAShowed,
        isSMSShowed: payload.isSMSShowed,
      };
    case SET_MESSAGES_SELECTION_DISABLED:
      return { ...state, isSelectionActive: false, selectedMessages: [] };
    case SET_F0RWARDING_SUCCESS:
      return { ...state, forwardingSuccess: true, selectedMessages: [] };
    case SET_MESSAGE_TO_REPLY:
      return { ...state, selectedToReply: payload };
    case UNSET_MESSAGE_TO_REPLY:
      return { ...state, selectedToReply: null };
    default:
      return state;
  }
}

// actions

export const setMessageToReplyAction = (id) => (dispatch, getState) => {
  const message = getState().messages.data.filter(
    (message) => message.id === id
  )[0];
  dispatch({ type: SET_MESSAGE_TO_REPLY, payload: message });
};

export const unsetMessageToReplyAction = () => (dispatch) => {
  dispatch({ type: SET_MESSAGE_TO_REPLY });
};

export const setMessagesSelectionActive = (status) => (dispatch) => {
  dispatch({ type: SET_MESSAGES_SELECTION_ACTIVE, payload: status });
};

export const setMessagesSelectionDisabled = (status) => (dispatch) => {
  dispatch({ type: SET_MESSAGES_SELECTION_DISABLED, payload: status });
};

export const setMessagesAction = (messages) => ({
  type: SET_MESSAGES,
  payload: messages,
});

export const setIsMsgShowed =
  (isWAShowed, isSMSShowed) => async (dispatch, getState) => {
    console.log("ON CLICK setIsMsgShowed => ", isWAShowed, isSMSShowed);

    await dispatch({
      type: SET_IS_WA_SHOWED,
      payload: { isWAShowed: isWAShowed, isSMSShowed: isSMSShowed },
    });
    await dispatch(getMessagesAction(false));
  };

export const setIsSMSMsgShowed = (isShowed) => async (dispatch, getState) => {
  console.log("ON CLICK setIsSMSMsgShowed => ", isShowed);

  //await dispatch({ type: SET_IS_SMS_SHOWED, payload: { isSMSShowed:isShowed } });
  //await  dispatch(getMessagesAction(false));
};

export const handleNewMessagesButtonAction = (status) => (dispatch) => {
  dispatch({ type: HANDLE_NEW_MESSAGES_BUTTON, payload: status });
};

export const updateMessageId =
  (previousId, message) => (dispatch, getState) => {
    const conversations = [...getState().conversations?.data];
    const newMessages = getState().messages.data.map((x) => {
      if (x.id === previousId) {
        return { ...message, sent: true };
      } else {
        return { ...x };
      }
    });
    let updateConversations = false;
    const newConversations = conversations.map((x) => {
      if (x?.lastMessage?.id === previousId) {
        updateConversations = true;
        return { ...x, lastMessage: { ...message } };
      } else {
        return { ...x };
      }
    });
    const messagesUniq = uniqBy(newMessages, "id");
    console.log("1");
    dispatch({ type: ADD_NEW_MESSAGES, payload: messagesUniq });
    if (updateConversations) {
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
    }
  };
export const updateMessageStatusId =
  (previousId, messageStatus, messages, errorCode = null, errorInfo = null) =>
  (dispatch) => {
    const messagesS = [...messages];
    const messageToUpdateIndex = messages.findIndex((x) => x.id === previousId);
    messagesS[messageToUpdateIndex].messageStatus = messageStatus;
    messagesS[messageToUpdateIndex].errorCode = errorCode;
    messagesS[messageToUpdateIndex].errorInfo = errorInfo;
    dispatch({ type: UPDATE_MESSAGE_STATUS, payload: messagesS });
  };

export const updateMessageWithError = (id) => (dispatch, getState) => {
  const messages = [...getState().messages.data];
  const messageToUpdateIndex = messages.findIndex((x) => x.id === id);
  messages[messageToUpdateIndex] = {
    ...messages[messageToUpdateIndex],
    sent: false,
    error: true,
  };
  console.log("2");
  dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
};

export const selectingMessagesAction =
  (messageIds) => async (dispatch, getState) => {
    try {
      const messages = getState().messages.data;
      const currentSelectedMessages = getState().messages.selectedMessages;

      for (const messageId of messageIds) {
        const index = currentSelectedMessages.indexOf(messageId);
        if (index === -1) currentSelectedMessages.push(messageId);
        else currentSelectedMessages.splice(index, 1);
      }

      for (const message of messages) {
        message.selected = currentSelectedMessages.some(
          (id) => message.messageApiId === id
        );
      }

      dispatch({
        type: SET_MESSAGES_SELECTION_ACTIVE,
        payload: { selectedMessages: currentSelectedMessages },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const addLastMessageAction = (lastMessage) => (dispatch, getState) => {
  const messages = [...getState().messages.data];
  const userId = getState().user.data.id;
  if (isFirstMessage) {
    isFirstMessage = false;
  } else {
    isFirstMessage = true;
  }

  if (lastMessage.senderId !== userId) {
    dispatch(handleNewMessagesButtonAction(true));
  }

  if (messages[0]?.id === lastMessage.id) {
    const lastMessageData = { ...lastMessage, sent: true };
    messages.splice(0, 1, lastMessageData);
    console.log("3");
    dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
  } else {
    const lastMessageData = { ...lastMessage, sent: true };
    messages.unshift(lastMessageData);
    console.log("4");
    dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
    // dispatch({ type: ADD_NEW_MESSAGE_TO_CURSOR });
  }
};

export const deleteMessageAction = (id) => async (dispatch, getState) => {
  await deleteMessage(id);
  const messages = [...getState().messages.data];
  const newMessages = messages.filter((x) => x.id !== id);
  console.log("5");
  dispatch({ type: ADD_NEW_MESSAGES, payload: newMessages });
};

export const getMessagesLoaderAction = () => (dispatch) => {
  dispatch({ type: GET_MESSAGES });
};

export const resetCursorAction = () => (dispatch) => {
  dispatch({ type: SET_CURSOR, payload: 0 });
};

export const setWindowRemainingTimeAction = (clientId) => async (dispatch, getState) => {
  

  let windowRemaining = null;
    if (clientId) {

      const lastWindowMessage = await getLastActiveWindow(clientId);
      console.log('GETTING LAST WINDOWS => ', lastWindowMessage)

      if (lastWindowMessage?.windowExpiration) {
        const windowTimestamp = new Date(lastWindowMessage?.windowExpiration).getTime();
        const currentTimestamp = new Date().getTime();
  
        console.log('windowTimestamp local => ', windowTimestamp)
        console.log('currentTimestamp => ', currentTimestamp)
  
        const diff = moment.duration(windowTimestamp - currentTimestamp, 'milliseconds');
        console.log('diff => ', diff)
  
        const diffMinutes = Math.floor(diff.asMinutes());

        console.log('diffMinutes => ', diffMinutes)

        windowRemaining = diffMinutes
      }

      dispatch({
        type: SET_WINDOW_REMAINING_TIME,
        payload: {
          windowRemaining: windowRemaining,
        },
      });
    }
}

export const setConstWindowRemainingTimeAction = (windowRemaining) => async (dispatch, getState) => {
  dispatch({
    type: SET_WINDOW_REMAINING_TIME,
    payload: {
      windowRemaining: windowRemaining,
    },
  });
    
}

export const getMessagesAction = (widthSkip, currentConversation) => async (dispatch, getState) => {
  try {
    console.log(
      "getMessagesAction!!!!!!!!!!!!!!!!! WA SMS => ",
      getState().messages.isWAShowed,
      getState().messages.isSMSShowed
    );
    isFirstMessage = true;
    dispatch({ type: GET_MESSAGES });

    console.log("Conversation state", getState().conversations.current);
    console.log("Conversation currentConversation", currentConversation);
    let selectedConversation;
    if (currentConversation) {
      selectedConversation = { ...currentConversation };
    } else {
      selectedConversation = { ...getState().conversations.current };
    }
    const conversationsIds = [];
    if (getState().messages.isWAShowed) {
      conversationsIds.push(selectedConversation?.id);
    }
    // if (getState().messages.isSMSShowed) {
    //   const selectedSpaces = getState().spaces.selected;

    //   const { channels } = selectedSpaces;
    //   const smsChannel = channels
    //     .filter((c) => c?.platform?.name === CONSTANTS.SMS_PLATFORM)
    //     ?.pop();

    //   console.log(
    //     "SMS-CHANNEL SENDERID => ",
    //     smsChannel,
    //     selectedConversation?.senderId
    //   );
    //   const smsKey = smsChannel?.id + selectedConversation?.senderId;
    //   console.log(
    //     "SMS-CHANNEL SENDERID smsKey => ",
    //     smsChannel,
    //     selectedConversation?.senderId,
    //     smsKey
    //   );

    //   const conversationSMS = await getConversationByKey(smsKey);
    //   console.log("conversationSMS => ", conversationSMS);

    //   if (conversationSMS) conversationsIds.push(conversationSMS?.id);
    // }

    console.log("CONVERATIONSIDS =>>>>>", conversationsIds);

    const currentCursor = widthSkip ? getState().messages.lastMessageCursor : 0;
    console.log("Conversation 2", currentCursor);
    if (currentCursor === -1) return;
    console.log("Conversation 3", currentCursor);
    const newMessages = await getConverstionMessages(
      conversationsIds.filter((c) => c),
      currentCursor
    );
    console.log("Conversation 4", newMessages);
    const seenBy = getState().user.data.name;



    if (
      currentCursor === 0 &&
      selectedConversation?.id === getState().conversations.current?.id
    ) {
      console.log("Conversation 6");
      dispatch({
        type: GET_MESSAGES_SUCCESS,
        payload: {
          messages: (newMessages ?? [])?.map((x) => ({
            ...x,
            sent: true,
            seenBy: x.seenBy || seenBy,
            seenAt: x.seenAt || Date.now(),
            time: x.time && parseInt(`${x.time.toString()}000`, 10),
            selected: false,
          })),
          lastMessageCursor: 30,
          isSelectionActive: getState().messages.isSelectionActive,
          selectedMessages: [...getState().messages.selectedMessages],
          isSMSShowed: getState().messages.isSMSShowed,
          isWAShowed: getState().messages.isWAShowed,
        },
      });
    } else if (
      selectedConversation?.id === getState().conversations.current?.id
    ) {
      console.log("Conversation 7");
      const previousMessages = getState().messages.data;
      if (newMessages.length === 0) {
        console.log("Conversation 9");
        dispatch({
          type: GET_MESSAGES_SUCCESS,
          payload: {
            messages: previousMessages,
            lastMessageCursor: -1,
            isSelectionActive: getState().messages.isSelectionActive,
            selectedMessages: [...getState().messages.selectedMessages],
            isSMSShowed: getState().messages.isSMSShowed,
            isWAShowed: getState().messages.isWAShowed,
          },
        });
      } else {
        console.log("Conversation 10");
        dispatch({
          type: GET_MESSAGES_SUCCESS,
          payload: {
            messages: [
              ...previousMessages,
              ...newMessages.map((x) => ({
                ...x,
                sent: true,
                seenBy: x.seenBy || seenBy,
                seenAt: x.seenAt || Date.now(),
                time: x.time && parseInt(`${x.time.toString()}000`, 10),
                selected: false,
              })),
            ],
            isSelectionActive: false,
            lastMessageCursor: currentCursor + newMessages.length,
            selectedMessages: [...getState().messages.selectedMessages],
            isSMSShowed: getState().messages.isSMSShowed,
            isWAShowed: getState().messages.isWAShowed,
          },
        });
      }
    } else {
      dispatch(getMessagesAction(widthSkip));
    }

    //await setWindowRemainingTime(selectedConversation?.id);

  } catch (error) {
    console.error("GET_MESSAGES_ERROR", error);
    dispatch({ type: GET_MESSAGES_ERROR, payload: error });
  } finally {
  }
};

export const seenMessage = (conversation) => async (_, getState) => {
  try {
    const pendingMessages = await getNotSeenMessages(conversation.id);
    const userName = getState().user.data?.name;
    const date = nowUtcDate();
    const pendingIds = map(pendingMessages, (message) => message.id);
    await markAsReadMessages(pendingIds, userName, date, conversation.id);
  } catch (error) {
    throw error;
  }
};

export const unseenMessage = (conversation) => async () => {
  try {
    const pendingMessage = await getLastMessageId(conversation.id);
    await markAsNotReadMessage(pendingMessage, conversation.id);
  } catch (error) {
    throw error;
  }
};

export const sendNewMessageAction = (lastMessage) => (dispatch, getState) => {
  const messages = [...getState().messages.data];
  const lastMessageData = { ...lastMessage };
  messages.unshift(lastMessageData);
  console.log("6");
  dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
  // dispatch({ type: ADD_NEW_MESSAGE_TO_CURSOR });

  const conversations = [...getState().conversations.data];
  const currentConversation = { ...getState().conversations.current };
  const currentConversationIndex = conversations.findIndex(
    (x) => x.id === currentConversation.id
  );
  const currentInArray = { ...conversations[currentConversationIndex] };
  currentInArray.lastMessage = {
    ...lastMessageData,
    content: lastMessageData.text,
  };
  // conversations.splice(currentConversationIndex, 1);
  // conversations.unshift(currentInArray);
  const payload = {
    data: conversations,
    current: currentInArray,
  };
  dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
};

export const deleteTemporalMessageAction =
  (messageId) => (dispatch, getState) => {
    const messages = [...getState().messages.data];
    const messageIndex = messages.find((x) => x.id === messageId);
    if (messageIndex !== -1) {
      messages.splice(messageIndex, 1);
    }
    console.log("7");
    dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
  };

export const addLastMessageError = () => (dispatch, getState) => {
  const messages = [...getState().messages.data];
  const lastMessageData = { ...messages[0], error: true };
  messages.splice(0, 1, lastMessageData);
  console.log("8");
  dispatch({ type: ADD_NEW_MESSAGES, payload: messages });
};

export const getFollowUpMessage = (selectedConversationId) => async () => {
  const followUpMessage = await getFollowUpMessageQuery(selectedConversationId);
  return followUpMessage;
};

export const createFollowUpMessage = (
  selectedConversationId,
  agentId,
  content,
  sendAt,
  channelId,
  templateId,
  variables,
  fileId
) => async () => {
  console.log('LLEGA FILE ID ZERO ===> ', fileId)
  const followUpMessage = await createFollowUpMessageQuery(
    selectedConversationId,
    agentId,
    content,
    sendAt,
    channelId,
    templateId,
    variables,
    fileId
  );
  return followUpMessage;
};

export const followUpMessageUpdate =
  (id, content, sendAt, templateId, clientId, agentId, channelId, variables, fileId) =>
  async () => {
    console.log('variables cero => ', variables)
    const followUpMessage = await updateFollowUpMessageQuery(
      id,
      content,
      sendAt,
      templateId,
      clientId,
      agentId,
      channelId,
      variables,
      fileId
    );
    return followUpMessage;
  };

export const getFollowUpMessageForEdit =
  (selectedConversationId) => async () => {
    const followUpMessage = await getFollowUpMessageQueryEdit(
      selectedConversationId
    );
    return followUpMessage;
  };

export const deleteFollowUpMessage = (selectedConversationId) => async () => {
  const deleteFollowUpMessageQ = await deleteFollowUpMessageQuery(
    selectedConversationId
  );
  return deleteFollowUpMessageQ;
};

export const clearMessagesAction = () => ({
  type: CLEAR_MESSAGES,
  payload: null,
});
