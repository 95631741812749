import { getAlbums, createAlbum, uploadFile, getFilesAndSubalbums, deleteFile, deleteAlbum, uploadVideo } from '../utils/multimedia';

const MULTIMEDIA_INITIAL_STATE = {
  albums: [],
  selectedAlbum: {},
  subAlbums: [],
  albumFiles: [],
  selectedSubAlbum: {},
  selectedFile: {},
  subAlbumFiles: [],
  selectedMessageFile: {},
  fetching: false,
  fetchingAlbumData: false,
  error: false,
};

const GET_ALBUMS = 'GET_ALBUMS';
const GET_ALBUMS_SUCCESS = 'GET_ALBUMS_SUCCESS';
const GET_ALBUMS_ERROR = 'GET_ALBUMS_ERROR';
const SET_SELECTED_ALBUM = 'SET_SELECTED_ALBUM';
const GET_ALBUM_DATA = 'GET_ALBUM_DATA';
const SET_SUBALBUMS = 'SET_SUBALBUMS';
const SET_FILES = 'SET_FILES';
const ADD_NEW_FILE = 'ADD_NEW_FILE';
const SET_SELECTED_SUBALBUM = 'SET_SELECTED_SUBALBUM';
const SET_SUBALBUM_FILES = 'SET_SUBALBUM_FILES';
const ADD_NEW_SUBALBUM_FILE = 'ADD_NEW_SUBALBUM_FILE';
const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
const SET_SELECTED_MESSAGE_FILE = 'SET_SELECTED_MESSAGE_FILE';
const CLEAR_SELECTED_MESSAGE_FILE = 'CLEAR_SELECTED_MESSAGE_FILE';
const CLEAR_MULTIMEDIA_DATA = 'CLEAR_MULTIMEDIA_DATA';

export default function adminsReducer(state = { ...MULTIMEDIA_INITIAL_STATE }, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALBUMS:
      return { ...state, fetching: true, error: false };
    case GET_ALBUMS_SUCCESS:
      return { ...state, fetching: false, error: false, albums: payload };
    case GET_ALBUMS_ERROR:
      return { ...state, fetching: false, error: payload };
    case SET_SELECTED_ALBUM:
      return { ...state, fetching: false, error: false, selectedAlbum: payload };
    case GET_ALBUM_DATA:
      return { ...state, fetchingAlbumData: true };
    case SET_SUBALBUMS:
      return { ...state, fetchingAlbumData: false, subAlbums: payload };
    case SET_FILES:
      return { ...state, fetchingAlbumData: false, albumFiles: payload };
    case ADD_NEW_FILE:
      return { ...state, albumFiles: [...state.albumFiles, payload] };
    case SET_SELECTED_SUBALBUM:
      return { ...state, selectedSubAlbum: payload };
    case SET_SUBALBUM_FILES:
      return { ...state, fetchingAlbumData: false, subAlbumFiles: payload };
    case ADD_NEW_SUBALBUM_FILE:
      return { ...state, subAlbumFiles: [...state.subAlbumFiles, payload] };
    case SET_SELECTED_FILE:
      return { ...state, selectedFile: payload };
    case SET_SELECTED_MESSAGE_FILE:
      return { ...state, selectedMessageFile: payload };
    case CLEAR_SELECTED_MESSAGE_FILE:
      return { ...state, selectedMessageFile: {} };
    case CLEAR_MULTIMEDIA_DATA:
      return { ...MULTIMEDIA_INITIAL_STATE };
    default:
      return state;
  }
}

export const getAlbumsAction = (spaces) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALBUMS });
    const albums = await getAlbums(spaces);
    dispatch({ type: GET_ALBUMS_SUCCESS, payload: albums });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_ALBUMS_ERROR, payload: error });
  }
};

export const createAlbumAction = (name, selectedSpaceId, parentId) => async (dispatch, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const spaceId = selectedSpaceId || getState().spaces.selected.id;
    const newAlbum = await createAlbum(companyId, spaceId, name, parentId);
    dispatch({ type: SET_SELECTED_ALBUM, payload: newAlbum });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setAlbumAsSelectedAction = (albumId) => (dispatch, getState) => {
  const { albums } = getState().multimedia;
  const selectedAlbum = albums.find((album) => album.id === albumId);
  dispatch({ type: SET_SELECTED_ALBUM, payload: selectedAlbum });
};

export const clearSelectedAlbumAction = () => (dispatch) => {
  dispatch({ type: SET_SELECTED_ALBUM, payload: {} });
  dispatch({ type: SET_SUBALBUMS, payload: [] });
  dispatch({ type: SET_FILES, payload: [] });
  dispatch({ type: SET_SELECTED_FILE, payload: {} });
};

export const uploadFileAction = (formData, onUploadProgress, albumType) => async (dispatch) => {
  const response = await uploadFile(formData, onUploadProgress);
  if (albumType === 'album') dispatch({ type: ADD_NEW_FILE, payload: response });
  else if(albumType !== 'templateMedia') dispatch({ type: ADD_NEW_SUBALBUM_FILE, payload: response });
  return response;
};

export const getFilesAndSubalbumsAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALBUM_DATA });
  const { selectedAlbum } = getState().multimedia;
  const { childs: subAlbums, files } = await getFilesAndSubalbums(selectedAlbum.id);
  dispatch({ type: SET_SUBALBUMS, payload: subAlbums });
  dispatch({ type: SET_FILES, payload: files });
};

export const deleteFileAction = (fileId, albumType) => async (dispatch, getState) => {
  const response = await deleteFile(fileId);
  const { albumFiles, subAlbumFiles } = getState().multimedia;
  if (albumType === 'album') {
    const newAlbumFiles = albumFiles.filter((file) => file.id !== fileId);
    dispatch({ type: SET_FILES, payload: newAlbumFiles });
  } else {
    const newAlbumFiles = subAlbumFiles.filter((file) => file.id !== fileId);
    dispatch({ type: SET_SUBALBUM_FILES, payload: newAlbumFiles });
  }
  return response;
};

export const deleteAlbumAction = (albumType) => async (_, getState) => {
  const { selectedAlbum, selectedSubAlbum } = getState().multimedia;
  const albumId = albumType === 'album' ? selectedAlbum.id : selectedSubAlbum.id;
  await deleteAlbum(albumId);
};

export const createSubAlbumAction = (name) => async (dispatch, getState) => {
  try {
    const { id: parentId, space: { id: parentSpaceId } } = getState().multimedia.selectedAlbum;
    const companyId = getState().user.data.company.id;
    const newAlbum = await createAlbum(companyId, parentSpaceId, name, parentId);
    dispatch({ type: SET_SELECTED_SUBALBUM, payload: newAlbum });
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setSubAlbumAsSelectedAction = (subAlbumId) => (dispatch, getState) => {
  const { subAlbums } = getState().multimedia;
  const selectedAlbum = subAlbums.find((album) => album.id === subAlbumId);
  dispatch({ type: SET_SELECTED_SUBALBUM, payload: selectedAlbum });
};

export const getSubAlbumFilesAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_ALBUM_DATA });
  const { selectedSubAlbum } = getState().multimedia;
  const { files } = await getFilesAndSubalbums(selectedSubAlbum.id);
  dispatch({ type: SET_SUBALBUM_FILES, payload: files });
};

export const uploadVideoAction = (albumType, url) => async (dispatch, getState) => {
  const { selectedAlbum, selectedSubAlbum } = getState().multimedia;
  let albumId;
  if (albumType === 'album') {
    albumId = selectedAlbum.id;
    const response = await uploadVideo(url, albumId);
    dispatch({ type: ADD_NEW_FILE, payload: response });
  } else {
    albumId = selectedSubAlbum.id;
    const response = await uploadVideo(url, albumId);
    dispatch({ type: ADD_NEW_SUBALBUM_FILE, payload: response });
  }
};

export const setFileAsSelectedAction = (fileId) => (dispatch, getState) => {
  const { albumFiles, subAlbumFiles } = getState().multimedia;
  const allFiles = albumFiles.concat(subAlbumFiles);
  const selectedFile = allFiles.find((file) => file.id === fileId);
  dispatch({ type: SET_SELECTED_FILE, payload: selectedFile });
};

export const setTempFileAsSelected = (file) => (dispatch) => {
  dispatch({ type: SET_SELECTED_FILE, payload: file });
};

export const setMessageFileAsSelectedAction = (file) => (dispatch) => {
  dispatch({ type: SET_SELECTED_MESSAGE_FILE, payload: file });
};

export const selectFileFromQuickAnswer = (file) => (dispatch) => {
  dispatch({ type: SET_SELECTED_FILE, payload: file });
};

export const selectFileFromUpload = () => (dispatch, getState) => {
  const { selectedFile } = getState().multimedia;
  dispatch({ type: SET_SELECTED_MESSAGE_FILE, payload: selectedFile });
};

export const clearSelectedMessageFile = () => (dispatch) => {
  dispatch({ type: CLEAR_SELECTED_MESSAGE_FILE });
};

export const clearMultimediaState = () => (dispatch) => {
  dispatch({ type: CLEAR_MULTIMEDIA_DATA });
};
