/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  RadioLabel,
  RadioContainer,
  RadioInput,
  InputError,
} from './RadioOptions.style';

const RadioOptions = forwardRef(({ name, options, label = null, error }, ref) => (
  <>
    {label && <RadioLabel>{label}</RadioLabel>}
    { options?.map((option) => (
      <RadioContainer key={option.value}>
        <RadioInput type="radio" name={name} value={option.value} ref={ref} />
        <label htmlFor={option.value}>{option.text}</label>
      </RadioContainer>
    ))}
    { error && <InputError>{error}</InputError> }
  </>
));

RadioOptions.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  ref: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default RadioOptions;
