import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";

import { getLabelMessage } from "../../../const/labelService";
import Button from "../../../components/shared/components/button/Button.style";
import { NewWhatsappTitle } from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import {
  MessagePreview,
  PreviewImageContainer,
} from "../../settings/whatsappTemplates/whatsappTemplateMessagePreview/whatsappTemplateMessagePreview";
import {
  PreviewHeader,
  PreviewIcon,
} from "../../../components/shared/components/dropZoneImage/DropZoneImage.style";
import PdfPreview from "../../../components/album/filePreview/pdfPreview/PdfPreview.component";
import {
  CurrentTime,
  WhatsappTemplateDetails,
} from "../../../components/whatsappTemplateSelect/WhatsappTemplateSelect.style";
import MessageViewed from "../../../components/messages/messageViewed/MessageViewed";
import CONSTANTS from "../../../const/constants";
import fileIcon from "../../../assets/svg/fileIcon.svg";
import { ContentSpan, BroadcastAppCard } from "./BroadcastView.styles";

const BroadcastPreview = () => {
  const history = useHistory();
  const channelId = useSelector((state) => state.campaign.create.channelId);
  const whatsappTemplate = useSelector((state) => state.campaign?.selected);
  const fileUrl = whatsappTemplate?.components
    ? whatsappTemplate?.components?.[0]?.exampleHeader?.header_handle?.[0]
    : "";
  const templateInfo = useSelector((state) => state.campaign.selected);
  const text = whatsappTemplate?.components
    ? whatsappTemplate?.components?.[0]?.text
    : "";
  const exampleBody = whatsappTemplate?.components?.[0]?.exampleBody?.body_text;
  const [fileType, setfileType] = useState("");
  const labels = [
    "broadcastPreviewTitle",
    "variableInstructionsMessagePreview",
    "broadcastName",
    "whatsappTemplateName",
  ];
  const lbls = getLabelMessage(labels);

  const handleActionButton = () => {
    history.push("/broadcast/createTag");
  };

  useEffect(() => {
    const fileType = fileUrl?.split(".")?.pop();
    if (fileType) {
      switch (fileType.toUpperCase()) {
        case "JPG":
          setfileType("JPG");
          break;
        case "PNG":
          setfileType("PNG");
          break;
        case "MP4":
          setfileType("MP4");
          break;

        case "PPTX":
          setfileType(CONSTANTS.FILE_TYPE_PPTX);
          break;

        case "DOCX":
          setfileType(CONSTANTS.FILE_TYPE_DOCX);
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          console.log("CONSTANTS.FILE_TYPE_XLSX: ", CONSTANTS.FILE_TYPE_XLSX);
          setfileType(CONSTANTS.FILE_TYPE_XLSX);
          break;
        case "PDF":
          setfileType(CONSTANTS.FILE_TYPE_PDF);
          break;

        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);

  const setVariablesContent = (text, example) => {
    let contentMessage = "";
    const formatedText = text
      .replace(/\n/g, "<br>")
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
      .replace(/_(.*?)_/g, "<em>$1</em>")
      .replace(/~(.*?)~/g, "<del>$1</del>");
    if (example) {
      contentMessage = formatedText;
      example.forEach((element) => {
        console.log(element);
        contentMessage = contentMessage?.replace(
          /\{\{\d\}\}/,
          `<span class="select-var">${element}</span>`
        );
      });
      return contentMessage;
    }

    return formatedText;
  };

  return (
    <BroadcastAppCard>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <NewWhatsappTitle style={{ paddingBottom: "1.25rem" }}>
          {lbls.broadcastPreviewTitle}
        </NewWhatsappTitle>
        <div
          style={{
            backgroundColor: "#e3e3e3",
            width: "26.625rem",
            minHeight: "10.625rem",
            height: "auto",
            paddingTop: "1.563rem",
            paddingBottom: "0.25rem",
          }}
        >
          <MessagePreview
            style={{
              position: "relative",
              top: "0",
            }}
          >
            {fileUrl && (
              <PreviewImageContainer>
                {(fileType === "JPG" || fileType === "PNG") && (
                  <PreviewHeader
                    alt="preview"
                    width="100%"
                    height="100%"
                    image
                    messagePreview
                    style={{
                      backgroundImage: `url(${
                        templateInfo.newFileUrl || fileUrl
                      })`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  />
                )}
                {fileType === "MP4" && (
                  <PreviewHeader doc messagePreview>
                    <video controls width="100%" height="100%">
                      <source
                        src={templateInfo.newFileUrl || fileUrl}
                        type="video/mp4"
                      />
                      <p>Tu navegador no admite el elemento video.</p>
                    </video>
                  </PreviewHeader>
                )}
                {(fileType === "PDF" ||
                  fileType === "XLSX" ||
                  fileType === "PPTX" ||
                  fileType === "TXT") && (
                  <PreviewHeader doc messagePreview>
                    {fileType === "PDF" && (
                      <PdfPreview
                        url={templateInfo.newFileUrl || fileUrl}
                        templateMedia
                      />
                    )}
                    {fileType !== "PDF" && (
                      <PreviewIcon src={fileIcon} width={50} height={50} />
                    )}
                  </PreviewHeader>
                )}
              </PreviewImageContainer>
            )}
            <ContentSpan>
              {parse(setVariablesContent(text, exampleBody))}
            </ContentSpan>
            <WhatsappTemplateDetails>
              <CurrentTime>{moment().format("HH:mm")}</CurrentTime>
              <MessageViewed messageStatus={"viewed"} templateSelect />
            </WhatsappTemplateDetails>
          </MessagePreview>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BackButton
          style={{
            position: "relative",
            bottom: "initial",
            left: "initial",
          }}
          templateAction
          onClick={() => {
            history.push("/broadcast/selectDate");
          }}
        >
          <BackButtonIconContainer>
            <BackButtonIcon src={backButtonIcon} />
          </BackButtonIconContainer>
          <BackButtonText>Regresar</BackButtonText>
        </BackButton>

        <Button createTemplate color="primary" onClick={handleActionButton}>
          Continuar
        </Button>
      </div>
    </BroadcastAppCard>
  );
};

export default BroadcastPreview;
