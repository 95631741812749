import styled from 'styled-components';

export const RadioLabel = styled.div`
  margin-bottom: 0.5rem;
`;

export const RadioContainer = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const RadioInput = styled.input`
  margin-right: 0.25rem;
  cursor: pointer;
`;

export const InputError = styled.div`
  font-size: 1rem;
  color: red;
`;
