import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

import { ChatDropzoneContainer } from "./ChatDropzone.style";
import FilesSection from "../album/filesSection/FilesSection.component";
import {
  setTempFileAsSelected,
} from "../../redux/multimediaDuck";
import CONSTANTS from "../../const/constants";

const ChatDropzone = ({
  fileType,
  templateMedia,
  isPreview,
  newTheme,
  isSelectTemplate,
}) => {
  const dispatch = useDispatch();

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const url = await URL.createObjectURL(file);
      const tempFile = {
        id: CONSTANTS.UPLOAD_ID,
        file,
        url,
        fileType,
        fileName: file.name,
        weight: file.size,
      };

      dispatch(setTempFileAsSelected(tempFile));

      // if (templateMedia) {
      //   acceptedFiles.map(async (file) => {
      //     const formData = new FormData();
      //     formData.append("file", file);
      //     formData.append("name", file.name);
      //     formData.append("weight", file.size);
      //     let fileType = null;
      //     if (file.type === "image/jpeg" || file.type === "image/png")
      //       fileType = CONSTANTS.FILE_TYPE_IMAGE;
      //     else if (file.type === "application/pdf")
      //       fileType = CONSTANTS.FILE_TYPE_PDF;
      //     else if (file.type === "video/mp4")
      //       fileType = CONSTANTS.FILE_TYPE_VIDEO;
      //     formData.append("fileType", fileType);
      //     const s3Url = await dispatch(
      //       uploadFileAction(
      //         formData,
      //         (progress, error) => console.log(progress, error),
      //         'templateMedia'
      //       )
      //     );
      //     console.log(s3Url)
      //     dispatch(setTempFileAsSelected({ ...tempFile, url: s3Url.url }));

      //     // deleteUploadedFile(file.id);
      //   });
      // }
    }
  };

  const fileTypes = (constFileType) => {
    console.log("constFileType ===== > ", constFileType);
    switch (constFileType) {
      case CONSTANTS.FILE_TYPE_IMAGE:
        return ["image/jpg", "image/png", "image/jpeg"];
      case CONSTANTS.FILE_TYPE_GENERIC:
        return ["*"];
      case CONSTANTS.FILE_TYPE_VIDEO:
        return ["video/mp4", "video/3gpp"];
      case CONSTANTS.FILE_TYPE_PDF:
        return ["application/pdf"];
      case CONSTANTS.FILE_TYPE_TEMPLATE:
        return ["image/jpg", "image/png", "application/pdf", "video/mp4"];
      case "PNG":
      case "JPG":
        return ["image/png", "image/jpeg"];

      default:
        return ["*"];
    }
  };

  const mainLabel = (constFileType) => {
    switch (constFileType) {
      case CONSTANTS.FILE_TYPE_IMAGE:
        return "filesDropZoneMainImageText";
      case CONSTANTS.FILE_TYPE_PDF:
        return "filesDropZoneMainTextPdf";
      case CONSTANTS.FILE_TYPE_VIDEO:
        return "filesDropZoneMainVideoText";
      case CONSTANTS.FILE_TYPE_TEMPLATE:
        return "filesDropZoneMainTemplateText";
      default:
        return "filesDropZoneMainText";
    }
  };

  return (
    <ChatDropzoneContainer newTheme={newTheme}>
      <FilesSection
        hideTitle
        onDrop={onDrop}
        maxSize={templateMedia ? undefined : 16777216}
        prepareFiles={() => null}
        acceptedFilesTypes={fileTypes(fileType)}
        mainLabel={mainLabel(fileType)}
        canUploadFiles
        templateMedia={templateMedia}
        isPreview={isPreview}
        newTheme={newTheme}
        isSelectTemplate={isSelectTemplate}
      />
    </ChatDropzoneContainer>
  );
};

ChatDropzone.propTypes = {
  fileType: PropTypes.string,
};

export default ChatDropzone;
