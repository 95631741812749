/* eslint-disable no-restricted-syntax */
import { isUndefined, map, find, concat, remove } from 'lodash';

import { changeCategoryAction } from './conversationsDuck';
import { translateWord } from '../const/labelService';
import {
  createDataSheetConfiguration, getDataSheetConfigurationByCompanyId, deleteDataSheetConfigurationById, updateDateSheetConfiguration,
  getClientInformationUtil, upsertClientInformation, getDefaultDataSheetFields, getClientInfoByDataSheetField, deleteClientInfoRelated,
} from '../utils/datasheet';
import CONSTANTS from '../const/constants';

const DATASHEETCONFIGURATION_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  selected: {},
};

const GET_CONFIGURATIONS = 'GET_CONFIGURATIONS';
const GET_CONFIGURATION_SUCCESS = 'GET_CONFIGURATION_SUCCESS';
const GET_CONFIGURATION_ERROR = 'GET_CONFIGURATION_ERROR';
const SET_SELECTED_DATASHEET_FIELD = 'SET_SELECTED_DATASHEET_FIELD';

export default function dataSheetConfigurationReducer(state = DATASHEETCONFIGURATION_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONFIGURATIONS:
      return { ...state, fetching: true };
    case GET_CONFIGURATION_SUCCESS:
      return { ...state, fetching: false, fetched: true, data: payload };
    case GET_CONFIGURATION_ERROR:
      return { ...state, fetching: false, fetched: true };
    case SET_SELECTED_DATASHEET_FIELD:
      return { ...state, selected: payload };
    default:
      return state;
  }
}

export const setSelectedFieldAction = (field) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELECTED_DATASHEET_FIELD, payload: field });
  } catch (error) {
    throw error;
  }
};

export const saveDataSheetFieldAction = (name, type, list, id) => async (dispatch, getState) => {
  try {
    const options = isUndefined(list) ? [] : list;
    const companyId = getState().user.data.company.id;
    const key = `${companyId}-${name}`;
    const field = { name, type, options, key };
    if (id) {
      field.id = id;
      await updateDateSheetConfiguration(field);
    } else await createDataSheetConfiguration(field, companyId);
  } catch (error) {
    throw error;
  }
};

export const deleteDataSheetConfiguration = (field) => async (dispatch, getState) => {
  try {
    await deleteDataSheetConfigurationById(field.id);
    const newFields = getState().configurations.data;
    remove(newFields, ['id', field.id]);
    dispatch({ type: GET_CONFIGURATION_SUCCESS, payload: newFields });
  } catch (error) {
    throw error;
  }
};

export const getDataSheetConfigurationByCompanyIdAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONFIGURATIONS });
    const companyId = getState().user.data.company.id;
    const dataSheetConfigurations = await getDataSheetConfigurationByCompanyId(companyId);
    const defaultFields = await getDefaultDataSheetFields();
    const allFields = concat(defaultFields, dataSheetConfigurations);
    dispatch({ type: GET_CONFIGURATION_SUCCESS, payload: allFields });
    return allFields;
  } catch (error) {
    dispatch({ type: GET_CONFIGURATION_ERROR, payload: error });
    throw error;
  }
};

export const getClientInformationAction = (clientId) => async (_, getState) => {
  try {
    let clientInfoes = await getClientInformationUtil(clientId);
    clientInfoes = clientInfoes.clientInfoes;
    const fieldConfigurations = getState().configurations.data;
    const fieldRecords = map(fieldConfigurations, (configField) => {
      const clientValue = find(clientInfoes, (infoField) => (configField.id === infoField.fieldName.id));
      let storedValue = isUndefined(clientValue) ? undefined : clientValue.value;
      const clientInfoId = isUndefined(clientValue) ? undefined : clientValue.id;
      if (configField.type === 'boolean') storedValue = (storedValue === 'true');
      const fieldOptions = map(configField.options, (option) => ({ text: option, value: option, isDisabled: false }));
      fieldOptions.unshift({ text: 'Selecciona', value: '', isDisabled: false });
      return {
        field: configField.name,
        translatedField: configField.isDefault ? translateWord(configField.name) : configField.name,
        fieldId: configField.id,
        isDefault: configField.isDefault,
        options: fieldOptions,
        type: configField.type,
        clientInfoId,
        value: storedValue,
      };
    });
    return fieldRecords;
  } catch (error) {
    throw error;
  }
};

export const saveClientInformationAction = (clientData, category) => async (dispatch, getState) => {
  try {
    const clientId = getState().conversations.current.id;
    if (category) {
      await dispatch(changeCategoryAction(category, clientId));
    }
    const results = [];
    for (const clientRecord of clientData) {
      const key = `${clientId}${clientRecord.fieldId}`;
      if (!isUndefined(clientRecord.value)) {
        results.push(upsertClientInformation(key, clientId, clientRecord.fieldId, clientRecord.value.toString()));
      }
    }
    const response = await Promise.all(results);
    return response;
  } catch (error) {
    throw error;
  }
};
