import gql from 'graphql-tag';

const GET_ALL_PERMISSIONS = gql`
  query permissions($name: String!) {
    permissions(where: { name_not: $name }) {
      id
      name
    }
  }
`;

const CREATE_ROLE = gql`
  mutation createRole(
    $name: String!,
    $companyId: ID!,
    $permissions: [PermissionWhereUniqueInput!],
    $key: String!) {
    createRol(data: {
      name: $name
      company: {
        connect: { id: $companyId }
      },
      permissions: {
        connect: $permissions
      }
      key: $key
    }) {
      id,
      name
      permissions {
        id
        name
      }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation updateRol(
    $id: ID!,
    $name: String!,
    $permissions: [PermissionWhereUniqueInput!],
    $key: String!
    ) {
    updateRol(where: {id: $id},
      data: {
        name: $name,
        permissions: {
          set: $permissions
        }
        key: $key
      }
    ) {
      id
      name
      permissions {
        id
      }
    }
  }
`;

const ROLE_REMOVE_PERMISSIONS = gql`
  mutation updateRol($id: ID!, $name: String!, $permissions: [PermissionWhereUniqueInput!]) {
    updateRol(where: {id: $id},
      data: {
        name: $name,
        permissions: {
          disconnect: $permissions
        }
      }
    ) {
      id
      name
      permissions {
        id
      }
    }
  }
`;

const VALIDATE_TO_DELETE_ROLE = gql`
  query users($roleId: ID!) {
    users ( where : {
      rol: { id: $roleId }
    }) {
      id
      name
    }
  }
`;

const GET_ROLES_BY_COMPANY_ID = gql`
  query rols($companyId: ID!) {
    rols(where: {
      company: {id: $companyId }
    }) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const FIND_ROLE_BY_NAME = gql`
  query rols($name: String!, $companyId: ID!) {
    rols ( where : {
      name: $name,
      company: { id: $companyId }
    }) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const DELETE_ROLE = gql`
  mutation($roleId: ID!) {
    deleteRol(where: { id: $roleId }) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const ROLES_WITH_SAME_PERMISSIONS = gql`
  query rols($permissions: [String!], $companyId: ID!) {
    rols(where: {
      permissions_some: {
        name_in: $permissions
      }
      company: { id: $companyId }
    }) {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;

const GET_PERMISSIONS_BY_NAME = gql`
  query permissions($names: [String!]) {
    permissions(
      where: {
        name_in: $names
      }
    ) {
      id
    }
  }
`;

const ApolloRoles = {
  GET_ALL_PERMISSIONS,
  CREATE_ROLE,
  UPDATE_ROLE,
  ROLE_REMOVE_PERMISSIONS,
  VALIDATE_TO_DELETE_ROLE,
  GET_ROLES_BY_COMPANY_ID,
  FIND_ROLE_BY_NAME,
  DELETE_ROLE,
  ROLES_WITH_SAME_PERMISSIONS,
  GET_PERMISSIONS_BY_NAME,
};

export default ApolloRoles;
