import React, { useState, useEffect } from "react";

import {
  Label,
  PrimaryButton,
} from "../../components/simplifiedOnboarding/Onboarding.styles";
import { WhatsappQRCode } from "../../components/shared/components/whatsappQRCode/WhatsappQRCode";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
// import { getChannels } from "../../utils/channels";
// import { verifyToken } from "../../utils/userAuth";

const TwoFactorQrCodeView = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [uri, setUri] = useState(null);
  const [isOpenLink, setisOpenLink] = useState(false);
  const location = useLocation();
  const email = location?.state?.email;
  const password = location?.state?.password;

  const init = async () => {
    try {
      const uri = localStorage.getItem("uri-qr");
      console.log("uri ==> " + uri);
      setUri(uri);
      // setTimeout(() => {
      //   console.log("uri ==> " + uri);
      //   if (isOpenLink) {
      //     setTimeout(() => {
      //       history.push({
      //         pathname: `/two-factor`,
      //         state: { email, password },
      //       });
      //     }, 2000);
      //   } else {
      //     history.push({
      //       pathname: `/two-factor`,
      //       state: { email, password },
      //     });
      //   }
      // }, 9000);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading && <div>Cargando...</div>}
      {!loading && (
        <>
          <Label
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              width: "50%",
              marginBottom: "2rem",
              padding: "1rem",
            }}
          >
            Descarga la aplicación gratuita{" "}
            <span
              style={{
                color: "#0074d9",
              }}
            >
              Google Authenticator
            </span>{" "}
            para{" "}
            <a
              style={{
                color: "#00bfa5",
                textDecoration: "none",
              }}
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              onClick={() => setisOpenLink(true)}
              target="_blank"
            >
              Android
            </a>{" "}
            o{" "}
            <a
              style={{
                color: "#0074d9",
                textDecoration: "none",
              }}
              href="https://apps.apple.com/es/app/google-authenticator/id388497605"
              onClick={() => setisOpenLink(true)}
              target="_blank"
            >
              iOS
            </a>
            , añade una nueva cuenta y acontinuación escanea el código QR para
            configurar tu cuenta.
          </Label>
          <WhatsappQRCode twoFactor={uri} />

          <div style={{ cursor: "pointer", marginTop: "2rem" }}>
            <PrimaryButton
            
              onClick={() => {
                history.push({
                  pathname: `/two-factor`,
                  state: { email, password },
                });
              }}
            >
              Continuar
            </PrimaryButton>
          </div>

          <div style={{ cursor: "pointer", marginTop: "2rem" }}></div>
        </>
      )}
    </main>
  );
};

export default TwoFactorQrCodeView;
