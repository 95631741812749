import styled, { css, keyframes } from "styled-components";

export const NewWhatsappTemplatesViewContainer = styled.div``;

export const Status = styled.span`
  color: ${(props) =>
    props.status === "accepted"
      ? "#00CD7E"
      : props.status === "denied"
      ? "#F9173D"
      : "#979797"};
`;

export const StepperContainer = styled.div`
  margin-bottom: 2.5rem;
`;

export const InputContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 26.75rem;
  height: 18.125rem;
  position: relative;
`;

export const ColorPick = styled.section`
  display: flex;
  width: 100%;
  & > div > :nth-child(3) {
    & :nth-child(8) {
      display: none !important;
    }
    & :nth-child(9) {
      display: none !important;
    }
  }
`;

export const ColorBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorTitle = styled.span`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  opacity: 0.5;
`;

export const ChooseColor = styled.div`
  background-color: ${(props) => props.color};
  width: 5%;
  margin-left: 2rem;
  border-radius: 4px;
`;

export const Suggestion = styled.p`
  width: ${(props) => (props.variables ? "100%" : "70%")};
  height: 1.688rem;
  margin-top: 1.375rem;
  margin-bottom: 1.938rem;
  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.32px;
  color: #757575;
  ${(props) =>
    props.variables &&
    css`
      margin-top: 0;
      margin-bottom: 5rem;
    `}
`;

export const Grap = styled.div`
  height: 0.2rem;
  width: 100px;
  margin-bottom: 1.688rem;
`;

export const DragImage = styled.div`
  border: 2px dashed #e4e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  width: 38.21rem;
  align-items: center;
  margin-top: 21px;
  margin-bottom: 1rem;
`;

export const MainTextStyle = styled.span`
  color: #5eca84;
  text-decoration-line: underline;
  font-weight: bolder;
`;

export const CharactersNumber = styled.span`
  width: 38.256rem;
  height: 1.688rem;
  display: flex;
  justify-content: flex-end;
`;

export const NewWhatsappTitle = styled.p`
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
`;

export const Info = styled.p`
  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  letter-spacing: -0.233455px;
  color: #000000;
`;

export const PreviewTitle = styled.p`
  font-family: "SpaceGrotesk-Semibold";
  font-weight: 600;
  font-size: 20px;
  line-height: 17px;
  letter-spacing: -0.26px;
  color: #202020;
`;

export const MessageWithVariables = styled.div`
  resize: vertical;
  width: 100%;
  min-height: 6rem;
  max-width: 490px;
  background-color: ${(props) =>
    props.error ? "rgba(255, 0, 0, 0.1)" : "#f0f2f5"};
  border: none;
  border-radius: 6px;
  font-family: SpaceGrotesk-Bold;
  opacity: ${(props) => (props.isInit ? "0.5" : "1")};
  display: flex;
  cursor: text;
`;

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const animation = (props) =>
  css`
    ${pulse} 0.5s infinite alternate;
  `;

export const Cursor = styled.span`
  animation: ${animation};
`;

export const MessageWhitVariableArea = styled.div`
  resize: vertical;
  width: 100%;
  min-height: 6rem;
  background-color: #f0f2f5;
  padding: 1rem;
  border: none;
  border-radius: 6px;
  position: relative;
`;

export const TextAreaV = styled.textarea`
  resize: vertical;
  width: 100%;
  min-height: 6rem;
  background-color: ${(props) =>
    props.error ? "rgba(255, 0, 0, 0.1)" : "#f5f4f6"};
  padding: 1rem;
  border: none;
  border-radius: 6px;
  font-family: SpaceGrotesk-Bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  ${(props) =>
    props.botMenu &&
    css`
      height: 140px;
      min-height: 140px;
    `}
  &::placeholder {
    opacity: 0.4;
  }
  font-family: "Montserrat-Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
`;

export const InputVariable = styled.input`
  border: 2px solid #e9e9e9;
  border-radius: 4px;
  width: ${(props) => (props.followUp ? "186px" : "168px")};
  height: 44px;
  padding: 10px;
`;

export const VariablesButtonsContainer = styled.div`
  display: flex;
  width: 120%;
`;

export const ContainerVariable = styled.div`
  display: flex;
  margin-right: 12px;
  cursor: pointer;
`;

export const ContainerIcon = styled.div`
  width: 14px;
  height: 17px;
  margin-right: 4px;
`;

export const DeleteText = styled.span`
  font-family: SpaceGrotesk-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.275122px;
`;

export const Icon = styled.img`
  width: 100%;
`;

export const ContainerAddVariable = styled.div`
  display: flex;
`;

export const ContainerAddIcon = styled.div`
  width: 18px;
  height: 18px;
`;

export const AddText = styled.span`
  font-family: SpaceGrotesk-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.275122px;
  color: #00cd7e;
`;

export const RemoveText = styled.span`
  font-family: SpaceGrotesk-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 16px;
  letter-spacing: 0.275122px;
  color: #757575;
`;

export const AddIcon = styled.img`
  width: 100%;
`;

export const SetVariablesArea = styled.div`
  ${(props) =>
    props.templateSelect &&
    css`
      margin-left: 1rem;
    `};
`;

export const Subtitle = styled.p`
  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #757575;
  ${(props) =>
    props.followUpLabel &&
    css`
      padding-left: 1rem;
      padding-bottom: 1rem;
    `};
`;

export const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 26px;
    gap 2;
`;

export const GridItem = styled.div`
  width: ${(props) => (props.followUp ? "20%" : "50%")};
  box-sizing: border-box;
  float: ${(props) => (props.right ? "right" : "left")};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

export const EditorContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 140px;
  overflow-y: auto;
  position: relative;
  margin-bottom: 1rem;

  .ql-container.ql-snow {
    border: 2px solid #2e3353;
    border-radius: 4px;
    background-color: white;
  }
`;

export const EditorLabel = styled.label`
  font-family: "SpaceGrotesk-Bold";
  font-style: normal;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
  position: absolute;
  background-color: white;
  padding: 0.25rem;
  top: -14px;
  left: 10px;
  z-index: 1;
`;

export const RadioButtonsContainer = styled.div`
  margin-top: 28px;
  width: 620px;
  display: flex;
 
`;

export const GropRadioButtons = styled.div`
  width: 350px;
  display: flex;
  align-items: center;
`;

export const LabelRadioButton = styled.label`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
  margin-left: 0.5rem;
`;
