import API from "../axios";
import CONSTANTS from "../const/constants";

export const onboardingGoToNextStep = (currentStep, history) => {
  const currentStepIndex = CONSTANTS.ONBOARDING_FLOW?.findIndex(
    (x) => x === currentStep
  );
  if (currentStepIndex === -1) {
    throw new Error("Step not found");
  }
  const nextStep = CONSTANTS.ONBOARDING_FLOW[currentStepIndex + 1];
  history.push("/onboarding/step/" + nextStep);
};

export const shouldRestartOnboarding = async (prevStep, history) => {
  const previousOnboardingId = localStorage.getItem("onboardingId");

  if (previousOnboardingId && !prevStep) {
    const response = await API.get(`/onboarding/${previousOnboardingId}`);
    // TODO: set previous onboarding steps in redux
    history.push(
      "/onboarding/step/" + CONSTANTS.ONBOARDING_PREVIOUS_ONBOARDING_STEP
    );
    return;
  }

  if (!previousOnboardingId && !prevStep) {
    history.push("/onboarding/step/" + CONSTANTS.ONBOARDING_FLOW[0]);
    return;
  }
};
