const contries = [
  {
    value: 'Argentina',
    text: 'Argentina',
  },
  {
    value: 'Bolivia',
    text: 'Bolivia',
  },
  {
    value: 'Brasil',
    text: 'Brasil',
  },
  {
    value: 'Chile',
    text: 'Chile',
  },
  {
    value: 'Colombia',
    text: 'Colombia',
  },
  {
    value: 'Costa Rica',
    text: 'Costa Rica',
  },
  {
    value: 'Cuba',
    text: 'Cuba',
  },
  {
    value: 'Ecuador',
    text: 'Ecuador',
  },
  {
    value: 'El Salvador',
    text: 'El Salvador',
  },
  {
    value: 'España',
    text: 'España',
  },
  {
    value: 'Guayana Francesa',
    text: 'Guayana Francesa',
  },
  {
    value: 'Granada',
    text: 'Granada',
  },
  {
    value: 'Guatemala',
    text: 'Guatemala',
  },
  {
    value: 'Guayana',
    text: 'Guayana',
  },
  {
    value: 'Haití',
    text: 'Haití',
  },
  {
    value: 'Honduras',
    text: 'Honduras',
  },
  {
    value: 'Jamaica',
    text: 'Jamaica',
  },
  {
    value: 'México',
    text: 'México',
  },
  {
    value: 'Nicaragua',
    text: 'Nicaragua',
  },
  {
    value: 'Paraguay',
    text: 'Paraguay',
  },
  {
    value: 'Panamá',
    text: 'Panamá',
  },
  {
    value: 'Perú',
    text: 'Perú',
  },
  {
    value: 'Puerto Rico',
    text: 'Puerto Rico',
  },
  {
    value: 'República Dominicana',
    text: 'República Dominicana',
  },
  {
    value: 'Surinam',
    text: 'Surinam',
  },
  {
    value: 'Uruguay',
    text: 'Uruguay',
  },
  {
    value: 'Venezuela',
    text: 'Venezuela',
  },
];

export default contries;
