const size = {
  smallMobile: '320px',
  360: '360px',
  mediumMobile: '375px',
  largeMobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  mediumlaptop: '1200px',
  1400: '1400px',
  Largelaptop: '1440px',
  desktop: '2560px',
};

const Devices = {
  smallMobile: `(min-width: ${size.smallMobile})`,
  360: `(min-width: ${size[360]})`,
  mediumMobile: `(min-width: ${size.mediumMobile})`,
  largeMobile: `(min-width: ${size.largeMobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  mediumLaptop: `(min-width: ${size.mediumlaptop})`,
  1400: `(min-width: ${size[1400]})`,
  Largelaptop: `(min-width: ${size.Largelaptop})`,
  mLargelaptop: `(max-width: ${size.Largelaptop})`,
  desktop: `(min-width: ${size.desktop})`,
};

export default Devices;
