const FILTERS = {
  agents: {
    filters: [
      {
        title: "Categoría",
        value: "category",
        options: [
          {
            text: "Global",
            value: "Global",
            selected: true
          },
          {
            text: "Citas",
            value: "Citas",
            selected: true
          },
          {
            text: "Contacto",
            value: "Contacto",
            selected: true
          },
          {
            text: "Superagente",
            value: "Superagente",
            selected: true
          }
        ]
      }
    ],
    defaultSelection: { category: ["Global", "Citas", "Contacto", "Superagente"] }
  },
  chat: {
    filters: [
      {
        title: "Intención",
        value: "purpose",
        options: [
          {
            text: "Citas",
            value: "Citas",
            selected: true
          },
          {
            text: "Contacto",
            value: "Contacto",
            selected: true
          },
          {
            text: "Superagente",
            value: "Superagente",
            selected: true
          },
          {
            text: "Otros",
            value: "UNDEFINED",
            selected: true
          }
        ]
      },
      {
        title: "Canal",
        value: "channel",
        options: [
          {
            text: "Facebook Messenger",
            value: "facebook",
            selected: true
          },
          {
            text: "Whatsapp Business",
            value: "whatsapp",
            selected: true
          }
        ]
      },
      {
        title: "Prioridad",
        value: "priority",
        options: [
          {
            text: "Alta",
            value: "high",
            selected: true
          },
          {
            text: "Media",
            value: "medium",
            selected: true
          },
          {
            text: "Baja",
            value: "low",
            selected: true
          }
        ]
      }
    ],
    defaultSelection: {
      purpose: ["Citas", "Contacto", "Superagente", "UNDEFINED"],
      channel: ["facebook", "whatsapp"],
      priority: ["high", "medium", "low"]
    }
  }
};

export default FILTERS;
