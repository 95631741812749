import client from '../apollo/apolloClient';
import ApolloBotFollowUp from '../apollo/apolloBotFollowUp';

const CONTACTS_INIT_STATE = {
  data: [],
  started: false,
  fetching: false,
  error: false,
  messagesBot: [],
};

const MESSAGE_BOT_FOLLOW_UP = 'MESSAGE_BOT_FOLLOW_UP';
const MESSAGE_BOTS = 'MESSAGE_BOTS';

export default function botFollowUpReducer(
  state = CONTACTS_INIT_STATE,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case MESSAGE_BOT_FOLLOW_UP:
      return { ...state, fetching: true, data: payload, started: true };
    case MESSAGE_BOTS:
      return { ...state, fetching: true, messagesBot: payload, started: true };

    default:
      return state;
  }
}

export const getBotFollowUpMessage = (companyId, channelId) => async (dispatch) => {
  try {
    const response = await client.query({
      query: ApolloBotFollowUp.GET_MESSAGE_BOT_CONFIG,
      variables: { channelId },
    });

    const responsedata = response.data?.messageBotConfigs?.pop();

    if ( responsedata?.id) {
      const responseMessageBots = await client.query({
        query: ApolloBotFollowUp.GET_MESSAGE_BOTS,
        variables: { messageBotConfigId: responsedata?.id },
      });

      const messageBots = responseMessageBots.data?.messageBots;
       responsedata['messagesBot'] = messageBots;
    }

    await dispatch({ type: MESSAGE_BOT_FOLLOW_UP, payload: responsedata });

    return responsedata;
  } catch (error) {
    throw new Error('ERROR_GETTING_BOTFOLLOWUP_MESSAGE');
  }
};
export const updateBotFollowUpMessage = (createFollowUpBot) => async (dispatch, getState) => {
  try {
    const updateFollowBot = [...getState().botFollowUp.messagesBot];
    updateFollowBot.push(createFollowUpBot.createMessageBot);
    dispatch({ type: MESSAGE_BOTS, payload: updateFollowBot });
  } catch (error) {
    console.log('Errorupdate: ', error);
    throw new Error('ERROR_GETTING_BOTFOLLOWUP_MESSAGE');
  }
};
export const getMessagesBots = (messageBotConfigId) => async (dispatch) => {
  try {
    const response = await client.query({
      query: ApolloBotFollowUp.GET_MESSAGE_BOTS,
      variables: { messageBotConfigId },
    });
    const responsedata = response.data?.messageBots;
    dispatch({ type: MESSAGE_BOTS, payload: responsedata });
  } catch (error) {
    console.log('ErrorGet: ', error);
    // throw new Error('ERROR_GETTING_MESSAGE_BOTS');
  }
};
export const deleteMessagesBots = (messageBotId) => async (dispatch, getState) => {
  try {
    await client.mutate({
      mutation: ApolloBotFollowUp.DELETE_MESSAGE_BOTS,
      variables: { messageBotId },
    });
    const dalateFollowBot = [...getState().botFollowUp.messagesBot];
    const aux = dalateFollowBot.filter((d) => d.id !== messageBotId);
    console.log(aux);
    dispatch({ type: MESSAGE_BOTS, payload: aux });
  } catch (error) {
    console.log('ErrorGet: ', error);
    throw new Error('ERROR_GETTING_BOTFOLLOWUP_MESSAGE');
  }
};

export const addBotTag = (companyId) => async (dispatch, getState) => {
  try {
    const response = await client.query({
      query: ApolloBotFollowUp.DELETE_MESSAGE_BOTS,
      variables: { companyId },
    });
    const responsedata = response.data?.messageBotConfigs[0];
    dispatch({ type: MESSAGE_BOT_FOLLOW_UP, payload: responsedata });
    return responsedata;
  } catch (error) {
    throw new Error('ERROR_GETTING_BOTFOLLOWUP_MESSAGE');
  }
};
