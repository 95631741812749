import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  width: ${(props) => (props.small ? '10rem' : '16.56rem')};
  overflow: hidden;
  display: inline-block;
  background-color: white;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
`;

export const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: ${(props) => (props.small ? '10rem' : '16.56rem')};
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

export const CardImg = styled.img`
  object-fit: 'cover';
  width: 100%;
  height: 100%;
`;

export const DefaultImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const CardInfoContainer = styled.div`
  padding: ${(props) => (props.small ? '1rem' : '2rem')};
  ${(props) => props.small && css`
    height: 5.125rem;
  `}
`;

export const CardTitle = styled.div`
  font-family: system-ui, sans-serif;
  font-size: ${(props) => (props.small ? '0.875rem' : '1rem')};
  margin-bottom: ${(props) => (props.small ? '1rem' : '1.5rem')};
`;

export const CardSubtitle = styled.div`
  font-family: system-ui, sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  opacity: 0.5;
`;

export const CardCreationDate = styled.div`
  font-family: system-ui, sans-serif;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
  opacity: 0.5;
`;
