import styled, { css } from "styled-components";
import AppCard from "../../../components/shared/components/appCard/AppCard";

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 22px;
`;

export const Column = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
  justify-content: end;
`;

export const RowFullHeight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 22px;
  justify-content: end;
  flex-grow: 1;
`;

export const StatCard = styled.div`
  padding: 12px 22px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  min-width: 286px;
  height: 85px;
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
`;

export const StatCardColumn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StatCardRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SvgIcon = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const StatCardTile = styled.span`
  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: ${(props) => props.color};
`;

export const StatCardContent = styled.span`
  font-family: "SpaceGrotesk-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) => props.color};
`;

export const MainCotainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
  height: 100%;
`;

export const MainSubtitle = styled.span`
  font-family: "SpaceGrotesk-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: ${(props) => props.color};
`;

export const PaymentTitle = styled.span`
  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
`;

export const SelectTagTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  border-bottom: 1px solid #d9d9d9;
`;

export const Tbody = styled.tbody``;

export const Th = styled.th`
  font-family: SpaceGrotesk-SemiBold, Inter, sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;
  /* identical to box height, or 21px */
  letter-spacing: -0.257238px;

  color: #2e3353;
  padding-bottom: 1.875rem;
  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;
export const Tr = styled.tr`
  text-align: center;
  height: 90px;
  border-bottom: 1px solid #d9d9d9;
`;
export const Td = styled.td`
  width: 35%;
  font-family: SpaceGrotesk-Regular, Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */

  letter-spacing: -0.257238px;

  color: #757575;

  padding: 1rem 1rem 1rem 0;

  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;
export const TableContent = styled.div`
  width: 100%;
  margin-top: 30px;
  overflow-y: scroll;
  flex-grow: 1;
  max-height: 287px;

  @media (min-height: 700px) {
    max-height: 350px;
  }

  @media (min-height: 950px) {
    max-height: 400px;
  }

  @media (min-height: 1300px) {
    max-height: 700px;
  }

  ${(props) =>
    props.newAudience &&
    css`
      margin-top: 114px;
    `}
`;

export const SelectTagWidgetContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SelectTagHead = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectTagTitle = styled.span`
  font-family: SpaceGrotesk-Medium, Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */
  width: 28%;
  letter-spacing: -0.257238px;

  color: #757575;
`;

export const SelectTagSearchContainer = styled.div`
  width: 90%;
  margin: 0.875rem 1rem 1rem 1rem;
  ${(props) =>
    props.templateHead &&
    css`
      width: 24%;
      margin: 0.875rem 2.5rem 1rem 1rem;
    `}
`;
export const ColorCircle = styled.div`
  background-color: ${(props) => props.color};
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin-left: 0.625rem;
`;

export const PaymentSubTitle = styled.span`
  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #757575;
`;

export const PaymentCostSpan = styled.span`
  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
`;

export const PaymentMethodCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 22px;
  gap: 16px;
  width: 362px;
  height: 85px;
  background: #ffffff;
  border: 1px solid #128c7e;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

export const PaymentMethodCardSection = styled.div`
  display: flex;
  flex-direction: columns;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
`;

export const PaymentMethodCardSectionCenter = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const MainColumn = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const RadioInput = styled.input`
  width: 24px;
  height: 24px;
`;

export const CardInfoSpan = styled.span`
  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  ${(props) =>
    props.isImportant
      ? css`
          text-decoration-line: underline;
          color: #128c7e;
        `
      : css`
          color: #2e3353;
        `}
`;

export const PaymentModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 20;
  background-color: #0f1432e8;
  backdrop-filter: blur(0px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const PaymentModal = styled.div`
  position: absolute;
  width: 548px;
  height: 562px;
  left: calc(50% - 548px / 2);
  top: 71px;
  background: #ffffff;
  border-radius: 4px;
`;

export const WhatsappTemplateInputContainer = styled.div`
  position: relative;
  width: 25rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
`;

export const WhatsappTemplateLabel = styled.label`
  position: absolute;
  font-family: "SpaceGrotesk-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  top: -9px;
  background: white;
  color: #2e3353;
  z-index: 10;
  left: 8px;
`;

export const WhatsappTemplateSelect = styled.select`
  width: 100%;
  height: 100%;
  border: 2px solid #e9e9e9;
  border-radius: 4px;

  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;

  & > option {
    font-family: "SpaceGrotesk-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 150%;
  }
`;
export const SelectTemplateContainer = styled.div`
  background-color: white;
  width: 37.938rem;
  max-height: min-content;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  margin-top: 26px;
`;
export const SelectTemplateHead = styled.div`
  display: flex;
  align-items: center;
  height: 20%;
  width: 100%;
  padding-top: 26px;
  margin-bottom: 1rem;
`;
export const WhatsappTemplateBody = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 260px;

  @media (min-height: 700px) {
    max-height: 350px;
  }

  @media (min-height: 950px) {
    max-height: 500px;
  }

  @media (min-height: 1300px) {
    max-height: 700px;
  }
`;

export const WhatsappTemplateSearchContainer = styled.div`
  width: 57%;
  margin: 0rem 1rem 0 1rem;
`;

export const ContentSpan = styled.span`
  background-color: #d9fdd3 !important;
  font-family: SpaceGrotesk-Medium, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important;
  letter-spacing: -0.257238px !important;
  padding: 0.75rem 0.75rem 0 0.75rem !important;
`;

export const GridContainer = styled.div`
  display: flex;
  width: 80%;
  height: auto;
  flex-direction: column;
  grid-auto-flow: dense;
  gap: 18px;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: 10px;
`;
export const BroadcastErrorCardContainer = styled.div`
  width: 70%;
  height: 301px;
  margin-left: 2rem;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ExportIconContainer = styled.div`
  width: 22px;
  height: 22px;
  margin-left: 0.5rem;
`;

export const ExportIcon = styled.img`
  width: 100%;
`;

export const BroadcastAppCard = styled(AppCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
`;

export const TableHeaderText = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #757575;
  width: 40%;
`;

export const TableDetailsButton = styled.button`
  font-family: SpaceGrotesk-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.257238px;
  text-decoration-line: underline;
  color: #128c7e;
  cursor: pointer;
  background: none;
  border: none;
`;

export const TableRowActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const TransparentBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`;

export const ConjunctionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 800px;
  flex-wrap: wrap;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ConjunctionTitle = styled.p`
  text-align: center;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
`;

export const ConjunctionGreenTitle = styled.p`
  text-align: center;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: #128c7e;
`;

export const ConjunctionOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ConjunctionOption = styled.button`
  width: 25%;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ConjunctionImg = styled.img`
  width: 80%;
  margin-bottom: 30px;
`;

export const ConjunctionSelect = styled.img`
  width: 30px;
  height: 30px;
`;

export const ConjunctionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ConjunctionButton = styled.button`
  font-family: "SpaceGrotesk-Bold", sans-serif;
  width: 10.5rem;
  height: 2.375rem;
  background-color: #128c7e;
  font-size: 1rem;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
`;