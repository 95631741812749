import gql from 'graphql-tag';

const CREATE_DATASHEET_CONFIGURATION = gql`
  mutation createDataSheetConfiguration(
    $name: String!,
    $type: String!,
    $options: [String!],
    $companyId: ID!,
    $key: String!,
    $companyIdString: String!
    ) {
    createDataSheetConfiguration(
      data: {
        name: $name,
        type: $type,
        options: {set: $options},
        company: { connect: { id: $companyId }},
        key: $key,
        companyId: $companyIdString,
      }
    ) {
      id,
      name,
      type,
    }
  }
`;

const GET_DATASHEET_CONFIGURATION_BY_COMPANY_ID = gql`
  query dataSheetConfigurations($companyId: String!) {
    dataSheetConfigurations(where: { companyId: $companyId } ) {
      id
      name
      type
      options
      isDefault
    }
  }
`;
const GET_DATASHEET_CONFIGURATION_BY_KEY = gql`
  query dataSheetConfigurations($key: String!) {
    dataSheetConfigurations(where: { key: $key } ) {
      id
      name
      type
      options
      isDefault
    }
  }
`;

const GET_DEFAULT_DATASHEET_FIELDS = gql`
  query {
    dataSheetConfigurations(
      where: { isDefault: true, company: null, name:"Phone" }
    ) {
      id
      name
      type
      options
      isDefault
    }
  }
`;

const UPDATE_DATASHEET_CONFIGURATION_BY_ID = gql`
  mutation updateDataSheetConfiguration(
    $id: ID!,
    $name: String!,
    $type: String!,
    $options: [String!],
    $key: String!
    ) {
    updateDataSheetConfiguration(where: { id: $id },
      data: {
        name: $name,
        type: $type,
        options: {set: $options},
        key: $key
      }) {
        id
    }
  }
`;

const DELETE_DATASHEET_CONFIGURATION_BY_ID = gql`
  mutation($id: ID!) {
    deleteDataSheetConfiguration(where: { id: $id }) {
      id
    }
  }
`;

const GET_CLIENT_INFO_BY_DATASHEET_ID = gql`
  query clientInfoes($id: ID!) {
    clientInfoes(
      where: { fieldName: { id: $id } }
    ) {
      id
    }
  }
`;

const DELETE_CLIENT_INFO_BY_DATASHEET_ID = gql`
  mutation deleteManyClientInfoes($id: ID!){
    deleteManyClientInfoes(
      where: { fieldName: { id: $id } }
    ) {
      count
    }
  }
`;

const GET_CLIENT_INFORMATION_BY_ID = gql`
  query($clientId: String!) {
    clientInfoes(where: {
      clientId: $clientId
    }) {
      id
      fieldName {
        id
        name
      }
      value
    }
  }
`;

const UPSERT_CLIENT_INFORMATION = gql`
  mutation($key: String!, $value: String! $clientId: ID!, $fieldNameId: ID!, $clientIdString: String!) {
    upsertClientInfo(
      where: { key: $key }
      update: { value: $value }
      create: {
        key: $key
        value: $value
        client: { connect: { id: $clientId }}
        fieldName: { connect: { id: $fieldNameId }}
        clientId: $clientIdString
      }
    ) {
      id
    }
  }
`;

const ApolloDatasheet = {
  CREATE_DATASHEET_CONFIGURATION,
  GET_DATASHEET_CONFIGURATION_BY_COMPANY_ID,
  GET_DATASHEET_CONFIGURATION_BY_KEY,
  UPDATE_DATASHEET_CONFIGURATION_BY_ID,
  DELETE_DATASHEET_CONFIGURATION_BY_ID,
  GET_CLIENT_INFORMATION_BY_ID,
  UPSERT_CLIENT_INFORMATION,
  GET_DEFAULT_DATASHEET_FIELDS,
  GET_CLIENT_INFO_BY_DATASHEET_ID,
  DELETE_CLIENT_INFO_BY_DATASHEET_ID,
};

export default ApolloDatasheet;
