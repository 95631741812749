import moment from "moment";
import API from "../axios";

export const getAnalytics = async (startDate, endDate, channelsIds) => {
  try {
     // Ajustar las fechas al huso horario GMT
     startDate = moment(startDate).utc().toISOString();
     endDate = moment(endDate).utc().toISOString();
    const response = await API.post("/analytics", {
      startDate,
      endDate,
      channelsIds,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCustomFieldsFromBot = async (
  startDate,
  endDate,
  companyId,
  channelsIds
) => {
  try {
    // Ajustar las fechas al huso horario GMT
    const adjustedStartDate = moment(startDate).utc().toISOString();
    const adjustedEndDate = moment(endDate).utc().toISOString();

    const response = await API.get(
      `/botFlow/clienInfo?startDate=${adjustedStartDate}&endDate=${adjustedEndDate}&companyId=${companyId}&channelsIds=${channelsIds}`
    );
    return response?.data;
  } catch (error) {
    console.log("ERROR_get360StatusAccount", error);
    throw new Error("ERROR_get360StatusAccount");
  }
};
