/* eslint-disable camelcase */
import gql from "graphql-tag";

const GET_CONVERSATIONS_BY_FIREBASE_ID_AND_CHANNELS = gql`
  query convertations(
    $id: ID!
    $channels: [ID!]
    $purpose: [String!]
    $channelsName: [String!]
    $floor: String!
    $ceiling: String!
  ) {
    convertations(
      where: {
        id: $id
        channelId: { id_in: $channels }
        purpose_in: $purpose
        channel_in: $channelsName
        priority_gte: $floor
        priority_lte: $ceiling
      }
    ) {
      id
      channel
      firebaseNodeId
      purpose
      company {
        id
      }
      agentId
      client {
        id
        platform
        name
        imageUrl
        accessId
        senderId
      }
      senderId
      priority
    }
  }
`;

const GET_CONVERSATION_BY_ID = gql`
  query client($id: ID!) {
    client(where: { id: $id }) {
      id
      agent {
        id
        name
      }
    }
  }
`;

const GET_CLIENT_BY_KEY = gql`
  query client($key: String!) {
    client(where: { key: $key }) {
      id
    }
  }
`;

const GET_CONVERSATION_BY_KEY = gql`
  query client($key: String!) {
    client(where: { key: $key }) {
      id
    }
  }
`;

const UPDATE_CLIENT_INFO = gql`
  mutation updateClient(
    $id: ID!
    $name: String
    $phone: String
    $location: String
    $purpose: String
    $product: String
    $credit: String
    $date: String
    $yotellevo: String
    $departure: String
    $time: String
    $persons: String
    $phone_home: String
    $first_name: String
    $second_name: String
    $email: String
    $birthday: String
    $precio_frac: String
    $rango_precio: String
    $observaciones: String
    $id_asesor: String
    $id_punto_venta: String
    $fecha_apartado: String
    $fecha_firma: String
  ) {
    updateClient(
      where: { id: $id }
      data: {
        name: $name
        phone: $phone
        location: $location
        purpose: $purpose
        product: $product
        credit: $credit
        date: $date
        yotellevo: $yotellevo
        departure: $departure
        time: $time
        persons: $persons
        phone_home: $phone_home
        first_name: $first_name
        second_name: $second_name
        email: $email
        birthday: $birthday
        precio_frac: $precio_frac
        rango_precio: $rango_precio
        observaciones: $observaciones
        id_asesor: $id_asesor
        id_punto_venta: $id_punto_venta
        fecha_apartado: $fecha_apartado
        fecha_firma: $fecha_firma
      }
    ) {
      id
      name
      email
      phone
    }
  }
`;

const GET_CLIENT_INFO = gql`
  query client($id: ID!) {
    client(where: { id: $id }) {
      senderId
      answered
      nameFromChannel
      imageUrl
      isAssignable
      isOver
      CRM_ID
      purpose
      priority
      location
      phone
      phone_home
      name
      first_name
      second_name
      date
      email
      birthday
      precio_frac
      rango_precio
      observaciones
      id_asesor
      id_punto_venta
      fecha_apartado
      fecha_firma
      yotellevo
      departure
      time
      persons
      product
      credit
    }
  }
`;

const CONVERSATION_ADD_TAGS = gql`
  mutation updateClient($id: ID!, $tagId: ID!) {
    updateClient(
      where: { id: $id }
      data: { tags: { connect: { id: $tagId } } }
    ) {
      id
    }
  }
`;

const CONVERSATION_REMOVE_TAGS = gql`
  mutation updateClient($id: ID!, $tagId: ID!) {
    updateClient(
      where: { id: $id }
      data: { tags: { disconnect: { id: $tagId } } }
    ) {
      id
    }
  }
`;

const CHANGE_CATEGORY_CLIENT = gql`
  mutation updateClient($id: ID!, $categoryId: ID!) {
    updateClient(
      where: { id: $id }
      data: { category: { connect: { id: $categoryId } } }
    ) {
      id
      agent {
        id
        name
        imageUrl
      }
    }
  }
`;

const GET_CONVERSATIONS_BY_FILTERS = ({
  companyId,
  priority_gte,
  priority_lte,
  agentIdIn,
  tagsIdIn,
  channelsNames,
  channelsIds,
  skip,
  first,
  cleanedName,
  answered,
  isArchived,
  unanswered,
  isBroadcastMessage,
  isConversationValuable,
  unreadMessages,
  groupAdminId
}) => gql`
  query conversationByFilters(
    ${companyId ? "$companyId: String," : ""}
    ${priority_gte ? "$priority_gte: Float," : ""}
    ${priority_lte ? "$priority_lte: Float," : ""}
    ${agentIdIn ? "$agentIdIn: [String!]," : ""}
    ${tagsIdIn ? "$tagsIdIn: [ID!]," : ""}
    ${channelsNames ? "$channelsNames: [PlatformName!]," : ""}
    ${channelsIds ? "$channelsIds: [String!]," : ""}
    ${cleanedName ? "$cleanedName: String," : ""}
    ${groupAdminId ? "$groupAdminId: String," : ""}
    ${skip ? "$skip: Int," : ""}
    ${first ? "$first: Int" : ""}
  ) {
    clients(
      where: {
        AND: [
          ${companyId ? "{companyId: $companyId}," : ""}
          ${priority_gte ? "{priority_gte: $priority_gte}," : ""}
          ${priority_lte ? "{priority_lte: $priority_lte}," : ""}
          ${agentIdIn ? "{agentId_in: $agentIdIn}," : ""}
          ${tagsIdIn ? "{tags_some: {id_in: $tagsIdIn}}," : ""}
          ${cleanedName ? "{cleanedName_contains: $cleanedName}," : ""}
          ${
            channelsNames
              ? "{channel: {platform:{name_in: $channelsNames}}}"
              : ""
          } 
          ${channelsIds ? "{channelId_in: $channelsIds}" : ""}
          ${answered === false ? "{answered: false}" : ""}
          ${
            unanswered === false
              ? "{unanswered: true}"
              : ""
          }
          ${unreadMessages ? "{unreadMessagesCount_gte: 1}" : ""}
          ${groupAdminId ? "{groupAdminId: $groupAdminId}," : ""}
          ${
            isArchived === true
              ? "{isArchived: true}"
              : "{isArchived_not: true}"
          }
          ${
            isBroadcastMessage === true
              ? "{isBroadcastMessage: true}"
              : "{isBroadcastMessage_not: true}"
          }
          ${
            isConversationValuable === true
              ? "{isConversationValuable: true}"
              : ""
          }
          ${"{ lastMessageId_not: null }"}

    
        ]
      },
      orderBy: lastUpdate_DESC,
      ${skip ? "skip: $skip," : ""}
      ${first ? "first: $first" : ""}
    ) {
      id
      company {
        id
      }
      setValuableByUser
      hasFollowUpMessage     
      channel {
        id
        name
        pageToken
        providerId
        pageId
        platform {
          id
          name
          api
        }
        isActive
        useChatbot
      }
      agent {
        id
        name
        imageUrl
      }
      key
      cleanedName
      createdAt
      updatedAt
      lastUpdate
      lastMessageId
      answered
      isArchived
      isBroadcastMessage
      isConversationValuable
      nameFromChannel
      imageUrl
      isAssignable
      category {
        id
        name
      }
      priority
      contexts
      last_postbacks
      isReady
      isOver
      senderId
      tags {
        id
        name
        color
        isDefault
      }
      unreadMessagesCount
      whatsappStartedAt
      whatsappLastMessageAt
      isClosed
      groupAdminId
    }
  }
`;

const CHECK_EXISTED_PHONE = gql`
  query checkExistedPhone(
    $companyId: String!
    $channelId: String!
    $phone: String!
  ) {
    clients(
      where: {
        AND: [
          { companyId: $companyId }
          { channelId: $channelId }
          { senderId_contains: $phone }
        ]
      }
    ) {
      id
    }
  }
`;

const GET_CONVERSATIONS_BY_SEARCHING_KEYWORD = ({
  companyId,
  keyword,
  channelId,
  channelsNames,
  channelsIds,
  agentIdIn,
  groupAdminId,
  skip,
  first,
}) => gql`
  query conversationByFilters(
    ${keyword ? "$keyword: String," : ""}
    ${companyId ? "$companyId: String," : ""}
    ${channelId ? "$channelId: String," : ""}
    ${channelsIds ? "$channelsIds: [String!]," : ""}
    ${agentIdIn ? "$agentIdIn: [String!]," : ""}
    ${groupAdminId ? "$groupAdminId: String," : ""}
    ${skip ? "$skip: Int," : ""}
    ${first ? "$first: Int" : ""}
  ) {
    clients(
      where: {
        AND: [
          ${companyId ? "{companyId: $companyId}," : ""}
          ${keyword ? "{cleanedName_contains: $keyword}," : ""}
          ${channelId ? "{channelId: $channelId}," : ""}
          ${channelsIds ? "{channelId_in: $channelsIds}" : ""}
          ${agentIdIn ? "{agentId_in: $agentIdIn}," : ""}
          ${groupAdminId ? "{groupAdminId: $groupAdminId}," : ""}
        ]
      },
      orderBy: lastUpdate_DESC,
      ${skip ? "skip: $skip," : ""}
      ${first ? "first: $first" : ""}
    ) {
      id
      company {
        id
      }
      setValuableByUser
      hasFollowUpMessage     
      channel {
        id
        name
        pageToken
        providerId
        pageId
        platform {
          id
          name
          api
        }
        isActive
        useChatbot
      }
      agent {
        id
        name
        imageUrl
      }
      key
      cleanedName
      createdAt
      updatedAt
      lastUpdate
      lastMessageId
      answered
      isArchived
      isBroadcastMessage
      isConversationValuable
      nameFromChannel
      imageUrl
      isAssignable
      category {
        id
        name
      }
      priority
      contexts
      last_postbacks
      isReady
      isOver
      senderId
      tags {
        id
        name
        color
        isDefault
      }
      unreadMessagesCount
      whatsappStartedAt
      whatsappLastMessageAt
      isClosed
      groupAdminId
    }
  }
`;

const GET_ALL_CONVERSATION_COMPACTED = ({ companyId, channelId }) => gql`
  query conversationByFilters(
    ${companyId ? "$companyId: String," : ""}
    ${channelId ? "$channelId: String," : ""}
  ) {
    clients(
      where: {
        AND: [
          ${companyId ? "{companyId: $companyId}," : ""}
          ${channelId ? "{channelId: $channelId}," : ""}
        ]
      },
      orderBy: lastUpdate_DESC,
      first: 20
    ) {
      id
      company {
        id
      }
      channel {
        id
        name
        pageId
        providerId
        pageToken
        platform {
          id
          name
          api
        }
        isActive
      }
      key
      nameFromChannel
      imageUrl
      senderId
      isClosed
      groupAdminId
    }
  }
`;
const GET_BROADCAST_CONVERSATIONS = gql`
  query broadcastConversations($companyId: String!) {
    clients(
      where: { AND: [{ companyId: $companyId }, { isBroadcastMessage: true }] },
      first: 50
    ) {
      id
    }
  }
`;

const UPDATE_CLIENT_NAME = gql`
  mutation updateClientName(
    $id: ID!
    $nameFromChannel: String!
    $cleanedName: String!
  ) {
    updateClient(
      where: { id: $id }
      data: { nameFromChannel: $nameFromChannel, cleanedName: $cleanedName }
    ) {
      id
    }
  }
`;
const UPDATE_CLIENT_TAG = gql`
  mutation updateClientName($id: ID!, $key: String!) {
    updateClient(
      where: { id: $id }
      data: { tags: { connect: { key: $key } } }
    ) {
      id
    }
  }
`;

const UPDATE_CLIENT_PHONE = gql`
  mutation updateClientPhone(
    $id: ID!
    $cleanedName: String!
    $key: String!
    $senderId: String!
  ) {
    updateClient(
      where: { id: $id }
      data: { cleanedName: $cleanedName, key: $key, senderId: $senderId }
    ) {
      id
    }
  }
`;

const UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE = gql`
  mutation updateClientName($id: ID!, $hasFollowUpMessage: Boolean!) {
    updateClient(
      where: { id: $id }
      data: { hasFollowUpMessage: $hasFollowUpMessage }
    ) {
      id
    }
  }
`;

const UPDATE_CONVERSATION_CLASSIFICATION = gql`
  mutation updateClientClassification(
    $id: ID!
    $isConversationValuable: Boolean
    $setValuableByUser: Boolean
  ) {
    updateClient(
      where: { id: $id }
      data: {
        isConversationValuable: $isConversationValuable
        setValuableByUser: $setValuableByUser
      }
    ) {
      id
    }
  }
`;
const TagInput = {
  connect: [String],
};

const CREATE_CLIENT_FROM_PHONE = gql`
  mutation createClientFromPhone(
    $senderId: String!
    $name: String!
    $companyId: ID!
    $companyIdString: String
    $channelId: ID!
    $channelIdString: String
    $key: String!
    $cleanedName: String!
    $lastUpdate: DateTime!
    $agentId: ID!
    $agentIdString: String
    $tags: [TagWhereUniqueInput!]
    $tagsString: String
  ) {
    createClient(
      data: {
        nameFromChannel: $name
        senderId: $senderId
        company: { connect: { id: $companyId } }
        companyId: $companyIdString
        channel: { connect: { id: $channelId } }
        agent: { connect: { id: $agentId } }
        agentId: $agentIdString
        channelId: $channelIdString
        key: $key
        cleanedName: $cleanedName
        isConversationValuable: null
        lastUpdate: $lastUpdate
        isArchived: false
        tags: { connect: $tags }
        tagsString: $tagsString
        source: MANUAL
        answered: true
      }
    ) {
      id
      company {
        id
      }
      setValuableByUser
      hasFollowUpMessage
      channel {
        id
        name
        pageToken
        pageId
        providerId
        platform {
          id
          name
          api
        }
        isActive
        useChatbot
      }
      agent {
        id
        name
        imageUrl
      }
      key
      cleanedName
      createdAt
      updatedAt
      lastUpdate
      lastMessageId
      answered
      isArchived
      isBroadcastMessage
      isConversationValuable
      nameFromChannel
      imageUrl
      isAssignable
      category {
        id
        name
      }
      priority
      contexts
      last_postbacks
      isReady
      isOver
      senderId
      tags {
        id
        name
        color
        isDefault
      }
      unreadMessagesCount
      participants {
        id
        key
        senderId
        name
      }
      whatsappStartedAt
      whatsappLastMessageAt
    }
  }
`;

const GET_PLATFORM_API_PHONE = gql`
  query getApi($companyId: ID!) {
    channels(
      where: {
        company: { id: $companyId }
        platform: { name: WHATSAPP }
        isActive: true
      }
    ) {
      id
      name
      isActive
      platform {
        api
      }
    }
  }
`;

const SET_IS_ARCHIVED = gql`
  mutation updateClientisArchived($id: ID!, $setValuableIsArchived: Boolean) {
    updateClient(
      where: { id: $id }
      data: { isArchived: $setValuableIsArchived }
    ) {
      id
    }
  }
`;
const MARK_AS_UNREAD = gql`
  mutation updateClientUnread($id: ID!) {
    updateClient(where: { id: $id }, data: { unreadMessagesCount: 1 }) {
      id
    }
  }
`;

const MARK_AS_READ = gql`
  mutation updateClientRead($id: ID!) {
    updateClient(where: { id: $id }, data: { unreadMessagesCount: 0 }) {
      id
    }
  }
`;

const GET_CLIENTS_REPORT = ({
  companyId,
  initialDate,
  endDate,
  channelIds,
  agentIdIn,
  tagsIdIn,
  channelsNames,
  cleanedName,
  answered,
  unreadMessages,
  isArchived,
  isBroadcastMessage,
  isConversationValuable,
  keyword,
  skip,
  first,
}) => gql`
  query clientPanel(
    ${companyId ? "$companyId: String," : ""}
    ${initialDate ? "$initialDate: DateTime!," : ""}
    ${endDate ? "$endDate: DateTime!," : ""}    
    ${channelIds ? "$channelIds: [String!]," : ""}
    ${agentIdIn ? "$agentIdIn: [String!]," : ""}
    ${tagsIdIn ? "$tagsIdIn: [ID!]," : ""}
    ${channelsNames ? "$channelsNames: [PlatformName!]," : ""}
    ${cleanedName ? "$cleanedName: String," : ""}
    ${skip ? "$skip: Int," : ""}
    ${first ? "$first: Int" : ""}
    ${keyword ? "$keyword: String," : ""}
    ) {
    clients(
      where: {
        companyId: $companyId
        ${endDate ? "createdAt_lte: $endDate" : ""}
        ${initialDate ? "createdAt_gte: $initialDate" : ""}
        ${channelIds ? "channelId_in: $channelIds" : ""}
        ${agentIdIn ? "agentId_in: $agentIdIn" : ""}
        ${keyword ? "cleanedName_contains: $keyword" : ""}
      },
      orderBy: lastUpdate_DESC,
      ${skip ? "skip: $skip," : ""}
      ${first ? "first: $first" : ""}
    ) {
      id
      nameFromChannel
      agent {
        name
      }
      tags {
        name
      }
      channel {
        providerId
        id
        name
        platform {
          name
        }
      }
      senderId
      createdAt
      lastUpdate
      isClosed
      groupAdminId
    }
  }
`;

const GET_CLIENT_VINTE_EXTRA_INFO = ({ keyIn }) => gql`
  query clientPanelExtraInfo(    
    ${keyIn ? "$keyIn: [String!]" : ""} 
    ) {
    clientInfoes(
        where: {
      key_in: $keyIn
    }) {
        key
        value
        clientId
        fieldName {
          id
        }
    }
  }
`;

const GET_CONVERSATION_DATA_BY_KEY = gql`
  query client($key: String!) {
    client(where: { key: $key }) {
      id
      company {
        id
      }
      setValuableByUser
      hasFollowUpMessage
      channel {
        id
        name
        pageToken
        pageId
        providerId
        platform {
          id
          name
          api
        }
        isActive
        useChatbot
      }
      agent {
        id
        name
        imageUrl
      }
      key
      cleanedName
      createdAt
      updatedAt
      lastUpdate
      lastMessageId
      answered
      isArchived
      isBroadcastMessage
      isConversationValuable
      nameFromChannel
      imageUrl
      isAssignable
      category {
        id
        name
      }
      priority
      contexts
      last_postbacks
      isReady
      isOver
      senderId
      tags {
        id
        name
        color
        isDefault
      }
      unreadMessagesCount
      participants {
        id
        key
        senderId
        name
      }
      whatsappStartedAt
      whatsappLastMessageAt
    }
  }
`;

const ApolloConversations = {
  GET_CONVERSATION_BY_ID,
  GET_CONVERSATIONS_BY_FIREBASE_ID_AND_CHANNELS,
  UPDATE_CLIENT_INFO,
  GET_CLIENT_INFO,
  CONVERSATION_ADD_TAGS,
  CONVERSATION_REMOVE_TAGS,
  CHANGE_CATEGORY_CLIENT,
  GET_CONVERSATIONS_BY_FILTERS,
  GET_CONVERSATIONS_BY_SEARCHING_KEYWORD,
  GET_ALL_CONVERSATION_COMPACTED,
  UPDATE_CLIENT_NAME,
  UPDATE_CLIENT_PHONE,
  CHECK_EXISTED_PHONE,
  UPDATE_CLIENT_TAG,
  UPDATE_CONVERSATION_CLASSIFICATION,
  UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE,
  CREATE_CLIENT_FROM_PHONE,
  GET_PLATFORM_API_PHONE,
  SET_IS_ARCHIVED,
  MARK_AS_UNREAD,
  MARK_AS_READ,
  GET_CLIENTS_REPORT,
  GET_CLIENT_VINTE_EXTRA_INFO,
  GET_BROADCAST_CONVERSATIONS,
  GET_CONVERSATION_BY_KEY,
  GET_CONVERSATION_DATA_BY_KEY,
  GET_CLIENT_BY_KEY,
};

export default ApolloConversations;
