const PLANS_INIT_STATE = {
  plans: [],
  fetching: false,
  error: false,
};

const GETTING_PLANS = 'GETTING_PLANS';
const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
const GET_PLANS_ERROR = 'GET_PLANS_ERROR';
const CLEAR_DATA = 'CLEAR_DATA';

export default function rolesReducer(state = PLANS_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_PLANS:
      return { ...state, fetching: true };
    case GET_PLANS_SUCCESS:
      return { ...state, fetching: false, plans: payload };
    case GET_PLANS_ERROR:
      return { ...state, fetching: false, error: payload };
    case CLEAR_DATA:
      return { ...PLANS_INIT_STATE };
    default:
      return state;
  }
}