import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #ddd;
`;

const ProgressBarInner = styled.div`
  position: relative;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: white;
  background-color: #128c7e;
  transition: width 0.5s ease-in-out;
`;

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarContainer>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
          color: progress > 40 ? "white" : "black",
        }}
      >
        {progress}%{" "}
      </div>
      <ProgressBarInner style={{ width: `${progress}%` }}></ProgressBarInner>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
