import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/test.json";

const LoaderTest = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      className: "plane"
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={400} width={400} isStopped={false} isPaused={false} />
    </div>
  );
};

export default LoaderTest;
