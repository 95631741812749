// import * as firebase from 'firebase/app';
// import 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import safariBrowserVerification from './browserVerification';

let messagingFb;
let getTokenFirebase;

try {
  const supportsServiceWorker = 'serviceWorker' in navigator;
  const isSafari = safariBrowserVerification();
  if (supportsServiceWorker && !isSafari) {
    const firebaseConfig = {
      apiKey: 'AIzaSyAB6nV2I7zCBl-yAs_af8iUtTiofr8KQVo',
      authDomain: 'iventas-d3de1.firebaseapp.com',
      projectId: 'iventas-d3de1',
      storageBucket: 'iventas-d3de1.appspot.com',
      messagingSenderId: '250156020418',
      appId: '1:250156020418:web:d3aa677e32f7e86c28815b',
      measurementId: 'G-W02R1FYJZK',
    };
    const firebaseApp = initializeApp(firebaseConfig);
    console.log('FCD:mesag');
    messagingFb = getMessaging(firebaseApp);
    getTokenFirebase = async () => {
      let currentToken = '';

      try {
        currentToken = await getToken(messagingFb, {
          vapidKey:
            'BH_Rvg9UgyrrCFPVJkRIXJAlFjhQozqsapArAKncdJTyCS_0MBs5e2YaRx2UwsZGrtn69O7IhAgyqzU5nBSZTN8',
        });
      } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
      }

      return currentToken;
    };
  } else if (typeof Storage !== 'undefined') {
    // LocalStorage disponible
    console.log('Registro localStorage');
    getTokenFirebase = async () => {
      let currentToken = '';

      try {
        currentToken = await getToken(messagingFb, {
          vapidKey:
            'BH_Rvg9UgyrrCFPVJkRIXJAlFjhQozqsapArAKncdJTyCS_0MBs5e2YaRx2UwsZGrtn69O7IhAgyqzU5nBSZTN8',
        });
      } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
      }

      return currentToken;
    };
  } else {
    console.log('Registro localStorage no soportado');
  }
} catch (error) {
  console.log(error);
}

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messagingFb, (payload) => {
    console.log(payload);
    resolve(payload);
  });
});

export { messagingFb, getTokenFirebase };
