import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  FilesSectionContainer,
  FilesSectionTitle,
  DropZoneContainer,
  EmptyFiles,
  FileCardContainer,
} from "./FilesSection.style";
import DropZoneImage from "../../shared/components/dropZoneImage/DropZoneImage.component";
import FileCard from "../../shared/components/fileCard/FileCard.component";
import { getLabelMessage } from "../../../const/labelService";
import OrderByModal from "../../orderByModal/OrderByModal.component";
import CONSTANTS from "../../../const/constants";

const FilesSection = ({
  files,
  selectFileAction,
  onDrop,
  prepareFiles,
  uploadingFiles,
  maxSize,
  deleteFileAction,
  orderByOption,
  hideTitle = false,
  acceptedFilesTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/zip",
    "video/mp4",
  ],
  mainLabel = "filesDropZoneMainText",
  canUploadFiles = false,
  templateMedia,
  isPreview,
  newTheme,
  isSelectTemplate,
}) => {
  const [displayedFiles, setDisplayedFiles] = useState(files);
  const [orderSelectedOption, setOrderSelectedOption] =
    useState("creationDate");
  const labels = [
    mainLabel,
    "filesSectionTitleLabel",
    "filesDropZoneSecondaryText",
    "filesEmpty",
    acceptedFilesTypes.includes(".pdf") && "filesDropZoneThirdText",
  ];
  const lbls = getLabelMessage(labels);
  const canWrite = useSelector(
    (state) => state.user?.data?.permissions?.writeMultimedia
  );

  const orderBy = (option) => {
    orderByOption(option);
    setOrderSelectedOption(option);
  };

  useEffect(() => {
    setDisplayedFiles(files);
  }, [files, orderSelectedOption]);

  return (
    <FilesSectionContainer newTheme={newTheme}>
      {!hideTitle && (
        <FilesSectionTitle>{lbls.filesSectionTitleLabel}</FilesSectionTitle>
      )}
      {(canWrite || canUploadFiles) && (
        <DropZoneContainer noBottomMargin newTheme={newTheme}>
          <DropZoneImage
            mainText={lbls[mainLabel]}
            secondaryText={lbls.filesDropZoneThirdText}
            thirdText={lbls.filesDropZoneSecondaryText}
            fullSize
            showBorder
            onDrop={onDrop}
            prepareFiles={prepareFiles}
            acceptedFilesTypes={acceptedFilesTypes}
            maxSize={maxSize}
            templateMedia={templateMedia}
            isPreview={isPreview}
            newTheme={newTheme}
            isSelectTemplate={isSelectTemplate}
          />
        </DropZoneContainer>
      )}
      {displayedFiles?.length === 0 && uploadingFiles?.length === 0 && (
        <EmptyFiles>{lbls.filesEmpty}</EmptyFiles>
      )}
      {(displayedFiles?.length > 0 || uploadingFiles?.length > 0) && (
        <>
          <OrderByModal onOptionClick={orderBy} />
          <FileCardContainer>
            {(displayedFiles?.length > 0 || uploadingFiles?.length > 0) &&
              [...displayedFiles, ...uploadingFiles].map((file, index) => (
                <FileCard
                  key={file.id}
                  name={file.name}
                  url={file.url}
                  fileType={file.fileType}
                  weight={file.weight}
                  onSelectAction={() => selectFileAction(index)}
                  onDeleteAction={() => deleteFileAction(file.id)}
                  isUploading={file.isUploading}
                  progress={file.progress}
                  showDeleteButton={canWrite}
                  displayFileName={false}
                />
              ))}
          </FileCardContainer>
        </>
      )}
    </FilesSectionContainer>
  );
};

FilesSection.propTypes = {
  acceptedFilesTypes: PropTypes.arrayOf(PropTypes.string),
  deleteFileAction: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      fileType: PropTypes.oneOf([
        CONSTANTS.FILE_TYPE_PDF,
        CONSTANTS.FILE_TYPE_IMAGE,
      ]),
      url: PropTypes.string,
      weight: PropTypes.number,
      createdAt: PropTypes.string,
    })
  ).isRequired,
  hideTitle: PropTypes.bool,
  maxSize: PropTypes.number,
  onDrop: PropTypes.func.isRequired,
  orderByOption: PropTypes.func.isRequired,
  prepareFiles: PropTypes.func.isRequired,
  selectFileAction: PropTypes.func.isRequired,
  uploadingFiles: PropTypes.func.isRequired,
  mainLabel: PropTypes.string,
  canUploadFiles: PropTypes.bool,
};

export default FilesSection;

FilesSection.defaultProps = {
  acceptedFilesTypes: [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/zip",
    "video/mp4",
  ],
  hideTitle: false,
  canUploadFiles: false,
};
