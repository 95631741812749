import client from '../apollo/apolloClient';
import ApolloAgents from '../apollo/agents';
import API from '../axios';

export const getAgentsByFilters = async (companyId, rolesIds, spacesIds) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_FILTERS(companyId, rolesIds, spacesIds),
      variables: { companyId, rolesIds, spacesIds },
    });
    return data.users;
  } catch (error) {
    console.log(error);
    const jcnError = new Error('AGENTS_NOT_FOUND');
    throw jcnError;
  }
};

export const getAgents = async (companyId) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_COMPANY,
      variables: { companyId },
    });
    return data;
  } catch (error) {
    const jcnError = new Error('AGENTS_NOT_FOUND');
    throw jcnError;
  }
};

export const findAgentByEmail = async (email) => {
  try {
    console.log("findAgentByEmail API ==================", email)
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENT_BY_EMAIL,
      variables: { email },
    });
    console.log("data", data.users);
    return data.users;
  } catch (error) {
    const jcnError = new Error('USER_WITH_EMAIL_NOT_FOUND');
    throw jcnError;
  }
};

export const getAgentsByCategories = async (companyId, categories) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_COMPANY_AND_CATEGORIES,
      variables: { companyId, categories },
    });
    return data.users;
  } catch (error) {
    const jcnError = new Error('AGENTS_NOT_FOUND');
    throw jcnError;
  }
};

export const getAgentsByRoles = async (companyId, roles) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_COMPANY_ID_ROLES,
      variables: { companyId, roles },
    });
    return data.users;
  } catch (error) {
    const jcnError = new Error('AGENTS_NOT_FOUND');
    throw jcnError;
  }
};

export const deleteAgent = async (agentId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloAgents.DELETE_AGENT,
      variables: { documentId: agentId },
    });
    return data;
  } catch (error) {
    const jcnError = new Error('ERROR_DELETING_USER');
    throw jcnError;
  }
};

export const addAgent = async (data) => {
  try {
    const response = await API.post('/user', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editAgentPassword = async (data) => {
  try {
    const response = await API.put('/user', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (data) => {
  try {
    const response = await API.put('/deleteUser', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const assignAgent = async (data) => {
  try {
    const response = await API.post('/reassign-agent', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editAgentAvailability = async (agentId, isAvailable) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloAgents.UPDATE_AGENT_AVAIABILITY,
      variables: {
        id: agentId,
        isAvailable,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    throw new Error('ERROR_UPDATING_AVAILABILITY');
  }
};

export const getAgentsRole = async (companyId, spaceId) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_PERMISSIONS,
      variables: { companyId, spaceId },
    });
    return data;
  } catch (error) {
    throw new Error('ERROR_GETTING_AGENTS');
  }
};

export const editAgent = async (data) => {
  try {
    const response = await API.put('/user', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAgentsByCompanyIdAndNotLocationId = async (companyId, locationIds) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_COMPANY_ID_AND_NOT_LOCATION_ID,
      variables: { companyId, locationIds },
    });
    return data.agents;
  } catch (error) {
    return error;
  }
};

export const getAgentsBySpaceId = async (spaceId) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_AGENTS_BY_SPACE_ID,
      variables: { spaceId },

    });
    return data.users;
  } catch (error) {
    throw error;
  }
};

export const getAdminssBySpaceId = async (spaceId) => {
  try {
    const { data } = await client.query({
      query: ApolloAgents.GET_ADMINS_BY_SPACE_ID,
      variables: { spaceId },

    });
    return data.users;
  } catch (error) {
    throw error;
  }
};
