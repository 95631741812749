import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PdfContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PdfPageCounterContainer = styled.div`
  position: absolute;
  bottom: -50px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NextPdfButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0 10px 10px 0;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }
`;

export const NextPdfPage = styled(FontAwesomeIcon)`
  font-size: 1rem;
  cursor: pointer;
  color: black;
`;

export const PreviousPdfButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px 0 0 10px;
  cursor: pointer;

  &:hover {
    background-color: #e6e6e6;
  }
`;

export const PreviousPdfPage = styled(FontAwesomeIcon)`
  font-size: 1rem;
  cursor: pointer;
  color: black;
`;

export const PageCounter = styled.div`
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 0 1rem;
`;

export const DocumentContainer = styled.div`
  width: 100%;
  height: 100%;
  ${(props) => props.hasClickAction && css`cursor: pointer;`}
`;
