import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FileCardContainer = styled.div`
  width: 20rem;
  max-width: 400px;
  height: auto;
  overflow: visible;
  position: relative;
  border-radius: 9px;
  display: inline-block;
  cursor: pointer;

  ${(props) => props.isFacebookAd && !props.isClientSender &&
    css`
      background-color: #0202020b;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    `}

  ${(props) => props.isFacebookAd && props.isClientSender &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  ${(props) => props.isChatSelector &&
    css`
      width: 100%;
    `}
`;

export const FileImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;

  ${(props) => props.isFacebookAd && !props.isClientSender &&
    css`
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
    `}
`;

export const FileDescriptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: ${(props) => (props.small ? '1.9rem' : '4.75rem')};
  z-index: 1;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

export const FileDescription = styled.div`
  margin-right: 0.75rem;
`;

export const FileName = styled.div`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 0.625rem;
  opacity: 0.5;
`;

export const FileSize = styled.div`
  display: flex;
  font-size: 0.625rem;
  opacity: 0.7;
`;

export const DeleteFileButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  text-align: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const FileDeleteIcon = styled(FontAwesomeIcon)`
  font-size: 0.9rem;
  cursor: pointer;
`;

export const ProgressContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  flex-wrap: wrap;
`;

export const ProgressLabelContainer = styled.div`
  width: 80%;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  display: flex;
`;

export const ProgressLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00cd7e;
  border-radius: 5px;
  height: 10px;
  width: ${(props) => props.progress && `${props.progress}%`};
  color: white;
`;

export const ProgressElement = styled.div`
  color: white;
  font-family: Montserrat-Bold;
  font-size: 10px;
  width: 100%;
  text-align: center;
`;

export const VideoCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5F4F6;
`;

export const VideoIcon = styled(FontAwesomeIcon)`
  font-size: 6rem;
  color: #3C5597;
  cursor: pointer;
`;

export const FileTypeImg = styled.img`
  position: absolute;
  ${(props) => props.small && css`width: 2rem;`}
  top: -2rem;
`;

export const FilePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  ${(props) => !props.isClientSender &&
    css`
      width: initial;
      height: initial;
      justify-content: flex-start;
    `}
`;

export const FileDescriptionImage = styled.div`
  position: relative;
`;

export const FacebookAdDescription = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
`;

export const FacebookAdDescriptionTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
`;

export const FacebookAdDescriptionLink = styled.a`
  font-size: 0.8rem;
  color: #9599A8;
  text-decoration: none;
  z-index: 10;
`;
