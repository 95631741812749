import { getPermissions } from '../utils/roles';

const PERMISSIONS_INITIAL_STATE = {
  permissions: [],
  fetched: false,
  fetching: false,
  error: false,
};

const GET_PERMISSIONS = 'GET_ROLES';
const GET_PERMISSIONS_SUCCESS = 'GET_ROLES_SUCCESS';
const GET_PERMISSIONS_ERROR = 'GET_ROLES_ERROR';
const CLEAR_PERMISSIONS = 'CLEAR_PERMISSIONS';

export default function permissionsReducer(state = PERMISSIONS_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PERMISSIONS:
      return { ...state, fetching: true, fetched: false, error: false, permissions: [] };
    case GET_PERMISSIONS_SUCCESS:
      return { ...state, fetching: false, fetched: true, error: false, permissions: payload };
    case GET_PERMISSIONS_ERROR:
      return { ...state, fetching: false, fetched: false, error: payload, permissions: [] };
    case CLEAR_PERMISSIONS:
      return { ...PERMISSIONS_INITIAL_STATE };
    default:
      return state;
  }
}

export const getPermissionsAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_PERMISSIONS });
    const { permissions } = await getPermissions();

    dispatch({ type: GET_PERMISSIONS_SUCCESS, payload: permissions });
    return permissions;
  } catch (error) {
    dispatch({ type: GET_PERMISSIONS_ERROR, payload: error });
    return error;
  }
};
