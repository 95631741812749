import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Label, WhatsappButton } from "./Onboarding.styles";
import { WhatsappQRCode } from "../shared/components/whatsappQRCode/WhatsappQRCode";
import { getChannels } from "../../utils/channels";
import { verifyToken } from "../../utils/userAuth";
import pusher from "../../utils/pusher";
import API from "../../axios";
import { setOnboardingDataAction } from "../../redux/onboardingDuck";
import { updateStep } from "../../utils/onboarding";
const WhatsappLinkQrCodeInstructions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [isEnable, setIsEnable] = useState(true);
  const [phone, setPhone] = useState(null);
  const [error, setError] = useState(null);

  const onboardingUserData = useSelector((state) => state.onboarding.data);

  const handleWhatsappConnection = async (callbackObject) => {
    console.log(callbackObject);
    console.log("client ID: " + callbackObject.client);
    console.log("channel IDs: " + callbackObject.channels);
    if (callbackObject.revokedChannels) {
      console.log("Revoked Channel IDs: " + callbackObject.revokedChannels);
    }
  };

  const handleNotification = (data) => {
    console.log("data", data);
    if (data.success) {
      history.push("/link-whatsapp/profile");
    } else {
      setError(true);
    }
  };
  const handleNotificationEnableButton = (data) => {
    console.log("data", data);
    if (data.success) {
      setIsEnable(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    console.log("companyId", onboardingUserData.companyId);
    if (onboardingUserData.companyId) {
      const channel = pusher.subscribe(
        `onboarding_${onboardingUserData.companyId}`
      );
      const channelPhone = pusher.subscribe(
        `phone_${onboardingUserData?.phone}`
      );
      console.log("channel", channel);
      channel.bind("status", (data) => {
        handleNotification(data);
      });
      channelPhone.bind(`qr`, (data) => {
        handleNotificationEnableButton(data);
      });
    }
  }, [onboardingUserData.companyId]);

  const init = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await verifyToken(token);
      const channels = await getChannels(data?.company?.id);
      setPhone(channels?.[0]?.phone);

      if (!onboardingUserData.id) {
        const response = await API.get("/onboarding");

        dispatch(setOnboardingDataAction({ ...response.data }));

        await updateStep(response.data.id, "qrcode");
      } else {
        await updateStep(onboardingUserData?.id, "qrcode");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading && <div>Cargando...</div>}
      {!loading && (
        <>
          <Label
            style={{
              textAlign: "center",
            }}
          >
            ¡Con este QR podrás descargar un video con las instrucciones
            necesarias para vincular tu número!
          </Label>

          <WhatsappQRCode instructions />
          <WhatsappButton
            partnerId="Nog49UPA"
            callback={handleWhatsappConnection}
            label="Vincular WhatsApp"
            hide={isEnable}
            queryParameters={{plan_selection: "basic"}}
          />
        </>
      )}
    </main>
  );
};

export default WhatsappLinkQrCodeInstructions;
