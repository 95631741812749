import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { faTimes, faPlayCircle } from "@fortawesome/free-solid-svg-icons";

import {
  FileCardContainer,
  FileImage,
  FileDescriptionContainer,
  FileDescription,
  FileName,
  FileSize,
  DeleteFileButton,
  FileDeleteIcon,
  ProgressElement,
  ProgressLabel,
  ProgressLabelContainer,
  ProgressContainer,
  VideoIcon,
  VideoCard,
  FileTypeImg,
  FilePreviewContainer,
  FileDescriptionImage,
  FacebookAdDescription,
  FacebookAdDescriptionTitle,
  FacebookAdDescriptionLink,
} from "./FileCard.style";
import jpg from "../../../../assets/svg/filetypes/jpg.svg";
import pdf from "../../../../assets/svg/filetypes/pdf.svg";
import pdfPreview from "../../../../assets/svg/pdfPreview.svg";
import { getLabelMessage } from "../../../../const/labelService";
import CONSTANTS from "../../../../const/constants";
import MessageViewed from "../../../messages/messageViewed/MessageViewed";
import { ContentImageViewed } from "../../../messages/messageViewed/MessageViewed.styles";
import getWeightFile from "../../../../utils/getWeightFile";

const FileCard = ({
  name,
  url,
  fileType,
  weight,
  onSelectAction,
  onDeleteAction,
  messageStatus,
  messagePlatform,
  isClientSender,
  showDeleteButton,
  isUploading = false,
  progress,
  displayFileName = true,
  isFacebookAd,
  facebokAdLink,
  isChatSelector,
}) => {
  const labels = ["progressUploadFileLabel", "fileSizeLabel"];
  const lbls = getLabelMessage(labels);

  const { fileSize, size } = getWeightFile(weight);
  useEffect(()=>{
    console.log("url: ", url ,"====> ", name);
  })

  return (
    <FileCardContainer isClientSender={isClientSender} isFacebookAd={isFacebookAd} isChatSelector={isChatSelector}>
      <FilePreviewContainer isClientSender={isClientSender} isFacebookAd={isFacebookAd}>
        {fileType === CONSTANTS.FILE_TYPE_IMAGE && (
          <FileImage src={url} alt={name} onClick={onSelectAction} isClientSender={isClientSender} isFacebookAd={isFacebookAd} />
        )}
        {fileType === CONSTANTS.FILE_TYPE_STICKER && (
          <FileImage src={url} alt={name} onClick={onSelectAction} />
        )}
        {fileType === CONSTANTS.FILE_TYPE_PDF && (
          <FileImage src={pdfPreview} alt={name} onClick={onSelectAction} />
        )}
        {fileType === CONSTANTS.FILE_TYPE_VIDEO && (
          <VideoCard onClick={onSelectAction}>
            <VideoIcon icon={faPlayCircle} />
          </VideoCard>
        )}
      </FilePreviewContainer>
      {!isUploading && fileType !== CONSTANTS.FILE_TYPE_STICKER && !isFacebookAd && (
        <>
          <FileDescriptionContainer
            onClick={onSelectAction}
            small={!displayFileName}
          >
            {/* <FileDescriptionImage>
              {fileType === CONSTANTS.FILE_TYPE_IMAGE && (
                <FileTypeImg src={jpg} alt="jpg" small={!displayFileName} />
              )}
              {fileType === CONSTANTS.FILE_TYPE_PDF && (
                <FileTypeImg src={pdf} alt="pdf" small={!displayFileName} />
              )}
            </FileDescriptionImage> */}
            <FileDescription>
              {displayFileName && <FileName>{name}</FileName>}
              {/* <FileSize>
                {fileSize} {size}
              </FileSize> */}
            </FileDescription>
          </FileDescriptionContainer>
          {messagePlatform === "chatapi" && !isClientSender && (
            <MessageViewed messageStatus={messageStatus} imagen />
          )}

          {showDeleteButton && (
            <DeleteFileButton onClick={onDeleteAction}>
              <FileDeleteIcon icon={faTimes} />
            </DeleteFileButton>
          )}
        </>
      )}
      {!isClientSender && isFacebookAd && facebokAdLink && (
        <FacebookAdDescription>
          <FacebookAdDescriptionTitle>
            Chatea con nosotros
          </FacebookAdDescriptionTitle>
          <FacebookAdDescriptionLink>
            {facebokAdLink}
          </FacebookAdDescriptionLink>
        </FacebookAdDescription>
      )}
      {isUploading && (
        <ProgressContainer>
          <ProgressLabelContainer>
            <ProgressLabel progress={progress}>{progress}%</ProgressLabel>
          </ProgressLabelContainer>
          <ProgressElement>{lbls.progressUploadFileLabel}</ProgressElement>
        </ProgressContainer>
      )}
    </FileCardContainer>
  );
};

FileCard.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf([
    CONSTANTS.FILE_TYPE_IMAGE,
    CONSTANTS.FILE_TYPE_PDF,
    CONSTANTS.FILE_TYPE_VIDEO,
  ]).isRequired,
  weight: PropTypes.number.isRequired,
  onSelectAction: PropTypes.func,
  onDeleteAction: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  isUploading: PropTypes.bool,
  progress: PropTypes.number,
  displayFileName: PropTypes.bool,
  messageStatus: PropTypes.string,
  messagePlatform: PropTypes.string,
  isClientSender: PropTypes.bool,
  isFacebookAd: PropTypes.bool,
  facebokAdLink: PropTypes.string,
};

FileCard.defaultProps = {
  displayFileName: true,
};

export default FileCard;
