/* eslint-disable import/no-cycle */
import { map, isEqual } from 'lodash';
import * as moment from 'moment';

import { setInstancesCountAction } from './userDuck';
import { getSpacesAction, updateWhatsappChannelsNameInSpacesAction } from './spacesDuck';
import {
  getChannels,
  getFacebookChannels,
  getWhatsappChannels,
  getChannelQr,
  getChannelStatus,
  logoutWhatsappSlot,
  facebookCallback,
  updateStatusChannel,
  useChatBot,
  editChannelName,
} from '../utils/channels';

const CHANNELS_INITIAL_STATE = {
  fetching: false,
  success: false,
  error: false,
  data: { facebookChannels: [], whatsappChannels: [] },
  current: [],
  reload: false,
  selectedWhatsappChannel: {},
  whatsappTemplateSelectedChannel: null,
};

// constants
const GET_CHANNELS = 'GET_CHANNELS';
const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
const GET_CHANNELS_ERROR = 'GET_CHANNELS_ERROR';
const CLEAR_CHANNEL_ERROR = 'CLEAR_CHANNEL_ERROR';
const REFETCH_CHANNELS = 'REFETCH_CHANNELS';
const CLEAR_DATA = 'CLEAR_DATA';
const SET_CHANNEL_STATUS = 'SET_CHANNEL_STATUS';
const SET_WHATSAPP_CHANNEL_AS_SELECTED = 'SET_WHATSAPP_CHANNEL_AS_SELECTED';
const UPDATE_WHATSAPP_CHANNELS = 'UPDATE_WHATSAPP_CHANNELS';
const SET_WHATSAPP_TEMPLATE_SELECTED_CHANNEL = 'SET_WHATSAPP_TEMPLATE_SELECTED_CHANNEL';

// reducer
export default function channelsReducer(state = CHANNELS_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CHANNELS:
      return { ...state, fetching: true, error: false, reload: false };
    case GET_CHANNELS_SUCCESS:
      return { ...state, data: payload, current: {}, fetching: false, success: true, reload: false, selectedWhatsappChannel: {} };
    case GET_CHANNELS_ERROR:
      return { ...state, data: [], fetching: false, error: payload, success: false, reload: false, selectedWhatsappChannel: {} };
    case REFETCH_CHANNELS:
      return { ...state, reload: true, selectedWhatsappChannel: {} };
    case SET_WHATSAPP_CHANNEL_AS_SELECTED:
      return { ...state, selectedWhatsappChannel: payload };
    case UPDATE_WHATSAPP_CHANNELS:
      return { ...state, data: { facebookChannels: [...state.data.facebookChannels], whatsappChannels: payload } };
    case SET_WHATSAPP_TEMPLATE_SELECTED_CHANNEL:
      return { ...state, whatsappTemplateSelectedChannel: payload };
    case CLEAR_DATA:
      return CHANNELS_INITIAL_STATE;
    default:
      return state;
  }
}

// actions
export const getChannelsAction = (spaces) => async (dispatch) => {
  try {
    dispatch({ type: GET_CHANNELS });
    const channels = [];
    // eslint-disable-next-line no-unused-expressions
    spaces?.forEach((space) => {
      channels.push(...space.channels);
    });
    const whatsappChannels = getWhatsappChannels(channels);
    const facebookChannels = await getFacebookChannels(channels);
    dispatch({ type: GET_CHANNELS_SUCCESS, payload: { facebookChannels, whatsappChannels } });
    return { facebookChannels, whatsappChannels };
  } catch (error) {
    dispatch({ type: GET_CHANNELS_ERROR, payload: { error } });
  }
};

export const setWhatsappTemplateSelectedChannelAction = (channelId) => async (dispatch) => {
  try {
    console.log('channelId', channelId);
    dispatch({ type: SET_WHATSAPP_TEMPLATE_SELECTED_CHANNEL, payload: channelId });
  } catch (error) {
    dispatch({ type: GET_CHANNELS_ERROR, payload: { error } });
  }
};

export const activeChatBotAction = (selectedChannel, active, type) => async (dispatch, getState) => {
  try {
    const { facebookChannels } = getState().channels.data;
    const { whatsappChannels } = getState().channels.data;
    await useChatBot(selectedChannel.id, active);
    if (type === 'facebook') {
      map(facebookChannels, (channel) => {
        if (selectedChannel.id === channel.id) channel.useChatbot = active;
        return channel;
      });
    } else if (type === 'whatsapp') {
      map(whatsappChannels, (channel) => {
        if (selectedChannel.id === channel.id) channel.useChatbot = active;
        return channel;
      });
    }
    dispatch({ type: GET_CHANNELS_SUCCESS, payload: { facebookChannels, whatsappChannels } });
  } catch (error) {
    throw error;
  }
};

export const getChannelQrAction = (channelId, slotName) => async () => {
  try {
    const qrUrl = await getChannelQr(channelId, slotName);
    return qrUrl;
  } catch (error) {
    throw error;
  }
};

export const getChannelStatusAction = (whatsappChatHistory, whatsappChatHistoryTime) => async () => {
  try {
    const getChatHistory = whatsappChatHistory === 'yes';
    let chatHistoryTime;
    if (whatsappChatHistoryTime === 'year') chatHistoryTime = moment().subtract(1, 'year').unix();
    else if (whatsappChatHistoryTime === 'six_months') chatHistoryTime = moment().subtract(6, 'months').unix();
    else if (whatsappChatHistoryTime === 'month') chatHistoryTime = moment().subtract(1, 'month').unix();
    else if (whatsappChatHistoryTime === 'two_weeks') chatHistoryTime = moment().subtract(2, 'weeks').unix();
    else if (whatsappChatHistoryTime === 'week') chatHistoryTime = moment().subtract(1, 'week').unix();
    else if (whatsappChatHistoryTime === 'day') chatHistoryTime = moment().subtract(1, 'day').unix();
    else if (whatsappChatHistoryTime === 'all') chatHistoryTime = 0;
    else chatHistoryTime = 0;
    const channelInfo = await getChannelStatus(getChatHistory, chatHistoryTime);
    return channelInfo;
  } catch (error) {
    throw error;
  }
};

export const logoutWhatsappSlotAction = (channelId) => async (dispatch, getState) => {
  try {
    const oldIstanceCount = getState().user?.data?.company?.whatsappInstancesCount;
    const status = await logoutWhatsappSlot(channelId);
    await dispatch(setInstancesCountAction(oldIstanceCount - 1));
    return status;
  } catch (error) {
    throw error;
  }
};

export const selectWhatappChannelAction = (channel) => (dispatch) => {
  try {
    dispatch({ type: SET_WHATSAPP_CHANNEL_AS_SELECTED, payload: channel });
  } catch (error) {
    throw error;
  }
};

export const refetchChannelsAction = () => ({ type: REFETCH_CHANNELS });

export const facebookCallbackAction = (response) => async (dispatch, getState) => {
  try {
    if (response.status === 'unknown') return;
    const companyId = getState().user.data.company.id;
    await facebookCallback(response, companyId);
    // window.location.pathname = '/spaces';
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_CHANNELS_ERROR, payload: { error } });
  }
};

export const clearChannelErrorAction = () => (dispatch) => {
  dispatch({ type: CLEAR_CHANNEL_ERROR, payload: null });
};

export const editChannelStatus = (id, status, type) => async (dispatch, getState) => {
  let channels = getState().channels.data;
  const { current } = getState().channels;

  channels = map(channels, (channel) => {
    if (isEqual(channel.type, type)) {
      const subChannels = map(channel.channels, (subChannel) => {
        if (isEqual(subChannel.id, id)) {
          subChannel.isActive = status;
        }
        return subChannel;
      });
      channel.channels = subChannels;
    }
    return channel;
  });
  const channelId = await updateStatusChannel(status, id);
  if (channelId) {
    const payload = { data: channels, current };
    dispatch({ type: SET_CHANNEL_STATUS, payload });
    return true;
  }
};

export const editChannelNameAction = (channelId, newSlotName) => async (dispatch, getState) => {
  const updatedChannelId = await editChannelName(channelId, newSlotName);
  const whatsappChannels = getState().channels?.data?.whatsappChannels;
  const newWhatsappChannels = whatsappChannels.map(
    (channel) => (channel.id === updatedChannelId ? ({ ...channel, title: newSlotName }) : ({ ...channel })),
  );
  dispatch({ type: UPDATE_WHATSAPP_CHANNELS, payload: newWhatsappChannels });
  dispatch(updateWhatsappChannelsNameInSpacesAction(updatedChannelId, newSlotName));
  return updatedChannelId;
};

export const clearDataAction = () => ({
  type: CLEAR_DATA,
  payload: null,
});
