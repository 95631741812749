import CONSTANTS from "../const/constants";

export const getReferralLink = (referralCode, whatsappNumber, message) => {
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  const link = `https://wa.me/${whatsappNumber}?text=${message}${referralCode}`;
  dummy.setAttribute("value", link);
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

export const getWhatsappActiveChannel = (spaces) => {
  let whatsappActiveChannel = null;
  // eslint-disable-next-line no-unused-expressions
  spaces?.some((space) => {
    whatsappActiveChannel = space.channels.find(
      (channel) =>
        channel.platform.name === CONSTANTS.WHATSAPP_PLATFORM &&
        channel.isActive
    );
    if (whatsappActiveChannel) return true;
    return false;
  });
  return whatsappActiveChannel;
};
