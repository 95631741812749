import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";

import API from "../../axios";
import jobAvatar from "../../assets/images/job.png";
import qrAvatar from "../../assets/images/qr.png";

import {
  StyledWhatsappButton,
  Success,
} from "./Onboarding.styles";
import PlanStatus from "../../const/planStatus";
import CONSTANTS from "../../const/constants";
import { getLoginDataAction } from "../../redux/userDuck";

const LinkFirstWhatsapp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wasNotified, setWasNotified] = useState(false);
  const userData = useSelector((state) => state.user.data);

  const talkToExpert = () => {
    API.get(`/onboarding/need-help`);
    setWasNotified(true);
  };

  const goToChannels = async () => {
    try {
      API.post(`/send-onboarding-video`, {send:true});
    } catch(e) {
      console.log('Error at send-onboarding-video request', e)
    } finally {
      history.push("/spaces");
    }
  };
  
  useEffect(() => {
    const planStatus = userData?.company?.status;
    
    if (!planStatus) {
      dispatch(getLoginDataAction());
      return;
    }

    if (planStatus === PlanStatus.AWAITING_FIRST_PAYMENT) {
      history.replace(CONSTANTS.FIRST_PAYMENT_ROUTE);
      return;
    }

    if (planStatus !== PlanStatus.AWAITING_WA_VINCULATION) {
      history.replace('/chat');
    }
  }, [userData?.company?.status]);

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      width: "100vw",
      height: "100vh",
      backgroundColor: "#f5f5f5",
      overflowY: "auto",
      ...(isMobile && { padding: "20% 5%" }),
    }}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        gap: "50px",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        width: "100%",
      }}>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            flexDirection: "column",
            gap: "20px",
            alignContent: "center",
            alignItems: "center"
          }}
        >
          <img src={jobAvatar} 
            style={{
              width: "280px",
              height: "250px",
            }} 
          />
          <StyledWhatsappButton onClick={talkToExpert}>
            Agendar cita con experto
          </StyledWhatsappButton>
        </div>
        {
          !isMobile && (
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                flexDirection: "column",
                gap: "20px",
                alignContent: "center",
                alignItems: "center"
              }}
            >
              <img src={qrAvatar} 
                style={{
                  width: "280px",
                  height: "250px",
                }} 
              />
              <StyledWhatsappButton onClick={goToChannels}>
                Vincular ahora
              </StyledWhatsappButton>
            </div>
          )
        }
      </div>
      {(wasNotified || isMobile) && (
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
          flexWrap: "nowrap"
        }}>
          {wasNotified && (
            <Success style={{width: '100%' }}>
              En breve un experto se pondrá en contacto contigo para ayudarte a
              vincular tu número de WhatsApp.
            </Success>
          )}
          {isMobile && (
            <Success style={{width: '100%' }}>
              Inicia sesión en escritorio para vincular tu número de WhatsApp.
            </Success>
          )}
        </div>
      )}
    </div>
  );
};

export default LinkFirstWhatsapp;
