import { intersectionWith, differenceWith } from "lodash";
import Axios from "axios";

import client from "../apollo/apolloClient";
import ApolloChannels from "../apollo/apolloChannels";
import API from "../axios";
import CONSTANTS from "../const/constants";
import ApolloPlatform from "../apollo/apolloPlatform";
import ApolloSpaces from "../apollo/apolloSpaces";

export const getChannels = async (companyId) => {
  try {
    const variables = { companyId };
    const { data } = await client.query({
      query: ApolloChannels.GET_ALL_CHANNELS,
      variables,
    });
    return data.channels;
  } catch (error) {
    return error;
  }
};

export const getWhatsappActiveChannels = async (companyId) => {
  try {
    const variables = { companyId };
    const { data } = await client.query({
      query: ApolloChannels.GET_WHATSAPP_ACTIVE_CHANNELS,
      variables,
    });
    return data.channels;
  } catch (error) {
    return error;
  }
};

export const useChatBot = async (channelId, active) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloChannels.UPDATE_CHANNEL_CHAT_BOT,
      variables: { id: channelId, active },
    });
    return data;
  } catch (error) {
    throw new Error("ERROR_UPDATING_CHATBOT_STATUS");
  }
};

export const getFacebookChannels = async (channels) => {
  try {
    const facebookPages = channels?.filter(
      (channel) => channel.platform.name === "MESSENGER"
    );
    if (facebookPages) {
      const facebookChannels = await facebookPages?.map(async (channel) => {
        try {
          const url = `https://graph.facebook.com/v7.0/${channel.pageId}/picture?redirect=0`;
          const data = {
            access_token: channel.pageToken,
          };
          const imageUrl = await Axios.get(url, data);
          return {
            id: channel.id,
            pageId: channel.pageId,
            title: channel.name,
            date: channel.date ? channel.date : "",
            image: imageUrl?.data?.data?.url,
            isActive: channel.isActive,
            pageToken: channel.pageToken,
            updatedAt: channel.updatedAt ? channel.updatedAt : "",
            status: channel.status,
            useChatbot: channel.useChatbot,
          };
        } catch (error) {
          console.log(error);
        }
      });
      const facebookChannelsReturned = await Promise.all(facebookChannels);
      return facebookChannelsReturned;
    }
    return [];
  } catch (error) {
    throw error;
  }
};

export const getWhatsappChannels = (channels) => {
  const whatsappNumbers = channels?.filter(
    (channel) => channel.platform.name === "WHATSAPP"
  );
  if (whatsappNumbers) {
    const whatsappChannels = whatsappNumbers?.map((channel) => ({
      id: channel.id,
      pageId: channel.pageId,
      title: channel.name,
      date: channel.date ? channel.date : "",
      isActive: channel.isActive,
      pageToken: channel.pageToken,
      updatedAt: channel.updatedAt ? channel.updatedAt : "",
      status: channel.status,
      useChatbot: channel.useChatbot,
      platform: channel.platform,
      phone: channel.phone,
      qualityRating: channel.qualityRating,
      currentLimit: channel.currentLimit,
      providerId: channel.providerId,

    }));
    return whatsappChannels;
  }
  return [];
};

export const getChannelQr = async (channelId, slotName) => {
  try {
    const QR = await API.post("/getQR", { channelId, slotName });
    return QR.data.qrUrl;
  } catch (error) {
    throw error;
  }
};

export const getChannelStatus = async (getChatHistory, chatHistoryTime) => {
  try {
    const response = await API.post("/getChannelStatus", {
      getChatHistory,
      chatHistoryTime,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutWhatsappSlot = async (channelId) => {
  try {
    const response = await API.post("/logoutSlot", { channelId });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const facebookCallback = async (response, companyId) => {
  try {
    const { userID, accessToken } = response;
    const url = `https://graph.facebook.com/v7.0/${userID}/accounts?access_token=${accessToken}`;
    const responseFetch = await fetch(url);
    const jsonResponse = await responseFetch.json();
    const newFacebookChannels = jsonResponse.data;

    const { data } = await client.query({
      query: ApolloChannels.GET_CHANNELS,
      variables: { companyId },
    });
    const actualFacebookChannels = data?.channels?.filter(
      (x) => x.platform.name === "MESSENGER"
    );

    const {
      data: { spaces: defaultSpacesQuery },
    } = await client.query({
      query: ApolloSpaces.GET_DEFAULT_SPACE_BY_COMPANY,
      variables: { companyId },
    });
    const defaultSpace = defaultSpacesQuery.pop();

    const channelsToActivate = intersectionWith(
      actualFacebookChannels,
      newFacebookChannels,
      (arrVal, othVal) => arrVal.pageId === othVal.id
    );

    console.log("channelsToActivate", channelsToActivate);

    if (channelsToActivate?.length > 0) {
      const channelsToActivatePromise = channelsToActivate?.map(
        async (channel) => {
          try {
            const currentFbPage = newFacebookChannels?.find(
              (page) => page.id === channel.pageId
            );
            const mutationResponse = await client.mutate({
              mutation: ApolloChannels.ACTIVATE_CHANNEL,
              variables: { id: channel.id },
            });

            if (mutationResponse) {
              await fetch(
                // eslint-disable-next-line max-len
                `https://graph.facebook.com/v4.0/${currentFbPage.id}/subscribed_apps?access_token=${currentFbPage.access_token}&subscribed_fields=messages,conversations,messaging_postbacks`,
                {
                  method: "POST",
                  mode: "cors",
                  cache: "no-cache",
                  credentials: "omit",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  redirect: "follow",
                  referrer: "no-referrer",
                }
              );

              const permantentTokenRequest = {
                token: currentFbPage.access_token,
                channelId: mutationResponse.data.updateChannel.id,
              };

              await API.post("/getSetPermanentToken", permantentTokenRequest);
            }
            // eslint-disable-next-line no-empty
          } catch (error) {
            console.lor(error);
          }
        }
      );
      await Promise.allSettled(channelsToActivatePromise);
    }

    const channelsToDeactivate = differenceWith(
      actualFacebookChannels,
      newFacebookChannels,
      (arrVal, othVal) => arrVal.pageId === othVal.id
    );

    if (channelsToDeactivate?.length > 0) {
      const channelsToDeactivatePromise = channelsToDeactivate?.map(
        async (channel) => {
          await client.mutate({
            mutation: ApolloChannels.DEACTIVATE_CHANNEL,
            variables: { id: channel.id },
          });
          // await API.post('/deleteFacebookGetStarterMessage', { channelId: channel.id });
        }
      );
      await Promise.allSettled(channelsToDeactivatePromise);
    }

    const channelsToAdd = differenceWith(
      newFacebookChannels,
      actualFacebookChannels,
      (arrVal, othVal) => arrVal.id === othVal.pageId
    );

    const errors = [];
    if (channelsToAdd?.length > 0) {
      const { data: platforms } = await client.query({
        query: ApolloPlatform.GET_MESSENGER_PLATFORM,
      });

      const messengerPlatform = platforms?.platforms[0];

      const channelsToAddPromise = channelsToAdd?.map(async (channel) => {
        try {
          const channelVariables = {
            name: channel.name,
            platformId: messengerPlatform.id,
            pageId: channel.id,
            pageToken: channel.access_token,
            isActive: true,
            company: companyId,
            status: CONSTANTS.AUTHENTICATED_CHANNEL_STATUS,
          };

          let mutationResponse = null;
          try {
            mutationResponse = await client.mutate({
              mutation: ApolloChannels.CREATE_CHANNEL,
              variables: channelVariables,
            });
            await client.mutate({
              mutation: ApolloSpaces.ADD_CHANNEL_TO_SPACE,
              variables: {
                spaceId: defaultSpace.id,
                channelId: mutationResponse.data.createChannel.id,
              },
            });
          } catch (error) {
            errors.push({ name: channelVariables.name, error: error.message });
          }

          if (mutationResponse) {
            await fetch(
              // eslint-disable-next-line max-len
              `https://graph.facebook.com/v4.0/${channel.id}/subscribed_apps?access_token=${channel.access_token}&subscribed_fields=messages,conversations,messaging_postbacks`,
              {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                  "Content-Type": "application/json",
                },
                redirect: "follow",
                referrer: "no-referrer",
              }
            );

            const permantentTokenRequest = {
              token: channel.access_token,
              channelId: mutationResponse.data.createChannel.id,
            };

            await API.post("/getSetPermanentToken", permantentTokenRequest);
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
          console.lor(error);
        }
      });
      await Promise.allSettled(channelsToAddPromise);
      console.log(errors);
      return { success: true, errors };
    }
  } catch (error) {
    console.log(error);
    return { error: error.message };
  }
};

export const updateStatusChannel = async (status, channelId) => {
  try {
    await client.mutate({
      mutation: ApolloChannels.UPDATE_CHANNEL_STATUS,
      variables: { id: channelId, active: status },
    });
    return true;
  } catch (error) {
    return { error };
  }
};

export const editChannelName = async (channelId, newSlotName) => {
  const updatedChannel = await client.mutate({
    mutation: ApolloChannels.UPDATE_CHANNEL_NAME,
    variables: { id: channelId, name: newSlotName },
  });
  if (updatedChannel?.data?.updateChannel?.id)
    return updatedChannel.data.updateChannel.id;
  throw new Error("Error updating channel name");
};
