import React from "react";
import QRCode from "qrcode.react";

export function WhatsappQRCode({ phoneNumber, instructions = false, twoFactor }) {
  const whatsappLink = instructions
    ? "https://wa.link/tfzcld"
    : `https://wa.me/${phoneNumber}`;

  return <QRCode value={ twoFactor !== undefined ? twoFactor  : whatsappLink} />;
}
