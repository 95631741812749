import { forEach, isEmpty, concat, find } from 'lodash';

import formatDateUtil from '../tools/date-utils';
import { getSpaces, getChannelsAvailables, createSpace, deleteSpace, getChannelsAvailableToEdit, updateSpace } from '../utils/spaces';
import CONSTANTS from '../const/constants';

const SPACES_INITIAL_STATE = {
  fetching: false,
  error: false,
  success: false,
  data: [],
  selected: {},
  current: {},
};

// constants
const GET_SPACES = 'GET_SPACES';
const GET_SPACES_SUCCESS = 'GET_SPACES_SUCCESS';
const GET_SPACES_ERROR = 'GET_SPACES_ERROR';
const SET_SELECTED_SPACE = 'SET_SELECTED_SPACE';
const CLEAR_DATA = 'CLEAR_DATA';
const ACTION_SPACE = 'ACTION_SPACE';
const ERROR_ACTION = 'ERROR_ACTION';
const ACTION_SUCCESS = 'ACTION_SUCCESS';
const SET_SELECTED = 'SET_SELECTED';

// reducer
export default function spacesReducer(state = SPACES_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_SPACES:
      return { ...state, fetching: true, error: false };
    case GET_SPACES_SUCCESS:
      return { ...state, data: payload, current: {}, fetching: false, success: true };
    case GET_SPACES_ERROR:
      return { ...state, data: [], fetching: false, success: false, error: true };
    case ACTION_SPACE:
      return { ...state, fetching: true };
    case ERROR_ACTION:
      return { ...state, fetching: false, success: false };
    case ACTION_SUCCESS:
      return { ...state, fetching: false, current: {}, success: true };
    case SET_SELECTED_SPACE:
      return { ...state, current: payload };
    case SET_SELECTED:
      return { ...state, selected: payload };
    case CLEAR_DATA:
      return SPACES_INITIAL_STATE;
    default:
      return state;
  }
}

// actions
export const setSelectedAction = (space) => async (dispatch) => {
  forEach(space.channels, (channel) => {
    if (channel.platform.name === CONSTANTS.MESSENGER_PLATFORM) {
      space.facebook = { name: channel.name, id: channel.id };
    } else if (channel.platform.name === CONSTANTS.WHATSAPP_PLATFORM) {
      space.whatsapp = { name: channel.name, id: channel.id };
    }
  });
  dispatch({ type: SET_SELECTED_SPACE, payload: space });
};

export const setActualSpaceAction = (space) => async (distpatch) => {
  distpatch({ type: SET_SELECTED, payload: space });
};

export const getSpacesAction = (spaces) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPACES });

    let selectedSpace = find(spaces, { isDefault: true });

    if (!selectedSpace?.id) {
      selectedSpace = { ...spaces?.[0] };
    }

    dispatch({ type: GET_SPACES_SUCCESS, payload: spaces });
    dispatch({ type: SET_SELECTED, payload: selectedSpace });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_SPACES_ERROR });
  }
};

export const getAvailableChannelsAction = () => async (distpatch, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const spaces = await getChannelsAvailables(companyId);
    const facebook = [];
    const whatsapp = [];
    forEach(spaces, (space) => {
      forEach(space.channels, (channel) => {
        const option = {
          img: channel.imageUrl,
          mainText: channel.name,
          secondaryText: `Vinculada: ${formatDateUtil(channel.createdAt)}`,
          isDisabled: false,
          id: channel.id,
        };
        if (channel.platform?.name === CONSTANTS.MESSENGER_PLATFORM) facebook.push(option);
        else whatsapp.push(option);
      });
    });

    return { facebook, whatsapp };
  } catch (error) {
    throw error;
  }
};

export const channelsToEditAction = (spaceId) => async (distpatch, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const { space, available } = await getChannelsAvailableToEdit(companyId, spaceId);
    const availableChannels = concat(space.pop()?.channels, available.pop()?.channels);
    const facebook = [];
    const whatsapp = [];
    forEach(availableChannels, (channel) => {
      const option = {
        img: channel.imageUrl,
        mainText: channel.name,
        secondaryText: `Vinculada: ${channel.createdAt}`,
        isDisabled: false,
        id: channel.id,
      };
      if (channel.platform?.name === CONSTANTS.MESSENGER_PLATFORM) facebook.push(option);
      else whatsapp.push(option);
    });
    return { facebook, whatsapp };
  } catch (error) {
    throw error;
  }
};

export const createSpaceAction = (data, files) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_SPACE });
    const formData = new FormData();
    if (!isEmpty(files) && !files[0].uploaded) {
      formData.append('file', files[0]);
    }
    const channels = [];
    if (!isEmpty(data.facebookSelect)) channels.push(data.facebookSelect);
    if (!isEmpty(data.whatsappSelect)) channels.push(data.whatsappSelect);
    formData.append('spaceName', data.spaceName);
    formData.append('channels', JSON.stringify(channels));
    const response = await createSpace(formData);
    dispatch({ type: ACTION_SUCCESS });
    return response;
  } catch (error) {
    dispatch({ type: ERROR_ACTION });
    throw error;
  }
};

export const editSpaceAction = (data, files, space) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_SPACE });
    const formData = new FormData();
    const newChannelsIds = [];

    if (!isEmpty(data.facebookSelect)) newChannelsIds.push(data.facebookSelect);
    if (!isEmpty(data.whatsappSelect)) newChannelsIds.push(data.whatsappSelect);
    if (isEmpty(newChannelsIds)) throw Error('ERROR_EMPTY_CHANNELS');
    if (!isEmpty(files) && !files[0].uploaded) {
      formData.append('file', files[0]);
    }

    formData.append('spaceName', data.spaceName);
    formData.append('id', space.id);
    formData.append('newChannels', JSON.stringify(newChannelsIds));
    await updateSpace(formData);
    dispatch({ type: ACTION_SUCCESS });
  } catch (error) {
    dispatch({ type: ERROR_ACTION });
    throw error;
  }
};

export const deleteSpaceAction = (space) => async (dispatch) => {
  try {
    dispatch({ type: GET_SPACES });
    await deleteSpace(space.id);
    await dispatch(getSpacesAction());
  } catch (error) {
    dispatch({ type: ERROR_ACTION });
    throw error;
  }
};

export const updateWhatsappChannelsNameInSpacesAction = (channelId, newSlotName) => (dispatch, getState) => {
  const spacesData = getState().spaces.data;
  const selectedSpace = getState().spaces.selected;

  let channelToUpdate = null;
  let channelToUpdateIndex = null;
  let spaceToUpdate = null;
  let spaceToUpdateIndex = null;
  spacesData.some((space, index) => {
    channelToUpdateIndex = space.channels.findIndex((channel) => channel.id === channelId);
    if (channelToUpdateIndex > -1) {
      channelToUpdate = { ...space.channels[channelToUpdateIndex] };
      spaceToUpdateIndex = index;
      spaceToUpdate = { ...space };
      return true;
    }
    return false;
  });

  const newChannel = { ...channelToUpdate, name: newSlotName };
  const newChannelsData = [...spacesData[spaceToUpdateIndex].channels];
  newChannelsData.splice(channelToUpdateIndex, 1, newChannel);

  const newSpace = { ...spaceToUpdate, channels: newChannelsData, whatsapp: newSlotName };
  const newSpacesData = [...spacesData];
  newSpacesData.splice(spaceToUpdateIndex, 1, newSpace);

  dispatch({ type: GET_SPACES_SUCCESS, payload: newSpacesData });

  if (spaceToUpdate?.id === selectedSpace?.id) {
    dispatch({ type: SET_SELECTED_SPACE, payload: newSpace });
  }
};

export const clearDataAction = () => ({
  type: CLEAR_DATA,
  payload: null,
});
