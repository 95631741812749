import gql from "graphql-tag";

const GET_TEMPLATES = gql`
  query getTemplates($companyId: String!) {
    whatsappTemplates(where: { companyId: $companyId }) {
      id
      name
      status
      type
      channelId
      category
      channel {
        id
        name
        providerId
      }
      components {
        format
        text
        exampleBody {
          body_text
        }
        exampleHeader {
          header_handle
        }
      }
    }
  }
`;

const GET_TEMPLATES_BY_CHANNEL_ID = gql`
  query getTemplates($channelId: String!) {
    whatsappTemplates(where: { channelId: $channelId, status: "accepted" }) {
      id
      name
      status
      type
      channelId
      category
      channel {
        id
        name
        providerId
      }
      components {
        format
        text
        exampleBody {
          body_text
        }
        exampleHeader {
          header_handle
        }
      }
    }
  }
`;

const ApolloTemplates = {
  GET_TEMPLATES,
  GET_TEMPLATES_BY_CHANNEL_ID,
};

export default ApolloTemplates;
