import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import { orderBy } from 'lodash';

import Card from '../../../components/shared/components/card/Card.component';
import Search from '../../../components/search/Search';
import {
  MultimediaActionsContainer,
  SearchContainer,
  MultimediaCardsContainer,
  MultimediaCard,
  MultimediaEmpty,
} from './Multimedia.style';
import { getLabelMessage, getJcnError } from '../../../const/labelService';
import GeneralModal from '../../../components/shared/components/generalModal/GeneralModal.component';
import { getAlbumsAction, createAlbumAction, setAlbumAsSelectedAction } from '../../../redux/multimediaDuck';
import formatDateUtil from '../../../tools/date-utils';
import LoaderComponent from '../../../components/loader/Loader.component';
import toastConfig from '../../../components/shared/constants/toaster-config';
import OrderByModal from '../../../components/orderByModal/OrderByModal.component';
import CONSTANTS from '../../../const/constants';
import AddButton from '../../../components/shared/components/addButton/addButton.component';

const Multimedia = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const labels = [
    'multimediaOrderByLabel',
    'multimediaCreationDate',
    'multimediaEmpty',
    'multimediaAddNewModalAlbum',
    'multimediaCreateAlbum',
    'btnCancel',
    'createNewAlbumButtonLabel',
  ];
  const lbls = getLabelMessage(labels);

  const albums = useSelector((state) => state.multimedia.albums);
  const isLoadingAlbums = useSelector((state) => state.multimedia.fetching);
  const [displayedAlbums, setDisplayedAlbums] = useState([...albums]);
  const [searchValue, setSearchValue] = useState('');
  const canWrite = useSelector((state) => state.user?.data?.permissions?.writeMultimedia);

  const [isCreateNewAlbumModalOpen, setIsCreateNewAlbumModalOpen] = useState(false);
  const [isNewAlbumLoading, setIsNewAlbumLoading] = useState(false);

  const selectedSpaceName = useSelector((state) => state.spaces.selected.name);
  const selectedSpaceId = useSelector((state) => state.spaces.selected.id);
  const arrayForm = [{ name: 'createNewAlbumName', show: true }];

  useEffect(() => {
    dispatch(getAlbumsAction([selectedSpaceId]));
  }, []);

  useEffect(() => {
    dispatch(getAlbumsAction([selectedSpaceId]));
  }, [selectedSpaceName]);

  useEffect(() => {
    setDisplayedAlbums(albums);
  }, [albums]);

  const handleSearch = (e) => {
    const newDisplayedAlbums = albums.filter((x) => x.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setDisplayedAlbums(newDisplayedAlbums);
    setSearchValue(e.target.value);
  };

  const onAlbumClick = (albumId) => {
    dispatch(setAlbumAsSelectedAction(albumId));
    history.push(CONSTANTS.ALBUM_ROUTE);
  };

  const createAlbum = async (data) => {
    try {
      setIsNewAlbumLoading(true);
      await dispatch(createAlbumAction(data.createNewAlbumName, data.createNewAlbumSpaceSelect));
      history.push(CONSTANTS.ALBUM_ROUTE);
    } catch (error) {
      toast.error(getJcnError(error.message), toastConfig);
    } finally {
      setIsNewAlbumLoading(false);
    }
  };

  const orderByOption = (option) => {
    if (option === 'creationDate') {
      const newAlbumOrder = orderBy(displayedAlbums, [(o) => moment(o.createdAt).format('x')]);
      setDisplayedAlbums(newAlbumOrder);
    } else if (option === 'name') {
      const newAlbumOrder = orderBy(displayedAlbums, [(o) => o.name.toLowerCase()]);
      setDisplayedAlbums(newAlbumOrder);
    }
  };

  return (
    <>
      {isLoadingAlbums && <LoaderComponent width={70} height={70} /> }
      {!isLoadingAlbums && (
        <>
          <MultimediaActionsContainer>
            {canWrite && (
              <AddButton onClick={() => setIsCreateNewAlbumModalOpen(true)} label={lbls.createNewAlbumButtonLabel} />
            )}
            <SearchContainer>
              <Search handleSearch={handleSearch} value={searchValue} />
            </SearchContainer>
          </MultimediaActionsContainer>
          <OrderByModal onOptionClick={orderByOption} />
          { albums?.length === 0 && <MultimediaEmpty>{lbls.multimediaEmpty}</MultimediaEmpty> }
          { albums?.length > 0 && (
            <MultimediaCardsContainer>
              { displayedAlbums?.map((album) => (
                <MultimediaCard key={album.id}>
                  <Card
                    name={album.name}
                    albumsCount={album.childs?.length}
                    filesCount={album.files?.length}
                    creationDate={formatDateUtil(album.createdAt, '/')}
                    image={album.files?.filter(
                      (x) => x.fileType !== CONSTANTS.FILE_TYPE_PDF && x.fileType !== CONSTANTS.FILE_TYPE_VIDEO,
                    )[0]?.url}
                    onClick={() => onAlbumClick(album.id)}
                  />
                </MultimediaCard>
              )) }
            </MultimediaCardsContainer>
          ) }
          <GeneralModal
            type="general"
            mainText={lbls.multimediaAddNewModalAlbum}
            confirmText={lbls.multimediaCreateAlbum}
            cancelText={lbls.btnCancel}
            handleCancelButton={() => setIsCreateNewAlbumModalOpen(false)}
            handleConfirmButton={(data) => createAlbum(data)}
            show={isCreateNewAlbumModalOpen}
            arrayForm={arrayForm}
            loadingForm={isNewAlbumLoading}
          />
        </>
      )}
    </>
  );
};

export default Multimedia;
