import gql from 'graphql-tag';

const GET_QUICK_ANSWERS = gql`
  query quickAnswers($spaceId: String!) {
    quickAnswers(where: { spaceId: $spaceId }, orderBy: createdAt_ASC) {
      id
      shortcut
      text
      file {
        id
        name
        url
        fileType
        weight
        key
      }
      lastUse
      frecuency
    }
  }
`;

const GET_QUICK_ANSWERS_ORDER_DESC = gql`
  query quickAnswers($spaceId: String!) {
    quickAnswers(where: { spaceId: $spaceId }, orderBy: createdAt_DESC) {
      id
      shortcut
      text
      file {
        id
        name
        url
        fileType
        weight
        key
      }
      lastUse
      frecuency
      createdAt
    }
  }
`;

const DELETE_QUICK_ANSWER = gql`
  mutation deleteQuickAnswer($quickAnswerId: ID!) {
    deleteQuickAnswer(where: {id: $quickAnswerId}) {
      id
    }
  }
`;

const UPDATE_QUICK_ASNWER_LAST_USER = gql`
  mutation updateQuickAnswerLastUse($quickAnswerId: ID!, $lastUse: DateTime!) {
    updateQuickAnswer(where: { id: $quickAnswerId }, data: { lastUse: $lastUse }) {
      id
      lastUse
    }
  }
`;

const ApolloQuickAnswers = {
  GET_QUICK_ANSWERS,
  DELETE_QUICK_ANSWER,
  GET_QUICK_ANSWERS_ORDER_DESC,
  UPDATE_QUICK_ASNWER_LAST_USER,
};

export default ApolloQuickAnswers;
