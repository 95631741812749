import styled from 'styled-components';

export const NewTagViewContainer = styled.div``;

export const StepperContainer = styled.div`
  margin-bottom: 2.5rem;
`;

export const InputContainer = styled.div`
  width: 50%;
  margin-bottom: 3rem;
`;

export const ColorPick = styled.section`
  display: flex;
  width: 100%;
  &  > div > :nth-child(3) {
    & :nth-child(8) {
      display: none !important;
    }
    & :nth-child(9) {
      display: none !important;
    }
  }
`;

export const ColorBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColorTitle = styled.span`
  font-size: 0.75rem;
  margin-bottom: .5rem;
  opacity: 0.5;
`;

export const ChooseColor = styled.div`
  background-color: ${(props) => props.color};
  width: 5%;
  margin-left: 2rem;
  border-radius: 4px;
`;
