import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import LoaderComponent from "../../../../loader/Loader.component";

function PDFViewer({ url }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    console.log("file", url);
  }, [url]);

  return (
    <div>
      <Document
        // className="pdfDocument"
        file={{url}}
        loading={<LoaderComponent width={30} height={30} />}
        onLoadSuccess={onDocumentLoadSuccess}
        renderAnnotationLayer={false}
      >
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          className="pdfDocument"
        />
      </Document>
    </div>
  );
}

export default PDFViewer;
