import gql from 'graphql-tag';

const CREATE_WELCOME_MESSAGE = gql`
  mutation createWelcomeMessage(
    $companyId: ID!,
    $text: String,
    $isActive: Boolean!,
    $useInWorkingHours: Boolean!,
    $channelId: String,
    $templateId: String
  ) {
    createWelcomeMessage(
      data: {
        company: { connect: { id: $companyId } },
        text: $text,
        isActive: $isActive,
        useInWorkingHours: $useInWorkingHours,
        channelId: $channelId,
        templateId: $templateId
      }
    ) {
      id
      text
      isActive
    }
  }
`;

const GET_WELCOME_MESSAGE = gql`
  query getWelcomeMessages($companyId: ID!, $channelId: String!) {
    welcomeMessages(where: { company: { id: $companyId }, channelId: $channelId }) {
      id
      text
      isActive
      useInWorkingHours
      channelId
      templateId
    }
  }
`;
const GET_WELCOME_MESSAGE_TEMPLATE = gql`
  query getWelcomeMessages($templateId: String!) {
    welcomeMessages(where: { templateId: $templateId }) {
      id
      text
      isActive
      useInWorkingHours
      channelId
      templateId
    }
  }
`;

const UPDATE_IS_ACTIVE_WELCOME_MESSAGE = gql`
  mutation updateWelcomeMessage($id: ID!, $isActive: Boolean!) {
    updateWelcomeMessage(
      where: { id: $id }
      data: {
        isActive: $isActive,
      }
    ) {
      id
    }
  }
`;

const UPDATE_WELCOME_MESSAGE = gql`
  mutation updateWelcomeMessage(
    $id: ID!,
    $text: String,
    $isActive: Boolean!,
    $useInWorkingHours: Boolean!,
    $templateId: String
  ) {
    updateWelcomeMessage(
      where: { id: $id }
      data: {
        isActive: $isActive,
        text: $text
        useInWorkingHours: $useInWorkingHours
        templateId: $templateId
      }
    ) {
      id
    }
  }
`;

const ApolloWelcomeMessage = {
  CREATE_WELCOME_MESSAGE,
  GET_WELCOME_MESSAGE,
  GET_WELCOME_MESSAGE_TEMPLATE,
  UPDATE_IS_ACTIVE_WELCOME_MESSAGE,
  UPDATE_WELCOME_MESSAGE,
};

export default ApolloWelcomeMessage;
