export const getSucriptionAlertMessage = ({
  plan,
  daysToExpire,
  paymentIntents,
  paymentAmount,
  penalty,
  cardNumber,
  paymentMethod,
  expirationDate,
}) => {
  console.log('paymentIntents', paymentIntents);
  if (paymentIntents === 1 || paymentIntents === 2) {
    if (paymentMethod === "CARD") {
      return {
        message: `Tuvimos problemas al cobrar la suscripción, intentaremos cobrar de nuevo en unos días`,
        type: "warning",
        buttonMessage: "Pagar ahora",
      };
    }
    if (paymentMethod === "TRANSFER") {
      return {
        message:
          "No se ha recibido el pago de la transferencia, por favor verifica que se haya realizado correctamente",
        type: "warning",
        buttonMessage: "Pagar ahora",
      };
    }
  }
  if (paymentIntents === 3) {
    if (paymentMethod === "CARD") {
      return {
        message: `Tuvimos problemas al cobrar la suscripción, evita que tu cuenta sea pausada`,
        type: "error",
        buttonMessage: "Pagar ahora",
      };
    }
    if (paymentMethod === "TRANSFER") {
      return {
        message: `Tuvimos problemas al cobrar la suscripción, evita que tu cuenta sea pausada`,
        type: "error",
        buttonMessage: "Pagar ahora",
      };
    }
  }
  if (paymentIntents > 3) {
    return {
      message: `Tu cuenta ha sido pausada por falta de pago, por favor realiza el pago para reactivarla`,
      type: "error",
      buttonMessage: "Pagar ahora",
    };
  }
  if (daysToExpire <= 1 && daysToExpire > 0) {
    return {
      message:
        "El día de mañana se hará el cobro de tu plan, por favor verifica que tengas saldo suficiente en tu tarjeta.",
      type: "warning",
      buttonMessage: "Ver cuenta",
    };
  }

  return null;
};
