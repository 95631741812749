import { find, has, some } from 'lodash';
import CONSTANTS from '../const/constants';

const protectedRoutes = [
  {
    path: "/chat",
    permissions: ["readChat", "agent"],
  },
  {
    path: "/bot",
    permissions: ["writeUsers"],
  },
  {
    path: "/broadcast",
    permissions: ["writeUsers"],
  },
  {
    path: "/broadcast/:currentStep",
    permissions: ["writeUsers"],
  },
  {
    path: "/contacts",
    permissions: ["readChat", "agent"],
  },
  {
    path: "/users",
    permissions: ["readUsers", "writeUsers"],
  },
  {
    path: "/users/new",
    permissions: ["writeUsers"],
  },
  {
    path: "/spaces",
    permissions: ["readSpaces", "writeSpaces"],
  },
  {
    path: "/spaces/new",
    permissions: ["writeSpaces"],
  },
  {
    path: "/spaces/new-whatsapp",
    permissions: ["writeSpaces"],
  },
  {
    path: "/config",
    permissions: [
      "readCompany",
      "writeCompany",
      "readPayments",
      "writePayments",
      "readTags",
      "writeTags",
      "readRols",
      "writeRols",
      "readDataSheetConfiguration",
      "writeDataSheetConfiguration",
    ],
  },
  {
    path: "/config/new-tag",
    permissions: ["writeTags"],
  },
  {
    path: "/config/new-rol",
    permissions: ["writeRols"],
  },
  {
    path: "/config/new-datasheet",
    permissions: ["writeDataSheetConfiguration"],
  },
  {
    path: "/config/new-whatsapp-template",
    permissions: ["whatsappTemplate"],
  },
  {
    path: "/config/edit-plan",
    permissions: ["writePayments"],
  },
  {
    path: "/config/edit-card",
    permissions: ["writePayments"],
  },
  {
    path: "/config/payment",
    permissions: ["writePayments"],
  },
  {
    path: CONSTANTS.MULTIMEDIA_ROUTE,
    permissions: ["agent", "writeMultimedia", "readMultimedia"],
  },
  {
    path: CONSTANTS.ALBUM_ROUTE,
    permissions: ["agent", "writeMultimedia", "readMultimedia"],
  },
  {
    path: CONSTANTS.SUBALBUM_ROUTE,
    permissions: ["agent", "writeMultimedia", "readMultimedia"],
  },
  {
    path: CONSTANTS.QUICK_ANSWERS_ROUTE,
    permissions: ["agent", "readQuickAnswers", "writeQuickAnswers"],
  },
  {
    path: CONSTANTS.QUICK_ANSWERS_ACTION_ROUTE,
    permissions: ["agent", "writeQuickAnswers"],
  },
  {
    path: CONSTANTS.LINK_WHATSAPP_ROUTE,
    permissions: ["writeSpaces"],
  },
  {
    path: CONSTANTS.CONFIG_NEW_WHATSAPPTEMPLATE_ROUTE,
    permissions: ["whatsappTemplate"],
  },
  {
    path: CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE,
    permissions: ["readPayments", "writePayments"],
  },
  {
    path: "/account",
    permissions: ["readCompany", "writeCompany"],
  },
  {
    path: "/panel",
    permissions: ["readCompany"],
  },
  {
    path: "/analytics",
    permissions: ["readCompany", "writeCompany", "readAnalytics"],
  },
  {
    path: "/analytics/agents",
    permissions: [
      "readCompany",
      "writeCompany",
      "readAnalytics",
      "agent",
      "readUsers",
      "writeUsers",
    ],
  },
  {
    path: "/help",
    permissions: ["readCompany", "writeCompany"],
  },
  {
    path: "/broadcast/table",
    permissions: ["campaigns"],
  },
];

const routeGuard = (path, user) => {
  const { permissions } = user.data;
  const protectedRoute = find(protectedRoutes, ['path', path]);
  const credentials = protectedRoute ? protectedRoute.permissions : [];
  const response = some(credentials, (credential) => has(permissions, credential));
  return response;
};

export default routeGuard;
