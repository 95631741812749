import styled from 'styled-components';

import { IoMdHelpCircle } from 'react-icons/io';

export const Bar = styled.section`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const SlotArea = styled.div`
  height: 2rem;
  width: 2rem;
  overflow: hidden;
  cursor: pointer;
`;

export const OptionsBar = styled.div`
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  justify-content: flex-start;
`;
export const Support = styled(IoMdHelpCircle)`
  font-size: 1.5rem;
  opacity: 0.4;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const ContactModal = styled.div`
  width: 30%;
  background-color: #ffffff;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

export const ModalBlur = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: absolute;
  z-index: 20;
  background-color: ${(props) => (props.transparent ? 'transparent' : 'rgba(0, 0, 0, 0.5)')};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: ${(props) => (props.transparent ? 'none' : 'blur(4px)')};
  justify-content: center;
  align-items: center;
`;
export const Title = styled.p`
  font-family: Montserrat-Bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
export const SubTitle = styled.p`
  font-family: Montserrat-Medium;
  font-size: 1rem;
  margin-bottom: 1rem;
`;
export const Email = styled.p`
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: ${(props) => props.theme.primary};
  margin-bottom: 1rem;
`;
export const Whatsapp = styled.p`
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: ${(props) => props.theme.primary};
  cursor: pointer;
  margin-bottom: 1rem;
`;
