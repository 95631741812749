/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import {
  isNull,
  find,
  isUndefined,
  findIndex,
  map,
  head,
  last,
  intersectionWith,
} from "lodash";

import client from "../apollo/apolloClient";
import API from "../axios";
import ApolloMessages from "../apollo/apolloMessages";
import ApolloConversations from "../apollo/conversations";
import ApolloAdmins from "../apollo/apolloAdmins";
import CONSTANTS from "../const/constants";
import { getLabelMessage } from "../const/labelService";
import {
  SET_CONVERSATION_AS_SELECTED,
  GET_CONVERSATIONS_SUCCESS,
} from "../redux/conversationsDuck";
import { toast } from "react-toastify";

export const getContacts = async ({
  channelId,
  companyId,
  selectedSpacesIds,
}) => {
  try {
    const graphQueryFilters = { channelId, companyId };

    const {
      data: { clients: fetchedConversations },
    } = await client.query({
      query:
        ApolloConversations.GET_ALL_CONVERSATION_COMPACTED(graphQueryFilters),
      variables: graphQueryFilters,
    });

    return fetchedConversations;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getContactsByKeyword = async ({
  companyId,
  keyword,
  channelId,
}) => {
  try {
    const graphQueryFilters = { companyId, keyword, channelId };
    console.log("getContactsByKeyword=======>", companyId, keyword, channelId);

    const {
      data: { clients: fetchedConversations },
    } = await client.query({
      query:
        ApolloConversations.GET_CONVERSATIONS_BY_SEARCHING_KEYWORD(
          graphQueryFilters
        ),
      variables: graphQueryFilters,
    });

    return fetchedConversations;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const importContacts = async (formData, setProgress) => {
  try {
    console.log("importContacts=======>", formData);

    const response = await API.post("/importContacts", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log(response.data.importContacts);
    return response.data.importContacts;
  } catch (error) {
    toast.error("Error importing contacts");
    throw error;
  }
};
