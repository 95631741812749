import { isEmpty } from 'lodash';

import {
  getStripeCustomer,
  cancelSubscription,
  firstPayment,
  getPaymentCustomer,
} from '../utils/payments';

const PAYMENT_INIT_STATE = {
  payment: null,
  fetched: false,
  fetching: false,
  customer: null,
};

const GETTING_PAYMENT = 'GETTING_PAYMENT';
const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
const UPDATING_PAYMENT = 'UPDATING_PAYMENT';
const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
const CLEAR_DATA = 'CLEAR_DATA';

export default function rolesReducer(state = PAYMENT_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_PAYMENT:
      return { ...state, fetching: true };
    case GET_PAYMENT_SUCCESS:
      return { ...state, fetching: false, payment: payload };
    case UPDATING_PAYMENT:
      return { ...state, fetching: true };
    case UPDATE_PAYMENT_SUCCESS:
      return { ...state, fetching: false, payment: payload };
    case GET_CUSTOMER_SUCCESS:
      return { ...state, fetching: false, payment: payload.payment, customer: payload.customer };
    case CLEAR_DATA:
      return { ...PAYMENT_INIT_STATE };
    default:
      return state;
  }
}

export const getStripeInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GETTING_PAYMENT });
    const response = await getStripeCustomer();
    dispatch({ type: GET_CUSTOMER_SUCCESS, payload: response });
    return response;
  } catch (error) {
    const JcnError = new Error('CANNOT_GET_PAYMENT');
    throw JcnError;
  }
};

export const cancelSubscriptionAction = () => async () => {
  try {
    const response = await cancelSubscription();
    return response;
  } catch (error) {
    const JcnError = new Error('CANNOT_GET_PAYMENT');
    throw JcnError;
  }
};

export const getPaymentCustomerAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: GETTING_PAYMENT });
    const response = await getPaymentCustomer(companyId);
    if (!isEmpty(response)) {
      dispatch({ type: GET_CUSTOMER_SUCCESS, payload: {
        customer: response,
      }});
      return response;
    }
    return false;
  } catch (error) {
    throw error;
  }
}
