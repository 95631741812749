import { getAdmins, deleteAdmin, addAdmin, editAdmin } from '../utils/getAdmins';

const ADMINS_INITIAL_STATE = {
  data: [],
  fetched: false,
  fetching: false,
  current: {},
  index: null,
  editAdminSuccess: false,
};

// constants
const SET_ADMINS = 'SET_ADMINS';
const GET_ADMINS = 'GET_ADMINS';
const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';
const SET_ADMIN_AS_SELECTED = 'SET_ADMIN_AS_SELECTED';
const UNSELECT_ADMIN = 'UNSELECT_ADMIN';
const DELETE_ADMIN = 'DELETE_ADMIN';
const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
const CLEAR_EDIT_ADMIN_SUCCESS_ACTION = 'CLEAR_EDIT_ADMIN_SUCCESS_ACTION';
const CLEAR_ADMINS = 'CLEAR_ADMINS';
const CLEAR_DATA = 'CLEAR_DATA';

// reducer
export default function adminsReducer(state = { ...ADMINS_INITIAL_STATE }, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_ADMINS:
      return payload;
    case GET_ADMINS:
      return { ...state, fetching: true };
    case GET_ADMINS_SUCCESS:
      return { ...state, fetching: false, fetched: true, data: payload };
    case GET_ADMINS_ERROR:
      return { ...state, fetching: false, fetched: true, error: payload };
    case SET_ADMIN_AS_SELECTED:
      return { ...state, current: payload.selectedadmin, index: payload.index };
    case UNSELECT_ADMIN:
      return { ...state, current: {}, index: null };
    case DELETE_ADMIN:
      return { ...state, data: payload.data, current: {}, index: null, fetching: false };
    case EDIT_ADMIN_SUCCESS:
      return { ...state, editAdminSuccess: true };
    case CLEAR_EDIT_ADMIN_SUCCESS_ACTION:
      return { ...state, editAdminSuccess: false };
    case CLEAR_ADMINS:
      return { ...ADMINS_INITIAL_STATE };
    case CLEAR_DATA:
      return { ...ADMINS_INITIAL_STATE };
    default:
      return state;
  }
}

// actions
export const setAdminsAction = (messages) => ({ type: SET_ADMINS, payload: messages });

export const getAdminsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ADMINS });
    const { companyId } = getState().user.data;

    const admins = await getAdmins(companyId);
    const adminsFormated = admins.map((e) => ({ ...e, selected: false }));
    dispatch({ type: GET_ADMINS_SUCCESS, payload: adminsFormated });
  } catch (error) {
    dispatch({ type: GET_ADMINS_ERROR, payload: error });
  }
};

export const setAdminAsSelectedAction = (index) => (dispatch, getState) => {
  const selectedadmin = getState().admins.data[index];
  dispatch({ type: SET_ADMIN_AS_SELECTED, payload: { selectedadmin, index } });
};

export const unSelectAdminAction = () => (dispatch, getState) => {
  dispatch({ type: UNSELECT_ADMIN, payload: null });
};

export const deleteAdminAction = () => async (dispatch, getState) => {
  dispatch({ type: GET_ADMINS });
  const admins = { ...getState().admins };
  const status = await deleteAdmin(admins.current.id, admins.current.email);
  if (status) {
    dispatch(getAdminsAction());
    return true;
  }
  return false;
};

export const addAdminAction = (data) => async (dispatch, getState) => {
  try {
    const { companyId } = getState().user.data;
    const adminData = { ...data, companyId, locationId: data.location };
    delete adminData.passwordConfirmation;
    delete adminData.location;
    adminData.type = 'ADMIN';
    const status = await addAdmin(adminData);
    return status;
  } catch (error) {
    return { error };
  }
};

export const editAdminAction = (data) => async (dispatch, getState) => {
  const selectedadmin = getState().admins.current;
  const adminData = { ...data, id: selectedadmin.id };
  const status = await editAdmin(adminData, selectedadmin.email);
  if (status) {
    dispatch({ type: EDIT_ADMIN_SUCCESS });
    return true;
  }
  return false;
};

export const clearEditAdminsSuccessAction = () => ({
  type: CLEAR_EDIT_ADMIN_SUCCESS_ACTION,
  payload: null,
});

export const clearAdminsAction = () => ({
  type: CLEAR_ADMINS,
  payload: null,
});
