import client from '../apollo/apolloClient';
import API from '../axios';
import ApolloMultimedia from '../apollo/apolloMultimedia';

export const getAlbums = async (spaces) => {
  const variables = { isRoot: true, spacesIdIn: spaces };
  const { data } = await client.query({
    query: ApolloMultimedia.GET_ALBUMS,
    variables,
  });
  return data.albums;
};

export const createAlbum = async (companyId, spaceId, name, parentId) => {
  const data = { companyId, spaceId, name };
  if (parentId) data.parentId = parentId;
  const response = await API.post('/createAlbum', data);
  return response.data;
};

export const uploadFile = async (formData, onUploadProgress) => {
  try {
    const config = {
      onUploadProgress: (progressEvent) => {
        onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      },
    };
    const response = await API.put('/uploadFile', formData, config);
    return response.data;
  } catch (error) {
    onUploadProgress(0, error);
    throw error;
  }
};

export const getFilesAndSubalbums = async (albumId) => {
  const { data } = await client.query({
    query: ApolloMultimedia.GET_ALBUM_FILES_AND_SUBALBUMS,
    variables: { albumId },
  });
  return data.album;
};

export const deleteFile = async (fileId) => {
  const response = await API.delete('/removeContent', { data: { fileId } });
  return response;
};

export const deleteAlbum = async (albumId) => {
  const response = await API.delete('/deleteAlbum', { data: { albumId } });
  return response;
};

export const getSubAlbumFiles = async (albumId) => {
  const { data } = await client.query({
    query: ApolloMultimedia.GET_SUBALBUM_FILES,
    variables: { albumId },
  });
  return data.album;
};

export const uploadVideo = async (url, albumId) => {
  const response = await API.put('/uploadFile', { name: url, url, albumId, fileType: 'VIDEO' });
  return response.data;
};
