import ReactImageAppear from 'react-image-appear';
import React from 'react';
import PropTypes from 'prop-types';

import DefaultImage from "../../../../assets/images/users/placeholder_1.svg";
import Cointainer from "./FadeImage.style";


const FadeImage = ({ image }) => (
  <Cointainer image={image?.length > 0}>
    <ReactImageAppear
      key={image}
      src={image || DefaultImage}
      animation="fadeIn"
      placeholder={DefaultImage}
      animationDuration="2s"
      loaderStyle={{ display: 'none' }}
    />
  </Cointainer>
);

FadeImage.propTypes = {
  image: PropTypes.string,
};

export default FadeImage;
