/* eslint-disable import/no-cycle */
import {
  differenceWith,
  intersectionWith,
  isEqual,
  map,
  orderBy,
  uniqBy,
  groupBy,
  get,
} from "lodash";
import { Semaphore } from "await-semaphore";

import moment from "moment";
import {
  changeCategory,
  connectTag,
  createClientFromPhone,
  getPlatformApiFromPhone,
  deleteConversationCallback,
  disconnectTag,
  getClientInfo,
  getConversations,
  sendToCrm,
  updateClientInfo,
  updateClientUsernameMutation,
  updateValuableByUserMutation,
  updateClientPhoneMutation,
  checkExistedPhone,
  deleteConversation,
  setIsArchived,
  getClientReport,
  getBroadCastConversationsQuery,
  getClientReportToExport,
} from "../utils/getConversations";
import { assignAgent } from "../utils/getAgents";
import { getLastSurveyInfo } from "../utils/survey";
import {
  ADD_NEW_MESSAGES,
  clearMessagesAction,
  handleNewMessagesButtonAction,
  updateMessageStatusId,
  seenMessage,
  setConstWindowRemainingTimeAction,
} from "./messagesDuck";
import { setAppStatus } from "./appDuck";
import CONSTANTS from "../const/constants";
import { isDialogWindowOpen } from "../tools/date-utils";
import cleanString from "../utils/cleanString";
import client from "../apollo/apolloClient";
import ApolloAgents from "../apollo/agents";
import { getAllAgents } from "./agentsDuck";
import { getClientInformationUtil } from "../utils/datasheet";
import ApolloConversations from "../apollo/conversations";
import API from "../axios";

const semaphoreDeleteConversation = new Semaphore(1);

const CONVERSATIONS_INITIAL_STATE = {
  fetching: false,
  fetched: false,
  data: [],
  current: {},
  isCurrentWindowOpen: false,
  isFirstQuery: true,
  searchFilter: "",
  isClientInfoOpen: false,
  handleScroll: false,
  source: null, // request, subscription
  hasMore: true,
  unreadBroadcastChat: 0,
  isNewBroadcastChat: true,
  allUsersForFilter: [], // used for filter in Vinte admins
};

let onSnapshot = null;
let subscriptionObserver = null;
let subscriptionObserverMessageStatus = null;
let unsassignedAgentObserver = null;

// constants
export const SET_CONVERSATIONS = "SET_CONVERSATIONS";
const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_CONVERSATIONS_SUCCESS = "GET_CONVERSATIONS_SUCCESS";
const GET_CONVERSATIONS_ERROR = "GET_CONVERSATIONS_ERROR";
export const SET_CONVERSATION_AS_SELECTED = "SET_CONVERSATION_AS_SELECTED";
const CLEAR_CONVERSATIONS = "CLEAR_CONVERSATIONS";
const CLEAR_SELECTED_CONVERSATION = "CLEAR_SELECTED_CONVERSATION";
const UPDATE_CLIENT_INFO = "UPDATE_CLIENT_INFO";
const SET_SEARCH_FILTER = "SET_SEARCH_FILTER";
const SET_CURRENT_WINDOW_OPEN = "SET_CURRENT_WINDOW_OPEN";
const HANDLE_CLIENT_INFO = "HANDLE_CLIENT_INFO";
const HANDLE_SCROLL = "HANDLE_SCROLL";
const THERE_IS_NO_MORE_CONVERSATIONS = "THERE_IS_NO_MORE_CONVERSATIONS";
const THERE_IS_MORE_CONVERSATIONS = "THERE_IS_MORE_CONVERSATIONS";
const UPDATE_REASSING_TAG = "UPDATE_REASSING_TAG";
const UPDATE_USER_NAME = "UPDATE_USER_NAME";
const UPDATE_USER_PHONE = "UPDATE_USER_PHONE";
const CLEAR_DATA = "CLEAR_DATA";
const UPDATE_VALUABLE_CLASSIFICATION = "UPDATE_VALUABLE_CLASSIFICATION";
const UPDATE_HASFOLLOWUPMESSAGE = "UPDATE_HASFOLLOWUPMESSAGE";
const UPDATE_LAST_MESSAGE_CONVERSATION = "UPDATE_LAST_MESSAGE_CONVERSATION";
const RESET_CONVERSATION_CURRENT = "RESET_CONVERSATION_CURRENT";
const GET_BROADCAST_CONVERSATIONS = "GET_BROADCAST_CONVERSATIONS";
const UPDATE_UNREAD_BROADCAST_CHAT = "UPDATE_UNREAD_BROADCAST_CHAT";
const SET_CURRENT_CONVERSATION = "SET_CURRENT_CONVERSATION";
const SET_USER_FILTERS = "SET_USER_FILTERS";
const SET_CURRENT_LAST_SURVEY_INFO = "SET_CURRENT_LAST_SURVEY_INFO";
const SET_CONVERSATION_CLOSED = "SET_CONVERSATION_CLOSED";

// reducer
export default function conversationsReducer(
  state = CONVERSATIONS_INITIAL_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_CONVERSATIONS:
      return { ...state, data: payload };
    case GET_CONVERSATIONS:
      if (payload) return { ...state, fetching: true, current: {} };
      return { ...state, fetching: true };
    case GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: payload.conversations,
        source: payload.source,
        isFirstQuery: false,
      };
    case GET_CONVERSATIONS_ERROR:
      return { ...state, fetching: false, error: payload, current: {} };
    case SET_CURRENT_WINDOW_OPEN:
      return { ...state, isCurrentWindowOpen: true };
    case SET_CONVERSATION_AS_SELECTED:
      return {
        ...state,
        data: payload.data,
        current: {
          ...payload.current,
          tags:
            payload?.current?.tags?.length > 0
              ? uniqBy(payload.current.tags, "id")
              : [],
        },
        isCurrentWindowOpen: payload.isCurrentWindowOpen,
        source: payload.source || state.source,
      };
    case CLEAR_SELECTED_CONVERSATION:
      return { ...state, current: {} };
    case SET_SEARCH_FILTER:
      return { ...state, searchFilter: payload };
    case HANDLE_CLIENT_INFO:
      return { ...state, isClientInfoOpen: payload };
    case HANDLE_SCROLL:
      return { ...state, handleScroll: !state.handleScroll };
    case UPDATE_CLIENT_INFO:
      return {
        ...state,
        current: {
          ...state.current,
          ...payload,
          tags:
            state?.current?.tags?.length > 0
              ? uniqBy(state.current.tags, "id")
              : [],
        },
      };

    case SET_CURRENT_LAST_SURVEY_INFO:
      return {
        ...state,
        current: {
          ...state.current,
          lastSurveyInfo: { createdAt: new Date() },
          isClosed: true,
        },
      };
    case SET_CURRENT_LAST_SURVEY_INFO:
      return {
        ...state,
      };
    case THERE_IS_NO_MORE_CONVERSATIONS:
      return { ...state, hasMore: false };
    case THERE_IS_MORE_CONVERSATIONS:
      return { ...state, hasMore: true };
    case UPDATE_USER_NAME:
      return {
        ...state,
        current: {
          ...state.current,
          nameFromChannel: payload.nameFromChannel,
          cleanedName: payload.cleanedName,
        },
      };
    case GET_BROADCAST_CONVERSATIONS:
      return {
        ...state,
        unreadBroadcastChat: payload.unreadBroadcastChat,
      };
    case UPDATE_UNREAD_BROADCAST_CHAT:
      return {
        ...state,
        isNewBroadcastChat: payload.isNewBroadcastChat,
      };
    case UPDATE_USER_PHONE:
      return {
        ...state,
        current: {
          ...state.current,
          cleanedName: payload.cleanedName,
          key: payload.key,
          senderId: payload.senderId,
        },
      };
    case UPDATE_VALUABLE_CLASSIFICATION:
      return {
        ...state,
        current: {
          ...state.current,
          setValuableByUser: payload.value,
          isConversationValuable: payload.value,
        },
        data: payload.data,
      };
    case UPDATE_REASSING_TAG:
      return {
        ...state,
        current: {
          ...state.current,
          tags: payload?.length > 0 ? uniqBy(payload, "id") : [],
        },
      };
    case CLEAR_CONVERSATIONS:
      return { ...CONVERSATIONS_INITIAL_STATE };
    case CLEAR_DATA:
      return { ...CONVERSATIONS_INITIAL_STATE };
    case UPDATE_HASFOLLOWUPMESSAGE:
      return {
        ...state,
        current: {
          ...state.current,
          hasFollowUpMessage: payload.hasFollowUpMessage,
          tags:
            state?.current?.tags?.length > 0
              ? uniqBy(state.current.tags, "id")
              : [],
        },
        data: payload.data,
      };
    case UPDATE_LAST_MESSAGE_CONVERSATION:
      return { ...state, payload };
    case RESET_CONVERSATION_CURRENT:
      return { ...state, current: {} };
    case SET_CURRENT_CONVERSATION:
      return { ...state, current: payload };
    case SET_USER_FILTERS:
      return { ...state, allUsersForFilter: payload };
    default:
      return state;
  }
}

// actions
export const deleteOnSnapshotConversationsListenerAction = () => () => {
  if (subscriptionObserver) {
    subscriptionObserver.unsubscribe();
    subscriptionObserver = null;
  }
  if (subscriptionObserverMessageStatus) {
    subscriptionObserverMessageStatus.unsubscribe();
    subscriptionObserverMessageStatus = null;
  }
  if (unsassignedAgentObserver) {
    unsassignedAgentObserver.unsubscribe();
    unsassignedAgentObserver = null;
  }
  if (onSnapshot) {
    onSnapshot();
    onSnapshot = null;
  }
};

const unassignedAgentObservableNext = async (
  value,
  getState,
  dispatch,
  subscriptionFilters
) => {
  const release = await semaphoreDeleteConversation.acquire();
  try {
    if (value?.data?.client?.node) {
      const { newConversations, newUnsubscribeQuery } =
        await deleteConversationCallback(
          value?.data?.client?.node,
          getState().conversations.data,
          subscriptionFilters
        );
      if (newConversations) {
        const currentConversationId = getState().conversations.current?.id;
        if (currentConversationId === value?.data?.client?.node.id) {
          const payload = { data: newConversations, current: {} };
          dispatch({
            type: SET_CONVERSATION_AS_SELECTED,
            payload: {
              ...payload,
              source: CONSTANTS.CONVERSATIONS_SOURCE_SUBSCRIPTION,
            },
          });
        } else {
          dispatch({
            type: GET_CONVERSATIONS_SUCCESS,
            payload: {
              conversations: newConversations,
              source: CONSTANTS.CONVERSATIONS_SOURCE_SUBSCRIPTION,
            },
          });
        }
      }

      if (newUnsubscribeQuery) {
        if (unsassignedAgentObserver) {
          unsassignedAgentObserver.unsubscribe();
          unsassignedAgentObserver = null;
        }
        unsassignedAgentObserver = newUnsubscribeQuery.subscribe({
          next: (value) =>
            unassignedAgentObservableNext(
              value,
              getState,
              dispatch,
              subscriptionFilters
            ),
          error: (error) => console.log(error),
        });
      }
    }
    release();
  } catch (error) {
    console.log(error);
    release();
  }
};

export const updateLastMessage =
  (
    conversationId,
    messageId,
    messageStatus,
    errorCode = null,
    errorInfo = null,
    windowExpiration
  ) =>
  (dispatch, getState) => {
    const conversations = [...getState().conversations.data];
    const currentId = getState().conversations?.current?.id;

    const newConversations = conversations.map((c) => {
      if (c.id === conversationId && c?.lastMessage?.id === messageId) {
        if (
          (messageStatus === "sent" || messageStatus === "delivered") &&
          windowExpiration
        ) {
          const currentTimestamp = new Date().getTime();

          console.log("windowExpiration realtime => ", windowExpiration);
          console.log("currentTimestamp => ", currentTimestamp);

          const diff = moment.duration(
            windowExpiration * 1000 - currentTimestamp,
            "milliseconds"
          );
          const diffMinutes = Math.floor(diff.asMinutes());
          console.log("diffMinutes => ", diffMinutes);

          if (currentId === conversationId) {
            dispatch(setConstWindowRemainingTimeAction(diffMinutes));
          }

          return {
            ...c,
            windowRemainingTime: diffMinutes,
          };
        } else {
          return {
            ...c,
            lastMessage: {
              ...c.lastMessage,
              messageStatus,
              errorCode,
              errorInfo,
            },
          };
        }
      }
      return { ...c };
    });

    dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
  };

export const getConversationsAction =
  (restart, limit) => async (dispatch, getState) => {
    try {
      dispatch(deleteOnSnapshotConversationsListenerAction());
      if (restart) {
        dispatch(clearMessagesAction());
        dispatch({ type: HANDLE_CLIENT_INFO, payload: false });
        dispatch({ type: HANDLE_SCROLL, payload: null });
        dispatch({ type: GET_CONVERSATIONS, payload: restart });
      }
      const selectedSpaces = getState().spaces.selected;
      const selectedFilterType = getState().filters.type;
      const rolName = getState().user.data.rol.name;
      

      const { channels } = selectedSpaces;

      console.log("selectedSpaces ===> ", selectedSpaces);

      console.log('CHANNELS AT GET CONVERSATION ============> ', JSON.stringify(channels, null, 2))
      const actualAgentId = getState().user.data.id;

      let selectedFilters = [];
      selectedFilters = getState().filters.selected;
      let userRol1 = ""
      userRol1 = getState().user.data.rol.name.toLowerCase();

      console.log("userRol11111111111", userRol1);
      console.log("Otro console", getState().user.data.rol.name);

      if (selectedSpaces?.id === "650dcadea7b11b000891b536") {
        console.log("IS NEW VINTE SPACE");
        selectedFilters.agents = [
          {
            id: CONSTANTS.UNASSIGNED_AGENT_ID,
            name: "Sin asignar",
            selected: false,
          },
        ];
      } else {
        if (selectedFilterType === CONSTANTS.FILTER_ASSIGNED) {
          selectedFilters.agents = [{ id: actualAgentId }];
        }else if (selectedFilterType === CONSTANTS.FILTER_UNREADMESSAGES) {
          selectedFilters.unreadMessages = true;
        } else if (selectedFilterType === CONSTANTS.FILTER_UNANSWERED) {
          selectedFilters.unanswered = true;
        }
        if (userRol1 === CONSTANTS.AGENT || userRol1 === "vendedor") {
          selectedFilters.agents = [{ id: actualAgentId }];
        }
      }

      const searchValue = getState().conversations.searchFilter;
      const conversations = getState().conversations.data;
      const conversationsFilter = getState().conversations.searchFilter;

      console.log("GET CONVERSATIONS ACTION =====> ", conversations);
      const companyId = getState().user.data.company.id;
      const userId = getState().user.data.id;
      const userSpaces = getState().user.data.spaces;
      selectedFilters.companyId = companyId;

      let selectedPagesIds = [];

      if (
        userSpaces.length === 1 &&
        userSpaces[0]?.name === CONSTANTS.VINTE_DEFAULT_NAME
      ) {
        selectedPagesIds = [
          CONSTANTS.VINTE_DEFAULT_WHATSAPP,
          CONSTANTS.VINTE_DEFAULT_FACEBOOK,
        ];
      } else {
        selectedPagesIds = channels
          .filter((c) => c?.platform?.name !== CONSTANTS.SMS_PLATFORM)
          .map((e) => e.id);
      }

      const selectedPagesIdsCleaned = selectedPagesIds.filter((e) => e);
      selectedFilters.selectedSpacesIds = selectedPagesIdsCleaned;

      selectedFilters.skip = restart ? 0 : conversations.length;
      selectedFilters.first = limit || 20;

      if (searchValue) {
        selectedFilters.cleanedName = cleanString(searchValue);
      } else selectedFilters.cleanedName = "";

      const companyName = getState().user.data.company.name;
      //const rolName = getState().user.data.rol.name;
      selectedFilters.isVinte = companyName === CONSTANTS.VINTE_COMPANY_NAME;
      selectedFilters.isAgent =
        rolName !== CONSTANTS.SUPERADMIN
        && rolName !== CONSTANTS.ADMIN
        && rolName !== CONSTANTS.VINTE_COMMUNITY_MANAGER;

      if (rolName === CONSTANTS.GROUPADMIN) {
        selectedFilters.groupAdminId = userId;
        selectedFilters.channels = channels;
      }

      let fetchedConversations = [];

      if (companyName === CONSTANTS.VINTE_COMPANY_NAME) {
        if (rolName === CONSTANTS.ADMIN) {
          // get conversations by admin's channelsId
          fetchedConversations = await getConversations(
            selectedFilters,
            userId
          );

          // get agents conversations
          const agentFilters = { ...selectedFilters };

          agentFilters.agents = [{ id: actualAgentId }];
          console.log("agentFilters", agentFilters);
          console.log("selectedFilters.agents", selectedFilters.agents);
          if (
            selectedFilterType !== CONSTANTS.FILTER_ASSIGNED &&
            selectedFilters.agents?.length === 0
          ) {
            try {
              const agentByPlace = await client.query({
                query: ApolloAgents.GET_AGENTS_NOT_SUPERADMINS_BY_SPACE_ID,
                variables: {
                  spaceId: selectedSpaces?.id,
                },
              });
              if (agentByPlace?.data?.users?.length > 0) {
                agentFilters.agents = [
                  { id: actualAgentId },
                  ...agentByPlace?.data?.users
                    ?.filter((x) => x.rol?.name !== CONSTANTS.SUPERADMIN)
                    ?.map((x) => ({ id: x.id })),
                ];
              }
            } catch (error) {
              console.error(error);
            }
          }
          agentFilters.isAgent = true;
          let fetchedConversationsAgent = [];
          if (
            selectedFilterType !== CONSTANTS.FILTER_ASSIGNED &&
            selectedFilters.agents?.length === 0
          ) {
            fetchedConversationsAgent = await getConversations(
              agentFilters,
              userId
            );
          }
          fetchedConversations = [
            ...fetchedConversations,
            ...fetchedConversationsAgent,
          ];
          fetchedConversations = uniqBy(fetchedConversations, "id");
          fetchedConversations = orderBy(
            fetchedConversations,
            [(x) => moment(x.lastMessage?.createdAt)],
            ["desc"]
          );
        } else {
          fetchedConversations = await getConversations(
            selectedFilters,
            userId
          );
        }
      } else {
        console.log("selectedFilters", selectedFilters);
        fetchedConversations = await getConversations(selectedFilters, userId);
        console.log("fetchedConversations", fetchedConversations);
      }

      let newConversations = [];
      if (!restart) {
        newConversations = [...conversations, ...fetchedConversations];
      } else newConversations = [...fetchedConversations];

      if (fetchedConversations?.length === 0) {
        dispatch({ type: THERE_IS_NO_MORE_CONVERSATIONS });
      } else dispatch({ type: THERE_IS_MORE_CONVERSATIONS });

      newConversations = uniqBy(newConversations, "id");
      console.log("newConversations", newConversations);
      console.log("selectedFilters", selectedFilters);
      console.log("conversationsFilter", conversationsFilter);
      // if(conversationsFilter ) {

      const newConversationsWithLastMessage = newConversations.filter(
        (c) => c.lastMessageId !== null
      );
      console.log(
        "newConversationsWithLastMessage",
        newConversationsWithLastMessage
      );

      dispatch({
        type: GET_CONVERSATIONS_SUCCESS,
        payload: {
          conversations:
            conversationsFilter?.length > 0
              ? newConversations
              : newConversationsWithLastMessage,
          source: CONSTANTS.CONVERSATIONS_SOURCE_REQUEST,
        },
      });
      return;
    } catch (error) {
      dispatch({ type: GET_CONVERSATIONS_ERROR, payload: { error } });
    }
  };

export const updateMessageStatus = (data) => (dispatch, getState) => {
  const statuses = {
    null: 0,
    sent: 0,
    delivered: 1,
    viewed: 2,
    deleted: 3,
    failed: 3,
    warning: 3,
  };

  const conversations = [...getState().conversations.data];
  const messages = [...getState().messages.data];

  if ((data.intents || 0) < 10) {
    let updateMessageSuccess = false;
    messages.forEach((message) => {
      if (
        message.id === data.id &&
        statuses[data.messageStatus] > (statuses[message.messageStatus] || 0)
      ) {
        dispatch(
          updateMessageStatusId(
            data.id,
            data.messageStatus,
            messages,
            data.errorCode,
            data.errorInfo
          )
        );
        updateMessageSuccess = true;
      }
    });

    if (!updateMessageSuccess) {
      if (data.intents) {
        data.intents = data.intents += 1;
      } else {
        data.intents = 1;
      }
      setTimeout(() => dispatch(updateMessageStatus(data)), 200);
    }
  }

  if ((data.conversationIntents || 0) < 10) {
    let updateConversationSuccess = false;
    conversations.forEach(async (conversation) => {
      //console.log('statuses[data.messageStatus] => ', data.messageStatus)
      //console.log('conversation.lastMessage?.messageStatus => ', conversation.lastMessage?.messageStatus)

      if (
        conversation?.lastMessage?.id === data.id &&
        statuses[data.messageStatus] >=
          (statuses[conversation.lastMessage?.messageStatus] || 0)
      ) {
        //console.log('statuses[data.messageStatus] => ', data.messageStatus)

        dispatch(
          updateLastMessage(
            data.clientId,
            data.id,
            data.messageStatus,
            data.errorCode,
            data.errorInfo,
            data.windowExpiration
          )
        );
        updateConversationSuccess = true;
      }
    });

    if (!updateConversationSuccess) {
      if (data.intents) {
        data.conversationIntents = data.conversationIntents += 1;
      } else {
        data.conversationIntents = 1;
      }
      setTimeout(() => dispatch(updateMessageStatus(data)), 200);
    }
  }
};

const isConversationInFilters =
  (conversation) => async (dispatch, getState) => {
    const selectedFilters = getState().filters.selected;
    const usersRef = getState().conversations.allUsersForFilter;
    const user = getState().user.data;

    console.log('SELECTED FILTERS => ', selectedFilters)

    console.log("filter 1");
    const selectedChannels = getState().spaces?.selected?.channels;
    if (user?.company?.name === CONSTANTS.VINTE_COMPANY_NAME) {
      console.log("Vinte");
      console.log(user?.rol?.name);
      if (user?.rol?.name !== CONSTANTS.AGENT_PERMISSION) {
        let isChannelsFilterPassed = undefined;
        console.log(user.spaces);
        console.log(user.spaces.length);
        console.log(user.spaces[0].name);
        if (
          user.spaces.length === 1 &&
          user.spaces[0].name === CONSTANTS.VINTE_DEFAULT_NAME
        ) {
          console.log("in");
          console.log(conversation?.client?.channel?.id);
          console.log(CONSTANTS.VINTE_DEFAULT_WHATSAPP);
          console.log(CONSTANTS.VINTE_DEFAULT_FACEBOOK);
          isChannelsFilterPassed = [
            CONSTANTS.VINTE_DEFAULT_WHATSAPP,
            CONSTANTS.VINTE_DEFAULT_FACEBOOK,
          ].includes(conversation?.client?.channel?.id);
          console.log("isChannelsFilterPassed", isChannelsFilterPassed);
          if (isChannelsFilterPassed === false) return false;
        } else {
          isChannelsFilterPassed = selectedChannels?.find(
            (x) => x.id === conversation?.client?.channel?.id
          );
        }
        if (isChannelsFilterPassed === undefined) {
          if (user?.rol?.name === CONSTANTS.ADMIN) {
            console.log("admin de vinte");
            let userInSpaceHasLead = false;
            let users = [];
            if (usersRef?.length === 0) {
              const users = await dispatch(getAllAgents());
              dispatch({ type: SET_USER_FILTERS, payload: users });
            } else {
              users = [...usersRef];
            }
            const usersInSameSpace = users
              .filter((x) => x.rol?.name !== CONSTANTS.SUPERADMIN)
              .filter((x) => {
                const isInSameSpace = intersectionWith(
                  user.spaces,
                  x.spaces,
                  (a, b) => a.id === b.id
                );
                if (isInSameSpace?.length > 0) return true;
              });
            console.log(usersInSameSpace);
            usersInSameSpace.forEach((x) => {
              if (x.id === conversation?.client?.agent?.id) {
                userInSpaceHasLead = true;
                console.log("admin asignado", x);
              }
            });
            console.log("userInSpaceHasLead", userInSpaceHasLead);
            if (userInSpaceHasLead === false) return false;
          } else {
            return false;
          }
        }
        console.log("filter 2");
      }
    } else {
      const isChannelsFilterPassed = selectedChannels?.find(
        (x) => x.id === conversation?.client?.channel?.id
      );
      if (isChannelsFilterPassed === undefined) return false;
      console.log("filter 2");
    }

    if (selectedFilters?.channels?.length > 0) {
      const filterPlatforms = [...selectedFilters.channels];
      if (filterPlatforms?.length > 0) {
        const isFilterPlatformPassed = filterPlatforms?.find(
          (x) => x?.platform?.name === conversation.client?.platform?.name
        );
        if (isFilterPlatformPassed === undefined) return false;
      }
    }
    console.log("filter 3");

    if (selectedFilters?.agents?.length > 0) {
      const filterAgents = [...selectedFilters.agents];
      if (filterAgents?.length > 0) {
        const isFilterAgentsPassed = filterAgents?.find(
          (x) => x.id === conversation.client?.agent?.id
        );
        if (isFilterAgentsPassed === undefined) return false;
      }
    }
    console.log("filter 4");

    if (selectedFilters?.tags?.length > 0) {
      const filterTags = [...selectedFilters.tags];
      if (filterTags?.length > 0) {
        const isFilterTagsPassed = intersectionWith(
          filterTags,
          conversation.client?.tags,
          (a, b) => a.id === b.id
        );
        if (isFilterTagsPassed?.length === 0) return false;
      }
    }
    console.log("filter 5");

    const answeredFilter = selectedFilters.noAnswered;
    console.log("Filter ====>", answeredFilter);
    console.log(conversation?.client);
    console.log("Filteranswered ====>", conversation?.client?.answered);
    if (conversation?.client?.answered === answeredFilter) return false;
    console.log("filter 6");
    if(conversation?.client?.lastMessage?.sender !== "CLIENT" && answeredFilter) return false;

    if (selectedFilters.isArchived === undefined) {
      if (conversation?.client?.isArchived) return false;
    } else if (!conversation?.client?.isArchived) return false;
    console.log("filter 7");

    if (selectedFilters.isArchivedBroadcast === undefined) {
      if (
        conversation?.client?.isBroadcastMessage &&
        conversation?.client?.plataform?.name === "SMS"
      )
        return false;
    } else if (
      !conversation?.client?.isBroadcastMessage &&
      conversation?.client?.plataform?.name === "SMS"
    )
      return false;
    console.log("filter 8");

    // if (selectedFilters.isConversationValuable === undefined) {
    //   if (conversation?.client?.isConversationValuable) return false;
    // } else if (!conversation?.client?.isConversationValuable) return false;
    console.log("filter 9", conversation?.client?.isConversationValuable);

    // const unreadFilter = selectedFilters.unreadMessages;
    // if (conversation?.client?.lastMessage?.seen === unreadFilter) return false;
    // console.log("filter 9");

    const assignedFilter = getState().filters.type;
    const userId = getState().user?.data?.id;
    if (assignedFilter === "assigned") {
      if (conversation?.client?.agent?.id !== userId) return false;
    }
    console.log("filter 10");

    const searchValue = `${getState()
      .conversations.searchFilter?.trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s/g, "")
      .toLowerCase()}`;

    if (searchValue) {
      if (!conversation?.client?.cleanedName?.includes(searchValue))
        return false;
    }
    console.log("filter 11");

    return true;
  };

export const updateConverations = (data) => async (dispatch, getState) => {
  const conversationsData = [...getState().conversations.data];
  const currentConversation = { ...getState().conversations.current };
  const userId = getState().user.data?.id;
  const rolName = getState().user.data.rol.name;
  const selectedSpaces = getState().spaces.selected;
  const channelIds = selectedSpaces?.channels?.map((c) => c.id);
  console.log('conversationsData', conversationsData)
  console.log('currentConversation', currentConversation)
  console.log('userId', userId)
  console.log('rolName', rolName)
  console.log('selectedSpaces', selectedSpaces)
  console.log('channelIds', channelIds)


  if (!channelIds.some(id => id === data?.client?.channel?.id)) {
    console.log('conversation not belongs to space')
    return;
  }

  if (rolName === CONSTANTS.GROUPADMIN) {
    console.log('is group admin!!!')

    if (data?.client?.groupAdminId !== userId) {
      console.log('Different group admin ID, returning...')
      return
    } 

    if (!data?.client?.groupAdminId && data?.client?.agent?.id !== userId) {
      console.log('it does not belong to user group or agentId')
      if (data?.type === 'newClient') {
        console.log('removing group admin convresation')
        dispatch(deleteConversationAction(data));
      }
      return;
    }


    // if (data?.client?.agent?.id === userId) {
    //   console.log('it does not belong to user group or agentId')
    //   return;
    // };
  }

  console.log("updateConverations =====>", data);
  const conversationPassFilters = await dispatch(isConversationInFilters(data));
  console.log("conversationPassFilters", conversationPassFilters);
  if (!conversationPassFilters) {
    dispatch(deleteConversationAction(data));
    return;
  }

  
  if (rolName === CONSTANTS.GROUPADMIN) {
    console.log('is group admin 2!!!')
    if ((data?.client?.groupAdminId === null && data?.client?.agent?.id !== userId) && data?.type === 'newClient') {
      console.log('it does not belong to user group or agentId, unassigning...')
      dispatch(deleteConversationAction(data));
      return;
    };
  }

  if (data.message?.senderId === userId) return;

  let newConversationsData = [...conversationsData];
  let conversationIndex;
  if (data.client.platform.name === "SMS") {
    console.log("IS NEW SMSM MESSAGEE!!!1");
    conversationIndex = newConversationsData.findIndex(
      (x) => data.client.senderId === x.senderId
    );
    console.log("conversationIndex ======> ", conversationIndex);
  } else {
    conversationIndex = newConversationsData.findIndex(
      (x) => data.client.id === x.id
    );
  }
  if (conversationIndex > 0) {
    const switchConversation = newConversationsData[conversationIndex];
    switchConversation.lastMessage = {
      ...data?.client?.lastMessage,
      time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
    };
    console.log('setting agent conversation one', data?.client.agent)
    if (data?.client?.agent?.id) {
      switchConversation.agent = { ...data?.client.agent };
    }
    if (data.client.id === currentConversation?.id) {
      dispatch(seenMessage(currentConversation));
    } else {
      if (data.client.lastMessage?.sender === CONSTANTS.CLIENT_SENDER) {
        switchConversation.unreadMessages += 1;
      } else {
        switchConversation.unreadMessages = 0;
      }
    }
    newConversationsData.splice(conversationIndex, 1);
    newConversationsData.unshift(switchConversation);
  } else if (conversationIndex === 0) {
    newConversationsData[conversationIndex].lastMessage = {
      ...data?.client?.lastMessage,
      time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
    };

    console.log('setting agent conversation two', data?.client.agent)

    if (data?.client?.agent?.id) {
      newConversationsData[conversationIndex].agent = { ...data?.client.agent };
    }

    if (data.client.id === currentConversation?.id) {
      dispatch(seenMessage(currentConversation));
    } else {
      if (data.client.lastMessage?.sender === CONSTANTS.CLIENT_SENDER) {
        newConversationsData[conversationIndex].unreadMessages += 1;
      } else {
        newConversationsData[conversationIndex].unreadMessages = 0;
      }
    }
  } else {
    newConversationsData = [
      { ...data.client, unreadMessages: 1 },
      ...newConversationsData,
    ];
    newConversationsData[0].lastMessage = {
      ...data?.client?.lastMessage,
      time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
    };

    console.log('setting agent conversation three', data?.client.agent)

    if (data?.client?.agent?.id) {
      newConversationsData[0].agent = { ...data?.client.agent };
    }
  }
  console.log("newConversationsData ============>", newConversationsData);
  dispatch({ type: SET_CONVERSATIONS, payload: newConversationsData });

  if (
    data.client.id === currentConversation?.id ||
    (data.client.platform.name === "SMS" &&
      data.client.senderId === currentConversation?.senderId)
  ) {
    let messages = [...getState().messages.data];
    data.client.lastMessage.client = data.client;
    if (data.client.lastMessage.sender === "CLIENT") {
      messages = [
        {
          ...data?.client?.lastMessage,
          time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
        },
        ...messages,
      ];
    } else {
      const foundMessageIndex = messages.findIndex(
        (x) => x.id === data?.client?.lastMessage?.id
      );
      if (foundMessageIndex === -1) {
        messages = [
          {
            ...data?.client?.lastMessage,
            time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
          },
          ...messages,
        ];
      } else {
        messages[foundMessageIndex] = {
          ...data?.client?.lastMessage,
          time: parseInt(`${data?.client?.lastMessage?.time}000`, 10),
        };
      }
    }
    const messagesFiltered = messages.filter((x) => !x.id.includes("fakeId"));
    const messagesUniq = uniqBy(messagesFiltered, "id");
    dispatch({ type: ADD_NEW_MESSAGES, payload: messagesUniq });
    console.log("ADD_NEW_MESSAGES =====> ", messages);
  }

  if (data?.botAssigments?.id) {
    console.log("NEW BOT ASSIGNMENT ===> ");
    if (userId === data?.botAssigments?.nextAgent) {
      const currentAssignments = getState().app.assignments;
      const indexAssignment = currentAssignments.findIndex(
        (as) => as.id === data?.botAssigments?.id
      );
      if (indexAssignment !== -1) currentAssignments.splice(indexAssignment, 1);
      dispatch(setAppStatus([...currentAssignments, data?.botAssigments]));
    }
  }
};

export const finishBotAssignment = (data) => async (dispatch, getState) => {
  console.log("FINISH BOT ASSIGNMENT ===> ");
  const currentAssignments = getState().app.assignments;

  const index = currentAssignments.findIndex(
    (as) => as?.id === data?.botConfig?.id && as?.clientId === data?.client?.id
  );

  if (index !== -1) currentAssignments.splice(index, 1);
  dispatch(setAppStatus([...currentAssignments]));
};

export const removeAssignation = (clientId) => async (dispatch, getState) => {
  console.log("NEW BOT unassign ===> ", clientId);

  const currentAssignments = [...getState().app.assignments];

  const indexAssignment = currentAssignments.findIndex(
    (as) => as?.clientId === clientId
  );
  currentAssignments.splice(indexAssignment, 1);

  await dispatch(setAppStatus(currentAssignments));
};

export const unassignClient = (data) => async (dispatch, getState) => {
  const userId = getState().user.data.id;
  const companyName = getState().user.data.company?.name;

  if (userId != data?.client?.agent?.id)
    await dispatch(removeAssignation(data?.client?.id));

  const userRol = getState().user.data.rol.name;
  if (userRol === CONSTANTS.SUPERADMIN || userRol === CONSTANTS.ADMIN) {
    if (
      companyName === CONSTANTS.VINTE_COMPANY_NAME &&
      userRol === CONSTANTS.ADMIN
    ) {
      // TODO: Review this case
      const conversationsData = [...getState().conversations.data];
      const newConversations = conversationsData.map((x) => {
        if (x.id === data.client?.id) {
          return {
            ...x,
            agent: {
              id: data.client?.agent?.id,
              name: data?.client?.agent?.name,
            },
          };
        }
        return { ...x };
      });
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });

      const selectedConversation = { ...getState().conversations.current };
      if (selectedConversation?.id === data?.client?.id) {
        const newConversation = {
          ...selectedConversation,
          agent: {
            id: data.client?.agent?.id,
            name: data?.client?.agent?.name,
          },
        };
        dispatch({ type: SET_CURRENT_CONVERSATION, payload: newConversation });
      }
    } else {
      const conversationsData = [...getState().conversations.data];
      const newConversations = conversationsData.map((x) => {
        if (x.id === data.client?.id) {
          return {
            ...x,
            agent: {
              id: data.client?.agent?.id,
              name: data?.client?.agent?.name,
            },
          };
        }
        return { ...x };
      });
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });

      const selectedConversation = { ...getState().conversations.current };
      if (selectedConversation?.id === data?.client?.id) {
        const newConversation = {
          ...selectedConversation,
          agent: {
            id: data.client?.agent?.id,
            name: data?.client?.agent?.name,
          },
        };
        dispatch({ type: SET_CURRENT_CONVERSATION, payload: newConversation });
      }
    }
  } else {
    const conversationsData = [...getState().conversations.data];
    const currentConversationId = getState().conversations.current?.id;
    const newConversationsData = conversationsData.filter(
      (x) => x.id !== data.client.id
    );
    dispatch({ type: SET_CONVERSATIONS, payload: newConversationsData });
    if (data.client.id === currentConversationId) {
      dispatch({ type: ADD_NEW_MESSAGES, payload: [] });
      dispatch({ type: CLEAR_SELECTED_CONVERSATION });
    }
  }
};

export const updateAssignTag = () => (dispatch, getState) => {
  const tags = getState().tags.data;
  const assigmentTag = tags?.find((x) => x.name === "Reasignada");
  if (assigmentTag?.id) {
    const conversation = getState().conversations?.current;
    const payload = conversation?.tags?.map((x) => x);
    payload.push(assigmentTag);
    dispatch({ type: UPDATE_REASSING_TAG, payload });
  }
};

export const markAsReadAction = (data) => (dispatch, getState) => {
  const conversationsData = getState().conversations.data;
  let update = false;
  const newConversations = conversationsData.map((x) => {
    if (x.id === data.clientId) {
      update = true;
      return { ...x, unreadMessages: 0 };
    }
    return { ...x };
  });
  if (update) {
    dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
  }
};

export const markAsConversationValuable = (data) => (dispatch, getState) => {
  const conversationsData = getState().conversations.data;
  let update = false;
  const newConversations = conversationsData.map((x) => {
    if (x.id === data?.client?.id) {
      update = true;
      return {
        ...x,
        isConversationValuable: data?.client?.isConversationValuable,
      };
    }
    return { ...x };
  });
  if (update) {
    const value = data?.client?.isConversationValuable;
    dispatch({
      type: UPDATE_VALUABLE_CLASSIFICATION,
      payload: { value, data: newConversations },
    });
  }
};

export const markAsUnReadAction = (data) => (dispatch, getState) => {
  const conversationsData = getState().conversations.data;
  let update = false;
  console.log("markAsUnReadAction", data);
  const newConversations = conversationsData.map((x) => {
    if (x.id === data.client?.id) {
      update = true;
      return { ...x, unreadMessages: 1 };
    }
    return { ...x };
  });
  if (update) {
    dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
  } else {
    newConversations.unshift({ ...data.client, unreadMessages: 1 });
    newConversations[0].lastMessage = {
      ...data.message,
      time: parseInt(`${data.message?.time}000`, 10),
    };
    dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
  }
};

export const setConversationsAction = (conversations) => ({
  type: SET_CONVERSATIONS,
  payload: conversations,
});

export const setCurrentWindowOpen = () => (dispatch, getState) => {
  dispatch({
    type: SET_CURRENT_WINDOW_OPEN,
    payload: {},
  });
};

export const setSearchFilterAction = (input) => ({
  type: SET_SEARCH_FILTER,
  payload: input,
});

export const setConversationAsSelectedAction =
  (conversation) => async (dispatch, getState) => {
    try {
      dispatch({ type: HANDLE_CLIENT_INFO, payload: true });
      dispatch(handleNewMessagesButtonAction(false));
      let isCurrentWindowOpen;
      const conversations = [...getState().conversations.data];
      const conversationAsSelected = conversations.map((x) => {
        if (x.id === conversation.id) {
          isCurrentWindowOpen = isDialogWindowOpen(
            conversation.whatsappStartedAt
          );
          return { ...x, selected: true, unreadMessages: 0, hasUpdates: false };
        }
        return { ...x, selected: false };
      });

      const lastSurveyInfo = await getLastSurveyInfo(conversation?.id);
      //console.log('lastSurveyInfo ===========> ', lastSurveyInfo)
      dispatch({
        type: SET_CONVERSATION_AS_SELECTED,
        payload: {
          data: conversationAsSelected,
          current: { ...conversation, lastSurveyInfo: lastSurveyInfo },
          isCurrentWindowOpen,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_CONVERSATIONS_ERROR, payload: { error } });
    }
  };

export const handleClientInfoAction =
  (status) => async (dispatch, getState) => {
    try {
      if (status) {
        const selectedConversationId = getState().conversations.current.id;
        const clientInfoData = await getClientInfo(selectedConversationId);
        dispatch({ type: UPDATE_CLIENT_INFO, payload: clientInfoData });
      }
      dispatch({ type: HANDLE_CLIENT_INFO, payload: status });
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const updateClientInfoAction =
  (clientInfo) => async (dispatch, getState) => {
    try {
      const clientUpdated = await updateClientInfo(clientInfo);
      if (clientUpdated) {
        const conversationData = [...getState().conversations.data];
        const index = conversationData.findIndex((x) => x.id === clientInfo.id);
        conversationData[index] = { ...conversationData[index], ...clientInfo };
        dispatch({ type: SET_CONVERSATIONS, payload: conversationData });
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

export const createClientInfoAction =
  (clientInfo, agentId, importContact, updateProgress) => async (_, getState) => {
    try {
      const companyId = getState().user.data.company.id;
      const createClient = await createClientFromPhone(
        clientInfo,
        agentId,
        importContact,
        updateProgress,
        companyId
      );

      return createClient;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
export const createClientInfoFromExcelAction =
  (clientInfo, agentId, importContact) => async () => {
    try {
      const createClient = await createClientFromPhone(
        clientInfo,
        agentId,
        importContact
      );
      return createClient;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const getPlatformApi = async (companyId) => {
  try {
    return await getPlatformApiFromPhone(companyId);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const updateClientAgent =
  (agentId, clientId, isGroupAdmin=false) => async (dispatch, getState) => {
    try {
      const data = { clientId, agentId, isGroupAdmin };
      const conversations = await getState().conversations.data;
      const reassignResponse = await assignAgent(data);
      let newSelectedConversation;
      const newConversations = map(conversations, (conversation) => {
        if (conversation.id === clientId) {
          conversation.agent = reassignResponse.assignedAgent;
          conversation.groupAdminId = reassignResponse?.groupAdmin;
          newSelectedConversation = conversation;
        }
        return conversation;
      });

      if (newSelectedConversation?.id) {
        const payload = {
          data: newConversations,
          current: newSelectedConversation,
        };
        dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const changeCategoryAction =
  (category, clientId) => async (dispatch, getState) => {
    try {
      const currentConversation = getState().conversations.current;
      const conversations = getState().conversations.data;
      if (currentConversation.id === clientId) {
        currentConversation.category = category;
      }
      const newConversations = map(conversations, (conversation) => {
        if (conversation.id === clientId) {
          conversation = currentConversation;
        }
        return conversation;
      });
      await changeCategory(clientId, category.id);
      const payload = { data: newConversations, current: currentConversation };
      dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const updateClientTags = (data) => async (dispatch, getState) => {
  try {
    const conversations = [...getState().conversations.data];
    const selectedConversation = { ...getState().conversations.current };
    const tags = getState().tags.data;
    let updateTags = false;
    const newConversations = conversations.map((x) => {
      if (x.id === data.id) {
        if (data.tags.connect.length > 0) {
          console.log("ADDING TAGS");
          console.log("data.tags.connect ====> ", data.tags.connect);

          updateTags = true;
          const newTags = data.tags.connect.map((tag) => {
            const selectedTag = tags.find((tagData) => tagData.id === tag.id);
            return selectedTag;
          });
          const tagsUniq = uniqBy([...x.tags, ...newTags], (v) => v.id);
          console.log("updatedTags ====> ", tagsUniq);

          return { ...x, tags: tagsUniq };
        }
        if (data.tags.disconnect.length > 0) {
          console.log("REMOVING TAGS");

          console.log("data.tags.disconnect ====> ", data.tags.disconnect);

          updateTags = true;
          const newTags = differenceWith(
            x.tags,
            data.tags.disconnect,
            (value, other) => value.id === other.id
          );

          console.log("updatedTags ====> ", newTags);

          return { ...x, tags: newTags };
        }
        return { ...x };
      }
      return { ...x };
    });
    if (selectedConversation.id === data.id) {
      if (data.tags.connect) {
        const newTags = data.tags.connect.map((tag) => {
          const selectedTag = tags.find((tagData) => tagData.id === tag.id);
          return selectedTag;
        });

        const tagsUniq = uniqBy(
          [...selectedConversation.tags, ...newTags],
          (v) => v.id
        );
        const payload = {
          data: newConversations,
          current: {
            ...selectedConversation,
            tags: tagsUniq,
          },
        };
        dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
      } else if (data.tags.disconnect) {
        const newTags = differenceWith(
          selectedConversation.tags,
          data.tags.disconnect,
          (value, other) => value.id === other.id
        );
        console.log(newTags);
        const payload = {
          data: newConversations,
          current: { ...selectedConversation, tags: [...newTags] },
        };
        dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
      }
    } else if (updateTags) {
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
    }
  } catch (error) {
    console.error(error);
  }
};

export const clientTagAction =
  (clientId, tag, add) => async (dispatch, getState) => {
    try {
      if (add) {
        await connectTag(clientId, tag.id);
      } else {
        await disconnectTag(clientId, tag.id);
      }
      const conversations = [...getState().conversations.data];
      const selectedConversation = { ...getState().conversations.current };
      const newConversations = conversations.map((storeConversation) => {
        if (storeConversation.id === clientId) {
          const newStoreConversation = { ...storeConversation };
          if (add === true) newStoreConversation.tags.push(tag);
          else {
            const newTags = storeConversation.tags.filter(
              (x) => x.id !== tag.id
            );
            newStoreConversation.tags = newTags;
          }
          selectedConversation.tags = [...newStoreConversation.tags];
          return newStoreConversation;
        }
        return storeConversation;
      });
      const payload = {
        data: newConversations,
        current: { ...selectedConversation },
      };
      dispatch({ type: SET_CONVERSATION_AS_SELECTED, payload });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const sendToCrmAction = (clientId) => async () => {
  try {
    const clientUpdated = await sendToCrm(clientId);
    return clientUpdated;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getClientNameFromNotification = (data) => (dispatch, getState) => {
  try {
    const { id } = getState().conversations.current;
    if (id === data.id) {
      dispatch({
        type: UPDATE_USER_NAME,
        payload: {
          nameFromChannel: data.nameFromChannel,
          cleanedName: data.cleanedName,
        },
      });
    }
    const conversations = [...getState().conversations.data];
    let updateConversationList = false;
    const newConversations = conversations.map((c) => {
      if (c.id === data.id) {
        updateConversationList = true;
        return {
          ...c,
          nameFromChannel: data.nameFromChannel,
          cleanedName: data.cleanedName,
        };
      }
      return { ...c };
    });
    if (updateConversationList) {
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateClientUsername =
  (newUsername, phone) => async (dispatch, getState) => {
    try {
      const { id } = getState().conversations.current;
      const cleanedName = cleanString(newUsername + phone);
      const clientUpdated = await updateClientUsernameMutation(
        id,
        newUsername,
        cleanedName
      );

      const conversations = [...getState().conversations.data];
      const newConversations = conversations.map((c) => {
        if (c.id === id) {
          return { ...c, nameFromChannel: newUsername, cleanedName };
        }
        return { ...c };
      });
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });

      return clientUpdated;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

export const updateClientPhone = (newPhone) => async (dispatch, getState) => {
  try {
    const { id, key, senderId, cleanedName, company, channel } =
      getState().conversations.current;
    const currentPhone = senderId?.split("@")?.[0];

    if (newPhone.startsWith("52")) {
      newPhone = `${newPhone.substring(0, 2)}1${newPhone.substring(
        2,
        newPhone.length
      )}`;
    } else if (newPhone.startsWith("54")) {
      newPhone = `${newPhone.substring(0, 2)}9${newPhone.substring(
        2,
        newPhone.length
      )}`;
    }

    const existedPhones = await checkExistedPhone(
      company.id,
      channel.id,
      newPhone
    );

    console.log("existedPhones READYY=======>", existedPhones);

    if (existedPhones.length === 0) {
      const updatedkey = key.replace(currentPhone, newPhone);
      const updatedSenderId = senderId.replace(currentPhone, newPhone);
      const updatedCleanedName = cleanedName.replace(currentPhone, newPhone);

      const clientUpdated = await updateClientPhoneMutation(
        id,
        updatedCleanedName,
        updatedkey,
        updatedSenderId
      );

      dispatch({
        type: UPDATE_USER_PHONE,
        payload: {
          cleanedName: updatedCleanedName,
          key: updatedkey,
          senderId: updatedSenderId,
        },
      });

      const conversations = [...getState().conversations.data];
      const newConversations = conversations.map((c) => {
        if (c.id === id) {
          return {
            ...c,
            cleanedName: updatedCleanedName,
            key: updatedkey,
            senderId: updatedSenderId,
          };
        }
        return { ...c };
      });
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });

      return clientUpdated;
    }
    return { error: true };
  } catch (error) {
    console.error("error at updateClientPhone ==> ", error);
    return error;
  }
};

export const readBroadcastChats = (isNewBroadcastChat) => (dispatch) => {
  // const payload = { isNewBroadcastChat };
  dispatch({
    type: UPDATE_UNREAD_BROADCAST_CHAT,
    payload: { isNewBroadcastChat },
  });
};

export const setBroadcastChat = (count) => async (dispatch) => {
  const payload = { unreadBroadcastChat: count };
  dispatch({ type: GET_BROADCAST_CONVERSATIONS, payload });
};

export const getBroadCastConversationsAction =
  () => async (dispatch, getState) => {
    try {
      const companyId = getState().user.data.company.id;
      const response = await getBroadCastConversationsQuery(companyId);
      console.log(response.clients.length);
      dispatch(setBroadcastChat(response.clients.length));
      return;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

export const updateValuableByUserAction =
  (value) => async (dispatch, getState) => {
    try {
      const { id } = getState().conversations.current;
      const clientUpdated = await updateValuableByUserMutation(id, value);
      const conversations = [...getState().conversations.data];
      const index = conversations.findIndex((x) => x.id === id);
      conversations[index] = {
        ...conversations[index],
        isConversationValuable: value,
      };
      dispatch({
        type: UPDATE_VALUABLE_CLASSIFICATION,
        payload: { value, data: conversations },
      });
      return clientUpdated;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

export const clearConversationsAction = () => ({
  type: CLEAR_CONVERSATIONS,
  payload: null,
});

export const clearDataAction = () => ({
  type: CLEAR_DATA,
  payload: null,
});

export const updateHasFollowUpMessage =
  (hasFollowUpMessage) => async (dispatch, getState) => {
    const { id } = getState().conversations.current;
    const conversations = [...getState().conversations.data];
    const index = conversations.findIndex((x) => x.id === id);
    conversations[index] = {
      ...conversations[index],
      hasFollowUpMessage: hasFollowUpMessage,
    };

    dispatch({
      type: UPDATE_HASFOLLOWUPMESSAGE,
      payload: { hasFollowUpMessage, data: conversations },
    });
  };

export const conversationDeletRequest = (clientId) => async () => {
  const data = { clientId };
  const deleteConversationQ = await deleteConversation(data);
  return !!deleteConversationQ;
};
export const conversationArchived = (clientId, isArchived) => async (_) => {
  const archivedConversationQ = await setIsArchived(clientId, isArchived);
  return !!archivedConversationQ;
};

export const deleteConversationAction =
  (data) => async (dispatch, getState) => {
    console.log("DATA: ", data);
    const conversations = [...getState().conversations.data];
    const current = { ...getState().conversations.current };
    console.log("CONVERSATIONS: ", conversations);
    console.log("CURRENT: ", current);
    const newConversations = conversations.filter(
      (x) => x.id !== data?.client?.id
    );
    if (data?.client?.id === current?.id) {
      dispatch({ type: CLEAR_SELECTED_CONVERSATION });
    }
    dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
  };

export const archivedConversationAction =
  (data) => async (dispatch, getState) => {
    const archived = getState().filters.type;

    if (archived === "Archived") {
      if (data.client.isArchived) {
        if (!data.client.newMessage) {
          const archivedConversations = [...getState().conversations.data];
          archivedConversations.unshift(data.client);
          dispatch({
            type: SET_CONVERSATIONS,
            payload: archivedConversations,
          });
        } else {
          data.type = "newMessage";
          console.log("NEMESSAGE: ", data);
          dispatch(updateConverations(data));
        }
      } else {
        dispatch(deleteConversationAction(data));
      }
    } else if (data.client.isArchived) {
      dispatch(deleteConversationAction(data));
    } else {
      data.type = "newMessage";
      console.log("NEMESSAGE: ", data);
      dispatch(updateConverations(data));
    }
  };

export const archivedBroadConversationAction =
  (data) => async (dispatch, getState) => {
    const archived = getState().filters.type;
    const { unreadBroadcastChat } = getState().conversations;

    if (data.client.platform.name === "SMS") {
      console.log("NEW SMS BROSSSS!!!!");
      dispatch(updateConverations(data));
    } else {
      if (archived === "ArchivedBroadcast") {
        console.log("LOG 11111111111111111111111111=>");

        if (data.client.isBroadcastMessage) {
          if (!data.client.newMessage) {
            const archivedConversations = [...getState().conversations.data];
            archivedConversations.unshift(data.client);
            dispatch({
              type: SET_CONVERSATIONS,
              payload: archivedConversations,
            });
          } else {
            data.type = "newMessage";
            console.log("NEMESSAGEBroad: ", data);
            dispatch(updateConverations(data));
          }
        } else {
          dispatch(deleteConversationAction(data));
        }
        dispatch({
          type: GET_BROADCAST_CONVERSATIONS,
          payload: { unreadBroadcastChat: 0 },
        });
      } else if (data.client.isBroadcastMessage) {
        console.log("LOG 22222222222222222222222222 =>");
        dispatch(deleteConversationAction(data));
        // const broadcast = await getBroadCastConversationsQuery(
        //   data.client.company.id
        // );
        dispatch({
          type: GET_BROADCAST_CONVERSATIONS,
          payload: { unreadBroadcastChat: unreadBroadcastChat + 1 },
        });
      } else {
        console.log("LOG 333333333333333 =>");

        dispatch({
          type: GET_BROADCAST_CONVERSATIONS,
          payload: { unreadBroadcastChat: unreadBroadcastChat - 1 },
        });
      }
    }
  };

export const resetConversation = () => (dispatch) => {
  dispatch({ type: RESET_CONVERSATION_CURRENT });
};

export const setLastSurveyInfoDate = () => (dispatch) => {
  dispatch({ type: SET_CURRENT_LAST_SURVEY_INFO });
};

export const setConversationClosed = (data) => (dispatch, getState) => {
  const conversationsData = [...getState().conversations.data];
  const currentConversation = { ...getState().conversations.current };
  const conversationIndex = conversationsData.findIndex(
    (x) => data.client.id === x.id
  );

  const newConversationsData = [...conversationsData];

  if (conversationIndex > 0) {
    newConversationsData[conversationIndex].isClosed = data.client.isClosed;
    dispatch({ type: SET_CONVERSATIONS, payload: newConversationsData });
  }

  console.log("current cons => ", currentConversation);

  if (currentConversation && currentConversation?.id === data.client.id) {
    console.log("ENTRA CHUDI");
    const newCurrentConversation = {
      ...currentConversation,
      isClosed: data.client.isClosed,
    };
    dispatch({
      type: SET_CURRENT_CONVERSATION,
      payload: newCurrentConversation,
    });
  }
};

const getClientInfoByClients = async (clients) => {
  let clientInfoByClient = [];
  try {
    const getClientInformation = async (clientId) => {
      const clientInfo = [];
      const data = await getClientInformationUtil(clientId);
      // console.log("data", data);
      // console.log("clientInfoes", data.clientInfoes);
      // // const info = data?.clientInfo;
      for await (const info of data?.clientInfoes) {
        // console.log("info", info);

        clientInfo.push({
          name: info?.fieldName?.name,
          value: info?.value,
        });
      }
      // console.log("clientInfo", clientInfo);
      return clientInfo;
    };

    const clientInfoes = await Promise.all(
      clients.map(async (client) => {
        const info = await getClientInformation(client?.id);
        return { id: client?.id, info };
      })
    );

    // console.log("clientInfoesN", clientInfoes);

    clientInfoByClient = groupBy(clientInfoes, (y) => y.id);
    console.log("clientInfo", clientInfoByClient);
    return clientInfoByClient;
  } catch (error) {
    console.log(error);
  }
};

export const searchClientReportAction =
  (filterContactsBy, initialDate, endDate, filters, skip, rol, agentId, keyword, tagsIds) =>
  async (_, getState) => {
    try {
      const companyId = getState().user.data.company.id;
      const companyName = getState().user.data.company.name;
      const selectedSpaces = getState().spaces.selected;
      //const rol = getState().user.data.rol.name;
      const userId = getState().user.data.id;

      const channelIds = selectedSpaces?.channels?.map((x) => x.id);
      console.log("channelIds", channelIds);



      const response = await API.post("/conversations_by_filter", {
        query: {
          filterContactsBy,
          keyword: cleanString(keyword) === '' ? null : keyword,
          companyId,
          createdAtFrom: initialDate,
          createdAtTo: endDate,
          skip: skip ?? 0,
          agentId: (rol === CONSTANTS.ADMIN || rol === CONSTANTS.SUPERADMIN) ? (agentId === 'Todos' ? null : agentId) : userId,
          custom: true,
          first: 50,
          tagsIds
        }
      });

      const clients = response?.data ?? [];

      // const clients = await getClientReport(
      //   companyId,
      //   companyName,
      //   initialDate,
      //   endDate,
      //   channelIds,
      //   filters,
      //   skip,
      //   rol,
      //   agentId
      // );
      console.log("clients", clients);
      let clientInfoByClient = [];
      if (companyName !== CONSTANTS.VINTE_COMPANY_NAME) {
        clientInfoByClient = await getClientInfoByClients(clients);
      }

      return clients.map((client) => {
        // console.log("client: ", client);
        const c = {
          id: client?.id,
          nameFromChannel: client?.nameFromChannel.split(" ")[0],
          lastName: client?.nameFromChannel.split(" ").slice(1).join(" "),
          agent: client?.agent?.name || "",
          tags:
            client?.tags?.length > 0
              ? client?.tags?.reduce(
                  (pV, cV, cI) => pV + (cI > 0 ? ", " : "") + cV.name,
                  ""
                )
              : "",
          plataform: client?.channel?.platform?.name || "",
          channel: client?.channel?.name || "",
          number: client?.senderId?.replace("@c.us", "") || "",
          createdAt: moment(client?.createdAt).format("DD-MM-YYYY HH:mm") || "",
          lastUpdate:
            moment(client?.lastUpdate)?.format("DD-MM-YYYY HH:mm") || "",
          clientInfo: clientInfoByClient[client?.id]
            ? clientInfoByClient[client?.id][0].info
            : [],
        };
        if (client.name) {
          c.name = client.name;
        }
        if (client.correoElectronico) {
          c.email = client.correoElectronico;
        }
        if (client.plaza) {
          c.plaza = client.plaza;
        }
        if (client.number) {
          c.number =
            client.number?.replace("@c.us", "") ||
            client?.senderId?.replace("@c.us", "") ||
            "";
        }
        return c;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
export const exportClientReportAction =
  (filterContactsBy, initialDate, endDate, keyword, tagsIDS, agentID) => async (_, getState) => {
    try {
      const companyId = getState().user.data.company.id;
      const companyName = getState().user.data.company.name;
      const selectedSpaces = getState().spaces.selected;
      const channelIds = selectedSpaces?.channels?.filter(x => x?.isActive === true).map((x) => x.id);
      console.log("channelIds", channelIds);
      console.log('KEYWORD ====>', keyword)
      const clients = await getClientReportToExport(
        filterContactsBy,
        companyId,
        companyName,
        keyword ? null: initialDate,
        keyword ? null: endDate,
        channelIds,
        keyword ? cleanString(keyword) : null,
        tagsIDS,
        agentID
      );
      console.log("clients", clients);
      let clientInfoByClient = [];
      if (companyName !== CONSTANTS.VINTE_COMPANY_NAME) {
        // clientInfoByClient = await getClientInfoByClients(clients);
        clientInfoByClient = [];
      }

      return clients.map((client) => {
        // console.log("client: ", client);
        const c = {
          id: client?.id,
          nameFromChannel: client?.nameFromChannel.split(" ")[0],
          lastName: client?.nameFromChannel.split(" ").slice(1).join(" "),
          agent: client?.agent?.name || "",
          tags:
            client?.tags?.length > 0
              ? client?.tags?.reduce(
                  (pV, cV, cI) => pV + (cI > 0 ? ", " : "") + cV.name,
                  ""
                )
              : "",
          plataform: client?.channel?.platform?.name || "",
          channel: client?.channel?.name || "",
          number: client?.senderId?.replace("@c.us", "") || "",
          createdAt: moment(client?.createdAt).format("DD-MM-YYYY HH:mm") || "",
          lastUpdate:
            moment(client?.lastUpdate)?.format("DD-MM-YYYY HH:mm") || "",
          // clientInfo: clientInfoByClient[client?.id]
          //   ? clientInfoByClient[client?.id][0].info
          //   : [],
        };
        if (client.name) {
          c.name = client.name;
        }
        if (client.correoElectronico) {
          c.email = client.correoElectronico;
        }
        if (client.plaza) {
          c.plaza = client.plaza;
        }
        if (client.number) {
          c.number =
            client.number?.replace("@c.us", "") ||
            client?.senderId?.replace("@c.us", "") ||
            "";
        }
        return c;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const searchClientByKeywordAction =
  (keyword, selectedAgentId, skip) => async (_, getState) => {
    try {
      console.log("nOentrakeyword", keyword);
      console.log("keyword", keyword);
      const cleanedName = keyword
        ?.trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s/g, "")
        .toLowerCase();

      const companyId = getState().user.data.company.id;
      const companyName = getState().user.data.company.name;
      const selectedSpaces = getState().spaces.selected;
      const userId = getState().user.data.id;
      const rolName = getState().user.data.rol.name;
      const isVinte = companyName === CONSTANTS.VINTE_COMPANY_NAME;
      const isAgent =
        rolName !== CONSTANTS.SUPERADMIN 
        && rolName !== CONSTANTS.ADMIN 
        && rolName !== CONSTANTS.GROUPADMIN;

      const userSpaces = getState().user.data.spaces;
      const agents = getState().agents.data;

      const { channels } = selectedSpaces;

      let selectedPagesIds = [];
      console.log("keyword", cleanedName);

      if (
        userSpaces.length === 1 &&
        userSpaces[0]?.name === CONSTANTS.VINTE_DEFAULT_NAME
      ) {
        selectedPagesIds = [
          CONSTANTS.VINTE_DEFAULT_WHATSAPP,
          CONSTANTS.VINTE_DEFAULT_FACEBOOK,
        ];
      } else {
        selectedPagesIds = channels
          .filter((c) => c?.platform?.name !== CONSTANTS.SMS_PLATFORM && c?.isActive)
          .map((e) => e.id);
      }
      const selectedPagesIdsCleaned = selectedPagesIds.filter((e) => e);
      const selectedSpacesIds = selectedPagesIdsCleaned;
      let agentIdIn = [];
      if (agents?.length > 0) {
        const isUnassignedAgentSelected = agents.some(
          (x) => x.id === CONSTANTS.UNASSIGNED_AGENT_ID
        );
        let agentsId = agents
          .filter((x) => x.id !== CONSTANTS.UNASSIGNED_AGENT_ID)
          .map((x) => x.id);
        if (isUnassignedAgentSelected) agentsId = [...agentsId, ""];
        agentIdIn = agentsId;
      }

      // let channelsNames;
      // if (channels?.length > 0) {
      //   if (!isVinte || (isVinte && !isAgent)) {
      //     const getChannelsNames = map(channels, (channel) => channel.name);
      //     channelsNames = [...getChannelsNames];
      //   }
      // }
      //console.log("getConversations channelsNames => ", channelsNames);
      
      let channelsIds;
      if (selectedSpacesIds?.length > 0) {
        if (!isVinte) channelsIds = selectedSpacesIds;
        if (isVinte && !isAgent) channelsIds = selectedSpacesIds;
        if (!isVinte || (isVinte && !isAgent)) channelsIds = selectedSpacesIds;
      }
      console.log("getConversations channelsIds => ", channelsIds);
      console.log("keyword channelsIds => ", keyword);
      const graphQuerySearchFilter = {
        companyId,
        keyword: cleanedName,
        channelsIds,
        //channelsNames,
        ...(isAgent ? { agentId: userId } : {}),
        ...(selectedAgentId && { agentId: selectedAgentId === 'Todos' ? null : selectedAgentId}),
        ...(rolName === CONSTANTS.GROUPADMIN ? { groupAdminId: userId } : {}),
      };
      console.log("graphQuerySearchFilter", graphQuerySearchFilter);
      // const data = await client.query({
      //   query: ApolloConversations.GET_CONVERSATIONS_BY_SEARCHING_KEYWORD(
      //     graphQuerySearchFilter
      //   ),
      //   variables: graphQuerySearchFilter,
      // });
      const response = await API.post("/conversations_by_filter", {
        query: {
          ...graphQuerySearchFilter,
          skip: skip ?? 0,
          custom: true,
          first: 100
        }
      });

      console.log("Search Contac data => ", response?.data);
      return response?.data ?? [];
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  };

export const getConversationsPanel =
  ({
    initialDate,
    finalDate,
    noAnswered,
    archived,
    unread,
    whatsappChannel,
    messengerChannel,
    agents,
    broadcast,
    tags,
    isConversationValuable,
  }) =>
  async (dispatch) => {
    console.log("answered", noAnswered);
    console.log("isArchived", archived);
    console.log("unread", unread);
    console.log("whatsappChannel", whatsappChannel);
    console.log("messengerChannel", messengerChannel);
    console.log("agents", agents);
    console.log("broadcast", broadcast);
    console.log("tags", tags);
    console.log("isConversationValuable", isConversationValuable);

    const filters = {
      noAnswered,
      isArchived: archived,
      whatsappChannel,
      messengerChannel,
      agents,
      isArchivedBroadcast: broadcast,
      tags,
      isConversationValuable,
    };

    const fetchedConversations = await dispatch(
      searchClientReportAction(initialDate, finalDate, filters)
    );

    console.log("fetchedConversations", fetchedConversations);
    return fetchedConversations;
  };
  
  
export const handleGroupAdmin = (data) => async (dispatch, getState) => {
  console.log('handleGroupAdmin +++++++++++++++++++')

  const userId = getState().user.data?.id;
  const rolName = getState().user.data.rol.name;

  if (rolName === CONSTANTS.GROUPADMIN) {
    console.log('is group admin rol')
    const conversations = [...getState().conversations.data];

    if (data.userId !== userId) {
      console.log('different IDs')
      const current = { ...getState().conversations.current };
      const newConversations = conversations.filter(
        (x) => x.id !== data?.client?.id
      );
      if (data?.client?.id === current?.id) {
        dispatch({ type: CLEAR_SELECTED_CONVERSATION });
      }
      dispatch({ type: SET_CONVERSATIONS, payload: newConversations });
    } else {
      console.log('same IDs')
      const index = 
        conversations.findIndex(conversation => conversation?.id === data?.client?.id)
      if (index === -1) {
        console.log('there is no conversation', data?.client?.id)

        dispatch(updateConverations(data));
      }
    }
  }
};
