import * as _ from 'lodash';

import ApolloRoles from '../apollo/apolloRoles';
import client from '../apollo/apolloClient';

const buildPermissions = (permissions) => {
  let newPermissions = permissions;
  newPermissions = _.map(permissions, (permission) => ({ id: permission.id }));
  return newPermissions;
};

export const getPermissions = async () => {
  try {
    const response = await client.query({
      query: ApolloRoles.GET_ALL_PERMISSIONS,
      variables: { name: 'empty' },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createRole = async (role, companyId, key) => {
  try {
    const permissions = buildPermissions(role.permissions);
    const response = await client.mutate({
      mutation: ApolloRoles.CREATE_ROLE,
      variables: { name: role.name, companyId, permissions, key },
    });
    return response.data.createRol;
  } catch (error) {
    if (error.message.includes('Details: Field name = key')) throw new Error('ROLE_WITH_SAME_NAME_ERROR');
    if (error.message.includes('A ROL ALREDY EXISTS WITH SAME PERMISSIONS')) throw new Error('ROLE_ALREADY_EXISTS');
    throw new Error('ERROR_CREATING_ERROR');
  }
};

export const updateRole = async (role) => {
  try {
    const permissions = buildPermissions(role.permissions);
    const response = await client.mutate({
      mutation: ApolloRoles.UPDATE_ROLE,
      variables: { id: role.id, name: role.name, permissions, key: role.key },
    });
    return response.data.updateRol;
  } catch (error) {
    if (error.message.includes('Details: Field name = key')) throw new Error('ROLE_WITH_SAME_NAME_ERROR');
    if (error.message.includes('A ROL ALREDY EXISTS WITH SAME PERMISSIONS')) throw new Error('ROLE_ALREADY_EXISTS');
    throw new Error('ERROR_UPDATING_ROLE');
  }
};

export const validateRoleUsers = async (role) => {
  try {
    const response = await client.query({
      query: ApolloRoles.VALIDATE_TO_DELETE_ROLE,
      variables: { roleId: role.id },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findRoles = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloRoles.GET_ROLES_BY_COMPANY_ID,
      variables: { companyId },
    });
    return response.data.rols;
  } catch (error) {
    return error;
  }
};

export const findRoleByCompany = async (name, companyId) => {
  try {
    const response = await client.query({
      query: ApolloRoles.FIND_ROLE_BY_NAME,
      variables: { name, companyId },
    });
    return response.data.rols;
  } catch (error) {
    return error;
  }
};

export const removeRole = async (roleId) => {
  try {
    const response = await client.mutate({
      mutation: ApolloRoles.DELETE_ROLE,
      variables: { roleId },
    });
    return response.data.deleteRol;
  } catch (error) {
    return error;
  }
};

export const findEqualRoles = async (role, companyId) => {
  try {
    const response = await client.query({
      query: ApolloRoles.ROLES_WITH_SAME_PERMISSIONS,
      variables: { permissions: role.permissions, companyId },
    });
    return response.data.rols;
  } catch (error) {
    throw error;
  }
};

export const findPermissionByName = async (names) => {
  try {
    const { data } = await client.query({
      query: ApolloRoles.GET_PERMISSIONS_BY_NAME,
      variables: { names },
    });
    return data.permissions;
  } catch (error) {
    throw new Error('ERROR_GETTING_PERMISSIONS');
  }
};
