import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import tagsImage from "../../../assets/svg/tagsImage.svg";
import excelImage from "../../../assets/svg/ExcelFileImage.svg";
import audinceImage from "../../../assets/svg/AudinceImage.svg";
import BroadcastTypeCard from "./BroadcastTypeCard/BroadcastTypeCard.component";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import {
  BackButton,
  CardContainer,
} from "./BroadcastTypeCard/BroadcastTypeCard";

const BroadcastType = () => {
  const history = useHistory();
  const channelId = useSelector((state) => state.campaign.create.channelId);
  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);
  return (
    <CardContainer>
      <BroadcastTypeCard
        title={"Archivo Excel"}
        subtitle={"Sube un archivo Excel con tus contactos"}
        imagen={excelImage}
        nextPath=""
        disabled
        handleCreateTemplateButton={() => {}}
      />
      <BroadcastTypeCard
        title={"Audiencia"}
        subtitle={
          "Selecciona una audiencia o crea una seleccionando a tus contactos"
        }
        imagen={audinceImage}
        nextPath=""
        disabled
        handleCreateTemplateButton={() => {}}
      />

      <BroadcastTypeCard
        title={"Etiqueta(s)"}
        subtitle={"Selecciona a tus contactos por una o varias etiquetas"}
        imagen={tagsImage}
        nextPath=""
        handleCreateTemplateButton={() => {
          history.push("/broadcast/tags");
        }}
      />
      <BackButton
        onClick={() => {
          history.push("/broadcast/table");
        }}
      >
        <BackButtonIconContainer>
          <BackButtonIcon src={backButtonIcon} />
        </BackButtonIconContainer>
        <BackButtonText>Regresar</BackButtonText>
      </BackButton>
    </CardContainer>
  );
};

export default BroadcastType;
