import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "../../../components/datepicker/DatePicker.component";
import { getLabelMessage } from "../../../const/labelService";
import Button from "../../../components/shared/components/button/Button.style";
import { setSendAtAction } from "../../../redux/campaignDuck";
import {
  BotInfo,
  ButtonContainer,
} from "../../settings/autoresponses/AutoResponses.style";
import { NewWhatsappTitle } from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import { BroadcastAppCard } from "./BroadcastView.styles";
import TimePicker from "../../../components/timePicker/TimePicker.component";
import { getCurrentDateTimeStrings } from "../../../tools/date-utils";
import { useEffect } from "react";

const BroadcastSelectDate = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const channelId = useSelector((state) => state.campaign.create.channelId);
  const whatsappTemplate = useSelector((state) => state.campaign?.selected);
  const templateFileUrl = whatsappTemplate?.components
    ? whatsappTemplate?.components?.[0]?.exampleHeader?.header_handle?.[0]
    : "";
  const { date: currentDate, time: currentTime } = getCurrentDateTimeStrings();
  const [date, setDate] = useState(currentDate);
  const [time, setTime] = useState(currentTime);

  const labels = [
    "broadcastSelectDateTitle",
    "variableInstructionsMessagePreview",
    "broadcastName",
    "whatsappTemplateName",
  ];
  const lbls = getLabelMessage(labels);

  const handleActionButton = () => {
    const fullDate = new Date(`${date}T${time}`).toISOString();
    dispatch(setSendAtAction(fullDate));
    history.push("/broadcast/preview");
  };

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const handleTime = (e) => {
    setTime(e.target.value);
  };

  useEffect(() => {
    console.log("channelId: ", channelId);
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);

  return (
    <BroadcastAppCard>
      <BotInfo newTemplate style={{ flexGrow: 1 }}>
        <NewWhatsappTitle>{lbls.broadcastSelectDateTitle}</NewWhatsappTitle>
        <div
          style={{ position: "relative", marginTop: "46px", display: "flex" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              gap: "15px",
            }}
          >
            <DatePicker
              label="Fecha"
              value={date}
              onChange={handleDate}
              min={currentDate}
            />
            <TimePicker label="Hora" value={time} onChange={handleTime} />
          </div>
        </div>
      </BotInfo>
      <BackButton
        style={{
          position: "relative",
          bottom: "0px",
          left: "0px",
          marginTop: "20px",
        }}
        templateAction
        onClick={() => {
          if (templateFileUrl !== '') history.push("/broadcast/selectMultimedia");
          else history.push("/broadcast/selectTemplate");
        }}
      >
        <BackButtonIconContainer>
          <BackButtonIcon src={backButtonIcon} />
        </BackButtonIconContainer>
        <BackButtonText>Regresar</BackButtonText>
      </BackButton>

      <ButtonContainer newTemplate templateMedia={true}>
        <Button createTemplate color="primary" onClick={handleActionButton}>
          Continuar
        </Button>
      </ButtonContainer>
    </BroadcastAppCard>
  );
};

export default BroadcastSelectDate;
