import { groupBy } from "lodash";
import getReports, { deleteReport } from "../utils/getReports";

const REPORTER_INITIAL_STATE = {
  data: [],
  fetched: false,
  fetching: false,
  current: {},
  index: null,
  editReporterSuccess: false
};

// constants
const SET_REPORTERS = "SET_REPORTERS";
const GET_REPORTERS = "GET_REPORTERS";
const GET_REPORTERS_SUCCESS = "GET_REPORTERS_SUCCESS";
const GET_REPORTERS_ERROR = "GET_REPORTERS_ERROR";
const SET_REPORTER_AS_SELECTED = "SET_REPORTER_AS_SELECTED";
const UNSELECT_REPORTER = "UNSELECT_REPORTER";
const DELETE_REPORTER = "DELETE_REPORTER";
const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
const CLEAR_EDIT_REPORTER_SUCCESS_ACTION = "CLEAR_EDIT_REPORTER_SUCCESS_ACTION";
const CLEAR_REPORTER = "CLEAR_REPORTER";
const CLEAR_DATA = "CLEAR_DATA";

// reducer
export default function reporterReducer(state = { ...REPORTER_INITIAL_STATE }, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_REPORTERS:
      return payload;
    case GET_REPORTERS:
      return { ...state, fetching: true };
    case GET_REPORTERS_SUCCESS:
      return { ...state, fetching: false, fetched: true, data: payload };
    case GET_REPORTERS_ERROR:
      return { ...state, fetching: false, fetched: true, error: payload };
    case SET_REPORTER_AS_SELECTED:
      return { ...state, current: payload.selectedReporter, index: payload.index };
    case UNSELECT_REPORTER:
      return { ...state, current: {}, index: null };
    case DELETE_REPORTER:
      return { ...state, data: payload.data, current: {}, index: null, fetching: false };
    case EDIT_ADMIN_SUCCESS:
      return { ...state, editAdminSuccess: true };
    case CLEAR_DATA:
      return { ...REPORTER_INITIAL_STATE };
    default:
      return state;
  }
}

// actions
export const setReporterAction = reporters => ({ type: SET_REPORTERS, payload: reporters });

export const getReporterAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REPORTERS });
    const companyId = getState().user.data.companyId;
    const reports = await getReports(companyId);
    const reportsGroup = groupBy([...reports], "email");
    const reportsFormated = [];
    for (let key in reportsGroup) {
      let frequencyFormated = "";
      reportsGroup[key].forEach(el => {
        if (el.frequency === "DAILY" && el.isActive)
          frequencyFormated += frequencyFormated.length > 0 ? ", DIARIA" : "DIARIA";
        else if (el.frequency === "WEEKLY" && el.isActive)
          frequencyFormated += frequencyFormated.length > 0 ? ", SEMANAL" : "SEMANAL";
        else if (el.frequency === "MONTHLY" && el.isActive)
          frequencyFormated += frequencyFormated.length > 0 ? ", MENSUAL" : "MENSUAL";
      });
      reportsFormated.push({
        frequencies: reportsGroup[key],
        ...reportsGroup[key][0],
        frequency: frequencyFormated,
        selected: false
      });
    }
    dispatch({ type: GET_REPORTERS_SUCCESS, payload: reportsFormated });
  } catch (error) {
    dispatch({ type: GET_REPORTERS_ERROR, payload: error });
  }
};

export const setReporterAsSelectedAction = index => (dispatch, getState) => {
  const selectedReporter = getState().report.data[index];
  dispatch({ type: SET_REPORTER_AS_SELECTED, payload: { selectedReporter, index } });
};

export const unSelectReporterAction = () => (dispatch, getState) => {
  dispatch({ type: UNSELECT_REPORTER, payload: null });
};

export const deleteReporterAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_REPORTERS });
    const selectedReport = { ...getState().report.current };
    const reportsDeletedSuccess = await deleteReport(selectedReport);
    if (reportsDeletedSuccess) {
      dispatch({ type: UNSELECT_REPORTER, payload: null });
      dispatch(getReporterAction());
    } else {
      dispatch(getReporterAction());
      dispatch({ type: GET_REPORTERS_ERROR, payload: "Cannot delete reports" });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_REPORTERS_ERROR, payload: error });
  }
};

export const addReporterAction = data => async (dispatch, getState) => {
  try {
    // const companyId = getState().user.data.companyId;
    // const adminData = { ...data, companyId, locationId: data.location };
    // delete adminData.passwordConfirmation;
    // delete adminData.location;
    // adminData.type = "ADMIN";
    // const status = await addAdmin(adminData);
    // return status;
  } catch (error) {
    return { error };
  }
};

export const editReporterAction = data => async (dispatch, getState) => {
  // const selectedadmin = getState().admins.current;
  // const adminData = { ...data, id: selectedadmin.id };
  // console.log(adminData);
  // const status = await editAdmin(adminData);
  // if (status) {
  //   console.log(status);
  //   dispatch({ type: EDIT_AGENT_SUCCESS });
  //   return true;
  // }
};

export const clearEditReporterSuccessAction = () => ({
  type: CLEAR_EDIT_REPORTER_SUCCESS_ACTION,
  payload: null
});

export const clearReporterAction = () => ({
  type: CLEAR_REPORTER,
  payload: null
});
