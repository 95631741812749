const PlanStatus = {
  ACTIVE: 'ACTIVE',
  IN_REVIEW: 'IN_REVIEW',
  PAUSED: 'PAUSED',
  SIGNING_UP: 'SIGNING_UP',
  INACTIVE: 'INACTIVE',
  UPDATED: 'UPDATED',
  TRIAL: 'TRIAL',
  AWAITING_WA_VINCULATION: 'AWAITING_WA_VINCULATION',
  AWAITING_FIRST_PAYMENT: 'AWAITING_FIRST_PAYMENT',
  AWAITING_SUSCRIPTION_PAYMENT: 'AWAITING_SUSCRIPTION_PAYMENT',
  AWAITING_BILLABLE_EXTRA_MESSAGES_PAYMENT: 'AWAITING_BILLABLE_EXTRA_MESSAGES_PAYMENT',
};

export default PlanStatus;