import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
`;

export const InputForm = styled.div`
  height: 2.5rem;
  width: 100%;
  border-radius: 6px;
  background-color: ${(props) => {
    if (props.error) return 'rgba(255, 0, 0, 0.1)';
    if (props.disabled) return 'transparent';
    return '#f5f4f6';
  }};
  padding: 0.6rem 0 0.6rem 1rem;
  margin-top: 0.5rem;
`;

export const InputLabel = styled.label`
  font-size: 1rem;
  font-family: 'Montserrat-Bold';
  ${(props) => (props.newTheme ? css`color: ${props.theme.new.mainText};` : css`opacity: 0.5;`)}
`;

export const InputIcon = styled.img`
  margin-left: 1rem;
`;

export const InputValue = styled.input`
  font-family: Montserrat-Bold;
  font-size: 1rem;
  background: none;
  border: none;
  margin-left: 1rem;
  flex-grow: 2;

  &:focus {
    outline: none;
  }

  &::placeholder {
    opacity: 0.3;
  }
`;

export const InputError = styled.div`
  font-size: 1rem;
  color: red;
`;
