import styled from 'styled-components';

export const FilesSectionContainer = styled.div`
  margin-bottom: ${(props) => (props.noBottomMargin ? '3.0rem' : '0')};
  ${(props) => props.newTheme && `height: 100%;`}
`;

export const FilesSectionTitle = styled.div`
  margin-bottom: 2.5rem;
  height: 1.25rem;
  font-weight: bold;
  font-family: system-ui, sans-serif;
`;

export const DropZoneContainer = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;
  ${(props) => props.newTheme ?  `height: 100%;` : 'height: 10.625rem;'}
`;

export const MultimediaSortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
  margin-right: 2rem;
  height: 1.25rem;
`;

export const EmptyFiles = styled.div`
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  opacity: 0.5;
`;

export const FileCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`;
