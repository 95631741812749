import gql from "graphql-tag";

const GET_CATEGORIES = gql`
  query {
    categories {
      id
      name
    }
  }
`;

const GET_AGENTS_BY_COMPANY = gql`
  query users($companyId: String!) {
    users(
      where: {
        companyId: $companyId
      }
    ) {
      id
      name
      rol {
        permissions {
          name
        }
      }
    }
  }
`;

const GET_COMPANY_BY_ID = gql`
  query companies($id: ID!) {
    users(where: { id: $id }) {
      id
      company {
        name
        utcOffsetLabel
      }
    }
  }
`;

const GET_COMPANY = gql`
  query companies($id: ID!) {
    companies(where: { id: $id }) {
      id
      isWelcomeMessageActive
    }
  }
`;
const GET_HAS_USED_TWO_FACTOR = gql`
  query companies($id: ID!) {
    companies(where: { id: $id }) {
      hasUsed2FA
    }
  }
`;
const GET_CAN_RETURN_INBOX_CONVERSATIONS = gql`
  query companies($id: ID!) {
    companies(where: { id: $id }) {
      id
      canReturnInboxConversation
    }
  }
`;

const UPDATE_IS_AGENT_NAME_IN_MESSAGE_ACTIVE = gql`
  mutation updateCompany($id: ID!, $isAgentNameInMessageActive: Boolean) {
    updateCompany(
      where: { id: $id }
      data: { isAgentNameInMessageActive: $isAgentNameInMessageActive }
    ) {
      id
    }
  }
`;

const SET_WELCOME_BOT_STATUS = gql`
  mutation updateCompany($id: ID!, $isWelcomeMessageActive: Boolean) {
    updateCompany(
      where: { id: $id }
      data: { isWelcomeMessageActive: $isWelcomeMessageActive }
    ) {
      id
    }
  }
`;

const ApolloCompany = {
  GET_CATEGORIES,
  GET_AGENTS_BY_COMPANY,
  UPDATE_IS_AGENT_NAME_IN_MESSAGE_ACTIVE,
  GET_COMPANY_BY_ID,
  SET_WELCOME_BOT_STATUS,
  GET_COMPANY,
  GET_CAN_RETURN_INBOX_CONVERSATIONS,
  GET_HAS_USED_TWO_FACTOR,
};

export default ApolloCompany;
