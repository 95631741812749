import React from "react";

import {
  CardNumber,
  CardText,
  CardTitle,
  CardWrapper,
} from "./AnalyticsCard.style";
import LoaderComponent from "../loader/Loader.component";
// import image from "../../assets/images/cards/photo_2023-07-17_14-52-07.jpg";

const AnalyticsCard = ({
  color,
  title,
  count,
  hideSubtitle = false,
  messageInfo,
  isLoading = false,
  isNewText = false,
  newText = "",
  cost,
  image
}) => {
  return (
    <CardWrapper messageInfo={messageInfo} image={image}>
      <CardTitle color={color}>{title}</CardTitle>
      {isLoading ? (
        <LoaderComponent width="22" height="22" />
      ) : (
        <>
          {isNewText ? (
            <CardNumber color={color} >{`${cost}`}</CardNumber>
          ) : (
            <CardNumber color={color} >
              {count}
            </CardNumber>
          )}
        </>
      )}

      {!hideSubtitle && (
        <>
          {isNewText ? (
            <CardText color={color} >
              {newText}
            </CardText>
          ) : (
            <CardText color={color}  >
              {/* {messageInfo ? "Mensajes" : "Totales"} */}
            </CardText>
          )}
        </>
      )}
    </CardWrapper>
  );
};

export default AnalyticsCard;
