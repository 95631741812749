import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getLabelMessage } from "../../../const/labelService";
import AppCard from "../../../components/shared/components/appCard/AppCard";
import LoaderComponent from "../../../components/loader/Loader.component";
import Button from "../../../components/shared/components/button/Button.style";
import {
  cleanSelectedFileAction,
  setSelectedFileAction,
} from "../../../redux/campaignDuck";
import {
  BotInfo,
  ButtonContainer,
} from "../../settings/autoresponses/AutoResponses.style";
import {
  GropRadioButtons,
  LabelRadioButton,
  NewWhatsappTitle,
  RadioButtonsContainer,
} from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import CONSTANTS from "../../../const/constants";
import { InputError } from "../../../components/stripeInput/StripeInput.style";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import ChatDropzone from "../../../components/chatDropzone/ChatDropzone";
import { uploadFileAction } from "../../../redux/multimediaDuck";

const BroadcastSelectMultimedia = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const channelId = useSelector((state) => state.campaign.create.channelId);
  const fileName = useSelector(
    (state) => state.multimedia?.selectedFile?.fileName
  );
  const file = useSelector((state) => state.multimedia?.selectedFile?.file);
  const [messageError] = useState("");
  const whatsappTemplate = useSelector((state) => state.campaign?.selected);
  const templateFileUrl = whatsappTemplate?.components
    ? whatsappTemplate?.components?.[0]?.exampleHeader?.header_handle?.[0]
    : "";
  const [isLoading, setIsLoading] = useState(false);
  const labels = ["broadcastSelectMultimediaTitle", "whatsappTemplateCategory"];
  const lbls = getLabelMessage(labels);

  const [isOption, setIsOption] = useState(0);
  const [fileType, setfileType] = useState(0);

  const onValueChange = (event) => {
    console.log("En");
    setIsOption(event.target.value);
  };

  const handleActionButton = async () => {
    setIsLoading(true);
    const formData = new FormData();
    console.log("file: ", file);
    if (file == null || file === undefined) {
      history.push("/broadcast/selectDate");
    }

    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("weight", file.size);

    let fileType = null;
    if (file.type === "image/jpeg") fileType = "JPG";
    else if (file.type === "image/png") fileType = "PNG";
    else if (file.type === "application/pdf")
      fileType = CONSTANTS.FILE_TYPE_PDF;
    else if (file.type === "video/mp4") fileType = "MP4";
    formData.append("fileType", fileType);
    const s3Url = await dispatch(
      uploadFileAction(
        formData,
        (progress, error) => console.log(progress, error),
        "templateMedia"
      )
    );
    console.log("s3Url: ", s3Url);
    dispatch(setSelectedFileAction(s3Url.url));
    setIsLoading(false);
    history.push("/broadcast/selectDate");
  };

  useEffect(() => {
    if (channelId === null) history.push("/broadcast/table");
  }, [channelId]);

  useEffect(() => {
    if (isOption === "yes") {
      console.log("es yes: ", isOption);
      //   isMedia(true);
    } else {
      //   isMedia(false);
    }
  }, [isOption]);
  useEffect(() => {
    console.log("FILE: ", templateFileUrl);
    const fileType = templateFileUrl?.split(".")?.pop();
    console.log("FILE: ", fileType);
    if (fileType) {
      switch (fileType.toUpperCase()) {
        case "JPG":
          setfileType("JPG");
          break;
        case "PNG":
          setfileType("PNG");
          break;
        case "MP4":
          setfileType("MP4");
          break;

        case "PPTX":
          setfileType(CONSTANTS.FILE_TYPE_PPTX);
          break;

        case "DOCX":
          setfileType(CONSTANTS.FILE_TYPE_DOCX);
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          console.log("CONSTANTS.FILE_TYPE_XLSX: ", CONSTANTS.FILE_TYPE_XLSX);
          setfileType(CONSTANTS.FILE_TYPE_XLSX);
          break;
        case "PDF":
          setfileType(CONSTANTS.FILE_TYPE_PDF);
          break;

        default:
          break;
      }
    }
  }, []);

  return (
    <AppCard BroadcastSelectMultimedia>
      <BotInfo
        newTemplate
        style={{
          height: "90%",
        }}
      >
        <NewWhatsappTitle>
          {lbls.broadcastSelectMultimediaTitle}
        </NewWhatsappTitle>
        <div
          // onSubmit={handleSubmit(() => {})}
          style={{ position: "relative" }}
        >
          <RadioButtonsContainer>
            <GropRadioButtons>
              <input
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                }}
                type="radio"
                id="yes"
                value={"yes"}
                checked={isOption === "yes"}
                onChange={onValueChange}
              />
              <LabelRadioButton for="yes">
                Sí, quiero cambiar la multimedia.
              </LabelRadioButton>
            </GropRadioButtons>
            <GropRadioButtons>
              <input
                style={{
                  backgroundColor: "#FFFFFF",
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                }}
                type="radio"
                id="not"
                value={"not"}
                checked={isOption === "not"}
                onChange={onValueChange}
                onClick={() => {
                  dispatch(cleanSelectedFileAction());
                  history.push("/broadcast/selectDate");
                }}
              />
              <LabelRadioButton for="not">
                No, pasar al siguiente paso
              </LabelRadioButton>
            </GropRadioButtons>
          </RadioButtonsContainer>
          {isOption === "yes" && (
            <>
              <ChatDropzone
                fileType={fileType}
                templateMedia
                isPreview
                newTheme
                isSelectTemplate
              />
            </>
          )}
        </div>
      </BotInfo>
      <InputError>{messageError}</InputError>

      <BackButton
        style={{
          position: "relative",
          bottom: "0px",
          left: "0px",
          marginTop: "20px",
        }}
        templateAction
        onClick={() => {
          history.push("/broadcast/selectTemplate");
        }}
      >
        <BackButtonIconContainer>
          <BackButtonIcon src={backButtonIcon} />
        </BackButtonIconContainer>
        <BackButtonText>Regresar</BackButtonText>
      </BackButton>
      <ButtonContainer newTemplate templateMedia>
        <Button
          createTemplate
          color="primary"
          onClick={handleActionButton}
          disabled={fileName === undefined || fileName.length > 50}
          createTemplateDisable={fileName === undefined}
        >
          {isLoading ? <LoaderComponent width="22" height="22" /> : "Continuar"}
        </Button>
      </ButtonContainer>
    </AppCard>
  );
};

export default BroadcastSelectMultimedia;
