import styled, { css } from "styled-components";

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: nowrap; */
`;

export const SearchForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: unset;
  padding-left: ${(props) => (props.templateSelection ? "" : "1rem")};
  width: ${(props) => (props.templateSelection ? "100%" : "85%")};
  border: 1px solid #e1e5ea;
  height: 2rem;
  border-radius: 0.375rem;
  background-color: #f5f4f6;
`;

export const SearchInput = styled.input`
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  background: none;
  border: none;
  margin-left: 1rem;
  font-weight: 500;
  flex-grow: 2;
  color: #000000;
  opacity: 1;

  &::placeholder {
    color: #000000;
    opacity: 0.7;
  }

  ${(props) =>
    props.templateSelection &&
    css`
      margin-left: 0.75rem;
      font-family: "SpaceGrotesk-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      /* or 24px */

      display: flex;
      align-items: center;
      letter-spacing: -0.257238px;

      color: #9599a8;
    `}

  &:focus {
    opacity: 1;
    outline: none;
  }
`;
export const SearchIconContainer = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`;


export const LabelNewAudience = styled.label`
  width: 193px;
  height: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin-left: 24px;
  margin-bottom: 12px;
  color: #a4a4a4;
`;
