import { find, isEmpty, isEqual, remove } from "lodash";

import { updateAssignTag, updateClientAgent } from "./conversationsDuck";
import {
  addAgent,
  deleteUser,
  editAgent,
  editAgentAvailability,
  editAgentPassword,
  findAgentByEmail,
  getAdminssBySpaceId,
  getAgents,
  getAgentsByFilters,
  getAgentsBySpaceId,
  getAgentsRole,
} from "../utils/getAgents";
import CONSTANTS from "../const/constants";

const AGENTS_INITIAL_STATE = {
  data: [],
  fetched: false,
  fetching: false,
  current: {},
  index: null,
  editAgentSuccess: false,
};

// constants
const SET_AGENTS = "SET_AGENTS";
const GET_AGENTS = "GET_AGENTS";
const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
const GET_AGENTS_ERROR = "GET_AGENTS_ERROR";
const SET_AGENT_AS_SELECTED = "SET_AGENT_AS_SELECTED";
const UNSELECT_AGENT = "UNSELECT_AGENT";
const DELETE_AGENT = "DELETE_AGENT";
const EDIT_AGENT_SUCCESS = "EDIT_AGENT_SUCCESS";
const CLEAR_EDIT_AGENT_SUCCESS_ACTION = "CLEAR_EDIT_AGENT_SUCCESS_ACTION";
const CLEAR_AGENTS = "CLEAR_AGENTS";
const CLEAR_DATA = "CLEAR_DATA";

// reducer
export default function agentsReducer(
  state = { ...AGENTS_INITIAL_STATE },
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_AGENTS:
      return payload;
    case GET_AGENTS:
      return { ...state, fetching: true };
    case GET_AGENTS_SUCCESS:
      return { ...state, fetching: false, fetched: true, data: payload };
    case GET_AGENTS_ERROR:
      return { ...state, fetching: false, fetched: true, error: payload };
    case SET_AGENT_AS_SELECTED:
      return { ...state, current: payload };
    case UNSELECT_AGENT:
      return { ...state, current: {}, index: null };
    case DELETE_AGENT:
      return {
        ...state,
        data: payload.data,
        current: {},
        index: null,
        fetching: false,
      };
    case EDIT_AGENT_SUCCESS:
      return { ...state, editAgentSuccess: true };
    case CLEAR_EDIT_AGENT_SUCCESS_ACTION:
      return { ...state, editAgentSuccess: false };
    case CLEAR_AGENTS:
      return { ...AGENTS_INITIAL_STATE };
    case CLEAR_DATA:
      return { ...AGENTS_INITIAL_STATE };
    default:
      return state;
  }
}

// actions
export const setAgentsAction = (messages) => ({
  type: SET_AGENTS,
  payload: messages,
});

export const getAgentsAction =
  (rolesFilters, spacesFilters) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_AGENTS });

      const companyId = getState().user.data.company.id;

      const agents = await getAgentsByFilters(
        companyId,
        rolesFilters,
        spacesFilters
      );

      const agentsFormated = agents?.map((agent) => ({
        ...agent,
        spacesNames: agent?.spaces?.reduce(
          (acc, cur, idx, arr) =>
            `${acc}${cur.name}${idx !== arr.length - 1 ? ", " : ""}`,
          ""
        ),
        selected: false,
      }));

      dispatch({ type: GET_AGENTS_SUCCESS, payload: agentsFormated });
      return agentsFormated;
    } catch (error) {
      dispatch({ type: GET_AGENTS_ERROR, payload: error });
      throw error;
    }
  };

export const getAllAgents = () => async (_, getState) => {
  const companyId = getState().user.data.company.id;
  const agents = await getAgents(companyId);
  return agents?.users;
};

export const setCurrentUserAction = (agent) => (dispatch) => {
  dispatch({ type: SET_AGENT_AS_SELECTED, payload: agent });
};

export const unSelectAgentAction = () => (dispatch, getState) => {
  dispatch({ type: UNSELECT_AGENT, payload: null });
};

export const deleteAgentAction = () => async (dispatch, getState) => {
  const selectedAgent = getState().agents.current;
  const roleType = selectedAgent.rol.name;
  const isSuperadmin = isEqual(roleType, "superadmin");
  try {
    if (isSuperadmin) {
      throw new Error("SUPERADMIN_CANNOT_BE_DELETED");
    }
    await deleteUser(selectedAgent);
    const response = await dispatch(getAgentsAction());
    return response;
  } catch (error) {
    throw error;
  }
};

export const addAgentAction =
  (agent, files, spacesData) => async (dispatch, getState) => {
    try {
      const otherUsers = await findAgentByEmail(agent.email);
      if (!isEmpty(otherUsers)) {
        throw new Error("USER_WITH_SAME_EMAIL");
      }

      const { roles } = getState().roles;
      const selectedRol = find(roles, { id: agent.rol });
      const spaces = Object.keys(spacesData)
        .filter((space) => spacesData[space])
        .map((x) => x);

      const formData = new FormData();
      if (!isEmpty(files) && !files[0].uploaded) {
        formData.append("file", files[0]);
      }
      formData.append("name", agent.name);
      formData.append("email", agent.email);
      formData.append("phone", agent.phone);
      formData.append("rolId", selectedRol.id);
      formData.append("password", agent.password);
      formData.append("spacesIds", JSON.stringify(spaces));

      await addAgent(formData);
      await dispatch(getAgentsAction());
    } catch (error) {
      console.error(error);
      if (error?.message?.error) throw new Error(error.message.error);
      throw error;
    }
  };

export const editAgentAvailabilityAction =
  (id, isAvailable) => async (dispatch, getState) => {
    try {
      const agents = getState().agents.data;
      const tempAgents = agents.map((agent) =>
        agent.id === id ? { ...agent, isAvailable } : agent
      );
      dispatch({ type: GET_AGENTS_SUCCESS, payload: tempAgents });
      return await editAgentAvailability(id, isAvailable);
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

export const editAgentAction =
  (agent, id, files, spacesData) => async (_, getState) => {
    try {
      const { roles } = getState().roles;
      const selectedRol = find(roles, { id: agent.rol });
      const spaces = Object.keys(spacesData)
        .filter((space) => spacesData[space])
        .map((x) => x);

      const otherUsers = await findAgentByEmail(agent.email);
      remove(otherUsers, ["id", id]);
      if (!isEmpty(otherUsers)) {
        throw new Error("USER_WITH_SAME_EMAIL");
      }
      // console.log("files", files[0])
      const formData = new FormData();
      if (!isEmpty(files) && !files[0].uploaded && typeof files[0] !== "string") {
        console.log("files", files[0])
        formData.append("file", files[0]);
      }
      formData.append("id", id);
      formData.append("name", agent.name);
      formData.append("email", agent.email);
      formData.append("phone", agent.phone);
      formData.append("rolId", selectedRol.id);
      if (agent.password) formData.append("password", agent.password);
      formData.append("spacesIds", JSON.stringify(spaces));

      const response = await editAgent(formData);
      return response;
    } catch (error) {
      throw error;
    }
  };

export const findAgentsAction = (canAgentsReasign) => async (_, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const companyName = getState().user.data.company.name;
    let response;
    if (companyName === "Vinte") {
      response = await getAgents(companyId);
    } else {
      if (canAgentsReasign) {
        response = await getAgents(companyId);
      } else {
        const selectedSpaceId = getState().spaces.selected.id;
        response = await getAgentsRole(companyId, selectedSpaceId);
      }
    }
    return response.users;
  } catch (error) {
    throw error;
  }
};

export const getAgentsBySpaceIdAction = (spaceId) => async (_, getState) => {
  try {
    const agents = await getAgentsBySpaceId(spaceId);
    return agents;
  } catch (error) {
    throw error;
  }
};

export const getAdminsBySpaceIdAction = (spaceId) => async () => {
  try {
    const agents = await getAdminssBySpaceId(spaceId);
    return agents;
  } catch (error) {
    throw error;
  }
};

export const reassignAgentAction = (agent, clientId, isGroupAdmin=false) => async (dispatch) => {
  try {
    await dispatch(updateClientAgent(agent.id, clientId, isGroupAdmin));
    dispatch(updateAssignTag());
  } catch (error) {
    throw error;
  }
};

export const editAgentPasswordAction = (data) => async () => {
  const response = await editAgentPassword(data);
  return response;
};

export const clearEditAgentSuccessAction = () => ({
  type: CLEAR_EDIT_AGENT_SUCCESS_ACTION,
  payload: null,
});

export const clearAgentsAction = () => ({
  type: CLEAR_AGENTS,
  payload: null,
});
