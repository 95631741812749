/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import API from "../axios";
import client from "../apollo/apolloClient";
import apolloLocation from "../apollo/apolloLocation";
import ApolloCompany from "../apollo/apolloCompany";

export const login = async (data, firebaseToken) => {
  try {
    const response = await API.post("/loginDash", {
      ...data,
      firebaseToken,
    });
    console.log("response", response);
    const { token } = response.data;
    if (token) {
      localStorage.setItem("token", token);
      return token;
    }
  } catch (error) {
    throw error;
  }
};
export const getUserByEmail = async (email) => {
  try {
    const response = await API.post("/getUserByEmail", {
      email
    });
    console.log("response", response);
    return response.data.hasUsed2FA;
  } catch (error) {
    return null;
  }
};
export const getTwoFactor = async (data, firebaseToken) => {
  try {
    const response = await API.post("/sendTwoFactorCode", {
      ...data,
      firebaseToken,
    });
    console.log("response", response);
    if (response.data?.factor?.binding) {
      const { uri } = response.data?.factor?.binding;
      localStorage.setItem("uri-qr", uri);
      // return response.data;
    }

    return response.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const updateIsAgentNameInMessageActive = async (
  id,
  isAgentNameInMessageActive
) => {
  console.log(
    "updateIsAgentNameInMessageActive === ENTRA",
    id,
    isAgentNameInMessageActive
  );

  try {
    const { data } = await client.mutate({
      mutation: ApolloCompany.UPDATE_IS_AGENT_NAME_IN_MESSAGE_ACTIVE,
      variables: { id, isAgentNameInMessageActive },
    });
    console.log("updateIsAgentNameInMessageActive === dta", data);
    if (data) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("updateIsAgentNameInMessageActive error ============", error);
    return false;
  }
};

export const verifyToken = async (token) => {
  try {
    const response = await API.post("/verifyToken", { token });
    return response.data;
  } catch (error) {
    localStorage.clear();
    window.location.replace("/");
    // throw error;
  }
};

export const verifyCodeEmail = async (code) => {
  try {
    const response = await API.post("/verify", { code });
    return response;
  } catch (error) {
    throw error;
  }
};

export const sendEmailVerification = async (email, plan) => {
  try {
    const response = await API.post("/emailVerification", { email, plan });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (token, password) => {
  try {
    const response = await API.post("/resetPassword", { token, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (data) => {
  const response = await API.post("/register", data);
  const { token } = response.data;
  if (token) {
    localStorage.clear();
    localStorage.setItem("token", token);
    const registerResponse = { token };
    return registerResponse;
  }
  throw response.data;
};

export const registerInProcess = (data) => {
  try {
    const requestData = {};
    if (data?.onboardingEmail) requestData.email = data?.onboardingEmail;
    if (data?.onboardingName) requestData.name = data?.onboardingName;
    if (data?.onboardingPhone) requestData.phone = data?.onboardingPhone;
    if (data?.onboardingCompany) requestData.company = data?.onboardingCompany;
    if (data?.onboardingCountry) requestData.country = data?.onboardingCountry;
    API.post("/registerInProcess", requestData);
  } catch (error) {
    console.log(error);
  }
};

export const recoverPassword = async (data) => {
  try {
    const response = await API.post("/sendResetPasswordEmail", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (data) => {
  console.log("updateCompany API ==================");
  try {
    const response = await API.post("/updateCompany", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompanyV2 = async (data) => {
  console.log("updateCompany V2 API ==================");
  try {
    const response = await API.post("/updateCompanyV2", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLocations = async (toUpdateOrAdd, toDelete, companyId) => {
  try {
    for (const location in toUpdateOrAdd) {
      if (toUpdateOrAdd[location].id) {
        await client.mutate({
          mutation: apolloLocation.UPDATE_LOCATION,
          variables: {
            id: toUpdateOrAdd[location].id,
            name: toUpdateOrAdd[location].name,
          },
        });
      } else {
        await client.mutate({
          mutation: apolloLocation.CREATE_LOCATION,
          variables: { name: toUpdateOrAdd[location].name, companyId },
        });
      }
    }

    for (const location in toDelete) {
      if (toDelete[location].id) {
        await client.mutate({
          mutation: apolloLocation.DELETE_LOCATION,
          variables: { documentId: toDelete[location].id },
        });
      }
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const logout = async (token, session) => {
  try {
    if (!token) token = localStorage.getItem("token");
    const body = { token, session };
    await API.post("/logout", body);
    localStorage.clear();
  } catch (error) {
    throw error;
  }
};
