import {
  cloneDeep,
  pull,
  isEmpty,
  map,
  find,
  isUndefined,
  remove,
} from "lodash";

import { getCategories, getUserAgents } from "../utils/company";
import { getConversationsAction } from "./conversationsDuck";
import { getTagsByCompanyAction } from "./tagsDuck";
import { getAllTagsByCompany } from "./tagsDuck";
import CategoryEnum from "../components/shared/constants/categoryEnum";
import filtersConst from "../const/filters";
import CONSTANTS from "../const/constants";

const FILTERS_INITIAL_STATE = {
  data: [],
  selected: [],
  type: "assigned",
  channels: [],
  priority: [],
  agents: [],
  tags: [],
  noAnswered: false,
  isArchived: false,
  unanswered: false,
  isConversationValuable: false,
  isArchivedBroadcast: false,
  fetching: false,
  fetched: false,
  unreadMessages: false,
};

// constants
const SET_FILTERS = "SET_FILTERS";
const SELECT_FILTER = "SELECT_FILTER";
const CLEAR_DATA = "CLEAR_DATA";
const GET_CATEGORIES = "GET_CATEGORIES";

const GET_CHAT_FILTERS = "GET_CHAT_FILTERS";
const GET_CHAT_FILTERS_SUCCESS = "GET_CHAT_FILTERS_SUCCESS";
const SWITCH_ANSWERED_FILTER = "SWITCH_ANSWERED_FILTER";
const SWITCH_ARCHIVED_FILTER = "SWITCH_ARCHIVED_FILTER";
const SWITCH_UNANSWRED_FILTER = "SWITCH_UNANSWRED_FILTER";
const SWITCH_ARCHIVED_BROADCAST_FILTER = "SWITCH_ARCHIVED_BROADCAST_FILTER";
const SWITCH_CONVERSATION_VALUABLE_FILTER =
  "SWITCH_CONVERSATION_VALUABLE_FILTER";
const SET_ARCHIVED_TYPE_FILTER = "SET_ARCHIVED_TYPE_FILTER";
const SET_ARCHIVED_BROADCAST_TYPE_FILTER = "SET_ARCHIVED_BROADCAST_TYPE_FILTER";
const SWITCH_UNREADMESSAGES_FILTER = "SWITCH_UNREADMESSAGES_FILTER";
const GET_CHAT_FILTERS_ERROR = "GET_CHAT_FILTERS_ERROR";
const SET_CHAT_FILTERS = "SET_CHAT_FILTERS";
const SET_CHAT_FILTER_TYPE = "SET_CHAT_FILTER_TYPE";
const CLEAR_CHAT_FILTERS = "CLEAR_CHAT_FILTERS";

// reducer
export default function filtersReducer(state = FILTERS_INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTERS:
      return {
        data: cloneDeep(filtersConst[payload].filters),
        selected: cloneDeep(filtersConst[payload].defaultSelection),
      };
    case SELECT_FILTER:
      return { data: payload.data, selected: payload.selected };
    case CLEAR_DATA:
      return FILTERS_INITIAL_STATE;
    case GET_CATEGORIES:
      return { ...state, categories: payload };
    case GET_CHAT_FILTERS:
      return { ...state, fetching: true };
    case GET_CHAT_FILTERS_SUCCESS:
      return {
        ...state,
        channels: payload.channels,
        priority: payload.priority,
        agents: payload.agents,
        tags: payload.labels,
        noAnswered: payload.answered,
        fetching: false,
      };
    case SWITCH_ANSWERED_FILTER:
      return { ...state, noAnswered: !state.noAnswered };
    case SWITCH_ARCHIVED_FILTER:
      return { ...state, isArchived: !state.isArchived };
    case SWITCH_ARCHIVED_BROADCAST_FILTER:
      return { ...state, isArchivedBroadcast: !state.isArchivedBroadcast };
    case SWITCH_CONVERSATION_VALUABLE_FILTER:
      return {
        ...state,
        isConversationValuable: !state.isConversationValuable,
      };
    case SET_ARCHIVED_TYPE_FILTER:
      return { ...state, type: "Archived" };
    case SET_ARCHIVED_BROADCAST_TYPE_FILTER:
      return { ...state, type: "ArchivedBroadcast" };
    case SWITCH_UNREADMESSAGES_FILTER:
      return { ...state, unreadMessages: !state.unreadMessages };
    case SWITCH_UNANSWRED_FILTER:
      return { ...state, unanswered: !state.unanswered };
    case GET_CHAT_FILTERS_ERROR:
      return { ...state, fetching: false };
    case SET_CHAT_FILTERS:
      return { ...state, selected: payload };
    case SET_CHAT_FILTER_TYPE:
      return { ...state, type: payload };
    case CLEAR_CHAT_FILTERS:
      return {
        ...state,
        selected: [],
        noAnswered: false,
        isArchived: false,
        unanswered: false,
        isConversationValuable: false,
        isArchivedBroadcast: false,
        fetching: false,
        fetched: false,
        unreadMessages: false,
      };
    default:
      return state;
  }
}

// actions
export const setFiltersAction = (filtersType) => async (dispatch) => {
  dispatch({ type: SET_FILTERS, payload: filtersType });
  return true;
};

export const setFilterTypeAction = (filterType) => async (dispatch) => {
  dispatch({ type: SET_CHAT_FILTER_TYPE, payload: filterType });
};

export const selectFilterAction =
  (columnIndex, optionIndex) => (dispatch, getState) => {
    const newFilters = [...getState().filters.data];
    const newSelectedFilters = { ...getState().filters.selected };

    const newSelectedOptionValue =
      !newFilters[columnIndex].options[optionIndex].selected;
    newFilters[columnIndex].options[optionIndex].selected =
      newSelectedOptionValue;

    const selectedCategory = newFilters[columnIndex].value;
    const selectedOption = newFilters[columnIndex].options[optionIndex].value;

    if (newSelectedOptionValue)
      newSelectedFilters[selectedCategory].push(selectedOption);
    else pull(newSelectedFilters[selectedCategory], selectedOption);

    dispatch({
      type: SELECT_FILTER,
      payload: { data: newFilters, selected: newSelectedFilters },
    });
  };

export const searchCategoriesAction = () => async (dispatch, getState) => {
  try {
    const categories = await getCategories();
    const selectedCategories = getState().filters.selected.categories;
    map(categories, (category) => {
      category.translatedName = CategoryEnum[category.name];
      const foundCategory = find(selectedCategories, { id: category.id });
      category.selected = !isUndefined(foundCategory);
      return category;
    });
    if (!isEmpty(categories)) {
      dispatch({ type: GET_CATEGORIES, payload: categories });
      return categories;
    }
    return [];
  } catch (error) {
    const jcnError = new Error("Error al obtener las categorias");
    throw jcnError;
  }
};

export const searchAgentsAction = () => async (_, getState) => {
  try {
    const companyId = getState().user.data.company.id;
    const actualAgent = getState().user.data;
    const canReadUsers = getState().user.data?.permissions?.readUsers;
    const agents = await getUserAgents(companyId);
    const selectedAgents = getState().filters.selected.agents;
    map(agents, (agent) => {
      const foundAgent = find(selectedAgents, { id: agent.id });
      agent.selected = !isUndefined(foundAgent);
      return agent;
    });
    if (!canReadUsers) {
      agents.push({
        id: actualAgent.id,
        name: actualAgent.name,
        selected: false,
      });
    }
    return agents;
  } catch (error) {
    throw error;
  }
};

export const searchLabelsAction = () => async (dispatch, getState) => {
  try {
    // await dispatch(getTagsByCompanyAction);
    const labels = await dispatch(getTagsByCompanyAction());

    // const selectedLabels = getState().filters.selected.tags;
    // // console.log("foundLabel: ", foundLabel);
    // const newLabels = labels.map((label) => {
    //   const foundLabel = find(selectedLabels, { id: label.id });
    //   label.selected = !isUndefined(foundLabel);
    //   return label;
    // });

    return labels;
  } catch (error) {
    throw error;
  }
};

export const getChatFiltersAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CHAT_FILTERS });
    const categories = await dispatch(searchCategoriesAction());
    const labels = await dispatch(searchLabelsAction());

    const agents = await dispatch(searchAgentsAction());
    agents.push({
      id: CONSTANTS.UNASSIGNED_AGENT_ID,
      name: "Sin asignar",
      selected: false,
    });

    const channels = [{ name: "MESSENGER" }, { name: "WHATSAPP" }];
    const selectedChannels = getState().filters.selected.channels;
    map(channels, (channel) => {
      const foundChannel = find(selectedChannels, { name: channel.name });
      channel.selected = !isUndefined(foundChannel);
      return channel;
    });

    const priority = [{ name: "high" }, { name: "medium" }, { name: "low" }];
    const selectedPriority = getState().filters.selected.priority;
    map(priority, (option) => {
      const foundPriority = find(
        selectedPriority,
        (row) => row === option.name
      );
      option.selected = !isUndefined(foundPriority);
      return option;
    });

    const payload = { categories, agents, priority, labels, channels };
    console.log("payload: ====> ", payload);
    dispatch({ type: GET_CHAT_FILTERS_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: GET_CHAT_FILTERS_ERROR });
    throw error;
  }
};

export const setSelectedChatFiltersAction =
  (selectedFilters) => async (dispatch) => {
    try {
      await dispatch({ type: SET_CHAT_FILTERS, payload: selectedFilters });
      await dispatch(getConversationsAction(true));
    } catch (error) {
      throw new Error("ERROR_SAVING_FILTERS");
    }
  };

export const switchNoAnsweredFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_ANSWERED_FILTER });
};
export const switchArchivedFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_ARCHIVED_FILTER });
};
export const switchArchivedBroadcastFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_ARCHIVED_BROADCAST_FILTER });
};
export const switchConversationValubleFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_CONVERSATION_VALUABLE_FILTER });
};
export const setArchivedType = () => (dispatch) => {
  dispatch({ type: SET_ARCHIVED_TYPE_FILTER });
};
export const setArchivedBroadcastType = () => (dispatch) => {
  dispatch({ type: SET_ARCHIVED_BROADCAST_TYPE_FILTER });
};
export const switchUnansweredFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_UNANSWRED_FILTER });
};

export const switchUnreadMessagesFilter = () => (dispatch) => {
  dispatch({ type: SWITCH_UNREADMESSAGES_FILTER });
};

export const clearSelectedChatFiltersAction = () => async (dispatch) => {
  try {
    await dispatch({ type: CLEAR_CHAT_FILTERS });
    await dispatch(getChatFiltersAction());
    await dispatch(getConversationsAction(true));
  } catch (error) {
    console.error(error);
    throw new Error("ERROR_CLEANING FILTERS");
  }
};

export const clearFiltersAction = () => (dispatch) => {
  dispatch({ type: CLEAR_CHAT_FILTERS });
};

export const clearDataAction = () => ({ type: CLEAR_DATA, payload: null });
