import styled from "styled-components";
export const DateInputContainer = styled.div`
  position: relative;
  width: 11.938rem;
  height: 2.5rem;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 12px;
  border: 2px solid #e9e9e9;
  border-radius: 4px;
`;

export const DateInputLabel = styled.label`
  position: absolute;
  font-family: "SpaceGrotesk-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  top: -9px;
  background: white;
  color: #2e3353;
  z-index: 10;
  left: 8px;
`;

export const DateInputText = styled.span`
  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  color: #2e3353;
  
`;
