import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import parse from "html-react-parser";

import { getLabelMessage } from "../../../const/labelService";

import AppCard from "../../../components/shared/components/appCard/AppCard";

import Button from "../../../components/shared/components/button/Button.style";
import { ButtonContainer } from "../../settings/autoresponses/AutoResponses.style";
import { NewWhatsappTitle } from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";

import {
  BroadcastErrorCardContainer,
  ContentSpan,
  ExportIcon,
  ExportIconContainer,
  GridContainer,
  GridItem,
} from "./BroadcastView.styles";
import BroadcastAnalitycCard from "./BroadcastAnalitycsCard/BroadcastAnalitycCard.component";
import exportIcon from "../../../assets/svg/Publish.svg";
import { getCampaignStatus } from "../../../utils/campaign";
import { generarExcel } from "../../../utils/xlsxClass";
import moment from "moment";

const BroadcastAnalitycs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedCampaign = useSelector(
    (state) => state.campaign?.selectedCampaign
  );
  const [campaignStatus, setCampaignStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(true);
  const labels = [
    "broadcastPreviewTitle",
    "variableInstructionsMessagePreview",
    "broadcastName",
    "whatsappTemplateName",
  ];
  const lbls = getLabelMessage(labels);

  const handleActionButton = () => {
    generarExcel(
      selectedCampaign?.name,
      moment(selectedCampaign?.lastSendAt).format("DD/MM/YYYY"),
      campaignStatus
    );
    // history.push("/broadcast");
  };

  const getStatus = async () => {
    setIsLoading(true);
    const status = await getCampaignStatus(selectedCampaign?.id || "");
    console.log(status);
    setCampaignStatus(status);
    setIsLoading(false);
  };

  useEffect(() => {
    getStatus();
    // setError(status.error);
  }, []);

  return (
    <AppCard broadcastCard>
      <NewWhatsappTitle
        style={{ marginLeft: "0.875rem", fontSize: "1.5rem" }}
      >{`${selectedCampaign.name} - ${moment(selectedCampaign.createdAt).format(
        "DD/MM/YYYY"
      )} `}</NewWhatsappTitle>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(auto,1fr)",
          gridTemplateRows: "repeat(2, auto)",
          gridGap: "10px",
          marginTop: "10px",
          width: "90%",
        }}
      >
        <GridContainer>
          <GridItem>
            <BroadcastAnalitycCard
              count={campaignStatus?.successfulMessages?.length || 0}
              statusTitle="Mensajes enviados exitosamente"
              type="status"
              payload="success"
              isLoading={isLoading}
            />
          </GridItem>
          <GridItem>
            <BroadcastAnalitycCard
              count={campaignStatus?.sentdMessages?.length  || 0}
              statusTitle="Mensajes enviados, aún no recibidos por el cliente."
              type="status"
              payload="sent"
              isLoading={isLoading}
            />
            <BroadcastAnalitycCard
              count={campaignStatus?.deliveredMessages?.length  || 0}
              statusTitle="Mensajes recibidos, aún no leídos."
              type="status"
              payload="delivered"
              isLoading={isLoading}
            />
            <BroadcastAnalitycCard
              count={campaignStatus?.viewedMessages?.length  || 0}
              statusTitle="Mensaje leídos"
              type="status"
              payload="read"
              isLoading={isLoading}
            />
          </GridItem>
          <GridItem>
            <BroadcastAnalitycCard
              count={campaignStatus?.failedMessages?.length  || 0}
              statusTitle="Mensajes no enviados"
              type="error"
              payload="error"
              isLoading={isLoading}
            />
          </GridItem>
        </GridContainer>
      </div>

      <BackButton
        style={{
          marginTop: "10px",
          paddingTop: "30px",
          left: "0px",
        }}
        templateAction
        onClick={() => {
          history.push("/broadcast/table");
        }}
      >
        <BackButtonIconContainer>
          <BackButtonIcon src={backButtonIcon} />
        </BackButtonIconContainer>
        <BackButtonText>Regresar</BackButtonText>
      </BackButton>

      <ButtonContainer newTemplate templateMedia={true}>
        <Button broadcastAnalitycs color="primary" onClick={handleActionButton}>
          Exportar
          <ExportIconContainer>
            <ExportIcon src={exportIcon} />
          </ExportIconContainer>
        </Button>
      </ButtonContainer>
    </AppCard>
  );
};

export default BroadcastAnalitycs;
