import * as Excel from "exceljs";
import * as FileSaver from "file-saver";
export class Xlsx {
  constructor(content) {
    this.content = content;
  }

  header() {
    return this.content[0];
  }

  body() {
    return this.content.slice(1, this.content.length);
  }
}

export const generarExcel = async (
  campaignName,
  campaignDate,
  campaignStatus
) => {
  const workbook = new Excel.Workbook();
  const sheet = workbook.addWorksheet("estadísticas_campañas");

  const row = sheet.getRow(1);
  row.getCell(1).value = campaignName;
  const row2 = sheet.getRow(2);
  row2.getCell(1).value = "Estadisticas de campañas - iVentas";
  const row3 = sheet.getRow(3);
  row3.getCell(1).value = `${campaignDate} - ${campaignName}`;
  const row4 = sheet.getRow(4);
  row4.getCell(1).value = "Números";
  const row5 = sheet.getRow(5);
  row5.getCell(1).value = "Status";
  row5.getCell(2).value = "Exitosos";
  row5.getCell(3).value = "Fallidos";
  row5.getCell(4).value = 'Status "Recibido"';
  row5.getCell(5).value = 'Status "Leído"';
  campaignStatus.successfulMessages.forEach((x, i) => {
    const successfulMessages = sheet.getRow(6 + i);
    successfulMessages.getCell(2).value = x;
  });
  campaignStatus.failedMessages.forEach((x, i) => {
    const failedMessages = sheet.getRow(6 + i);
    failedMessages.getCell(3).value = x;
  });
  campaignStatus.deliveredMessages.forEach((x, i) => {
    const receivedMessages = sheet.getRow(6 + i);
    receivedMessages.getCell(4).value = x;
  });
  campaignStatus.viewedMessages.forEach((x, i) => {
    const readMessages = sheet.getRow(6 + i);
    readMessages.getCell(5).value = x;
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(blob, "estadíscas_campañas.xlsx");
};
