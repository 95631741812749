/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Routes from "./router/router.jsx";

import "./App.css";

const App = () => <Routes />;

export default App;
