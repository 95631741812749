import styled, { css } from "styled-components";

export const FollowUpContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;
`;

export const FollowUpTitle = styled.span`
  font-family: system-ui, sans-serif;
  font-size: 0.8rem;
  margin-left: 0.625rem;
  color: #9193a6;
  width: 5.5rem;
`;

export const FollowUpDataTitle = styled.section`
  overflow: hidden;
  color: rgba(117, 117, 117, 1);
  margin-bottom: 0.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FollowUpDataContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FollowUpData = styled.div`
  display: flex;
  color: rgba(117, 117, 117, 1);
  align-items: center;
`;

export const FollowUpDate = styled.span`
  font-size: 0.8rem;
`;

export const FollowUpDateInputContainer = styled.div`
  margin-bottom: 1.6rem;
  ${(props) =>
    props.followDateCampaign &&
    css`
      margin-left: 21px;
    `}
`;

export const FollowUpDateInputTitle = styled.div`
  font-family: system-ui, sans-serif;
  font-size: 0.8rem;
  margin-bottom: 0.625rem;
  width: 100%;
`;

export const FollowUpDatePickerContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const FollowUpTextArea = styled.textarea`
  padding: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  width: 100%;
  height: 7.82rem;
`;

export const FollowUpSubmitButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const DateInputContainer = styled.button`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  cursor: pointer;
`;

export const DateInputLabels = styled.div`
  display: flex;
  align-items: center;
`;

export const DateInputText = styled.span`
  font-family: system-ui, sans-serif;
  font-size: 1rem;
  margin-left: 0.8rem;
`;
export const FollowUpTitlleContainer = styled.div`
  display: flex;
  padding-right: 0.75rem;
`;
