import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  LinksContainer,
  LinkContainer,
  LinkImage,
  LinkName,
  ImageContainer,
} from "./links.style";
import {
  NavContainer,
  ProfileContainer,
  ProfileImage,
  ProfileName,
  Footer,
  Logo,
  BrandName,
} from "./Nav.style";
import defaultImage from "../../../assets/images/facebook-image-profile.jpg";
import links from "../../../const/links";
import IventasWhite from "../../../assets/images/logos/logo_white.svg";
import { getLabelMessage } from "../../../const/labelService";
import { getWhatsappActiveChannel } from "../../../utils/getReferralLink";
import CONSTANTS from "../../../const/constants";
import { SlotArea } from "../sidebar/sidebar.style";
import FadeImage from "../../shared/components/FadeImage/FadeImage";
import SidebarModalComponent from "../sidebarModal/SidebarModal.component";
import bot from "../../../assets/images/bot.png";

const Nav = () => {
  const profileImageRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const spaces = useSelector((state) => state.spaces.data);
  const selectedSpace = useSelector((state) => state.spaces.selected);
  const waChannels = useSelector(
    (state) => state.channels.data?.whatsappChannels
  );
  const [showBotLink, setShowBotLink] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const labels = ["mobieLabel"];
  const lbls = getLabelMessage(labels);

  const whatsappActiveChannel = useRef(null);
  if (!whatsappActiveChannel.current?.name) {
    whatsappActiveChannel.current = getWhatsappActiveChannel(spaces);
  }

  const handleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    whatsappActiveChannel.current = getWhatsappActiveChannel(spaces);
  }, [spaces]);

  useEffect(() => {
    const waDialogChannel = waChannels?.find(
      (x) => x.platform.ap?.includes("DIALOG") && x.isActive
    );
    if (waDialogChannel?.id) setShowBotLink(false);
  }, [waChannels]);

  return (
    <NavContainer
      background={
        process.env.REACT_APP_ENV === "DEVELOPMENT" ? "secondary" : "main"
      }
    >
      <LinksContainer>
        {links.filter(link => !(user.data.rol.name === 'groupadmin' && link.name === 'Equipo')).map(
          (link) =>
            user?.data?.permissions &&
            link?.permissions?.some(
              (linkPermission) => user.data.permissions[linkPermission]
            ) && (
              <LinkContainer
                key={link.routes[0]}
                onClick={() => history.push(link.routes[0])}
                isActive={link.routes.some(
                  (route) => route === location.pathname
                )}
              >
                <ImageContainer>
                  <LinkImage src={link.routes.some(
                    (route) => location.pathname.includes(route)
                  ) ? link.activeIcon : (link.inactiveIcon || link.activeIcon)} />
                </ImageContainer>
                <LinkName
                  isActive={link.routes.some(
                    (route) => location.pathname.includes(route)
                  )}
                >
                  {link.name}
                </LinkName>
              </LinkContainer>
            )
        )}
        <SlotArea src={bot} onClick={handleModal}>
          <FadeImage image={selectedSpace?.imageUrl} />
        </SlotArea>
      </LinksContainer>

      {/* <ProfileContainer>
        <ProfileImage
          src={user.data.imageUrl || defaultImage}
          ref={profileImageRef}
        />
      </ProfileContainer> */}
      {/* <SlotArea src={bot} onClick={handleModal}>
        <FadeImage image={selectedSpace?.imageUrl} />
      </SlotArea> */}
      <SidebarModalComponent show={showModal} />
    </NavContainer>
  );
};

export default Nav;
