class CONSTANTS {
  static ADMIN = "admin";
  static AGENT = "agente";
  static GROUPADMIN = "groupadmin";
  static AUTHENTICATED_CHANNEL_STATUS = "AUTHENTICATED";
  static CODE_VERIFICATION_SIZE = 6;
  static DEFAULT_PERMISSION = "readChat";
  static AGENT_PERMISSION = "agent";
  static FILTER_ALL = "all";
  static FILTER_ASSIGNED = "assigned";
  static FILTER_UNANSWERED = "unanswered";
  static FILTER_UNREADMESSAGES = "unreadMessages";
  static FILTER_OTHERS = "others";
  static INFO_MODAL_DISPLAY_TIME = 2500;
  static LOGGING_CHANNEL_STATUS = "LOGGING";
  static MESSENGER_PLATFORM = "MESSENGER";
  static SMS_PLATFORM = "SMS";
  static ONE = 1;
  static WHATSAPPTEMPLATES_LIMIT = 5;
  static SENDER_CHAT = "iVentas";
  static INACTIVE_PLAN = "INACTIVE";
  static PAUSED_PLAN = "PAUSED";
  static UNLIMITED_PLAN = "UNLIMITED";
  static STRIPE_CANCEL_STATUS = "CANCELED";
  static SUPERADMIN = "superadmin";
  static GROUPADMIN = "groupadmin";
  static VINTE_COMMUNITY_MANAGER = "Community Manager";
  static SUPPORT_EMAIL = "hola@iventas.mx";
  static SUPPORT_WA = "wa.link/lngkra"; // wa.link/lt3of8
  static SUPPORT_WALINK = "wa.link/d3kkqw"; // wa.link/lt3of8
  static TAGS_LIMIT = 50;
  static URL_PRYVACY = "https://iventas.mx/privacy.html";
  static URL_TERMS = "https://iventas.mx/terms.html";
  static WHATSAPP_PLATFORM = "WHATSAPP";
  static WHATSAPP_QR_LIFETIME = 40000;
  static WHATSAPP_SLOTS_LIMIT = 1;
  static ZERO = 0;
  static BOT_SENDER = "BOT";
  static CLIENT_SENDER = "CLIENT";
  static CATEGORIES = {
    GLOBAL: "Global",
    CONTACT: "Contact",
    FEEDBACK: "Feedback",
  };
  static FIRST_CONVERSATION = 10;
  static MULTIMEDIA_ROUTE = "/multimedia";
  static SUBALBUM_ROUTE = "/subAlbum";
  static ALBUM_ROUTE = "/album";
  static UPDATE_SUBSCRIPTION_ROUTE = "/account";
  static LINK_WHATSAPP_ROUTE = "/link-whatsapp";
  static CONFIG_NEW_WHATSAPPTEMPLATE_ROUTE = "/config/new-whatsappTemplate";
  static REF_CODE_ROUTE = "/ref";
  static QUICK_ANSWERS_ROUTE = "/quickAnswers";
  static QUICK_ANSWERS_ACTION_ROUTE = "/quickAnswers/new";
  static FIRST_PAYMENT_ROUTE = "/payment";
  static FILE_TYPE_GENERIC = "GENERIC";
  static FILE_TYPE_TXT = "TXT";
  static FILE_TYPE_PDF = "PDF";
  static FILE_TYPE_DOC = "DOC";
  static FILE_TYPE_DOCX = "DOCX";
  static FILE_TYPE_XLS = "XLS";
  static FILE_TYPE_XLSM = "XLSM";
  static FILE_TYPE_XLSX = "XLSX";
  static FILE_TYPE_PPT = "PPT";
  static FILE_TYPE_PPTX = "PPTX";
  static FILE_TYPE_ZIP = "ZIP";
  static FILE_TYPE_AUDIO = "AUDIO";
  static FILE_TYPE_AUDIO_MP3 = "AUDIO_MP3";
  static FILE_TYPE_VIDEO = "VIDEO";
  static FILE_TYPE_IMAGE = "IMAGE";
  static FILE_TYPE_STICKER = "STICKER";
  static FILE_TYPE_CONTACTS = "CONTACTS";
  static FILE_TYPE_OTHER = "OTHER";
  static FILE_TYPE_TEMPLATE = "TEMPLATE";
  static LOCATION = "LOCATION";
  static UTILITY_TEMPLATE_CATEGORY = "UTILITY";
  static MARKETING_TEMPLATE_CATEGORY = "MARKETING";
  static CONVERSATIONS_SOURCE_REQUEST = "request";
  static CONVERSATIONS_SOURCE_SUBSCRIPTION = "subscription";
  static UPLOAD_ID = "UPLOAD_ID";
  static PLAN_ENUM = {
    INACTIVE: 0,
    ONE: 1,
    STARTER: 2,
    BASIC: 3,
    PLUS: 4,
    PRO: 5,
    SUPERPRO: 6,
    UNLIMITED: 7,
  };
  static PLAN_NAME_ENUM = {
    INACTIVE: "INACTIVE",
    ONE: "ONE",
    STARTER: "STARTER",
    BASIC: "BÁSICO",
    PLUS: "PLUS",
    PRO: "PRO",
    SUPERPRO: "SUPERPRO",
    UNLIMITED: "ILIMITADO",
  };
  static leadName = "{nombreLead}";
  static agentName = "{nombreAgente}";
  static UNASSIGNED_AGENT_ID = "unassigned";
  static LIMIT_SELECTED_MESSAGES = 6;
  static LIMIT_SELECTED_CONTACTS = 2;
  static VALID_EXTENSIONS = [
    "jpg",
    "png",
    "jpeg",
    "txt",
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsm",
    "ppt",
    "pptx",
    "zip",
    "mp4",
  ];
  static VINTE_COMPANY_ID = "62c113ea0274390007235d41";
  static VINTE_COMPANY_NAME = "Vinte";
  static VINTE_DEFAULT_WHATSAPP = "5f31a32a0274390007b4eaf1";
  static VINTE_DEFAULT_FACEBOOK = "5f31a1fa0274390007b4eaf0";
  static VINTE_DEFAULT_NAME = "Default";
  static ONBOARDING_NAME_STEP = "name";
  static ONBOARDING_PHONE_STEP = "phone";
  static ONBOARDING_CODE_STEP = "code";
  static ONBOARDING_BUSINESS_STEP = "business";
  static ONBOARDING_WEBSITE_STEP = "website";
  static ONBOARDING_BUSINESS_CATEGORY_STEP = "businessCategory";
  static ONBOARDING_EMAIL_STEP = "email";
  static ONBOARDING_PASSWORD_STEP = "password";
  static ONBOARDING_PLAN_STEP = "plan";
  static ONBOARDING_WELCOME_STEP = "welcome";
  static ONBOARDING_PAYMENT_STEP = "payment";
  static ONBOARDING_PREVIOUS_ONBOARDING_STEP = "previousOnboarding";
  static ONBOARDING_USE_OLD_NUMBER_STEP = "useOldNumber";
  static ONBOARDING_REVIEW_STEP = "review";
  static AGUASAFE_COMPANY_ID = "66198e2aa7b11b00080f1ce1";
  static IVENTAS_COMPANY_ID = "5ed824990274390007afcc70";
  static ONBOARDING_FLOW = [
    CONSTANTS.ONBOARDING_NAME_STEP,
    CONSTANTS.ONBOARDING_PHONE_STEP,
    CONSTANTS.ONBOARDING_CODE_STEP,
    CONSTANTS.ONBOARDING_BUSINESS_STEP,
    CONSTANTS.ONBOARDING_WEBSITE_STEP,
    CONSTANTS.ONBOARDING_BUSINESS_CATEGORY_STEP,
    CONSTANTS.ONBOARDING_EMAIL_STEP,
    CONSTANTS.ONBOARDING_PASSWORD_STEP,
    /* CONSTANTS.ONBOARDING_PLAN_STEP, */
    CONSTANTS.ONBOARDING_WELCOME_STEP,
  ];
  static MESSAGE_STATUS = {
    DELIVERED: "Entregado",
    SENT: "Enviado",
    VIEWED: "Visto",
    FAILED: "fallido",
  };
  static STARTER_PLAN = "STARTER";
  static BASIC_PLAN = "BASIC";
  static PRICE_BASIC_PLAN = 0.0499;
  static PRICE_PRO_PLAN = 0.0436;
  static PRO_PLAN = "PRO";
  static PENALITY = "$65.00";
  static SUPERPRO_PLAN = "SUPERPRO";
  static UNLIMITED_PLAN = "UNLIMITED";
  static CUSTOM_PLAN = "CUSTOM";
  static SECONDS_BEFORE_RESEND_CODE = 60;
  static BUSINESS_CATEGORIES = [
    "Servicio automotor",
    "Indumentaria y accesorios",
    "Arte y entretenimiento",
    "Belleza, cosmética y cuidado personal",
    "Educación",
    "Organizador de eventos",
    "Finanzas",
    "Tienda de comestibles",
    "Hotel",
    "Medicina y salud",
    "Organización sin fines de lucro",
    "Restaurante",
    "Compras y ventas minoristas",
    "Viajes y transporte",
    "Otro",
  ];
  static PASSWORD_MIN_LENGTH = 8;
  static PAYMENT_METHOD_CARD = "CARD";
  static PAYMENT_METHOD_TRANSFER = "TRANSFER";
  static WHATSAPP_TEMPLATE_UTILITY = "UTILITY";
  static WHATSAPP_TEMPLATE_MARKETING = "MARKETING";
  static WHATSAPP_TEMPLATE_CUSTOM_TYPE = "CUSTOM_TEMPLATE";
  static WHATSAPP_TEMPLATE_NORMAL_TYPE = "NORMAL_TEMPLATE";
  static TAGS_COLORS = [
    "#f61e44",
    "#a300ff",
    "#00cd7e",
    "#072e93",
    "#ffc730",
    "#cd81fb",
    "#27b1b3",
  ];
}

export default CONSTANTS;
