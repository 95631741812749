import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  CardImage,
  CardImageContainer,
  CardSubtitle,
  CardTitle,
  DisabledCard,
} from "./BroadcastAnalitycCard";
import Button from "../../../../components/shared/components/button/Button.style";
import LoaderComponent from "../../../../components/loader/Loader.component";
import wa_read from './../../../../assets/svg/wa_read.svg';
import wa_delivered from './../../../../assets/svg/wa_delivered.svg';
import wa_sent from './../../../../assets/svg/wa_sent.svg';

const BroadcastAnalitycCard = ({ count, statusTitle, type, isLoading, payload }) => {
  return (
    <Card>
      {type === "status" && (
        <>
          <CardTitle>
            {/* 0 */}
            {isLoading ? <LoaderComponent width="16" height="16" /> : count}
          </CardTitle>
          {payload === 'sent' && (<img src={wa_sent} width={35} style={{ marginBottom: '15px'}}/>)}
          {payload === 'delivered' && (<img src={wa_delivered} width={45} style={{ marginBottom: '15px'}}/>)}
          {payload === 'read' && (<img src={wa_read} width={45} style={{ marginBottom: '15px'}}/>)}
          <CardSubtitle>
            {statusTitle}
            {/* Mensajes enviados */}
          </CardSubtitle>
        </>
      )}
      {type === "error" && (
        <>
          <CardTitle error>
            {/* 0 */}
            {isLoading ? <LoaderComponent width="16" height="16" /> : count}
          </CardTitle>
          <CardSubtitle>
            {statusTitle}
            {/* Mensajes enviados */}
          </CardSubtitle>
          <CardBody>
            Números invalidos o no existen en whatsApp.
          </CardBody>
        </>
      )}
    </Card>
  );
};

BroadcastAnalitycCard.protoType = {
  handleCreateTemplateButton: PropTypes.func,
};

export default BroadcastAnalitycCard;
