import styled, { css } from "styled-components";
import Devices from "../../../../const/devices";

const AppCard = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: ${(props) => (props.padding ? `${props.padding}rem` : "2.5rem")};
  position: relative;
  border-radius: 7px;
  overflow-x: scroll;

  @media ${Devices.Largelaptop} {
    font-size: 1.2rem;
  }

  ${(props) =>
    props.isFromLinkedChannels &&
    css`
      height: 85%;
    `};

  ${(props) =>
    props.newTemplate &&
    css`
      padding: 2rem 1.5rem 0 1.5rem;
    `};
  ${(props) =>
    props.whatsappTemplate &&
    css`
      padding: 2rem 1.5rem 0 1.5rem;
      height: 658px;
    `};

  ${(props) =>
    props.whatsappTemplateMultimedia &&
    css`
      padding: 2rem 1.5rem 0 1.5rem;
      height: 100%;
      max-height: 588px;
      overflow-y: hidden;
    `};
  ${(props) =>
    props.whatsappTemplateCategory &&
    css`
      background-color: #f5f4f6;
      border-radius: 0;
      border: none;
      box-shadow: none;
      margin-top: 0 !important;
      padding-top: 0;
      overflow-y: hidden;
    `};

  ${(props) =>
    (props.BroadcastSelectTemplate || props.BroadcastSelectMultimedia) &&
    css`
      padding: 2rem 1.5rem 0 1.5rem;
      height: 72vh;
      margin-top: 22px;
    `};

  ${(props) =>
    props.broadcastCard &&
    css`
      margin-top: 0px;
      min-height: 75vh;
      overflow-y: hidden;
      border: none;
      background-color: #f5f4f6;
      box-shadow: none;
      padding-left: 5px;
      padding-right: 0;
      border-radius: 0;
    `};

  ${(props) =>
    props.contactView &&
    css`
      padding: 0 2.5rem 2.5rem 2.5rem;
    `};
`;

export default AppCard;
