import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../../components/shared/components/button/Button.style";
import { WhatsappCustomTemplateBtnIcon } from "../../../components/whatsappTemplateSelect/WhatsappTemplateSelect.style";
import {
  DeleteTemplateContainer,
  DeleteTemplateIcon,
  TableContent,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WhatsappTemplateTable,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import { LoaderContainer } from "../../panel/panel.style";
import GeneralModal from "../../../components/shared/components/generalModal/GeneralModal.component";

import whatsappTemplateDeleteIcon from "../../../assets/svg/whatsappTemplateDeleteIcon.svg";
import whatsappTemplateAddIcon from "../../../assets/svg/whatsappTemplateAddIcon.svg";
import {
  deleteCampaignAction,
  setSelectedChannelIdAction,
  getCampaignsByChannelAction,
  setSelectedCampaignAction,
} from "../../../redux/campaignDuck";
import { dateFromNowLongVersion } from "../../../tools/date-utils";
import {
  BroadcastAppCard,
  TableDetailsButton,
  TableHeaderContainer,
  TableHeaderText,
  TableRowActions,
  WhatsappTemplateInputContainer,
  WhatsappTemplateLabel,
  WhatsappTemplateSelect,
} from "./BroadcastView.styles";
import { getWhatsappActiveChannels } from "../../../utils/channels";

const statusLabel = {
  ERROR: "Error",
  FINISHED: "Finalizada",
  IN_PROGRESS: "En progreso",
  SCHEDULED: "Programada",
  PAUSED: "Pausada"
};

const BroadcastTable = ({ qualityRating }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const campaigns = useSelector((state) => state?.campaign?.data);
  const companyId = useSelector((state) => state?.user?.data?.company?.id);
  const selectedChannelId = useSelector(
    (state) => state?.campaign?.data?.create?.channelId
  );

  const [loading, setLoading] = useState(false);
  const [isQualityModalOpen, setIsQualityModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [channels, setChannels] = useState([]); // {id, name, phone}

  const goToCreate = () => {
    if (qualityRating === "Baja" || qualityRating === "Media") {
      setIsQualityModalOpen(true);
      return;
    }
    handleConfirmButton();
  };

  const handleConfirmButton = () => {
    history.push("/broadcast/type");
  };

  const handleDeleteCampaign = async () => {
    setLoadingDelete(true);
    console.log("selectedCampaign", selectedCampaign);
    await dispatch(deleteCampaignAction(selectedCampaign));
    setIsDeleteModalOpen(false);
    setLoadingDelete(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedCampaign(null);
  };

  const setSelectedChannel = (channelId) => {
    dispatch(setSelectedChannelIdAction(channelId));
    dispatch(getCampaignsByChannelAction(channelId));
  };

  const getCampaigns = async () => {
    const channelsFromDb = await getWhatsappActiveChannels(companyId);
    if (channelsFromDb?.length) {
      const channelsFormatted = channelsFromDb.map((x) => ({
        id: x.id,
        name: x.name,
        phone: x.phone,
      }));
      setChannels(channelsFormatted);
      dispatch(setSelectedChannelIdAction(channelsFormatted[0].id));
      dispatch(getCampaignsByChannelAction(channelsFormatted[0].id));
    }
  };

  useEffect(() => {
    if (companyId) getCampaigns();
  }, [companyId]);

  return (
    <>
      {loading && <div>Cargando...</div>}
      {!loading && (
        <BroadcastAppCard>
          <div>
            <TableHeaderContainer>
              <TableHeaderText>
                Envía un mensaje a varias personas al mismo tiempo creando
                campañas. Puedes usar una lista de contactos en un archivo Excel
                o seleccionar grupos específicos para enviar tu mensaje.
              </TableHeaderText>
              <WhatsappTemplateInputContainer>
                <WhatsappTemplateLabel>
                  Selecciona el número
                </WhatsappTemplateLabel>
                <WhatsappTemplateSelect
                  value={selectedChannelId}
                  onChange={(event) => setSelectedChannel(event.target.value)}
                >
                  {channels.map((channel, i) => {
                    return (
                      <option value={channel.id} key={i}>
                        {channel.phone && channel.name !== channel.phone
                          ? channel.name + " - " + channel.phone
                          : channel.name}
                      </option>
                    );
                  })}
                </WhatsappTemplateSelect>
              </WhatsappTemplateInputContainer>
              <Button createTemplate color="primary" onClick={goToCreate}>
                Crear campaña
                <WhatsappCustomTemplateBtnIcon>
                  <img
                    src={whatsappTemplateAddIcon}
                    alt="chat bubble"
                    width="100%"
                  />
                </WhatsappCustomTemplateBtnIcon>
              </Button>
            </TableHeaderContainer>
            <TableContent>
              <WhatsappTemplateTable onClick={() => {}}>
                {false ? (
                  <LoaderContainer width="32px" height="32px" />
                ) : (
                  <>
                    <Thead>
                      <Th left style={{ width: "15%" }}>
                        Nombre de la campaña
                      </Th>
                      <Th left style={{ width: "15%" }}>
                        Etiqueta de campaña
                      </Th>
                      <Th left style={{ width: "5%" }}>
                        Estado
                      </Th>
                      <Th left style={{ width: "10%" }}>
                        Último envío
                      </Th>
                      <Th></Th>
                    </Thead>
                    <Tbody>
                      {campaigns.filter(campaign => campaign?.createdAt).map((broadcast, key) => {
                        return (
                          <Tr key={key}>
                            <Td left style={{ width: "15%"}}>
                              {broadcast.name}
                            </Td>
                            <Td left style={{ width: "15%" }}>
                              {broadcast.selectedTags.reduce(
                                (pV, cV, i) =>
                                  i === 0 ? cV.name : pV + ", " + cV.name,
                                ""
                              )}
                            </Td>
                            <Td left style={{ width: "5%" }}>
                              {statusLabel[broadcast.status]}
                            </Td>
                            <Td left style={{ width: "10%" }}>
                              {broadcast.lastSendAt
                                ? dateFromNowLongVersion(broadcast.lastSendAt)
                                : "Pendiente"}
                            </Td>
                            <Td>
                              <TableRowActions>
                                {/*<span
                                  style={{
                                    fontFamily: "SpaceGrotesk-Medium",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "150%",
                                    letterSpacing: "-0.257238px",
                                    textDecorationLine: "underline",
                                    color: "#128C7E",
                                    cursor: "pointer",
                                  }}
                                >
                                  Editar
                                </span> */}
                                <TableDetailsButton
                                  onClick={() => {
                                    dispatch(
                                      setSelectedCampaignAction(broadcast)
                                    );
                                    history.push("/broadcast/analitycs");
                                  }}
                                >
                                  Ver detalles
                                </TableDetailsButton>
                                <DeleteTemplateContainer
                                  style={{ display: "none" }}
                                  onClick={() => {
                                    setSelectedCampaign(broadcast.id);
                                    setIsDeleteModalOpen(true);
                                  }}
                                >
                                  <DeleteTemplateIcon
                                    src={whatsappTemplateDeleteIcon}
                                  />
                                </DeleteTemplateContainer>
                              </TableRowActions>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </>
                )}
              </WhatsappTemplateTable>
            </TableContent>
          </div>
        </BroadcastAppCard>
      )}

      <GeneralModal
        type="broadcastQualityModal"
        mainText={""}
        show={isQualityModalOpen}
        broadcastquality={qualityRating}
        loadingForm={loadingDelete}
        handleConfirmButton={handleConfirmButton}
        handleCancelButton={() => {
          setIsQualityModalOpen(false);
        }}
      />

      <GeneralModal
        type="general"
        mainText="¿Seguro que quieres eliminar esta campaña?"
        show={isDeleteModalOpen}
        confirmText="Eliminar"
        cancelText="Cancelar"
        handleConfirmButton={handleDeleteCampaign}
        handleCancelButton={handleCloseDeleteModal}
      />
    </>
  );
};

export default BroadcastTable;
