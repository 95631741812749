import React, { useEffect, useState } from "react";
import * as ExcelJS from "exceljs";
import styled, { css } from "styled-components";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import * as Excel from "exceljs";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { toast } from "react-toastify";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import BroadcastSelectTagView from "./../dashboard/BroadcastView/BroadcastSelectTagView";

import { useDispatch, useSelector } from "react-redux";
import {
  createClientInfoAction,
  getConversationsPanel,
  getPlatformApi,
  searchClientByKeywordAction,
  searchClientReportAction,
} from "../../redux/conversationsDuck";
import { getAllAgents } from "../../redux/agentsDuck";
import { getAllTagsByCompany } from "../../redux/tagsDuck";

import AppCard from "../../components/shared/components/appCard/AppCard";
// import Table from "../../components/shared/components/table/Table.component";
import Button from "../../components/shared/components/button/Button.style";
import {
  PanelContainer,
  CalendarSection,
  SearchIcon,
  ButtonActions,
  LoaderContainer,
  ImportButtonContainer,
  ImportIcon,
  ImportIconContainer,
  Table,
  Td,
  Th,
  Tr,
  MoreInfoButton,
  ExportIconContainer,
  ExportIcon,
} from "./panel.style";
import { getJcnError, getLabelMessage } from "../../const/labelService";
import toastConfig from "../../components/shared/constants/toaster-config";
import CONSTANTS from "../../const/constants";
import LoaderComponent from "../../components/loader/Loader.component";
import UploadFile from "../../assets/svg/UploadFile.svg";
import GeneralModal from "../../components/shared/components/generalModal/GeneralModal.component";
import COUNTRY_CODES from "../../utils/countryCodes";
import AddButton from "../../components/shared/components/addButton/addButton.component";
import ReactPaginate from "react-paginate";
import CustomPagination from "../../components/shared/components/CustomPagination/CustomPagination";
import ImportModal from "./importContacModal";
import {
  WhatsappTemplateInputContainer,
  WhatsappTemplateLabel,
  WhatsappTemplateSearchContainer,
  WhatsappTemplateSelect,
} from "../../components/whatsappTemplateSelect/WhatsappTemplateSelect.style";
import Search from "../../components/search/Search";
import { ArrowButton } from "../../components/conversationCard/conversationCardStyle";
import ExportFile from "../../assets/svg/ExportFile.svg";
import { importContacts } from "../../utils/contacts";
import { getClientInformationUtil } from "../../utils/datasheet";
import { groupBy, set } from "lodash";
import ExportContacts from "../../components/contacts/contactsListModal/ExportContacts.component";

const ITEMS_PER_PAGE = 7;

const phoneNumberUtil = PhoneNumberUtil.getInstance();

const clientsFromExcel = [];

const ModalWrapper = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
`;

const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  justify-self: flex-end;
  padding: 0.5rem;
  color: #000000AA;
  font-size: 0.9rem;
  font-weight: 700;
  width: 100%;
  text-align: end;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 60%;
  height: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PanelView = ({ setonCreateNewContact }) => {
  const dispatch = useDispatch();
  const firstEndDate = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const firstInitialDate = moment()
    .startOf("day")
    .subtract(7, "days")
    .format("YYYY-MM-DDTHH:mm:ss");

  const selectedSpaceId = useSelector((state) => state.spaces.selected.id);
  const channels = useSelector((state) => state.spaces.selected.channels);
  const companyName = useSelector((state) => state.user.data.company.name);
  const companyId = useSelector((state) => state.user.data.company.id);
  const [clients, setClients] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [agentsData, setAgentsData] = useState([{id: null, name: "Todos"}]);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [selectedFilterBy, setSelectedFilterBy] = useState('byCreatedAt');

  const [tagsData, setTagsData] = useState([]);
  const [selectedTagsIds, setSelectedTagsIds] = useState([]);

  
  const [searchClients, setSearchClients] = useState([]);
  const [initalDate, setInitalDate] = useState(firstInitialDate);
  const [endDate, setEndDate] = useState(firstEndDate);
  const [filtersState, setFiltersState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [file, setFiles] = useState([]);
  const [channelField, setChannelField] = useState([]);
  const [isAddContactModelOpen, setIsAddContactModelOpen] = useState(false);
  const [platformApi, setPlatformApi] = useState([]);
  const [contactLoadError, setContactLoadError] = useState([]);
  const [successfulContactUpload, setSuccessfulContactUpload] = useState([]);
  const [data, setData] = useState({});
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [importResults, setImportContactStatus] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isImportModalOpen, setsImportModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const agentId = useSelector((state) => state.user?.data?.id);
  const agentName = useSelector((state) => state.user?.data?.name);
  const rolId = useSelector((state) => state.user?.data?.rol?.id);
  const ROYAL_HOLIDAY_ROL_ID = "64d27d7ea7b11b0008795fab";
  const IVENTAS_ROL_ID = "5ed833400274390007afccb3";
  const columns = [];
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [progress, setProgress] = useState(0);
  const [query, setQuery] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimer, setSearchTimer] = useState(null);
  const [isImportContacts, setIsImportContacts] = useState(false);
  const rol = useSelector((state) => state.user?.data?.rol?.name);
  const [showImportOptions, setShowImportOptions] = useState(false);
  let lastPage = 0;

  // Datos de ejemplo
  let rowData = [];

  if (companyName === CONSTANTS.VINTE_COMPANY_NAME) {
    //columns.push({ title: "ID", field: "id" });
    columns.push({ title: "Nombre en canal", field: "nameFromChannel" });
    columns.push({ title: "Nombre", field: "name" });
    columns.push({ title: "Email", field: "email" });
    columns.push({ title: "Canal", field: "channel" });
    columns.push({ title: "Plataforma", field: "plataform" });
    columns.push({ title: "Desarrollo", field: "plaza" });
    columns.push({ title: "Teléfono", field: "number" });
    columns.push({ title: "Fecha de creación", field: "createdAt" });
    columns.push({ title: "Última actualización", field: "lastUpdate" });
  } else {
    //columns.push({ title: "ID", field: "id" });
    columns.push({ title: "Nombre", field: "nameFromChannel" });
    columns.push({ title: "Apellido", field: "lastName" });
    columns.push({ title: "Teléfono", field: "number" });
    columns.push({ title: "Canal", field: "channel" });
    columns.push({ title: "Agente", field: "agent" });
    columns.push({ title: "Etiquetas", field: "tags" });
    columns.push({ title: "Plataforma", field: "plataform" });
    columns.push({ title: "Fecha de creación", field: "createdAt" });
  }

  const labels = [
    "selectChannel",
    "createContactGenericError",
    "createContactDuplicateError",
    "createContactButton",
    "newContactLabel",
    "createContactModalButton",
    "createContactSuccess",
    "btnCancel",
    "contactNoAvailable",
    "whatsappTemplateSelectChannel",
  ];
  const lbls = getLabelMessage(labels);

  const searchData = async (filterContactsBy, currentAgentID, currentSelectedTags, currentSkip) => {
    try {
      setSkip(currentSkip ?? skip);
      setClients([]);
      console.log("skip ====> ", skip);
      console.log('currentSelectedTags => ', currentSelectedTags)
      console.log('selectedAgentIdselectedAgentIdselectedAgentIdselectedAgentId', currentAgentID)
      setIsLoading(true);
      const response = await dispatch(
        searchClientReportAction(
          filterContactsBy,
          initalDate,
          endDate,
          selectedFilters,
          currentSkip,
          rol,
          (currentAgentID || selectedAgentId),
          searchTerm,
          currentSelectedTags
        )
      );

      console.log('>>>>>>>>>>>>>>>>>>>>RESPONSE CONTACTS PANEL => ', response)

      console.log("lastPage", lastPage);
      const isLastPage = lastPage === totalPages - 1;

      const newClients = [...response];
      console.log("newClients", newClients);
      if (companyName === CONSTANTS.VINTE_COMPANY_NAME) {
        const clientsByplace = newClients.filter((client) => {
          return channels.some((channel) => channel.name === client.channel);
        });
        setClients(clientsByplace);
      } else {
        setClients([...response]);
      }

      if (response?.length >= 50) {
        setSkip(skip + 50);
      }

      // Si estabas en la última página, ve a la última página nuevamente
      if (isLastPage) {
        setCurrentPage(lastPage);
      }
    } catch (error) {
      toast.error(getJcnError(error.message), toastConfig);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,selectedSpaceId", selectedSpaceId);
    setClients([]);
    searchData(selectedFilterBy, null, [], 0);
  }, [selectedSpaceId]);

  const handleDateChange = (date) => {
    const initDate = moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    setInitalDate(initDate);
    if (moment(endDate).isAfter(moment(date).add(1, "year"))) {
      setEndDate(moment(date).endOf("day").add(1, "year"));
    }
  };

  const handleEndDate = (date) => {
    const finalDate = moment(date).endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    setEndDate(finalDate);
    if (moment(initalDate).isBefore(moment(date).subtract(1, "year"))) {
      setInitalDate(moment(date).startOf("day").subtract(1, "year"));
    }
  };

  const prepareFilters = async () => {
    const finalFilters = [
      {
        name: "Conversaciones",
        options: [
          { name: "No contestadas", isSelected: false, value: "noAnswered" },
          { name: "Archivadas", isSelected: false, value: "archived" },
          { name: "No Leídas", isSelected: false, value: "unread" },
          {
            name: "Gran Oportunidad",
            isSelected: false,
            value: "isConversationValuable",
          },
        ],
      },
      {
        name: "Canal",
        options: [
          { name: "MESSENGER", isSelected: false, value: "messenger" },
          { name: "WHATSAPP", isSelected: false, value: "whatsapp" },
        ],
      },
    ];

    const users = await dispatch(getAllAgents());
    setAgentsData([{id: null, name: "Todos"}, ...users]);
    finalFilters.push({
      name: "Agente",
      options: users.map((x) => ({
        name: x.name,
        isSelected: false,
        value: x.id,
      })),
    });

    const tags = await dispatch(getAllTagsByCompany());
    setTagsData(tags);

    finalFilters.push({
      name: "Etiquetas",
      options: [
        {
          name: "Difusión",
          isSelected: false,
          value: "broadcast",
        },
        ...tags.map((x) => ({ name: x.name, isSelected: false, value: x.id })),
      ],
    });

    setFiltersState(finalFilters);
  };

  useEffect(() => {
    prepareFilters();
  }, []);

  // const handleOptionClick = (selectedFilterName, option) => {
  //   console.log(selectedFilterName);
  //   console.log(option);
  //   const newFilters = [...filtersState];
  //   const newSectionIndex = newFilters.findIndex(
  //     (x) => x.name === selectedFilterName
  //   );
  //   const newOptionIndex = newFilters[newSectionIndex].options.findIndex(
  //     (x) => x.value === option
  //   );
  //   let newOptions = [...newFilters[newSectionIndex].options];
  //   newOptions[newOptionIndex] = {
  //     ...newOptions[newOptionIndex],
  //     isSelected: !newOptions[newOptionIndex].isSelected,
  //   };
  //   newFilters[newSectionIndex].options = newOptions;
  //   setFiltersState(newFilters);
  // };

  // const applyFilters = async (filters) => {
  //   try {
  //     setIsLoading(true);
  //     let noAnswered = false;
  //     let archived = false;
  //     let unread = false;
  //     let whatsappChannel = false;
  //     let messengerChannel = false;
  //     let broadcast = null;
  //     let isConversationValuable = null;
  //     const otherFilters = filters.find(
  //       (x) => x.name === "Conversaciones"
  //     )?.options;
  //     if (otherFilters?.length > 0) {
  //       noAnswered = !!otherFilters?.find((x) => x === "noAnswered");
  //       archived = !!otherFilters?.find((x) => x === "archived");
  //       unread = !!otherFilters?.find((x) => x === "unread");
  //       isConversationValuable = !!otherFilters?.find(
  //         (x) => x === "isConversationValuable"
  //       );
  //     }
  //     const channelFilters = filters.find((x) => x.name === "Canal")?.options;
  //     if (channelFilters?.length > 0) {
  //       messengerChannel = !!channelFilters?.find((x) => x === "messenger");
  //       whatsappChannel = !!channelFilters?.find((x) => x === "whatsapp");
  //     }
  //     const agentFilters = filters.find((x) => x.name === "Agente")?.options;
  //     const tagsFilters = filters.find((x) => x.name === "Etiquetas")?.options;
  //     console.log("tagsFilters", tagsFilters);
  //     if (tagsFilters?.length > 0) {
  //       const broadcastIndex = tagsFilters?.findIndex((x) => x === "broadcast");
  //       if (broadcastIndex !== -1) {
  //         broadcast = !!tagsFilters[broadcastIndex];
  //         tagsFilters.splice(broadcastIndex, 1);
  //       }
  //     }

  //     const _filters = {
  //       noAnswered,
  //       archived,
  //       unread,
  //       whatsappChannel,
  //       messengerChannel,
  //       agents: agentFilters,
  //       broadcast,
  //       tags: tagsFilters,
  //       isConversationValuable,
  //     };
  //     setSelectedFilters(_filters);

  //     const response = await dispatch(
  //       getConversationsPanel({
  //         selectedFilterBy,
  //         initialDate: initalDate,
  //         endDate,
  //         ..._filters,
  //       })
  //     );


  //     setClients(response?.data);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const clearFilters = () => {
  //   prepareFilters();
  // };

  const onSelectAgent = async (newAgentID) => {
    console.log("onSelectAgent", newAgentID);
    setSelectedAgentId(newAgentID)
    searchData(selectedFilterBy,newAgentID,selectedTagsIds ,0);
  } 

  const onSelectContactsFilter = async (filterBy) => {
    console.log("onSelectContactsFilter", filterBy);
    setSelectedFilterBy(filterBy)
    searchData(filterBy, selectedAgentId, selectedTagsIds ,0);
  } 

  const handleQueryChange = (event) => {
    if (event.target.value === "") {
      setSearchClients([]);
      setSearchTerm("");
      setIsLoading(false);
      setQuery("");
      event.target.value = "";
      return;
    }
    setQuery(event.target.value);
    setIsLoading(true);
    const value = event.target.value;
    setSearchTerm(value);

    // Limpiar el temporizador existente si hay uno
    if (searchTimer) {
      clearTimeout(searchTimer);
    }

    const newTimer = setTimeout(async () => {
      const findContact = await dispatch(searchClientByKeywordAction(selectedFilterBy, value, selectedAgentId, 0));

      console.log("findContact", findContact);
      let clientInfoByClient = [];
      if (companyName !== CONSTANTS.VINTE_COMPANY_NAME) {
        const getClientInformation = async (clientId) => {
          const clientInfo = [];
          const data = await getClientInformationUtil(clientId);
          console.log("data", data);
          console.log("clientInfoes", data.clientInfoes);
          // // const info = data?.clientInfo;
          for await (const info of data?.clientInfoes) {
            console.log("info", info);
            if (info?.value)
              clientInfo.push({
                name: info?.fieldName?.name,
                value: info?.value,
              });
          }
          console.log("clientInfo", clientInfo);
          return clientInfo;
        };

        const clientInfoes = await Promise.all(
          clients.map(async (client) => {
            const info = await getClientInformation(client?.id);
            return { id: client?.id, info };
          })
        );

        console.log("clientInfoesN", clientInfoes);

        clientInfoByClient = groupBy(clientInfoes, (y) => y.id);
        console.log("clientInfo", clientInfoByClient);
      }

      const newClients = findContact.map((x) => ({
        nameFromChannel: x.nameFromChannel,
        lastName: x.lastName,
        number: x.senderId,
        channel: x.channel?.name,
        agent: x.agent?.name,
        tags: x.tags.map((y) => y.name).join(", "),
        plaza: x.plaza,
        createdAt: x.createdAt,
        lastUpdate: x.lastUpdate,
        plataform: x.plataform?.name,
        clientInfo: clientInfoByClient[x?.id]
          ? clientInfoByClient[x?.id][0].info
          : [],
      }));
      console.log("newClients", newClients);
      newClients.reverse();
      console.log("reverseNewClients ==== > ", newClients);
      setSearchClients(newClients);
      setIsLoading(false);
    }, 900); // Retardo de 500ms antes de realizar la búsqueda
    setSearchTimer(newTimer);
  };

  const saveClients = async (file) => {
    try {
      const clientInfo = { ...data };
      console.log("clientInfo:", clientInfo);

      const formData = new FormData();
      console.log("fileSave:", file);
      formData.append("file", file);

      const selectedChannel = clientInfo?.selectChannel
        ? platformApi.find(
            (channel) => channel?.id === clientInfo?.selectChannel
          )
        : platformApi.find((channel) => channel?.isActive);

      const channelId = clientInfo?.selectChannel || selectedChannel?.id;
      formData.append("channelId", channelId);
      formData.append("companyId", companyId);
      formData.append("agentId", agentId);
      console.log("companyId:", companyId);
      console.log("formData:", formData);
      const status = await importContacts(formData, setProgress);
      console.log("status:", status);
      if (status?.newClientsDataResult?.length !== 0) {
        const newClients = [...clients, ...status?.newClientsDataResult];
        const reverseNewClients = newClients.reverse();
        setClients(reverseNewClients);
      }
      setImportContactStatus(status?.finalStatus);
      setIsLoading(false);
      setIsSuccessModalOpen(true);
      setsImportModalOpen(false);
      // Handle the response as needed
    } catch (error) {
      // Handle errors
    }
  };

  const onCreateNewContacts = async (data) => {
    setsImportModalOpen(true);
    setData(data);
  };

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setIsLoading(true);
      saveClients(file);
    }
  };

  const handleCloseModal = async () => {
    console.log("handleCloseModal ==========> ");
    setIsAddContactModelOpen(false);
    setIsSuccessModalOpen(false);
    setImportContactStatus([]);
  };

  const isDate = (value) => {
    return value instanceof Date && !isNaN(value);
  };

  const getChannelsPlatformApi = async () => {
    const activeWAChannels = await getPlatformApi(companyId);
    console.log("USE: ", activeWAChannels);
    if (activeWAChannels?.length >= 1) {
      const select = [
        {
          name: "selectChannel",
          label: lbls.selectChannel,
          type: "select",
          options: activeWAChannels.map((x) => ({
            value: x.id,
            text: x.name,
            isDisabled: false,
          })),
        },
      ];
      console.log("SELECT:", select);
      setChannelField(select);
      console.log("CREATECHJANEL: ", channelField);
    }

    setPlatformApi(...platformApi, activeWAChannels);
    console.log(platformApi);
  };

  const openModalInfo = () => {
    setIsInfoModalOpen(true);
  };
  const openModalChannels = async () => {
    setIsInfoModalOpen(false);
    setIsAddContactModelOpen(true);
  };
  const closeModals = () => {
    setsImportModalOpen(false);
    setIsAddContactModelOpen(false);
    setIsLoading(false);
  };

  const onSelectTags = async (params) => {
    console.log('onSelectTags =>', params)
    setSelectedTagsIds([...params])
    setModalIsOpen(false);
    searchData(selectedFilterBy, selectedAgentId, [...params], 0)
  }

  const handlePageChange = async ({ selected }) => {
    // const totalPages = Math.ceil(clients?.length / ITEMS_PER_PAGE);
    console.log("selected", selected);

    console.log("totalPages", totalPages);

    // Comprueba si la página actual es igual a la última página
    const isLastPage = selected === totalPages - 1;

    if (isLastPage && (clients?.length >= 50 || searchClients?.length >= 50)) {
      setIsLoadingMore(true);
      lastPage = selected;
      setSkip(skip + 50);
      searchData(selectedFilterBy, selectedAgentId, selectedTagsIds, skip + 50);
    }
    setCurrentPage(selected);
    return selected;
  };

  useEffect(() => {
    getChannelsPlatformApi();
    console.log(platformApi);
  }, []);

  const [openRowIndex, setOpenRowIndex] = useState(-1);

  useEffect(() => {
    // Lógica para filtrar y paginar los datos aquí
    let filteredAndPaginatedData = [];
    if (query !== "") {
      console.log("searchClients", searchClients);
      searchClients.reverse();
      filteredAndPaginatedData = searchClients?.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
    } else {
      filteredAndPaginatedData = clients?.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
    }

    setFilteredData(filteredAndPaginatedData);
  }, [currentPage, clients, searchClients]);

  useEffect(() => {
    // Calcular el número total de páginas
    if (query !== "") {
      console.log("searchClientsPage", searchClients);
      const totalPages = Math.ceil(searchClients?.length / ITEMS_PER_PAGE);
      console.log("totalPages", totalPages);
      setTotalPages(totalPages);
    } else {
      const totalPages = Math.ceil(clients?.length / ITEMS_PER_PAGE);
      console.log("totalPages", totalPages);
      setTotalPages(totalPages);
    }
  }, [clients, searchClients]);

  useEffect(() => {
    // TODO: wait for Vinte requirement
    // if (companyName !== CONSTANTS.VINTE_COMPANY_NAME) {
    //   setShowImportOptions(true);
    //   return;
    // }

    // if (
    //   rol === CONSTANTS.ADMIN ||
    //   rol === CONSTANTS.SUPERADMIN ||
    //   rol === CONSTANTS.VINTE_COMMUNITY_MANAGER
    // ) {
    //   setShowImportOptions(true);
    //   return;
    // }

    // setShowImportOptions(false);
    setShowImportOptions(true);
  }, [rol, companyName]);

  return (
    <>
      <PanelContainer>
        <AppCard contactView>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              padding: "1rem 0",
            }}
          >
            {(rol === CONSTANTS.ADMIN ||
              rol === CONSTANTS.SUPERADMIN ||
              rol === CONSTANTS.VINTE_COMMUNITY_MANAGER) && (
              <ExportContacts filterByContacts={selectedFilterBy} tagsIDS={selectedTagsIds} agentID={selectedAgentId} initialDate={initalDate} endDate={endDate} keyword={query} />
            )}
            {showImportOptions && (
              <>
              <ImportButtonContainer>
                <Button importButton color="primary" onClick={openModalInfo}>
                  <ImportIconContainer>
                    <ImportIcon src={UploadFile} />
                  </ImportIconContainer>
                  Importar
                </Button>
              </ImportButtonContainer>
              <AddButton
                label={lbls.createContactButton}
                onClick={() => setonCreateNewContact(true)}
              />
              </>
            )}
          </div>

          
          <div style={{
            width: '100%',
            display: 'flex',
            gap: '20px'
          }}>

              <WhatsappTemplateInputContainer
                style={{
                  marginTop: "1.2rem",
                  width: "25rem"
                }}
              >
                <WhatsappTemplateLabel
                  style={{
                    backgroundColor: "#f5f4f6",
                  }}
                >
                  {"Filtrar contactos por"}
                </WhatsappTemplateLabel>
                <WhatsappTemplateSelect
                  value={selectedFilterBy}
                  onChange={(event) => onSelectContactsFilter(event.target.value)}
                >
                  <option value="byCreatedAt">Últimos Creados</option>
                  <option value="bylastUpdated">Últimos Activos</option>
                </WhatsappTemplateSelect>
              </WhatsappTemplateInputContainer>

              <CalendarSection>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    autoOk
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-init"
                    label="Fecha inicial"
                    value={initalDate}
                    maxDate={endDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    autoOk
                    id="date-picker-end"
                    label="Fecha final"
                    value={endDate}
                    minDate={initalDate}
                    maxDate={moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss")}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>

                <ButtonActions>
                  <Button
                    small
                    color="primary"
                    onClick={() => {
                      setSkip(0);
                      searchData(selectedFilterBy, selectedAgentId, selectedTagsIds, 0);
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </ButtonActions>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  { /* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      border: "2px solid #128C7E",
                      width: "13%",
                      height: "2.75rem",
                    }}
                  >
                    <span>Filtros</span> <ArrowButton isFilter />
                  </div> */ }
                  {/* <WhatsappTemplateInputContainer style={{ marginRight: "2.5rem" }}>
                    <WhatsappTemplateLabel>
                      {lbls.whatsappTemplateSelectChannel}
                    </WhatsappTemplateLabel>
                    <WhatsappTemplateSelect
                      value={selectedChannel}
                      onChange={(event) => setSelectedChannel(event.target.value)}
                    >
                      {channelField[0]?.options.map((channel, i) => {
                        return (
                          <option value={channel.value} key={i}>
                            {channel.text}
                          </option>
                        );
                      })}
                    </WhatsappTemplateSelect>
                  </WhatsappTemplateInputContainer> */}

                  <WhatsappTemplateSearchContainer contacView>
                    <Search
                      handleSearch={handleQueryChange}
                      value={query}
                      templateSelection
                    />
                  </WhatsappTemplateSearchContainer>
                </div>
              </CalendarSection>

          </div>

          

          <div style={{
            width: '100%',
            display: 'flex',
            gap: '20px'
          }}>

            

            {(rol === CONSTANTS.ADMIN ||
              rol === CONSTANTS.SUPERADMIN ||
              rol === CONSTANTS.VINTE_COMMUNITY_MANAGER) && 
                ( 
                  <WhatsappTemplateInputContainer
                    style={{
                      marginTop: "0.875rem",
                      width: "25rem"
                    }}
                  >
                    <WhatsappTemplateLabel
                      style={{
                        backgroundColor: "#f5f4f6",
                      }}
                    >
                      {"Selecciona un agente"}
                    </WhatsappTemplateLabel>
                    <WhatsappTemplateSelect
                      value={selectedAgentId}
                      onChange={(event) => onSelectAgent(event.target.value)}
                    >
                      {agentsData.map((agent, i) => {
                        return (
                          <option value={agent.id} key={i}>
                            {agent.name}
                          </option>
                        );
                      })}
                    </WhatsappTemplateSelect>
                  </WhatsappTemplateInputContainer>
                )
            }

            

            <div style={{
              marginTop: "0.8rem",
            }}>
              <Button color="primary" onClick={() => {
                setModalIsOpen(true)
              }}>
                Seleccionar Etiquetas
              </Button> 
            </div>
            {selectedTagsIds?.length > 0 && (
              <div style={{
                width: '25px',
                height: '25px',
                color: 'white',
                background: '#128c7e',
                fontWeight: 700,
                borderRadius: '50%',
                textAlign: 'center',
                alignContent: 'center',
                marginTop: '1.5rem'
              }}>
                {selectedTagsIds?.length}
              </div>
            )}
          </div>

          {isLoading && (
            <LoaderContainer>
              <LoaderComponent width={50} height={50} />
            </LoaderContainer>
          )}
          {!isLoading && (
            <>
              <Table
                style={{
                  marginTop: "1rem",
                }}
              >
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <Th key={column.field}>{column.title}</Th>
                    ))}
                    {
                      companyName !== CONSTANTS.VINTE_COMPANY_NAME && (
                        <Th>Más información</Th>
                      ) // Si no es Vinte, muestra la columna de más información
                    }
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.length > 0 ? (
                    filteredData?.map((row, index) => (
                      <>
                        <Tr
                          selected={index === openRowIndex}
                          isEven={index % 2 !== 0}
                          key={index}
                        >
                          {columns.map((column) => (
                            <Td key={column.field}>{row[column.field]}</Td>
                          ))}

                          {companyName !== CONSTANTS.VINTE_COMPANY_NAME && (
                            <Td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "none",
                              }}
                            >
                              {row?.clientInfo?.length > 0 ? (
                                <MoreInfoButton
                                  onClick={() => {
                                    setShowMoreInfo(!showMoreInfo);
                                    setOpenRowIndex(
                                      index === openRowIndex ? -1 : index
                                    );
                                  }}
                                >
                                  {showMoreInfo && index === openRowIndex
                                    ? "Ocultar"
                                    : "Mostrar"}
                                </MoreInfoButton>
                              ) : (
                                "No hay información adicional"
                              )}
                            </Td>
                          )}
                        </Tr>
                        {index === openRowIndex && (
                          <tr>
                            <td colSpan="10">
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <Table>
                                  {" "}
                                  {/* Tabla interna */}
                                  <tbody>
                                    <tr>
                                      <Th>Nombre de campo</Th>
                                      <Th>Valor</Th>
                                    </tr>
                                    {row?.clientInfo?.map((item, itemIndex) => (
                                      <tr key={itemIndex}>
                                        <Td style={{ fontWeight: "bold" }}>
                                          {item?.name}
                                        </Td>

                                        {item?.value instanceof Date && (
                                          <Td>
                                            {moment(item?.value)
                                              .local()
                                              .format("DD/MM/YYYY HH:mm z")}
                                          </Td>
                                        )}
                                        {!(item?.value instanceof Date) && (
                                          <Td>{item?.value}</Td>
                                        )}
                                        {/* <Td>{item?.value}</Td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={columns.length + 1}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                          }}
                        >
                          <span>{"Sin contactos"}</span>
                        </div>
                      </Td>
                    </Tr>
                  )}
                </tbody>
              </Table>
              <CustomPagination
                pageCount={totalPages}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </>
          )}
        </AppCard>
      </PanelContainer>
      <ModalWrapper isOpen={modalIsOpen}>
        <ModalContent>
          <CloseBtn type="button" onClick={() => {
            setModalIsOpen(false)
          }}>
            Cerrar X
          </CloseBtn>
          <h1>Selección de etiquetas</h1>
          {modalIsOpen && (
            <BroadcastSelectTagView
              accountLimitOfMessages={0}
              isFromContacts={true}
              companyId={companyId}
              parentCallAction={onSelectTags}
              globalTags={tagsData}
              selectedTags={selectedTagsIds}
            />
          )}
        </ModalContent>
      </ModalWrapper>
      <GeneralModal
        type="general"
        show={isAddContactModelOpen}
        mainText={"Selecciona un canal para importar tus contactos"}
        handleCancelButton={() => setIsAddContactModelOpen(false)}
        cancelText={lbls.btnCancel}
        handleConfirmButton={onCreateNewContacts}
        confirmText={"Continuar"}
        arrayForm={channelField}
        fixed
        dynamicFields={channelField}
        loadingForm={isLoading}
        isImportContact
      />
      <GeneralModal
        type="importContactInfo"
        mainText={lbls.createContactSuccess}
        show={isInfoModalOpen}
        handleConfirmButton={openModalChannels}
        handleCancelButton={() => setIsInfoModalOpen(false)}
        cancelText={lbls.btnCancel}
      />
      <GeneralModal
        type="importContactsSuccess"
        mainText={lbls.createContactSuccess}
        show={isSuccessModalOpen}
        successImportContac={importResults}
        errorImportContac={contactLoadError}
        handleCloseModal={handleCloseModal}
      />
      {/* <ImportModal
        importResults={importResults}
        isOpen={isSuccessModalOpen}
        onRequestClose={handleCloseModal}
      /> */}
      <GeneralModal
        type="importContact"
        mainText={lbls.createContactSuccess}
        secundaryText={
          "Recuerda seguir las instrucciones anteriores para que tus contactos se importen correctamente"
        }
        show={isImportModalOpen}
        progress={progress}
        loadingForm={isLoading}
        handleCancelButton={closeModals}
        handleConfirmButton={onDrop}
        cancelText={lbls.btnCancel}
      />
    </>
  );
};

export default PanelView;
