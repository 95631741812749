import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from './Button.style';
import LoaderComponent from '../../../loader/Loader.component';

const Button = ({
  type,
  onClick,
  disabled,
  text,
  loading,
  color = "primary",
  onboarding,
  full
}) => (
  <StyledButton
    type={type}
    onClick={onClick}
    disabled={disabled}
    color={color}
    onboarding={onboarding}
    full={full}
  >
    {loading ? <LoaderComponent width={15} height={15} /> : <span>{text}</span>}
  </StyledButton>
);

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  color: 'primary',
};

export default Button;
