import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 72vh;
  display: flex;
  justify-content: space-between;
  padding: 22px;
`;

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 1rem  1.5rem;
`;
export const CardTitle = styled.span`
  font-family: "SpaceGrotesk-Bold", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 150%;
  /* or 63px */

  text-align: start;
  letter-spacing: -0.257238px;

  color: #128c7e;

  ${(props) =>
    props.error &&
    css`
      color: #ff1717;
    `}
`;
export const CardBody = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
  width: 85%;
  padding-top: 0.5rem;
  /* or 171% */

  letter-spacing: -0.32px;

  color: #757575;
`;
export const CardSubtitle = styled.span`
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 100%;
  /* identical to box height, or 27px */

  letter-spacing: -0.257238px;

  color: #2e3353;
`;
export const CardImageContainer = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  padding-top: ${(props) => (props.category ? "2.625rem" : "2rem")};
  margin-bottom: ${(props) => (props.customTemplate ? "5rem" : "3rem")};

  ${(props) =>
    props.selected &&
    css`
      margin-bottom: 1.875rem;
    `}
`;
export const CardImage = styled.img`
  width: 100%;
`;

export const DisabledCard = styled.div`
  width: 19.25rem;
  height: 26.313rem;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 22px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

export const BackButton = styled.div`
  position: absolute;
  bottom: ${(props) => (!props.templateAction ? "0" : "30px")};
  left: ${(props) => (!props.templateAction ? "0" : "24px")};
  width: 11.5rem;
  height: 1.375rem;
  cursor: pointer;
  display: flex;
  z-index: 1000;
  align-items: center;
`;
