import gql from 'graphql-tag';

const GET_ONBOARDING_BY_EMAIL = gql`
  query onboardingInProcesses($email: String!) {
    onboardingInProcesses(where: { email: $email }, first: 1) {
      id
      email
      name
      planName
      step
      businessName
      businessCategory
      businessWeb
    }
  }
`;

const CREATE_ONBOARDING = gql`
  mutation createOnboarding(
    $email: String,
  ) {
    createOnboardingInProcess(
      data: {
        email: $email
      }
    ) {
      id
    }
  }
`;

const UPDATE_SCORE = gql`
  mutation updateOnboardingInProcess(
    $id: ID!,
    $score: Float,
  ) {
    updateOnboardingInProcess(
      where: { id: $id}
      data: {
        score: $score,
      }
    ) {
      id
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation updateOnboardingInProcess(
    $id: ID!,
    $location: String,
  ) {
    updateOnboardingInProcess(
      where: { id: $id}
      data: {
        location: $location,
      }
    ) {
      id
    }
  }
`;

const UPDATE_CONNSPEED = gql`
  mutation updateOnboardingInProcess(
    $id: ID!,
    $connSpeed: Float,
  ) {
    updateOnboardingInProcess(
      where: { id: $id}
      data: {
        connSpeedMBps: $connSpeed,
      }
    ) {
      id
    }
  }
`;

const UPDATE_STEP = gql`
  mutation updateOnboardingInProcess(
    $id: ID!,
    $step: String,
  ) {
    updateOnboardingInProcess(
      where: { id: $id}
      data: {
        step: $step,
      }
    ) {
      name
    }
  }
`;

const UPDATE_USER_INFO = gql`
  mutation updateOnboardingInProcess(
    $id: ID!,
    $device: String,
    $location: String,
    $connSpeed: Float
    
  ) {
    updateOnboardingInProcess(
      where: { id: $id}
      data: {
        device: $device
        connSpeedMBps: $connSpeed,
        location: $location
      }
    ) {
      device
      location
    }
  }
`;

const ApolloOnboarding = {
  GET_ONBOARDING_BY_EMAIL,
  CREATE_ONBOARDING,
  UPDATE_SCORE,
  UPDATE_LOCATION,
  UPDATE_STEP,
  UPDATE_CONNSPEED,
  UPDATE_USER_INFO
};

export default ApolloOnboarding;
