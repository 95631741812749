import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getWhatsappTemplatesByChannelAction } from "../../../redux/whatsappTemplateDuck";

import parse from "html-react-parser";

import { getLabelMessage } from "../../../const/labelService";

import {
  WhatsappTemplateInputContainer,
  WhatsappTemplateLabel,
  WhatsappTemplateSelect,
  SelectTemplateContainer,
  SelectTemplateHead,
  WhatsappTemplateSearchContainer,
  WhatsappTemplateBody,
  BroadcastAppCard,
} from "./BroadcastView.styles";

import Search from "../../../components/search/Search";
import LoaderComponent from "../../../components/loader/Loader.component";
import Button from "../../../components/shared/components/button/Button.style";
import { setSelectedTemplateAction } from "../../../redux/campaignDuck";
import { BotInfo } from "../../settings/autoresponses/AutoResponses.style";
import { NewWhatsappTitle } from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  CurrentTime,
  WhatsappCustomTemplateBtnIcon,
  WhatsappTemplateContainer,
  WhatsappTemplateDescription,
  WhatsappTemplateDescriptionContainer,
  WhatsappTemplateDescriptionText,
  WhatsappTemplateDescriptionTitle,
  WhatsappTemplateDetails,
} from "../../../components/whatsappTemplateSelect/WhatsappTemplateSelect.style";
import { PreviewImageContainer } from "../../settings/whatsappTemplates/whatsappTemplateMessagePreview/whatsappTemplateMessagePreview";
import CONSTANTS from "../../../const/constants";
import {
  PreviewHeader,
  PreviewIcon,
} from "../../../components/shared/components/dropZoneImage/DropZoneImage.style";
import PdfPreview from "../../../components/album/filePreview/pdfPreview/PdfPreview.component";
import { InputError } from "../../../components/stripeInput/StripeInput.style";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import fileIcon from "../../../assets/svg/fileIcon.svg";
import moment from "moment";
import MessageViewed from "../../../components/messages/messageViewed/MessageViewed";
import ChatBubble from "../../../assets/svg/ChatBubble.svg";

const BroadcastSelectTemplate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const channelId = useSelector((state) => state.campaign.create.channelId);
  const whatsappTemplates = useSelector(
    (state) => state.whatsappTemplates.data
  );
  const [messageError] = useState("");
  const [whatsappTemplatesDisplayed, setWhatsappTemplatesDisplayed] = useState(
    []
  );
  const labels = ["broadcastSelectTemplateTitle", "whatsappTemplateCategory"];
  const lbls = getLabelMessage(labels);

  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [formatedSearch, setFormatedSearch] = useState("");
  const [category, setCategory] = useState("");
  let fileType = "";

  const setVariablesContent = (text, example) => {
    let contentMessage = "";
    const formatedText = text
      .replace(/\n/g, "<br>")
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
      .replace(/_(.*?)_/g, "<em>$1</em>")
      .replace(/~(.*?)~/g, "<del>$1</del>");
    if (example) {
      contentMessage = formatedText;
      example.forEach((element, i) => {
        console.log(element);
        contentMessage = contentMessage?.replace(
          /\{\{\d\}\}/,
          `<span class="select-var">${element}</span>`
        );
      });
      console.log(contentMessage);
      return contentMessage;
    }

    return formatedText;
  };

  const getFileType = (fileUrl) => {
    let type = fileUrl.split(".");
    console.log("type: ", type);
    type = type.pop();
    console.log("typeFinal: ", type);
    switch (type) {
      case "jpg":
        fileType = "JPG";
        break;
      case "png":
        fileType = "PNG";
        break;
      case "mp4":
        fileType = "MP4";
        break;
      case "docx":
        fileType = CONSTANTS.FILE_TYPE_PPTX;
        break;
      case "xlsx":
        fileType = CONSTANTS.FILE_TYPE_DOCX;
        break;
      case "pptx":
        console.log("CONSTANTS.FILE_TYPE_XLSX: ", CONSTANTS.FILE_TYPE_XLSX);
        fileType = CONSTANTS.FILE_TYPE_XLSX;
        break;
      case "pdf":
        console.log("PDF ====> ", type);
        fileType = CONSTANTS.FILE_TYPE_PDF;
        break;

      default:
        break;
    }
  };

  const setSelectTemplate = (template) => {
    dispatch(setSelectedTemplateAction(template));
    if (template.components[0]?.exampleHeader?.header_handle?.length > 0) {
      history.push("/broadcast/selectMultimedia");
    } else {
      console.log("template: ", template);
      history.push("/broadcast/selectDate");
    }
  };

  const handleCategorySelect = (option) => {
    setCategory(option.target.value);
  };

  const handleQueryChange = (event) => {
    const currentQuery = event.target.value || "";
    const formatedQuery = currentQuery
      ?.trim()
      ?.toLowerCase()
      ?.normalize("NFD")
      ?.replace(/[\u0300-\u036f]/g, "")
      ?.replace(/\s+/g, "")
      ?.replace(/_/g, "");
    setSearch(currentQuery);
    setFormatedSearch(formatedQuery);
  };

  const filterWhatsappTemplates = () => {
    setIsLoading(true);
    const filteredWhatsappTemplates = whatsappTemplates?.filter((x) => {
      const hasVariables = x.components[0]?.exampleBody?.body_text?.length;
      if (hasVariables) return false;

      if (
        category === CONSTANTS.MARKETING_TEMPLATE_CATEGORY &&
        x.category !== CONSTANTS.MARKETING_TEMPLATE_CATEGORY
      )
        return false;
      if (
        category === CONSTANTS.UTILITY_TEMPLATE_CATEGORY &&
        x.category !== CONSTANTS.UTILITY_TEMPLATE_CATEGORY
      )
        return false;

      let includesSearchQuery = false;
      if (formatedSearch === "") includesSearchQuery = true;
      else {
        includesSearchQuery =
          x.name
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/\s+/g, "")
            ?.replace(/_/g, "")
            ?.includes(formatedSearch) ||
          x.components?.[0]?.text
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.replace(/\s+/g, "")
            ?.replace(/_/g, "")
            ?.includes(formatedSearch);
      }
      if (!includesSearchQuery) return false;

      return true;
    });
    setWhatsappTemplatesDisplayed(filteredWhatsappTemplates);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    filterWhatsappTemplates();
  }, [formatedSearch, category]);

  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
    dispatch(getWhatsappTemplatesByChannelAction(channelId));
  }, [channelId]);

  useEffect(() => {
    filterWhatsappTemplates();
  }, [whatsappTemplates]);

  return (
    <BroadcastAppCard
      style={{
        gap: "20px",
      }}
    >
      <BotInfo
        newTemplate
        style={{
          flexGrow: "1",
        }}
      >
        <NewWhatsappTitle>{lbls.broadcastSelectTemplateTitle}</NewWhatsappTitle>
        <div style={{ position: "relative" }}>
          <SelectTemplateContainer>
            <SelectTemplateHead>
              <WhatsappTemplateSearchContainer>
                <Search
                  handleSearch={handleQueryChange}
                  value={search}
                  templateSelection
                  label={"Buscar plantilla"}
                />
              </WhatsappTemplateSearchContainer>
              <WhatsappTemplateInputContainer>
                <WhatsappTemplateLabel>
                  {lbls.whatsappTemplateCategory}
                </WhatsappTemplateLabel>
                <WhatsappTemplateSelect
                  onChange={(e) => {
                    handleCategorySelect(e);
                  }}
                >
                  <option value="all">Todas</option>
                  <option value={CONSTANTS.MARKETING_TEMPLATE_CATEGORY}>
                    Avisos, marketing y promociones
                  </option>
                  <option value={CONSTANTS.UTILITY_TEMPLATE_CATEGORY}>
                    Actualización de estatus de un evento o transacción
                  </option>
                </WhatsappTemplateSelect>
              </WhatsappTemplateInputContainer>
            </SelectTemplateHead>
            <WhatsappTemplateBody>
              {isLoading ? (
                <LoaderComponent width="22" height="22" />
              ) : (
                <>
                  {whatsappTemplatesDisplayed.map((x) => (
                    <WhatsappTemplateContainer key={x.id}>
                      <WhatsappTemplateDescription>
                        <WhatsappTemplateDescriptionTitle>
                          {x.name}
                        </WhatsappTemplateDescriptionTitle>
                        <WhatsappTemplateDescriptionContainer>
                          {x.components[0]?.exampleHeader?.header_handle
                            .length > 0 && (
                            <PreviewImageContainer>
                              {getFileType(
                                x.components[0]?.exampleHeader?.header_handle[0]
                              )}
                              {(fileType === "JPG" || fileType === "PNG") && (
                                <PreviewHeader
                                  alt="preview"
                                  width="100%"
                                  height="100%"
                                  image
                                  messagePreview
                                  style={{
                                    backgroundImage: `url(${x.components[0]?.exampleHeader?.header_handle[0]})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                />
                              )}
                              {fileType === "MP4" && (
                                <PreviewHeader doc messagePreview>
                                  <video controls width="100%" height="100%">
                                    <source
                                      src={
                                        x.components[0]?.exampleHeader
                                          ?.header_handle[0]
                                      }
                                      type="video/mp4"
                                    />
                                    <p>
                                      Tu navegador no admite el elemento video.
                                    </p>
                                  </video>
                                </PreviewHeader>
                              )}
                              {(fileType === "PDF" ||
                                fileType === "XLSX" ||
                                fileType === "PPTX" ||
                                fileType === "TXT") && (
                                <PreviewHeader doc messagePreview>
                                  {fileType === "PDF" && (
                                    <PdfPreview
                                      url={
                                        x.components[0]?.exampleHeader
                                          ?.header_handle[0]
                                      }
                                      templateMedia
                                      parentWidth={50}
                                    />
                                  )}
                                  {fileType !== "PDF" && (
                                    <PreviewIcon
                                      src={fileIcon}
                                      width={50}
                                      height={50}
                                    />
                                  )}
                                </PreviewHeader>
                              )}
                            </PreviewImageContainer>
                          )}
                          <WhatsappTemplateDescriptionText>
                            {parse(
                              setVariablesContent(
                                x.components[0].text,
                                x.components[0]?.exampleBody?.body_text
                              )
                            )}
                            <WhatsappTemplateDetails>
                              <CurrentTime>
                                {moment().format("HH:mm")}
                              </CurrentTime>
                              <MessageViewed
                                messageStatus={"viewed"}
                                templateSelect
                              />
                            </WhatsappTemplateDetails>
                          </WhatsappTemplateDescriptionText>
                        </WhatsappTemplateDescriptionContainer>
                      </WhatsappTemplateDescription>

                      <div>
                        <Button
                          templateSelect
                          color="primary"
                          onClick={() => {
                            setSelectTemplate(x);
                          }}
                        >
                          Seleccionar Plantilla
                          <WhatsappCustomTemplateBtnIcon>
                            <img
                              src={ChatBubble}
                              alt="chat bubble"
                              width="100%"
                            />
                          </WhatsappCustomTemplateBtnIcon>
                        </Button>
                      </div>
                    </WhatsappTemplateContainer>
                  ))}
                </>
              )}
            </WhatsappTemplateBody>
          </SelectTemplateContainer>
        </div>
      </BotInfo>

      {messageError && <InputError>{messageError}</InputError>}

      <div>
        <BackButton
          style={{
            position: "relative",
            bottom: "initial",
            left: "initial",
          }}
          templateAction
          onClick={() => {
            history.push("/broadcast/tags");
          }}
        >
          <BackButtonIconContainer>
            <BackButtonIcon src={backButtonIcon} />
          </BackButtonIconContainer>
          <BackButtonText>Regresar</BackButtonText>
        </BackButton>
      </div>
    </BroadcastAppCard>
  );
};

export default BroadcastSelectTemplate;
