import { combineReducers } from 'redux';

import channels from './channelsDuck';
import conversations from './conversationsDuck';
import filters from './filtersDuck';
import messages from './messagesDuck';
import agents from './agentsDuck';
import admins from './adminsDuck';
import user from './userDuck';
import locations from './locationsDuck';
import report from './reporterDuck';
import configurations from './dataSheetConfigurationDuck';
import roles from './roleDuck';
import payment from './paymentDuck';
import tags from './tagsDuck';
import spaces from './spacesDuck';
import permissions from './permissionsDuck';
import plans from './plansDuck';
import multimedia from './multimediaDuck';
import quickAnswers from './quickAnswersDuck';
import contacts from './contactsDuck';
import botFollowUp from './botFollowUpDuck';
import whatsappTemplates from './whatsappTemplateDuck';
import app from './appDuck';
import onboarding from './onboardingDuck';
import campaign from './campaignDuck';


const reducer = combineReducers({
  app,
  channels,
  conversations,
  filters,
  messages,
  agents,
  admins,
  user,
  locations,
  report,
  configurations,
  roles,
  payment,
  tags,
  spaces,
  permissions,
  plans,
  multimedia,
  quickAnswers,
  contacts,
  botFollowUp,
  whatsappTemplates,
  onboarding,
  campaign
});

export default reducer;
