import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import LoaderComponent from "../../../loader/Loader.component";
import { getLabelMessage } from "../../../../const/labelService";
import {
  NextPdfPage,
  PreviousPdfPage,
  PdfContainer,
  PdfPageCounterContainer,
  NextPdfButton,
  PreviousPdfButton,
  PageCounter,
  DocumentContainer,
} from "./PdfPreview.style";

const PdfPreview = ({ url, parentWidth, onFileClick, templateMedia }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [fileTotalPages, setFileTotalPages] = useState(null);
  const [displayedPage, setDisplayedPage] = useState(1);

  const labels = ["pdfPageCount"];
  const lbls = getLabelMessage(labels);

  const onDocumentLoadSuccess = ({ numPages: pageCount }) => {
    setFileTotalPages(pageCount);
  };

  const previousPdfPage = () => {
    if (displayedPage > 1)
      setDisplayedPage((actualPageNumber) => actualPageNumber - 1);
  };

  const nextPdfPage = () => {
    if (displayedPage < fileTotalPages)
      setDisplayedPage((actualPageNumber) => actualPageNumber + 1);
  };

  useEffect(() => {
    setDisplayedPage(1);
    console.log("urlllllllllllllllllllll", url);
  }, [url]);

  const containerRef = useRef(null);

  return (
    <PdfContainer ref={containerRef}>
      <DocumentContainer onClick={onFileClick} hasClickAction={!!onFileClick}>
        <Document
          className="pdfDocument"
          file={{ url }}
          loading={<LoaderComponent width={100} height={100} />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {parentWidth && (
            <Page
              pageNumber={displayedPage}
              renderAnnotationLayer={false}
              className="pdfDocument"
            />
          )}
          {!parentWidth && (
            <Page
              pageNumber={displayedPage}
              renderAnnotationLayer={false}
              className="pdfDocument"
            />
          )}
        </Document>
      </DocumentContainer>
      {!templateMedia && (
        <PdfPageCounterContainer>
          <PreviousPdfButton onClick={previousPdfPage}>
            <PreviousPdfPage icon={faChevronLeft} />
          </PreviousPdfButton>
          {fileTotalPages && (
            <PageCounter>
              {lbls.pdfPageCount(displayedPage, fileTotalPages)}
            </PageCounter>
          )}
          <NextPdfButton onClick={nextPdfPage}>
            <NextPdfPage icon={faChevronRight} />
          </NextPdfButton>
        </PdfPageCounterContainer>
      )}
    </PdfContainer>
  );
};

PdfPreview.propTypes = {
  url: PropTypes.string.isRequired,
  parentWidth: PropTypes.number,
  onFileClick: PropTypes.func,
};

export default PdfPreview;
