import gql from 'graphql-tag';

const CREATE_SURVEY_CONFIG = gql`
  mutation createSurveyConfig(
    $companyId: String!,
    $initText: String!,
    $channelId: String!,
    $options: [SurveyOptionCreateInput!],
  ) {
    createSurveyConfig(
      data: {
        companyId: $companyId,
        initText: $initText,
        isActive: true,
        channelId: $channelId,
        options: {
          create: $options
        }
      }
    ) {
      id
      options {
        id
      }
    }
  }
`;

const UPDATE_SURVEY_CONFIG = gql`
  mutation updateSurveyConfig(
    $id: ID!,
    $initText: String,
    $options: [SurveyOptionCreateInput!],
  ) {
    updateSurveyConfig(
      where: { id: $id}
      data: {
        initText: $initText,
        options: {
          create: $options
        }
      }
    ) {
      id
      options {
        id
      }
    }
  }
`;

const DELETE_SURVEY_OPTIONS = gql`
  mutation deleteManySurveyOptions(
    $ids: [ID!],
  ) {
    deleteManySurveyOptions(
      where: {
        id_in: $ids
      }
    ) {
      count
    }
  }
`;



const GET_SURVEY_CONFIG_BY_COMPANY = gql`
  query surveyConfigs(
    $companyId: String!
  ) {
    surveyConfigs(
      where: {
        companyId: $companyId 
      }
    ) 
    {
      id
      initText
      isActive
      channelId
      options {
        id
        text
        value
        index
        endText
      }
    }
  }
`;

const ApolloSurvey = {
  CREATE_SURVEY_CONFIG,
  DELETE_SURVEY_OPTIONS,
  UPDATE_SURVEY_CONFIG,
  GET_SURVEY_CONFIG_BY_COMPANY
};

export default ApolloSurvey;
