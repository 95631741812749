import { remove } from "lodash";

import {
  getWhatsappTemplates,
  deleteWhatsappTemplate,
  getWhatsappTemplateByChannel,
} from "../utils/whatsappTemplate";

const WHATSAPPTEMPLATES_STATE = {
  fetching: false,
  fetched: false,
  whatsappTemplateView: "initWhatsappTemplateView",
  createTemplate: {
    name: "",
    text: "",
    textWithFormat: "",
    originalText: "",
    variables: [],
    category: "",
    isVariables: false,
    channelId: "",
  },
  data: [],
  selected: {},
};

const GET_WHATSAPPTEMPLATES_SUCESS = "GET_WHATSAPPTEMPLATES_SUCESS";
const GET_WHATSAPPTEMPLATES = "GET_WHATSAPPTEMPLATES";
const SET_SELECTED_WHATSAPPTEMPLATES = "SET_SELECTED_WHATSAPPTEMPLATES";
const UPDATE_CLIENT_WHATSAPPTEMPLATES = "UPDATE_CLIENT_WHATSAPPTEMPLATES";
const UPDATE_CLIENT_WHATSAPPTEMPLATES_END =
  "UPDATE_CLIENT_WHATSAPPTEMPLATES_END";
const SET_WATSAPP_TEMPLATE_VIEW = "SET_WATSAPP_TEMPLATE_VIEW";
const SET_WATSAPP_TEMPLATE_CATEGORY = "SET_WATSAPP_TEMPLATE_CATEGORY";
const SET_WATSAPP_TEMPLATE_TEXT = "SET_WATSAPP_TEMPLATE_TEXT";
const SET_WATSAPP_TEMPLATE_NAME = "SET_WATSAPP_TEMPLATE_NAME";
const SET_WATSAPP_TEMPLATE_VARIABLES = "SET_WATSAPP_TEMPLATE_VARIABLES";
const ADD_VARIABLE = "ADD_VARIABLE";
const SET_WATSAPP_TEMPLATE_WITH_VARIABLES =
  "SET_WATSAPP_TEMPLATE_WITH_VARIABLES";
const SET_TEXT_FORMAT = "SET_TEXT_FORMAT";
const RESET_CREATE_TEMPLATE = "RESET_CREATE_TEMPLATE";
const SET_ORIGINAL_TEXT = "SET_ORIGINAL_TEXT";
const SET_CHANEL_ID = "SET_CHANEL_ID";

export default function whatsappTemplatesReducer(
  state = WHATSAPPTEMPLATES_STATE,
  action
) {
  const { type, payload } = action;

  switch (type) {
    case GET_WHATSAPPTEMPLATES:
      return { ...state, fetching: true };
    case GET_WHATSAPPTEMPLATES_SUCESS:
      return { ...state, fetching: false, data: payload };
    case SET_SELECTED_WHATSAPPTEMPLATES:
      return { ...state, selected: payload };
    case UPDATE_CLIENT_WHATSAPPTEMPLATES:
      return { ...state, fetching: true };
    case UPDATE_CLIENT_WHATSAPPTEMPLATES_END:
      return { ...state, fetching: false };
    case SET_WATSAPP_TEMPLATE_VIEW:
      return { ...state, whatsappTemplateView: payload.whatsappTemplateView };
    case SET_WATSAPP_TEMPLATE_CATEGORY:
      return {
        ...state,
        createTemplate: { ...state.createTemplate, category: payload.category },
      };
    case SET_WATSAPP_TEMPLATE_WITH_VARIABLES:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          isVariables: payload.isVariables,
        },
      };
    case SET_WATSAPP_TEMPLATE_TEXT:
      return {
        ...state,
        createTemplate: { ...state.createTemplate, text: payload.text },
      };
    case SET_WATSAPP_TEMPLATE_NAME:
      return {
        ...state,
        createTemplate: { ...state.createTemplate, name: payload.name },
      };
    case SET_WATSAPP_TEMPLATE_VARIABLES:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          variables: payload.variables,
          isVariables: payload.isVariables,
        },
      };
    case ADD_VARIABLE:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          variables: [...state.createTemplate.variables, payload.variable],
        },
      };
    case SET_TEXT_FORMAT:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          textWithFormat: payload.textWithFormat,
        },
      };
    case RESET_CREATE_TEMPLATE:
      return {
        ...state,
        createTemplate: {
          name: "",
          text: "",
          textWithFormat: "",
          variables: [],
          category: "",
          isVariables: false,
        },
      };
    case SET_ORIGINAL_TEXT:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          originalText: payload.originalText,
        },
      };
    case SET_CHANEL_ID:
      return {
        ...state,
        createTemplate: {
          ...state.createTemplate,
          channelId: payload.channelId,
        },
      };

    default:
      return state;
  }
}

export const getWhatsappTemplatesByCompanyAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_WHATSAPPTEMPLATES });
      const companyId = getState().user.data.company.id;
      console.log("companyId", companyId);
      const whatsappTemplates = await getWhatsappTemplates(companyId);
      dispatch({
        type: GET_WHATSAPPTEMPLATES_SUCESS,
        payload: whatsappTemplates,
      });
      return whatsappTemplates;
    } catch (error) {
      throw error;
    }
  };

export const getWhatsappTemplatesByChannelAction =
  (channelId) => async (dispatch) => {
    try {
      dispatch({ type: GET_WHATSAPPTEMPLATES });
      const whatsappTemplates = await getWhatsappTemplateByChannel(channelId);
      dispatch({
        type: GET_WHATSAPPTEMPLATES_SUCESS,
        payload: whatsappTemplates,
      });
      return whatsappTemplates;
    } catch (error) {
      throw error;
    }
  };

export const eraseWhatsappTemplatesAction =
  (id, name, channelId) => async (dispatch, getState) => {
    try {
      const responce = await deleteWhatsappTemplate(id, name, channelId);

      return responce;
      // remove(newWHATSAPPTEMPLATES, ['id', response.id]);
    } catch (error) {
      throw error;
    }
  };

export const setSelectedWhatsappTemplatesAction =
  (WhatsappTemplates) => async (dispatch) => {
    try {
      dispatch({
        type: SET_SELECTED_WHATSAPPTEMPLATES,
        payload: WhatsappTemplates,
      });
    } catch (error) {
      throw error;
    }
  };

export const setWhatsappTemplateView =
  (whatsappTemplateView) => async (dispatch) => {
    try {
      // dispatch({ type: GET_WHATSAPPTEMPLATES });
      const payload = { whatsappTemplateView };
      dispatch({ type: SET_WATSAPP_TEMPLATE_VIEW, payload });
      return;
    } catch (error) {
      throw error;
    }
  };
export const setWhatsappTemplateIsVariables =
  (isVariables) => async (dispatch) => {
    try {
      // dispatch({ type: GET_WHATSAPPTEMPLATES });
      const payload = { isVariables };
      dispatch({ type: SET_WATSAPP_TEMPLATE_WITH_VARIABLES, payload });
      return;
    } catch (error) {
      throw error;
    }
  };
export const setWhatsappTemplateCategory = (category) => async (dispatch) => {
  try {
    // dispatch({ type: GET_WHATSAPPTEMPLATES });
    const payload = { category };
    dispatch({ type: SET_WATSAPP_TEMPLATE_CATEGORY, payload });
    return;
  } catch (error) {
    throw error;
  }
};
export const setWhatsappTemplateText = (text) => async (dispatch) => {
  try {
    // dispatch({ type: GET_WHATSAPPTEMPLATES });
    const payload = { text };
    dispatch({ type: SET_WATSAPP_TEMPLATE_TEXT, payload });
    return;
  } catch (error) {
    throw error;
  }
};
export const setWhatsappTemplateNameAction = (name) => async (dispatch) => {
  try {
    // dispatch({ type: GET_WHATSAPPTEMPLATES });
    const payload = { name };
    dispatch({ type: SET_WATSAPP_TEMPLATE_NAME, payload });
    return;
  } catch (error) {
    throw error;
  }
};
export const setWhatsappTemplateVariablesAction =
  (variables, isVariables) => async (dispatch) => {
    try {
      // dispatch({ type: GET_WHATSAPPTEMPLATES });
      const payload = { variables, isVariables };
      dispatch({ type: SET_WATSAPP_TEMPLATE_VARIABLES, payload });
      return;
    } catch (error) {
      throw error;
    }
  };

export const addVariableAction = (variable) => async (dispatch) => {
  try {
    dispatch({ type: ADD_VARIABLE, payload: variable });
    return;
  } catch (error) {
    throw error;
  }
};

export const setTextWithFormatAction = (textWithFormat) => async (dispatch) => {
  try {
    console.log(textWithFormat);
    const payload = { textWithFormat };
    dispatch({ type: SET_TEXT_FORMAT, payload });
    return;
  } catch (error) {
    throw error;
  }
};
export const setOriginalTextAction = (originalText) => async (dispatch) => {
  try {
    console.log(originalText);
    const payload = { originalText };
    dispatch({ type: SET_ORIGINAL_TEXT, payload });
    return;
  } catch (error) {
    throw error;
  }
};

export const resetCreateTemplate = () => ({
  type: RESET_CREATE_TEMPLATE,
});

export const setChannelIdAction = (channelId) => async (dispatch) => {
  try {
    const payload = { channelId };
    dispatch({ type: SET_CHANEL_ID, payload });
    return;
  } catch (error) {
    throw error;
  }
};
