import client from '../apollo/apolloClient';
import ApolloCompany from '../apollo/apolloCompany';

export const getCategories = async () => {
  try {
    const response = await client.query({
      query: ApolloCompany.GET_CATEGORIES,
    });
    const { categories } = response.data;
    return categories;
  } catch (error) {
    const jcnError = new Error('ERROR_GETTING_CATEGORIES');
    throw jcnError;
  }
};

export const getUserAgents = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloCompany.GET_AGENTS_BY_COMPANY,
      variables: { companyId },
    });
    const users = response.data.users.filter((user) => user.rol.permissions.some((permission) => permission.name === 'agent'));
    return users;
  } catch (error) {
    const jcnError = new Error('ERROR_GETTING_AGENTS');
    throw jcnError;
  }
};

export const getHasUsedTwoFactor = async (id) => {
  try {
    console.log("getHasUsedTwoFactor API ==================", id)
    const response = await client.query({
      query: ApolloCompany.GET_HAS_USED_TWO_FACTOR,
      variables: { id },
    });
    const result = response.data?.companies.pop();
    return result;
  } catch (error) {
    console.log("ERROR_GET_COMPANY_BY_ID", error);
    throw new Error("ERROR_GET_COMPANY_BY_ID");
  }
};


export default getCategories;
