import React, { forwardRef } from "react";
import moment from "moment";

import { DateInputLabels } from "../chatClientData/followUp/followUp.style";
import DateRange from "../../assets/svg/DateRange.svg";
import WatchLater from "../../assets/svg/WatchLater.svg";
import {
  DateInputContainer,
  DateInputLabel,
  DateInputText,
} from "./DatePicker.style";

const DatePicker = ({ label, value, onChange, min, max }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          background: "white",
          top: "-12px",
          left: "7px",
          padding: "5px",
          color: "#2E3353",
          fontFamily: "SpaceGrotesk-Bold",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
        }}
      >
        {label}
      </span>
      <input
        type="date"
        value={value}
        onChange={onChange}
        dateFormat="dd/MM/yyyy"
        min={min}
        max={max}
      />
    </div>
  );
};

export const DateInput = forwardRef(({ onClick, date, label }, ref) => (
  <DateInputContainer onClick={onClick} ref={ref}>
    <DateInputLabel>{label}</DateInputLabel>
    <DateInputLabels>
      <DateInputText>{moment(date).format("DD/MM/YYYY")}</DateInputText>
    </DateInputLabels>
    <img src={DateRange} alt="calendar" />
  </DateInputContainer>
));

export const TimeInput = forwardRef(({ onClick, date, label }, ref) => (
  <DateInputContainer onClick={onClick} ref={ref}>
    <DateInputLabel>{label}</DateInputLabel>
    <DateInputLabels>
      <DateInputText>{moment(date).format("HH:mm")}</DateInputText>
    </DateInputLabels>
    <img src={WatchLater} alt="watch" />
  </DateInputContainer>
));

export default DatePicker;
