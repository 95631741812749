import mailGreen from "../assets/svg/mail-g.svg";
import mailRed from "../assets/svg/mail-r.svg";

import passwordGreen from "../assets/svg/password-g.svg";
import passwordRed from "../assets/svg/password-r.svg";

import nameGreen from "../assets/svg/name-g.svg";
import nameRed from "../assets/svg/name-r.svg";

import phoneGreen from "../assets/svg/phone-g.svg";
import phoneRed from "../assets/svg/phone-r.svg";

import categoryGreen from "../assets/svg/category-g.svg";
import categoryRed from "../assets/svg/category-r.svg";

import countryGreen from "../assets/svg/country-g.svg";
import countryRed from "../assets/svg/country-r.svg";

import roleGreen from "../assets/svg/role_g.svg";
import roleRed from "../assets/svg/role_r.svg";

import companyGreen from "../assets/svg/company-g.svg";

import countries from "./countriesList";
import CONSTANTS from "./constants";

export const formInputs = {
  email: {
    name: "email",
    label: "Correo electrónico",
    placeholder: "Correo electrónico",
    type: "input",
    inputType: "text",
    icons: {
      wrong: mailRed,
      good: mailGreen,
    },
  },
  password: {
    name: "password",
    label: "Contraseña",
    placeholder: "Contraseña",
    type: "input",
    inputType: "password",
    icons: {
      wrong: passwordRed,
      good: passwordGreen,
    },
  },
  name: {
    name: "name",
    label: "Nombre completo",
    placeholder: "Nombre completo",
    type: "input",
    inputType: "text",
    icons: {
      wrong: nameRed,
      good: nameGreen,
    },
  },
  phone: {
    name: "phone",
    label: "Teléfono",
    placeholder: "Teléfono",
    type: "input",
    inputType: "tel",
    icons: {
      wrong: phoneRed,
      good: phoneGreen,
    },
  },
  companyName: {
    name: "companyName",
    label: "Empresa",
    placeholder: "Empresa",
    type: "input",
    inputType: "text",
    icons: {
      wrong: nameRed,
      good: nameGreen,
    },
  },
  passwordConfirmation: {
    name: "passwordConfirmation",
    label: "Confirmar contraseña",
    placeholder: "Confirmar contraseña",
    type: "input",
    inputType: "password",
    icons: {
      wrong: passwordRed,
      good: passwordGreen,
    },
  },
  rol: {
    name: "rol",
    label: "Rol",
    type: "select",
    icons: {
      wrong: roleRed,
      good: roleGreen,
    },
    options: [
      {
        value: "",
        text: "Elige...",
      },
    ],
  },
  category: {
    name: "category",
    label: "Categoría",
    type: "select",
    icons: {
      wrong: categoryRed,
      good: categoryGreen,
    },
    options: [
      {
        value: "",
        text: "Elige...",
      },
    ],
  },
  country: {
    name: "country",
    label: "País de residencia",
    type: "select",
    icons: {
      wrong: countryRed,
      good: countryGreen,
    },
    options: [
      {
        value: "",
        text: "Seleccione una opción",
        isDisabled: true,
      },
      ...countries,
    ],
  },
  tagName: {
    name: "tagName",
    label: "Nombre de etiqueta",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  rolName: {
    name: "rolName",
    label: "Nombre del rol",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  agent: {
    name: "agent",
    label: "Agente",
    type: "checkbox",
  },
  readChat: {
    name: "readChat",
    label: "Ver conversaciones",
    type: "checkbox",
  },
  readUsers: {
    name: "readUsers",
    label: "Ver usuarios",
    type: "checkbox",
  },
  writeUsers: {
    name: "writeUsers",
    label: "Editar usuarios",
    type: "checkbox",
  },
  readSpaces: {
    name: "readSpaces",
    label: "Ver espacios",
    type: "checkbox",
  },
  writeSpaces: {
    name: "writeSpaces",
    label: "Editar espacios",
    type: "checkbox",
  },
  readDataSheetConfiguration: {
    name: "readDataSheetConfiguration",
    label: "Ver campos de usuarios",
    type: "checkbox",
  },
  writeDataSheetConfiguration: {
    name: "writeDataSheetConfiguration",
    label: "Editar campos de usuarios",
    type: "checkbox",
  },
  readRols: {
    name: "readRols",
    label: "Ver roles",
    type: "checkbox",
  },
  writeRols: {
    name: "writeRols",
    label: "Editar roles",
    type: "checkbox",
  },
  readTags: {
    name: "readTags",
    label: "Ver etiquetas",
    type: "checkbox",
  },
  writeTags: {
    name: "writeTags",
    label: "Editar etiquetas",
    type: "checkbox",
  },
  readPayments: {
    name: "readPayments",
    label: "Ver pagos",
    type: "checkbox",
  },
  writePayments: {
    name: "writePayments",
    label: "Editar pagos",
    type: "checkbox",
  },
  readCompany: {
    name: "readCompany",
    label: "Ver datos de la compañía",
    type: "checkbox",
  },
  writeCompany: {
    name: "writeCompany",
    label: "Editar datos de la compañía",
    type: "checkbox",
  },
  writeMultimedia: {
    name: "writeMultimedia",
    label: "Editar álbumes multimedia",
    type: "checkbox",
  },
  readMultimedia: {
    name: "readMultimedia",
    label: "Ver álbumes multimedia",
    type: "checkbox",
  },
  writeQuickAnswers: {
    name: "writeQuickAnswers",
    label: "Editar respuestas rápidas",
    type: "checkbox",
  },
  tagConversations: {
    name: "tagConversations",
    label: "Etiquetar conversaciones",
    type: "checkbox",
  },
  coldBot: {
    name: "coldBot",
    label: "Etiquetar como frío",
    type: "checkbox",
  },
  followUp: {
    name: "followUp",
    label: "Programar mensajes",
    type: "checkbox",
  },
  reasignConversations: {
    name: "reasignConversations",
    label: "Reasignar conversaciones",
    type: "checkbox",
  },
  readBot: {
    name: "readBot",
    label: "Ver bot",
    type: "checkbox",
  },
  writeBot: {
    name: "writeBot",
    label: "Editar bot",
    type: "checkbox",
  },
  readAnalytics: {
    name: "readAnalytics",
    label: "Ver estadísticas",
    type: "checkbox",
  },
  deleteConversations: {
    name: "deleteConversations",
    label: "Eliminar conversaciones",
    type: "checkbox",
  },
  campaigns: {
    name: "campaigns",
    label: "Enviar campañas",
    type: "checkbox",
  },
  whatsappTemplate: {
    name: "whatsappTemplate",
    label: "Crear plantillas de WhatsApp",	
    type: "checkbox",
  },
  campaignTagName: {
    name: "campaignTagName",
    label: "Nombre de etiqueta",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  datasheetField: {
    name: "datasheetField",
    label: "Nombre del campo",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  datasheetType: {
    name: "datasheetType",
    label: "Tipo de campo",
    type: "select",
    options: [
      {
        value: "",
        text: "Elige...",
        isDisabled: true,
      },
      {
        value: "string",
        text: "Campo abierto",
      },
      {
        value: "date",
        text: "Fecha",
      },
      {
        value: "boolean",
        text: "Confirmación",
      },
      {
        value: "email",
        text: "Correo electrónico",
      },
      {
        value: "list",
        text: "Lista",
      },
      {
        value: "number",
        text: "Número",
      },
      {
        value: "phone",
        text: "Teléfono",
      },
    ],
  },
  // onboarding
  onboardingName: {
    name: "onboardingName",
    label: "¿Cuál es tu nombre?",
    placeholder: "Nombre completo",
    type: "input",
    inputType: "text",
    question: "¿Cuál es tu nombre?",
    icons: {
      good: nameGreen,
    },
  },
  onboardingPhone: {
    name: "onboardingPhone",
    label: "¿Cuál es tu número de celular personal?",
    placeholder: "Escribe tu número de celular personal...",
    type: "input",
    inputType: "text",
    question: "¿Cuál es tu número de celular personal?",
    icons: {
      good: phoneGreen,
    },
  },
  onboardingEmail: {
    name: "onboardingEmail",
    label: "Correo electrónico",
    placeholder: "ejemplo@correo.com",
    type: "input",
    inputType: "text",
    icons: {
      good: mailGreen,
    },
  },
  onboardingCompany: {
    name: "onboardingCompany",
    label: "Empresa",
    placeholder: "Ej. “Pastelería de moda”",
    type: "input",
    inputType: "text",
    question: "¿Cuál es el nombre de tu negocio?",
    icons: {
      good: companyGreen,
    },
  },
  onboardingPassword: {
    name: "onboardingPassword",
    label: "Contraseña",
    placeholder: "Password",
    type: "input",
    inputType: "password",
    question: "¿Cuál será tu contraseña?",
    icons: {
      good: passwordGreen,
    },
  },
  onboardingPasswordConfirmation: {
    name: "onboardingPasswordConfirmation",
    label: "Confirmar contraseña",
    placeholder: "Password",
    type: "input",
    inputType: "password",
    question: "Confirma tu contraseña",
    icons: {
      good: passwordGreen,
    },
  },
  onboardingCountry: {
    name: "onboardingCountry",
    label: "¿De qué país eres?",
    type: "select",
    question: "¿De que país eres?",
    icons: {
      good: countryGreen,
    },
    options: [
      {
        value: "",
        text: "Selecciona un país...",
        isDisabled: true,
      },
      ...countries,
    ],
  },
  spaceName: {
    name: "spaceName",
    label: "Nombre del espacio",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  cardName: {
    name: "cardName",
    label: "Nombre de tarjetahabiente",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  cardNumber: {
    name: "cardNumber",
    label: "Número de tarjeta",
    placeholder: "Escribe aqui…",
    type: "input",
    inputType: "text",
  },
  whatsappSlotName: {
    name: "whatsappSlotName",
    label: "Número de teléfono",
    placeholder: "Número de teléfono",
    type: "input",
    inputType: "text",
  },
  whatsappChatHistory: {
    name: "whatsappChatHistory",
    label: "¿Deseas importar tus conversaciones anteriores?",
    type: "radio",
    options: [
      {
        value: "yes",
        text: "Sí",
      },
      {
        value: "no",
        text: "No",
      },
    ],
  },
  whatsappChatHistoryTime: {
    name: "whatsappChatHistoryTime",
    label: "Selecciona una fecha",
    type: "select",
    options: [
      {
        value: "",
        text: "Elige...",
        isDisabled: true,
      },
      {
        value: "all",
        text: "Todo el historial",
      },
      {
        value: "year",
        text: "1 año",
      },
      {
        value: "six_months",
        text: "6 meses",
      },
      {
        value: "month",
        text: "1 mes",
      },
      {
        value: "two_weeks",
        text: "2 semanas",
      },
      {
        value: "week",
        text: "1 semana",
      },
      {
        value: "day",
        text: "1 día",
      },
    ],
  },
  expirationDate: {
    name: "expirationDate",
    label: "Fecha de vencimiento",
    placeholder: "DD/AA",
    type: "input",
    inputType: "text",
  },
  cvv: {
    name: "cvv",
    label: "CVV",
    placeholder: "CVV",
    type: "input",
    inputType: "text",
  },
  whatsappSelect: {
    name: "whatsappSelect",
    label: "WhatsApp",
    type: "customSelect",
    options: [
      {
        secondaryText: "",
        mainText: "Elige...",
        isDisabled: true,
      },
    ],
  },
  facebookSelect: {
    name: "facebookSelect",
    label: "Facebook Messenger",
    type: "customSelect",
    options: [
      {
        secondaryText: "",
        mainText: "Elige...",
        isDisabled: true,
      },
    ],
  },
  createNewAlbumName: {
    name: "createNewAlbumName",
    label: "Nombre del álbum",
    placeholder: "Nombre del álbum",
    type: "input",
    inputType: "text",
  },
  createNewAlbumSpaceSelect: {
    name: "createNewAlbumSpaceSelect",
    label: "Espacio",
    type: "select",
    options: [
      {
        value: "",
        text: "Elige...",
        isDisabled: true,
      },
    ],
  },
  selectTimeLapsSeconds: {
    name: "selectTimeLapsSeconds",
    label: "Lapso de seguimiento",
    type: "select",
    options: [
      {
        value: 120,
        text: "2 minutos",
        isDisabled: false,
      },
      {
        value: 3600,
        text: "1 hora",
        isDisabled: false,
      },
      {
        value: 86400,
        text: "24 horas",
        isDisabled: false,
      },
      {
        value: 259200,
        text: "3 dias",
        isDisabled: false,
      },
      {
        value: 432000,
        text: "5 dias",
        isDisabled: false,
      },
      {
        value: 864000,
        text: "10 dias",
        isDisabled: false,
      },
      {
        value: 2592000,
        text: "30 dias",
        isDisabled: false,
      },
    ],
  },
  selectTemplateCategory: {
    name: "selectTemplateCategory",
    label: "Categoría",
    type: "select",
    options: [
      {
        value: CONSTANTS.WHATSAPP_TEMPLATE_UTILITY,
        text: "Actualización de estatus de un evento o transacción",
        isDisabled: false,
      },
      {
        value: CONSTANTS.WHATSAPP_TEMPLATE_MARKETING,
        text: "Avisos, marketing y promociones",
        isDisabled: false,
      },
    ],
  },

  selectTemplateType: {
    name: "selectTemplateType",
    label: "Tipo de plantilla",
    type: "select",
    options: [
      {
        value: "NORMAL_TEMPLATE",
        text: "Normal",
        isDisabled: false,
      },
      {
        value: "CUSTOM_TEMPLATE",
        text: "Personalizada",
        isDisabled: false,
      },
    ],
  },
  selectTemplateMessageHeader: {
    name: "selectTemplateMessageHeader",
    label: "Encabezado del mensaje",
    type: "select",
    options: [
      {
        value: "imagen",
        text: "Imagen",
        isDisabled: false,
      },
      {
        value: "text",
        text: "Texto",
        isDisabled: false,
      },
      {
        value: "doc",
        text: "Documento",
        isDisabled: false,
      },
    ],
  },
  addVideo: {
    name: "addVideo",
    label: "Nuevo video",
    placeholder: "Pega la URL del video de Facebook aquí",
    type: "input",
    inputType: "text",
  },
  shortcut: {
    name: "shortcut",
    label: "Atajo",
    placeholder: "/ No dejes espacios",
    type: "input",
    inputType: "text",
  },
  messageBody: {
    name: "messageBody",
    label: "Cuerpo del mensaje*",
    placeholder: "Escribe aqui...",
    type: "textarea",
    inputType: "text",
  },

  messageFooter: {
    name: "messageFooter",
    label: "pie del mensaje*",
    placeholder: "Escribe aqui...",
    type: "textarea",
    inputType: "text",
  },
  message: {
    name: "message",
    label: "Mensaje",
    placeholder:
      "Hola, {nombreLead}. Mi nombre es {nombreAgente} y estaré feliz de platicarte sobre nuestro producto.",
    type: "textarea",
    inputType: "text",
  },
  botMenuMessage: {
    name: "botMenuMessage",
    label: "",
    placeholder:
      "¡Hola, buen día! Si tienes dudas de [servicio] elige la opción con la que quieres que te ayude:",
    type: "textarea",
    inputType: "text",
  },
  optionMenuTitle: {
    name: "optionMenuTitle",
    label: "",
    placeholder: "Reestablecer mi contraseña",
    type: "input",
    inputType: "text",
  },

  templateMessage: {
    name: "templateMessage",
    label: "Mensaje",
    placeholder: "Escribe aqui...",
    type: "textarea",
    inputType: "text",
  },

  templateName: {
    name: "templateName",
    label: "Nombre de plantilla",
    placeholder: "",
    type: "input",
    inputType: "text",
  },

  messageHeaderText: {
    name: "messageHeaderText",
    label: "Cuerpo  del mensaje",
    placeholder: "Escribe aqui...",
    type: "input",
    inputType: "text",
  },
  buttonQuickAnswer: {
    name: "buttonQuickAnswer",
    label: "Bóton de respuesta rápida",
    placeholder: "Etiqueta del botón ",
    type: "input",
    inputType: "text",
  },
  intlPhone: {
    name: "phone",
    label: "Número de teléfono",
    placeholder: "Número de teléfono",
    type: "intlPhone",
    inputType: "text",
  },
  isWelcomeMessageActive: {
    name: "isWelcomeMessageActive",
    label: "Número de teléfono",
    placeholder: "Número de teléfono",
    type: "isWelcomeMessageActive",
    inputType: "text",
  },
  timeLapsSeconds: {
    name: "timeLapsSeconds",
    label: "Tiempo de seguimiento",
    placeholder: "Tiempo de seguimiento",
    type: "input",
    inputType: "text",
  },
  messageWorkingHours: {
    name: "messageWorkingHours",
    label: "Mensaje",
    placeholder:
      "Hola, {nombreLead}. Mi nombre es {nombreAgente} y estaré feliz de platicarte sobre nuestro producto.",
    type: "textarea",
    inputType: "text",
  },
  isWelcomeMessageActiveWorkingHours: {
    name: "isWelcomeMessageActiveWorkingHours",
    label: "Número de teléfono",
    placeholder: "Número de teléfono",
    type: "isWelcomeMessageActive",
    inputType: "text",
  },
};

const formGenerator = (inputs, forms, dynamicInputs = null) => {
  let form = [];
  inputs.forEach((input) => {
    form.push(forms ? forms[input] : formInputs[input]);
  });
  if (dynamicInputs) form = [...form, ...dynamicInputs];
  return form;
};

export default formGenerator;
