import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import {
  SortContainer,
  SortSelectedOption,
  SortActionIcon,
  SortModalContainer,
  SortOption,
} from './OrderByModal.style';
import { getLabelMessage } from '../../const/labelService';
import useCloseoutside from '../../hooks/useCloseOutside';

const OrderByModal = ({ onOptionClick }) => {
  const labels = [
    'multimediaOrderByLabel',
    'multimediaCreationDate',
    'sortByCreatedAtLabel',
    'sortByNameLabel',
  ];
  const lbls = getLabelMessage(labels);

  const { ref, isActive, setIsActive } = useCloseoutside(false);

  const [selectedOption, setSelectedOption] = useState('creationDate'); // creationDate, name

  const onClick = (option) => {
    setSelectedOption(option);
    onOptionClick(option);
  };

  return (
    <SortContainer>
      <SortContainer>{lbls.multimediaOrderByLabel}</SortContainer>
      <SortSelectedOption>{selectedOption === 'creationDate' ? lbls.multimediaCreationDate : lbls.sortByNameLabel}</SortSelectedOption>
      <SortActionIcon icon={faCaretDown} onClick={() => setIsActive(!isActive)} />
      {isActive && (
        <SortModalContainer ref={ref}>
          <SortOption onClick={() => onClick('creationDate')}>{lbls.sortByCreatedAtLabel}</SortOption>
          <SortOption lastOption onClick={() => onClick('name')}>{lbls.sortByNameLabel}</SortOption>
        </SortModalContainer>
      )}
    </SortContainer>
  );
};

OrderByModal.propTypes = {
  onOptionClick: PropTypes.func,
};

export default OrderByModal;
