import client from "../apollo/apolloClient";
import ApolloTags from "../apollo/apolloTag";
import API from "../axios";

export const createTag = async (name, color, companyId, key) => {
  try {
    const response = await client.mutate({
      mutation: ApolloTags.CREATE_TAG,
      variables: { name, color, companyId, key, companyIdString: companyId },
    });
    return response.data.createTag;
  } catch (error) {
    if (error.message.includes("Details: Field name = key"))
      throw new Error("ERROR_CREATING_TAG_DUPLICATED");
    throw new Error("ERROR_CREATING_TAG");
  }
};

export const getTags = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloTags.GET_TAGS,
      variables: { companyId },
    });
    console.log("RES¨PONSETAG: ",response.data);
    return response.data;
  } catch (error) {
    throw new Error("ERROR_GETTING_TAGS");
  }
};

export const getNotDefaultTags = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloTags.GET_NOT_DEFAULT_TAGS,
      variables: { companyId },
    });
    return response.data;
  } catch (error) {
    throw new Error("ERROR_GETTING_TAGS");
  }
};

export const deleteTag = async (id) => {
  try {
    const response = await client.mutate({
      mutation: ApolloTags.DELETE_TAG,
      variables: { id },
    });
    return response.data.deleteTag;
  } catch (error) {
    throw new Error("ERROR_DELETING_TAG");
  }
};

export const editTag = async (id, name, color, key) => {
  try {
    const response = await client.mutate({
      mutation: ApolloTags.EDIT_TAG,
      variables: { id, name, color, key },
    });
    return response.data.updateTag;
  } catch (error) {
    if (error.message.includes("Details: Field name = key"))
      throw new Error("ERROR_CREATING_TAG_DUPLICATED");
    throw new Error("ERROR_UPDATING_TAG");
  }
};

export const upsertTag = async (name, color, companyId, key) => {
  try {
    const response = await client.mutate({
      mutation: ApolloTags.UPSERT_TAG,
      variables: { name, color, companyId, key, companyIdString: companyId },
    });
    return response.data.upsertTag;
  } catch (error) {
    if (error.message.includes("Details: Field name = key"))
      throw new Error("ERROR_CREATING_TAG_DUPLICATED");
    throw new Error("ERROR_UPSERT_TAG");
  }
};

export const getTagsCount = async (channelId, companyId) => {
  try {
    const response = await API.post("/tagsCount", { channelId, companyId });
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export default createTag;
