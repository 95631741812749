import gql from 'graphql-tag';

const GET_ALBUMS = gql`
  query albums($isRoot: Boolean!, $spacesIdIn: [ID!]) {
    albums(where: { isRoot: $isRoot, space: { id_in: $spacesIdIn } }) {
      id
      name
      company {
        id
      }
      space {
        id
      }
      files {
        id
        url
        fileType
      }
      childs {
        id
        files {
          id
          url
          fileType
        }
      }
      createdAt
    }
  }
`;

const GET_ALBUM_FILES_AND_SUBALBUMS = gql`
  query album($albumId: ID!) {
    album(where: { id: $albumId }) {
      id
      childs {
        id
        name
        space {
          id
        }
        files {
          id
          url
          fileType
        }
        createdAt
      }
      files {
        id
        name
        fileType
        url
        weight
        createdAt
      }
    }
  }
`;

const GET_SUBALBUM_FILES = gql`
  query album($albumId: ID!) {
    album(where: { id: $albumId }) {
      id
      files {
        id
        name
        fileType
        url
        weight
        createdAt
      }
    }
  }
`;

const ApolloMultimedia = {
  GET_ALBUMS,
  GET_ALBUM_FILES_AND_SUBALBUMS,
  GET_SUBALBUM_FILES,
};

export default ApolloMultimedia;
