import styled from "styled-components";

export const EndChatModalContainer = styled.div`
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(37, 41, 68, 0.9);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EndChatModalStyled = styled.section`
  width: 50vw;
  background-color: white;
  padding: 28px;
`;

export const Title = styled.h2`
  text-align: center;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 20px;
  margin-bottom: 42px;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const SectionComment = styled.div`
  margin-bottom: 16px;
`;

export const SectionMiddle = styled.div`
  width: 50%;
`;

export const FormTitle = styled.h3`
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  color: #757575;
  margin-bottom: 16px;
  font-weight: 500;
`;

export const StarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Star = styled.img`
  cursor: pointer;
  width: 38px;
  height: 36px;
  margin-right: 20px;
`;

export const Input = styled.input`
  background: #f5f4f6;
  border-radius: 4px;
  border: none;
  padding: 14px;
  font-size: 16px;
  font-family: "Montserrat-Medium", sans-serif;
`;

export const TextArea = styled.textarea`
  background: #f5f4f6;
  border-radius: 4px;
  border: none;
  padding: 14px;
  font-size: 16px;
  font-family: "Montserrat-Medium", sans-serif;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CancelButton = styled.button`
  border: 2px solid #f61e44;
  border-radius: 4px;
  color: #f61e44;
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 22px;
  background-color: transparent;
  width: 30%;
  cursor: pointer;
`;

export const FinishButton = styled.button`
  border: none;
  border-radius: 4px;
  background-color: #00cd7e;
  color: white;
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 12px 22px;
  width: 30%;
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Montserrat-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;



export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${(props) => (props.checked ? "#00cd7e" : "#eee")};

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const RadioContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Montserrat-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

export const RadioCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.checked ? "#00cd7e" : "#eee")};

  &:after {
    content: "";
    position: absolute;
    display: ${(props) => (props.checked ? "block" : "none")};
    top: 8px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
`;
