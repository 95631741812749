import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';

import { LoaderContainer } from './Loader.style';

const LoaderComponent = ({ width, height, type = 'Oval', color= "#062E93" }) => (
  <LoaderContainer>
    <Loader
      type={type}
      color={color}
      height={width}
      width={height}
    />
  </LoaderContainer>
);

LoaderComponent.propTypes = {
  height: PropTypes.number,
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
};

export default LoaderComponent;

LoaderComponent.defaultProps = {
  type: 'Oval',
};
