/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider as ReduxProvider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
// import * as Sentry from "@sentry/react";

import client from "./apollo/apolloClient";
import store from "./redux/store";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import safariBrowserVerification from "./utils/browserVerification";

export const theme = {
  main: "#F7F8FA",
  primary: "#00cd7e",
  danger: "#f61e44",
  chat: "#d9fed3",
  grey: "#979797",
  blue: "#192B88",
  dark: {
    main: "#000000",
    light: "rgba(0, 0, 0, 0.4)",
  },
  secondary: {
    main: "#fff",
  },
  new: {
    blue: "#fff",
    mainText: "#2C3351",
    secondaryText: "#00ff00",
  },
};

require("dotenv").config();

// Sentry.init({
//   dsn: "https://45c7136071640000b70caa54657e6007@o4506593438924800.ingest.sentry.io/4506713512869888",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/arturo\.iventas\.mx/,
//         /^https:\/\/arturorestservices\.iventas\.mx/,
//         /^https:\/\/restservices\.iventas\.com/
//       ],
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if ("serviceWorker" in navigator && !safariBrowserVerification()) {
  try {
    if (!("Notification" in window)) {
      console.log("Este navegador no admite notificaciones.");
    } else {
      navigator.serviceWorker
        .register("./firebase-messaging-sw.js")
        .then((registration) => {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch((err) => {
          console.error("Service worker registration failed, error:", err);
        });
    }
  } catch (error) {
    console.error(error);
  }
}

ReactDOM.render(
  <>
    <Helmet>
      <title>iVentas</title>
    </Helmet>
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <App />
          <ToastContainer />
        </ThemeProvider>
      </ApolloProvider>
    </ReduxProvider>
  </>,
  document.getElementById("root")
);

serviceWorker.unregister();
