import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CustomPagination = ({ pageCount, onPageChange , currentPage }) => {
  // const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    console.log("pageCountCustom", pageCount);
  }, [pageCount]);

  return (
    <PaginationContainer>
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      /> */}
      <ReactPaginate
        pageCount={pageCount}
        className="react-paginate"
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        onPageChange={onPageChange}
        // containerClassName={"pagination"}
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        // activeClassName={"active"}
        // previousClassName={"previous"}
        nextClassName={"next"}
        pageClassName={"page"}
        forcePage={currentPage}
      />
    </PaginationContainer>
  );
};

export default CustomPagination;
