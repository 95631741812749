import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import React from 'react';
import es from 'react-phone-input-2/lang/es.json';

import { InputContainer } from '../generalModal/GeneralModal.style';
import 'react-phone-input-2/lib/high-res.css';
import { PhoneLabel } from './CustomPhoneInput.style';

const CustomPhoneInput = ({ input, control }) => (
  <InputContainer>
    <PhoneLabel>{input.label}</PhoneLabel>
    <Controller
      as={(
        <PhoneInput
          country="mx"
          preferredCountries={['mx', 'ar', 'bo', 'cl', 'ck', 'ec', 'pe', 'uy', 've']}
          countryCodeEditable={false}
          localization={es}
          defaultMask=".. .. .. .. .."
          alwaysDefaultMask
          placeholder="Número de teléfono"
          inputProps={{
            name: input.name,
            required: true,
          }}
          name={input.name}
          containerStyle={{
            height: '40px',
            width: '100%',
            display: 'flex',
            marginBottom: '26px',
          }}
          buttonStyle={{
            border: '1px solid #CCCCCC',
            borderRadius: '6px',
            backgroundColor: 'white',
          }}
          inputStyle={{
            marginLeft: '76px',
            paddingLeft: '16px',
            height: '40px',
            borderRadius: '6px',
            width: '100%',
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            fontSize: '14px',
          }}
          dropdownStyle={{
            fontFamily: 'Inter, sans-serif',
          }}
        />
      )}
      name={input.name}
      control={control}
      defaultValue=""
    />
  </InputContainer>
);

export default CustomPhoneInput;
