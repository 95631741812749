import styled, { css } from "styled-components";

export const GridLayout = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: grid;
  grid-template-rows: 70px auto 1fr;
  grid-template-columns: 100%;
  grid-template-areas:
  "nav"
  "alert"
  "main";

`;

export const Header = styled.header`
  grid-area: header;
`;

export const Main = styled.main`
  max-height: 100%;
  grid-area: main;
  overflow-y: scroll;
  display: grid;
  box-shadow: -12px 0 24px -5px rgba(132, 146, 166, 0.16),
    12px 0 24px -5px rgba(132, 146, 166, 0.16);
  z-index: 1;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  grid-template-areas: "page";
`;


export const SectionPage = styled.section`
  grid-area: page;
  max-height: 100%;
  max-width: 100%;
  background-color: #f5f4f6;
  display: grid;
  grid-template-rows: 9% 91%;
  grid-template-columns: 100%;
  grid-template-areas:
    "title"
    "content";
  ${(props) =>
    props.isChat &&
    css`
      background-color: #ffffff;
      grid-template-rows: 100%;
      grid-template-columns: 100%;
      grid-template-areas: "content";
    `}
`;

export const Title = styled.section`
  grid-area: title;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  padding-top: 1.6rem;
  font-size: 1.7rem;
  font-family: SpaceGrotesk-Bold, sans-serif;
`;

export const PageContainer = styled.section`
  ${(props) =>
    props.hideTitle
      ? css`
          grid-area: title / content;
          grid-row: 1 / 3;
        `
      : css`
          grid-area: content;
        `}
  width: 100%;
  height: 100%;
  overflow-y: ${(props) => (props.isPageChat ? "hidden" : "scroll")};
  padding: ${(props) => {
    if (props.isChat) return "0";
    if (props.hideTitle) return "2rem 1.5rem 2rem";
    return "0 1.5rem 2rem";
  }};
`;
