import client from "../apollo/apolloClient";
import ApolloMessages from "../apollo/apolloMessages";
import ApolloConversations from "../apollo/conversations";
import API from "../axios";

const getConversationMessages = async (selectedConversation, cursor) => {
  try {
    if (selectedConversation.length > 1) {
      const { data: messagesData } = await client.query({
        query: ApolloMessages.GET_MESSAGES_BY_CLIENT_ID,
        variables: { ids: selectedConversation, skip: cursor },
      });
      return messagesData?.messages;
    }
    const { data: messagesData } = await client.query({
      query: ApolloMessages.GET_MESSAGES_BY_UNIQUE_CLIENT_ID,
      variables: { id: selectedConversation.pop(), skip: cursor },
    });
    return messagesData?.messages;
  } catch (error) {
    console.log(error);
  }
};

export const getLastActiveWindow = async (clientId) => {
  try {
    const { data: messagesData } = await client.query({
      query: ApolloMessages.GET_LAST_ACTIVE_WINDOW,
      variables: { id: clientId },
    });
    console.log('last window messages => ', messagesData?.messages)
    return messagesData?.messages?.pop();
  } catch (error) {
    console.log('last window err => ',error);
  }
};

export const markAsReadMessages = async (ids, user, date, conversationId) => {
  try {
    if (ids?.length > 0) {
      const { data } = await client.mutate({
        mutation: ApolloMessages.MARK_AS_READ_MESSAGES,
        variables: { ids, user, date },
      });
      await client.mutate({
        mutation: ApolloConversations.MARK_AS_READ,
        variables: { id: conversationId },
      });
      return data.updateManyMessages;
    }
  } catch (error) {
    console.log(error);
    // throw new Error('ERROR_SEEN_MESSAGE');
  }
};

export const markAsNotReadMessage = async (messageId, conversationId) => {
  try {
    const ids = [];
    ids.push(messageId);
    const { data } = await client.mutate({
      mutation: ApolloMessages.MARK_AS_NOT_READ_MESSAGES,
      variables: { ids },
    });
    await client.mutate({
      mutation: ApolloConversations.MARK_AS_UNREAD,
      variables: { id: conversationId },
    });
    return data.updateMessage;
  } catch (error) {
    throw new Error("ERROR_NOT_SEEN_MESSAGE: ");
  }
};

export const getNotSeenMessages = async (conversationId) => {
  try {
    const { data } = await client.query({
      query: ApolloMessages.GET_NOT_SEEN_MESSAGES,
      variables: { id: conversationId },
    });
    return data?.messages;
  } catch (error) {
    console.log("ERROR_NOT_SEEN_MESSAGE: ", error);
    // throw new Error("ERROR_GETTING_MESSAGES");
  }
};

export const getLastMessageId = async (conversationId) => {
  try {
    const { data } = await client.query({
      query: ApolloMessages.GET_LAST_MESSAGE_ID,
      variables: { clientId: conversationId },
    });
    return data.messages[0].id;
  } catch (error) {
    throw new Error("ERROR_GETTING_MESSAGES");
  }
};

export const getFollowUpMessageQuery = async (conversationId) => {
  try {
    const { data } = await client.query({
      query: ApolloMessages.GET_FOLLOW_UP_MESSAGE,
      variables: { clientId: conversationId },
    });
    return data.followUpMessages;
  } catch (error) {
    throw new Error("ERROR_GETTING_FOLLOW_UP_MESSAGES");
  }
};
export const getFollowUpMessageQueryEdit = async (conversationId) => {
  try {
    const { data } = await client.query({
      query: ApolloMessages.GET_FOLLOW_UP_MESSAGE_FOR_EDIT,
      variables: { clientId: conversationId },
    });
    console.log(data);
    return data.followUpMessages;
  } catch (error) {
    throw new Error("ERROR_GETTING_MESSAGES");
  }
};
export const deleteFollowUpMessageQuery = async (id) => {
  try {
    // const { data } = await client.query({
    //   query: ApolloMessages.GET_FOLLOW_UP_MESSAGE_ID,
    //   variables: { clientId: id },
    // });

    // await client.query({
    //   query: ApolloMessages.DELETE_FOLLOW_UP_MESSAGE_ID,
    //   variables: { id: data.followUpMessages[0].id },
    // });

    const { data } = await client.query({
      query: ApolloMessages.DELETE_MANY_FOLLOW_UP_MESSAGE,
      variables: { clientId: id },
    });

    await client.query({
      query: ApolloConversations.UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE,
      variables: { id, hasFollowUpMessage: false },
    });
  } catch (error) {
    throw new Error("ERROR_GETTING_MESSAGES");
  }
};

export const createFollowUpMessageQuery = async (
  clientId,
  agentId,
  content,
  sendAt,
  channelId,
  templateId,
  variables,
  fileId
) => {
  let followUpData;
  try {
    console.log("LLEGA FILE ID ONE?? ===> ", fileId);
    const { data } = await client.query({
      query: ApolloMessages.CREATE_FOLLOW_UP_MESSAGE,
      variables: {
        clientId,
        agentId,
        content,
        sendAt,
        channelId,
        templateId,
        variables,
        fileId,
      },
    });
    followUpData = data.createFollowUpMessage;
    await client.query({
      query: ApolloConversations.UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE,
      variables: { id: clientId, hasFollowUpMessage: true },
    });
    return followUpData;
  } catch (error) {
    try {
      await client.query({
        query: ApolloConversations.UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE,
        variables: { id: clientId, hasFollowUpMessage: false },
      });
      if (followUpData?.id) {
        await client.query({
          query: ApolloMessages.DELETE_FOLLOW_UP_MESSAGE_ID,
          variables: { id: followUpData?.id },
        });
      }
    } catch (errorRollback) {
      throw new Error("ERROR_ROLLING_BACK_FOLLOW_UP_MESSAGE");
    }
    throw new Error("ERROR_CREATING_FOLLOW_UP_MESSAGE");
  }
};
export const updateFollowUpMessageQuery = async (
  id,
  content,
  sendAt,
  templateId,
  clientId,
  agentId,
  channelId,
  variables,
  fileId
) => {
  try {
    const exist = await client.query({
      query: ApolloMessages.GET_FOLLOW_UP_MESSAGE_BY_ID,
      variables: { id: id },
    });
    let followUpData;
    console.log("EXITS FOLLOW UP => ", exist);
    console.log("variables at redux => ", variables);
    if (exist?.data?.followUpMessage) {
      const { data } = await client.query({
        query: ApolloMessages.UPDATE_FOLLOW_UP_MESSAGE,
        variables: {
          id,
          content,
          sendAt,
          templateId,
          agentId,
          variables,
          fileId,
        },
      });
      followUpData = data.updateFollowUpMessage;
    } else {
      const { data } = await client.query({
        query: ApolloMessages.CREATE_FOLLOW_UP_MESSAGE,
        variables: {
          clientId,
          agentId,
          content,
          sendAt,
          channelId,
          templateId,
          variables,
          fileId,
        },
      });
      followUpData = data.createFollowUpMessage;
      await client.query({
        query: ApolloConversations.UPDATE_CLIENT_HAS_FOLLOW_UP_MESSAGE,
        variables: { id: clientId, hasFollowUpMessage: true },
      });
    }
    return followUpData;
  } catch (error) {
    throw new Error("ERROR_UPDATING_FOLLOW_UP_MESSAGE");
  }
};

export const deleteMessage = async (id) => {
  try {
    await API.post("/deleteMessage", { id });
  } catch (error) {
    throw new Error("ERROR_DELETING_MESSAGE");
  }
};
export const getContact = async (contactId) => {
  try {
    console.log("CONTACT ID => ", contactId);
    const contact = await API.post("/getContact", { contactId });
    console.log("CONTACT => ", contact);
    return contact.data;
  } catch (error) {
    throw new Error("ERROR_GET_CONTACT_MESSAGE");
  }
};

export const getMessagesInfo = async (channelId, date) => {
  try {
    const data = await API.post("/getBalance", { channelId, date });
    console.log(" getMessagesInfo ===>", data);
    return data.data;
  } catch (error) {
    throw new Error("ERROR_GETTING_MESSAGES_INFO");
  }
};

export default getConversationMessages;
