import React from 'react';
import PropTypes from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Button from '../button/Button.style';
import { AddButtonIcon } from './AddButton.style';

const AddButton = ({ onClick, label ,addContact}) => (
  <Button addElementButton  onClick={onClick} color="primary">
    <AddButtonIcon icon={faPlus} />
    {label}
  </Button>
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default AddButton;
