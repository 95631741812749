import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px;
`;

export const Card = styled.div`
  position: relative;
  width: 19.25rem;
  height: 26.313rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  &:hover {
    border: 2px solid #128c7e;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  ${(props) =>
    props.isBot &&
    css`
      padding: 1rem 1rem;
      height: auto;
      max-height: 30rem;
      min-height: 29rem;
    `}


  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      border: 2px solid #128c7e;
    `}

  ${(props) =>
    props.category &&
    css`
      height: 24.188rem;
    `}
  ${(props) =>
    props.disabled &&
    css`
      &:hover {
        border: #f5f4f6;
        box-shadow: 0;
      }
    `}
`;
export const CardTitle = styled.span`
  font-family: SpaceGrotesk-Bold, sans-serif;
  padding-top: 2.25rem;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 150%;
  /* identical to box height, or 27px */

  letter-spacing: -0.257238px;

  text-align: center;

  color: #2e3353;
  ${(props) =>
    props.customTemplate &&
    css`
      width: 70%;
    `}
  ${(props) =>
    props.category &&
    css`
      color: #128c7e;
    `}
`;
export const CardSubtitle = styled.span`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;
  /* identical to box height, or 24px */

  padding-top: 1.375rem;
  text-align: center;
  letter-spacing: -0.257238px;
  color: #757575;
  ${(props) =>
    props.customTemplate &&
    css`
      width: 70%;
    `}

  ${(props) =>
    props.category &&
    css`
      width: 70%;
      font-family: SpaceGrotesk-Bold, sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 150%;
      /* or 27px */

      text-align: center;
      letter-spacing: -0.257238px;
      padding-top: 0;
      color: #757575;
    `}
`;
export const CardImageContainer = styled.div`
  width: 9.375rem;
  height: 9.375rem;
  padding-top: ${(props) => (props.category ? "2.625rem" : "2rem")};
  margin-bottom: ${(props) => (props.customTemplate ? "5rem" : "3rem")};

  ${(props) =>
    props.selected &&
    css`
      margin-bottom: 1.875rem;
    `}
`;
export const CardImage = styled.img`
  width: 100%;
`;

export const DisabledCard = styled.div`
  width: 19.25rem;
  height: 26.313rem;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 22px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

export const BackButton = styled.div`
  position: absolute;
  bottom: ${(props) => (!props.templateAction ? "0" : "30px")};
  left: ${(props) => (!props.templateAction ? "0" : "24px")};
  width: 11.5rem;
  height: 1.375rem;
  cursor: pointer;
  display: flex;
  z-index: 1000;
  align-items: center;
`;
