import { useState, useEffect, useRef } from 'react';

const useCloseoutside = (showComponent) => {
  const [isActive, setIsActive] = useState(showComponent);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isActive, setIsActive };
};

export default useCloseoutside;
