import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.5rem;
  margin-right: 2rem;
  position: relative;
`;

export const SortSelectedOption = styled.span`
  margin-right: 1rem;
  font-family: Montserrat-Bold;
  font-size: 0.875rem;
  color: #00cd7e;
`;

export const SortActionIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  cursor: pointer;
`;

export const SortModalContainer = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  padding: 1.25rem 1.5rem;
`;

export const SortOption = styled.div`
  width: '100%';
  text-align: center;
  font-size: 1rem;
  margin-bottom: ${(props) => (props.lastOption ? '0px' : '10px')};
  cursor: pointer;
  font-family: Montserrat-Bold;
`;
