import React, { useState } from "react";
import styled, { css } from "styled-components";
import { groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Papa from "papaparse";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  faFileCsv,
  faFileExcel,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import {
  ExportIcon,
  ExportIconContainer,
} from "../../../views/panel/panel.style";
import ExportFile from "../../../assets/svg/ExportFile.svg";

import { exportClientReportAction } from "../../../redux/conversationsDuck";
import { useDispatch } from "react-redux";
import CONSTANTS from "../../../const/constants";
import LoaderComponent from "../../loader/Loader.component";

const Toolbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ModalWrapper = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  width: 23.5rem;
  height: auto;
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Button = styled.button`
  margin: 1rem;
  width: 10.5rem;
  height: 2.75rem;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #128c7e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  ${(props) =>
    props.cancel &&
    css`
      color: #f61e44;
      width: 90%;
      background-color: white;
      border: 2px solid #f61e44;
      border-radius: 4px;
      &:hover {
        background-color: #f61e44;
        color: white;
      }
    `}
`;

const GreenButton = styled(Button)`
  background-color: black;
  width: 90%;
  color: white;
`;
const GreenButtonXls = styled(Button)`
  background-color: #0d7c43;
  width: 90%;
  color: white;
`;

const RedButton = styled(Button)`
  background-color: gray;
  color: white;
  width: 90%;
`;

const Icon = styled.div`
  margin-right: 10px;
`;

const ExportContacts = ({ filterByContacts, initialDate, endDate, keyword, tagsIDS, agentID }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [isLoadingXlsx, setIsLoadingXlsx] = useState(false);

  const openModal = () => {
    setIsLoading(false);
    setIsLoadingPDF(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getMessageStatus = (messageStatus) =>
    CONSTANTS.MESSAGE_STATUS[messageStatus?.toUpperCase()] || "";

  const handleExport = async (format) => {
    const data = await dispatch(exportClientReportAction(filterByContacts, initialDate, endDate, keyword, tagsIDS, agentID));

    if (format === "pdf") {
      const doc = new jsPDF();
      const columns = [
        "Nombre",
        "Teléfono",
        "Canal",
        "Agente",
        "Plataforma",
        "Fecha de creación",
        "Última actualización"
      ];
      let rows = [];
      data.forEach((x, i) => {
        const doc = new jsPDF();

        rows.push([
          x?.nameFromChannel,
          x?.number,
          x?.channel || x?.channel?.phone || "",
          x?.agent || "",
          // x?.tags,
          x?.plataform || "",
          x?.createdAt,
          x?.lastUpdate
        ]);
      });

      doc.autoTable({
        head: [columns],
        body: rows,
      });
      doc.save("Contactos.pdf"); // Nombre del archivo PDF
    } else if (format === "csv") {
      const csvData = [];
      console.log(data);
      const columnNames = [
        "Nombre",
        "Teléfono",
        "Canal",
        "Agente",
        "Etiquetas",
        "Plataforma",
        "Fecha de creación",
         "Última actualización"
      ];
      csvData.push(columnNames);
      data.forEach((x, i) => {
        const lastMessage = x?.messages
          ?.filter((x) => x.sender !== "CLIENT")
          .pop();

        const rowData = [
          x?.nameFromChannel,
          x?.number,
          x?.channel || x?.channel?.phone || "" || x?.channel?.name,
          x?.agent || "" || x?.agent?.name,
          x?.tags,
          x?.plataform || "",
          x?.createdAt,
          x?.lastUpdate
        ];
        csvData.push(rowData);
      });

      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "clientes.csv"; // Nombre del archivo CSV
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setIsLoading(false);
    } else if (format === "xlsx") {
      const resultMatrix = [];
      const customFieldHeaders = []; // Header from custom fields

      const totalsByLead = groupBy(data, (m) => m?.id);

      // //build custom fields headers
      // for (let key of Object.keys(totalsByLead)) {
      //   const lead = totalsByLead[key];
      //   for (let i = 0; i < lead.length; i++) {
      //     for (let j = 0; j < lead[i].clientInfo.length; j++) {
      //       const { name } = lead[i].clientInfo[j];
      //       if (!customFieldHeaders.includes(name)) {
      //         customFieldHeaders.push(name);
      //       }
      //     }
      //   }
      // }

      // build total headers
      const totalHeaders = [
        "Nombre",
        "Teléfono",
        "Canal",
        "Agente",
        "Etiquetas",
        "Plataforma",
        "Fecha de creación",
         "Última actualización"
      ];

      resultMatrix.push(totalHeaders);

      // build total rows
      for (let key of Object.keys(totalsByLead)) {
        const lead = totalsByLead[key];
        for (let i = 0; i < lead.length; i++) {
          const row = [
            lead[i]?.nameFromChannel,
            lead[i].number,
            lead[i]?.channel,
            lead[i]?.agent,
            lead[i]?.tags,
            lead[i]?.plataform,
            lead[i]?.createdAt,
            lead[i]?.lastUpdate,
          ];

          const customFields = {};
          // for (let j = 0; j < lead[i].clientInfo.length; j++) {
          //   const { name, value } = lead[i].clientInfo[j];
          //   customFields[name] = value;
          // }

          // for (let fieldName of customFieldHeaders) {
          //   row.push(customFields[fieldName] || "");
          // }

          resultMatrix.push(row);
        }
      }
      const ws = XLSX.utils.aoa_to_sheet(resultMatrix);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "clientes.xlsx");
      setIsLoadingXlsx(false);
    }
    closeModal();
  };

  return (
    <div>
      <Button onClick={openModal}>
        <ExportIconContainer>
          <ExportIcon src={ExportFile} />
        </ExportIconContainer>
        Exportar
      </Button>

      <ModalWrapper isOpen={modalIsOpen}>
        <ModalContent>
          <h2>Opciones de Exportación</h2>
          <RedButton
            onClick={() => {
              handleExport("pdf");
              setIsLoadingPDF(true);
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faFilePdf} />
            </Icon>
            {isLoadingPDF ? (
              <LoaderComponent width="16px" height="16px" color="white" />
            ) : (
              "Exportar como PDF"
            )}
          </RedButton>
          <GreenButton
            onClick={() => {
              handleExport("csv");
              setIsLoading(true);
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faFileCsv} />
            </Icon>
            {isLoading ? (
              <LoaderComponent width="16px" height="16px" color="white" />
            ) : (
              "Exportar como CSV"
            )}
          </GreenButton>
          <GreenButtonXls
            onClick={() => {
              handleExport("xlsx");
              setIsLoadingXlsx(true);
            }}
          >
            <Icon>
              <FontAwesomeIcon icon={faFileExcel} />
            </Icon>
            {isLoadingXlsx ? (
              <LoaderComponent width="16px" height="16px" color="white" />
            ) : (
              "Exportar como XLSX"
            )}
          </GreenButtonXls>

          <Button onClick={closeModal} cancel>
            Cancelar
          </Button>
        </ModalContent>
      </ModalWrapper>
    </div>
  );
};

export default ExportContacts;
