import gql from 'graphql-tag';

const GET_SPACES_BY_COMPANY = gql`
  query($companyId: ID!) {
    spaces(
      where: { company: { id: $companyId } }
    ) {
      id
      name
      isDefault
      imageUrl
      isGroup
      channels {
        id
        name
        createdAt
        platform { name }
        isActive
      }
    }
  }
`;

const GET_CHANNELS_IN_DEFAULT = gql`
  query($companyId: ID!) {
    spaces(
      where: { company: { id: $companyId } isDefault: true }
    ) {
      id
      name
      isDefault
      channels {
        createdAt
        imageUrl
        id
        name
        platform { name }
      }
    }
  }
`;

const GET_CHANNELS_AVAILABLE_TO_EDIT = gql`
  query ($companyId: ID!, $spaceId: ID!) {
    available: spaces(where: {company: {id: $companyId}, isDefault: true}) {
      channels {
        id
        name
        imageUrl
        createdAt
        updatedAt
        platform {
          name
        }
      }
    }
    space: spaces(where: { id: $spaceId }) {
      id
      name
      channels {
        id
        name
        imageUrl
        createdAt
        updatedAt
        platform {
        name
        }
      }
    }
  }
`;

const GET_DEFAULT_SPACE_BY_COMPANY = gql`
  query($companyId: ID!) {
    spaces(
      where: { company: { id: $companyId }, isDefault: true }
    ) {
      id
      name
      channels {
        id
        name
      }
    }
  }
`;

const ADD_CHANNEL_TO_SPACE = gql`
  mutation addChannelToSpace($spaceId: ID!, $channelId: ID!) {
    updateSpace(where: { id: $spaceId }, data: { channels: { connect: { id: $channelId } } }) {
      id
      name
    }
  }
`;

const DELETE_CHANNEL_FROM_SPACE = gql`
  mutation deleteChannelFromSpace($spaceId: ID!, $channelId: ID!) {
    updateSpace(where: { id: $spaceId }, data: { channels: { disconnect: { id: $channelId } } }) {
      id
      name
    }
  }
`;

const GET_SPACES_BY_CHANNEL_ID = gql`
  query getSpacesByChannelId($channelId: ID!) {
    spaces(where: { channels_some: { id: $channelId } }) {
      id
      name
      isDefault
    }
  }
`;

const ApolloSpaces = {
  GET_SPACES_BY_COMPANY,
  GET_CHANNELS_IN_DEFAULT,
  GET_CHANNELS_AVAILABLE_TO_EDIT,
  GET_DEFAULT_SPACE_BY_COMPANY,
  ADD_CHANNEL_TO_SPACE,
  DELETE_CHANNEL_FROM_SPACE,
  GET_SPACES_BY_CHANNEL_ID,
};

export default ApolloSpaces;
