const CategoryEnum = {
  Global: 'Global',
  Contact: 'Contacto',
  Date: 'Citas',
  Feedback: 'Retroalimentación',
  Assistance: 'Asistencia',
};

Object.freeze(CategoryEnum);
export default CategoryEnum;
