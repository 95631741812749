import * as moment from 'moment';
import 'moment/locale/es';

const formatDateUtil = (date, separator = '-') => {
  const formatDate = moment(date).utc().format(`DD${separator}MM${separator}YY`);
  return formatDate;
};

export const dateFromNow = (date) =>
  moment(date).local().locale("es").calendar(null, {
    lastDay: "[Ayer]",
    sameDay: "HH:mm",
    lastWeek: "DD/MMM",
    sameElse: "DD/MMM",
  });

export const dateFromNowLongVersion = (date) => moment(date).local().locale('es').calendar(null, {
  lastDay: '[Ayer a las] HH:mm',
  sameDay: '[Hoy a las] HH:mm',
  lastWeek: 'DD/MM/YYYY [a las] HH:mm',
  sameElse: 'DD/MM/YYYY [a las] HH:mm',
});

export const dateFromNowWithoutTime = (date) => moment(date).local().locale('es').calendar(null, {
  lastDay: '[Ayer]',
  sameDay: '[Hoy]',
  lastWeek: 'DD/MM/YYYY',
  nextWeek: 'DD/MM/YYYY',
  sameElse: 'DD/MM/YYYY',
});

export const isDialogWindowOpen = (lastWindowDate) => {
  const currentTime = moment();
  const lastSentTemplateDate = moment( lastWindowDate ).local();
  const offsetInMinutes = lastSentTemplateDate.utcOffset();
  const diffInHours = Math.abs(
      lastSentTemplateDate
        .subtract(offsetInMinutes, 'minutes')
        .diff(currentTime, 'minutes')
    ) / 60;
  return diffInHours < 24;
}

export const nowUtcDate = () => {
  const now = moment().utc();
  return now;
};

export const formatTimeUtil = (date) => moment(date).local().format('HH:mm');

export const timestampFormat = (date) => moment(date).format('X');

export const getCurrentDateTimeStrings = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  return {
    date: `${year}-${month}-${day}`,
    time: `${hours}:${minutes}`,
  };
};

export default formatDateUtil;
