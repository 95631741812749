import { useCallback, useState } from "react";
import pusher from "../utils/pusher";

const useBinder = (user, handleNotification) => {
  const services = [
    "onLinkedChannel",
    "newMessage",
    "newClient",
    "updateUserData",
    "deleteConversation",
    "archived",
    "archivedBroadcastConversation",
    "markAsRead",
    "markAsUnread",
    "conversationValuable",
    "messageStatus",
    "finishBotAssignment",
    "newUserName",
    "tagsUpdate",
    "unassignClient",
    "openClient",
    "groupAdmin"
  ];

  const [channel, setChannel] = useState(null);

  const subscribe = useCallback(() => {
    const channel = subscribeToChannel();
    setChannel(channel);
    if (channel) {
      console.log("Binder:: Subscribing to channel: ", channel.name);
      for (const service of services) {
        channel.bind(service, (data) => {
          handleNotification(data);
        });
      }
    }

    return channel;
  }, [user.data?.company?.id, user.data?.rol?.name, user.data?.id]);

  const unsubscribe = () => {
    console.log("Binder:: Unsubscribing: ", channel);
    if (channel) {
      console.log("Binder:: Unsubscribe to channel: ", channel.name);
      services.forEach((service) => channel.unbind(service));
      pusher.unsubscribe(getChannelName());
    }
  };

  const isUserAdmin = () =>
    user.data?.rol?.name === "admin" 
    || user.data?.rol?.name === "superadmin" 
    || user.data?.rol?.name === "groupadmin";

  const subscribeToChannel = () =>
    !!user.data?.company?.id ? pusher.subscribe(getChannelName()) : null;

  const getChannelName = () =>
    isUserAdmin()
      ? `company_${user.data?.company?.id}`
      : `agent_${user.data?.id}`;

  return { subscribe, unsubscribe };
};

export default useBinder;
