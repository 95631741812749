import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import icon from "../../assets/svg/payment.svg";
import API from "../../axios";
import { useEffect } from "react";

const SelectPlanView = () => {
  const [basicPrice, setBasicPrice] = useState(0);
  const [proPrice, setProPrice] = useState(0);
  const [basicAgents, setBasicAgents] = useState(1);
  const [basicMessages, setBasicMessages] = useState(100);
  const [proAgents, setProAgents] = useState(1);
  const [proMessages, setProMessages] = useState(100);

  const getInitialPricing = async () => {
    const pricing = await API.get("/pricing/BASIC/1/100");
    setBasicPrice(pricing.data.pricing);
    const pricingPro = await API.get("/pricing/PRO/1/100");
    setProPrice(pricingPro.data.pricing);
  };

  useEffect(() => {
    getInitialPricing();
  }, []);

  const handleBasicAgents = async (e) => {
    setBasicAgents(e.target.value);
    const pricing = await API.get(
      `/pricing/BASIC/${parseInt(e.target.value, 10)}/${parseInt(
        basicMessages,
        10
      )}`
    );
    setBasicPrice(pricing.data.pricing);
  };

  const handleBasicMessages = async (e) => {
    setBasicMessages(e.target.value);
    const pricing = await API.get(
      `/pricing/BASIC/${parseInt(basicAgents, 10)}/${parseInt(
        e.target.value,
        10
      )}`
    );
    setBasicPrice(pricing.data.pricing);
  };

  const handleProAgents = async (e) => {
    setProAgents(e.target.value);
    const pricing = await API.get(
      `/pricing/PRO/${parseInt(e.target.value, 10)}/${parseInt(
        proMessages,
        10
      )}`
    );
    setProPrice(pricing.data.pricing);
  };

  const handleProMessages = async (e) => {
    setProMessages(e.target.value);
    const pricing = await API.get(
      `/pricing/PRO/${parseInt(proAgents, 10)}/${parseInt(e.target.value, 10)}`
    );
    setProPrice(pricing.data.pricing);
  };

  const selectPlan = async (plan) => {
    await API.post(
      `/update-test-plan`,
      {
        plan,
        agents: plan === "BASIC" ? basicAgents : proAgents,
        messages: plan === "BASIC" ? basicMessages : proMessages,
      }
    );
    window.location.href = "/chat";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        padding: "0 25%",
        backgroundColor: "#3f51b5",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "3rem",
        }}
      >
        <article
          style={{
            backgroundColor: "#fff",
            padding: "2rem",
            borderRadius: "1rem",
            width: "270px",
          }}
        >
          <h4
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              textAlign: "center",
              color: "#2a285f",
              margin: "0.5rem 0",
            }}
          >
            PLAN
          </h4>
          <h4
            style={{
              fontSize: "1.5rem",
              margin: "0.5rem 0",
              fontWeight: "bold",
              textAlign: "center",
              color: "#2a285f",
            }}
          >
            BASIC MENSUAL
          </h4>
          <h6
            style={{
              fontSize: "3rem",
              color: "#2a285f",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "3rem",
                color: "#2a285f",
              }}
            >
              ${basicPrice}
            </span>{" "}
            <span
              style={{
                fontSize: "1.3rem",
                fontWeight: "300",
              }}
            >
              USD
            </span>
          </h6>
          <h6
            style={{
              color: "grey",
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            Promedio {Math.ceil(basicPrice * 17)} MXN mensual
          </h6>
          <hr
            style={{
              margin: "1rem 0",
              borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          />
          <select
            name="basicAgents"
            id="basicAgents"
            value={basicAgents}
            onChange={handleBasicAgents}
            style={{
              width: "100%",
              padding: "0.5rem",
            }}
          >
            <option value="1">1 agente</option>
            <option value="2">2 agentes (multiacceso)</option>
            <option value="3">3 agentes (multiacceso)</option>
            <option value="4">4 agentes (multiacceso)</option>
            <option value="5">5 agentes (multiacceso)</option>
          </select>
          <select
            name="basicMessages"
            id="basicMessages"
            value={basicMessages}
            onChange={handleBasicMessages}
            style={{
              width: "100%",
              padding: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <option value="100">100 conversaciones</option>
            <option value="200">200 conversaciones</option>
            <option value="400">400 conversaciones</option>
            <option value="600">600 conversaciones</option>
            <option value="800">800 conversaciones</option>
          </select>
          <ul
            style={{
              listStyle: "none",
              marginTop: "1rem",
            }}
          >
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> WhatsApp
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Mensajes masivos
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Mensajes programados
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Chatbot multi-opciones
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Encuestas dinámicas
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Soporte Lunes a Viernes
            </li>
          </ul>
          <button
            onClick={() => selectPlan("BASIC")}
            style={{
              color: "#fff",
              backgroundColor: "#001eb4",
              border: "none",
              fontSize: "1.21rem",
              cursor: "pointer",
              width: "100%",
              padding: "1rem",
              borderRadius: "1rem",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Seleccionar
          </button>
        </article>
        <article
          style={{
            backgroundColor: "#fff",
            padding: "2rem",
            borderRadius: "1rem",
            width: "270px",
          }}
        >
          <img src={icon} alt="Más comprado" />

          <h4
            style={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              textAlign: "center",
              color: "#2a285f",
              margin: "0.5rem 0",
            }}
          >
            PLAN
          </h4>
          <h4
            style={{
              fontSize: "1.5rem",
              margin: "0.5rem 0",
              fontWeight: "bold",
              textAlign: "center",
              color: "#2a285f",
            }}
          >
            PRO MENSUAL
          </h4>
          <h6
            style={{
              fontSize: "3rem",
              color: "#2a285f",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "3rem",
                color: "#2a285f",
              }}
            >
              ${proPrice}
            </span>{" "}
            <span
              style={{
                fontSize: "1.3rem",
                fontWeight: "300",
              }}
            >
              USD
            </span>
          </h6>
          <h6
            style={{
              color: "grey",
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            Promedio {Math.ceil(proPrice * 17)} MXN mensual
          </h6>
          <hr
            style={{
              margin: "1rem 0",
              borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          />
          <select
            name="basicAgents"
            id="basicAgents"
            value={proAgents}
            onChange={handleProAgents}
            style={{
              width: "100%",
              padding: "0.5rem",
            }}
          >
            <option value="1">1 agente</option>
            <option value="2">2 agentes (multiacceso)</option>
            <option value="3">3 agentes (multiacceso)</option>
            <option value="4">4 agentes (multiacceso)</option>
            <option value="5">5 agentes (multiacceso)</option>
          </select>
          <select
            name="basicMessages"
            id="basicMessages"
            value={proMessages}
            onChange={handleProMessages}
            style={{
              width: "100%",
              padding: "0.5rem",
              marginTop: "0.5rem",
            }}
          >
            <option value="100">100 conversaciones</option>
            <option value="200">200 conversaciones</option>
            <option value="400">400 conversaciones</option>
            <option value="600">600 conversaciones</option>
            <option value="800">800 conversaciones</option>
            <option value="1000">1000 conversaciones</option>
            <option value="1200">1200 conversaciones</option>
            <option value="1400">1400 conversaciones</option>
            <option value="1600">1600 conversaciones</option>
          </select>
          <ul
            style={{
              listStyle: "none",
              marginTop: "1rem",
            }}
          >
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> WhatsApp
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Mensajes masivos
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Mensajes programados
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Chatbot multi-opciones
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Encuestas dinámicas
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Landing Page + Ads
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Acceso seguro 2F
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Soporte Lunes a Domingo
            </li>
            <li
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "1rem",
                color: "#2a285f",
              }}
            >
              <FontAwesomeIcon icon={faCheckSquare} /> Soporte Lunes a Viernes
            </li>
          </ul>
          <button
            onClick={() => selectPlan("PRO")}
            style={{
              color: "#fff",
              backgroundColor: "#001eb4",
              border: "none",
              fontSize: "1.21rem",
              cursor: "pointer",
              width: "100%",
              padding: "1rem",
              borderRadius: "1rem",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
          >
            Seleccionar
          </button>
        </article>
      </div>
    </div>
  );
};

export default SelectPlanView;
