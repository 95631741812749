import React from "react";

import Loader from "../../assets/loader.svg";

const SplashScreen = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Loader} alt="iVentas" />
    </div>
  );
};

export default SplashScreen;
