/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */

import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import {
  SelectContainer,
  SelectLabel,
  SelectForm,
  SelectIcon,
  SelectValue,
  SelectError,
  Option,
  InputError,
  OnboardingStyleError,
  OnboardingErrorIcon
} from "./Select.style";

import errorIcon from "../../../../assets/svg/onboarding-error.svg";

const Select = forwardRef(
  (
    {
      name,
      onChange,
      options,
      label = null,
      icon = null,
      disabled = false,
      showLabelError = false,
      error = null,
      value,
      required = true,
      newTemplate,
      followUp,
      followUpTemplate,
      onboardingLabelStyle = false,
    },
    ref
  ) => (
    <SelectContainer hasErrors={error}>
      {label && (
        <SelectLabel htmlFor={name} onboardingLabelStyle={onboardingLabelStyle}>
          {label}
        </SelectLabel>
      )}
      <SelectForm error={error} disabled={disabled} onboardingLabelStyle={onboardingLabelStyle}>
        {icon && <SelectIcon src={icon} />}
        {ref ? (
          followUp || followUpTemplate ? (
            <SelectValue
              ref={ref}
              onChange={(e) => onChange(e.target.value)}
              name={name}
              value={value}
              required={required}
              disabled={disabled}
            >
              {options?.map((option) => (
                <option
                  disabled={option.isDisabled}
                  selected={option.isDisabled}
                  key={option.value}
                  value={option.value}
                >
                  {option.text.slice(0, 80)}
                </option>
              ))}
            </SelectValue>
          ) : (
            <SelectValue
              ref={ref}
              onChange={() => onChange(name)}
              name={name}
              required={required}
              disabled={disabled}
            >
              {options?.map((option) => (
                <option
                  disabled={option.isDisabled}
                  selected={option.isDisabled}
                  key={option.value}
                  value={option.value}
                >
                  {option.text.slice(0, 80)}
                </option>
              ))}
            </SelectValue>
          )
        ) : (
          <SelectValue
            onChange={(e) => onChange(name, e)}
            name={name}
            value={value}
            disabled={disabled}
          >
            {options?.map((option) => (
              <option key={option.text} value={option.value}>
                {option.text.slice(0, 80)}
              </option>
            ))}
          </SelectValue>
        )}
      </SelectForm>
      {showLabelError && onboardingLabelStyle && error && (
        <OnboardingStyleError>
          <OnboardingErrorIcon
            src={errorIcon}
            alt="Error"
          />
          <InputError onboardingLabelStyle>{error}</InputError>
        </OnboardingStyleError>
      )}
      {showLabelError && !onboardingLabelStyle && error && <InputError>{error}</InputError>}
    </SelectContainer>
  )
);

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  showLabelError: PropTypes.bool,
  newTemplate: PropTypes.bool,
  followUp: PropTypes.bool,
  followUpTemplate: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: (props, propName) => {
    if (props.ref === null && (props[propName] === undefined || props[propName] === null)) {
      return new Error("Please provide value prop if ref prop is not provided");
    }
    if (typeof props[propName] !== "number" && typeof props[propName] !== "string") {
      return new Error("value props must be a number or string");
    }
  },
  ref: (props, propName) => {
    if (props.value === null && (props[propName] === undefined || props[propName] === null)) {
      return new Error("Please provide ref prop if value prop is not provided");
    }
  },
  onboardingLabelStyle: PropTypes.bool,
};

export default Select;
