import client from '../apollo/apolloClient';
import ApolloSurvey from '../apollo/apolloSurvey';
import API from '../axios';

export const saveSurveyInfo = async (data) => {
  console.log('POST SurveyConfig API ==================')
  try {
    const response = await API.post(`/saveClientSurvey`, data);
    return response.data;
  } catch (error) {
    console.log('Error at saving survey config request => ', error)
    throw error;
  }
};


export const createSurveyConfig = async (
  companyId,
  channelId,
  initText,
  options,
) => {
  try {
    const response = await API.post(`/survey`, {
      companyId: companyId,
      channelId: channelId,
      initText: initText,
      options: options
    });
    return response.data;
  } catch (error) {
    console.log('ERROR_createSurveyConfig', error)
    throw new Error('ERROR_createSurveyConfig');
  }
};

export const getSurveyConfig = async (
  companyId,
) => {
  try {
    const response = await API.get(`/survey?companyId=${companyId}`);
    return response.data?.pop();
  } catch (error) {
    console.log('ERROR_GETTING_surveyConfigs', error)
    throw new Error('ERROR_GETTING_surveyConfigs');
  }
};

export const getLastSurveyInfo = async (
  clientId,
) => {
  try {
    const response = await API.get(`/survey/last?clientId=${clientId}`);
    return response.data?.pop();
  } catch (error) {
    console.log('ERROR_getLastSurveyInfo', error)
    throw new Error('ERROR_getLastSurveyInfos');
  }
};

export const updateSurveyConfig = async (
  id,
  initText,
  options,
) => {
  try {
    const response = await API.put(`/survey`, {
      id: id,
      initText: initText,
      options: options
    });
    return response.data;
  } catch (error) {
    console.log('ERROR_updateSurveyConfig', error)

    throw new Error('ERROR_updateSurveyConfig');
  }
};

export const deleteSurveyOptions = async (
  ids,
) => {
  try {
    const response = await API.post(`/survey/options`, {
      ids: ids,
    });
    return response.data;
  } catch (error) {
    console.log('ERROR_deleteManySurveyOptions', error)

    throw new Error('ERROR_deleteManySurveyOptions');
  }
};

// export const createSurveyConfig = async (
//   companyId,
//   channelId,
//   initText,
//   options,
// ) => {
//   try {
//     const response = await client.mutate({
//       mutation: ApolloSurvey.CREATE_SURVEY_CONFIG,
//       variables: { companyId, initText, channelId, options },
//     });
//     const result = response.data?.createSurveyConfig;
//     console.log("createSurveyConfig =====> ", result)
//     return result;
//   } catch (error) {
//     console.log('ERROR_createSurveyConfig', error)
//     throw new Error('ERROR_createSurveyConfig');
//   }
// };

// export const updateSurveyConfig = async (
//   id,
//   initText,
//   options,
// ) => {
//   try {
//     const response = await client.mutate({
//       mutation: ApolloSurvey.UPDATE_SURVEY_CONFIG,
//       variables: { id, initText, options},
//     });
//     const result = response.data?.updateSurveyConfig;
//     console.log("updateSurveyConfig =====> ", result)
//     return result;
//   } catch (error) {
//     console.log('ERROR_updateSurveyConfig', error)

//     throw new Error('ERROR_updateSurveyConfig');
//   }
// };

// export const deleteSurveyOptions = async (
//   ids,
// ) => {
//   try {
//     const response = await client.mutate({
//       mutation: ApolloSurvey.DELETE_SURVEY_OPTIONS,
//       variables: { ids },
//     });
//     const result = response.data?.deleteManySurveyOptions;
//     console.log("deleteManySurveyOptions =====> ", result)
//     return result;
//   } catch (error) {
//     console.log('ERROR_deleteManySurveyOptions', error)

//     throw new Error('ERROR_deleteManySurveyOptions');
//   }
// };

// export const getSurveyConfig = async (
//   companyId,
// ) => {
//   try {
//     const response = await client.query({
//       query: ApolloSurvey.GET_SURVEY_CONFIG_BY_COMPANY,
//       variables: {  companyId },
//     });
//     const result = response.data?.surveyConfigs;
//     console.log("surveyConfigs =====> ", result)
//     return result;
//   } catch (error) {
//     console.log('ERROR_GETTING_surveyConfigs', error)
//     throw new Error('ERROR_GETTING_surveyConfigs');
//   }
// };
