import { FaSearch } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import styled from "styled-components";

export const PanelContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 100%;
`;

export const CalendarSection = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonActions = styled.div`
  display: flex;
  width: 10%;
  justify-content: space-evenly;
`;

export const SearchIcon = styled(FaSearch)``;
export const RefreshIcon = styled(FiRefreshCcw)``;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 60vh;
`;

export const ImportButtonContainer = styled.div`
  width: 10.75rem;
  height: 2.75rem;
`;

export const ImportIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const ImportIcon = styled.img`
  width: 100%;
`;

export const ExportIconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const ExportIcon = styled.img`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th`
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  
`;
export const Tr = styled.tr`
  ${(props) =>
    props.isEven ? "background-color: #f2f2f2;" : "background-color: white;"}
  ${(props) => props.selected && "background-color: rgb(234, 241, 251)"}
`;
export const MoreInfoButton = styled.button`
  background-color: #848488;
  width: 80%;
  border-radius: 9px;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
`;
