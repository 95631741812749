import * as _ from 'lodash';

import CONSTANTS from '../const/constants';

import {
  findRoles, createRole, getPermissions, findRoleByCompany, validateRoleUsers, removeRole, findEqualRoles,
  findPermissionByName, updateRole,
} from '../utils/roles';

const ROLES_INIT_STATE = {
  roles: [],
  fetched: false,
  fetching: false,
  selected: {},
  index: null,
  editRoleSuccess: false,
};
const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
const CLEAR_ROLES = 'CLEAR_ROLES';
const GET_ROLES = 'GET_ROLES';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const EDITTING_ROLE = 'EDITTING_ROLE';
const ROLE_EDIT_SUCCESS = 'ROLE_EDIT_SUCCESS';
const CANCEL_EDITION = 'CANCEL_EDITION';
const INSERTING_ROLE = 'INSERTING_ROLE';
const INSERT_END = 'INSERT_END';
const ROLE_EDIT_ERROR = 'ROLE_EDIT_ERROR';

export default function rolesReducer(state = ROLES_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ROLES:
      return { ...state, fetching: true };
    case INSERTING_ROLE:
      return { ...state, fetching: true };
    case INSERT_END:
      return { ...state, fetching: false };
    case GET_ROLES_SUCCESS:
      return { ...state, fetching: false, fetched: true, roles: payload };
    case SET_SELECTED_ROLE:
      return { ...state, selected: payload, fetching: true };
    case EDITTING_ROLE:
      return { ...state, fetching: true };
    case ROLE_EDIT_SUCCESS:
      return { ...state, selected: {}, roles: payload };
    case ROLE_EDIT_ERROR:
      return { ...state, fetching: false };
    case CANCEL_EDITION:
      return { ...state, selected: {} };
    case CLEAR_ROLES:
      return { ...ROLES_INIT_STATE };
    default:
      return state;
  }
}

export const setRoleToEditAction = (role) => (dispatch) => {
  const defaultValues = {};
  _.forEach(role.permissions, (permission) => {
    defaultValues[permission.name] = true;
  });
  if (role.id) {
    defaultValues.rolName = role.name;
    role.defaultValues = defaultValues;
  }
  dispatch({ type: SET_SELECTED_ROLE, payload: role });
};

export const getSelectedRole = (getState) => {
  const selectedRole = getState().roles.selected;
  return selectedRole;
};

const serchForEqualRole = (newRole, roles) => {
  const rolesToAdd = _.sortBy(newRole.permissions);
  const isEqual = _.some(roles, (savedRole) => {
    savedRole = _.map(savedRole.permissions, (permission) => permission.name);
    const storedRole = _.sortBy(savedRole);
    return _.isEqual(rolesToAdd, storedRole);
  });
  return isEqual;
};

export const getAllRolesAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ROLES });
    const companyId = getState().user.data.company.id;
    const roles = await findRoles(companyId);

    dispatch({ type: GET_ROLES_SUCCESS, payload: roles });
    return roles;
  } catch (error) {
    return error;
  }
};

export const insertRole = (role) => async (dispatch, getState) => {
  const companyId = getState().user.data.company.id;
  const savedRoles = getState().roles.roles;
  try {
    if (_.isEmpty(role.permissions)) throw new Error('EMPTY_PERMISSSIONS_ERROR');
    await dispatch({ type: INSERTING_ROLE });
    role.name = _.capitalize(role.name);
    const key = `${companyId}-${role.name}`;

    role.permissions.push(CONSTANTS.DEFAULT_PERMISSION);

    role.permissions = await findPermissionByName(role.permissions);
    const created = await createRole(role, companyId, key);
    if (created.id) {
      savedRoles.push(created);
      await dispatch({ type: GET_ROLES_SUCCESS, payload: savedRoles });
    }
    dispatch({ type: INSERT_END });
    return created;
  } catch (error) {
    dispatch({ type: INSERT_END });
    throw error;
  }
};

export const editRoleAction = (role) => async (dispatch, getState) => {
  const companyId = getState().user.data.company.id;
  role.key = `${companyId}-${role.name}`;
  try {
    dispatch({ type: EDITTING_ROLE });
    if (_.isEmpty(role.permissions)) throw new Error('EMPTY_PERMISSSIONS_ERROR');
    role.permissions.push(CONSTANTS.DEFAULT_PERMISSION);

    role.permissions = await findPermissionByName(role.permissions);
    const edited = await updateRole(role);
    if (edited.id) {
      let { roles } = getState().roles;
      roles = _.map(roles, (stateRole) => {
        if (_.isEqual(stateRole.id, edited.id)) return edited;
        return stateRole;
      });
      dispatch({ type: ROLE_EDIT_SUCCESS, payload: roles });
    }
    return edited;
  } catch (error) {
    dispatch({ type: ROLE_EDIT_ERROR });
    throw error;
  }
};

export const findPermissions = () => async () => {
  try {
    const data = await getPermissions();
    return data.permissions;
  } catch (error) {
    return error;
  }
};

export const deleteRoleAction = (role) => async (dispatch, getState) => {
  try {
    if (role.name !== CONSTANTS.SUPERADMIN && role.name !== CONSTANTS.ADMIN) {
      const savedRoles = getState().roles.roles;
      const response = await validateRoleUsers(role);
      if (_.isEmpty(response.users)) {
        const deletedRole = await removeRole(role.id);
        if (deletedRole.id) {
          _.remove(savedRoles, ['id', deletedRole.id]);
          dispatch({ type: GET_ROLES_SUCCESS, payload: savedRoles });
        }
        return deletedRole;
      }
      throw new Error('ROLE_ASSIGNED_TO_USERS');
    }
    throw new Error('ADMIN_ROLES_CANNOT_BE_DELETED');
  } catch (error) {
    throw error;
  }
};

export const cancelEdit = () => async (dispatch) => {
  dispatch({ type: CANCEL_EDITION, payload: null });
};
