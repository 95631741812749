import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { FiUpload } from "react-icons/fi";

import API from "../../axios";
import {
  DescriptionContainer,
  DescriptionInput,
  DescriptionLabel,
  Error,
  FileInput,
  FileInputContainer,
  FileInputLabel,
  Label,
  PrimaryButton,
  SecondaryButton,
  TalkToExpertLabel,
  WhatsappButton,
  BusinessLabel,
  BusinessInput,
  BusinessTextArea,
  BusinessSelect
} from "./Onboarding.styles";

const WhatsappLinkProfile = () => {
  const history = useHistory();
  const inputRef = useRef();

  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [website, setWebsite] = useState(null);
  const [email, setEmail] = useState(null);
  const [businessDescription, setBusinessDescription] = useState(null);
  const [address, setAddress] = useState(null);
  const [vertical, setVertical] = useState(null);

  const [file, setFile] = useState(null);
  const [about, setAbout] = useState("");

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      if (file.size > 15000000) throw "La imagen debe ser menor a 15MB";

      await new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => {
          if (image.width > 640 || image.height > 640) {
            reject("La imagen debe ser menor a 640x640");
          } else {
            resolve();
          }
        });
        image.src = URL.createObjectURL(file);
        window.removeEventListener("load", image);
      });

      setImageError("");
      setFile(file);
    } catch (error) {
      setImageError(error);
    }
  };

  const handleDescriptionChange = (event) => {
    setAbout(event.target.value);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      event.preventDefault();
      if (about.length >= 256) {
        setDescriptionError(
          "La descripción debe tener menos de 256 caracteres"
        );
        throw new Error("La descripción debe tener menos de 256 caracteres");
      }

      if (imageError) throw new Error(imageError);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("about", about.trim());

      formData.append("website", [website.trim()]);
      formData.append("vertical", vertical);
      formData.append("email", email.trim());
      formData.append("description", businessDescription.trim());
      formData.append("address", address.trim());

      await API.post(`/link-whatsapp/profile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      history.push("/link-whatsapp/qrcode");
    } catch (error) {
      console.error(error);
      switch (error?.message) {
        case "UPLOADING_ABOUT_ERROR":
          setDescriptionError("Hubo un error al actualizar la descripción");
          break;
        case "UPLOADING_BUSINESS_INFO_ERROR":
          setDescriptionError(
            "Hubo un error al actualizar la información del negocio"
          );
        case "UPLOADING_PHOTO_ERROR":
          setImageError("Hubo un error al actualizar la foto de perfil");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (about.length === 0) {
      setDescriptionError("");
    }
  }, [about]);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
        overflowY: "scroll"
      }}
    >
      <Label>Configura la información de tu perfil</Label>
      <form onSubmit={handleSubmit}>
        <FileInputContainer>
          <FileInputLabel htmlFor="file">
            <FiUpload />
            {file ? (
              <span
                style={{
                  marginLeft: "10px",
                  fontFamily: "SpaceGrotesk, sans-serif",
                  fontSize: " 0.875rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {file.name}
              </span>
            ) : (
              <span style={{ marginLeft: "10px" }}>Seleccionar imagen</span>
            )}
          </FileInputLabel>
          <FileInput
            type="file"
            id="file"
            name="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleFileChange}
          />
        </FileInputContainer>
        <br />
        <br />
        <br />
        <DescriptionContainer>
          <BusinessLabel htmlFor="description">Información del negocio</BusinessLabel>
          <DescriptionInput
            type="text"
            id="description"
            name="description"
            value={about}
            onChange={handleDescriptionChange}
          />
        </DescriptionContainer>
        <>
          <BusinessLabel htmlFor="vertical">Vertical</BusinessLabel>
          <BusinessSelect
            value={vertical}
            onChange={(e) => setVertical(e.target.value)}
          >
            <option value="Automotive">Automotriz</option>
            <option value="Beauty, Spa and Salon">Belleza, Spa y Salón</option>
            <option value="Clothing and Apparel">
              Ropa y Prendas de Vestir
            </option>
            <option value="Education">Educación</option>
            <option value="Entertainment">Entretenimiento</option>
            <option value="Event Planning and Service">
              Planeación de Eventos y Servicios
            </option>
            <option value="Finance and Banking">Finanzas y Banca</option>
            <option value="Food and Grocery">Comida y Abarrotes</option>
            <option value="Public Service">Servicios Públicos</option>
            <option value="Hotel and Lodging">Hoteles y Alojamiento</option>
            <option value="Medical and Health">Salud y Medicina</option>
            <option value="Non-profit">
              Organizaciones sin Fines de Lucro
            </option>
            <option value="Professional Services">
              Servicios Profesionales
            </option>
            <option value="Shopping and Retail">
              Compras y Ventas al Menudeo
            </option>
            <option value="Travel and Transportation">
              Viajes y Transporte
            </option>
            <option value="Restaurant">Restaurante</option>
            <option value="Other">Otro</option>
          </BusinessSelect>


          <br />
          <BusinessLabel htmlFor="website">Sitio Web Negocio</BusinessLabel>
          <BusinessInput
            type="url"
            name="website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            error={error}
            ref={inputRef}
          />

          <BusinessLabel htmlFor="email">Correo Negocio</BusinessLabel>
          <BusinessInput
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={error}
            ref={inputRef}
          />

          <BusinessLabel htmlFor="address">Dirección</BusinessLabel>
          <BusinessInput
            type="text"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            error={error}
            ref={inputRef}
          />

          <BusinessLabel htmlFor="businessDescription">
            Descripción Negocio
          </BusinessLabel>
          <BusinessTextArea
            name="businessDescription"
            value={businessDescription}
            onChange={(e) => setBusinessDescription(e.target.value)}
            error={error}
            ref={inputRef}
          />
        </>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "30px",
            alignItems: "center",
            width: "50%",
          }}
        >
          {loading && <div>Cargando...</div>}
          {!loading && (
            <PrimaryButton type="submit" disabled={loading}>
              Guardar
            </PrimaryButton>
          )}
        </div>
      </form>

      {imageError && <Error>{imageError}</Error>}
      {descriptionError && (
        <Error>
          Hubo un error al agregar tu información de perfil: {descriptionError}.
        </Error>
      )}
    </main>
  );
};

export default WhatsappLinkProfile;
