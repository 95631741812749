import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import parse from "html-react-parser";

import Button from "../button/Button.style";
import ButtonComponent from "../button/Button.component";
import pusher from "../../../../utils/pusher";
import {
  ModalBlur,
  ModalContainer,
  ModalMainText,
  ModalButtonsContainer,
  Modal,
  InputContainer,
  ModalSecundaryText,
  SuccessContact,
  ModalImageContainer,
  ModalImage,
  ModalSecundaryTextContainer,
  ModalInfoContainer,
  ModalInfo,
  CloseModalIconContainer,
  CloseModalIcon,
  ErrorContact,
  CardWhatsappLink,
  ModalInfoUl,
  ModalInfoLi,
  ModalInfoImageContainer,
  ModalInfoImage,
  ModalQualityText,
} from "./GeneralModal.style";
import successIcon from "../../../../assets/images/success@3x.png";
import successContactIcon from "../../../../assets/svg/succes-logoContact.svg";
import useFormBuilder from "../../../../hooks/useFormBuilder";
import Input from "../Input/Input.component";
import Select from "../select/Select.component";
import RadioOptions from "../radioOptions/RadioOptions.component";
import CustomPhoneInput from "../phoneInput/CustomPhoneInput.component";
import {
  ImportButtonContainer,
  ImportIcon,
  ImportIconContainer,
  Table,
  Td,
  Th,
  Tr,
} from "../../../../views/panel/panel.style";
import DropZoneImage from "../dropZoneImage/DropZoneImage.component";
import CheckCircleModal from "../../../../assets/svg/CheckCircleModal.svg";
import Info from "../../../../assets/svg/InfoImportContact.svg";
import closeModalIcon from "../../../../assets/svg/closeIcon.svg";
import avatar from "../../../../assets/svg/importContacAvatar.svg";
import excelExaple from "../../../../assets/images/excelExaple.png";
import UploadFile from "../../../../assets/svg/UploadFile.svg";
import LoaderComponent from "../../../loader/Loader.component";
import {
  CheckboxInput,
  CheckboxLabel,
  Checkmark,
} from "../../../chat/endChatModal/EndChatModal.style";
import CustomPagination from "../CustomPagination/CustomPagination";
import ProgressBar from "../ProgressBar/ProgressBar.component";
import { useSelector } from "react-redux";

const ITEMS_PER_PAGE = 7;

const GeneralModal = ({
  mainText,
  cancelText,
  handleCancelButton,
  confirmText,
  handleConfirmButton,
  handleCloseModal,
  show,
  type,
  inputName,
  defaultInputValue,
  arrayForm,
  secundaryText,
  loadingForm = false,
  onWatch = null,
  fixed = false,
  dynamicFields = [],
  successImportContac = [],
  errorImportContac = [],
  isImportContact = false,
  broadcastquality = null,
  updatedClient = false,
}) => {
  const formInputs =
    arrayForm?.length > 0 ? arrayForm.map((x) => x.name) : [inputName];
  const {
    form,
    register: registerForm,
    unregister: unregisterForm,
    errors,
    watch,
    triggerValidation,
    handleSubmit,
    control,
  } = useFormBuilder(
    formInputs,
    { [inputName]: defaultInputValue },
    false,
    true,
    dynamicFields,
    true
  );

  const watchAllFields = watch();
  const [files, setFiles] = useState([]);
  const user = useSelector((state) => state.user);
  const [isInbox, setIsInbox] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const bulletList = [
    'Máximo 5mil contactos</b>.',
    'Nombre, Apellido, Teléfono, Etiquetas, Correo del agente, deberán estar como campos predeterminados <b class="custom-bold" >en 5 celdas diferentes</b>.',
    "Los campos a partir de la columna 5 se consideran campos personalizados.",
    "Puedes añadir tus campos personalizados desde la celda 5.",
    'Usar la plantilla para importar, al dar en <b class="custom-bold" >Continuar</b> se descargará automáticamente.',
    'Todos deben de tener el <b class="custom-bold" >LADA</b> del país al que correspondan.',
    'Para números nacionales en <b class="custom-bold" >México</b> tendrás que poner (521).',
    'Para números <b class="custom-bold">argentinos</b> tendrás que poner (549).',
    'La lista de etiquetas tiene que ser separada por una coma simple ",".',
  ];
  const [progress, setProgress] = useState(0);
  const prepareFiles = (newFiles) => {
    setFiles(newFiles);
  };

  const changeisInbox = () => {
    setIsInbox(!isInbox);
  };
  const handlePageChange = ({ selected }) => {
    console.log("selected", selected);
    setCurrentPage(selected);
  };

  // const downliadFile = () => {
  //   const url = 'URL_DEL_ARCHIVO_EN_S3'; // Reemplaza con el enlace directo al objeto en S3
  //   axios({
  //     url: url,
  //     method: 'GET',
  //     responseType: 'blob', // Especifica que esperamos una respuesta en formato blob (archivo)
  //   })
  //     .then(response => {
  //       // Crea un objeto de Blob con la respuesta y descárgalo
  //       const blob = new Blob([response.data]);
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'archivo_desde_s3.txt'; // Nombre del archivo que se descargará
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       a.remove();
  //     })
  //     .catch(error => {
  //       console.error('Error al descargar el archivo desde S3:', error);
  //     });
  // };

  useEffect(() => {
    if (onWatch) onWatch(watchAllFields);
  }, [watchAllFields]);

  useEffect(() => {
    console.log(arrayForm);
    console.log(formInputs);
    console.log(form);
    if (arrayForm?.length > 0 && form.length > 0) {
      form.forEach((input) => {
        if (input?.type === "select") {
          const inputFormName = arrayForm
            .filter((x) => x.name === input.name)
            .pop();
          input.options = inputFormName?.options ||
            input.options || [
              { value: "", text: "Elige...", isDisabled: true },
            ];
        }
      });
    }
  }, [registerForm, arrayForm]);

  const handleNotification = (data) => {
    setProgress(data.progress);
  };

  useEffect(() => {
    if (user.data?.company?.id) {
      let channel;
      if (
        user.data?.rol?.name === "admin" ||
        user.data?.rol?.name === "superadmin" ||
        user.data?.rol?.name === "groupadmin"
      ) {
        channel = pusher.subscribe(`company_${user.data?.company?.id}`);
      } else {
        channel = pusher.subscribe(`agent_${user.data?.id}`);
      }

      channel.bind("progressBar", (data) => {
        handleNotification(data);
      });
    }
  }, []);

  useEffect(() => {
    // Lógica para filtrar y paginar los datos aquí
    if (successImportContac?.length === 0) return;
    const filteredAndPaginatedData = successImportContac?.slice(
      currentPage * ITEMS_PER_PAGE,
      (currentPage + 1) * ITEMS_PER_PAGE
    );
    setFilteredData(filteredAndPaginatedData);
  }, [currentPage, successImportContac]);

  const getDataform = () => {
    console.log(document.getElementById("modalForm").submit());
  };
  return (
    <Modal show={show}>
      {type === "general" && (
        <>
          <ModalBlur fixed={fixed} />
          <ModalContainer
            type={type}
            fixed={fixed}
            isImportContact={isImportContact}
          >
            {mainText.split("\n").map((text, index) => (
              <ModalMainText
                key={text}
                hasMarginTop={index === 0}
                hasMarginBottom={index === mainText.split("\n").length - 1}
              >
                {text}
              </ModalMainText>
            ))}
            {secundaryText && (
              <ModalSecundaryText>{secundaryText}</ModalSecundaryText>
            )}
            {
              <form id="modalForm" onSubmit={handleSubmit(handleConfirmButton)}>
                {(inputName || arrayForm?.length > 0) &&
                  form.map((input, index) => {
                    if (arrayForm?.[index]?.name && !arrayForm?.[index]?.show) {
                      return null;
                    }

                    switch (input.type) {
                      case "input":
                        return (
                          <InputContainer key={input.name}>
                            <Input
                              ref={registerForm({
                                required: arrayForm?.[index]?.show,
                              })}
                              error={get(errors, `${input.name}.message`)}
                              watch={watch}
                              onChange={triggerValidation}
                              name={input.name}
                              label={input.label}
                              placeholder={input.placeholder}
                              type={input.inputType}
                              showLabelError
                            />
                          </InputContainer>
                        );
                      case "select":
                        return (
                          <InputContainer>
                            <Select
                              name={input.name}
                              label={input.label}
                              options={input.options}
                              onChange={triggerValidation}
                              ref={registerForm({
                                required: arrayForm?.[index]?.show,
                              })}
                            />
                          </InputContainer>
                        );
                      case "radio":
                        return (
                          <InputContainer>
                            <RadioOptions
                              ref={registerForm({
                                required: arrayForm?.[index]?.show,
                              })}
                              name={input.name}
                              options={input.options}
                              label={input.label}
                              error={get(errors, `${input.name}.message`)}
                            />
                          </InputContainer>
                        );
                      case "intlPhone":
                        return (
                          <CustomPhoneInput input={input} control={control} />
                        );
                      default:
                        return null;
                    }
                  })}
              </form>
            }
            {/* <CheckboxLabel
              style={{
                paddingRight: "52px",
                marginLeft: "40px",
              }}
            >
              ¿Deseas que tus contactos aparezcan en la lista principal de
              chats?
              <CheckboxInput
                type="checkbox"
                checked={isInbox}
                onChange={changeisInbox}
              />
              <Checkmark checked={isInbox} />
            </CheckboxLabel> */}

            <ModalButtonsContainer importContacChannelButton={isImportContact}>
              <Button
                secondary
                onClick={handleCancelButton}
                type="button"
                importCancelButtonChannels={isImportContact}
              >
                {cancelText}
              </Button>
              {!isImportContact && (
                <ButtonComponent
                  type="submit"
                  onClick={handleSubmit(handleConfirmButton)}
                  loading={loadingForm}
                  disabled={loadingForm}
                  text={confirmText}
                />
              )}
              {isImportContact && (
                <Button
                  type="submit"
                  onClick={handleSubmit(handleConfirmButton)}
                  importConfirmButton
                  loading={loadingForm}
                  disabled={loadingForm}
                >
                  {confirmText}
                </Button>
              )}
            </ModalButtonsContainer>
          </ModalContainer>
        </>
      )}
      {type === "success" && (
        <>
          <ModalBlur transparent />
          <ModalContainer type={type} updatedClient={updatedClient}>
            {updatedClient ? (
              <img src={successContactIcon} width={80} alt={mainText} />
            ) : (
              <img src={successIcon} width={80} alt={mainText} />
            )}
            <ModalMainText type={type}>{mainText}</ModalMainText>
          </ModalContainer>
        </>
      )}

      {type === "importContactsSuccess" && (
        <>
          <ModalBlur transparent fixed />
          <ModalContainer type={type} fixed>
            <CloseModalIconContainer
              onClick={handleCloseModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "20px",
              }}
            >
              <CloseModalIcon src={closeModalIcon} alt="close" />
            </CloseModalIconContainer>
            <ModalMainText type={type}>Resultados de Importación</ModalMainText>
            <div
              style={{
                maxHeight: "350px",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <Table>
                <thead>
                  <Tr>
                    <Th style={{ textAlign: "center" }}>Número o Nombre</Th>
                    <Th style={{ textAlign: "center" }}>Estado</Th>
                    <Th style={{ textAlign: "center" }}>Mensaje</Th>
                  </Tr>
                </thead>

                <tbody>
                  {filteredData.map((result, index) => (
                    <Tr key={index}>
                      <Td
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: "200px",
                        }}
                      >
                        {result.number}
                      </Td>
                      <Td>{result.status}</Td>
                      <Td>{result.message}</Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <CustomPagination
              pageCount={Math.ceil(
                successImportContac?.length / ITEMS_PER_PAGE
              )}
              onPageChange={handlePageChange}
            />
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Button
                importConfirmButton
                onClick={handleCloseModal}
                type="button"

              >
                Continuar
              </Button>

            </div>
          </ModalContainer>
        </>
      )}
      {type === "importContactInfo" && (
        <>
          <ModalBlur transparent fixed />
          <ModalContainer type={type} fixed style={{ minHeight: "40rem" }}>
            <ModalMainText type={type} style={{
              margin: "1rem"
            }}>
              Para importar tus contactos, tu archivo en Excel debe contener lo
              siguiente:
            </ModalMainText>
            <ModalInfoUl>
              {bulletList.map((el) => (
                <ModalInfoLi>{parse(el)}</ModalInfoLi>
              ))}
            </ModalInfoUl>

            <span>
              {/* <DownloadFile onClick={downliadFile}>Descargar la plantilla para importar contactos</DownloadFile> */}
              <a href="https://iventas-media.s3.amazonaws.com/ImportarContactos.xlsx" download>Descargar la plantilla para importar contactos</a>
            </span>
            <ModalInfoImageContainer>
              <ModalInfoImage src={excelExaple} />
            </ModalInfoImageContainer>

            <ModalButtonsContainer importContactButton>
              <Button
                importCancelButton
                onClick={handleCancelButton}
                type="button"
              >
                {cancelText}
              </Button>
              <ButtonComponent
                type="submit"
                onClick={handleConfirmButton}
                importConfirmButton
                loading={loadingForm}
                disabled={loadingForm}
                text={"Continuar"}
              />
            </ModalButtonsContainer>
          </ModalContainer>
        </>
      )}
      {type === "broadcastQualityModal" && (
        <>
          <ModalBlur transparent />
          <ModalContainer type={"broadcastQuality"}>
            <ModalMainText type={"broadcastQuality"}>
              {broadcastquality === "Baja" && (
                <>
                  {"La calidad de tu cuenta de Facebook es  "}
                  <ModalQualityText color={"#F61E44"}>
                    {`${broadcastquality}`}
                  </ModalQualityText>
                  . Hasta que mejore la calidad de tu cuenta, no puedes enviar
                  campañas
                </>
              )}
              {broadcastquality === "Media" && (
                <>
                  La calidad de tu cuenta de Facebook es
                  <ModalQualityText
                    color={"#FF9518"}
                  >{`${broadcastquality}`}</ModalQualityText>
                  , te sugerimos mandar campañas pequeñas para evitar baneos
                </>
              )}
            </ModalMainText>
            <Button
              onClick={
                broadcastquality === "Media"
                  ? handleConfirmButton
                  : handleCancelButton
              }
              broadcastQualityModal
              loading={loadingForm}
              disabled={loadingForm}
              text={"Continuar"}
            >
              Entendido
            </Button>
          </ModalContainer>
        </>
      )}
      {type === "importContact" && (
        <>
          <ModalBlur transparent fixed />
          <ModalContainer type={type} fixed>
            <ModalMainText type={type}>
              Selecciona tu archivo Excel para importar tus contactos, recuerda que máximo son 5mil contactos
            </ModalMainText>
            <ModalSecundaryText importContactInfo>
              {secundaryText}
            </ModalSecundaryText>

            <ModalButtonsContainer
              importContactInfoButton
              isloadingForm={loadingForm}
            >
              <Button
                importInfoCancelButton
                isloadingForm={loadingForm}
                onClick={handleCancelButton}
                type="button"
              >
                {cancelText}
              </Button>
              {loadingForm ? (
                // <LoaderComponent width={15} height={15} />
                <ProgressBar progress={progress} />
              ) : (
                <DropZoneImage
                  prepareFiles={(newFile) => prepareFiles(newFile)}
                  acceptedFilesTypes={["text/csv"]}
                  onDrop={handleConfirmButton}
                  mainText={"Importar"}
                  importContacts
                  minWidth={30}
                />
              )}
            </ModalButtonsContainer>
          </ModalContainer>
        </>
      )}
    </Modal>
  );
};

GeneralModal.propTypes = {
  mainText: PropTypes.string.isRequired,
  secundaryText: PropTypes.string,
  cancelText: PropTypes.string,
  handleCancelButton: PropTypes.func,
  confirmText: PropTypes.string,
  handleConfirmButton: PropTypes.func,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["general", "success"]).isRequired,
  inputName: PropTypes.string,
  defaultInputValue: PropTypes.string,
  arrayForm: PropTypes.array,
  loadingForm: PropTypes.bool,
  onWatch: PropTypes.func,
  fixed: PropTypes.bool,
  dynamicFields: PropTypes.array,
};


export default GeneralModal;