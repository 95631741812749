import { cloneDeep } from "lodash";

import {
  deleteCampaign,
  getCampaigns,
  getCampaignsByChannelId,
} from "../utils/campaign";

const CAMPAIGN_STATE = {
  loading: false,
  data: [],
  selectedCampaign: {},
  selected: {
    template: {},
    newFileUrl: "",
  },
  create: {
    type: "TAG",
    tagsIds: [],
    channelId: "",
    sendAt: "",
    name: "",
    shouldCreateTag: false,
    tagToCreate: {
      name: "",
      color: "",
    },
    conjunction: null,
  },
};

const GET_CAMPAIGNS = "GET_CAMPAIGNS";
const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCESS";
const SET_TAGS = "SET_TAGS";
const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";
const SELECTED_TEMPLATE = "SELECTED_TEMPLATE";
const SET_NAME = "SET_NAME";
const SET_TAG_TO_CREATE = "SET_TAG_TO_CREATE";
const SET_NOT_CREATE_TAG = "SET_NOT_CREATE_TAG";
const SET_SEND_AT = "SET_SEND_AT";
const SET_SELECTED_FILE = "SET_SELECTED_FILE";
const SELECTED_CAMPAIGN = "SELECTED_CAMPAIGN";
const CLEAN_SELECTED_FILE = "CLEAN_SELECTED_FILE";
const SET_CONJUNCTION = "SET_CONJUNCTION";

export default function campaignReducer(state = CAMPAIGN_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CAMPAIGNS:
      return { ...state, loading: true };
    case GET_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case SET_TAGS:
      return { ...state, create: { ...state.create, tagsIds: payload } };
    case SET_SELECTED_CHANNEL:
      return { ...state, create: { ...state.create, channelId: payload } };
    case SELECTED_TEMPLATE:
      return { ...state, selected: payload };
    case SET_NAME:
      return { ...state, create: { ...state.create, name: payload } };
    case SET_SEND_AT:
      return { ...state, create: { ...state.create, sendAt: payload } };
    case SET_SELECTED_FILE:
      return { ...state, selected: { ...state.selected, newFileUrl: payload } };
    case SET_TAG_TO_CREATE:
      return {
        ...state,
        create: {
          ...state.create,
          shouldCreateTag: true,
          tagToCreate: payload,
        },
      };
    case SET_NOT_CREATE_TAG:
      return {
        ...state,
        create: {
          ...state.create,
          shouldCreateTag: false,
          tagToCreate: {
            name: "",
            color: "",
          },
        },
      };
    case SELECTED_CAMPAIGN:
      return { ...state, selectedCampaign: payload };
    case CLEAN_SELECTED_FILE:
      return { ...state, selected: { ...state.selected, newFileUrl: "" } };
    case SET_CONJUNCTION:
      return { ...state, create: { ...state.create, conjunction: payload } };
    default:
      return state;
  }
}

export const getCampaignsByCompanyAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CAMPAIGNS });
    const companyId = getState().user.data.company.id;
    const { campaigns } = await getCampaigns(companyId);
    dispatch({ type: GET_CAMPAIGNS_SUCCESS, payload: cloneDeep(campaigns) });
    return cloneDeep(campaigns);
  } catch (error) {
    throw error;
  }
};

export const getCampaignsByChannelAction = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CAMPAIGNS });
    const { campaigns } = await getCampaignsByChannelId(channelId);
    dispatch({ type: GET_CAMPAIGNS_SUCCESS, payload: cloneDeep(campaigns) });
    return cloneDeep(campaigns);
  } catch (error) {
    throw error;
  }
};

export const deleteCampaignAction = (id) => async (dispatch, getState) => {
  try {
    await deleteCampaign(id);
    const currentCampaigns = getState().campaign.data;
    const newCampaigns = currentCampaigns.filter(
      (campaign) => campaign.id !== id
    );
    dispatch({ type: GET_CAMPAIGNS_SUCCESS, payload: newCampaigns });
  } catch (error) {
    throw error;
  }
};

export const setSelectedTagsAction = (ids) => async (dispatch) => {
  try {
    dispatch({ type: SET_TAGS, payload: ids });
  } catch (error) {
    throw error;
  }
};

export const setSelectedConjunctionAction = (conjunction) => async (dispatch) => {
  try {
    dispatch({ type: SET_CONJUNCTION, payload: conjunction });
  } catch (error) {
    throw error;
  }
};

export const setSelectedTemplateAction = (template) => async (dispatch) => {
  try {
    dispatch({ type: SELECTED_TEMPLATE, payload: template });
  } catch (error) {
    throw error;
  }
};

export const setNameAction = (name) => async (dispatch) => {
  try {
    dispatch({ type: SET_NAME, payload: name });
  } catch (error) {
    throw error;
  }
};

export const setTagToCreateAction = (name, color) => async (dispatch) => {
  try {
    dispatch({ type: SET_TAG_TO_CREATE, payload: { name, color } });
  } catch (error) {
    throw error;
  }
};

export const setNotTagToCreateAction = () => async (dispatch) => {
  try {
    dispatch({ type: SET_NOT_CREATE_TAG });
  } catch (error) {
    throw error;
  }
};

export const setSendAtAction = (sendAt) => async (dispatch) => {
  try {
    dispatch({ type: SET_SEND_AT, payload: sendAt });
  } catch (error) {
    throw error;
  }
};

export const setSelectedFileAction = (newFileUrl) => async (dispatch) => {
  try {
    console.log(newFileUrl);
    dispatch({ type: SET_SELECTED_FILE, payload: newFileUrl });
  } catch (error) {
    throw error;
  }
};

export const setSelectedChannelIdAction = (channelId) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELECTED_CHANNEL, payload: channelId });
  } catch (error) {
    throw error;
  }
};

export const setSelectedCampaignAction = (campaign) => async (dispatch) => {
  try {
    dispatch({ type: SELECTED_CAMPAIGN, payload: campaign });
  } catch (error) {
    throw error;
  }
}

export const cleanSelectedFileAction = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAN_SELECTED_FILE });
  } catch (error) {
    throw error;
  }
}