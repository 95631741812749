import CONSTANTS from '../const/constants';
import { getContacts, getContactsByKeyword } from '../utils/contacts';
import cleanString from '../utils/cleanString';

const CONTACTS_INIT_STATE = {
  data: [],
  selected: [],
  started: false,
  fetching: false,
  error: false,
};

const GETTING_CONTACTS = 'GETTING_CONTACTS';
const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
const GET_CONTACTS_ERROR = 'GET_CONTACTS_ERROR';
const RESET_CONTACTS = 'RESET_CONTACTS';
const SET_SELECTION_CONTACTS_ACTIVE = 'SET_SELECTION_CONTACTS_ACTIVE';

export default function contactsReducer(state = CONTACTS_INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_CONTACTS:
      return { ...state, fetching: true, data: payload, started: true };
    case GET_CONTACTS_SUCCESS:
      return { ...state, fetching: false, data: payload };
    case GET_CONTACTS_ERROR:
      return { ...state, fetching: false, error: payload };
    case RESET_CONTACTS:
      return { ...state, fetching: false, data:[], started: false,  selected: []};
    case SET_SELECTION_CONTACTS_ACTIVE:
        return { ...state, selected: payload.selectedContacts };
    default:
      return state;
  }
}

export const searchContactsAction = (keyword) => async (dispatch, getState) => {
  console.log('keyword =======> ', keyword)
  try {
    const currentContacts = getState().contacts.data;

    //await dispatch({ type: GETTING_CONTACTS, payload: {} });

    const selectedChannelId = getState().conversations.current.channel.id;

    let selectedFilters = [];

    const companyId = getState().user.data.company.id;

    selectedFilters.companyId = companyId;
    selectedFilters.channelId = selectedChannelId;
    selectedFilters.keyword =  cleanString(keyword);


    const contacts = await getContactsByKeyword(selectedFilters);

    const items = [];

    if (currentContacts && currentContacts.length > 0) {
      items.push(...currentContacts.filter(c => c.selected))
    }

    for (let contact of contacts.filter(c => c.channel.isActive))
      if (!items.some(c => c.id === contact.id)) 
        items.push({
          id: contact.id,
          chatId: contact.senderId,
          nameFromChannel: contact.nameFromChannel,
          imageUrl: contact.imageUrl,
          selected: false,
          companyId: contact.company.id,
          platformApi: contact.channel.platform.api,
          pageId: contact.channel.pageId,
          pageToken: contact.channel.pageToken,
        })

    dispatch({ type: GET_CONTACTS_SUCCESS, payload: items });
  } catch (error) {
    dispatch({ type: GET_CONTACTS_ERROR, payload: error });
    console.log('error===========> ', error)
    const JcnError = new Error('CANNOT_GET_CONTACTS');
    throw JcnError;
  }
};

export const getContactsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GETTING_CONTACTS, payload: {} });

    const selectedChannelId = getState().conversations.current.channel.id;

    let selectedFilters = [];

    const companyId = getState().user.data.company.id;

    selectedFilters.companyId = companyId;
    selectedFilters.channelId = selectedChannelId;

    const contacts = await getContacts(selectedFilters);
    
    const items = [];
    for (let contact of contacts.filter(c => c.channel.isActive))
      items.push({
        id: contact.id,
        chatId: contact.senderId,
        nameFromChannel: contact.nameFromChannel,
        imageUrl: contact.imageUrl,
        selected: false,
        companyId: contact.company.id,
        platformApi: contact.channel.platform.api,
        pageId: contact.channel.pageId,
        pageToken: contact.channel.pageToken,
      })

    dispatch({ type: GET_CONTACTS_SUCCESS, payload: items });
  } catch (error) {
    dispatch({ type: GET_CONTACTS_ERROR, payload: error });
    const JcnError = new Error('CANNOT_GET_CONTACTS');
    throw JcnError;
  }
};

export const selectingContactsAction = (contactsIds) => async (dispatch, getState) => {
  try {
    const contacts = getState().contacts.data;
    const currentSelectedContacts = getState().contacts.selected;

    for (let contactId of contactsIds) {
      const index = currentSelectedContacts.indexOf(contactId);
      if (index === -1)
        currentSelectedContacts.push(contactId)
      else 
        currentSelectedContacts.splice(index, 1)
    }

    for (let contact of contacts)
      contact.selected = currentSelectedContacts.some(id => contact.chatId === id)

    dispatch({ type: SET_SELECTION_CONTACTS_ACTIVE, payload: { selectedContacts: currentSelectedContacts }});
  } catch (error) {
  }
};

export const resetContactsAction = () => async (dispatch, getState) => {
  dispatch({ type: RESET_CONTACTS, payload: {} });
};