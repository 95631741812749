import gql from "graphql-tag";

const GET_AGENT_NAME = gql`
  query agents($id: ID!) {
    agents(where: { id: $id }) {
      name
    }
  }
`;

const GET_AGENT_BY_EMAIL = gql`
  query users($email: String!) {
    users(where: { email: $email }) {
      id
      email
      name
      company {
        id
      }
    }
  }
`;

const GET_AGENTS_BY_COMPANY_ID_ROLES = gql`
  query users($companyId: String!, $roles: [String!]) {
    users(where: { companyId: $companyId, rol: { name_in: $roles } }) {
      id
      name
      email
      imageUrl
      company {
        id
        name
      }
      phone
      referralCode
      rol {
        id
        name
        permissions {
          name
        }
      }
      spaces {
        id
        name
      }
    }
  }
`;

const GET_AGENTS_BY_FILTERS = (companyId, rolesIds, spacesIds) => gql`
  query getAgentsByFilters(
    ${companyId ? "$companyId: String!," : ""}
    ${rolesIds?.length > 0 ? "$rolesIds: [ID!]," : ""}
    ${spacesIds?.length > 0 ? "$spacesIds: [ID!]" : ""}
  ) {
    users(where: {
      ${companyId ? "companyId: $companyId," : ""}
      ${rolesIds?.length > 0 ? "rol: { id_in: $rolesIds }," : ""}
      ${spacesIds?.length > 0 ? "spaces_some: { id_in: $spacesIds }" : ""}
    }) {
      id
      name
      email
      imageUrl
      company {
        id
        name
      }
      phone
      referralCode
      isAvailable
      rol {
        id
        name
        permissions {
          name
        }
      }
      spaces {
        id
        name
      }
    }
  }
`;

const GET_AGENTS_BY_COMPANY_AND_ROLES = gql`
  query users($companyId: String!, $roles: [String!]) {
    users(where: { companyId: $companyId, rol: { name_in: $roles } }) {
      id
      name
      email
      imageUrl
      company {
        id
        name
      }
      categories {
        id
        name
      }
      phone
      referralCode
      rol {
        id
        name
        permissions {
          name
        }
      }
      isAvailable
    }
  }
`;

const GET_AGENTS_BY_COMPANY_AND_CATEGORIES = gql`
  query users($companyId: String!, $categories: [String!]) {
    users(
      where: {
        companyId: $companyId
        categories_some: { name_in: $categories }
      }
    ) {
      id
      name
      email
      imageUrl
      company {
        id
        name
      }
      categories {
        id
        name
      }
      phone
      referralCode
      rol {
        id
        name
        permissions {
          name
        }
      }
      isAvailable
    }
  }
`;

const GET_AGENTS_BY_COMPANY = gql`
  query users($companyId: String!) {
    users(where: { companyId: $companyId }) {
      id
      name
      email
      imageUrl
      phone
      referralCode
      rol {
        id
        name
        permissions {
          name
        }
      }
      isAvailable
      spaces {
        id
        name
      }
    }
  }
`;

const DELETE_AGENT = gql`
  mutation ($documentId: ID!) {
    deleteUser(where: { id: $documentId }) {
      id
    }
  }
`;

const DELETE_AGENT_BY_EMAIL = gql`
  mutation ($email: String!) {
    deleteAgent(where: { email: $email }) {
      id
    }
  }
`;

const ADD_AGENT = gql`
  mutation createAgent(
    $name: String!
    $email: String!
    $password: String!
    $companyId: String!
    $location: ID!
    $category: String!
    $phone: String!
  ) {
    createAgent(
      data: {
        name: $name
        email: $email
        password: $password
        companyId: $companyId
        location: { connect: { id: $location } }
        category: $category
        phone: $phone
      }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      location {
        id
        name
      }
      category
      phone
    }
  }
`;

const UPDATE_AGENT = gql`
  mutation updateAgent(
    $id: ID!
    $name: String!
    $email: String!
    $location: ID!
    $category: String!
    $phone: String!
  ) {
    updateAgent(
      where: { id: $id }
      data: {
        name: $name
        email: $email
        location: { connect: { id: $location } }
        category: $category
        phone: $phone
      }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      location {
        id
        name
      }
      category
      phone
    }
  }
`;

const UPDATE_AGENT_AVAIABILITY = gql`
  mutation updateAgent($id: ID!, $isAvailable: Boolean!) {
    updateUser(where: { id: $id }, data: { isAvailable: $isAvailable }) {
      id
      isAvailable
    }
  }
`;

const UPDATE_AGENT_BY_EMAIL = gql`
  mutation updateAgent(
    $name: String!
    $email: String!
    $location: ID!
    $phone: String!
    $previousEmail: String!
  ) {
    updateAgent(
      where: { email: $previousEmail }
      data: {
        name: $name
        email: $email
        location: { connect: { id: $location } }
        phone: $phone
      }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      location {
        id
        name
      }
      category
      phone
    }
  }
`;

const GET_AGENT_BY_ID = gql`
  query agents($id: ID!) {
    agents(where: { id: $id }) {
      id
      name
    }
  }
`;

const GET_AGENTS_BY_LOCATION_ID = gql`
  query agents($locationId: ID!) {
    agents(where: { location: { id: $locationId } }) {
      id
      name
    }
  }
`;

const GET_AGENTS_BY_COMPANY_ID_AND_NOT_LOCATION_ID = gql`
  query agents($companyId: String!, $locationIds: [ID!]) {
    agents(
      where: { company: $companyId, location: { id_not_in: $locationIds } }
    ) {
      id
      name
      location {
        id
        name
      }
    }
  }
`;

export const GET_AGENTS_BY_PERMISSIONS = gql`
  query usersForReassign($companyId: String!, $spaceId: ID!) {
    users(
      where: {
        companyId: $companyId
        rol: { permissions_some: { name: "agent" } }
        spaces_some: { id: $spaceId }
      }
    ) {
      id
      name
      imageUrl
    }
  }
`;

const GET_AGENTS_BY_SPACE_ID = gql`
  query users($spaceId: ID!) {
    users(where: { spaces_some: { id: $spaceId } }) {
      id
      name
      imageUrl
      rol {
        name
      }
    }
  }
`;

const GET_AGENTS_NOT_SUPERADMINS_BY_SPACE_ID = gql`
  query users($spaceId: ID!) {
    users(
      where: { spaces_some: { id: $spaceId }, rol: { name_not: "superadmin" } }
    ) {
      id
      name
      imageUrl
      rol {
        name
      }
    }
  }
`;

const GET_ADMINS_BY_SPACE_ID = gql`
  query users($spaceId: ID!) {
    users(
      where: {
        spaces_some: { id: $spaceId }
        rol: { name_in: ["admin", "superadmin", "groupadmin"] }
      }
    ) {
      id
      name
      imageUrl
    }
  }
`;

const ApolloAgents = {
  GET_AGENT_NAME,
  GET_AGENT_BY_EMAIL,
  GET_AGENTS_BY_COMPANY_ID_ROLES,
  GET_AGENTS_BY_COMPANY_AND_ROLES,
  GET_AGENTS_BY_COMPANY_AND_CATEGORIES,
  DELETE_AGENT,
  ADD_AGENT,
  UPDATE_AGENT,
  GET_AGENT_BY_ID,
  GET_AGENTS_BY_COMPANY,
  GET_AGENTS_BY_LOCATION_ID,
  GET_AGENTS_BY_COMPANY_ID_AND_NOT_LOCATION_ID,
  DELETE_AGENT_BY_EMAIL,
  UPDATE_AGENT_BY_EMAIL,
  GET_AGENTS_BY_PERMISSIONS,
  UPDATE_AGENT_AVAIABILITY,
  GET_AGENTS_BY_FILTERS,
  GET_AGENTS_BY_SPACE_ID,
  GET_ADMINS_BY_SPACE_ID,
  GET_AGENTS_NOT_SUPERADMINS_BY_SPACE_ID,
};

export default ApolloAgents;
