import styled, { css } from "styled-components";

export const WhatsappTemplateSelectContainer = styled.section`
  z-index: 1;
  background-color: white;
  overflow: scroll;
  overflow-x: hidden;
  padding: 15px;
  height: 100%;
  max-height: ${(props) =>
    props.isFromModal ? "40vh" : "66vh"};
`;
export const WhatsappTemplateSelectorContainer = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
`;


export const WhatsappTemplateTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid #e3e3e3;
`;

export const WhatsappTemplateTitle = styled.div`
  font-family: 'SpaceGrotesk-SemiBold';
  font-style: normal;
  font-weight: 500;
  display: flex;
  width: 54%;
  margin-left: 1rem;
  color: #2E3353;
`;
export const WhatsappTemplateInfo = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.iswhatsappTemplateSelected ? "row" : "column"};

  cursor: pointer;
  ${props => props.align === "center" && css`
    align-items: center;
  `}
`;

export const WhatsappTemplateInputContainer = styled.div`
  position: relative;
  height: 2.5rem;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.botFollowUp &&
    css `
      width: 70%;
      height: 3rem;
      margin-top: 1rem;
    `}
`;
export const WhatsappTemplateButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
`;
export const ButtonIcon = styled.img`
  width: 100%;
`;

export const WhatsappTemplateLabel = styled.label`
  position: absolute;
  font-family: "SpaceGrotesk-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  top: -9px;
  background: white;
  color: #2e3353;
  left: 8px;
`;
export const WhatsappTemplateSelect = styled.select`
  width: 100%;
  height: 100%;
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "500px",
  maxHeight: "100px",
  border: 2px solid #e9e9e9;
  border-radius: 4px;

  font-family: "SpaceGrotesk-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;

  & > option {
    font-family: "SpaceGrotesk-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 150%;
    max-width: 500px;
  }
`;

export const WhatsappTemplateTitleLabel = styled.span`
  font-family: 'SpaceGrotesk-SemiBold', sans-serif;
  font-style: normal;
  font-size: 1rem;
  font-weight: 500;
  color: #2E3353;
  padding-left: 0.438rem;
`;

export const WhatsappTemplateInfoLabel = styled.span`
  margin-top: 5px;
  font-family: "SpaceGrotesk-Medium", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #757575;
  padding-left: 0.438rem;
`;
export const WhatsappTemplateRet = styled.span`
  font-family: "SpaceGrotesk-Medium", "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  text-decoration-line: underline;
  color: #2e3353;
`;

export const WhatsappTemplateWarningLabel = styled.span`
  font-family: "Montserrat-Bold", sans-serif;
  padding-left: 1rem;
  font-size: 0.8rem;
  color: #595959;
`;

export const WhatsappTemplateContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 1rem 1rem 0.75rem;
  background-color: #F5F4F6;
  margin-bottom: 1.563rem;
  &:hover {
    background-color: #ddf7fc;
    cursor: pointer;
  }

  ${(props) =>
    props.iswhatsappTemplateSelected &&
    css`
      padding-bottom: 0;
      margin-bottom: 0;
    `}
`;

export const WhatsappTemplateDescription = styled.div`
  margin-bottom: 1rem;
  ${(props) =>
    props.iswhatsappTemplateSelected &&
    css`
      margin-bottom: 0.5rem;
    `}
`;

export const WhatsappTemplateDescriptionTitle = styled.h5`
  font-family: "SpaceGrotesk-Medium", "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #2e3353;
  margin-bottom: 0.5rem;
`;

export const WhatsappTemplateDescriptionText = styled.p`
  font-family: SpaceGrotesk-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  padding: 0.75rem 2rem 2rem 0.75rem;
  color: #2e3353;
  background-color: #e0fcd6;
  border-radius: 8px;

  & > * {
    font-family: SpaceGrotesk-Regular, sans-serif;
    font-size: 0.875rem;
  }
`;
export const WhatsappTemplateSearchContainer = styled.div`
  width: 95%;
  margin: 0.875rem 1rem 0 1rem;
  ${(props) =>
    props.templateHead &&
    css`
      width: 24%;
      margin: 0.875rem 2.5rem 1rem 1rem;
    `}

  ${(props) =>
    props.contacView &&
    css`
      width: 20.675rem;
      margin: 0 0 0 1rem;
      
    `}
`;

export const WhatsappTemplateDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0fcd6;
  margin-left: 3.125rem;
  margin-right: 3.125rem;
  margin-bottom: 0.3rem;
  position: relative;
  border-radius: 8px;
`;

export const WhatsappTemplateDetails = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const CurrentTime = styled.span`
  font-family: "SpaceGrotesk-Medium";
  margin-right: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: -0.257238px;
  color: #757575;
`;

export const WhatsappCustomTemplateBtnIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;

export const SuggestionMedia = styled.span`
  font-family: 'SpaceGrotesk-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.257238px;
  margin-bottom: 1rem;
`;


