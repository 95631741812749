import { find, forEach, isUndefined } from "lodash";
import CONSTANTS from "./constants";

const Labels = [
  {
    key: "btnDelete",
    module: "General",
    label: "ELIMINAR",
  },
  {
    key: "btnSave",
    module: "General",
    label: "Guardar",
  },
  {
    key: "btnSaving",
    module: "General",
    label: "Guardando ...",
  },
  {
    key: "btnCancel",
    module: "General",
    label: "Cancelar",
  },
  {
    key: "btnOk",
    module: "General",
    label: "Aceptar",
  },
  {
    key: "deleteOption",
    module: "General",
    label: "Eliminar",
  },
  {
    key: "editOption",
    module: "General",
    label: "Editar",
  },
  {
    key: "btnFinish",
    module: "General",
    label: "Finalizar",
  },
  {
    key: "btnNext",
    module: "General",
    label: "Siguiente",
  },
  {
    key: "btnCancel",
    module: "General",
    label: "Cancelar",
  },
  {
    key: "sendingLabel",
    module: "General",
    label: "Enviando ...",
  },
  {
    key: "btnPay",
    module: "General",
    label: "Iniciar Membresía",
  },
  {
    key: "processPayWith",
    module: "General",
    label: "Pagos procesados con",
  },
  {
    key: "paymentProvider",
    module: "General",
    label: "Stripe",
  },
  {
    key: "exportTitle",
    module: "General",
    label: "Exportar",
  },
  {
    key: "exportToCsv",
    module: "General",
    label: "Exportar a CSV",
  },
  {
    key: "exportFileTitle",
    module: "General",
    label: (initDate, endDate) => `Reporte del ${initDate} al ${endDate}`,
  },
  {
    key: "searchPlaceholder",
    module: "General",
    label: "Buscar",
  },
  {
    key: "chooseAnOption",
    module: "General",
    label: "Elige ...",
  },
  {
    key: "noResults",
    module: "General",
    label: "Sin resultados",
  },
  {
    key: "erroEreachUserLimit",
    module: "Agents",
    codeError: "REACH_USERS_LIMIT_ERROR",
    label: "Has alcanzado el límite de usuarios permitidos",
  },
  {
    key: "errorEeachAdminsLimit",
    module: "Agents",
    codeError: "REACH_ADMINS_LIMIT_ERROR",
    label: "Has alcanzado el límite de usuarios administradores permitidos",
  },
  {
    key: "errorUpdatingData",
    module: "Agents",
    codeError: "ERROR_UPDATING_DATA",
    label: "Error al actualizar el registro",
  },
  {
    key: "errorDeletingSuperadmin",
    module: "Agents",
    codeError: "SUPERADMIN_CANNOT_BE_DELETED",
    label: "El super administrador no puede ser eliminado",
  },
  {
    key: "agentSuccessDeleted",
    module: "Agents",
    label: "Agente eliminado con éxito",
  },
  {
    key: "errorUserWithSameEmail",
    module: "Agents",
    codeError: "USER_WITH_SAME_EMAIL",
    label: "El correo electrónico ya ha sido registrado",
  },
  {
    key: "configurationViewTitle",
    module: "Configuration",
    label: "Configuración",
  },
  {
    key: "generalTab",
    module: "Configuration",
    label: "Datos generales",
  },
  {
    key: "plansTab",
    module: "Configuration",
    label: "Planes",
  },
  {
    key: "chatBotTab",
    module: "Configuration",
    label: "Chatbot",
  },
  {
    key: "whatsappTemplates",
    module: "Configuration",
    label: "Plantillas para WhatsApp",
  },
  {
    key: "mainTextDragImage",
    module: "WhatsappTemplates",
    label: "Arrastra aquí tus Imagenes",
  },
  {
    key: "mainTextDragDoc",
    module: "WhatsappTemplates",
    label: "Arrastra aquí tus Documentos",
  },
  {
    key: "sizeFileDragImage",
    module: "WhatsappTemplates",
    label: "Tu archivo puede pesar máximo 16 MB",
  },
  {
    key: "tagsTab",
    module: "Configuration",
    label: "Etiquetas",
  },
  {
    key: "rolesTab",
    module: "Configuration",
    label: "Roles y permisos",
  },
  {
    key: "imagesTab",
    module: "Configuration",
    label: "Imágenes",
  },

  {
    key: "dataSheetConfigurationTab",
    module: "Configuration",
    label: "Datos del Contacto",
  },
  {
    key: "configTab",
    module: "Configuration",
    label: "Configuración",
  },
  {
    key: "saveButton",
    module: "Configuration",
    label: "Guardar cambios",
  },
  {
    key: "dropZoneGeneralDataImageLabel",
    module: "Configuration",
    label: "Imagen de la página",
  },
  {
    key: "dropZoneGeneralDataMainText",
    module: "Configuration",
    label: "Editar",
  },
  {
    key: "dropZoneGeneralDataSecondaryText",
    module: "Configuration",
    label: "Esta es la imagen que se mostrará en el inicio de la plataforma",
  },
  {
    key: "dropZoneFormatError",
    module: "Configuration",
    codeError: "IMAGE_FORMAT_ERROR",
    // eslint-disable-next-line max-len
    label: (minWidth, maxWidth) =>
      `La imagen debe ser cuadrada y medir mínimo ${minWidth} x ${minWidth}px y máximo ${maxWidth}x${maxWidth}px`,
  },
  {
    key: "updateGeneralDataError",
    module: "Configuration",
    codeError: "UPDATE_GENERAL_DATA_ERROR",
    label: "Error al editar la información de la empresa.",
  },
  {
    key: "updateGeneralDataSuccess",
    module: "Configuration",
    label: "La información de la empresa ha sido actualizada con éxito",
  },
  {
    key: "tablePaginationCounter",
    module: "Table",
    // eslint-disable-next-line max-len
    label: (page, rowsPerPage, count) =>
      `Mostrando ${page * rowsPerPage + 1} - ${
        rowsPerPage * (page + 1) > count ? count : rowsPerPage * (page + 1)
      } de ${count}`,
  },
  {
    key: "tablePreviousLabel",
    module: "Table",
    label: "Anterior",
  },
  {
    key: "tableNextLabel",
    module: "Table",
    label: "Siguiente",
  },
  {
    key: "searchInputPlaceholder",
    module: "Table",
    label: "Buscar",
  },
  {
    key: "deleteModalButtonLabel",
    module: "Table",
    label: "Eliminar",
  },
  {
    key: "mainTextModalTagsWidget",
    module: "TagsWidget",
    label:
      "¿Estás seguro de que deseas eliminar esta etiqueta? Se perderán los datos",
  },
  {
    key: "mainTextModalWhatsappTemplatesWidget",
    module: "TagsWidget",
    label:
      "¿Estás seguro de que deseas eliminar esta plantilla? Se perderán los datos",
  },
  {
    key: "cancelTextModalTagsWidget",
    module: "TagsWidget",
    label: "Cancelar",
  },
  {
    key: "cancelTextModalWhatsappTemplatesWidget",
    module: "TagsWidget",
    label: "Cancelar",
  },
  {
    key: "confirmTextModalWhatsappTemplatesWidget",
    module: "TagsWidget",
    label: "Eliminar",
  },
  {
    key: "confirmTextModalTagsWidget",
    module: "TagsWidget",
    label: "Eliminar",
  },
  {
    key: "tagNameColumnTagsWidget",
    module: "TagsWidget",
    label: "Etiqueta",
  },
  {
    key: "statusAccepted",
    module: "WhatsappTemplates",
    label: "Listo para usarse",
  },
  {
    key: "statusDenied",
    module: "WhatsappTemplates",
    label: "No aprobado",
  },
  {
    key: "statusRevision",
    module: "WhatsappTemplates",
    label: "En revisión",
  },
  {
    key: "errorDeleteTemplateAll",
    module: "WhatsappTemplates",
    label: "La plantilla se está utilizando en los bots y mensajes programados",
  },
  {
    key: "errorDeleteTemplateFolloUp",
    module: "WhatsappTemplates",
    label: "La plantilla se está ultilizando en mensajes programados",
  },
  {
    key: "errorDeleteTemplateFollowUp",
    module: "WhatsappTemplates",
    label: "La plantilla se está ultilizando en mensajes programados",
  },
  {
    key: "errorDeleteTemplateBotFollowUp",
    module: "WhatsappTemplates",
    label: "La plantilla se está ultilizando en el bot de seguimiento",
  },
  {
    key: "errorDeleteTemplateWelcomeMessages",
    module: "WhatsappTemplates",
    label: "La plantilla se está ultilizando en el bot de bienvenida",
  },
  {
    key: "errorDeleteTemplateFolloUpAndBot",
    module: "WhatsappTemplates",
    label:
      "La plantilla se está ultilizando en mensajes programados y el bot de seguimiento",
  },
  {
    key: "errorDeleteTemplateBotFollowUpAndWelcome",
    module: "WhatsappTemplates",
    label:
      "La plantilla se está ultilizando en el bot de seguimiento y el bot de bienvenida",
  },
  {
    key: "errorDeleteTemplateFolloAndWelcome",
    module: "WhatsappTemplates",
    label:
      "La plantilla se está ultilizando en mensajes programados y el bot de bienvenida",
  },
  {
    key: "createdAtColumnWhatsappTemplatesWidget",
    module: "WhatsappTemplates",
    label: "Fecha de creación",
  },
  {
    key: "createWhatsappTemplateSuccess",
    module: "WhatsappTemplates",
    label: "Plantilla añadida con éxito",
  },
  {
    key: "createdAtColumnTagsWidget",
    module: "TagsWidget",
    label: "Fecha de creación",
  },
  {
    key: "assignedAgent",
    module: "Chat",
    label: "Agente asignado",
  },
  {
    key: "reassignOption",
    module: "Chat",
    label: "Reasignar",
  },
  {
    key: "personalInformation",
    module: "Chat",
    label: "Datos de Usuario",
  },
  {
    key: "notAssigned",
    module: "Chat",
    label: "Sin asignar",
  },
  {
    key: "category",
    module: "Chat",
    label: "Categoría",
  },
  {
    key: "channel",
    module: "Chat",
    label: "Canal",
  },
  {
    key: "agent",
    module: "Chat",
    label: "Agente",
  },
  {
    key: "priority",
    module: "Chat",
    label: "Prioridad",
  },
  {
    key: "labels",
    module: "Chat",
    label: "Etiquetas",
  },
  {
    key: "forwardModalContactsTitle",
    module: "Chat",
    label: "Reenviar mensaje a",
  },
  {
    key: "forwardMessagesSuccess",
    module: "Chat",
    label: "Mensajes reenviados correctamente",
  },
  {
    key: "forwardMessagesError",
    module: "Chat",
    label: "Error al Reenviar mensajes",
  },
  {
    key: "addNewTagLabelButton",
    module: "Tags",
    label: "Añadir etiqueta",
  },
  {
    key: "tagsRouteLabel",
    module: "Tags",
    label: "Etiquetas",
  },
  {
    key: "whatsappTemplatesRouteLabel",
    module: "WhatsappTemplates",
    label: "Plantillas para WhatsApp",
  },
  {
    key: "newWhatsappTemplateSuggestion",
    module: "WhatsappTemplates",
    label:
      "El nombre solo debe de contener minúsculas, caracteres alfanuméricos y guión bajo",
  },
  {
    key: "variableInstructions",
    module: "WhatsappTemplates",
    label:
      "Escribe tu mensaje y, una vez que hayas hecho click en continuar, identificaremos automáticamente por ti.",
  },
  {
    key: "identifyVariableInstructions",
    module: "WhatsappTemplates",
    label:
      "Si los campos personalizables no están bien, puedes eliminarlos y crear nuevos seleccionando las palabras que quieras personalizar. ",
  },
  {
    key: "variableInstructionsMessagePreview",
    module: "WhatsappTemplates",
    label:
      "Recuerda que podrás editar tus campos personalizables desde el chat. <br>¡Así podrás darle un mejor seguimiento a cada contacto!",
  },
  {
    key: "whatsappInstructionsMessagePreview",
    module: "WhatsappTemplates",
    label: "¡Así se verá tu mensaje!",
  },
  {
    key: "infoTitle",
    module: "WhatsappTemplates",
    label: "Escribe tu mensaje",
  },
  {
    key: "whatsappTemplateMultimediaTitle",
    module: "WhatsappTemplates",
    label: "¿Quieres agregar multimedia a tu mensaje?",
  },
  {
    key: "broadcastSelectTemplateTitle",
    module: "WhatsappTemplates",
    label: "Selecciona una plantilla",
  },
  {
    key: "broadcastSelectMultimediaTitle",
    module: "WhatsappTemplates",
    label: "¿Quieres cambiar la multimedia de tu plantilla?",
  },
  {
    key: "whatsappTemplateMessagePreviewTitle",
    module: "WhatsappTemplates",
    label: "Vista previa de tu mensaje",
  },
  {
    key: "editorLabel",
    module: "WhatsappTemplates",
    label: "Mensaje",
  },
  {
    key: "WhatsappTemplateNameLabel",
    module: "WhatsappTemplates",
    label: "Para terminar, dale un nombre a tu plantilla",
  },
  {
    key: "broadcastName",
    module: "Broadcasts",
    label: "Para terminar, dale un nombre a tu campaña",
  },
  {
    key: "broadcastSelectDateTitle",
    module: "Broadcasts",
    label: "Selecciona la fecha y la hora en la que se enviará tu mensaje:",
  },
  {
    key: "broadcastPreviewTitle",
    module: "Broadcasts",
    label: "Vista previa de tu mensaje de campaña",
  },
  {
    key: "info",
    module: "WhatsappTemplates",
    label:
      "Puedes editar las variables de tus plantillas personalizadas para programar un mensaje de seguimiento desde la información del contacto.",
  },
  {
    key: "subtitle",
    module: "WhatsappTemplates",
    label: "Por favor escribe ejemplos de lo que pondrás en tus variables.",
  },
  {
    key: "maximumNumberCharacteres",
    module: "WhatsappTemplates",
    label: "1024 caracteres",
  },
  {
    key: "maximumNumberCharacteresTemplate",
    module: "WhatsappTemplates",
    label: "Error: el contenido no puede ser mayor a 1024 caracteres",
  },
  {
    key: "averageNumberCharacteres",
    module: "WhatsappTemplates",
    label: "60 caracteres",
  },
  {
    key: "minimumNumberCharacteres",
    module: "WhatsappTemplates",
    label: "20 caracteres",
  },
  {
    key: "addButton",
    module: "WhatsappTemplates",
    label: "+ Agregar botón",
  },
  {
    key: "labelStatus",
    module: "WhatsappTemplates",
    label: "Status",
  },
  {
    key: "labelColor",
    module: "Tags",
    label: "Color",
  },
  {
    key: "newWhatsappTempateRouteLabel",
    module: "whatsappTemplate",
    label: "Nueva plantilla",
  },
  {
    key: "templateMessage",
    module: "whatsappTemplate",
    label: "Mensaje",
  },
  {
    key: "removeTag",
    module: "whatsappTemplate",
    label: "Eliminar variable",
  },
  {
    key: "addTag",
    module: "whatsappTemplate",
    label: "Añadir variable",
  },
  {
    key: "newTagRouteLabel",
    module: "Tags",
    label: "Nueva etiqueta",
  },
  {
    key: "editTagRouteLabel",
    module: "Tags",
    label: "Editar etiqueta",
  },
  {
    key: "editSuccessLabel",
    module: "Tags",
    label: "Etiqueta editada con éxito",
  },
  {
    key: "reachLimitTags",
    module: "Tags",
    label: "Has alcanzado el máximo de etiquetas permitido",
  },
  {
    key: "reachLimitWhatsappTemplates",
    module: "Tags",
    label: "Has alcanzado el máximo de plantillas permitido",
  },
  {
    key: "defaultTagMessage",
    module: "Tags",
    label: "Las etiquetas predefinidas no pueden ser editadas o eliminadas",
  },
  {
    key: "addNewTagSuccessLabel",
    module: "Tags",
    label: "Etiqueta añadida con éxito",
  },
  {
    key: "errorCreatingTag",
    module: "Configuration",
    codeError: "ERROR_CREATING_TAG",
    label: "Error al crear etiqueta",
  },
  {
    key: "errorGettingTag",
    module: "Configuration",
    codeError: "ERROR_GETTING_TAGS",
    label: "Error obtener las etiquetas",
  },
  {
    key: "errorDeletingTag",
    module: "Configuration",
    codeError: "ERROR_DELETING_TAG",
    label: "Error al eliminar la etiqueta",
  },
  {
    key: "errorUpdatingTag",
    module: "Configuration",
    codeError: "ERROR_UPDATING_TAG",
    label: "Error al actualizar la etiqueta",
  },
  {
    key: "errorDuplicatedTag",
    module: "Configuration",
    codeError: "ERROR_CREATING_TAG_DUPLICATED",
    label: "Ya existe una etiqueta con ese nombre",
  },
  {
    key: "emptyConversationError",
    module: "Chat",
    label: "Selecciona una conversación para iniciar",
  },
  {
    key: "labelYes",
    module: "Chat",
    label: "Sí",
  },
  {
    key: "labelNot",
    module: "Chat",
    label: "No",
  },
  {
    key: "emptyField",
    module: "Chat",
    label: "Vacío",
  },
  {
    key: "checkAsRead",
    module: "Chat",
    label: "🙉 Marcar como leído",
  },
  {
    key: "checkAsNotRead",
    module: "Chat",
    label: "🙈 Marcar como no leído",
  },
  {
    key: "deleteMessage",
    module: "Chat",
    label: "🗑️ Eliminar mensaje",
  },
  {
    key: "conversationDelete",
    module: "chat",
    label: "🗑️ Eliminar conversación",
  },
  {
    key: "conversationArchive",
    module: "chat",
    label: "🗃️ Archivar conversación",
  },
  {
    key: "conversationUnArchive",
    module: "chat",
    label: "🗃️ Desarchivar",
  },
  {
    key: "replyMessage",
    module: "Chat",
    label: "Responder mensaje",
  },
  {
    key: "btnTag",
    module: "Chat",
    label: "Etiquetar",
  },
  {
    key: "emptyConversationLabel",
    module: "Chat",
    label: "Selecciona una conversación",
  },
  {
    key: "emptyClientLabel",
    module: "Chat",
    label: "Sin información del cliente",
  },
  {
    key: "emptyContactsLabel",
    module: "Chat",
    label: "Sin contactos",
  },
  {
    key: "changeTagMessageConfirmation",
    module: "Chat",
    label: "¿Estás seguro que deseas mover este cliente a otra etiqueta?",
  },
  {
    key: "errorAddingTag",
    module: "Chat",
    codeError: "ERROR_ADDING_TAG",
    label: "Error al agregar la etiqueta",
  },
  {
    key: "errorReassigningAgent",
    module: "Chat",
    codeError: "ERROR_REASSIGNING_AGENT",
    label: "Error al reasignar agente",
  },
  {
    key: "errorUpdatingTag",
    module: "Chat",
    codeError: "ERROR_REMOVING_TAG",
    label: "Error al eliminar la etiqueta",
  },
  {
    key: "companyNameAlreadyExists",
    module: "Agents",
    codeError: "COMPANY_ALREADY_EXISTS",
    label: "El nombre de la compañia ya ha sido registrado",
  },
  {
    key: "welcomeTitle",
    module: "Login",
    label: "Bienvenido de vuelta",
  },
  {
    key: "notificationsWharning",
    module: "Login",
    label: (type) =>
      `Por el momento, las notificaciones de iVentas no funcionan en ${type} , te recomendamos acceder desde Chrome u otro navegador.`,
  },
  {
    key: "dialog360WindowMsg",
    module: "Chat",
    label:
      "Una vez enviada la primera plantilla, se abre una ventana de 24 horas. Si no hay respuesta de tu cliente en este lapso de tiempo, puedes enviar otra plantilla para iniciar de nuevo la conversación. Para poder enviar libremente otro mensaje, tu cliente tiene que responderte primero.",
  },
  {
    key: "dialog360WindowMsgTemplate",
    module: "Chat",
    label:
      "PARA INICIAR UNA CONVERSACIÓN SELECCIONA O CREA UNA PLANTILLA DE MENSAJE",
  },
  {
    key: "dialog360WindowMsgTemplateOnly",
    module: "Chat",
    label:
      "Las plantillas de mensajes son la única manera en la que los agentes pueden iniciar una conversación con clientes. Estas plantillas deben ser preaprobadas por WhatsApp.",
  },

  {
    key: "notificationsWharningN",
    module: "Login",
    label:
      "Por favor activa las notificaciones o inicia sesión fuera del modo incógnito.",
  },
  {
    key: "welcomeMessage",
    module: "Login",
    label: "Por favor, ingresa tus datos de acceso para iniciar sesión",
  },
  {
    key: "loginError",
    module: "Login",
    label:
      "El correo electrónico o contraseña son incorrectos. Revísalos y vuelve a intentarlo.",
  },
  {
    key: "loginButton",
    module: "Login",
    label: "Iniciar Sesión",
  },
  {
    key: "loginLoading",
    module: "Login",
    label: "Iniciando ...",
  },
  {
    key: "iventasConditions",
    module: "Login",
    label: "Términos y Condiciones",
  },
  {
    key: "iventasPolicies",
    module: "Login",
    label: "Políticas de Privacidad",
  },
  {
    key: "missPassword",
    module: "Login",
    label: "Olvidé mi contraseña",
  },
  {
    key: "haveAccountLabel",
    module: "Login",
    label: "¿Aún no tienes una cuenta?",
  },
  {
    key: "createAccountBtn",
    module: "Login",
    label: "Crear cuenta",
  },
  {
    key: "iventasCopyRight",
    module: "Login",
    label: (year) => `©  ${year} iVentas. Derechos reservados.`,
  },
  {
    key: "datasheetRouteLabel",
    module: "DataSheetConfiguration",
    label: "Datos de contacto",
  },
  {
    key: "newDatasheetRouteLabel",
    module: "DataSheetConfiguration",
    label: "Nuevo campo",
  },
  {
    key: "addNewDatasheetLabelButton",
    module: "DataSheetConfiguration",
    label: "Añadir campo",
  },
  {
    key: "addNewDatasheetSuccessLabel",
    module: "DataSheetConfiguration",
    label: "Campo de usuario añadido con éxito",
  },
  {
    key: "editDatasheetSuccessLabel",
    module: "DataSheetConfiguration",
    label: "Campo de usuario editado con éxito",
  },
  {
    key: "datasheetNameColumnLabel",
    module: "DataSheetConfiguration",
    label: "Nombre del campo",
  },
  {
    key: "fieldTypeColumnLabel",
    module: "DataSheetConfiguration",
    label: "Tipo de campo",
  },
  {
    key: "createdDateColumnLabel",
    module: "DataSheetConfiguration",
    label: "Fecha de creación",
  },
  {
    key: "mainTextModalDataSheetConfiguration",
    module: "DataSheetConfiguration",
    label:
      "¿Estás seguro de que deseas eliminar este campo de usuario? Se perderán los datos",
  },
  {
    key: "cancelTextModalDataSheetConfiguration",
    module: "DataSheetConfiguration",
    label: "Cancelar",
  },
  {
    key: "confirmTextModalDataSheetConfiguration",
    module: "DataSheetConfiguration",
    label: "Eliminar",
  },
  {
    key: "successDeleteFieldAndClientInfo",
    module: "DataSheetConfiguration",
    label: "El campo y los datos han sido eliminado con éxito",
  },
  {
    key: "deleteDefaultFieldError",
    module: "DataSheetConfiguration",
    label: "No se pueden editar o eliminar los campos predefinidos",
  },
  {
    key: "confirmTextModalDataSheetConfiguration",
    module: "DataSheetConfiguration",
    label: "Eliminar",
  },
  {
    key: "datasheetNameAlreadyExists",
    module: "DataSheetConfiguration",
    codeError: "ERROR_DATASHEETFIELD_NAME",
    label: "Ya existe un campo con ese nombre",
  },
  {
    key: "rolRouteLinkLabel",
    module: "Roles",
    label: "Roles y permisos",
  },
  {
    key: "rolRouteLinkLabel",
    module: "Roles",
    label: "Nuevo rol",
  },
  {
    key: "editRouteLabel",
    module: "Roles",
    label: "Editar rol",
  },
  {
    key: "addNewRolLabelButton",
    module: "Roles",
    label: "Agregar nuevo rol",
  },
  {
    key: "addNewRolSuccessLabel",
    module: "Roles",
    label: "Rol añadido con éxito",
  },
  {
    key: "rolNameColumnLabel",
    module: "Roles",
    label: "Rol",
  },
  {
    key: "createdDateColumnLabel",
    module: "Roles",
    label: "Fecha de creación",
  },
  {
    key: "permissionsColumnLabel",
    module: "Roles",
    label: "Permisos",
  },
  {
    key: "mainTextModalRol",
    module: "Roles",
    label:
      "¿Estás seguro de que deseas eliminar este rol? Deberás asignar a los usuarios un rol diferente",
  },
  {
    key: "roleWithSamePermissionsError",
    module: "Roles",
    codeError: "ROLE_ALREADY_EXISTS",
    label: "Ya existe un rol con los mismos permisos",
  },
  {
    key: "roleWithSameNameError",
    module: "Roles",
    codeError: "ROLE_WITH_SAME_NAME_ERROR",
    label: "Ya existe un rol con el mismo nombre",
  },
  {
    key: "roleAdminDeleteError",
    module: "Roles",
    codeError: "ADMIN_ROLES_CANNOT_BE_DELETED",
    label: "El rol administrador no puede ser eliminado",
  },
  {
    key: "superAdminError",
    module: "Roles",
    label: "El rol superadministrador no puede ser editado",
  },
  {
    key: "roleAssignedError",
    module: "Roles",
    codeError: "ROLE_ASSIGNED_TO_USERS",
    label: "El rol no puede ser eliminado porque esta asignado a un usuario",
  },
  {
    key: "errorUpdatingRole",
    module: "Roles",
    codeError: "ERROR_UPDATING_ROLE",
    label: "Error al actualizar el rol",
  },
  {
    key: "emptyPermissionsError",
    module: "Roles",
    codeError: "EMPTY_PERMISSSIONS_ERROR",
    label: "No se ha asignado ningún permiso",
  },
  {
    key: "successUpdateMessage",
    module: "Roles",
    label: "El rol fue actualizado con éxito",
  },
  {
    key: "emailVerificationNotFoundError",
    module: "Onboarding",
    codeError: "EMAIL_VERIFICATION_NOT_FOUND",
    label: "Error al verificar el código ingresado",
  },
  {
    key: "emailAlreadyUsedError",
    module: "Onboarding",
    codeError: "EMAIL_ALREADY_USED",
    label: "El correo electrónico ya ha sido registrado",
  },
  {
    key: "companyAlreadyUsedError",
    module: "Onboarding",
    codeError: "ERROR_ADDING_COMPANY",
    label: "El nombre la compañia ya ha sido utilizado, escoge otro",
  },
  {
    key: "photoLabel",
    module: "Onboarding",
    label: "Fotografía",
  },
  {
    key: "selectImageMessage",
    module: "Onboarding",
    label: "Esta es la imagen que se mostrará cuando entres al panel",
  },
  {
    key: "emailPageTitle",
    module: "Onboarding",
    label: "¡Hola! 👋",
  },
  {
    key: "emailPageTitleTwo",
    module: "Onboarding",
    label: "Vamos a crear tu cuenta",
  },
  {
    key: "emailPageLabel",
    module: "Onboarding",
    label: "A continuación, ingresa el correo electrónico de tu negocio.",
  },
  {
    key: "btnVerifying",
    module: "Onboarding",
    label: "Verificando ...",
  },
  {
    key: "codeTitle",
    module: "Onboarding",
    label: "Revisa tu correo electrónico e ingresa el código",
  },
  {
    key: "codeLabel",
    module: "Onboarding",
    label:
      "Te hemos enviado un código de 6 caracteres. Ingrésalo a continuación para continuar:",
  },
  {
    key: "codeMessage",
    module: "Onboarding",
    label:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    key: "nameLabel",
    module: "Onboarding",
    label: "Nombre completo",
  },
  {
    key: "emailLabel",
    module: "Onboarding",
    label: "correo electrónico",
  },
  {
    key: "phoneLabel",
    module: "Onboarding",
    label: "Telefono",
  },
  {
    key: "companyLabel",
    module: "Onboarding",
    label: "Empresa",
  },
  {
    key: "congratulationsTitle",
    module: "Onboarding",
    label: "¡Felicidades!",
  },
  {
    key: "endMessage",
    module: "Onboarding",
    // eslint-disable-next-line max-len
    label:
      "Ya eres miembro de iVentas - Prepárate para un experiencia del todo nueva. Desde hoy la comunicación con tus clientes cambiará.",
  },
  {
    key: "endMessageIcons",
    module: "Onboarding",
    label: "👏👏👏👏",
  },
  {
    key: "onboardingLogin",
    module: "Onboarding",
    label: "Iniciar sesión ahora",
  },
  {
    key: "countryLabel",
    module: "Onboarding",
    label: "País de residencia",
  },
  {
    key: "paymentTitle",
    module: "Onboarding",
    label: "Define un método de Pago",
  },
  {
    key: "paymentSubTitle",
    module: "Onboarding",
    label: (hours) =>
      `¡Despreocúpate! 😊 Tienes ${hours} horas para probarlo y no te cobraremos hasta después de tu prueba.`,
  },
  {
    key: "planType",
    module: "Onboarding",
    label: "Plan",
  },
  {
    key: "planTypeEnum",
    module: "Onboarding",
    label: "Mensual",
  },
  {
    key: "planNameLabel",
    module: "Onboarding",
    label: "Nombre",
  },
  {
    key: "messagePerDay",
    module: "Onboarding",
    label: "Mensajes por día",
  },
  {
    key: "usersAdmin",
    module: "Onboarding",
    label: "Usuarios Administradores",
  },
  {
    key: "commonUsers",
    module: "Onboarding",
    label: "Usuarios",
  },
  {
    key: "dollarType",
    module: "Onboarding",
    label: "USD $",
  },
  {
    key: "spacesTab",
    module: "Spaces",
    label: "Tus espacios",
  },
  {
    key: "channelsTab",
    module: "Spaces",
    label: "Canales vinculados",
  },
  {
    key: "MainTextModalSpacesTable",
    module: "Spaces",
    label: "¿Estás seguro de que deseas eliminar este espacio?",
  },
  {
    key: "spaceNameColumn",
    module: "Spaces",
    label: "Nombre del espacio",
  },
  {
    key: "whatsappColumn",
    module: "Spaces",
    label: "Whatsapp",
  },
  {
    key: "facebookColumn",
    module: "Spaces",
    label: "Facebook Messenger",
  },
  {
    key: "SpacesViewTitle",
    module: "Spaces",
    label: "Espacios",
  },
  {
    key: "SpacesRouteName",
    module: "Spaces",
    label: "Tus espacios",
  },
  {
    key: "NewSpaceRouteName",
    module: "Spaces",
    label: "Nuevo espacio",
  },
  {
    key: "EditSpaceRouteName",
    module: "Spaces",
    label: "Editar espacio",
  },
  {
    key: "DropZoneSpacesImage",
    module: "Spaces",
    label: "Imagen del espacio",
  },
  {
    key: "DropZoneSpacesMainText",
    module: "Spaces",
    label: "Editar",
  },
  {
    key: "DropZoneSpaceSecondaryText",
    module: "Spaces",
    label: "Esta es la imagen que se mostrará cuando elijas el espacio",
  },
  {
    key: "saveSpaceButton",
    module: "Spaces",
    label: "Crear espacio",
  },
  {
    key: "facebookChannelsLabel",
    module: "Spaces",
    label: "Facebook Messenger",
  },
  {
    key: "notificationWarningFacebook",
    module: "Spaces",
    label:
      "Por favor, conecta tu página de Facebook de nuevo para que siga vinculada a iVentas.",
  },
  {
    key: "thereIsNoFacebookPages",
    module: "Spaces",
    label: "Aún no has vinculado una página de Facebook",
  },
  {
    key: "linkedWithFacebookButton",
    module: "Spaces",
    label: "Conectar Messenger",
  },
  {
    key: "linkedWithWhatsappButton",
    module: "Spaces",
    label: "Conectar con WhatsApp",
  },
  {
    key: "whatsappChannelsLabel",
    module: "Spaces",
    label: "WhatsApp",
  },
  {
    key: "thereIsNoWhatsappNumbers",
    module: "Spaces",
    label: "Aún no has vinculado un número",
  },
  {
    key: "linkWhatsappMainText",
    module: "Spaces",
    label: "Para vincular WhatsApp con tu cuenta:",
  },
  {
    key: "linkWhatsappStepOne",
    module: "Spaces",
    label: "Abre WhatsApp en tu teléfono",
  },
  {
    key: "linkWhatsappStepTwoA",
    module: "Spaces",
    label: "Toca Menú",
  },
  {
    key: "linkWhatsappStepTwoB",
    module: "Spaces",
    label: "o Configuración",
  },
  {
    key: "linkWhatsappStepTwoC",
    module: "Spaces",
    label: "y selecciona WhatsApp Web",
  },
  {
    key: "linkWhatsappStepThree",
    module: "Spaces",
    label:
      "Cuando se active la cámara, apunta tu teléfono hacia esta pantalla para escanear el código",
  },
  {
    key: "reloadQrLabel",
    module: "Spaces",
    label: "Haz clic para actualizar el código QR",
  },
  {
    key: "linkWhatsappModalWarning",
    module: "Spaces",
    // eslint-disable-next-line max-len
    label:
      "Introduce el número de teléfono que quieres vincular con iVentas.\nEs altamente recomendado que la cuenta de WhatsApp vinculada sea exclusivamente para iVentas.\nEste número se usará para generar el enlace de los agentes.",
  },
  {
    key: "linkedWhatsappConfirmButton",
    module: "Spaces",
    label: "Vincular",
  },
  {
    key: "spacesRouteLabel",
    module: "Spaces",
    label: "Canales vinculados",
  },
  {
    key: "newWhatsappRouteLabel",
    module: "Spaces",
    label: "Nuevo número de Whatsapp",
  },
  {
    key: "updateSpaceSuccess",
    module: "Spaces",
    label: "Cambios guardados con éxito",
  },
  {
    key: "UsersViewTitle",
    module: "Users",
    label: "Equipo",
  },
  {
    key: "newUserTableButton",
    module: "Users",
    label: "Añadir nuevo integrante",
  },
  {
    key: "newShortAnswerTableButton",
    module: "ShortAnswer",
    label: "Agregar nueva respuesta rápida",
  },
  {
    key: "usernameColumnName",
    module: "Users",
    label: "Nombre",
  },
  {
    key: "emailColumnName",
    module: "Users",
    label: "Correo electrónico",
  },
  {
    key: "rolColumnName",
    module: "Users",
    label: "Rol",
  },
  {
    key: "categoryColumnName",
    module: "Users",
    label: "Categoría",
  },
  {
    key: "clearFiltersButton",
    module: "Users",
    label: "Eliminar filtros",
  },
  {
    key: "applyFilterButton",
    module: "Users",
    label: "Aplicar filtros",
  },
  {
    key: "usersRouteName",
    module: "Users",
    label: "Equipo",
  },
  {
    key: "newUsersRouteName",
    module: "Users",
    label: "Nuevo miembro del equipo",
  },
  {
    key: "editUsersRouteName",
    module: "Users",
    label: "Editar miembro del equipo",
  },
  {
    key: "saveUserButton",
    module: "Users",
    label: "Guardar cambios",
  },

  {
    key: "updateUserSuccess",
    module: "Users",
    label: "Cambios guardados con éxito",
  },
  {
    key: "dropZoneUser",
    module: "Users",
    label: "Fotografía del usuario",
  },
  {
    key: "dropZoneSecondaryTextUser",
    module: "Users",
    label: "Esta es la imagen que se mostrará en el chat",
  },
  {
    key: "successDeleteTagModal",
    module: "Users",
    label: "Etiqueta eliminada con éxito",
  },
  {
    key: "modalMainText",
    module: "Users",
    label:
      "¿Estás seguro de que deseas eliminar a este usuario? Se perderán los datos",
  },
  {
    key: "modalMainTextMessage",
    module: "Chat",
    label: "¿Estás seguro de que deseas eliminar este mensaje?",
  },
  {
    key: "successUserDelete",
    module: "Users",
    label: "Usuario eliminado con éxito",
  },
  {
    key: "successDeleteTagModal",
    module: "Users",
    label: "Etiqueta eliminada con éxito",
  },
  {
    key: "successDeleteWhatsappTemplateModal",
    module: "Users",
    label: "Plantilla eliminada con éxito",
  },
  {
    key: "errorCreatingUsers",
    module: "Onboarding",
    codeError: "ERROR_CREATING_USER",
    label: "Error al crear usuario",
  },
  {
    key: "successDeleteDataSheetConfigurationModal",
    module: "Users",
    label: "Etiqueta eliminada con éxito",
  },
  {
    key: "editUserPassword",
    module: "Users",
    label: "Cambiar contraseña",
  },
  {
    key: "clientAlreadyAssigned",
    module: "Chat",
    label: "El cliente ya ha sido asignado a este agente",
  },
  {
    key: "editDataSheetConfigurationField",
    module: "DataSheetConfiguration",
    label: "Editar campo",
  },
  {
    key: "emptyListError",
    module: "DataSheetConfiguration",
    label: "No pueden haber opciones vacias",
  },
  {
    key: "listPlaceholder",
    module: "DataSheetConfiguration",
    label: "Escribe aqui ...",
  },
  {
    key: "newListOptionInputLabel",
    module: "DataSheetConfiguration",
    label: "Nueva opción",
  },
  {
    key: "actualPlanLabel",
    module: "Plans",
    label: "Plan actual",
  },
  {
    key: "ChangePlanButton",
    module: "Plans",
    label: "Cambiar",
  },
  {
    key: "nextBillingLabel",
    module: "Plans",
    label: "Próxima fecha de facturación",
  },
  {
    key: "creditDebitCardLabel",
    module: "Plans",
    label: "Tarjeta de crédito / débito",
  },
  {
    key: "changeCardButton",
    module: "Plans",
    label: "Cambiar tarjeta",
  },
  {
    key: "cancelSubscriptionButton",
    module: "Plans",
    label: "Cancelar suscripción",
  },
  {
    key: "plansRouteLabel",
    module: "Plans",
    label: "Planes",
  },
  {
    key: "editCardRouteLabel",
    module: "Plans",
    label: "Cambiar tarjeta",
  },
  {
    key: "saveCardButton",
    module: "Plans",
    label: "Guardar",
  },
  {
    key: "successEditCardModal",
    module: "Plans",
    label: "Tarjeta editada con éxito",
  },
  {
    key: "cardNumberInputPlaceholder",
    module: "Plans",
    label: "Número de tarjeta",
  },
  {
    key: "cardNumberInputLabel",
    module: "Plans",
    label: "Número de tarjeta",
  },
  {
    key: "cardExpirationDateInputPlaceholder",
    module: "Plans",
    label: "MM/AA",
  },
  {
    key: "cardExpirationDateInputLabel",
    module: "Plans",
    label: "Fecha de vencimiento",
  },
  {
    key: "cardCvvInputPlaceholder",
    module: "Plans",
    label: "CVV",
  },
  {
    key: "cardCvvInputLabel",
    module: "Plans",
    label: "CVV",
  },
  {
    key: "cancelModalText",
    module: "Plans",
    label:
      "¿Estás seguro de que deseas cancelar tu suscripción? Tu plan se cancelará una vez finalizado tu periodo de facturación",
  },
  {
    key: "confirmCancelPlanButton",
    module: "Plans",
    label: "Confirmar",
  },
  {
    key: "cancelActionButton",
    module: "Plans",
    label: "Regresar",
  },
  {
    key: "priceLabel",
    module: "Plans",
    label: "USD al mes",
  },
  {
    key: "editPlanActionButton",
    module: "Plans",
    label: "Elegir",
  },
  {
    key: "selectedPlanButton",
    module: "Plans",
    label: "Actual",
  },
  {
    key: "plansRouteLabel",
    module: "Plans",
    label: "Planes",
  },
  {
    key: "editPlanRouteLabel",
    module: "Plans",
    label: "Cambiar plan",
  },
  {
    key: "channelsEmptyError",
    module: "Spaces",
    codeError: "ERROR_EMPTY_CHANNELS",
    label: "El espacio debe tener por lo menos un canal asignado",
  },
  {
    key: "createSpaceGeneralError",
    module: "Spaces",
    label: "Error al guardar los datos del espacio",
  },
  {
    key: "updateSpaceGeneralError",
    module: "Spaces",
    label: "Error al actualizar los datos del espacio",
  },
  {
    key: "errorUpdatingDefaultSpace",
    module: "Spaces",
    label: "El espacio predefinido no puede ser editado o eliminado",
  },
  {
    key: "successDeleteSpace",
    module: "Spaces",
    label: "El espacio ha sido eliminado con éxito",
  },
  {
    key: "gettingChannelError",
    module: "Spaces",
    codeError: "ERROR_GETTING_CHANNELS",
    label: "Error al obtener canales",
  },
  {
    key: "gettingSpacesError",
    module: "Spaces",
    codeError: "ERROR_GETTING_SPACES",
    label: "Error al obtener espacios",
  },
  {
    key: "spaceNameError",
    module: "Spaces",
    codeError: "ERROR_EMPTY_SPACE_NAME",
    label: "Debes agregar un nombre al espacio",
  },
  {
    key: "errorUpdatingSpace",
    module: "Spaces",
    codeError: "ERROR_UPDATING_SPACE",
    label: "Error al actualizar el espacio",
  },
  {
    key: "errorDeletigSpace",
    module: "Spaces",
    codeError: "ERROR_DELETING_SPACE",
    label: "Error al eliminar el espacio",
  },
  {
    key: "errorSpaceNotFound",
    module: "Spaces",
    codeError: "NOT_FOUND_SPACE",
    label: "Error al buscar espacio",
  },
  {
    key: "errorDuplicatedSpace",
    module: "Spaces",
    codeError: "ERROR_DUPLICATED_SPACE",
    label: "Ya existe un Espacio con ese nombre",
  },
  {
    key: "linkedWhatsappSuccessModal",
    module: "Channels",
    label: "Whatsapp vinculado con éxito",
  },
  {
    key: "loadingWhatsappQrLabel",
    module: "Channels",
    label: "Cargando ...",
  },
  {
    key: "linkedDateLabel",
    module: "Channels",
    label: "Vinculada:",
  },
  {
    key: "unlinkedChannelButton",
    module: "Channels",
    label: "Desvincular",
  },
  {
    key: "linkedChannelButton",
    module: "Channels",
    label: "Vincular",
  },
  {
    key: "logoutWhatsappChannelSuccessModal",
    module: "Channels",
    label: "Número desvinculado exitosamente",
  },
  {
    key: "errorUpdatingStatus",
    codeError: "ERROR_UPDATING_CHATBOT_STATUS",
    module: "Channels",
    label: "Error al actualizar el estatus del bot",
  },
  {
    key: "successUpdateBotStatus",
    module: "Channels",
    label: "El estatus del bot se ha actualizado con éxito",
  },
  {
    key: "activeBotLabel",
    module: "Channels",
    label: "Bot activo",
  },
  {
    key: "inactiveBotLabel",
    module: "Channels",
    label: "Bot inactivo",
  },
  {
    key: "errorUserWithEmailNotFound",
    module: "Recover password",
    codeError: "ERROR_GETTING_USER",
    label:
      "El correo electrónico no se encuentra en el sistema. Revísalo y vuelve a intentarlo.",
  },
  {
    key: "recoverTitle",
    module: "Recover password",
    label: "Restablece tu contraseña",
  },
  {
    key: "recoverSubtitle",
    module: "Recover password",
    label:
      "Te enviaremos un correo con las instrucciones para recuperar tu contraseña, ingresa tu correo electrónico",
  },
  {
    key: "sendInstructionsBtn",
    module: "Recover password",
    label: "Enviar instrucciones",
  },
  {
    key: "successSentRecoverEmail",
    module: "Recover password",
    label:
      "Las instrucciones fueron enviadas con éxito, revisa tu correo electrónico",
  },
  {
    key: "successChangePassword",
    module: "Change password",
    label: "Tu contraseña se ha actualizado con éxito, inicia sesión",
  },
  {
    key: "expiredRequest",
    module: "Change password",
    label:
      "Tu solicitud de cambio de contraseña ha expirado, por favor vuelve a intentarlo",
  },
  {
    key: "changePasswordTitle",
    module: "Change password",
    label: "Elige una nueva contraseña",
  },
  {
    key: "changePasswordSubtitle",
    module: "Change password",
    label:
      "No te preocupes, nos pasa a todos. Para generar una nueva contraseña, te sugerimos que tenga un mínimo de 8 caracteres y al menos un número.",
  },
  {
    key: "btnRestore",
    module: "Change password",
    label: "Restablecer",
  },
  {
    key: "restoring",
    module: "Change password",
    label: "Restableciendo ...",
  },
  {
    key: "btnRetry",
    module: "Change password",
    label: "Volver a enviar",
  },
  {
    key: "cancelLabel",
    module: "payments",
    label: "(cancelada)",
  },
  {
    key: "listOptionsLabel",
    module: "Configuration",
    label: "Opciones de lista",
  },
  {
    key: "thereIsNoAvailableChannelsError",
    module: "Spaces",
    codeError: "THERE_IS_NO_AVAILABLE_CHANNELS",
    label: "No hay números disponibles, por favor, contacta a soporte",
  },
  {
    key: "errorInPaymentMethod",
    codeError: "REQUIRES_PAYMENT_METHOD",
    module: "Payment",
    label:
      "Hubo un error al realizar el pago, por favor, intenta con otra tarjeta.",
  },
  {
    key: "errorCreatingFirstPayment",
    codeError: "ERROR_CREATING_FIRST_PAYMENT",
    module: "Payment",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStripeInvoiceStatus",
    codeError: "ERROR_UNKNOWN_STRIPE_INVOICE_STATUS",
    module: "Payment",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStripeSubscriptionStatus",
    codeError: "ERROR_UNKNOWN_STRIPE_SUBSCRIPTION_STATUS",
    module: "Payment",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStipeInvoiceStatusSubActive",
    codeError: "ERROR_UNKNOWN_STRIPE_INVOICE_STATUS_SUBSCRIPTION_ACTIVE",
    module: "Payment",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStripePaymentStatus",
    codeError: "ERROR_UNKNOWN_STRIPE_PAYMENT_INTENT_STATUS",
    module: "Payment",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorUpdatingPaymentMethod",
    codeError: "ERROR_UPDATING_PAYMENT_METHOD",
    module: "Payment",
    label:
      "Hubo un error al actualizar tu método de pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStripeCard",
    codeError: "Your card number is invalid.",
    module: "Stripe",
    label: "Número de tarjeta incorrecto",
  },
  {
    key: "errorStripeProcessingError",
    codeError: "PROCESSING_ERROR",
    module: "Stripe",
    label:
      "Ha ocurrido un error al procesar la tarjeta. Por favor, intenta en unos momentos.",
  },
  {
    key: "errorStripeIncorrectCvc",
    codeError: "INCORRECT_CVC",
    module: "Stripe",
    label: "Ha ocurrido un error al procesar la tarjeta. CVV incorrecto.",
  },
  {
    key: "errorStripeExpiredCard",
    codeError: "EXPIRED_CARD",
    module: "Stripe",
    label: "Tarjeta expirada. Por favor, intenta con una diferente.",
  },
  {
    key: "errorStripeCardDeclined",
    codeError: "CARD_DECLINED",
    module: "Stripe",
    label: "Tarjeta rechazada. Por favor, intenta con una diferente.",
  },
  {
    key: "incorrectPlanKey",
    codeError: "INCORRECT_PLAN_KEY",
    module: "Stripe",
    label: "El plan seleccionado es incorrecto",
  },
  {
    key: "errorIncompletNumberCard",
    codeError: "El número de tu tarjeta está incompleto.",
    module: "Stripe",
    label: "El número de tu tarjeta está incompleto",
  },
  {
    key: "errorWrongNumberCard",
    codeError: "El número de tu tarjeta no es válido.",
    module: "Stripe",
    label: "El número de tu tarjeta no es válido",
  },
  {
    key: "errorIncompleteExpiryDate",
    codeError: "La fecha de caducidad de tu tarjeta está incompleta.",
    module: "Stripe",
    label: "La fecha de caducidad de tu tarjeta está incompleta",
  },
  {
    key: "errorWrongYearExpiryDate",
    codeError: "El año de caducidad de la tarjeta ya pasó.",
    module: "Stripe",
    label: "El año de caducidad de la tarjeta ya pasó",
  },
  {
    key: "errorReachedExpiryDate",
    codeError: "La fecha de caducidad de tu tarjeta ya ha pasado.",
    module: "Stripe",
    label: "La fecha de caducidad de tu tarjeta ya ha pasado",
  },
  {
    key: "errorWrongExpiryDate",
    codeError: "El año de caducidad de la tarjeta no es válido.",
    module: "Stripe",
    label: "El año de caducidad de la tarjeta no es válido",
  },
  {
    key: "errorIncompleteCVV",
    codeError: "El código de seguridad de tu tarjeta está incompleto.",
    module: "Stripe",
    label: "El código de seguridad de tu tarjeta está incompleto",
  },
  {
    key: "updatatingPlanLabel",
    module: "Payment",
    label: (planName) => `(se actualizará a ${planName} después de esta fecha)`,
  },
  {
    key: "upgradePlanModalText",
    module: "Payment",
    label: "¿Estás seguro que deseas cambiar de plan?",
  },
  {
    key: "planUpgratedSuccessful",
    module: "Payment",
    label: "Plan editado exitosamente",
  },
  {
    key: "reactivePlan",
    module: "Payment",
    label: "Reactivar",
  },
  {
    key: "titleHelp",
    module: "Sidebar",
    label: "¿Necesitas ayuda?",
  },
  {
    key: "textBtn",
    module: "Help",
    label: "Pedir ayuda",
  },
  {
    key: "subTitleHelp",
    module: "Sidebar",
    label: "Si tienes dudas o problemas, contáctanos a",
  },
  {
    key: "errorStripeTrialFail",
    codeError: "ERROR_UNKNOWN_STRIPE_INVOICE_STATUS_SUBSCRIPTION_TRIALING",
    module: "Stripe",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "errorStripePaymentFail",
    codeError: "ERROR_UNKOWN_PAYMENT_INTENT_STATUS_SUBSCRIPTION_ACTIVE",
    module: "Stripe",
    label: "Hubo un error al realizar el pago, por favor, contacta a soporte.",
  },
  {
    key: "issue",
    module: "Bot",
    label: "Problema",
  },
  {
    key: "name",
    module: "Bot",
    label: "Nombre",
  },
  {
    key: "feedback",
    module: "Bot",
    label: "Sugerencia",
  },
  {
    key: "Phone",
    module: "Bot",
    label: "Teléfono",
  },
  {
    key: "btnContinueBotMenu",
    module: "Bot",
    label: "Continuar",
  },
  {
    key: "botHi",
    module: "Bot",
    label: "Bot de bienvenida",
  },
  {
    key: "survey",
    module: "Bot",
    label: "Encuesta de satisfacción",
  },
  {
    key: "botFollowUp",
    module: "Bot",
    label: "Bot de seguimiento",
  },
  {
    key: "botMenu",
    module: "Bot",
    label: "Bot Menú",
  },
  {
    key: "thereAreNoConversationsLabel",
    module: "Chat",
    label: "Aún no hay conversaciones nuevas",
  },
  {
    key: "loadingLabel",
    module: "General",
    label: "Loading ...",
  },
  {
    key: "clearFiltersLabel",
    module: "Filters",
    label: "Eliminar filtros",
  },
  {
    key: "applyFiltersLabel",
    module: "Filters",
    label: "Aplicar",
  },
  {
    key: "filterAllLabel",
    module: "Filters",
    label: "Todo",
  },
  {
    key: "filterAssignedLabel",
    module: "Filters",
    label: "Asignadas",
  },
  {
    key: "filterOthersLabel",
    module: "Filters",
    label: "Otros filtros",
  },
  {
    key: "writeAMessagePlaceholder",
    module: "Chat",
    label: "Escribe un mensaje ...",
  },
  {
    key: "newMessagesLabel",
    module: "Chat",
    label: "Nuevos mensajes",
  },
  {
    key: "desactiveChannelLabel",
    module: "Chat",
    label: "Canal desactivado",
  },
  {
    key: "btnShowMore",
    module: "v-Card",
    label: "Ver todos",
  },
  {
    key: "vCardContact",
    module: "v-Card",
    label: (firstContact, numberOfContact) =>
      `${firstContact} y ${numberOfContact} ${
        numberOfContact === 1 ? "contacto más" : "contactos más"
      } `,
  },
  {
    key: "facebookMessageError",
    codeError: "FACEBOOK_MESSAGE_ERROR",
    module: "chat",
    label:
      "Error al envíar el mensaje. Por favor verifica que estás dentro de la ventana de 24 horas.",
  },
  {
    key: "sendMessageError",
    codeError: "SEND_MESSAGE_ERROR",
    module: "chat",
    label: "Error al envíar el mensaje",
  },
  {
    key: "editClientDataLabel",
    module: "Chat",
    label: "Editar Datos",
  },
  {
    key: "phoneUpdatedContactDuplicated",
    module: "Chat",
    label: "Ya existe un contacto con ese número de Teléfono",
  },
  {
    key: "phoneUpdatedSuccessfully",
    module: "Chat",
    label: "Teléfono actualizado correctamente",
  },
  {
    key: "notValidPhoneNumber",
    module: "Chat",
    label: "Número de Teléfono no válido",
  },
  {
    key: "errorAtUpdatingContactPhone",
    module: "Chat",
    label: "Error al actualizar teléfono de contacto",
  },
  {
    key: "updateContactPhoneModalTitle",
    module: "Chat",
    label: "Actualizar Teléfono de Contacto",
  },
  {
    key: "updateContactCancelBtn",
    module: "Chat",
    label: "Cancelar",
  },
  {
    key: "updateContactAcceptBtn",
    module: "Chat",
    label: "Actualizar",
  },
  {
    key: "facebookWindowError",
    codeError: "FACEBOOK_MESSAGE_ERROR",
    module: "Chat",
    label:
      "Te encuentras fuera de la ventana de 24 horas de Facebook. Por lo tanto, no se pueden mandar mensajes a este usuario.",
  },
  {
    key: "linkWhatsappWarning",
    module: "Whatsapp",
    label:
      "Una vez escaneado el código, espera en esta página hasta que se complete el proceso, puede tardar varios minutos",
  },
  {
    key: "referralCodeWhatsappImgAlt",
    module: "Custom Link",
    label: "Whatsapp link",
  },
  {
    key: "referralCodeWhatsappText",
    module: "Custom Link",
    label: "Enlace de WhatsApp",
  },
  {
    key: "referralLinkText",
    module: "Custom Link",
    label:
      "%C2%A1Hola!%20Buen%20d%C3%ADa.%20%C2%BFPodr%C3%ADan%20contactarme%3F%20Mi%20clave%20%C3%BAnica%20de%20referencia%20es%3A%20",
  },
  {
    key: "referralLinkWasCopied",
    module: "Custom Link",
    label: "Enlace copiado exitosamente",
  },
  {
    key: "referralLinkError",
    module: "Custom Link",
    label: "Error al generar el enlace",
  },
  {
    key: "linkAltColumn",
    module: "Custom Link",
    label: "Enlace",
  },
  {
    key: "updateWhatsappChannelNumber",
    module: "Spaces",
    // eslint-disable-next-line max-len
    label:
      "Introduce el número de teléfono vinculado con iVentas.\nEste número se usará para generar el enlace de los agentes.",
  },
  {
    key: "successfulUpdateSpaceNumber",
    module: "Spaces",
    label: "Número editado exitosamente",
  },
  {
    key: "whatsappWebWarning",
    module: "Spaces",
    label:
      "Una vez vinculado el número, no se debe utilizar con Whatsapp Web.\nPor favor, cierra las sesiones activas de Whatsapp Web.",
  },
  {
    key: "continueBtn",
    module: "General",
    label: "Continuar",
  },
  {
    key: "continueBtn",
    module: "General",
    label: "Continuar",
  },
  {
    key: "newConversationNotification",
    module: "Notifications",
    label: "Tienes una nueva conversación",
  },
  {
    key: "newMessageNotification",
    module: "Notifications",
    label: "Tienes un nuevo mensaje",
  },
  {
    key: "inputMessagePlaceholder",
    module: "Chat",
    label: "Escribe un mensaje",
  },
  {
    key: "multimediaTab",
    module: "Multimedia",
    label: "Multimedia",
  },
  {
    key: "multimediaOrderByLabel",
    module: "Multimedia",
    label: "Ordenar por",
  },
  {
    key: "deleteConversation",
    module: "DeleteConversation",
    label: "¿Estás seguro de que deseas eliminar esta conversación",
  },
  {
    key: "archiveConversation",
    module: "chat",
    label: "¿Estás seguro de que deseas archivar esta conversación",
  },
  {
    key: "textSecundaryDeleteConversation",
    module: "DeleteConversation",
    label: "Al eliminar esta conversación, también se elimina el contacto.",
  },
  {
    key: "textSecundaryArchiveConversation",
    module: "chat",
    label: "Para ver tus conversaciones archivadas, da click en Otros filtros.",
  },
  {
    key: "deleteConversationSuccess",
    module: "DeleteConversation",
    label: "Conversación eliminada con éxito",
  },
  {
    key: "archiveConversationSuccess",
    module: "chat",
    label: "Conversación archivada con éxito",
  },
  {
    key: "deleteConversatioConfirm",
    module: "DeleteConversation",
    label: "Eliminar",
  },
  {
    key: "archiveConversatioConfirm",
    module: "chat",
    label: "Archivar",
  },
  {
    key: "multimediaCreationDate",
    module: "Multimedia",
    label: "Fecha de creación",
  },
  {
    key: "multimediaAlbumsCount",
    module: "Multimedia",
    label: (albumsCount, filesCount) =>
      `${albumsCount} ${
        albumsCount === 1 ? "álbum" : "álbumes"
      } / ${filesCount} ${filesCount === 1 ? "archivo" : "archivos"}`,
  },
  {
    key: "multimediaEmpty",
    module: "Multimedia",
    label: "Aún no has agregado un álbum. ¡Inténtalo!",
  },
  {
    key: "multimediaAddNewModalAlbum",
    module: "Multimedia",
    label: "Introduce el nombre del álbum que deseas crear",
  },
  {
    key: "multimediaCreateAlbum",
    module: "Multimedia",
    label: "Crear",
  },
  {
    key: "multimediaRouteLabel",
    module: "Multimedia",
    label: "Multimedia",
  },
  {
    key: "albumTitleLabel",
    module: "Multimedia",
    label: "Álbumes",
  },
  {
    key: "filesSectionTitleLabel",
    module: "Multimedia",
    label: "Archivos generales",
  },
  {
    key: "filesDropZoneMainText",
    module: "Multimedia",
    label:
      "Arrastra aquí tus archivos en pdf, jpg, png y mp4 o elígelos haciendo click",
  },
  {
    key: "filesDropZoneMainTextPdf",
    module: "Multimedia",
    label:
      "Arrastra aquí tus archivos en formato .xlxs, .pdf, .docs .pptx, .zip, y mp3 o elígelos haciendo click",
  },
  {
    key: "filesDropZoneMainImageText",
    module: "Multimedia",
    label: "Arrastra aquí tu imagen jpg, jpeg o png o elígela haciendo click.",
  },
  {
    key: "filesDropZoneMainVideoText",
    module: "Multimedia",
    label: "Arrastra aquí tu video mp4 o elígelo haciendo click.",
  },
  {
    key: "filesDropZoneMainTemplateText",
    module: "Multimedia",
    label:
      "Arrastra aquí tu multimedia o documento (PDF, Word, Excel) o haz click aquí para subirlo",
  },
  {
    key: "filesDropZoneMainPdfText",
    module: "Multimedia",
    label: "Arrastra aquí tu archivo o elígelo haciendo click.",
  },
  {
    key: "filesDropZoneSecondaryText",
    module: "Multimedia",
    label: "Tu archivo puede pesar máximo 16MB.",
  },
  {
    key: "filesDropZoneThirdText",
    module: "Multimedia",
    label: "Formatos aceptados: pdf, word, excel, power point, zip o txt.",
  },
  {
    key: "videoSectionTitle",
    module: "Multimedia",
    label: "Videos",
  },
  {
    key: "videoSectionUploadButton",
    module: "Multimedia",
    label: "Subir",
  },
  {
    key: "PanelViewTitle",
    module: "Users",
    label: "Panel",
  },
  {
    key: "pdfPageCount",
    module: "Multimedia",
    label: (displayedPage, fileTotalPages) =>
      `${displayedPage} de ${fileTotalPages}`,
  },
  {
    key: "returnButtonLabel",
    module: "Multimedia",
    label: "Volver",
  },
  {
    key: "subAlbumChatTitle",
    module: "Multimedia",
    label: "Álbumes",
  },
  {
    key: "filesChatTitle",
    module: "Multimedia",
    label: "Archivos",
  },
  {
    key: "imagesChatTitle",
    module: "Multimedia",
    label: "Imágenes",
  },
  {
    key: "videosChatTitle",
    module: "Multimedia",
    label: "Videos",
  },
  {
    key: "filePreviewTitle",
    module: "Multimedia",
    label: "Vista preliminar",
  },
  {
    key: "errorCreatingAlbum",
    codeError: "ERROR_CREATING_ALBUM",
    module: "datasheet",
    label: "Error al crear el álbum",
  },
  {
    key: "errorCreatingAlbumOnPrisma",
    codeError: "ERROR_CREATING_ALBUM_ON_PRISMA",
    module: "datasheet",
    label: "Error al crear el álbum",
  },
  {
    key: "errorUpdatingAlbum",
    codeError: "ERROR_UPDATING_ALBUM",
    module: "datasheet",
    label: "Error al crear el álbum",
  },
  {
    key: "deleteFileLabel",
    module: "Multimedia",
    label:
      "¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer",
  },
  {
    key: "deleteFileButton",
    module: "Multimedia",
    label: "Eliminar",
  },
  {
    key: "deleteAlbumModal",
    module: "Multimedia",
    label:
      "¿Estás seguro de que deseas eliminar este álbum? Se eliminarán todos los álbumes y archivos que contenga",
  },
  {
    key: "deleteSubAlbumModal",
    module: "Multimedia",
    label:
      "¿Estás seguro de que deseas eliminar este álbum? Se eliminarán todos los archivos que contenga",
  },
  {
    key: "sortByCreatedAtLabel",
    module: "Multimedia",
    label: "Fecha de creación",
  },
  {
    key: "sortByNameLabel",
    module: "Multimedia",
    label: "Nombre",
  },
  {
    key: "filesEmpty",
    module: "Multimedia",
    label: "Aún no has agregado un archivo",
  },
  {
    key: "dragFilesError",
    module: "Multimedia",
    label: (rejectedFiles, length) =>
      `${rejectedFiles} no ${
        length === 1 ? "tiene" : "tienen"
      } un formato válido.`,
  },
  {
    key: "fileDeletedLabel",
    module: "Multimedia",
    label: "Archivo eliminado con éxito",
  },
  {
    key: "albumDeletedLabel",
    module: "Multimedia",
    label: "Álbum eliminado con éxito",
  },
  {
    key: "multimediaSubAlbumFilesCount",
    module: "Multimedia",
    label: (filesCount) =>
      `${filesCount} ${filesCount === 1 ? "archivo" : "archivos"}`,
  },
  {
    key: "progressUploadFileLabel",
    module: "Multimedia",
    label: "Cargando Elemento",
  },
  {
    key: "fileSizeLabel",
    module: "Multimedia",
    label: "KB",
  },
  {
    key: "videoDeletedLabel",
    module: "Multimedia",
    label: "Video eliminado con éxito",
  },
  {
    key: "deleteVideoWarningLabel",
    module: "Multimedia",
    label:
      "¿Estás seguro de que deseas eliminar este video? Esta acción no se puede deshacer",
  },
  {
    key: "fileUploadError",
    module: "Multimedia",
    label: (fileName) => `Error al subir el archivo ${fileName}`,
  },
  {
    key: "multimediaNotAvailable",
    module: "Multimedia",
    label: "Multimedia no disponible",
  },
  {
    key: "formatNotAvailable",
    module: "Multimedia",
    label: "Formato no disponible.",
  },
  {
    key: "onFileUrlClick",
    module: "Multimedia",
    label: "Da click aqui para ver el contenido original.",
  },
  {
    key: "deletedFileImgAlt",
    module: "Multimedia",
    label: "archivo eliminado",
  },
  {
    key: "unassignedConversationLabel",
    module: "Chat",
    label: "Sin asignar",
  },
  {
    key: "videoFileTypeLabel",
    module: "Chat",
    label: "Video",
  },
  {
    key: "imageFileTypeLabel",
    module: "Chat",
    label: "Imagen",
  },
  {
    key: "pdfFileTypeLabel",
    module: "Chat",
    label: "PDF",
  },
  {
    key: "audioFileTypeLabel",
    module: "Chat",
    label: "Audio",
  },
  {
    key: "multimediaFileTypeLabel",
    module: "Chat",
    label: "Multimedia",
  },
  {
    key: "stickerFileTypeLabel",
    module: "Chat",
    label: "Sticker",
  },
  {
    key: "contactFileTypeLabel",
    module: "Chat",
    label: "Contacto",
  },
  {
    key: "multimediaNoAvailableFileTypeLabel",
    module: "Chat",
    label: "Multimedia no disponible",
  },
  {
    key: "dragFilesSizeError",
    module: "Multimedia",
    label: (rejectedFiles, length) =>
      `${rejectedFiles} ${length === 1 ? "pesa" : "pesan"} más de 16MB.`,
  },
  {
    key: "errorUpdatingAlbum",
    codeError: "ERROR_DUPLICATED_ALBUM",
    module: "Multimedia",
    label: "Ya existe un álbum con el mismo nombre.",
  },
  {
    key: "errorUpdatingAlbum",
    codeError: "ERROR_DUPLICATED_FILE",
    module: "Multimedia",
    label: (type, filename) =>
      type === CONSTANTS.FILE_TYPE_VIDEO
        ? "Ya existe un video con la misma url."
        : `Ya existe un archivo con el nombre ${filename}`,
  },
  {
    key: "emptyMultimediaInChat",
    module: "Chat",
    label: "No hay archivos disponibles",
  },
  {
    key: "thereAreNoMoreConversations",
    module: "Chat",
    label: "No hay más conversaciones",
  },
  {
    key: "maxMessagesAlert",
    module: "Chat",
    label: (maxMessages) => `Máximo ${maxMessages} mensajes.`,
  },
  {
    key: "minMessagesAlert",
    module: "Chat",
    label: "Selecciona un mensaje para reenviar.",
  },
  {
    key: "maxContactsAlert",
    module: "Chat",
    label: (maxMessages) => `Máximo ${maxMessages} contactos.`,
  },
  {
    key: "minContactsAlert",
    module: "Chat",
    label: "Selecciona al menos un contacto.",
  },
  {
    key: "seenByLabel",
    module: "Chat",
    label: "Visto por",
  },
  {
    key: "sentByLabel",
    module: "Chat",
    label: "Enviado por",
  },
  {
    key: "vicNameLabel",
    module: "Chat",
    label: "BOT",
  },
  {
    key: "unassignedClientLabel",
    module: "Chat",
    label: "Sin asignar",
  },
  {
    key: "selectedMessages",
    module: "Chat",
    label: "Mensajes seleccionados:",
  },
  {
    key: "browserCannotPreviewVideos",
    module: "Chat",
    label: "Tu navegador no puede previsualizar videos.",
  },
  {
    key: "browserCannotPreviewAudios",
    module: "Chat",
    label: "Tu navegador no puede previsualizar audio.",
  },
  {
    key: "planCurrency",
    module: "Plans",
    label: "USD",
  },
  {
    key: "planCostPerUser",
    module: "Plans",
    label: "por usuario",
  },
  {
    key: "planCardButton",
    module: "Plans",
    label: "SELECCIONAR",
  },
  {
    key: "actualPlanLabel",
    module: "Plans",
    label: "Activo",
  },
  {
    key: "reactivateAccountLabel",
    module: "Plans",
    label: "Reestablecer cuenta",
  },
  {
    key: "expiredAccountLabel",
    module: "Plans",
    label: "Tu suscripción ha expirado.",
  },
  {
    key: "expiredAccountDescriptionLabel",
    module: "Plans",
    label: "Para poder acceder a iVentas necesitas reactivar tu suscripción.",
  },
  {
    key: "selectPlanButton",
    module: "Plans",
    label: "Seleccionar plan",
  },
  {
    key: "logoutButton",
    module: "Plans",
    label: "Cerrar sesión",
  },
  {
    key: "QuickAnswersViewTitle",
    module: "QuickAnswers",
    label: "Respuestas rápidas",
  },
  {
    key: "MultimediaViewTitle",
    module: "QuickAnswers",
    label: "Multimedia",
  },
  {
    key: "BotTitle",
    module: "Bot",
    label: "Bot",
  },
  {
    key: "BroadcastTitle",
    module: "Broadcast",
    label: "Campañas",
  },
  {
    key: "successDeleteQuickAnswer",
    module: "QuickAnswers",
    label: "Respuesta rápida eliminada con éxito",
  },
  {
    key: "quickAnswersModalMainText",
    module: "QuickAnswers",
    label:
      "¿Estás seguro de que deseas eliminar a esta respuesta rápida? Se perderán los datos",
  },
  {
    key: "quickAnswersRouteName",
    module: "QuickAnswers",
    label: "Respuestas rápidas",
  },
  {
    key: "newQuickAnswersRouteName",
    module: "QuickAnswers",
    label: "Nueva respuesta rápida",
  },
  {
    key: "editQuickAnswersRouteName",
    module: "QuickAnswers",
    label: "Editar respuesta rápida",
  },
  {
    key: "addNewQuickAnswerLabel",
    module: "QuickAnswers",
    label: "+ Agregar",
  },
  {
    key: "leadNameLabel",
    module: "QuickAnswers",
    label: "Nombre del Lead",
  },
  {
    key: "agentNameLabel",
    module: "QuickAnswers",
    label: "Nombre del Agente",
  },
  {
    key: "chatPreviewLabel",
    module: "QuickAnswers",
    label: "Vista previa",
  },
  {
    key: "quickAnswerLeadName",
    module: "QuickAnswers",
    label: "John Doe",
  },
  {
    key: "createQuickAnswerSuccess",
    module: "Users",
    label: "Respuesta rápida creada con éxito",
  },
  {
    key: "updateQuickAnswerSuccess",
    module: "Users",
    label: "Respuesta rápida actualizada con éxito",
  },
  {
    key: "errorShortcutAlreadyExists",
    module: "Agents",
    codeError: "SHORTCUT_ALREADY_EXISTS",
    label: "Ya existe una respuesta rápida con el mismo atajo",
  },
  {
    key: "permissionsLabel",
    module: "Users",
    label: "Permisos",
  },
  {
    key: "onboardingMainDescription",
    module: "onboarding",
    label: "iVentas | CRM para WhatsApp y Messenger",
  },
  {
    key: "onboardingPlan",
    module: "onboarding",
    label: "Plan",
  },
  {
    key: "onboardingMontlyLabel",
    module: "onboarding",
    label: "MENSUAL",
  },
  {
    key: "onboardingCongratulationsTitle",
    module: "onboarding",
    label: "¡Felicidades!",
  },
  {
    key: "onboardingCongratulationsSubtitle",
    module: "onboarding",
    label:
      "Con iVentas tu negocio logrará sus objetivos más rápido y en equipo.",
  },
  {
    key: "onboardingCongratulationsText",
    module: "onboarding",
    label:
      "Ahora el WhatsApp y Messenger de tu negocio tendrá superpoderes. ¡Aprovéchalos!",
  },
  {
    key: "onboardingCongratulationsLinkNumber",
    module: "onboarding",
    label: "¿Tienes el teléfono de tu negocio a la mano?",
  },
  {
    key: "onboardingCongratulationsWhatsapp",
    module: "onboarding",
    label: "Solo necesitas el teléfono de tu negocio a la mano",
  },
  {
    key: "onboardingCongratulationsLinkWhatsappAction",
    module: "onboarding",
    label: "Sí, enlacemos",
  },
  {
    key: "onboardingCongratulationsGoToDashboardAction",
    module: "onboarding",
    label: "Lo haré después",
  },
  {
    key: "onboardingDescriptionTitle",
    module: "onboarding",
    label: "¡Ya casi! ¿Qué es lo que obtendrás?",
  },
  {
    key: "onboardingDescriptionTextChats",
    module: "onboarding",
    label:
      "Permite que tus colaboradores tengan acceso a los chats de tu negocio",
  },
  {
    key: "onboardingDescriptionTextWhatsapp",
    module: "onboarding",
    label:
      "Perfecto para marketing, ventas y atención a clientes. Con iVentas ¡No perderás oportunidades de venta!",
  },
  {
    key: "onboardingDescriptionTextPayment",
    module: "onboarding",
    label: "Contrata sin compromisos, cancela cuando quieras",
  },
  {
    key: "onboardingDescriptionAction",
    module: "onboarding",
    label: "¡Bien! Ir al último paso",
  },
  {
    key: "emojiCheckAriaLabel",
    module: "aria",
    label: "emoji palomita",
  },
  {
    key: "emojiWomanInComputerAriaLabel",
    module: "aria",
    label: "emoji mujer en computadora",
  },
  {
    key: "emojiManInComputerAriaLabel",
    module: "aria",
    label: "emoji hombre en computadora",
  },
  {
    key: "emojiHandshakeAriaLabel",
    module: "aria",
    label: "emoji apretón de manos",
  },
  {
    key: "paymentWithStripeLabel",
    module: "onboarding",
    label:
      "* El cobro a tu tarjeta está libre de riesgos vía Stripe, el motor de pagos más importante y seguro del mundo.",
  },
  {
    key: "paymentFinalMonthlyCost",
    module: "onboarding",
    label: (cost) => `Solo pagas USD $ ${cost}/mes`,
  },
  {
    key: "planResume",
    module: "onboarding",
    label: "Resumen de tu plan",
  },
  {
    key: "changePlan",
    module: "onboarding",
    label: "(Cambiar plan)",
  },
  {
    key: "unlimitedPlan",
    module: "onboarding",
    label: "Ilimitado",
  },
  {
    key: "paymentNoteStrong",
    module: "onboarding",
    label: "Nota:",
  },
  {
    key: "paymentTextOne",
    module: "onboarding",
    label: "No te cobraremos nada durante las",
  },
  {
    key: "paymentTextTwo",
    module: "onboarding",
    label: (hours) => `próximas ${hours} hrs de tu prueba`,
  },
  {
    key: "paymentTextThree",
    module: "onboarding",
    label:
      ". Después de ese tiempo y si no cancelas tu cuenta, entonces realizaríamos el primer cobro mensual. Recuerda que puedes cancelar",
  },
  {
    key: "paymentTextFour",
    module: "onboarding",
    label: "cuando quieras",
  },
  {
    key: "paymentTextFive",
    module: "onboarding",
    label: "¿Dudas?",
  },
  {
    key: "paymentTextSix",
    module: "onboarding",
    label: "Contacto para pago por transferencia",
  },
  {
    key: "sendUsAWhatsappText",
    module: "onboarding",
    label: "Mándanos un WhatsApp",
  },
  {
    key: "linkWhatsappText",
    module: "onboarding",
    label: "Conectar WhatsApp",
  },
  {
    key: "errorGettingAvailableWhatsappInstances",
    module: "Agents",
    codeError: "ERROR_GETTING_AVAILABLE_WHATSAPP_INSTANCES",
    label: "Ha ocurrido un error, por favor contacta a soporte.",
  },
  {
    key: "goToWhatsappSupport",
    module: "LinkWhatsapp",
    label: "Contactar por WhatsApp",
  },
  {
    key: "genericErrorLabel",
    module: "General",
    label: "Ha ocurrido un error",
  },
  {
    key: "errorGettingQRLabel",
    module: "LinkWhatsapp",
    label: "Por favor contacta a soporte",
  },
  {
    key: "noUpdateUserPassword",
    module: "UserAction",
    label: "No cambiar contraseña",
  },
  {
    key: "emptySelectedSpacesError",
    codeError: "SHOULD_SELECT_AT_LEAST_ONE_SPACE",
    module: "Users",
    label: "Debes seleccionar al menos un espacio.",
  },
  {
    key: "filterLabel",
    module: "Table",
    label: "Filtros",
  },
  {
    key: "deleteTooltipLabel",
    module: "Table",
    label: "Borrar",
  },
  {
    key: "spacesUserColumn",
    module: "User",
    label: "Espacios",
  },
  {
    key: "spaceLabel",
    module: "Chat",
    label: "Espacio:",
  },
  {
    key: "noAnsweredFilter",
    module: "Chat",
    label: "No contestadas",
  },
  {
    key: "unansweredFilter",
    module: "Chat",
    label: "Sin respuesta",
  },
  {
    key: "archivedFilter",
    module: "Chat",
    label: "Archivadas",
  },
  {
    key: "archivedBroadcastFilter",
    module: "Chat",
    label: "Difusión",
  },
  {
    key: "conversationValuable",
    module: "Chat",
    label: "Gran Oportunidad",
  },
  {
    key: "createNewAlbumButtonLabel",
    module: "Chat",
    label: "Crear nuevo álbum",
  },
  {
    key: "addNewSpaceLabel",
    module: "Spaces",
    label: "Agregar nuevo espacio",
  },
  {
    key: "addNewWhatsappTemplatesLabel",
    module: "WhatsappTemplates",
    label: "Agregar nueva plantilla",
  },
  {
    key: "templateNameColumnWhatsappTemplatesWidget",
    module: "WhatsappTemplates",
    label: "Nombre",
  },
  {
    key: "addNewTagLabel",
    module: "Tags",
    label: "Agregar nueva etiqueta",
  },
  {
    key: "addNewRoleLabel",
    module: "Roles",
    label: "Agregar nuevo rol",
  },
  {
    key: "addNewDataSheetLabel",
    module: "Datasheet",
    label: "Agregar nuevo campo de contacto",
  },
  {
    key: "afiliateTitle",
    module: "Afiliate",
    label: "Bienvenido a iVentas (Afiliados)",
  },
  {
    key: "afiliateDescription",
    module: "Afiliate",
    label:
      "Ingresa la contraseña que te proporcionamos para consultar toda la información de tus cuentas referenciadas.",
  },
  {
    key: "afiliateLogin",
    module: "Afiliate",
    label: "Entrar",
  },
  {
    key: "payUsers",
    module: "Afiliate",
    label: "Usuarios con pago",
  },
  {
    key: "freeUsers",
    module: "Afiliate",
    label: "Usuarios sin pago",
  },
  {
    key: "companyColumnName",
    module: "Afiliate",
    label: "Compañía",
  },
  {
    key: "contactColumnName",
    module: "Afiliate",
    label: "Datos de contacto",
  },
  {
    key: "createdAtColumnName",
    module: "Afiliate",
    label: "Fecha de registro",
  },
  {
    key: "actualPlanColumnName",
    module: "Afiliate",
    label: "Plan actual",
  },
  {
    key: "nextPaymentColumnName",
    module: "Afiliate",
    label: "Próximo pago",
  },
  {
    key: "comissionColumnName",
    module: "Afiliate",
    label: "Comisión",
  },
  {
    key: "welcomeAfiliateTitle",
    module: "Afiliate",
    label: "Bienvenido,",
  },
  {
    key: "referralNumber",
    module: "Afiliate",
    label: "Número de referenciados",
  },
  {
    key: "monthlyEarnings",
    module: "Afiliate",
    label: "Ganancia de este mes",
  },
  {
    key: "totalEarnings",
    module: "Afiliate",
    label: "Ganancias totales",
  },
  {
    key: "referralAccounts",
    module: "Afiliate",
    label: "Cuentas referenciadas",
  },
  {
    key: "thereIsNoPaidUsers",
    module: "Afiliate",
    label: "No hay usuarios con pago registrados",
  },
  {
    key: "thereIsNoUnpaidUsers",
    module: "Afiliate",
    label: "No hay usuarios sin pago registrados",
  },
  {
    key: "classification",
    module: "Classification",
    label: "Clasificación",
  },
  {
    key: "opportunity",
    module: "Classification",
    label: "¡Oportunidad excelente!",
  },
  {
    key: "opportunitiesTitle",
    module: "Classification",
    label: "Oportunidades",
  },
  {
    key: "opportunitiesFirstText",
    module: "Classification",
    label:
      "iVentas detecta mediante inteligencia artificial cuáles son las conversaciones con más oportunidades de venta.",
  },
  {
    key: "opportunitiesSecondText",
    module: "Classification",
    label:
      "Te recomendamos que atiendas a estos clientes con mayor prioridad. ¡Cerrarás muchas ventas!",
  },
  {
    key: "buttonOk",
    module: "Classification",
    label: "Entendido",
  },
  {
    key: "classificationMainTitle",
    module: "Classification",
    label: "¿Como calificarías este lead?",
  },
  {
    key: "classificationOptionGood",
    module: "Classification",
    label: "Muy buena oportunidad",
  },
  {
    key: "classificationOptionBad",
    module: "Classification",
    label: "No está muy interesado",
  },
  {
    key: "classificationModalTitle",
    module: "Classification",
    label: "Clasificación de leads",
  },
  {
    key: "classificationModalFirstP",
    module: "Classification",
    label:
      "Califica los leads que consideras más valiosos, más interesados y con más oportunidad de cerrar una venta.",
  },
  {
    key: "classificationModalSecondP",
    module: "Classification",
    label:
      "Con base en estas calificaciones la poderosa tecnología de iVentas te ayudará a detectar conversaciones valiosas en un futuro, para que no se te escape ninguna oportunidad.",
  },
  {
    key: "opportinityLabelCard",
    module: "Classification",
    label: "Gran oportunidad",
  },
  {
    key: "createWelcomeMessageSuccess",
    module: "welcomeMessage",
    label: "Mensaje de bienvenida creado con éxito",
  },
  {
    key: "updateWelcomeMessageSuccess",
    module: "welcomeMessage",
    label: "Mensaje de bienvenida actualizado con éxito",
  },
  {
    key: "updateFollowUpMessageSuccess",
    module: "welcomeMessage",
    label: "Mensaje de seguimiento actualizado con éxito",
  },
  {
    key: "createFollowUpMessageSucceess",
    module: "welcomeMessage",
    label: "Mensaje de seguimiento creado con éxito",
  },
  {
    key: "updateWelcomeMessageError",
    module: "welcomeMessage",
    label: "Error actualizando mensaje de bienvenida",
  },
  {
    key: "updateFollowUpMessageError",
    module: "welcomeMessage",
    label: "Error actualizando mensaje de seguimiento",
  },
  {
    key: "activeWelcomeMessageNoWorkingHoursLabel",
    module: "welcomeMessage",
    label: "Activar mensaje de bienvenida fuera de horario comercial:",
  },
  {
    key: "activeWelcomeMessageWorkingHoursLabel",
    module: "welcomeMessage",
    label: "Activar mensaje de bienvenida en horario comercial:",
  },
  {
    key: "errorDeletingMessage",
    module: "chat",
    label: "Error al elminar mensaje",
  },
  {
    key: "workingHoursTitle",
    module: "welcomeMessage",
    label: "Horarios comerciales",
  },
  {
    key: "workingHoursHint",
    module: "welcomeMessage",
    label:
      "Si hay días completos que no entren dentro del horario comercial de tu negocio, selecciona 00:00 a 00:00",
  },
  {
    key: "timeZoneLabel",
    module: "welcomeMessage",
    label: "Zona Horaria",
  },
  {
    key: "workingHoursActiveLabel",
    module: "welcomeMessage",
    label: "Activar horarios comerciales",
  },
  {
    key: "isAgentNameInMessageActive",
    module: "UserAccount",
    label: "Mostrar nombre de agente en mensajes",
  },
  {
    key: "isAgentNameInMessageActiveLabel",
    module: "UserAccount",
    label: "Activar Mostrar nombre de agente en mensajes",
  },
  {
    key: "canUnarchiveConversations",
    module: "UserAccount",
    label: "Mantener los chats archivados",
  },
  {
    key: "canReturnInboxConversation",
    module: "UserAccount",
    label: "Mantener los chats de difusión archivados",
  },
  {
    key: "isBotMenuAssignmentsActive",
    module: "UserAccount",
    label: "Activar asignación automática Bot Menú",
  },
  {
    key: "isBotActiveAfterDay",
    module: "UserAccount",
    label: "Activar Bot Menú después de 24 horas",
  },
  {
    key: "isBotAutoAssignActiveAfterDay",
    module: "UserAccount",
    label: "Activar asignación automática Bot Menú después de 24 horas",
  },
  {
    key: "archivedConversationInfoForUser",
    module: "UserAccount",
    label:
      "Los chats archivados permanecerán archivados cuando recibas un mensaje nuevo.",
  },
  {
    key: "archivedBroadcastConversation",
    module: "UserAccount",
    label:
      "Los chats de difusión archivados permanecerán archivados cuando recibas un mensaje nuevo.",
  },
  {
    key: "botWelcomeDescription",
    module: "welcomeMessage",
    label:
      "Este bot enviará un mensaje de bienvenida cada que uno de tus clientes inicie un nueva conversación",
  },
  {
    key: "botFollowUpDescription",
    module: "followUpMessage",
    label:
      // eslint-disable-next-line max-len
      '¿Te ha pasado que algunos clientes dejan de responder? Con este bot mandará un mensaje de seguimiento a los contactos que etiquetes como "Fríos".',
  },
  {
    key: "botMenuDescription",
    module: "welcomeMessage",
    label:
      "Este bot es un menú que enviará un mensaje de bienvenida con diferentes \nopciones a elegir para tus prospectos. \n\nAl final de tu mensaje, te sugerimos pedirle a tu prospecto que elija una \nopción de tu menú.",
  },
  {
    key: "botMenuFinalDescription",
    module: "welcomeMessage",
    label:
      "Escribe el mensaje que quieres que el bot envíe después de que tu \nprospecto elija esta opción.",
  },
  {
    key: "botMenuFinalMessageDescription",
    module: "welcomeMessage",
    label:
      "Escribe el mensaje que quieres que el bot envíe después de que tu \nprospecto elija esta opción.",
  },
  {
    key: "tagcoldConversation",
    module: "tags",
    label: "Conversación fria",
  },
  {
    key: "coldConversation",
    module: "tags",
    label: "Frío",
  },
  {
    key: "textSms",
    module: "SMS",
    label: "Envía un mensaje SMS para darle seguimiento a tu contacto",
  },
  {
    key: "sendSMS",
    module: "SMS",
    label: "Enviar SMS",
  },
  {
    key: "withoutTags",
    module: "tags",
    label: "Sin etiquetas",
  },
  {
    key: "followUpTitle",
    module: "follow up",
    label: "Seguimiento",
  },
  {
    key: "followUpMessageActive",
    module: "follow up",
    label: "Mensaje programado",
  },
  {
    key: "followUpNoMessageActive",
    module: "follow up",
    label: "Sin mensajes programados",
  },
  {
    key: "followUpSchedule",
    module: "follow up",
    label: "Programar",
  },
  {
    key: "followUpScheduleTitle",
    module: "follow up",
    label: "Programar un mensaje de seguimiento",
  },
  {
    key: "followUpInputDateLabel",
    module: "follow up",
    label: "Programa un mensaje de seguimiento",
  },
  {
    key: "followUpTemplateType",
    module: "follow up",
    label: "Selecciona el tipo de plantilla",
  },
  {
    key: "followUpInputLabel",
    module: "follow up",
    label: "Mensaje",
  },
  {
    key: "followUpInputPlaceholder",
    module: "follow up",
    label:
      "¡Hola!, continuo dándote seguimiento, ¿Te gustaría agendar una llamada?",
  },
  {
    key: "followUpEdit",
    module: "follow up",
    label: "Editar mensaje",
  },
  {
    key: "followUpCreate",
    module: "follow up",
    label: "Crear mensaje",
  },
  {
    key: "errorCreatingFollowUpMessage",
    module: "follow up",
    label: "Error al programar mensaje",
  },
  {
    key: "errorDeletingFollowUpMessage",
    module: "follow up",
    label: "Error al eliminar mensaje programado",
  },
  {
    key: "errorUpdatingFollowUpMessage",
    module: "follow up",
    label: "Error al actualizar mensaje programado",
  },
  {
    key: "whatsappTemplateTitle",
    module: "whatsapp templates",
    label: "Plantillas de mensaje de WhatsApp",
  },
  {
    key: "whatsappTemplateInfo",
    module: "whatsapp templates",
    label:
      "¡Inicia una conversación y dale seguimiento a potenciales clientes con estas plantillas!",
  },
  {
    key: "whatsappTemplateReturn",
    module: "whatsapp templates",
    label: "Regresar a tus plantillas",
  },
  {
    key: "whatsappTemplateCategory",
    module: "whatsapp templates",
    label: "Categoría",
  },
  {
    key: "whatsappTemplateName",
    module: "whatsapp templates",
    label: "Nombre de plantilla",
  },
  {
    key: "whatsappTemplateSelectChannel",
    module: "whatsapp templates",
    label: "Selecciona el número",
  },
  {
    key: "initWhatsappTemplateView",
    module: "whatsapp templates",
    label: "Plantillas de mensaje de WhatsApp",
  },
  {
    key: "createWhatsappTemplate",
    module: "whatsapp templates",
    label: "Crea una nueva plantilla",
  },
  {
    key: "whatsappTemplateCardTitle",
    module: "whatsapp templates",
    label: "Plantilla simple",
  },
  {
    key: "whatsappTemplateCategoryCardTitle",
    module: "whatsapp templates",
    label: "Avisos, marketing y promociones",
  },
  {
    key: "whatsappTemplateSelectedCard",
    module: "whatsapp templates",
    label: "Basado en tu mensaje anterior, te sugerimos elegir esta categoría",
  },
  {
    key: "whatsappTemplateCardSubtitle",
    module: "whatsapp templates",
    label: "Ideal para iniciar una conversación",
  },
  {
    key: "customWhatsappTemplateCardTitle",
    module: "whatsapp templates",
    label: "Plantilla con campos personalizables",
  },
  {
    key: "whatsappTemplateCategoryIICardTitle",
    module: "whatsapp templates",
    label: "Actualización de estatus de un evento o transacción",
  },
  {
    key: "customWhatsappTemplateCardSubtitle",
    module: "whatsapp templates",
    label: "Ideal para dar atención personalizada y seguimiento",
  },
  {
    key: "newWhatsappTemplate",
    module: "whatsapp templates",
    label: "Nueva plantilla",
  },
  {
    key: "newCustomWhatsappTemplate",
    module: "whatsapp templates",
    label: "Nueva plantilla con campos personalizables",
  },
  {
    key: "whatsappTemplateCategoryView",
    module: "whatsapp templates",
    label: "¿A qué categoría pertenece tu plantilla?",
  },
  {
    key: "customWhatsappTemplateTitle",
    module: "whatsapp templates",
    label: "Nueva plantilla con campos personalizables",
  },
  {
    key: "variablesLabel",
    module: "whatsapp templates",
    label: "Edita tus campos personalizables:",
  },
  {
    key: "followUpLabel",
    module: "whatsapp templates",
    label: "Selecciona la fecha y la hora en la que se enviará tu mensaje:",
  },
  {
    key: "whatsappTemplateWarning",
    module: "whatsapp templates",
    label:
      "Primer contacto por Whatsapp debe ser a través de plantillas aprobadas.",
  },
  {
    key: "mobieLabel",
    module: "Nav",
    label: "Una producción especial de Next AI & Mobieffects",
  },
  {
    key: "selectChannel",
    module: "Contacts",
    label: "Seleccionar canal",
  },
  {
    key: "createContactGenericError",
    module: "Contacts",
    label: "Error al crear contacto",
  },
  {
    key: "createContactDuplicateError",
    module: "Contacts",
    label: "Contacto ya existente",
  },
  {
    key: "createContactButton",
    module: "Contacts",
    label: "Añadir contacto",
  },
  {
    key: "newContactLabel",
    module: "Contacts",
    label: "Nuevo contacto",
  },
  {
    key: "createContactModalButton",
    module: "Contacts",
    label: "Crear contacto",
  },
  {
    key: "createContactSuccess",
    module: "Contacts",
    label: "Contacto creado con éxito",
  },
  {
    key: "fileFormarNotValid",
    module: "Messages",
    label: "Formato de archivo no soportado",
  },
  {
    key: "contactNoAvailable",
    module: "Contacts",
    label: "Este número no se encuentra registrado en WhatsApp",
  },
  {
    key: "errocActiveChannelNotFound",
    module: "Channels",
    codeError: "ERROR_ACTIVE_CHANNEL_NOT_FOUND",
    label:
      "No tienes un canal activo, por favor vincula tu número de WhatsApp o página de Facebook",
  },
  {
    key: "templateAlreadyExists",
    module: "Templates",
    codeError: "TEMPLATE_ALREADY_EXISTS",
    label:
      "El nombre de la platilla ya existe, por favor intenta con uno diferente.",
  },
  {
    key: "templateNameNotAvailable",
    module: "Templates",
    codeError: "TEMPLATE_NAME_NOT_AVAILABLE",
    label:
      "El nombre de la plantilla ya está en uso, por favor intenta con uno diferente.",
  },
  {
    key: "selectedVariableInvalid",
    module: "Templates",
    codeError: "SELECTED_VARIABLE_INVALID",
    label: "Para eliminar una variable es necesario seleccionarla.",
  },
  {
    key: "emptyEditorContentError",
    module: "Templates",
    codeError: "EMPTY_EDITOR_CONTENT_ERROR",
    label:
      "El contenido del editor está vacío. Por favor, añada contenido antes de continuar.",
  },
  {
    key: "inputSizeLimitError",
    module: "Templates",
    codeError: "INPUT_SIZE_LIMIT_ERROR",
    label:
      "El tamaño del texto ingresado supera el límite permitido. Por favor, ingrese un texto más corto.",
  },
  {
    key: "incomingTextFormatError",
    module: "Templates",
    codeError: "INCOMING_TEXT_FORMAT_ERROR",
    label: "Formato no valido, ingresar solo texto.",
  },
  {
    key: "emptyTypeError",
    module: "Templates",
    codeError: "EMPTY_TYPE_ERROR",
    label: "Selecciona una tipo de plantilla por favor.",
  },
  {
    key: "emptyContentError",
    module: "Templates",
    codeError: "EMPTY_CATEGORY_ERROR",
    label: "Selecciona una Categoría por favor.",
  },
  {
    key: "variablesWarning",
    module: "Templates",
    codeError: "VARIABLES_WARNING",
    label:
      "No se detectaron variables en el texto, puedes agregar varibles manualmente si es el caso",
  },
  {
    key: "onboardingVerificationTitle",
    module: "Onboarding",
    label: "Código de verificación",
  },
  {
    key: "onboardingVerificationText",
    module: "Onboarding",
    label: "Ingresa el código de verificación que te enviamos vía WhatsApp",
  },
  {
    key: "onboardingVerificationResend",
    module: "Onboarding",
    label: "Haz click aquí para reenviar el código",
  },
  {
    key: "onboardingGretting",
    module: "Onboarding",
    label: "¡Hola! Cuéntanos sobre ti 😉",
  },
  {
    key: "campaginTagEmpty",
    module: "Campaigns",
    codeError: "TAG_NAME_EMPTY",
    label: "Se requiere un nombre de etiqueta",
  },
  {
    key: "createTagCampaignTitle",
    module: "Campaign",
    label: "¿Quieres crear una etiqueta para tus contactos de esta campaña?",
  },
  {
    key: "createTagCampaignYes",
    module: "Campaign",
    label: "Sí, quiero crear una etiqueta",
  },
  {
    key: "createTagCampaignNo",
    module: "Campaign",
    label: "No, pasar al último paso",
  },
  {
    key: "createTagCampaignPickColor",
    module: "Campaign",
    label: "Selecciona un color para tu etiqueta",
  },
  {
    key: "usersLimitReached",
    module: "Users",
    codeError: "USER_LIMIT_REACHED",
    label: "Límite de usuarios alcanzado.",
  },
  {
    key: "failedPaymentWarning1",
    module: "Payments",
    label: (price, currency, companyName) => `Estimado Cliente: Hubo un problema al detonar el cobro por suscripción mensual de iVentas en su tarjeta enlazada. El día de mañana se intentará un nuevo cobro por la cantidad de $${price} (${currency}), ${companyName}. Agradecemos su apoyo en mantener actualizado su método de pago con fondos suficientes. Le deseamos un excelente día.`,
  },
  {
    key: "failedPaymentWarning2",
    module: "Payments",
    label: (price, currency, companyName) => `Estimado Cliente: Hubo un problema al detonar el cobro por suscripción mensual de iVentas en su tarjeta enlazada. El día de mañana se intentará un nuevo cobro por la cantidad de $${price} (${currency}), ${companyName}. Agradecemos su apoyo en mantener actualizado su método de pago con fondos suficientes. Le deseamos un excelente día.`,
  },
  {
    key: "failedPaymentWarning3",
    module: "Payments",
    label: (price, currency, companyName) => `Estimado Cliente: Hubo un problema por tercera ocasión, al detonar el cobro por suscripción mensual de iVentas en su tarjeta enlazada. En 24 horas su servicio será pausado. Sírvase de realizar su pago por la cantidad de $${price} (${currency}), ${companyName}. Le deseamos un excelente día.`,
  },
  {
    key: "failedPaymentWarningPaused",
    module: "Payments",
    label: (price, currency, companyName) => `Servicio Pausado.\nPor favor, sírvase a cubrir el saldo pendiente. $${price} (${currency}), ${companyName}.`,
  },
];

export const getJcnError = (codeError) => {
  const message = find(Labels, { codeError });
  const label = message ? message.label : "Ha ocurrido un error";
  return label;
};

export const getLabelMessage = (texts) => {
  const objectLabel = {};
  forEach(texts, (text) => {
    const message = find(Labels, { key: text });
    const label = message ? message.label : text;
    objectLabel[text] = label;
  });
  return objectLabel;
};

export const translateWord = (word) => {
  const foundWord = find(Labels, { key: word });
  return isUndefined(foundWord) ? word : foundWord.label;
};

export default getJcnError;
