import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MAIN_COLOR } from '../../../const/theme';

export const NavContainer = styled.nav`
  grid-area: nav;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 25px;
  width: 100%;
  background-color: ${(props) => props.background === 'main' ? props.theme.new.blue : '#4350AF'};
`;

export const ProfileContainer = styled.div`
  padding: 1.5rem 1.1rem;
`;

export const ProfileImage = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
`;

export const ProfileNameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const ProfileName = styled.div`
  font-family: Montserrat-bold;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  color: ${(props) => props.theme.new.mainText};
`;

export const ProfileReferralCodeLink = styled.div`
  text-align: center;
  cursor: pointer;
`;

export const ProfileReferralCodeLinkImg = styled.img`
  height: 100%;
`;

export const ProfileReferralCodeText = styled.span`
  font-size: 0.8rem;
  color: ${(props) => props.theme.primary};
`;

export const ArrowButton = styled(FontAwesomeIcon)`
  font-size: 12px;
`;

export const CompanyName = styled.div`
  width: 100%;
  text-align: center;
  color: ${MAIN_COLOR};
  margin-top: 10px;
`;

export const RelativeDiv = styled.div`
  display: flex;
  width: 100%;
`;

/* Modal *******************************
*/
export const Modal = styled.div`
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 180px;
  height: 100px;
  top: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  ${(props) => !props.hide && 'display: none'}
`;

export const ModalContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.div`
  font-family: Visuelt-Medium;
  font-size: 14px;
  text-align: center;
  width: 100%;

  ${(props) => props.textRed && 'color: red;'}

  &:hover {
    cursor: pointer;
  }
`;

export const ModalArrow = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 30px;
  color: white;
  top: -18px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 3;
`;

export const Footer = styled.div`
  height: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Logo = styled.img`
  width: 7rem;
`;

export const BrandName = styled.div`
  text-align: center;
  padding: 0 1.68rem;
  font-family: Montserrat-Regular;
  font-size: 0.7rem;
  color: ${(props) => props.theme.new.mainText};
  line-height: 1.2;
`;
