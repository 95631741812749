import API from '../axios';

export const get360StatusAccount = async (
  providerId,
) => {
  try {
    const response = await API.get(`/broadcast/get360Status?providerId=${providerId}`);
    return response?.data;
  } catch (error) {
    console.log('ERROR_get360StatusAccount', error)
    throw new Error('ERROR_get360StatusAccount');
  }
};

export const getBroadcastPricing = async (
  channelId,
  templateId,
  tagsIds,
  conjunction
) => {
  try {
    const response = await API.post(`/broadcast/getTotalPricing`, {
      channelId,
      templateId,
      tagsIds,
      conjunction
    });
    return response?.data;
  } catch (error) {
    console.log('ERROR_getBroadcastPricing', error)
    throw new Error('ERROR_getBroadcastPricing');
  }
};

