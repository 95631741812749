import {
  string as yupstring,
  object as yupobject,
  ref,
} from 'yup';
import { map, isEmpty } from 'lodash';

const validations = {
  name: yupstring()
    .required("Nombre obligatorio")
    .matches(
      /([a-zA-Z0-9_-])$|^[0-9]*$/,
      "No puede contener caracteres especiales"
    )
    .trim(),
  onboardingName: yupstring()
    .required("Nombre obligatorio")
    .matches(
      /^[a-zA-ZáéíóúÁÉÍÓÚ ]*$/,
      "No puede contener caracteres especiales"
    )
    .trim(),
  phone: yupstring()
    .required("Teléfono obligatorio")
    .matches(/^[0-9]*$/, "Solo puede contener números")
    .trim(),
  onboardingPhone: yupstring()
    .required("Teléfono obligatorio")
    .matches(/^[0-9]*$/, "Solo puede contener números")
    .trim(),
  email: yupstring()
    .required("Correo obligatorio")
    .email("Correo invalido")
    .trim(),
  onboardingEmail: yupstring()
    .required("Correo obligatorio")
    .email("Correo invalido")
    .trim(),
  companyName: yupstring().required("Compañia obligatoria").trim(),
  onboardingCompany: yupstring().required("Compañia obligatoria").trim(),
  onboardingPassword: yupstring()
    .required("Contraseña obligatoria")
    .min(8, "la contraseña debe contener al menos 8 caracteres")
    .trim(),
  onboardingPasswordConfirmation: yupstring()
    .required("Campo obligatorio")
    .oneOf(
      [ref("onboardingPassword"), null],
      "Las contraseñas deben coincidir"
    ),
  password: yupstring()
    .required("Contraseña obligatoria")
    .min(8, "la contraseña debe contener al menos 8 caracteres")
    .trim(),
  passwordConfirmation: yupstring()
    .required("Campo obligatorio")
    .oneOf([ref("password"), null], "Las contraseñas deben coincidir"),
  country: yupstring().ensure().required("País obligatorio"),
  onboardingCountry: yupstring().ensure().required("País obligatorio"),
  tagName: yupstring().required("Nombre obligatorio").trim(),
  datasheetField: yupstring().required("Nombre del campo obligatorio").trim(),
  datasheetType: yupstring()
    .required("Tipo de campo obligatorio")
    .oneOf(["string", "date", "boolean", "email", "list", "number", "phone"])
    .trim(),
  rolName: yupstring().required("Nombre del rol obligatorio").trim(),
  whatsappSlotName: yupstring()
    .required("Teléfono obligatorio")
    .matches(/^[0-9]*$/, "Solo puede contener números")
    .trim(),
  whatsappChatHistory: yupstring().required("Selecciona una opción"),
  addVideo: yupstring()
    .required("URL obligatoria")
    .matches(
      /https:\/\/(www\.)?facebook.com\/*/,
      "Debe ser una url de facebook"
    )
    .trim(),
  createNewAlbumName: yupstring().required("Nombre obligatorio").trim(),
  shortcut: yupstring()
    .required("Atajo obligatorio")
    .matches(
      /^\/[0-9a-zA-Z_]*$/,
      "No puede contener espacios ni caracteres especiales"
    )
    .trim(),
  templateName: yupstring()
    .required("Nombre de la plantilla obligatorio")
    .max(512, "El nombre no debe exceder los 1024 caracteres")
    .matches(
      /^[a-z0-9_]*$/,
      "No puede contener mayúsculas, espacios ni caracteres especiales"
    )
    .trim(),
  message: yupstring().required("Mensaje obligatorio").trim(),
  templateMessage: yupstring()
    .max(1024, "El mensaje no debe exceder los 1024 caracteres")
    .trim(),
};
const typeValidation = {
  string: yupstring()
    .trim(),
  number: yupstring()
    .matches(/^([1-9]\d*(\.\d+)?){0,1}$/),
  boolean: yupstring()
    .ensure(),
  date: yupstring()
    .ensure(),
  email: yupstring()
    .email('Correo inválido')
    .trim(),
  phone: yupstring()
    .matches(/^([0-9]{10}){0,1}$/),
};

const formValidationGenerator = (inputs) => {
  const formValidations = {};
  inputs.forEach((input) => (formValidations[input] = validations[input]));
  return yupobject().shape(formValidations);
};

export const formValidationToCustomFields = (fields) => {
  const fieldValidation = {};
  if (!isEmpty(fields)) {
    map(fields, (field) => {
      fieldValidation[field.name] = typeValidation[field.type];
      return fieldValidation;
    });
  }
  return yupobject().shape(fieldValidation);
};

export default formValidationGenerator;
