import styled, { css, keyframes } from "styled-components";

export const CardWrapper = styled.article`
  width: ${(props) => (!props.messageInfo ? "400px" : "200px")};
  background-color: white;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.077));
  padding: 26px;
  border-radius: 12px;
  margin-bottom: 16px;
  margin-right: 16px;
  background-image: ${(props) => `url(${props.image})`};
  background-size: contain;
`;

export const CardTitle = styled.h2`
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin-bottom: 6px;
`;

export const CardNumber = styled.strong`
  display: block;
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin-bottom: 1rem;
  ${(props) =>
    props.isNewText &&
    css`
      opacity: 1;
      animation: ${fadeAnimation} 3s ease-in-out;
    `}
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const CardText = styled.span`
  display: block;
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.color};

  ${(props) =>
    props.isNewText &&
    css`
      opacity: 1;
      animation: ${fadeAnimation} 3s ease-in-out;
    `}
`;
