import styled, { css } from "styled-components";

export const SelectContainer = styled.div`
  overflow: hidden;
`;

export const SelectForm = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  border-radius: 6px;
  background-color: ${(props) => {
    if (props.error && !props.onboardingLabelStyle)
      return "rgba(255, 0, 0, 0.1)";
    if (props.disabled) return "transparent";
    return "#f5f4f6";
  }};
  padding: 0.62rem 0;
  ${(props) =>
    props.onboardingLabelStyle
      ? css`
          margin-top: 20px;
          ${(props) =>
            props.error
              ? css`
                  border: 1px solid #f61e44;
                `
              : css`
            border: border: none;
          `}
        `
      : css`
          margin-top: 0.5rem;
        `}
`;

export const SelectLabel = styled.label`
  ${(props) =>
    props.onboardingLabelStyle
      ? css`
          font-family: "Montserrat-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.249231px;
          color: #2e3353;
        `
      : css`
          font-size: 0.8125rem;
          opacity: 0.5;
        `}
  ${(props) =>
    props.addMargin &&
    css`
      display: block;
      margin-top: 40px;
      margin-bottom: 20px;
    `}
`;

export const SelectIcon = styled.img`
  margin-left: 1rem;
`;

export const SelectValue = styled.select`
  width: 320px;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  background: none;
  border: none;
  margin-left: 1rem;
  flex-grow: 2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &,
  & option {
    color: black;
  }

  &:invalid,
  & option[value=""] {
    opacity: 0.2;
  }

  ${(props) =>
    props.disabled &&
    css`
      appearance: none;
    `};
`;

export const SelectError = styled.div`
  font-size: 1rem;
  color: red;
`;

export const InputError = styled.div`
  ${(props) =>
    props.onboardingLabelStyle
      ? css`
          font-family: "Montserrat-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.257238px;
          color: #f61e44;
        `
      : css`
          font-size: 1rem;
          color: red;
        `}
`;

export const OnboardingStyleError = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

export const OnboardingErrorIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
