import client from "../apollo/apolloClient";
import ApolloSpaces from "../apollo/apolloSpaces";
import API from "../axios";

export const getSpaces = async (companyId) => {
  try {
    console.log("hola");
    const { data } = await client.query({
      query: ApolloSpaces.GET_SPACES_BY_COMPANY,
      variables: { companyId },
    });
    console.log("hola 2");
    console.log(data);
    return data.spaces;
  } catch (error) {
    throw Error("ERROR_GETTING_SPACES");
  }
};

export const getChannelsAvailables = async (companyId) => {
  try {
    const { data } = await client.query({
      query: ApolloSpaces.GET_CHANNELS_IN_DEFAULT,
      variables: { companyId },
    });
    return data.spaces;
  } catch (error) {
    throw Error("ERROR_GETTING_CHANNELS");
  }
};

export const getChannelsAvailableToEdit = async (companyId, spaceId) => {
  try {
    const { data } = await client.query({
      query: ApolloSpaces.GET_CHANNELS_AVAILABLE_TO_EDIT,
      variables: { companyId, spaceId },
    });
    return data;
  } catch (error) {
    throw Error("ERROR_GETTING_CHANNELS");
  }
};

export const createSpace = async (data) => {
  try {
    const response = await API.post("/createSpace", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSpace = async (id) => {
  try {
    const response = await API.delete(`/deleteSpace/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSpace = async (data) => {
  try {
    const response = await API.put("/updateSpace", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getSpaceGroup = async (companyId) => {
  try {
    const response = await API.get(`/getSpaceGroup`);
    console.log("SpaceGroup response ===> ", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
