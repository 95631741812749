import gql from 'graphql-tag';

const GET_MESSENGER_PLATFORM = gql`
  query platforms{
    platforms(where: { name: MESSENGER }) {
      id
      name
      api
    }
  }
`;

const ApolloPlatform = {
  GET_MESSENGER_PLATFORM,
};

export default ApolloPlatform;
