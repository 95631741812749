import styled, { css } from "styled-components";

export const WhatsappTemplatesWidgetContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  margin-top: ${(props) => (props.createTemplate ? "0" : "2rem")};
`;

export const Color = styled.div`
  background-color: ${(props) => props.color};
  width: 15px;
  height: 15px;
  border-radius: 100%;
`;

export const WhatsappTemplateTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
export const Thead = styled.thead`
  border-bottom: 1px solid #d9d9d9;
`;
export const Tbody = styled.tbody``;
export const Th = styled.th`
  font-family: SpaceGrotesk-SemiBold, Inter, sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: -0.257238px;

  color: #2e3353;

  padding-bottom: 1.875rem;
  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;
export const Tr = styled.tr`
  text-align: center;
  height: 90px;
  border-bottom: 1px solid #d9d9d9;
`;
export const Td = styled.td`
  width: 35%;
  font-family: SpaceGrotesk-Regular, Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */

  letter-spacing: -0.257238px;

  color: #757575;

  padding: 1rem 1rem 1rem 0;

  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
`;
export const TableContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 54px;
  max-height: 430px;
  overflow-y: scroll;

  ${(props) =>
    props.newAudience &&
    css`
      margin-top: 114px;
    `}
`;
export const DeleteTemplateContainer = styled.div`
  widt: 24px;
  height: 24px;
  cursor: pointer;
`;
export const DeleteTemplateIcon = styled.img`
  widt: 100%;
`;
export const WhatsappTemplateHead = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const WhatsappTemplateTitle = styled.span`
  font-family: SpaceGrotesk-Medium, Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  /* or 21px */
  width: 28%;
  letter-spacing: -0.257238px;

  color: #757575;
`;
export const WhatsappTemplateStatusText = styled.span`
  font-family: SpaceGrotesk-SemiBold, Inter, sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;

  letter-spacing: -0.257238px;

  color: ${(props) => {
    if (props.status === "submitted") {
      return "rgb(151, 151, 151)";
    } else if (props.status === "accepted") {
      return "#128c7e";
    } else {
      return "#F61E44";
    }
  }};
`;
export const BackButton = styled.button`
  position: absolute;
  bottom: ${(props) => (!props.templateAction ? "0" : "30px")};
  left: ${(props) => (!props.templateAction ? "0" : "24px")};
  width: 11.5rem;
  height: 1.375rem;
  cursor: pointer;
  display: flex;
  z-index: 1000;
  align-items: center;
  border: none;
  background-color: transparent;
`;

export const BackButtonText = styled.span`
  font-family: SpaceGrotesk-SemiBold, Inter, sans-serif;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.257238px;
  text-decoration-line: underline;
  color: #2e3353;
`;

export const BackButtonIconContainer = styled.div`
  width: 22px;
  height: 22px;
`;
export const BackButtonIcon = styled.img`
  width: 100%;
`;
