import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import formGenerator from '../const/forms';
import formValidationGenerator, { formValidationToCustomFields } from '../const/validations';

const useFormBuilder = (
  inputs, defaultValues = {}, byFieldType = false, checkToReset = false, dynamicFields = [], checkToResetDynamicFields = false,
) => {
  const [form, setForm] = useState(formGenerator(inputs, byFieldType, dynamicFields));

  const {
    register,
    unregister,
    errors,
    watch,
    triggerValidation,
    handleSubmit,
    setError,
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    validationSchema: byFieldType ? formValidationToCustomFields(inputs) : formValidationGenerator(inputs),
    defaultValues,
  });

  useEffect(() => {
    if (checkToReset) {
      setForm(formGenerator(inputs, byFieldType, dynamicFields.length ? dynamicFields : null));
    }
  }, [inputs.length]);

  useEffect(() => {
    if (checkToResetDynamicFields) {
      setForm(formGenerator(inputs, byFieldType, dynamicFields.length ? dynamicFields : null));
    }
  }, [dynamicFields.length]);

  return {
    form,
    register,
    unregister,
    errors,
    watch,
    triggerValidation,
    handleSubmit,
    setError,
    control,
    getValues,
    setValue,
    reset,
  };
};

export default useFormBuilder;
