import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { clearDataAction } from '../../../redux/channelsDuck';
import { getConversationsAction } from '../../../redux/conversationsDuck';
import { getJcnError } from '../../../const/labelService';
import { logout as logoutRequest } from '../../../utils/userAuth';
import { ModalContent, SpaceCard, SpaceImg, SpaceName, CloseButton } from './SidebarModal.style';
import { setActualSpaceAction } from '../../../redux/spacesDuck';
import FadeImage from '../../shared/components/FadeImage/FadeImage';
import toastConfig from '../../shared/constants/toaster-config';
import useCloseoutside from '../../../hooks/useCloseOutside';

const SidebarModalComponent = ({ show }) => {
  const { ref, isActive, setIsActive } = useCloseoutside(show);
  const token = useSelector((state) => state.user.data.token);
  const spaces = useSelector((state) => state.spaces.data);
  const selectedSpace = useSelector((state) => state.spaces.selected);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsActive(show);
  }, [show]);

  const logout = async () => {
    try {
      const session = 'front-app';
      await logoutRequest(token, session);
      history.replace('/login');
      dispatch(clearDataAction());
    } catch (error) {
      toast(getJcnError(error.message), toastConfig);
    }
  };

  const changeSpace = async (space) => {
    try {
      await dispatch(setActualSpaceAction(space));
      await dispatch(getConversationsAction(true, 10));
      setIsActive(false);
    } catch (error) {
      console.error(error);
      toast.error(getJcnError(error.message), toastConfig);
    }
  };

  return (
    <ModalContent ref={ref} open={isActive}>
      {spaces?.map((space) => (
        <SpaceCard key={space.id} selected={selectedSpace.id === space.id} onClick={() => changeSpace(space)}>
          <SpaceImg>
            <FadeImage image={space.imageUrl} />
          </SpaceImg>
          <SpaceName>{space.name}</SpaceName>
        </SpaceCard>
      ))}
      <CloseButton onClick={logout}>Cerrar sesión</CloseButton>
    </ModalContent>
  );
};

SidebarModalComponent.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default SidebarModalComponent;
