/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import { useDispatch, useSelector } from "react-redux";

import { getLabelMessage } from "../../../../const/labelService";

import {
  DragZoneContainer,
  Thumb,
  Img,
  Label,
  MainText,
  SecondaryText,
  TextContainer,
  DragWrapper,
  IconsContainer,
  IconContainer,
  IconText,
  PreviewContainer,
  PreviewHeader,
  PreviewBody,
  PreviewIconContainer,
  PreviewTextContainer,
  PreviewTitle,
  PreviewFileType,
  PreviewIcon,
  PreviewDetailsContainer,
  PreviewDeleteFile,
  PreviewDetailsFile,
} from "./DropZoneImage.style";
import { SelectError } from "../select/Select.style";
import imageIcon from "../../../../assets/svg/imageIcon.svg";
import videoIcon from "../../../../assets/svg/videoIcon.svg";
import fileIcon from "../../../../assets/svg/fileIcon.svg";
import getWeightFile from "../../../../utils/getWeightFile";
import { setTempFileAsSelected } from "../../../../redux/multimediaDuck";
import CONSTANTS from "../../../../const/constants";
import PDFViewer from "./pdfViewer/PdfViewer.component";
import PdfPreview from "../../../album/filePreview/pdfPreview/PdfPreview.component";

const DropZoneImage = ({
  prepareFiles,
  defaultFile = null,
  label = null,
  mainText = null,
  disabled = false,
  secondaryText = null,
  thirdText = null,
  maxSize = 15000000,
  minWidth = 512,
  maxWidth = 1024,
  acceptedFilesTypes = ["image/*", "video/mp4"],
  fullSize = false,
  showBorder = false,
  onDrop,
  newTheme = false,
  importContacts,
  templateMedia,
  isPreview,
  isSelectTemplate,
}) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileType, setfileType] = useState("");
  const [error, setError] = useState("");
  const file = useSelector((state) => state.multimedia?.selectedFile?.file);
  const fileUrl = useSelector((state) => state.multimedia?.selectedFile?.url);

  const labels = [
    "dropZoneFormatError",
    "dragFilesError",
    "dragFilesSizeError",
  ];
  const lbls = getLabelMessage(labels);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    //accept: acceptedFilesTypes,
    onDragEnter: () => {
      console.log("onDragEnter!!!!!!!!!!!!");
      setError("");
    },
    onDropRejected: (rejectedFiles) => {
      console.log("onDropRejected!!!!!!!!!!!!");

      let rejectedFilesText = "";
      rejectedFiles.forEach((rejectedFile, index) => {
        rejectedFilesText += `${rejectedFile.name}${
          rejectedFiles.length !== 1 && rejectedFiles.length - 1 !== index
            ? ", "
            : ""
        } `;
      });
      setError(
        (previousError) =>
          `${previousError} ${lbls.dragFilesError(
            rejectedFilesText,
            rejectedFiles.length
          )}`
      );
    },
    onDrop: (acceptedFiles) => {
      console.log("acceptedFiles => ", acceptedFiles);
      if (onDrop) {
        console.log("file.size: ", acceptedFiles[0]?.size);
        console.log("file.size: ", acceptedFiles[0]);
        console.log("file.maxSize: ", maxSize);
        console.log("file.file.name.length : ", acceptedFiles[0]?.name?.length);
        switch (acceptedFiles[0]?.type) {
          case "image/jpeg":
          case "image/png":
          case "video/mp4":
          case "text/csv":
            maxSize = 104857600;
            break;

          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "text/csv":
            maxSize = 104857600;
            break;

          default:
            break;
        }
        const rejectedFiles = acceptedFiles.filter(
          (file) => file.size > maxSize || file.name.length > 100
        );

        console.log("rejectedFiles: ", rejectedFiles);
        const acceptedFilesSize = acceptedFiles.filter(
          (file) => file.size <= maxSize
        );
        if (rejectedFiles.length > 0) {
          let rejectedFilesText = "";
          rejectedFiles.forEach((rejectedFile, index) => {
            rejectedFilesText += `${rejectedFile.name}${
              rejectedFiles.length !== 1 && rejectedFiles.length - 1 !== index
                ? ", "
                : ""
            } `;
          });
          console.log("rejectedFilesText: ", rejectedFilesText);
          setError(
            (previousError) =>
              `${previousError} ${lbls.dragFilesError(
                rejectedFilesText,
                rejectedFiles.length
              )}`
          );
          // setError(
          //   (previousError) =>
          //     `${previousError} ${
          //       rejectedFiles[0].name.length > 100
          //         ? "El nombre del archivo es muy largo"
          //         : lbls.dragFilesSizeError(
          //             rejectedFilesText,
          //             rejectedFiles.length
          //           )
          //     }

          //     `
          // );
        }
        if (acceptedFiles) onDrop(acceptedFilesSize);
      } else if (acceptedFiles[0].size < maxSize) {
        const image = new Image();
        image.addEventListener("load", () => {
          if (
            image.width === image.height &&
            image.width >= minWidth &&
            image.width <= maxWidth
          ) {
            const newFiles = acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            );
            setFiles(newFiles);
            prepareFiles(newFiles);
            console.log("FILE: ", newFiles);
            setError("");
          } else {
            setError(lbls.dropZoneFormatError(minWidth, maxWidth));
          }
        });
        image.src = URL.createObjectURL(acceptedFiles[0]);
        window.removeEventListener("load", image);
      } else {
        setError(lbls.dropZoneFormatError(minWidth, maxWidth));
      }
    },
    disabled,
  });

  useEffect(() => {
    if (defaultFile) {
      const extension = defaultFile.split(".").pop();
      fetch(defaultFile)
        .then((res) => res.blob())
        .then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          const defaultImage = new File([objectURL], `company.${extension}`, {
            type: blob.type,
          });
          Object.assign(defaultImage, {
            preview: objectURL,
            path: defaultFile,
            uploaded: true,
          });
          setFiles([defaultImage]);
        })
        .catch((e) => console.error(e));
    }
  }, [defaultFile]);

  useEffect(() => {
    console.log("FILE: ", file);
    if (file) {
      switch (file.type) {
        case "image/jpeg":
          setfileType("JPG");
          break;
        case "image/png":
          setfileType("PNG");
          break;
        case "video/mp4":
          setfileType("MP4");
          break;
        case "application/pdf":
          setfileType(CONSTANTS.FILE_TYPE_PDF);
          break;

        default:
          break;
      }
    }
  }, [file]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const { fileSize, size } = getWeightFile(file?.size);
  return (
    <>
      {label && <Label newTheme={newTheme}>{label}</Label>}
      <DragWrapper
        newTheme={newTheme}
        fullSize={fullSize}
        importContacts={importContacts}
        templateMedia={templateMedia}
        image={fileType === "JPG" || fileType === "PNG"}
        doc={
          fileType === CONSTANTS.FILE_TYPE_XLSX ||
          fileType === CONSTANTS.FILE_TYPE_PPTX
        }
      >
        {templateMedia && file && (
          <>
            <PreviewContainer isSelectTemplate={isSelectTemplate}>
              {(fileType === "JPG" || fileType === "PNG") && (
                <PreviewHeader
                  image
                  style={{
                    backgroundImage: `url(${fileUrl})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              )}
              {fileType === "MP4" && (
                <PreviewHeader>
                  <video controls width="100%" height="100%">
                    <source src={fileUrl} type="video/mp4" />
                    {/* <source src="ruta-del-video.webm" type="video/webm" /> */}
                    <p>Tu navegador no admite el elemento video.</p>
                  </video>
                </PreviewHeader>
              )}

              {fileType === "PDF" && (
                <PreviewHeader>
                  <PdfPreview url={fileUrl} templateMedia />
                </PreviewHeader>
              )}

              <PreviewBody>
                <PreviewIconContainer>
                  {(fileType === "JPG" || fileType === "PNG") && (
                    <PreviewIcon src={imageIcon} />
                  )}
                  {fileType === "MP4" && <PreviewIcon src={videoIcon} />}
                  {fileType === "PDF" && <PreviewIcon src={fileIcon} />}
                </PreviewIconContainer>

                <PreviewTextContainer>
                  <PreviewTitle wrap={file.name.length > 40}>
                    {file.name}
                  </PreviewTitle>
                  <PreviewFileType>{`Archivo ${fileType}`}</PreviewFileType>
                </PreviewTextContainer>

                <PreviewDetailsContainer>
                  <PreviewDeleteFile
                    onClick={() => {
                      setError("");
                      dispatch(setTempFileAsSelected({}));
                    }}
                  >
                    Eliminar
                  </PreviewDeleteFile>
                  <PreviewDetailsFile>
                    {fileSize}
                    {size}
                  </PreviewDetailsFile>
                </PreviewDetailsContainer>
              </PreviewBody>
            </PreviewContainer>
            <SelectError>{error}</SelectError>
          </>
        )}
        {!file && (
          <>
            <DragZoneContainer
              {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
              disabled={disabled}
              fullSize={fullSize}
              showBorder={showBorder}
              importContacts={importContacts}
              templateMedia={templateMedia}
            >
              {files.map((file) => (
                <Thumb key={file.name}>
                  <Img src={file.preview} />
                </Thumb>
              ))}
              <TextContainer
                fullSize={fullSize}
                importContacts={importContacts}
                templateMedia={templateMedia}
              >
                {importContacts && (
                  <MainText importContacts>{mainText}</MainText>
                )}

                {templateMedia && (
                  <>
                    <IconsContainer>
                      <IconContainer style={{ marginRight: "45px" }}>
                        <img src={imageIcon} alt="imageIcon" />
                        <IconText>Imagen</IconText>
                      </IconContainer>
                      <IconContainer style={{ marginRight: "45px" }}>
                        <img src={videoIcon} alt="videoIcon" />
                        <IconText>Video</IconText>
                      </IconContainer>
                      <IconContainer>
                        <img src={fileIcon} alt="fileIcon" />
                        <IconText>Documento</IconText>
                      </IconContainer>
                    </IconsContainer>
                    <MainText templateMedia>{mainText}</MainText>
                  </>
                )}

                {mainText && !importContacts && !templateMedia && (
                  <MainText newTheme={newTheme}>{mainText}</MainText>
                )}
                {secondaryText && (
                  <SecondaryText newTheme={newTheme}>
                    {secondaryText}
                  </SecondaryText>
                )}
                {thirdText && (
                  <SecondaryText newTheme={newTheme}>{thirdText}</SecondaryText>
                )}
              </TextContainer>
              <input {...getInputProps()} />
            </DragZoneContainer>
            <SelectError>{error}</SelectError>
          </>
        )}
      </DragWrapper>
    </>
  );
};

DropZoneImage.propTypes = {
  prepareFiles: PropTypes.func.isRequired,
  defaultFile: PropTypes.string,
  label: PropTypes.string,
  mainText: PropTypes.string,
  disabled: PropTypes.bool,
  secondaryText: PropTypes.string,
  maxSize: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  fullSize: PropTypes.bool,
  showBorder: PropTypes.bool,
  acceptedFilesTypes: PropTypes.array,
  newTheme: PropTypes.bool,
};

DropZoneImage.defaultProps = {
  newTheme: false,
};

export default DropZoneImage;
