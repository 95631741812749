import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import API from "../../axios";
import CONSTANTS from "../../const/constants";
import {
  onboardingGoToNextStep,
  shouldRestartOnboarding,
} from "../../utils/onboardingUtils";

import {
  ButtonContainer,
  CodeForm,
  CodeP,
  Error,
  InputCode,
  InputCodeContainer,
  Label,
  Loading,
  PrimaryButton,
  SecondaryButton,
  Title,
} from "../../components/simplifiedOnboarding/Onboarding.styles";
import { setOnboardingDataAction } from "../../redux/onboardingDuck";
import { loginUserAction } from "../../redux/userDuck";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const TwoFactorView = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const inputRefs = useRef([]);

  const email = location?.state?.email;
  const password = location?.state?.password;

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seconds, setSeconds] = useState(CONSTANTS.SECONDS_BEFORE_RESEND_CODE);
  const secondsRef = useRef(5);
  const [currentInput, setCurrentInput] = useState(0);

  const init = async () => {
    inputRefs.current[0].focus();
  };

  useEffect(() => {
    init();
    const interval = setInterval(() => updateTimer(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const updateTimer = () => {
    secondsRef.current = secondsRef.current - 1;
    setSeconds(secondsRef.current);
  };

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const newCode = [...code];
    newCode[index] = value.toUpperCase();

    if (value === "") {
      if (index > 0) {
        if (code === "") {
          inputRefs.current[index - 1].focus();
          setCurrentInput(index - 1);
        }
      }
    } else {
      if (index < code.length - 1) {
        inputRefs.current[index + 1].focus();
        setCurrentInput(index + 1);
      } else {
        const finalCode = newCode.join("");
        onSubmit(finalCode);
      }
    }

    setCode(newCode);
  };

  const onKeyDown = (event) => {
    const { key } = event;
    if (key === "Backspace") {
      const newCode = [...code];
      const index = newCode.findIndex((value) => value === "");
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");
    const newCode = [...code];

    for (let i = 0; i < pasteData.length && i < code.length; i++) {
      newCode[i] = pasteData[i];
    }

    setCode(newCode);

    if (newCode.length === 6) {
      onSubmit(newCode.join(""));
    }
  };

  const onSubmit = async (finalCode) => {
    try {
      if (finalCode.length !== 6) {
        setError("El código debe tener 6 dígitos");
        return;
      }
      setLoading(true);
      console.log("finalCode ==> " + finalCode);
      console.log("email ==> " + email);
      console.log("password ==> " + password);
      const route = await dispatch(
        loginUserAction({ email, password, otp: finalCode })
      );
      setLoading(false);
      history.push(route);
    } catch (error) {
      setLoading(false);
      console.error(error.message);
      switch (error?.message) {
        case "ERROR_CHECKING_CODE":
          setError("El código es incorrecto, por favor intenta de nuevo.");
          break;
        case "ERROR_CHECKING_QR_CODE":
          setError("El código es incorrecto, por favor intenta de nuevo.");
          break;
        default:
          setError("Ocurrió un error, por favor intenta de nuevo.");
          break;
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const finalCode = code.join("");
    onSubmit(finalCode);
  };

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CodeForm onSubmit={handleSubmit}>
        <Title>Ingresa el código de verificación</Title>
        <InputCodeContainer>
          {code.map((value, index) => (
            <InputCode
              key={index}
              type="text"
              maxLength="1"
              value={value}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(event) => handleInputChange(index, event)}
              onPaste={handlePaste}
              onKeyDown={onKeyDown}
              selected={index === currentInput}
              filled={value !== ""}
            />
          ))}
        </InputCodeContainer>

        {error && <Error>{error}</Error>}
        <CodeP>Ingresa el Código que aparece en tu app</CodeP>
        {seconds <= 0 && (
          <CodeP>
            Si no pudiste escanear el código
            <SecondaryButton onClick={goBack}>Regresar</SecondaryButton>
          </CodeP>
        )}
        <ButtonContainer flex="space-between" style={{ flexWrap: "wrap" }}>
          {loading && <Loading>Cargando...</Loading>}
          {!loading && <PrimaryButton type="submit">Continuar</PrimaryButton>}
          <SecondaryButton onClick={goBack}>Regresar</SecondaryButton>
        </ButtonContainer>
      </CodeForm>
    </main>
  );
};

export default TwoFactorView;
