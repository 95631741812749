import client from '../apollo/apolloClient';
import ApolloDatasheet from '../apollo/apolloDatasheet';
import API from '../axios';

export const createDataSheetConfiguration = async (field, companyId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloDatasheet.CREATE_DATASHEET_CONFIGURATION,
      variables: {
        name: field.name,
        type: field.type,
        options: field.options,
        companyId,
        key: field.key,
        companyIdString: companyId,
      },
    });
    return data.createDataSheetConfiguration;
  } catch (error) {
    if (error.message.includes('Details: Field name = key')
    || error.message.includes('THAT FIELD NAME IS A DEFAULT ONE')) throw new Error('ERROR_DATASHEETFIELD_NAME');
    throw new Error('ERROR_CREATING_DATASHEETFIELD');
  }
};

export const updateDateSheetConfiguration = async (field) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloDatasheet.UPDATE_DATASHEET_CONFIGURATION_BY_ID,
      variables: { id: field.id, name: field.name, type: field.type, options: field.options, key: field.key },
    });
    return data;
  } catch (error) {
    if (error.message.includes('Details: Field name = key')
    || error.message.includes('THAT FIELD NAME IS A DEFAULT ONE')) throw new Error('ERROR_DATASHEETFIELD_NAME');
    throw new Error('ERROR_UPDATING_DATASHEETFIELD');
  }
};

export const getDataSheetConfigurationByCompanyId = async (companyId) => {
  try {
    const { data } = await client.query({
      query: ApolloDatasheet.GET_DATASHEET_CONFIGURATION_BY_COMPANY_ID,
      variables: { companyId },
    });
    return data.dataSheetConfigurations;
  } catch (error) {
    console.error(error);
  }
};

export const getDefaultDataSheetFields = async () => {
  try {
    const { data } = await client.query({
      query: ApolloDatasheet.GET_DEFAULT_DATASHEET_FIELDS,
    });
    return data.dataSheetConfigurations;
  } catch (error) {
    console.error(error);
  }
};

export const deleteDataSheetConfigurationById = async (id) => {
  try {
    const response = await API.delete(`/datasheetConfiguration/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClientInfoByDataSheetField = async (id) => {
  try {
    const { data } = await client.query({
      query: ApolloDatasheet.GET_CLIENT_INFO_BY_DATASHEET_ID,
      variables: { id },
    });
    console.log(data.clientInfoes);
    return data.clientInfoes;
  } catch (error) {
    throw new Error('ERROR_GETTING_CLIENT_INFO_RELATED');
  }
};

export const deleteClientInfoRelated = async (id) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloDatasheet.DELETE_CLIENT_INFO_BY_DATASHEET_ID,
      variables: { id },
    });
    console.log(data);
    return data.deleteManyClientInfoes;
  } catch (error) {
    console.log(error);
    throw new Error('ERROR_DELETING_CLIENT_INFO_RELATED');
  }
};

export const getClientInformationUtil = async (id) => {
  const { data } = await client.query({
    query: ApolloDatasheet.GET_CLIENT_INFORMATION_BY_ID,
    variables: { clientId: id },
  });
  return data;
};

export const upsertClientInformation = async (key, clientId, fieldNameId, value) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloDatasheet.UPSERT_CLIENT_INFORMATION,
      variables: { key, value, clientId, fieldNameId, clientIdString: clientId },
    });
    console.log(data);
    return data;
  } catch (error) {
    throw new Error('ERROR_SAVING_CLIENT_INFORMATION');
  }
};

export const updateVinteInfo = async (clientId) => {
  try {
    const response = await API.post('/addVinteInfo', {
      clientId,
    });
    return response;
  } catch (error) {
    throw error;
  }
};