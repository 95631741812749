import gql from "graphql-tag";

const GET_MESSAGES_BY_ID = gql`
  query messages($ids: [ID!]) {
    messages(where: { id_in: $ids }, orderBy: createdAt_DESC) {
      id
      content
      time
      createdAt
      seen
      sender
      messageStatus
      seenAt
      seenBy
      actions
      quotedMsgId
      quotedMsgBody
      quotedMsgType
      messageApiId
      clientId
      location {
        url
        latitude
        longitude
        name
        address
      }
      contact {
        id
        phones {
          id
          phone
          wa_id
        }
        name {
          first_name
        }
      }
      withFile
      file {
        id
        name
        fileType
        url
        weight
      }
    }
  }
`;

const GET_LAST_MESSAGE_BY_CLIENT_ID = gql`
  query lastMessages($clientId: String!) {
    messages(
      where: { clientId: $clientId }
      orderBy: createdAt_DESC
      first: 1
    ) {
      id
      content
      createdAt
      seen
      seenAt
      seenBy
      sender
      messageApiId
      quotedMsgId
      quotedMsgBody
      quotedMsgType
      senderId
      actions
      contact {
        id
        phones {
          id
          phone
          wa_id
        }
        name {
          first_name
        }
      }
      location {
        url
        latitude
        longitude
        name
        address
      }
      withFile
      file {
        id
        name
        fileType
        url
        weight
      }
    }
  }
`;

const GET_MESSAGES_BY_CLIENT_ID = gql`
  query messages($ids: [String!], $skip: Int!) {
    messages(
      where: { clientId_in: $ids }
      orderBy: createdAt_DESC
      skip: $skip
      first: 30
    ) {
      id
      content
      time
      createdAt
      seen
      seenAt
      messageStatus
      errorCode
      errorInfo
      seenBy
      actions
      quotedMsgId
      quotedMsgBody
      quotedMsgType
      contactId
      location {
        url
        latitude
        longitude
        name
        address
      }
      sender
      senderId
      withFile
      messageApiId
      file {
        id
        name
        fileType
        url
        weight
      }
      author {
        id
        key
        senderId
        name
      }
    }
  }
`;

const GET_MESSAGES_BY_UNIQUE_CLIENT_ID = gql`
  query messages($id: String!, $skip: Int!) {
    messages(
      where: { clientId: $id }
      orderBy: createdAt_DESC
      skip: $skip
      first: 30
    ) {
      id
      content
      time
      createdAt
      seen
      seenAt
      messageStatus
      errorCode
      errorInfo
      seenBy
      actions
      quotedMsgId
      quotedMsgBody
      quotedMsgType
      contactId
      location {
        url
        latitude
        longitude
        name
        address
      }
      sender
      senderId
      withFile
      messageApiId
      file {
        id
        name
        fileType
        url
        weight
      }
      author {
        id
        key
        senderId
        name
      }
    }
  }
`;

const MARK_AS_READ_MESSAGES = gql`
  mutation updateManyMessages($ids: [ID!], $user: String!, $date: DateTime!) {
    updateManyMessages(
      where: { id_in: $ids }
      data: { seen: true, seenBy: $user, seenAt: $date }
    ) {
      count
    }
  }
`;
const MARK_AS_NOT_READ_MESSAGES = gql`
  mutation markAsNotReadMessages($ids: [ID!]) {
    updateManyMessages(
      where: { id_in: $ids }
      data: { seen: false, seenBy: "" }
    ) {
      count
    }
  }
`;

const UPDATE_FOLLOW_UP_MESSAGE = gql`
  mutation updateFollowUpMessage(
    $id: ID!
    $content: String
    $sendAt: DateTime!
    $templateId: String
    $agentId: ID
    $variables: [String!]
  ) {
    updateFollowUpMessage(
      where: { id: $id }
      data: {
        content: $content
        sendAt: $sendAt
        templateId: $templateId
        agent: { connect: { id: $agentId } }
        variables: { set: $variables }
      }
    ) {
      id
      clientId
      agent {
        id
        email
        name
        company {
          name
        }
      }
      content
      sendAt
      variables
    }
  }
`;

const GET_NOT_SEEN_MESSAGES = gql`
  query messages($id: String!) {
    messages(where: { seen: false, clientId: $id }) {
      id
    }
  }
`;

const GET_LAST_ACTIVE_WINDOW = gql`
  query messages($id: String!) {
    messages(where: { windowExpiration_not: null, clientId: $id }, orderBy: createdAt_DESC, first: 1) {
      id
      windowExpiration
    }
  }
`;


const GET_LAST_MESSAGE_ID = gql`
  query messages($clientId: String!) {
    messages(
      where: { clientId: $clientId }
      orderBy: createdAt_DESC
      first: 1
    ) {
      id
    }
  }
`;

const GET_MESSAGE_BY_ID = gql`
  query message($messageId: ID!) {
    message(where: { id: $messageId }) {
      id
      content
      sender
      senderId
      sent
      intent
      intentConfidence
      context
      seen
      seenAt
      quotedMsgId
      quotedMsgBody
      quotedMsgType
      seenBy
      withFile
      messageApiId
      createdAt
      location {
        url
        latitude
        longitude
        name
        address
      }
      contact {
        id
        phones {
          id
          phone
          wa_id
          contact {
            id
          }
        }
      }
      file {
        id
        name
        fileType
        url
        weight
      }
      author {
        id
        key
        senderId
        name
      }
    }
  }
`;

const GET_ALL_UNSEEN_MESSAGES = gql`
  query unseenMessages($clientIds: [ID!]) {
    messages(where: { client: { id_in: $clientIds }, seen: false }) {
      id
      client {
        id
      }
    }
  }
`;

const GET_FOLLOW_UP_MESSAGE_BY_TEMPLATE = gql`
  query followUpMessages($templateId: String!) {
    followUpMessages(where: { templateId: $templateId }) {
      id
      clientId
      agent {
        id
        email
        name
        company {
          name
        }
      }
      content
      sendAt
      templateId
      channel {
        id
        providerId
        platform {
          api
        }
      }
    }
  }
`;

const GET_FOLLOW_UP_MESSAGE_BY_ID = gql`
  query getFollowUpMessageById($id: ID!) {
    followUpMessage(where: { id: $id }) {
      id
    }
  }
`;

const GET_FOLLOW_UP_MESSAGE_FOR_EDIT = gql`
  query followUpMessagesForEdit($clientId: String!) {
    followUpMessages(where: { clientId: $clientId }) {
      content
      sendAt
    }
  }
`;
const GET_FOLLOW_UP_MESSAGE_ID = gql`
  query getFollowUpMessageID($clientId: String!) {
    followUpMessages(where: { clientId: $clientId }) {
      id
    }
  }
`;
const DELETE_FOLLOW_UP_MESSAGE_ID = gql`
  mutation deleteFollowUpMessage($id: ID) {
    deleteFollowUpMessage(where: { id: $id }) {
      id
    }
  }
`;

const DELETE_MANY_FOLLOW_UP_MESSAGE = gql`
  mutation deleteManyFollowUpMessages($clientId: String) {
    deleteManyFollowUpMessages(where: { clientId: $clientId }) {
      count
    }
  }
`;

const CREATE_FOLLOW_UP_MESSAGE = gql`
  mutation createFollowUpMessage(
    $clientId: String!
    $agentId: ID!
    $content: String
    $sendAt: DateTime!
    $channelId: ID!
    $templateId: String
    $variables: [String!]
    $fileId: String
  ) {
    createFollowUpMessage(
      data: {
        clientId: $clientId
        agent: { connect: { id: $agentId } }
        content: $content
        sendAt: $sendAt
        channel: { connect: { id: $channelId } }
        templateId: $templateId
        variables: { set: $variables }
        fileId: $fileId
      }
    ) {
      id
      clientId
      agent {
        id
        email
        name
        company {
          name
        }
      }
      content
      sendAt
    }
  }
`;

const MESSAGE_STATUS_SUBSCRIBE = gql`
  subscription messageSatus($companyId: String) {
    message(where: { node: { companyId: $companyId }, mutation_in: UPDATED }) {
      node {
        id
        messageStatus
      }
      updatedFields
    }
  }
`;

const GET_FOLLOW_UP_MESSAGE = gql`
  query followUpMessages($clientId: String!) {
    followUpMessages(where: { clientId: $clientId }) {
      id
      clientId
      agent {
        id
        email
        name
        company {
          name
        }
      }
      content
      sendAt
      templateId
      channel {
        id
        providerId
        platform {
          api
        }
      }
      variables
      fileId
    }
  }
`;

const ApolloMessages = {
  GET_MESSAGE_BY_ID,
  GET_MESSAGES_BY_ID,
  GET_MESSAGES_BY_CLIENT_ID,
  GET_MESSAGES_BY_UNIQUE_CLIENT_ID,
  MARK_AS_READ_MESSAGES,
  MARK_AS_NOT_READ_MESSAGES,
  GET_NOT_SEEN_MESSAGES,
  GET_LAST_MESSAGE_BY_CLIENT_ID,
  GET_LAST_MESSAGE_ID,
  GET_ALL_UNSEEN_MESSAGES,
  GET_FOLLOW_UP_MESSAGE,
  GET_FOLLOW_UP_MESSAGE_BY_TEMPLATE,
  UPDATE_FOLLOW_UP_MESSAGE,
  CREATE_FOLLOW_UP_MESSAGE,
  GET_FOLLOW_UP_MESSAGE_FOR_EDIT,
  GET_FOLLOW_UP_MESSAGE_ID,
  DELETE_FOLLOW_UP_MESSAGE_ID,
  MESSAGE_STATUS_SUBSCRIBE,
  GET_FOLLOW_UP_MESSAGE_BY_ID,
  DELETE_MANY_FOLLOW_UP_MESSAGE,
  GET_LAST_ACTIVE_WINDOW
};

export default ApolloMessages;
