import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 26.75rem;
  height: 26.25rem;
  position: relative;
`;

export const Suggestion = styled.p`
  width: ${(props) => (props.variables ? "100%" : "70%")};
  height: 1.688rem;
  margin-top: 1.375rem;
  margin-bottom: 1.938rem;
  font-family: SpaceGrotesk-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  letter-spacing: -0.32px;

  color: #757575;

  ${(props) =>
    props.variables &&
    css`
      margin-top: 0;
      margin-bottom: 5rem;
    `}
  ${(props) =>
    props.messagePreview &&
    css`
      margin-top: 0.625rem;
      width: 100%;
    `}
`;

//

export const NewWhatsappTitle = styled.p`
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-style: normal;

  font-size: 1.125rem;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: -0.257238px;

  color: #2e3353;
`;

export const MessagePreviewContainer = styled.div`
  width: 26.625rem;
  height: auto;
  position: relative;
  min-height: 50px;
  max-height: 580px;
  background: #e3e3e3;
`;
export const MessagePreview = styled.div`
  width: 87%;
  position: relative;
  right: -32px;
  top: 1.563rem;
  background-color: #d9fdd3;
  border-radius: 8px;
  padding: 0.75rem 0.5rem 1.5rem 0.938rem;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #2e3353;

  & > span {
    background-color: #d9fdd3 !important;
    font-family: SpaceGrotesk-Medium, sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 150% !important;
    /* or 21px */
    letter-spacing: -0.257238px !important;

    color: #2e3353 !important;
  }
`;

export const PreviewImageContainer = styled.div`
  width: 95%;
  height: 162px;
  margin-bottom: 0.75rem;
`;

export const ContentSpan = styled.span`
  background-color: #d9fdd3 !important;
  font-family: SpaceGrotesk-Medium, sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 150% !important;

  letter-spacing: -0.257238px !important;

  color: #2e3353 !important;
  padding: 0.75rem 0.75rem 0 0.75rem !important;
  * {
    font-family: inherit !important;
    font-size: inherit !important;
    color: inherit !important;

  }
`;
