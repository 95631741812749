/* eslint-disable max-len */
/* eslint-disable camelcase */
import gql from 'graphql-tag';

const GET_ADMIN_NAME = gql`
  query users($id: ID!) {
    users(where: { id: $id }) {
      name
    }
  }
`;

const GET_ADMINS_BY_COMPANY_ID_AND_LOCATION_ID = gql`
  query users($companyId: String!, $userType: String!) {
    users(where: { companyId: $companyId, userType: { name: $userType } }) {
      id
      name
      email
      company {
        id
        name
      }
      phone
      imageUrl
      location {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const DELETE_ADMIN = gql`
  mutation($documentId: ID!) {
    deleteUser(where: { id: $documentId }) {
      id
    }
  }
`;

const ADD_ADMIN = gql`
  mutation createUser(
    $name: String!
    $email: String!
    $password: String!
    $companyId: ID!
    $companyIdString: String!
    $location: ID!
    $phone: String!
    $userType: String!
  ) {
    createUser(
      data: {
        name: $name
        email: $email
        password: $password
        companyId: $companyIdString
        company: { connect: { id: $companyId } }
        location: { connect: { id: $location } }
        phone: $phone
        userType: { connect: { name: $userType } }
      }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      phone
      imageUrl
      location {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation updateUser($id: ID!, $name: String!, $email: String!, $location: ID!, $phone: String!) {
    updateUser(
      where: { id: $id }
      data: { name: $name, email: $email, location: { connect: { id: $location } }, phone: $phone }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      location {
        id
        name
      }
      phone
    }
  }
`;

const UPDATE_ADMIN_BY_EMAIL = gql`
  mutation updateUser(
    $name: String!
    $email: String!
    $location: ID!
    $phone: String!
    $previousEmail: String!
  ) {
    updateUser(
      where: { email: $previousEmail }
      data: { name: $name, email: $email, location: { connect: { id: $location } }, phone: $phone }
    ) {
      id
      name
      email
      company {
        id
        name
      }
      location {
        id
        name
      }
      phone
    }
  }
`;

const GET_ADMIN_BY_ID = gql`
  query users($id: ID!) {
    users(where: { id: $id }) {
      id
      name
    }
  }
`;

const GET_ADMINS_BY_LOCATION_ID = gql`
  query users($locationId: ID!) {
    users(where: { location: { id: $locationId } }) {
      id
      name
      location {
        id
        name
      }
    }
  }
`;

const GET_ADMINS_BY_COMPANY_ID = gql`
  query users($companyId: ID!) {
    users(where: { companyId: $companyId }) {
      id
      name
      location {
        id
        name
      }
    }
  }
  `;

const USER_SUBSCRIPTION = gql`
  subscription user($userId: ID!) {
    user(where: {node: {id: $userId}}) {
      node {
        id
        name
        email
        imageUrl
        phone
      }
    }
  }
`;

const CLIENT_SUBSCRIPTION_BY_FILTERS = (
  { priority_gte, priority_lte, agentIdIn, tagsIdIn, categoriesIds, companyId, channelsNames, channelsIds },
) => gql`
  subscription clientByFilters(
    ${priority_gte ? '$priority_gte: Float,' : ''}
    ${priority_lte ? '$priority_lte: Float,' : ''}
    ${agentIdIn ? '$agentIdIn: [String!],' : ''}
    ${tagsIdIn ? '$tagsIdIn: [ID!],' : ''}
    ${categoriesIds ? '$categoriesIds: [String!],' : ''}
    ${companyId ? '$companyId: String,' : ''}
    ${channelsNames ? '$channelsNames: [PlatformName!],' : ''}
    ${channelsIds ? '$channelsIds: [String!],' : ''}
    ) {
    client(
      where: {
        node: {
          AND: [
            ${priority_gte ? '{priority_gte: $priority_gte},' : ''}
            ${priority_lte ? '{priority_lte: $priority_lte},' : ''}
            ${agentIdIn ? '{agentId_in: $agentIdIn},' : ''}
            ${tagsIdIn ? '{tags_some: {id_in: $tagsIdIn}},' : ''}
            ${categoriesIds ? '{categoryId_in: $categoriesIds},' : ''}
            ${companyId ? '{companyId: $companyId},' : ''}
            ${channelsIds ? '{channelId_in: $channelsIds}' : ''}
            ${channelsNames ? '{channel: {platform:{name_in: $channelsNames}}}' : ''}
          ]
        }
      }
    ) {
      node {
        id
        whatsappStartedAt
        whatsappLastMessageAt
        senderId
        company {
          id
        }
        channel {
          id
          name
          pageId
          providerId
          pageToken
          platform {
            id
            name
            api
          }
          isActive
          useChatbot
        }
        agent {
          id
          name
          imageUrl
        }
        key
        createdAt
        updatedAt
        lastMessageId
        answered
        nameFromChannel
        imageUrl
        isAssignable
        category {
          id
          name
        }
        priority
        contexts
        last_postbacks
        isReady
        isOver
        isConversationValuable
        setValuableByUser
        hasFollowUpMessage
        tags {
          id
          name
          color
          isDefault
        }
        messages {
          seen
        }
      }
      updatedFields
      previousValues {
        nameFromChannel
      }
    }
  }
`;

const UNASSIGNED_CLIENTS_BY_FILTERS = (
  { priority_gte, priority_lte, agentIdIn, tagsIdIn, categoriesIds, companyId, channelsNames, channelsIds },
) => gql`
  subscription unassignedClientsByFilters(
    ${priority_gte ? '$priority_gte: Float,' : ''}
    ${priority_lte ? '$priority_lte: Float,' : ''}
    ${agentIdIn ? '$agentIdIn: [ID!],' : ''}
    ${tagsIdIn ? '$tagsIdIn: [ID!],' : ''}
    ${categoriesIds ? '$categoriesIds: [ID!],' : ''}
    ${companyId ? '$companyId: ID,' : ''}
    ${channelsNames ? '$channelsNames: [PlatformName!],' : ''}
    ${channelsIds ? '$channelsIds: [ID!],' : ''}
  ) {
    client(
      where: {
        node: {
          AND: [
            ${priority_gte ? '{priority_gte: $priority_lt},' : ''}
            ${priority_lte ? '{priority_lte: $priority_gt},' : ''}
            ${agentIdIn ? '{agent: {id_not_in: $agentIdIn}},' : ''}
            ${tagsIdIn ? '{tags_some: {id_not_in: $tagsIdIn}},' : ''}
            ${categoriesIds ? '{category: {id_not_in: $categoriesIds }},' : ''}
            ${companyId ? '{company:{id:$companyId}},' : ''}
            ${channelsNames && channelsIds
    ? `{
                          channel: {
                            platform:{name_not_in: $channelsNames},
                            id_not_in:$channelsIds
                          }
                        }`
    : channelsNames ? '{channel: {platform:{name_not_in: $channelsNames}}}'
      : channelsIds ? '{channel: {id_not_in:$channelsIds}}'
        : ''
}
          ]
        }
      }
    ) {
      node { id }
    }
  }
`;

const UNSUBSCRIBE_QUERY = gql`
  subscription unsubscribeClientByIdIn($ids: [ID!]) {
    client(where: { node: { id_in: $ids } }) {
      node {
        id
        senderId
        company {
          id
        }
        channel {
          id
          name
          platform {
            id
            name
            api
          }
          isActive
          useChatbot
          providerId
        }
        agent {
          id
          name
          imageUrl
        }
        key
        createdAt
        updatedAt
        lastMessageId
        answered
        nameFromChannel
        imageUrl
        isAssignable
        category {
          id
          name
        }
        priority
        contexts
        last_postbacks
        isReady
        isConversationValuable
        setValuableByUser
        isOver
        tags {
          id
          name
          color
          isDefault
        }
      }
    }
  }
`;

const UNASSIGNED_CLIENTS = gql`
subscription unassignedClient($userId: ID!, $companyId: ID!) {
  client(where: 
    {
      AND: [
        { node: {
            agent: { id_not: $userId },
            companyId: $companyId 
          }
        },
        { mutation_in: UPDATED }
      ]
    }
  ) 
  {
    node { id }
  }
}
`;

const GET_USER_IMAGE_URL = gql`
query user($userId: ID!) {
  user(where: { id: $userId }) {
    id
    imageUrl
    name
  }
}
`;

const ApolloAdmins = {
  GET_ADMIN_NAME,
  GET_ADMINS_BY_COMPANY_ID_AND_LOCATION_ID,
  DELETE_ADMIN,
  ADD_ADMIN,
  UPDATE_ADMIN,
  GET_ADMIN_BY_ID,
  GET_ADMINS_BY_LOCATION_ID,
  GET_ADMINS_BY_COMPANY_ID,
  UPDATE_ADMIN_BY_EMAIL,
  USER_SUBSCRIPTION,
  CLIENT_SUBSCRIPTION_BY_FILTERS,
  UNASSIGNED_CLIENTS,
  GET_USER_IMAGE_URL,
  UNASSIGNED_CLIENTS_BY_FILTERS,
  UNSUBSCRIBE_QUERY,
};

export default ApolloAdmins;
