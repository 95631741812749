import styled, { css } from 'styled-components';
import { FaCaretDown } from 'react-icons/fa';
import Devices from '../../const/devices';

export const ImageContent = styled.div`
  height: 3rem;
  width: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 100%;
  background-color: var(--avatar-${(props) => props.initialLetterName}, #40C351);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
`;

export const ArrowButton = styled(FaCaretDown)`
  display: none;
  color: #000000;
  margin-left: 0.3rem;
  font-size: 1.29rem;
  z-index: 3;
  ${(props) => props.isFilter && css`
    display: unset;
    opacity: 0.5;
  `};
`;

export const UserName = styled.div`
  font-size: 0.999rem;
  font-weight: 500;
  width: 100%;
  color: #000000;
  font-family: system-ui, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${(props) => props.seen && css`
    font-weight: 500;
    color: rgb(44 51 81 / 92%);
  `};
`;

export const UserMessage = styled.span`
  width: 100%;
  font-family: system-ui, sans-serif;
  font-size: 0.8125rem;
  font-weight: ${(props) => (props.seen ? '400' : '700')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => (props.seen ? props.theme.dark.light : props.theme.dark.main)};
`;

export const UpdateNotification = styled.span`
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${(props) => props.theme.primary};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.3rem;
`;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #24d366;
  border-radius: 100%;
  text-align: center;
  font-size: 0.75rem;
  color: white;
  ${(props) =>
    props.isFilter &&
    css`
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.5rem;
      font-size: 0.625rem;
    `};

  ${(props) =>
    props.isBroadcastChat &&
    css`
      margin-left: 0.125rem 0.25rem;
    `}
`;

export const LastMessage = styled.div`
  width: auto;
  height: 0.9375rem;
  opacity: 0.75;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  font-family: system-ui, sans-serif;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserSection = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1.5rem;
  gap: 2px;
`;

export const InformationSection = styled.section`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  justify-content: space-between;
`;

export const AssignedAgent = styled.div`
  width: fit-content;
  max-width: 95%;
  height: 0.9975rem;
  font-family: system-ui, sans-serif;
  font-size: 0.75rem;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  overflow: hidden;
  background-color: ${(props) => props.isAssigned ? "rgb(103 178 243 / 25%)" : "#ffa50070"};
  border-radius: 4px;
  padding-right: 0.3rem;
  padding-left: 0.2rem;
`;

export const IconContainer = styled.div`
  position: relative;
  bottom: 1.4rem;
  left: 2.375rem;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  position: relative;

`;
export const RelativeTags = styled.div`
  position: relative;
  width: 190px;
  height: 80px;
  right: 0;
`;
export const AbsolutTags = styled.div`
  position: absolute;
  right: 0;
  width: 190px;
  height: 80px;
`;

export const TagsModal = styled.div`
  width: 13rem;
  height: ${props => props.elements === 3 ? '105px' : '70px'};
  background-color: #ffffff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 5;
  right: 0;
  padding: 0.5rem 0;
  border-radius: 8px;
  margin-top: ${(props) => (props.bottomDirection ? '3rem' : '-2rem')};
  margin-left: ${(props) => (props.notification ? '2.5rem' : '0.875rem')};
  overflow: visible;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const ModalOptions = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 100;
`;

export const TagsArea = styled.section`
  display: flex;
  width: 11rem;
  height: auto;
  flex-direction: column;
  max-width: 100%;
`;

export const Option = styled.span`
  width: 100%;
  height: 25px;
  padding: 0.313rem 0.313rem;
  font-family: system-ui, sans-serif;
  font-size: 0.975rem;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
  &:hover {
    background-color: #ededed;
  }
  ${(props) => !props.first
    && css`
      margin-top: 5px;
    `};
`;

export const TagTile = styled.span`
  font-family: Montserrat-Regular;
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1rem;
`;

export const TagsList = styled.div`
  display: flex;
  flex-flow: wrap;
  max-width: 100%;
  max-height: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.2rem 0.5rem;
  padding-bottom: 0.6rem;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  margin: 0.2rem 0;
  height: ${(prps) => prps. isConversationValuable ? "auto" : "auto"} ;
  background-color: ${(props) => (props.selected ? "#EAF1FB" : "#FFF")};
  cursor: pointer;
  border-bottom: 1px groove rgba(255, 255, 255, 0.32);
  &:hover {
    background-color: rgba(39, 177, 179, 0.08);
    background-color: ${(props) => props.theme.main};
    ${ArrowButton} {
      display: unset;
    }
  }

  ${InformationSection}:hover & {
    width: 20%;
  }
`;

export const MultimediaLastMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MultimediaTypeLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 0.8125rem;
  font-family: Montserrat-Medium;
  font-weight: 600;
`;
export const StickerIconContainer = styled.div`
  width: 14px;
  height: 14px;
`;
export const StickerIcon = styled.img`
  width: 100%;
`;

export const SpaceName = styled.span`
  font-family: 'system-ui', sans-serif;
  font-size: 12px;
  color: #000000;

  ${(props) => props.seen && css`
    color: rgb(83, 81, 83);
  `};
`;

export const Notification = styled.div`
  background-color: ${(props) =>
    props.color === "blue" ? "#0774F7" : "rgba(44, 52, 80, 1)"};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  margin: 3px 0;
  width: fit-content;
  max-width: ${(props) => (props.color === "blue" ? "initial" : "50%")};
  @media ${Devices.mLargelaptop} {
    padding: 2px 2px;
  }
`;

export const NotificationLabel = styled.span`
  color: white;
  font-family: 'Montserrat-Medium', sans-serif;
  font-size: 10px;
  margin-left: 4px;
  @media ${Devices.mLargelaptop} {
    margin-left: 2px;
  }
`;
export const ContactContainer = styled.div`
  width: 100%;
  height: 30px;
  display: felx;
  align-items: center;
  font-family: SFPro, sans-serif;
  font-size: 0.8125rem;
`;
export const ContactIconContainer = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.125rem;
  margin-bottom: 0.375rem;
`;
export const ContactIcon = styled.img`
  width: 100%;
`;

export const NotificationContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin-top: 2px;
`;

export const EmptyImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
  font-family: system-ui, sans-serif;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.3rem;
`;

