const APP_INIT_STATE = {
  data: {},
  assignments: [],
  //isNewAssignmentRequesting: false,
  fetching: false,
  error: false,
};

const SET_APP_STATUS = 'SET_APP_STATUS';

export default function appReducer(
  state = APP_INIT_STATE,
  action,
) {
  const { type, payload } = action;
  switch (type) {
    case SET_APP_STATUS:
      return { ...state, fetching: true, assignments: payload};
    default:
      return state;
  }
}

export const setAppStatus = (payload) => async (dispatch) => {
  try {
    await dispatch({ type: SET_APP_STATUS, payload: payload });
  } catch (error) {
    throw new Error('ERROR_SETTING_APP_STATUS');
  }
};
