import styled from 'styled-components';

export const ModalContent = styled.div`
  width: 27%;
  height: auto;
  max-height: 600px;
  position: fixed;
  top: 2rem;
  right: 4rem;
  background-color: #ffffff;
  z-index: 20;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const SpaceCard = styled.div`
  height: 5.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => (props.selected ? props.theme.main : '#ffffff')};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.main};
  }
`;

export const SpaceImg = styled.div`
  height: 3rem;
  width: 3rem;
  @media (min-width: 1400px) {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

export const SpaceName = styled.div`
  font-family: Montserrat-Bold;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.dark.main};
  width: 80%;
  max-width: 85%;
`;

export const CloseButton = styled.button`
  outline: none;
  color: #000000;
  text-transform: none;
  margin: 2rem 0;
  font-size: .875rem;
  min-height: 2.125rem;
  text-decoration: none;
  font-family: Montserrat-Medium;
  font-weight: 500;
  padding: .5rem 1rem;
  opacity: 0.5;
  border: 1px solid rgba(0,0,0,0.5);
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
`;
