import gql from "graphql-tag";

const GET_CHANNELS = gql`
  query channels($companyId: ID!, $isActive: Boolean) {
    channels(where: { company: { id: $companyId }, isActive: $isActive }) {
      id
      name
      platform {
        id
        name
      }
      pageId
      pageToken
      providerId
      company {
        id
        name
      }
      whatsappInstance {
        id
      }
      isActive
      isActive
      qualityRating
      imageUrl
      expires_in
      updatedAt
      useChatbot
    }
  }
`;

const GET_ALL_CHANNELS = gql`
  query channels($companyId: ID!) {
    channels(where: { company: { id: $companyId } }) {
      id
      name
      phone
      providerId
      platform {
        id
        name
      }
      pageId
      pageToken
      company {
        id
        name
      }
      whatsappInstance {
        id
      }
      isActive
      qualityRating
      currentLimit
      imageUrl
      expires_in
      updatedAt
      useChatbot
    }
  }
`;

const GET_WHATSAPP_ACTIVE_CHANNELS = gql`
  query channels($companyId: ID!) {
    channels(
      where: {
        company: { id: $companyId }
        isActive: true
        platform: { api_contains: "dialog" }
      }
    ) {
      id
      name
      phone
      providerId
    }
  }
`;

const CREATE_CHANNEL = gql`
  mutation createChannel(
    $name: String!
    $pageId: String!
    $pageToken: String!
    $company: ID!
    $isActive: Boolean!
    $platformId: ID!
  ) {
    createChannel(
      data: {
        name: $name
        platform: { connect: { id: $platformId } }
        pageId: $pageId
        pageToken: $pageToken
        isActive: $isActive
        company: { connect: { id: $company } }
        useChatbot: false
      }
    ) {
      id
      name
      platform {
        id
        name
      }
    }
  }
`;

const DEACTIVATE_CHANNEL = gql`
  mutation updateChannel($id: ID!) {
    updateChannel(where: { id: $id }, data: { isActive: false }) {
      id
      name
      platform {
        id
        name
      }
    }
  }
`;

const ACTIVATE_CHANNEL = gql`
  mutation updateChannel($id: ID!) {
    updateChannel(
      where: { id: $id }
      data: { isActive: true, useChatbot: false }
    ) {
      id
      name
      platform {
        id
        name
      }
    }
  }
`;

const UPDATE_CHANNEL_STATUS = gql`
  mutation updateChannel($id: ID!, $active: Boolean!) {
    updateChannel(where: { id: $id }, data: { isActive: $active }) {
      id
    }
  }
`;

const UPDATE_CHANNEL_CHAT_BOT = gql`
  mutation updateChannel($id: ID!, $active: Boolean!) {
    updateChannel(where: { id: $id }, data: { useChatbot: $active }) {
      id
    }
  }
`;

const UPDATE_CHANNEL_NAME = gql`
  mutation updateChannel($id: ID!, $name: String!) {
    updateChannel(where: { id: $id }, data: { name: $name }) {
      id
    }
  }
`;

const ApolloChannels = {
  CREATE_CHANNEL,
  DEACTIVATE_CHANNEL,
  ACTIVATE_CHANNEL,
  GET_CHANNELS,
  GET_ALL_CHANNELS,
  UPDATE_CHANNEL_STATUS,
  UPDATE_CHANNEL_CHAT_BOT,
  UPDATE_CHANNEL_NAME,
  GET_WHATSAPP_ACTIVE_CHANNELS
};

export default ApolloChannels;
