import client from "../apollo/apolloClient";
import ApolloMessages from "../apollo/apolloMessages";
import ApolloWhatsappTemplates from "../apollo/apolloWhatsappTemplates";
import ApolloBotFollowUp from "../apollo/apolloBotFollowUp";
import ApolloWelcomeMessage from "../apollo/apolloWelcomeMessage";
import API from "../axios";
import CONSTANTS from "../const/constants";

export const createWhatsappTemplate = async (
  name,
  category,
  components,
  channelId,
  type = CONSTANTS.WHATSAPP_TEMPLATE_NORMAL_TYPE
) => {
  const response = await API.post("/whatsappTemplate", {
    name,
    category,
    components,
    channelId,
    type,
  });
  return response?.data?.createWhatsappTemplate;
};
export const getWhatsappTemplates = async (companyId) => {
  try {
    const response = await client.query({
      query: ApolloWhatsappTemplates.GET_TEMPLATES,
      variables: { companyId },
    });
    return response.data.whatsappTemplates;
  } catch (error) {
    console.error(error);
  }
};

export const getWhatsappTemplateByChannel = async (channelId) => {
  try {
    const response = await client.query({
      query: ApolloWhatsappTemplates.GET_TEMPLATES_BY_CHANNEL_ID,
      variables: { channelId },
    });
    return response.data.whatsappTemplates;
  } catch (error) {
    console.error(error);
  }
};

export const deleteWhatsappTemplate = async (id, templateName, channelId) => {
  try {
    const { data: followUpMessagesdata } = await client.query({
      query: ApolloMessages.GET_FOLLOW_UP_MESSAGE_BY_TEMPLATE,
      variables: { templateId: id },
    });
    const followUp = followUpMessagesdata?.followUpMessages;
    console.log(followUp);

    const { data: botFollowUpdata } = await client.query({
      query: ApolloBotFollowUp.GET_MESSAGE_BOT_CONFIG_TEMPLATE,
      variables: { templateId: id },
    });
    const botFollowUp = botFollowUpdata?.messageBotConfigs;
    console.log(botFollowUp);

    const { data: welcomeMessagesdata } = await client.query({
      query: ApolloWelcomeMessage.GET_WELCOME_MESSAGE_TEMPLATE,
      variables: { templateId: id },
    });

    const welcomeMessages = welcomeMessagesdata?.welcomeMessages;
    console.log(welcomeMessages);

    if (
      followUp.length > 0 &&
      botFollowUp.length > 0 &&
      welcomeMessages.length > 0
    ) {
      return "all";
    } else if (
      followUp.length > 0 &&
      botFollowUp.length === 0 &&
      welcomeMessages.length === 0
    ) {
      console.log("followUp");
      return "followUp";
    } else if (
      followUp.length === 0 &&
      welcomeMessages.length === 0 &&
      botFollowUp.length > 0
    ) {
      return "botFollowUp";
    } else if (
      followUp.length === 0 &&
      botFollowUp.length === 0 &&
      welcomeMessages.length > 0
    ) {
      return "welcomeMessages";
    } else if (
      followUp.length > 0 &&
      botFollowUp.length > 0 &&
      welcomeMessages.length === 0
    ) {
      console.log("followUpAndBot");
      return "followUpAndBot";
    } else if (
      followUp.length === 0 &&
      welcomeMessages.length > 0 &&
      botFollowUp.length > 0
    ) {
      return "botFollowUpAndWelcome";
    } else if (
      followUp.length > 0 &&
      botFollowUp.length === 0 &&
      welcomeMessages.length > 0
    ) {
      return "followUpAndwelcome";
    }

    await API.delete("/whatsappTemplate", {
      data: { templateName, channelId },
    });
    return;
  } catch (error) {
    throw new Error("ERROR_DELETING_WHATSAPPTEMPLATE");
  }
};
export const getWhatsappTemplateVariables = async (
  text,
  isIdentifyVariables
) => {
  try {
    const response = await API.post("/identify-variables", {
      text,
      isIdentifyVariables,
    });

    console.log(response);
    const data = response.data;
    console.log(data);
    console.log(data.variables);
    console.log(typeof data.variables);

    const formattedData = {
      category: data.category,
      variables: isIdentifyVariables
        ? JSON.parse(data.variables)
        : data.variables,
    };
    return formattedData;
  } catch (error) {
    console.error(error);
    throw new Error("ERROR_GET_WHATSAPP_TEMPLATE_VARIABLES");
  }
};
export const getIsName = async (name) => {
  try {
    console.log(name);
    const response = await API.post("/identify-name", {
      name,
    });

    console.log(response);
    const data = response.data;
    console.log(data);
    return data.isName;
  } catch (error) {
    console.error(error);
    throw new Error("ERROR_IDENTIFY_NAME");
  }
};
