import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HexColorPicker } from "react-colorful";
import { get } from "lodash";
import { toast } from "react-toastify";

import CONSTANTS from "../../../const/constants";
import {
  ChooseColor,
  ColorPick,
} from "../../settings/tags/tagsAction/Tag.style";
import {
  GropRadioButtons,
  LabelRadioButton,
  RadioButtonsContainer,
} from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  BotInfo,
  ButtonContainer,
  InputContainer,
} from "../../settings/autoresponses/AutoResponses.style";
import Input from "../../../components/shared/components/Input/Input.component";
import useFormBuilder from "../../../hooks/useFormBuilder";
import {
  BroadcastAppCard,
  WhatsappTemplateInputContainer,
} from "./BroadcastView.styles";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import Button from "../../../components/shared/components/button/Button.style";
import {
  setNotTagToCreateAction,
  setTagToCreateAction,
} from "../../../redux/campaignDuck";
import toastConfig from "../../../components/shared/constants/toaster-config";
import getJcnError, { getLabelMessage } from "../../../const/labelService";
import { useEffect } from "react";

const CREATE_TAG_YES = "yes";
const CREATE_TAG_NO = "no";

const BroadcastCreateTag = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const channelId = useSelector((state) => state.campaign.create.channelId) ;
  const [color, setColor] = useState(CONSTANTS.TAGS_COLORS[0]);
  const [shouldCreateTag, setShouldCreateTag] = useState(CREATE_TAG_YES);

  const labels = [
    "createTagCampaignTitle",
    "createTagCampaignYes",
    "createTagCampaignNo",
    "createTagCampaignPickColor",
  ];
  const lbls = getLabelMessage(labels);

  const inputs = ["campaignTagName"];
  const { form, register, errors, watch, getValues } = useFormBuilder(
    inputs,
    {},
    false,
    true
  );

  const onSelect = async (selectedColor) => {
    setColor(selectedColor);
  };

  const onValueChange = (event) => {
    setShouldCreateTag(event.target.value);
  };

  const goToPreviousStep = () => {
    history.push("/broadcast/preview");
  };

  const goToNextStep = () => {
    try {
      if (shouldCreateTag === CREATE_TAG_YES) {
        const tagName = getValues().campaignTagName;
        const trimmedTagName = tagName.trim();
        if (trimmedTagName.length === 0) throw "TAG_NAME_EMPTY";
        dispatch(setTagToCreateAction(trimmedTagName, color));
      } else {
        dispatch(setNotTagToCreateAction());
      }
      history.push("/broadcast/name");
    } catch (error) {
      console.error(error);
      toast.error(getJcnError(error), toastConfig);
    }
  };

   useEffect(() => {
     if (!channelId) history.push("/broadcast/table");
   }, [channelId]);

  return (
    <BroadcastAppCard>
      <div>
        <h2
          style={{
            fontFamily: "SpaceGrotesk-Bold",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "150%",
            letterSpacing: "-0.257238px",
            color: "#2E3353",
            marginBottom: "25px",
          }}
        >
          {lbls.createTagCampaignTitle}
        </h2>
        <RadioButtonsContainer>
          <GropRadioButtons>
            <input
              style={{
                backgroundColor: "#FFFFFF",
                width: "16px",
                height: "16px",
                cursor: "pointer",
              }}
              type="radio"
              id={CREATE_TAG_YES}
              value={CREATE_TAG_YES}
              checked={shouldCreateTag === CREATE_TAG_YES}
              onChange={onValueChange}
            />
            <LabelRadioButton for={CREATE_TAG_YES}>
              {lbls.createTagCampaignYes}
            </LabelRadioButton>
          </GropRadioButtons>
          <GropRadioButtons>
            <input
              style={{
                backgroundColor: "#FFFFFF",
                width: "16px",
                height: "16px",
                cursor: "pointer",
              }}
              type="radio"
              id={CREATE_TAG_NO}
              value={CREATE_TAG_NO}
              checked={shouldCreateTag === CREATE_TAG_NO}
              onChange={onValueChange}
            />
            <LabelRadioButton for={CREATE_TAG_NO}>
              {lbls.createTagCampaignNo}
            </LabelRadioButton>
          </GropRadioButtons>
        </RadioButtonsContainer>
        {shouldCreateTag === CREATE_TAG_YES && (
          <>
            <BotInfo newTemplate>
              <div style={{ position: "relative", marginTop: "46px" }}>
                <InputContainer style={{ height: "initial" }}>
                  <WhatsappTemplateInputContainer>
                    {form.map(
                      (input) =>
                        input.name === "campaignTagName" && (
                          <Input
                            key={input.name}
                            ref={register}
                            error={get(errors, `${input.name}.message`)}
                            watch={watch}
                            onChange={() => {}}
                            name={input.name}
                            label={"Nombre de etiqueta"}
                            placeholder={input.placeholder}
                            type={input.inputType}
                            showLabelError
                            broadcastName
                          />
                        )
                    )}
                  </WhatsappTemplateInputContainer>
                </InputContainer>
              </div>
            </BotInfo>
            <h3
              style={{
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "150%",
                letterSpacing: "-0.257238px",
                color: "#202020",
                marginBottom: "15px",
              }}
            >
              {lbls.createTagCampaignPickColor}
            </h3>
            <ColorPick>
              <HexColorPicker color={color} onChange={onSelect} />
              <ChooseColor color={color} />
            </ColorPick>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <BackButton
          style={{
            position: "relative",
            left: "0px",
            bottom: "0px",
            height: "initial",
          }}
          templateAction
          onClick={() => {
            history.push("/broadcast/preview");
          }}
        >
          <BackButtonIconContainer>
            <BackButtonIcon src={backButtonIcon} />
          </BackButtonIconContainer>
          <BackButtonText>Regresar</BackButtonText>
        </BackButton>

        <ButtonContainer newTemplate noMargin>
          <Button createTemplate color="primary" onClick={goToNextStep}>
            Continuar
          </Button>
        </ButtonContainer>
      </div>
    </BroadcastAppCard>
  );
};

export default BroadcastCreateTag;
