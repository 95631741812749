import React from "react";
import { useEffect } from "react";

const TimePicker = ({ label, value, onChange }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <span
        style={{
          position: "absolute",
          background: "white",
          top: "-12px",
          left: "7px",
          padding: "5px",
          color: "#2E3353",
          fontFamily: "SpaceGrotesk-Bold",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
        }}
      >
        {label}
      </span>
      <input
        type="time"
        value={value}
        onChange={onChange}
        dateFormat="hh:mm"
      />
    </div>
  );
};

export default TimePicker;
