
const toastConfig = {
  position: 'top-center',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export default toastConfig;
