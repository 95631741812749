import styled, { css } from 'styled-components';

import { MAIN_COLOR } from '../../../const/theme';

export const LinksContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${MAIN_COLOR};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${MAIN_COLOR};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  border-radius: 100%;
  height: 1.2rem;
  width: 1.2rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.3rem;
  color: p
`;

export const LinkImage = styled.img`
  margin: 0 auto;
  width: 100%;
`;

export const LinkName = styled.div`
  font-family: SpaceGrotesk-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 150%;
  letter-spacing: -0.257238px;

  color: #000;

  ${(props) =>
    props.isActive &&
    css`
      font-family: SpaceGrotesk-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 150%;

      letter-spacing: -0.257238px;

      color: #128c7e;
    `}
`;
