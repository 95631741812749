import Pusher from "pusher-js";

if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
  Pusher.logToConsole = true;
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

export default pusher;
