import styled, { css } from "styled-components";
import Devices from "../../../../const/devices";

export const InputContainer = styled.div`
  ${(props) =>
    props.fillFlex &&
    css`
      flex-grow: 1;
    `}
  position: relative;
`;

export const InputForm = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  border-radius: 6px;
  padding: 0.6rem 0;

  font-family: "SpaceGrotesk-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  background-color: ${(props) => {
    if (props.error && !props.onboardingLabelStyle) {
      if (!props.newTheme) return "rgba(255, 0, 0, 0.1)";
      if (props.newTheme) return "rgba(250, 76, 76, 0.95)";
      if (props.error) {
        if (!props.newTheme) return "rgba(255, 0, 0, 0.1)";
        if (props.newTheme) return "rgba(250, 76, 76, 0.95)";
      }
      if (props.disabled) return "transparent";
      return "#f5f4f6";
    }
  }};
  background-color: rgb(245, 244, 246);
  padding: 0.6rem 0;
  margin-top: 1rem;

  ${(props) =>
    props.broadcastName &&
    css`
      width: 100%;
      height: 38px;

      margin-top: 0;
      margin-left: 0.25rem;

      border: 2px solid #e9e9e9;
      border-radius: 4px;
      background-color: white;

      font-family: "SpaceGrotesk-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;
      letter-spacing: -0.257238px;

      color: #2e3353;

      padding-left: 0.5rem;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    `}
`;

export const InputLabel = styled.label`
  font-size: 0.875rem;
  opacity: 0.5;
  font-family: "SpaceGrotesk-Medium";
  ${(props) =>
    props.newTemplate &&
    css`
      font-family: "Montserrat-Medium";
      opacity: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 24px;
      /* identical to box height, or 171% */

      letter-spacing: -0.32px;

      color: #757575;
      margin-right: 98px;
      @media (min-width: 1500px) {
        margin-right: ${(props) => (props.variable ? "0" : "145px")};
      }
      @media (min-width: 1600px) {
        margin-right: ${(props) => (props.variable ? "0" : "230px")};
      }
      @media (min-width: 1900px) {
        margin-right: ${(props) => (props.variable ? "0" : "260px")};
      }
      @media ${Devices.desktop} {
        margin-right: ${(props) => (props.variable ? "0" : "290px")};
      }
    `}
  ${(props) =>
    props.broadcastName &&
    css`
      position: absolute;
      top: -14px;
      left: 12px;
      background: white;
      font-family: SpaceGrotesk-Bold;
      font-style: normal;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      /* identical to box height, or 18px */
      opacity: 1;
      padding-top: 0.25rem;

      letter-spacing: -0.257238px;

      color: #2e3353;
    `}

  ${(props) =>
    props.followUp &&
    css`
      margin-left: 2.625rem;
    `}
`;

export const InputIcon = styled.img`
  margin-left: 1rem;
  width: 1.5rem;
`;

export const InputValue = styled.input`
  font-family: ${(props) =>
    props.inputValueBold ? "SpaceGrotesk-Bold" : "SpaceGrotesk-Medium"};
  font-size: 1rem;
  background: none;
  border: none;
  margin-left: 1rem;
  flex-grow: 2;

  ${(props) =>
    props.error &&
    props.newTheme &&
    css`
      color: white;
    `}

  &:focus {
    outline: none;
  }

  &::placeholder {
    ${(props) =>
      props.error && props.newTheme
        ? css`
            color: white;
            opacity: 0.6;
          `
        : css`
            opacity: 0.3;
          `}
  }

  @media (min-width: 300px) {
    width: 103%;
  }

  ${(props) =>
    props.broadcastName &&
    css`
      font-family: SpaceGrotesk-Regular, sans-serif;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 150%;
      /* identical to box height, or 21px */

      display: flex;
      align-items: center;
      letter-spacing: -0.257238px;

      color: #2e3353;

      margin-left: 0.25rem;
    `}
`;

export const InputError = styled.div`
  ${(props) =>
    props.onboardingLabelStyle
      ? css`
          font-family: "Montserrat-Medium";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.257238px;
          color: #f61e44;
        `
      : css`
          font-size: 1rem;
          color: red;
        `}
`;

export const InputMidWidthContainer = styled.div`
  width: 70%;
  margin-bottom: 2rem;
`;

export const FullWidthContainer = styled.div`
  margin-top: 80px;
  display: flex;
  width: 100%;

  @media (max-width: 1200px) {
    margin-top: 120px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 170px;
  }
`;

export const Row = styled.div`
  width: 50%;
  ${(props) =>
    props.messagesDetails &&
    css`
      padding: 0 1rem;

      @media (min-width: 1400px) {
        padding: 0 2rem;
      }

      @media (max-width: 1200px) {
        padding: 0 1.5rem;
        width: 54%;
      }

      @media (max-width: 1024px) {
        order: -1;
        width: 100%;
        margin-bottom: 2rem;
      }
    `}
  ${(props) =>
    props.form &&
    css`
      @media (max-width: 1200px) {
        width: 44%;
      }
    `}
`;

export const OnboardingStyleError = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

export const OnboardingErrorIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
