import gql from "graphql-tag";

const GET_LOCATIONS_BY_COMPANY_ID = gql`
  query locations($companyId: ID!) {
    locations(where: { company: { id: $companyId } }) {
      id
      name
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation updateLocation($id: ID!, $name: String!) {
    updateLocation(where: { id: $id }, data: { name: $name }) {
      id
      name
      company {
        id
        name
      }
    }
  }
`;

const CREATE_LOCATION = gql`
  mutation createLocation($name: String!, $companyId: ID!) {
    createLocation(data: { name: $name, company: { connect: { id: $companyId } } }) {
      id
      name
      company {
        id
        name
      }
    }
  }
`;

const DELETE_LOCATION = gql`
  mutation($documentId: ID!) {
    deleteLocation(where: { id: $documentId }) {
      id
    }
  }
`;

const GET_LOCATIONS_BY_NOT_ID_IN = gql`
  query locations($companyId: ID!, $locationIds: [ID!]) {
    locations(where: { company: { id: $companyId }, id_not_in: $locationIds }) {
      id
      name
    }
  }
`;

const ApolloLocation = {
  GET_LOCATIONS_BY_COMPANY_ID,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATIONS_BY_NOT_ID_IN
};

export default ApolloLocation;
