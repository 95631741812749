/* eslint-disable consistent-return */

import { split } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL__SUBSCRIPTION_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      const token = localStorage.getItem('token');
      return {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
          Connection: 'Upgrade',
          Upgrade: 'websocket',
        },
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

const apolloQuery = async (query, variables) => {
  try {
    const response = await apolloClient.query({ query, variables, fetchPolicy: 'no-cache' });
    return response;
  } catch (error) {
    if (error?.graphQLErrors?.[0]?.message === 'INVALID_TOKEN') {
      localStorage.clear();
      window.location.replace('/');
    } else throw error;
  }
};

const apolloMutation = async (mutation, variables) => {
  try {
    const response = await apolloClient.mutate({ mutation, variables, fetchPolicy: 'no-cache' });
    return response;
  } catch (error) {
    if (error?.graphQLErrors?.[0]?.message === 'INVALID_TOKEN') {
      localStorage.clear();
      window.location.replace('/');
    } else throw error;
  }
};

const client = {
  query: (operation) => apolloQuery(operation.query, operation.variables),
  mutate: (operation) => apolloMutation(operation.mutation, operation.variables),
  subscribe: (operation) => apolloClient.subscribe({ ...operation, fetchPolicy: 'no-cache' }),
};


export default client;
