import styled, { css } from "styled-components";
import { ConnectButton } from "360dialog-connect-button";

export const Container = styled.main`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
  background-color: #f5f5f5;
`;

export const Form = styled.div`
  width: 100%;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

export const CodeForm = styled.form`
  width: 100%;
  max-width: 800px;
  /* @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 550px) {
    width: 90%;
  } */
`;

export const Title = styled.p`
  width: 100%;
  font-family: SpaceGrotesk-Bold, sans-serif;
  font-weight: 700;
  font-size: 2.7rem;
  margin-bottom: 58px;
  display: block;
  color: #2e3353;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
  @media (max-width: 425px) {
    text-align: center;
    font-size: 1.5rem;
  }
`;

export const Label = styled.label`
  width: 100%;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 1.2rem;
  margin-bottom: 34px;
  display: block;
  color: #2e3353;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const LabelBold = styled.label`
  width: 100%;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 700;
  line-height: 24px;
  font-size: 1.2rem;
  margin-bottom: 34px;
  color: #2e3353;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const TermsValue = styled.a`
  width: 100%;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const Link = styled.a`
  width: 100%;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  line-height: 24px;
  font-size: 1.2rem;
  margin-bottom: 34px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }

  @media (max-width: 425px) {
    text-align: center;
  }
`;

export const TalkToExpertLabel = styled.label`
  width: 100%;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  margin-top: 52px;
  display: block;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const InputCodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const InputContainer = styled.div`
  height: 50px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding: 10px 12px;
  position: relative;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

export const InputLabel = styled.label`
  position: absolute;
  top: -10px;
  left: 10px;
  font-size: 1.2rem;
  font-family: SpaceGrotesk-Bold;
  font-weight: 700;
  color: #2e3353;
  background-color: white;
`;

export const Error = styled.p`
  color: #f61e44;
  margin-top: 1rem;
  font-family: SpaceGrotesk-Bold;
  font-weight: 700;
`;

export const Success = styled.p`
  color: #00cd7e;
  margin-top: 1rem;
`;

export const SecondaryButton = styled.button`
  background-color: transparent;
  color: #2a285f;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: underline;
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 400;
  margin-top: 1.5rem;

  @media (max-width: 425px) {
    width: 100%;
    font-size: 2rem;
  }
`;

export const PrimaryButton = styled.button`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  height: 3rem;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  background-color: #128c7e;
  font-size: 1.5rem;
  width: 100%;
  padding: 1rem 1.5rem;
  margin: 0 0 0 auto;
  cursor: pointer;

  @media (max-width: 425px) {
    font-size: 1.4rem;
    padding: 2rem 0;
  }

  &:disabled {
    background-color: #e9e9e9;
    color: #757575;
    cursor: not-allowed;
  }
`;

export const ToLoginButton = styled.button`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  height: 3rem;
  border-radius: 4px;
  margin-top: 10px;
  color: white;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  background-color: #4050b5;
  font-size: 1rem;
  padding: 0 1.5rem;
  cursor: pointer;
`;

export const Loading = styled.div`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: ${(props) => props.flex || "space-between"};
  align-items: center;
  width: 100%;
  justify-content: center;
`;

export const InputCode = styled.input`
  width: 5rem;
  height: 5rem;
  border-radius: 6px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 1.25rem;
  background-color: #e9e9e9;
  border: 1.6px solid #e9e9e9;

  ${(props) =>
    props.selected &&
    css`
      background-color: white;
      border: 1.6px solid #128c7e;
    `}
  ${(props) =>
    props.filled &&
    css`
      background-color: white;
    `}
  @media (max-width: 1500px) {
    width: 4rem;
    height: 4rem;
  }
  @media (max-width: 1300px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media (max-width: 1200px) {
    width: 3.8rem;
    height: 3.8rem;
  }
  @media (max-width: 1000px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media (max-width: 950px) {
    width: 3rem;
    height: 3rem;
  }
  @media (max-width: 900px) {
    margin-right: 0.938rem;
    width: 2.5rem;
    height: 2.5rem;
  }
  @media (max-width: 768px) {
    margin-right: 0.938rem;
    width: 4.2rem;
    height: 4.2rem;
  }
  @media (max-width: 460px) {
    margin-right: 0.625rem;
    width: 4.2rem;
    height: 4.2rem;
  }
  @media (max-width: 425px) {
    margin-right: 0.625rem;
    width: 3.7rem;
    height: 3.7rem;
  }
  @media (max-width: 375px) {
    margin-right: 0.625rem;
    width: 3rem;
    height: 3rem;
  }
  @media (max-width: 320px) {
    margin-right: 0.625rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`;

export const CodeP = styled.p`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  margin-top: 1rem;
  @media (max-width: 425px) {
    font-size: 1.3rem;
  }
`;

export const LabelRadio = styled.label`
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1.6rem;
  }
`;

export const Radio = styled.input`
  margin-right: 10px;
`;

export const WebsiteRadioContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  font-weight: 500;
  border: none;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
`;

export const PasswordContainer = styled.div`
  width: 100%;
  border: 1px solid black;
  padding-left: 10px;
  position: relative;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
  @media (max-width: 768px) {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    font-family: SpaceGrotesk, sans-serif;
    font-weight: 400;
    font-size: 2rem;
  }
`;

export const PasswordInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.563rem;
  }
`;

export const PasswordButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;

  @media (max-width: 425px) {
    right: 1rem;
  }
`;

export const PlanRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
`;

export const PlanRowCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
`;

export const PlanTitle = styled.h3`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const PlanSubtitle = styled.p`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
  color: gray;
`;

export const PlanDesc = styled.span`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
`;

export const PlanOption = styled.strong`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  font-size: 1rem;
`;

export const PlanContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 40%;
  }
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(37, 41, 68, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Modal = styled.div`
  width: 80vw;
  height: 80vh;
  background-color: #fff;
  overflow-y: scroll;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  padding-top: 40px;
`;

export const ModalClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  font-size: 20px;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 20px;
`;

export const PlanCardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
`;

export const PlanCard = styled.article`
  padding: 15px;
  border-radius: 10px;
  border: 1px solid black;
  margin-bottom: 30px;
`;

export const PlanCardTitle = styled.h6`
  color: #2a285f;
  font-weight: 600;
  margin-top: 10px;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
`;

export const PlanCardUsd = styled.span`
  font-weight: 300;
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
`;

export const PlanUl = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 20px;
  margin-top: 1rem;
`;

export const PlanLi = styled.li`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const WhatsappButton = styled(ConnectButton)`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  height: 3rem;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  background-color: #25d366;
  font-size: 0.875rem;
  width: 16.375rem;
  padding: 0 1.5rem;
  margin: 0 0 0 auto;
  cursor: pointer;

  & > span {
    font-family: SpaceGrotesk, sans-serif;
    font-weight: 400;
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const StyledWhatsappButton = styled.button`
  font-family: SpaceGrotesk, sans-serif;
  font-weight: 600;
  height: 3rem;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  justify-content: center;
  background-color: #25d366;
  font-size: 0.875rem;
  width: 16.375rem;
  padding: 0 1.5rem;
  margin: 0 0 0 0;
  cursor: pointer;

  & > span {
    font-family: SpaceGrotesk, sans-serif;
    font-weight: 400;
  }

  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
`;

export const WelcomeTitle = styled.h6`
  color: #2e3353;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: SpaceGrotesk, sans-serif;
  font-size: 3rem;
  text-align: center;
`;

export const WelcomeSubtitle = styled.h6`
  font-weight: 500;
  margin-bottom: 15px;
  font-family: SpaceGrotesk, sans-serif;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.310495px;
  color: #757575;
`;

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 50%;
  max-width: 400px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const WelcomeImageContainer = styled.div`
  height: 500px;
  max-height: 300px;
`;

export const WelcomeImage = styled.img`
  height: 100%;
  @media (max-width: 425px) {
    width: 100%;
    height: auto;
  }
`;

export const FileInputContainer = styled.div`
  position: relative;
  width: 250px;
  margin-bottom: 10px;
`;

export const FileInputLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-family: SpaceGrotesk, sans-serif;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const DescriptionContainer = styled.div`
  position: relative;
  /* width: 500px; */
  margin-bottom: 10px;
`;

export const DescriptionInput = styled.input`
  width: 100%;
  height: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
`;

export const DescriptionLabel = styled.label`
  /* position: absolute;
  top: -10px;
  left: 10px; */
  font-size: 12px;
  color: #888;
  margin-bottom: 10px;
`;

export const BusinessLabel = styled.label`
  font-size: 16px;
  font-family: SpaceGrotesk, sans-serif;
  margin-top: 16px;
  color: #888;
`;

export const BusinessInput = styled.input`
  width: 100%;
  height: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: SpaceGrotesk, sans-serif;
  margin-top: 5px;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
`;

export const BusinessTextArea = styled.textarea`
  width: 100%;
  height: 3rem;
  border: 2px solid #ccc;
  border-radius: 6px;
  font-family: SpaceGrotesk, sans-serif;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
`;

export const BusinessSelect = styled.select`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  padding: 0 10px;
  font-family: SpaceGrotesk, sans-serif;
  font-size: 1rem;
  ${(props) => props.error && "border: 1px solid #F61E44;"}
`;

export const OnboardingImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 45vw;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OnboardingImage = styled.img`
  width: 100%;
`;

export const OnboardingImageWrapper = styled.div`
  height: 40%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OnboardingInfoContainer = styled.div`
  display: flex;
  justify-content: space-center;
  align-items: flex-end;
  height: 100%;
  width: 55vw;
  padding-right: 4vw;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const OnboardingFormCard = styled.div`
  height: 95%;
  width: 100%;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #e9e9e9;
  padding: 60px 70px;

  @media (max-width: 768px) {
    height: 100%;
    padding: 60px 30px;
    border-radius: 0;
  }
`;

export const OnboardingLogoContainer = styled.div`
  width: 100%;
  height: 4%;
  margin-bottom: 30px;
`;

export const OnboardingLogo = styled.img`
  height: 100%;
`;

export const OnboardingStepper = styled.div`
  display: flex;
  height: 20px;
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

export const OnboardingStep = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#128C7E" : "#E9E9E9")};
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin-right: 0;
  }
`;

export const OnboardingLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const OnboardingLoginText = styled.p`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #2e3353;
`;

export const OnboardingLoginButton = styled.button`
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  color: #128c7e;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
`;

export const PaymentContainer = styled.div`
  background-color: #f5f5f5;
  display: grid;

  @media (min-width: 768px) {
    padding: 15px;
  }
`;

export const PaymentCard = styled.div`
  display: grid;
  background-color: #fff;
  border: 1.6px solid #e9e9e9;
  padding: 40px 30px;
  gap: 20px;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 768px) {
    border-radius: 20px;
    height: calc(100vh - 50px);
    grid-template-columns: 1.2fr 0.8fr;
  }
`;

export const CardContainer = styled.div``;

export const ResumeContainer = styled.div``;

export const CardTitle = styled.h3`
  font-family: SpaceGrotesk-Bold, sans-serif;
  text-align: center;
  margin-bottom: 55px;
  font-size: 28px;
  color: #2e3353;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  margin-bottom: 55px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `}
`;

export const RowTitle = styled.p`
  font-family: SpaceGrotesk-Medium, sans-serif;
  color: #757575;
  font-size: 18px;
`;

export const RowDesc = styled.p`
  font-family: SpaceGrotesk-Bold, sans-serif;
  color: #2c3351;
  font-size: 18px;
`;

export const PricingTitle = styled.h3`
  font-family: SpaceGrotesk-Bold, sans-serif;
  color: #2e3353;
  font-size: 28px;
`;

export const PricingDesc = styled.h3`
  font-family: SpaceGrotesk-Bold, sans-serif;
  color: #2e3353;
  font-size: 28px;
`;

export const WelcomeOnboardingContainer = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  height: calc(100vh - 250px);
  width: 100%;
  gap: 24px;

  & p,
  & label {
    margin: 0;
  }

  @media (min-width: 768px) {
    height: 100%;

    & label {
      margin-bottom: 20vh;
    }
  }
`;
