import React from "react";
import PropTypes from "prop-types";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import Viewed, { ErrorIcon, ImageViewed } from "./MessageViewed.styles";
import schedule from "../../../assets/svg/schedule.svg";
import viewedStateOne from "../../../assets/svg/viewedStateOne.svg";
import viewedStateTwo from "../../../assets/svg/viewedStateTwo.svg";
import viewedStateThree from "../../../assets/svg/viewedStateThree.svg";

const MessageViewed = ({
  messageStatus,
  lastMessageCard,
  imagen,
  video,
  templateSelect,
}) => (
  <Viewed
    lastMessageCard={lastMessageCard}
    imagen={imagen}
    video={video}
    templateSelect={templateSelect}
  >
    {messageStatus === undefined || messageStatus === null ? (
      <ImageViewed src={viewedStateOne} alt="sent" />
    ) : messageStatus === "sent" ? (
      <ImageViewed src={viewedStateOne} alt="sent" />
    ) : messageStatus === "delivered" ? (
      <ImageViewed src={viewedStateTwo} alt="delivered" />
    ) : messageStatus === "viewed" ? (
      <ImageViewed src={viewedStateThree} alt="viewed" />
    ) : (
      <ErrorIcon icon={faExclamationCircle} />
    )}
  </Viewed>
);

MessageViewed.propTypes = {
  messageStatus: PropTypes.string,
  lastMessageCard: PropTypes.bool,
  imagen: PropTypes.bool,
  video: PropTypes.bool,
  templateSelect: PropTypes.bool,
};

export default MessageViewed;
