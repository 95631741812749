import client from '../apollo/apolloClient';
import ApolloLocation from '../apollo/apolloLocation';
import ApolloAdmins from '../apollo/apolloAdmins';

export const getLocations = async (companyId) => {
  try {
    const { data } = await client.query({
      query: ApolloLocation.GET_LOCATIONS_BY_COMPANY_ID,
      variables: { companyId },
    });
    return data.locations;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUsersByCompanyId = async companyId => {
  const { data } = await client.query({
    query: ApolloAdmins.GET_ADMINS_BY_COMPANY_ID,
    variables: { companyId },
  });
  return data.users;
};

export const getLocationsByIdNotIn = async (companyId, locationIds) => {
  try {
    const { data } = await client.query({
      query: ApolloLocation.GET_LOCATIONS_BY_NOT_ID_IN,
      variables: { companyId, locationIds },
    });
    return data.locations;
  } catch (error) {
    console.log(error);
    return error;
  }
};
