import API from '../axios';
import API_PAYMENTS from '../axiosPayments';

export const firstPayment = async (paymentMethodId, planKey) => {
  try {
    const response = await API.post('/stripe/firstPayment', { paymentMethodId, planKey });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStripeCustomer = async () => {
  try {
    const response = await API.get('/stripe/getCustomer');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await API.post('/stripe/cancelSubscription');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPaymentCustomer = async (companyId) => {
  try {
    const response = await API_PAYMENTS.get(`/customer/${companyId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addNewCard = async (cardId, customerId, setDefault) => {
  try {
    const response = await API_PAYMENTS.post('/customer/new-card', { cardId, customerId, setDefault });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteCard = async (customerId, cardId) => {
  try {
    const response = await API_PAYMENTS.delete(`/customer/${customerId}/card/${cardId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const setDefaultCard = async (customerId, cardId) => {
  try {
    const response = await API_PAYMENTS.post('/customer/card/default', {
      providerCustomerId: customerId,
      providerMethodId: cardId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const cancelSubscriptionRequest = async () => {
  try {
    const response = await API.post('/cancel');
    return response.data;
  } catch (error) {
    throw error;
  }
}