import CONSTANTS from "../const/constants";
import { setParams } from "../utils/onboarding";

// {
//   name,
//   phone,
//   business,
//   website,
//   category,
//   email,
//   plan,

//   score
//   device
// }

const ONBOARDING_INIT_STATE = {
  data: {},
  currentStep: CONSTANTS.ONBOARDING_NAME_STEP,
  prevStep: null,
  fetching: false,
  error: false,
};

const GETTING_ONBOARDING_USER_DATA = "GETTING_ONBOARDING_USER_DATA";
const GET_ONBOARDING_USER_DATA_SUCCESS = "GET_ONBOARDING_USER_DATA_SUCCESS";
const GET_ONBOARDING_USER_DATA_ERROR = "GET_ONBOARDING_USER_DATA_ERROR";
const SET_ONBOARDING_USER_DATA = "SET_ONBOARDING_USER_DATA";
const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";
const SET_ONBOARDING_PREV_STEP = "SET_ONBOARDING_PREV_STEP";

export default function onboardingReducer(
  state = ONBOARDING_INIT_STATE,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case GETTING_ONBOARDING_USER_DATA:
      return { ...state, fetching: true, data: payload, started: true };
    case GET_ONBOARDING_USER_DATA_SUCCESS:
      return { ...state, fetching: false, data: payload };
    case GET_ONBOARDING_USER_DATA_ERROR:
      return { ...state, fetching: false, error: payload };
    case SET_ONBOARDING_USER_DATA:
      return {
        ...state,
        data: payload.data,
      };
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        currentStep: payload.step,
      };
    case SET_ONBOARDING_PREV_STEP:
      return {
        ...state,
        prevStep: payload.step,
      };
    default:
      return state;
  }
}

export const setOnboardingDataAction = (userData) => async (dispatch) => {
  try {
    dispatch({ type: SET_ONBOARDING_USER_DATA, payload: { data: userData } });
  } catch (error) {
    const JcnError = new Error("CANNOT_SET_ONBOARDING_USER_DATA");
    throw JcnError;
  }
};

export const setScore = () => async (dispatch, getState) => {
  try {
    const userData = { ...getState().onboarding.data };
    let score = 0;

    // + (userData.device === 'desktop' ? 10 : 0)
    // + (userData.plan)
    // + (userData.connSpeed)
    // + (userData.location)
    // + (userData.referralCode);

    let value = 0;
    if (userData?.messages && userData?.agents) {
      const maxValue = 0.3;
      const maxMessages = 2000;
      const maxAgents = 5;

      const messagesRatio = userData?.messages / maxMessages;
      const agentsRatio = userData?.agents / maxAgents;

      const messagesWeight = 0.7;
      const agentsWeight = 0.3;

      value =
        (messagesRatio * messagesWeight + agentsRatio * agentsWeight) * maxValue;
    }
    score += value;

    // Tiene página web
    if (userData.hasWebsite) {
      score += 0.1;
    }

    // Dispositivo
    if (userData.device === "mobile") {
      score += 0.05;
    } else if (userData.device === "desktop") {
      score += 0.15;
    }

    // Velocidad de internet
    if (userData.connSpeed >= 100) {
      score += 0.2;
    } else if (userData.connSpeed >= 50) {
      score += 0.1;
    } else {
      score += 0.05
    }

    // Ubicación
    if (userData.location !== "MX") {
      score += 0.1;
    } else {
      score += 0.05;
    }

    // Si viene recomendado
    if (userData.referralCode) {
      score += 0.2;
    }

    score = Math.min(1, score);

    await setParams(userData?.id, { score });

    console.log('SCORE => ', score)

    dispatch({
      type: SET_ONBOARDING_USER_DATA,
      payload: { data: { ...userData, score } },
    });
  } catch (error) {
    console.error(error);
    const JcnError = new Error("CANNOT_SET_ONBOARDING_STEP");
    throw JcnError;
  }
};

export const setOnboardingStepAction = (step) => async (dispatch) => {
  try {
    dispatch({ type: SET_ONBOARDING_STEP, payload: { step: step } });
  } catch (error) {
    const JcnError = new Error("CANNOT_SET_ONBOARDING_STEP");
    throw JcnError;
  }
};

export const setOnboardingPrevStepAction = (step) => async (dispatch) => {
  try {
    dispatch({ type: SET_ONBOARDING_PREV_STEP, payload: { step: step } });
  } catch (error) {
    const JcnError = new Error("CANNOT_SET_ONBOARDING_PREV_STEP");
    throw JcnError;
  }
};

export const getOnboardingDataAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GETTING_ONBOARDING_USER_DATA, payload: {} });

    dispatch({ type: GET_ONBOARDING_USER_DATA_SUCCESS, payload: {} });
  } catch (error) {
    dispatch({ type: GET_ONBOARDING_USER_DATA_ERROR, payload: error });
    const JcnError = new Error("CANNOT_GET_ONBOARDING_USER_DATA");
    throw JcnError;
  }
};
