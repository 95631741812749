import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

export const ContentWithMargin = styled.div`
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
`;

export const ActiveAutoResponseContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const ActiveAutoResponseLabel = styled.p`
  margin-right: 1rem;
`;

export const FormContainer = styled.form`
  width: 100%;
`;

export const P = styled.p`
  margin-bottom: 1.5rem;
`;
export const PBotMenu = styled.pre`
  margin-bottom: 3.313rem;
`;
export const BotMenuTextArea = styled.textarea`
  resize: vertical;
  width: 100%;
  height: 140px;
  min-height: 140px;
  background-color: ${(props) =>
    props.error ? "rgba(255, 0, 0, 0.1)" : "#f5f4f6"};
  padding: 1rem;
  border: none;
  border-radius: 6px;
  font-family: Montserrat-Bold;
  margin-top: 0.5rem;
  &::placeholder {
    opacity: 0.4;
  }
`;

export const H1 = styled.h1`
  margin-bottom: 1.5rem;
`;
export const H1BotMenu = styled.h1`
  margin-bottom: 1.688rem;
  font-size: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
  ${(props) =>
    props.isOption &&
    css`
      color: #5897ff;
    `}
`;

export const BackButton = styled.div`
  width: 50%;
  height: 16px;
  display: flex;
  cursor: pointer;
  ${(props) =>
    props.onboarding &&
    css`
      margin-top: ${(props) =>
        props.isOption === "yes" ? "7.75rem" : "10.813rem"};
      font-family: "Montserrat-Medium";
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height, or 105% */

      letter-spacing: 0.288969px;
      text-decoration-line: underline;

      color: #2c3351;
    `}
  ${(props) =>
    props.createAccount &&
    css`
      margin-top: 3.75rem;
      font-family: "Montserrat-Medium";
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height, or 105% */

      letter-spacing: 0.288969px;
      text-decoration-line: underline;

      color: #2c3351;
    `}
`;
export const AddOptionContainer = styled.div`
  width: 156px;
  height: 16px;
  display: flex;
  cursor: pointer;
`;
export const ButtonAddOption = styled.div`
  width: 200px;
  height: 36px;
  display: flex;
  cursor: pointer;
  margin-top: 14px;
  margin-left: 420px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0px;
  }
`;

export const ButtonAbsolute = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
export const BackButtonIconContainer = styled.div`
  width: 16px;
  height: 16px;
`;
export const BotMenuButtonIcon = styled.img`
  width: 100%;
`;

export const BackButtonText = styled.span`
  width: 40px;
  height: 15px
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-decoration: underline;
`;

export const BotMenuLabel = styled.h6`
  margin-bottom: 17px;
  ${(props) => props.assignAgent && "margin-top: 45px"};
`;
export const OpcionsPreviewContainer = styled.div`
  width: 252px;
  margin-top: 35px;
  height: 241px;
  border: 1px solid #f5f4f6;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15));
  display: flex;
  flex-direction: column;
`;
export const OpcionsPreviewContent = styled.div`
  width: 100%;
  padding-left: 10px;
  overflow-y: scroll;
`;
export const OptionPreviewItem = styled.div`
  width: 100%;
  height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 10px;
  position: relative;
`;

export const OpcionPrevieText = styled.span`
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`;
export const OpcionPrevieCheckIcon = styled.span`
  border: 0.432675px solid #999999;
  width: 8.65px;
  height: 8.65px;
  border-radius: 50%;
  margin-left: 60px;
  position: absolute;
  right: 12px;
`;

export const WelcomeBotContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const CardWelcomeBotContent = styled.div`
  width: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CardWelcomeBot = styled.img`
  width: 100%;
  margin-bottom: 26px;
  margin-top: 25px;
  &:hover {
    border: 4px solid #00cd87;
    border-radius: 10px;
  }
`;

export const BotInfo = styled.div`
  width: 38.75rem;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.newTemplate &&
    css`
      margin-right: 5.875rem;
    `}
`;

export const PreviewContainer = styled.div`
  width: ${(props) => (props.botMenu ? "100%" : "50%")};
  padding: ${(props) => (props.botMenu ? "0 2rem 0 0" : "0 2rem")};
  font-size: 1.5rem;
  font-family: "Montserrat-Bold";
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.botMenu || props.alignStart ? "flex-start" : "flex-end")};
  ${(props) =>
    props.newTemplate &&
    css`
      padding-right: 0;
      align-items: flex-start;
    `}
`;

export const ChatPreviewTitle = styled.div`
  display: flex;
  font-family: "Montserrat-Bold";
  font-size: 1.5rem;
  justify-content: ${(props) => (props.botMenu ? "start" : "start")};
  width: 100%;
  margin-right: ${(props) => (props.botMenu ? "0" : "0")};
  margin-bottom: ${(props) => (props.botMenu ? "20px" : "0")};

  ${(props) =>
    props.isBotMenuPreview &&
    css`
      justify-content: flex-start;
      font-family: "Montserrat-Semibold";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    `}
`;

export const InputContainer = styled.div`
  margin-bottom: 1.5rem;
  width: 26.75rem;
  position: relative;
`;

export const VariablesButtonsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

export const AddLabel = styled.span`
  margin-right: 2rem;
  cursor: pointer;
`;

export const VariableName = styled.span`
  font-family: "Montserrat-Bold";
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  margin: 3.438rem 0 0 0;
  padding-right: 1.5rem;
  height: 3rem;
  ${(props) =>
    props.botMenu &&
    css`
      justify-content: flex-start;
      position: relative;
    `}
  ${(props) =>
    props.newTemplate &&
    css`
      margin: 1rem 1.5rem 1.5rem 0;
    `}
  ${(props) =>
    props.templateMedia &&
    css`
      margin: 10rem 1.5rem 1.5rem 0;
      position: absolute;
      bottom: ${(props) => (!props.templateAction ? "0" : "30px")};
      right: ${(props) => (!props.templateAction ? "0" : "24px")};
      width: 11.5rem;
      height: 1.375rem;
      cursor: pointer;
      display: flex;
      z-index: 1000;
      align-items: center;
    `}
  ${(props) =>
    props.isEditorError &&
    css`
      margin-top: 2.25rem;
    `}
  ${(props) =>
    props.noMargin &&
    css`
      margin: 0;
    `}
`;

export const InfoLabel = styled.span`
  font-family: Montserrat-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #757575;
  margin-top: 22px;
`;
