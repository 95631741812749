import styled, { css } from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Viewed = styled.div`
  ${(props) =>
    !props.lastMessageCard &&
    css`
      position: absolute;
      right: 0.313rem;
      bottom: 0.313rem;
    `};

  ${(props) =>
    props.imagen &&
    css`
      z-index: 3;
      margin-left: 0.25rem;
      right: 0.375rem;
      bottom: 0.5rem;
      padding: 0;
    `};

  padding-top: 0.375rem;
  padding-left: 0.5rem;
  margin-left: 5px;
  width: 1.375rem;
  height: 1.375rem;
  ${(props) =>
    props.file &&
    css`
      position: absolute;
      right: 0.125rem;
      bottom: 0.5rem;
      padding: 0;
    `};
  ${(props) =>
    props.templateSelect &&
    css`

      right: 0.5rem;

    `};
`;

export const ImageViewed = styled.img`
  width: 100%;
`;

export const ContentImageViewed = styled.div`
  width: 1.063rem;
  height: 1.063rem;
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: #ff0000;
`;

export default Viewed;
