import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { getLabelMessage } from "../../../const/labelService";
import {
  BroadcastAppCard,
  WhatsappTemplateInputContainer,
} from "./BroadcastView.styles";
import useFormBuilder from "../../../hooks/useFormBuilder";
import Button from "../../../components/shared/components/button/Button.style";
import { setNameAction } from "../../../redux/campaignDuck";
import {
  BotInfo,
  InputContainer,
} from "../../settings/autoresponses/AutoResponses.style";
import { NewWhatsappTitle } from "../../settings/whatsappTemplates/whatsappTemplateMultimedia/whatsappTemplateMultimedia";
import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";
import Input from "../../../components/shared/components/Input/Input.component";
import { useEffect } from "react";


const BroadcastName = ({ accountLimitOfMessages }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const channelId = useSelector((state) => state.campaign.create.channelId);

  const createCampaignData = useSelector((state) => state.campaign.create);
  const tamplateInfo = useSelector((state) => state.campaign.selected);

  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const labels = [
    "whatsappTemplateMultimediaTitle",
    "variableInstructionsMessagePreview",
    "broadcastName",
    "whatsappTemplateName",
  ];
  const lbls = getLabelMessage(labels);
  const inputs = ["templateName"];
  const {
    form,
    register: registerForm,
    errors,
    watch,
  } = useFormBuilder(inputs, {}, false, true);

  const templateNameWatch = watch("templateName", false);

  const handleActionButton = async () => {
    history.push("/broadcast/pricing");
    dispatch(setNameAction(templateNameWatch));

    // try {
    //   setLoading(true);
    //   dispatch(setNameAction(templateNameWatch));
    //   await API.post("/campaign", {
    //     channelId: createCampaignData.channelId,
    //     type: "TAG",
    //     tagsIds: createCampaignData.tagsIds,
    //     sendAt: createCampaignData.sendAt,
    //     shouldCreateTag: createCampaignData.shouldCreateTag,
    //     tagToCreate: {
    //       name: createCampaignData.tagToCreate.name,
    //       color: createCampaignData.tagToCreate.color,
    //     },
    //     name: templateNameWatch,
    //     templateId: tamplateInfo.id,
    //     fileUrl: tamplateInfo.newFileUrl,
    //     currentMessageLimit: currentLimit[accountLimitOfMessages] || 250,
    //     totalCost: 500, //TODO: Get from state
    //   });
    //   setLoading(false);
    //   setDisplaySuccessModal(true);
    //   setTimeout(() => {
    //     history.push("/broadcast/table");
    //   }, 2000);
    // } catch (error) {
    //   setLoading(false);
    //   console.error(error);
    //   toast.error("Error al crear la campaña", toastConfig);
    // }
  };

  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);

  return (
    <>
      <BroadcastAppCard>
        <BotInfo newTemplate style={{ flexGrow: 1 }}>
          <NewWhatsappTitle>{lbls.broadcastName}</NewWhatsappTitle>
          <div style={{ position: "relative", marginTop: "46px" }}>
            <InputContainer>
              <WhatsappTemplateInputContainer isBroadcastName={true}>
                {form.map(
                  (input) =>
                    input.name === "templateName" && (
                      <Input
                        key={input.name}
                        ref={registerForm}
                        error={get(errors, `${input.name}.message`)}
                        watch={watch}
                        onChange={() => {}}
                        name={input.name}
                        label={"Nombre de campaña"}
                        placeholder={input.placeholder}
                        type={input.inputType}
                        showLabelError
                        broadcastName
                      />
                    )
                )}
              </WhatsappTemplateInputContainer>
            </InputContainer>
          </div>
        </BotInfo>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BackButton
            style={{
              position: "relative",
              bottom: "0px",
              left: "0px",
              marginTop: "20px",
            }}
            templateAction
            onClick={() => {
              history.push("/broadcast/createTag");
            }}
          >
            <BackButtonIconContainer>
              <BackButtonIcon src={backButtonIcon} />
            </BackButtonIconContainer>
            <BackButtonText>Regresar</BackButtonText>
          </BackButton>

          <Button
            createTemplate
            color="primary"
            onClick={handleActionButton}
            disabled={templateNameWatch.length <= 0 || loading}
            createTemplateDisable={templateNameWatch.length <= 0 || loading}
          >
            Continuar
          </Button>
        </div>
      </BroadcastAppCard>
      {/* {displaySuccessModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            style={{
              width: "400px",
              height: "400px",
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <img
              src={successLogo}
              alt="success"
              style={{
                width: "100px",
                height: "100px",
              }}
            />
            <span
              style={{
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "28px",
                lineHeight: "150%",
                textAlign: "center",
                letterSpacing: "-0.257238px",
                color: "#000000",
                width: "100%",
              }}
            >
              ¡Has creado tu campaña con éxito!
            </span>
          </div>
        </div>
      )} */}
    </>
  );
};

export default BroadcastName;
