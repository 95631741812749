/* eslint-disable import/prefer-default-export */
import * as moment from 'moment';
import client from '../apollo/apolloClient';
import ApolloQuickAnswers from '../apollo/apolloQuickAnswers';
import API from '../axios';

export const getQuickAnswers = async (spaceId, descOrder) => {
  try {
    const { data } = await client.query({
      query: descOrder ? ApolloQuickAnswers.GET_QUICK_ANSWERS_ORDER_DESC : ApolloQuickAnswers.GET_QUICK_ANSWERS,
      variables: { spaceId },
    });
    return data.quickAnswers;
  } catch (error) {
    const JcnError = new Error('ERROR_GETTING_QUICK_ANSWERS');
    throw JcnError;
  }
};

export const deleteQuickAnswer = async (quickAnswerId) => {
  try {
    const { data } = await client.mutate({
      mutation: ApolloQuickAnswers.DELETE_QUICK_ANSWER,
      variables: { quickAnswerId },
    });
    return data;
  } catch (error) {
    const JcnError = new Error('ERROR_DELETING_QUICK_ANSWER');
    throw JcnError;
  }
};

export const createQuickAnswer = async (formData) => {
  try {
    const response = await API.post('/createQuickAnswer', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuickAnswer = async (formData) => {
  try {
    const response = await API.post('/updateQuickAnswer', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuickAnswerLastUse = async (quickAnswerId) => {
  try {
    const lastUse = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const { data } = await client.mutate({
      mutation: ApolloQuickAnswers.UPDATE_QUICK_ASNWER_LAST_USER,
      variables: { quickAnswerId, lastUse },
    });
    return data.updateQuickAnswer;
  } catch (error) {
    throw error;
  }
};
