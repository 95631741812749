import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  CardImage,
  CardImageContainer,
  CardSubtitle,
  CardTitle,
  DisabledCard,
} from "./BroadcastTypeCard";
import Button from "../../../../components/shared/components/button/Button.style";

const BroadcastTypeCard = ({
  title,
  subtitle,
  imagen,
  customTemplate,
  handleCreateTemplateButton,
  selected,
  category,
  disabled,
  label,
  isBot
}) => {
  return (
    <Card
      selected={selected}
      style={{}}
      onClick={() => handleCreateTemplateButton()}
      category={category}
      disabled={disabled}
      isBot={isBot}
    >
      {(disabled && isBot !== "true") && <DisabledCard />}
      <CardTitle customTemplate={customTemplate} category={category}>
        {!category && title}
        {category && selected && "RECOMENDADA"}
      </CardTitle>
      {!category && (
        <CardSubtitle customTemplate={customTemplate}>{subtitle}</CardSubtitle>
      )}
      <CardImageContainer
        customTemplate={customTemplate}
        selected={selected}
        category={category}
      >
        <CardImage src={imagen} />
      </CardImageContainer>
      <Button
        broadcastType
        disabledBroadcast={disabled}
        style={{ position: 'absolute', bottom: 0, marginBottom: '1rem'}}
      >
        {label ? label : "Elegir"}
      </Button>
    </Card>
  );
};

BroadcastTypeCard.protoType = {
  handleCreateTemplateButton: PropTypes.func,
};

export default BroadcastTypeCard;
