import gql from "graphql-tag";

const CREATE_TAG = gql`
  mutation createTag(
    $name: String!
    $color: String!
    $companyId: ID!
    $companyIdString: String!
    $key: String!
  ) {
    createTag(
      data: {
        name: $name
        color: $color
        company: { connect: { id: $companyId } }
        companyId: $companyIdString
        key: $key
        clientCount: 0
      }
    ) {
      id
      name
      createdAt
      color
      isDefault
    }
  }
`;

const EDIT_TAG = gql`
  mutation updateTag($id: ID!, $name: String!, $color: String!, $key: String!) {
    updateTag(
      where: { id: $id }
      data: { name: $name, color: $color, key: $key   }
    ) {
      id
      name
      color
      isDefault
      createdAt
    }
  }
`;

const GET_TAGS = gql`
  query getTags($companyId: String!) {
    tags(where: { companyId: $companyId }) {
      id
      name
      createdAt
      color
      key
      isDefault
    }
  }
`;

const GET_NOT_DEFAULT_TAGS = gql`
  query getNotDefaultTags($companyId: String!) {
    tags(where: { companyId: $companyId, isDefault: false }) {
      id
      name
      createdAt
      color
      clientCount
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTag($id: ID!) {
    deleteTag(where: { id: $id }) {
      id
    }
  }
`;

const UPSERT_TAG = gql`
  mutation upsertTag(
    $name: String!
    $color: String!
    $companyId: ID!
    $companyIdString: String!
    $key: String!
  ) {
    upsertTag(
      where: { key: $key }
      update: {
        key: $key
      }
      create: {
        name: $name
        color: $color
        company: { connect: { id: $companyId } }
        companyId: $companyIdString
        key: $key
        clientCount: 0
      }
    ) {
      id
    }
  }
`;

const ApolloTags = {
  CREATE_TAG,
  GET_TAGS,
  GET_NOT_DEFAULT_TAGS,
  DELETE_TAG,
  EDIT_TAG,
  UPSERT_TAG,
};

export default ApolloTags;
