import React, { useEffect, useState } from "react";

import API from "../../axios";
import useQuery from "../../hooks/useQuery";
import { Label } from "./Onboarding.styles";

const SuccessfulLink = () => {
  const clientQuery = useQuery("client");
  const channelsQuery = useQuery("channels");
  const channels = channelsQuery
    ?.replace("[", '["')
    ?.replace("]", '"]')
    ?.replace(/,/g, '","');

  const [isLoading, setIsLoading] = useState(false);

  const connectWhatsApp = async (client, channels) => {
    setIsLoading(true);
    const response = await API.post("/link-whatsapp", {
      client,
      channels,
    });
    console.log(response);
    setIsLoading(false);
    //window.close();
  };

  useEffect(() => {
    if (clientQuery && channelsQuery) {
      console.log("clientQuery", clientQuery);
      console.log("channelsQuery", JSON.parse(channels));
      connectWhatsApp(clientQuery, JSON.parse(channels));
    }
  }, [clientQuery, channelsQuery]);

  return (
    <main
      style={{
        width: "100vw",
        height: "100vh",
        padding: "50px",
      }}
    >
      {isLoading && (
        <Label>Conectando WhatsApp, no cierres esta ventana...</Label>
      )}
      {!isLoading && (
        <Label>Número vinculado con exito, puedes cerrar esta ventana.</Label>
      )}
    </main>
  );
};

export default SuccessfulLink;
