import styled, { css } from "styled-components";

export const Modal = styled.div`
  display: ${(props) => (props.show ? "initial" : "none")};
`;

export const ModalBlur = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "absolute")};
  z-index: 20;
  background-color: #0f1432e8;
  inset: 0;
  backdrop-filter: blur(0px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const ModalContainer = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "absolute")};
  z-index: 21;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;

  ${(props) =>
    props.type === "general" &&
    css`
      background-color: white;
      width: ${(props) => (props.isImportContact ? "34.75rem" : "33rem")};
    `}
  ${(props) =>
    props.type === "general" &&
    props.isImportContact &&
    css`
      height: 27.375rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-evenly;
    `}

  ${(props) =>
    props.type === "success" &&
    css`
      background-color ${props.updatedClient ? "#fcd34d" : props.theme.primary};
      border-radius: 4px;
      width: 17.5rem;
      height: 17.5rem;
      padding: 1rem;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${(props) =>
    props.type === "importContactsSuccess" &&
    css`
      background-color: white;
      border-radius: 4px;
      width: 44.375rem;
      height: 34.375rem;
      padding: 1rem;
      color: black;

      display: flex;
      flex-direction: column;
      align-content: center;

      font-family: SpaceGrotesk-SemiBold;
      font-style: normal;
      font-weight: 500;
      font-size: 1.625rem;
      line-height: 32px;
      /* or 123% */

      text-align: center;
      letter-spacing: -0.32px;
      color: #000000;
    `}
  ${(props) =>
    props.type === "importContactInfo" &&
    css`
      background-color: white;
      border-radius: 4px;
      width: 39.938rem;
      height: 34.375rem;
      padding: 1rem;
      color: black;

      display: flex;
      flex-direction: column;

      align-items: center;

      font-family: "Montserrat-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 32px;
      /* or 123% */

      text-align: center;
      letter-spacing: -0.32px;
      color: #000000;
    `}
  ${(props) =>
    props.type === "importContact" &&
    css`
      background-color: white;
      border-radius: 4px;
      width: 34.75rem;
      height: 24.563rem;
      padding: 1rem;
      color: black;

      display: flex;
      flex-direction: column;

      align-items: center;

      font-family: "Montserrat-Bold";
      font-style: normal;
      font-weight: 700;
      font-size: 1.625rem;
      line-height: 32px;
      /* or 123% */

      text-align: center;
      letter-spacing: -0.32px;
      color: #000000;
    `}
  ${(props) =>
    props.type === "broadcastQuality" &&
    css`
      background-color: white;
      border-radius: 4px;
      width: 34.75rem;
      height: 24.563rem;
      padding: 1rem;
      color: black;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `}
`;

export const ModalMainText = styled.div`
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
  text-align: center;
  margin-top: ${(props) => (props.hasMarginTop ? "2.5rem" : "0.5rem")};
  margin-bottom: ${(props) => (props.hasMarginBottom ? "2rem" : "0.5rem")};
  margin-right: 2rem;
  margin-left: 2rem;

  ${(props) =>
    props.type === "success" &&
    css`
      font-size: 1rem;
      margin: 1.5rem 0 0;
    `}
  ${(props) =>
    props.type === "importContactsSuccess" &&
    css`
      width: 100%;
      height: 4rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 32px;
      /* or 133% */
      margin-left: 0;
      margin-right: 0;
      text-align: center;
      letter-spacing: -0.32px;

      color: #000000;
    `}
  ${(props) =>
    props.type === "importContactInfo" &&
    css`
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 2rem;

      text-align: center;
      letter-spacing: -0.32px;

      margin: 44px 0 48px 0;

      color: #000000;
    `}
  ${(props) =>
    props.type === "importContact" &&
    css`
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 2rem;

      text-align: center;
      letter-spacing: -0.32px;

      margin: 2rem 0 3.5rem 0;

      color: #000000;
    `}
  ${(props) =>
    props.type === "broadcastQuality" &&
    css`
      font-family: SpaceGrotesk-SemiBold;
      font-style: normal;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 150%;
      /* or 42px */

      text-align: center;
      letter-spacing: -0.257238px;

      color: #2e3353;
    `}
`;

export const ModalSecundaryText = styled.div`
  font-family: Montserrat-Regular;
  font-size: 0.875rem;
  width: 430px;
  max-width: max-content;
  text-align: center;
  margin-top: ${(props) => (props.hasMarginTop ? "2.5rem" : "0.5rem")};
  margin-bottom: ${(props) => (props.hasMarginBottom ? "2rem" : "0.5rem")};
  margin-right: 2rem;
  margin-left: 2rem;
  ${(props) =>
    props.importContact &&
    css`
      width: 19.813rem;
      height: 2.5rem;
      left: 20px;
      top: 0px;
      margin: 0;
      font-family: Montserrat-Medium;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 20px;
      /* or 125% */

      text-align: center;
      letter-spacing: -0.257238px;

      color: #000000;
    `}
  ${(props) =>
    props.importContactInfo &&
    css`
      width: 27.75rem;
      height: 2.5rem;
      left: 20px;
      margin: 0;
      font-family: Montserrat-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.257238px;
      color: #000000;
    `}
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  

  ${(props) =>
    props.isloadingForm &&
    css`
      width: 100%;
    `}
`;

export const InputContainer = styled.div`
  margin: 0.625rem 2.5rem;
`;

export const CancelButton = styled.div`
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  cursor: pointer;
`;

export const SuccessContact = styled.strong`
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.32px;

  color: #00cd7e;
`;
export const ModalQualityText = styled.strong`
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  padding-left: 0.25rem;
  color: ${(props) => (props.color ? props.color : "#000000")};
`;
export const ErrorContact = styled.strong`
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.32px;

  color: #f61e44;
`;
export const ModalImageContainer = styled.div`
  width: 7.875rem;
  height: 7.875rem;
  margin: 3.375rem 0 2.438rem 13.438rem;
`;
export const ModalImage = styled.img`
  width: 100%;
`;

export const ModalSecundaryTextContainer = styled.div`
  width: 21.375rem;
  margin-left: 6.6rem;
  display: flex;
  justify-content: center;
  margin-top: 34px;
`;
export const ModalInfoContainer = styled.div`
  width: 1rem;
`;
export const ModalInfo = styled.img`
  width: 100%;
  margin-bottom: 3px;
`;

export const CloseModalIconContainer = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 14px;
  cursor: pointer;
`;
export const CloseModalIcon = styled.img`
  width: 100%;
`;

export const CardWhatsappLink = styled.span`
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 13.1318px;
  line-height: 18px;
  /* identical to box height, or 133% */
  cursor: pointer;
  letter-spacing: -0.233455px;
  text-decoration-line: underline;

  color: #00cd7e;
  margin-left: 26px;

  ${(props) =>
    props.importContact &&
    css`
      display: block;
      margin-top: 39px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    `}
`;

export const ModalInfoUl = styled.ul`
  width: 35.25rem;
  height: 16.813rem;
  margin-left: 2.438rem;
`;
export const ModalInfoLi = styled.li`
  text-align: left;
  font-family: "Montserrat-Medium";
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 22px;
  /* or 157% */

  letter-spacing: -0.257238px;
`;
export const ModalInfoImageContainer = styled.div`
  width: 35rem;
  height: 2.75rem;
  margin-top: 1rem;
`;
export const ModalInfoImage = styled.img`
  width: 100%;
`;
