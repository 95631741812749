import styled, { css } from "styled-components";

export const ChatDropzoneContainer = styled.div`
  padding: 1.3rem 1.3rem 0;
  ${(props) => props.newTheme && `height: 21rem;`}

  ${(props) =>
    props.newTheme &&
    css`
      @media (max-width: 1400px) {
        height: 22.5rem;
      }
    `}
`;
