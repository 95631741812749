import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import API from "../../axios";

import {
  ButtonContainer,
  Label,
  LabelRadio,
  PrimaryButton,
  Radio,
  SecondaryButton,
  Form,
  WebsiteRadioContainer,
  VideoContainer,
  Container,
  Error,
} from "./Onboarding.styles";

import {
  setOnboardingPrevStepAction,
  setOnboardingDataAction,
} from "../../redux/onboardingDuck";
import { verifyTokenAction } from "../../redux/userDuck";

const OnboardingUseOldNumber = () => {
  const inputRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();

  const onboardingUserData = useSelector((state) => state.onboarding.data);
  const userData = useSelector((state) => state.user.data);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUseNewPhone, setIsUseNewPhone] = useState("true");

  const init = async () => {
    try {
      if (!onboardingUserData.id) {
        setLoading(true);
        const response = await API.get("/onboarding");

        const { isUseNewPhone } = response.data;

        setIsUseNewPhone(isUseNewPhone);
        dispatch(setOnboardingDataAction({ ...response.data }));
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError("Ha ocurrido un error, inténtalo de nuevo más tarde.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputRef?.current) inputRef.current.focus();

    if(!userData?.id) {
      dispatch(verifyTokenAction());
    }

    init();
    // shouldRestartOnboarding(prevStep, history);
  }, []);

  const goBack = () => {
    history.goBack();
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await API.post("/set-old-phone", {
        isUseNewPhone: isUseNewPhone === "true",
      });
      //await API.get(`/onboarding/send-video`);
      dispatch(
        setOnboardingDataAction({
          ...onboardingUserData,
          isUseNewPhone,
        })
      );
      setLoading(false);
      history.push("/link-whatsapp");
    } catch (error) {
      console.error(error);
      setError("Ha ocurrido un error, inténtalo de nuevo más tarde.");
      setLoading(false);
    }
  };

  return (
    <Container>
      <Form>
        <Label htmlFor="businessWeb">
          ¿Desea utilizar su número de teléfono anterior o usar uno nuevo?{" "}
        </Label>
        <WebsiteRadioContainer>
          <LabelRadio>
            <Radio
              type="radio"
              value="true"
              name="useNewNumber"
              checked={isUseNewPhone === "true"}
              onChange={(e) => setIsUseNewPhone(e.target.value)}
            />
            Deseo utilizar uno nuevo
          </LabelRadio>
          <LabelRadio>
            <Radio
              type="radio"
              value="false"
              name="useOldNumber"
              checked={isUseNewPhone === "false"}
              onChange={(e) => setIsUseNewPhone(e.target.value)}
            />
            Deseo utilizar el mismo
          </LabelRadio>
        </WebsiteRadioContainer>
        {isUseNewPhone === "false" && (
          <VideoContainer>
            <video controls width="60%" height="100%">
              <source
                src="https://testflutterreact.s3.amazonaws.com/iventas_hola.mp4" //TODO: change to real video
                type="video/mp4"
              />
              {/* <source src="ruta-del-video.webm" type="video/webm" /> */}
              <p>Tu navegador no admite el elemento video.</p>
            </video>
          </VideoContainer>
        )}
          <ButtonContainer>
          <SecondaryButton onClick={goBack}>Regresar</SecondaryButton>
          {loading && <div>Cargando...</div>}
          {!loading && (
            <PrimaryButton onClick={onSubmit}>Siguiente</PrimaryButton>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default OnboardingUseOldNumber;
