const getWeightFile = (weight) => {
   let size = "KB";
   let fileSize = Math.round(weight / 10.24) / 100;
   if (fileSize >= 1000) {
     fileSize = Math.round(fileSize / 10.24) / 100;
     size = "MB";
   }
  return {
    size,
    fileSize
  };
};

export default getWeightFile;
