import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import API from "../../../axios";
import { toast } from "react-toastify";
import Button from "../../../components/shared/components/button/Button.style";
import LoaderComponent from "../../../components/loader/Loader.component";
import successLogo from "../../../assets/svg/succes-logo.svg";

import {
  PaymentTitle,
  MainRow,
  PaymentSubTitle,
  PaymentCostSpan,
  MainColumn,
  BroadcastAppCard,
} from "./BroadcastView.styles";

import {
  BackButton,
  BackButtonIcon,
  BackButtonIconContainer,
  BackButtonText,
} from "../../settings/whatsappTemplates/whatsappTemplats.style";
import { getBroadcastPricing } from "../../../utils/broadcast";
import toastConfig from "../../../components/shared/constants/toaster-config";
import backButtonIcon from "../../../assets/svg/KeyboardArrowLeft.svg";

const currentLimit = {
  TIER_1K: 1_000,
  TIER_10K: 10_000,
  TIER_100K: 100_000,
  "TIER_0.25K": 250,
  NA: 250,
};

const BroadcastPayment = ({ accountLimitOfMessages }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const createCampaignData = useSelector((state) => state.campaign.create);
  const templateInfo = useSelector((state) => state.campaign.selected);
  const templateFileUrl = templateInfo?.components
    ? templateInfo?.components?.[0]?.exampleHeader?.header_handle?.[0]
    : "";
  const channelId = useSelector((state) => state.campaign.create.channelId);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pricing, setPricing] = useState(0.0);
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);

  const handleActionButton = async () => {
    try {
      setIsLoading(true);
      await API.post("/campaign", {
        channelId: createCampaignData.channelId,
        type: "TAG",
        tagsIds: createCampaignData.tagsIds,
        sendAt: createCampaignData.sendAt,
        shouldCreateTag: createCampaignData.shouldCreateTag,
        tagToCreate: {
          name: createCampaignData.tagToCreate.name,
          color: createCampaignData.tagToCreate.color,
        },
        name: createCampaignData.name,
        templateId: templateInfo.id,
        fileUrl: templateInfo.newFileUrl || templateFileUrl,
        currentMessageLimit: currentLimit[accountLimitOfMessages] || 250,
        totalCost: pricing,
        conjunction: createCampaignData.conjunction,
      });
      setIsLoading(false);
      setDisplaySuccessModal(true);
      setTimeout(() => {
        history.push("/broadcast/table");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error("Error al crear la campaña", toastConfig);
    }
  };

  useEffect(() => {
    if (!channelId) history.push("/broadcast/table");
  }, [channelId]);

  useEffect(() => {
    fetchPricing();
  }, []);

  const fetchPricing = async () => {
    setIsLoading(true);
    try {
      const response = await getBroadcastPricing(
        createCampaignData?.channelId,
        templateInfo?.id,
        createCampaignData?.tagsIds,
        createCampaignData?.conjunction
      );
      console.log("getBroadcastPricing => ", response);
      setPricing(response?.totalCost);
    } catch (e) {
      console.log("ERROR AT FETCHING BROADCAST PRICING => ", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BroadcastAppCard>
        <MainColumn>
          <MainRow>
            <PaymentTitle>Costo total de la campaña</PaymentTitle>
          </MainRow>
          <MainRow>
            <PaymentCostSpan>
              {isLoading && <LoaderComponent width="22" height="22" />}
              {"$" + pricing?.toFixed(4) + " USD"}
            </PaymentCostSpan>
          </MainRow>
          <MainRow>
            <PaymentSubTitle>Se cobrará después</PaymentSubTitle>
          </MainRow>
        </MainColumn>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BackButton
            style={{
              position: "relative",
              bottom: "0px",
              left: "0px",
              marginTop: "20px",
            }}
            templateAction
            onClick={() => {
              history.push("/broadcast/name");
            }}
          >
            <BackButtonIconContainer>
              <BackButtonIcon src={backButtonIcon} />
            </BackButtonIconContainer>
            <BackButtonText>Regresar</BackButtonText>
          </BackButton>

          <Button
            createTemplate
            color="primary"
            onClick={handleActionButton}
            disabled={isLoading}
          >
            Continuar
          </Button>
        </div>
      </BroadcastAppCard>
      {displaySuccessModal && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div
            style={{
              width: "400px",
              height: "400px",
              backgroundColor: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <img
              src={successLogo}
              alt="success"
              style={{
                width: "100px",
                height: "100px",
              }}
            />
            <span
              style={{
                fontFamily: "SpaceGrotesk-Medium",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "28px",
                lineHeight: "150%",
                textAlign: "center",
                letterSpacing: "-0.257238px",
                color: "#000000",
                width: "100%",
              }}
            >
              ¡Has creado tu campaña con éxito!
            </span>
          </div>
        </div>
      )}
      {/*      
      <PaymentModalBackground>
        <PaymentModal>
          <MainRow>Nuevo método de pago</MainRow>
          <MainRow>
            <Elements stripe={stripePromise} options={stripeOptions}>
            <form
                onSubmit={submit}
                style={{
                  width: "70%",
                  maxWidth: "800px",
                  padding: "50px",
                }}
              >
                <div
                  style={{
                    marginTop: "30px",
                  }}
                >
                  <PaymentElement />
                </div>
                {error && (
                  <p
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error}
                  </p>
                )}
                {loading && (
                  <>
                    <span>Cargando...</span>
                  </>
                )}
                {!loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <PrimaryButton type="submit">Continuar con tarjeta</PrimaryButton>
                  </div>
                )}
                <div style={{marginTop: "10px"}}>
                {score > 0.4 && (
                  <>
                    <SecondaryButton
                      onClick={() =>
                        configPaymentMethod(CONSTANTS.PAYMENT_METHOD_TRANSFER)
                      }
                    >
                      Pagar mediante transferencia
                    </SecondaryButton>
                    <SecondaryButton
                      onClick={() => configPaymentMethod(CONSTANTS.PAYMENT_METHOD_OXXO)}
                    >
                      Pagar mediante deposito en OXXO
                    </SecondaryButton>
                  </>
                )}
                
              </div>
              </form>
            </Elements>
          </MainRow>
        </PaymentModal>
      </PaymentModalBackground>
      <MainRow>
        <PaymentTitle>Recarga saldo para tu campaña</PaymentTitle>    
      </MainRow>
      <MainRow>
        <PaymentSubTitle>Necesitas el saldo siguiente para enviar tu campaña:</PaymentSubTitle>    
      </MainRow>
      <MainRow>
        <PaymentCostSpan>$500.79 mxn</PaymentCostSpan>    
      </MainRow>
      <MainRow>
        <PaymentSubTitle>Elige tu método de pago:</PaymentSubTitle>    
      </MainRow>
      <MainRow>
        <MainColumn>
          <PaymentMethodCard>
            <MainColumn>
              <img src={mastercard} />
            </MainColumn>
            <PaymentMethodCardSectionCenter>
              <MainRow><CardInfoSpan>Mastercard Débito</CardInfoSpan></MainRow>
              <MainRow><CardInfoSpan>***************** 5465</CardInfoSpan></MainRow>
            </PaymentMethodCardSectionCenter>
            <MainColumn>
              <input type="radio" value="one" name="method" />
            </MainColumn>
          </PaymentMethodCard> 

          <PaymentMethodCard>
            <PaymentMethodCardSectionCenter>
              <MainRow><CardInfoSpan isImportant={true}>Cambiar o agregar método de pago</CardInfoSpan></MainRow>
            </PaymentMethodCardSectionCenter>
            <MainColumn>
              <img src={addIcon} />
            </MainColumn>
          </PaymentMethodCard> 
          
           <PaymentMethodCard>Elige tu método de pago 2222:</PaymentMethodCard>    
          <PaymentMethodCard>Elige tu método de pago 333:</PaymentMethodCard>
        </MainColumn>
      </MainRow>
      */}
    </>
  );
};

export default BroadcastPayment;
