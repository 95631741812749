import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CONSTANTS from "../../../const/constants";
import { MainWraper, MessageWrapper, Button } from './SuscriptionAlert.style';
import { InfoOutlined } from "@material-ui/icons";
import { getSucriptionAlertMessage } from "./SuscriptionAlert.utils";

const SuscriptionAlert = ({
  customer,
}) => {
  const {
    planName: plan = '',
    cards = [],
  } = customer;
  const history = useHistory();
  const permissions = useSelector((state) => state.user?.data?.permissions);
  const company = useSelector((state) => state.user?.data?.company);
  const today = new Date();
  const expiration = new Date(company.expirationDate);
  const days = Math.floor((expiration - today) / (1000 * 60 * 60 * 24));
  const paymentAmount = "$1,500 MXN";
  const penalty = CONSTANTS.PENALITY;
  const defaultCard = cards.find((car) => car.isDefault);
  const messageObj = getSucriptionAlertMessage({
    plan,
    daysToExpire: days,
    paymentIntents: company.paymentIntents,
    paymentAmount,
    penalty,
    cardNumber: defaultCard?.last4 || '-',
    expirationDate: expiration,
    permissions,
    paymentMethod: company.paymentMethod,
  });

  if (!messageObj) {
    return null;
  }

  return (
    <MainWraper type={messageObj.type}>
      <MessageWrapper type={messageObj.type}>
        <InfoOutlined fontSize="small" />
        {messageObj.message}
      </MessageWrapper>
      {
        (permissions?.readPayments || permissions?.writePayments) && (
          <Button
            type={messageObj.type}
            onClick={() => history.push(CONSTANTS.UPDATE_SUBSCRIPTION_ROUTE)}
          >
            {messageObj.buttonMessage}
          </Button>
        )
      }
    </MainWraper>
  );
}

export default SuscriptionAlert;